import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Currency from "oc/components/oc-currency";

const MyCafeteriaCurrentItemIndicator = ({ label, value, color, width }) => {
  return (
    <Box
      height="96px"
      width={width}
      style={{ backgroundColor: color, borderRadius: "8px" }}
    >
      <Box padding="12px 16px 16px 16px">
        <Typography
          variant="body1"
          style={{
            color: "white",
            padding: "0px",
            margin: "0px",
          }}
        >
          {label}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography
            variant="h4"
            style={{
              color: "white",
              lineHeight: "42.5px",
              padding: "0px",
              margin: "0px",
            }}
          >
            <Currency value={value} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

MyCafeteriaCurrentItemIndicator.propTypes = {
  color: PropTypes.string,
};

MyCafeteriaCurrentItemIndicator.defaultProps = {
  label: "Felhasznált összeg",
  width: "auto",
};

export default MyCafeteriaCurrentItemIndicator;
