import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 38,
    height: 23,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    color: theme.palette.gray60.main,
    "&$checked": {
      transform: "translateX(15px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
      "&$disabled": {
        backgroundColor: theme.palette.gray40.main,
        color: theme.palette.white.main,
        "& + $track": {
          backgroundColor: theme.palette.gray40.main,
        },
      },
    },
    "&$disabled": {
      backgroundColor: theme.palette.gray40.main,
      color: theme.palette.gray40.main,
      "& + $track": {
        backgroundColor: theme.palette.white.main,
        opacity: 0.5,
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.primary.main,
      border: "4px solid #fff",
    },
  },
  thumb: {
    width: 21,
    height: 21,
    boxShadow: "none",
  },
  track: {
    borderRadius: "12px",
    border: `1.5px solid ${theme.palette.gray60.main}`,
    boxSizing: "border-box",
    backgroundColor: theme.palette.white.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  disabled: {},
}));

export default useStyles;
