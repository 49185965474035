import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const MyCafeteriaStatementTitle = ({ label, currentYear }) => {
    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop="90px"
            paddingBottom="80px"
        >
            <Typography variant="h4">
                {label} {currentYear}
            </Typography>
        </Box>
    );
};

export default MyCafeteriaStatementTitle;
