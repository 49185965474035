import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const addCafeteriaItemResultState = atom({
  key: "addCafeteriaItemResultState",
  default: {
    result: false,
    messages: [],
  },
  effects_UNSTABLE: [persistAtom],
});
