import gql from "graphql-tag";

export const ADD_PERMIT = gql`
  mutation addPermit($_id: String!, $userId: String!) {
    addPermit(_id: $_id, userId: $userId) {
      _id
      requestedAt
      permittedAt
      permittedBy
      cancelledAt
    }
  }
`;
