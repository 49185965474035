import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useClientState from "hooks/use-oc-client-state";
import Button from "@material-ui/core/Button";
import { useMutation } from "@apollo/react-hooks";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import MyHrAdminUsersImportResult from "my-hr/components/my-hr-admin-users-import-result";
import { IMPORT_SSHR_USERS } from "graphql/mutations";
import { IMPORT_USERS } from "graphql/mutations";
import useStyles from "./my-hr-admin-users-import-dialog.styles";
import { useBeforeunload } from "react-beforeunload";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImportSSHRDialog({
  showDialog,
  onShowDialog,
  finishImport,
  importType,
  onCloseDialog,
  automaticPasswordGeneration,
  roleFilters,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  useBeforeunload((event) => {
    event.preventDefault();
    updateProperty({
      variables: {
        resource: "system",
        key: "importLocked",
        value: "false",
      },
    });
  });

  let IMPORT_MUTATION =
    importType === "sshr" ? IMPORT_SSHR_USERS : IMPORT_USERS;

  let DIALOG_TITLE =
    importType === "sshr"
      ? t("IMPORT_USERS_FROM_SSHR")
      : t("IMPORT_USERS_FROM_JDOLBER");

  let INFO_TEXT =
    importType === "sshr"
      ? t("IMPORT_SSHR_USERS_TEXT_INFO")
      : t("IMPORT_USERS_TEXT_INFO");

  const { data: importProgress, refetch } = useProperty({
    resource: "system",
    key: "importProgress",
  });

  const progressInPercentString = importProgress ? `${importProgress}%` : "0%";

  const [importUsersResult, setImportUsersResult] = useClientState(
    "importSSHRUsersResult",
    {}
  );

  const [importLoading, setImportLoading] = useState(false);

  const [importUsers] = useMutation(IMPORT_MUTATION, {
    onCompleted: (data) => {
      setImportLoading(false);
      if (importType === "sshr") {
        setImportUsersResult(data.importSSHRUsers);
      } else {
        setImportUsersResult(data.importUsers);
      }
      updateProperty({
        variables: {
          resource: "system",
          key: "importProgress",
          value: "100",
        },
      });

      refetch();
    },
    onError: (error) => {
      setImportLoading(false);

      if (error.message === "Import process cancelled") {
        onCloseDialog();
      }
    },
  });

  let showImportResult = Object.keys(importUsersResult).length !== 0;

  function handleEnter() {
    updateProperty({
      variables: {
        resource: "system",
        key: "importLocked",
        value: "true",
      },
    });

    updateProperty({
      variables: {
        resource: "system",
        key: "importCancelled",
        value: "false",
      },
    });

    setImportLoading(true);
    importUsers({ variables: { automaticPasswordGeneration, roleFilters } });
  }

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onError({ ...err }) {
      alert("error" + err.toString());
    },
  });

  function handleExit() {
    updateProperty({
      variables: {
        resource: "system",
        key: "importProgress",
        value: "0",
      },
    });
    updateProperty({
      variables: {
        resource: "system",
        key: "importLocked",
        value: "false",
      },
    });
  }

  function cancelImport() {
    updateProperty({
      variables: {
        resource: "system",
        key: "importCancelled",
        value: "true",
      },
    });
    handleExit();
    onCloseDialog();
    //onShowDialog();
  }

  return (
    <Dialog
      id="import-dialog"
      fullScreen
      open={showDialog}
      onClose={onShowDialog}
      TransitionComponent={Transition}
      TransitionProps={{
        onEntered: handleEnter,
        onExited: handleExit,
      }}
    >
      <AppBar
        classes={{
          root: classes.appBar,
          colorSecondary: classes.colorSecondary,
        }}
        color="secondary"
        elevation={0}
      >
        <Toolbar>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            flexGrow={1}
          >
            <Box>
              <Button
                id="my-hr-users-cancel-import-button"
                color="primary"
                onClick={cancelImport}
              >
                {t("INTERRUPT")}
              </Button>
            </Box>
            <Box>
              <Typography variant="h5">{DIALOG_TITLE}</Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={finishImport}
                disableElevation
                style={{ height: "36px" }}
                disabled={importProgress !== "100"}
              >
                {t("FINISH")}
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box padding="16px">
        <Box
          display="flex"
          alignItems="center"
          marginTop="40px"
          marginBottom="56px"
        >
          <Box width="72%" paddingLeft="14%">
            <LinearProgress
              variant="determinate"
              value={parseInt(importProgress)}
              classes={{
                root: classes.linear,
                colorPrimary: classes.colorPrimary,
              }}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            paddingRight="14%"
            paddingLeft="8px"
          >
            <Box>
              <Typography variant="body2" color="textSecondary">
                {progressInPercentString}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>{INFO_TEXT}</Box>
      </Box>
      {showImportResult && !importLoading && (
        <Box padding="0px 16px">
          <MyHrAdminUsersImportResult
            importUsersResult={importUsersResult}
            //onImportUsers={handleShowImportDialog}
          />
        </Box>
      )}
    </Dialog>
  );
}
