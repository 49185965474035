import gql from "graphql-tag";

export const GET_ADMISSION = gql`
  query getAdmission($contractCode: String!, $year: String!, $month: String!) {
    getAdmission(contractCode: $contractCode, year: $year, month: $month) {
      date
      dayType
      myTimeType
      startTime
      endTime
      timeDataTypeCode
      timeDataTypeName
      workBreakInMinutes
    }
  }
`;
