import React from "react";
import Group from "oc/components/oc-private-layout-sidebar-group";
import Item from "oc/components/oc-private-layout-sidebar-item";
import Collapse from "oc/components/oc-private-layout-sidebar-collapse";
import BackMenu from "oc/components/oc-private-layout-sidebar-back-menu";
import Submenu from "oc/components/oc-private-layout-sidebar-submenu";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useCurrentUserState from "hooks/use-current-user-state";

export default function OcPrivateLayoutSidebarSelector({
  item,
  onMenuChange,
  onMenuItemChange,
  activeMenuItem,
  narrowMenu,
  onChangeTemporaryDrawer,
  narrowMenuOpened,
}) {
  let { contractCode } = useCurrentUserState();
  const { hasRole } = useUserRolesDataByContract({
    contract: contractCode,
  });

  if (!hasRole(item.roles, item.notAllowedRoles)) return null;

  if (item.type === "backMenu")
    return (
      <BackMenu
        id={item.id}
        label={item.label}
        translation={item.translation}
        onMenuChange={onMenuChange}
        narrowMenu={narrowMenu}
        targetMenu={item.targetMenu}
      />
    );

  if (item.type === "group")
    return (
      <Group
        id={item.id}
        label={item.label}
        translation={item.translation}
        narrowMenu={narrowMenu}
        narrowMenuOpened={narrowMenuOpened}
      />
    );
  if (item.type === "item")
    return (
      <Item
        activeMenuItem={activeMenuItem}
        badge={item.badge}
        icon={item.icon}
        id={item.id}
        label={item.label}
        narrowMenu={narrowMenu}
        narrowMenuIcon={item.narrowMenuIcon}
        narrowMenuOpened={narrowMenuOpened}
        onChangeTemporaryDrawer={onChangeTemporaryDrawer}
        onMenuChange={onMenuItemChange}
        path={item.path}
        translation={item.translation}
      />
    );
  if (item.type === "collapse")
    return (
      <Collapse
        id={item.id}
        badge={item.badge}
        icon={item.icon}
        label={item.label}
        children={item.children}
        activeMenuItem={activeMenuItem}
        onMenuItemChange={onMenuItemChange}
        onMenuChange={onMenuChange}
        narrowMenu={narrowMenu}
        onChangeTemporaryDrawer={onChangeTemporaryDrawer}
        narrowMenuOpened={narrowMenuOpened}
        translation={item.translation}
      />
    );
  if (item.type === "submenu")
    return (
      <Submenu
        id={item.id}
        label={item.label}
        translation={item.translation}
        icon={item.icon}
        badge={item.badge}
        onMenuChange={() => onMenuChange(item.id)}
        narrowMenu={narrowMenu}
        narrowMenuOpened={narrowMenuOpened}
      />
    );
  return null;
}
