import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./my-leave-effective-work-days-table.style";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

export default function MyLeaveEffectiveWorkDaysTable({
  effectiveWorkDaysByDays,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  let counter = 0;

  return (
    <Box className={classes.container}>
      <Grid container spacing={0} columns={4}>
        <Grid item className={classes.tableHeader} xs={2}>
          <Box>{t("DAYS")}</Box>
        </Grid>
        <Grid item className={classes.tableHeader} xs={3}>
          <Box>{t("DATE")}</Box>
        </Grid>
        <Grid item className={classes.tableHeader} xs={4}>
          <Box>{t("CONTRACT_WORKDAYS")}</Box>
        </Grid>
        <Grid item className={classes.tableHeader} xs={3}>
          <Box>{t("EFFECTIVE_WORKDAYS")}</Box>
        </Grid>
        {effectiveWorkDaysByDays?.map((item) => {
          if (item?.workDays === 0) return null;
          counter = counter + 1;
          return (
            <>
              <Grid item className={classes.tableItem} xs={2}>
                <Box>{`${counter}. ` + t("DAY")}</Box>
              </Grid>
              <Grid item className={classes.tableItem} xs={3}>
                <Box>{new Date(item?.date).toLocaleDateString()}</Box>
              </Grid>
              <Grid item className={classes.tableItem} xs={4}>
                <Box>{item?.workDays}</Box>
              </Grid>
              <Grid item className={classes.tableItem} xs={3}>
                <Box>{item?.effectiveWorkDay?.toLocaleString()}</Box>
              </Grid>
            </>
          );
        })}
      </Grid>
    </Box>
  );
}
