import { useQuery, useSubscription } from "@apollo/client";
import { GET_USER_ROLES_DATA_BY_CONTRACT } from "graphql/queries";
import { USER_ROLES_DATA_BY_CONTRACT_SUBSCRIPTION } from "graphql/subcriptions";
import _ from "lodash";
import usePolling from "hooks/use-polling";

export default function useUserRolesDataByContract({ contract }) {
  const { data, loading, error, refetch } = useQuery(
    GET_USER_ROLES_DATA_BY_CONTRACT,
    {
      fetchPolicy: "cache-and-network",
      pollInterval: usePolling(1000 * 60),
      skip: !contract,
      variables: {
        contract,
      },
    }
  );


  useSubscription(USER_ROLES_DATA_BY_CONTRACT_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    skip: !contract,
    variables: {
      contract,
    },
    onData: ({ client, subscriptionData }) => {
      client.writeQuery({
        query: GET_USER_ROLES_DATA_BY_CONTRACT,
        data: subscriptionData.data,
      });
    },
  });

  let result = data?.userRolesDataByContract || [];

  let roles = result.map((item) => {
    return `${item.product}.${item.role}`;
  });

  function hasRole(allowedRoles, notAllowedRoles) {
    if (!allowedRoles) return false;
    let isAllowedExist = _.intersectionBy(allowedRoles, roles);
    if (isAllowedExist.length === 0) {
      return false;
    }
    let isNotAllowedExist = [];
    if (notAllowedRoles) {
      isNotAllowedExist = _.intersectionBy(notAllowedRoles, roles);
      return isNotAllowedExist.length > 0 ? false : true;
    }
    return isAllowedExist.length > 0 ? true : false;
  }

  return {
    data: result,
    roles,
    hasRole,
    loading,
    error,
    refetch,
  };
}
