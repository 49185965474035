import gql from "graphql-tag";

export const CREATE_MY_TIME_MESSAGE = gql`
  mutation createMyTimeMessage(
    $request: String!
    $message: String!
    $employee: String!
  ) {
    createMyTimeMessage(
      request: $request
      message: $message
      employee: $employee
    ) {
      _id
      request
      message
      createdAt
      createdBy
    }
  }
`;
