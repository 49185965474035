import React from "react";
import {
  Box,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

const MyTimeSelect = ({ id, label, show, value, setValue, values }) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Collapse in={show}>
      <Box
        style={{
          padding: "32px 0px 0px 0px",
        }}
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-filled-label">{label}</InputLabel>
          <Select id={id} label={label} value={value} onChange={handleChange}>
            {values?.map((item, index) => {
              return (
                <MenuItem key={`my-time-select-${index}`} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Collapse>
  );
};

export default MyTimeSelect;
