import React from "react";
import bagIcon from "@iconify/icons-mdi/bag-personal";
import accountIcon from "@iconify/icons-mdi/account";
import cafeteriaIcon from "@iconify/icons-mdi/coffee";
import clockIcon from "@iconify/icons-mdi/clock";
import { InlineIcon } from "@iconify/react";

export default function getModuleIcon(modul) {
  switch (modul) {
    case "MyHR":
      return (
        <InlineIcon
          icon={accountIcon}
          height="28px"
          style={{ color: "#4D4D4D" }}
        />
      );
    case "MyLeave":
      return (
        <InlineIcon icon={bagIcon} height="28px" style={{ color: "#4D4D4D" }} />
      );
    case "MyCafeteria":
      return (
        <InlineIcon
          icon={cafeteriaIcon}
          height="28px"
          style={{ color: "#4D4D4D" }}
        />
      );
    case "MyTime":
      return (
        <InlineIcon
          icon={clockIcon}
          height="28px"
          style={{ color: "#4D4D4D" }}
        />
      );

    default:
      return null;
  }
}
