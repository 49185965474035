import React, { useRef } from "react";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import plusIcon from "@iconify/icons-mdi/plus-thick";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import useStyles from "./oc-filter-item-available-list.style";

export default function OcFilterItemAvailableList({
  selectedItems,
  availableItems,
  addItem,
  filterType,
  withCode,
  onLoading,
  onFetchMore,
}) {
  const classes = useStyles();

  const observer = useRef();
  if (availableItems?.length === 0) return null;

  function isExist(o) {
    let isExist = selectedItems.filter(function (value) {
      return value === o.code;
    });

    if (isExist?.length > 0) return true;
    return false;
  }

  const handleScroll = () => {
    if (observer.current) {
      const { scrollTop, scrollHeight, clientHeight } = observer.current;
      if (scrollTop + clientHeight === scrollHeight) {
        onFetchMore();
      }
    }
  };

  function disabledAvaiableItems(item) {
    const isSelected = selectedItems.some((value) =>
      typeof value === "object" && typeof item === "object"
        ? value.code === item.code
        : value === item.code
    );

    if (isSelected) {
      return {
        color: "#A6ADAF",
        cursor: "auto",
        pointerEvents: "none",
      };
    }

    return {
      color: "#212121",
      cursor: "pointer",
    };
  }

  return (
    <OcErrorBoundary>
      <Box
        ref={observer}
        onScroll={handleScroll}
        style={{
          backgroundColor: "white",
          fontSize: "16px",
          letterSpacing: "0.44px",
          lineHeight: "24px",
          padding: "8px 8px 8px 8px",
          maxHeight: "180px",
          overflowY: "auto",
          border: "1px solid #C6CACC",
          borderRadius: "4px",
        }}
      >
        {availableItems?.map((item, index) => {
          return (
            <Box
              id={item.code}
              key={`${item.name}-${index}`}
              padding="4px 8px"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.filterLine}
              style={disabledAvaiableItems(item)}
              onClick={() => addItem({ key: filterType, value: item?.code })}
            >
              <Box>{withCode ? `${item.name} (${item.code})` : item.name}</Box>
              <Box
                style={{
                  padding: "0px 4px",
                  cursor: isExist(item) ? "auto" : "pointer",
                }}
              >
                <InlineIcon icon={plusIcon} width="12px" />
              </Box>
            </Box>
          );
        })}
      </Box>
    </OcErrorBoundary>
  );
}
