import { useRecoilState, useResetRecoilState } from "recoil";
import { taxApplicationsState } from "states/tax-application-status-state";

const useTaxApplications = () => {
  const [taxApplications, setTaxApplications] =
    useRecoilState(taxApplicationsState);

  const resetTaxApplications = useResetRecoilState(taxApplicationsState);
  return { taxApplications, setTaxApplications, resetTaxApplications };
};

export default useTaxApplications;
