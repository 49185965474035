import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { InlineIcon } from "@iconify/react";
import PDFIcon from "@iconify/icons-mdi/file-pdf-box-outline";
import DownloadIcon from "@iconify/icons-mdi/download";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import Collapse from "@material-ui/core/Collapse";
import useChangeRequestAttachement from "hooks/use-change-requests-attachment";
import MyHrUserDataRequestSubCollapseHeader from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-user-data-request-sub-collapse-header";
import useStyles from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-user-data-edit-collapse/my-hr-user-data-edit-collapse.style";
import showIcon from "@iconify/icons-mdi/show";
import MyHrAttachmentDialog from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-attachment-dialog/my-hr-attachment-dialog";

export default function MyHrUserDataRequesAttachment({
  createdDateAt,
  approvedAt,
  rejectedAt,
  typeGroup,
  type,
  employeeCode,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useChangeRequestAttachement(
    typeGroup ? typeGroup : type,
    employeeCode
  );

  const [attachmentExpanded, setAttachmentExpanded] = useState(false);
  const [fullScreenVisible, setFullScreenVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleAttachmentExpandClick = () => {
    setAttachmentExpanded(!attachmentExpanded);
  };

  function removeAssetTag(filename) {
    let filenameArray = filename.split("/");
    return filenameArray[filenameArray.length - 1];
  }

  const handleCancel = () => {
    setFullScreenVisible(false);
  };

  return (
    <>
      <MyHrUserDataRequestSubCollapseHeader
        expanded={attachmentExpanded}
        handleExpandClick={handleAttachmentExpandClick}
        label={t("ATTACHMENTS")}
        badge={data.length + " " + t("MYHR_ATTACHMENT")}
        createdDateAt={createdDateAt}
        className={classes.attachmentBackgroundColor}
        classNameCollapse={classes.attachmentBackgroundColorOnCollapse}
        data={data}
      />
      <Collapse
        in={
          approvedAt === null && rejectedAt === null
            ? attachmentExpanded
            : false
        }
        timeout="auto"
      >
        <Box
          maxWidth="600px"
          style={{
            backgroundColor: theme.palette.white.main,
            padding: "0px 20px 0px 20px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            marginLeft: "16px",
          }}
        >
          <Box
            maxWidth="600px"
            style={{
              height: "1px",
              backgroundColor: theme.palette.gray40.main,
              width: "600px",
            }}
            marginBottom="20px"
          />
          {data.map((item, index) => (
            <Box key={`${item}-${index}`}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                height="72px"
                border={"1px solid #C6CACC"}
                style={{
                  borderRadius: "8px",
                  paddingLeft: "20px",
                  backgroundColor: theme.palette.gray20.main,
                }}
              >
                <InlineIcon
                  icon={PDFIcon}
                  width="24px"
                  style={{ color: "#4d4d4d" }}
                />
                <Box width="14px" />
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <Box>
                    <Box key={`${index}_${item._id}`}>
                      {removeAssetTag(item.filename)}
                      <Box
                        variant="caption"
                        style={{
                          color: "#919699",
                          fontSize: "12px",
                          marginTop: "3px",
                        }}
                      >
                        {new Date(item.createdAt).toLocaleDateString()}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <InlineIcon
                    id="showIcon"
                    icon={showIcon}
                    width="24px"
                    style={{
                      color: "#4d4d4d",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const isPdf = item.filename
                        .toLowerCase()
                        .endsWith(".pdf");
                      if (isPdf) {
                        window.open(item?.filename, "_blank");
                      } else {
                        setSelectedFile({
                          ...item,
                        });
                        setFullScreenVisible(true);
                      }
                    }}
                  />

                  <MyHrAttachmentDialog
                    showDialog={fullScreenVisible}
                    handleCancel={handleCancel}
                    fileData={selectedFile}
                  />
                </Box>
                <Box width="10px" />
                <Box paddingRight="16px">
                  <Link href={item.filename} target={item.filename} download>
                    <InlineIcon
                      id="downloadIcon"
                      icon={DownloadIcon}
                      width="24px"
                      style={{
                        color: "#4d4d4d",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Box>
              </Box>
              <Box height="16px" />
            </Box>
          ))}
        </Box>
      </Collapse>
    </>
  );
}
