import gql from "graphql-tag";

export const GET_LEAVE_EVENTS = gql`
  query leaveEvents($roleFilters: RoleFilterInput, $currentDate: Date, $viewedByAdmin: Boolean) {
    leaveEvents(roleFilters: $roleFilters, currentDate: $currentDate, viewedByAdmin: $viewedByAdmin) {
      _id
      contract
      start
      end
      state
      workDays
      effectiveWorkDays
      type
      typeName
      leaveType
      title
    }
  }
`;
