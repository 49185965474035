import gql from "graphql-tag";

export const GET_COMPANIES = gql`
  query companies(
    $searchField: String
    $searchText: String
    $sort: String
    $page: Int
    $pageSize: Int
  ) {
    companies(
      searchField: $searchField
      searchText: $searchText
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      availableList {
        _id
        code
        name
      }
      selectedList {
        _id
        code
        name
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
