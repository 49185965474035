import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_ALL_REQUESTS_BADGE_COUNTER } from "my-time/graphql/queries";
import useActualDate from "my-time/hooks/use-actual-date";
import moment from "moment";
import { MY_TIME_REQUESTS_SUBSCRIPTION } from "my-time/graphql/subscriptions";

const useMyTimeRequestCount = () => {
  const { date } = useActualDate();
  const startDate = moment(date).startOf("month").toDate();
  const endDate = moment(date).endOf("month").toDate();
  const { data, loading, error, refetch } = useQuery(
    GET_ALL_REQUESTS_BADGE_COUNTER,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        startDate,
        endDate,
      },
    }
  );

  useSubscription(MY_TIME_REQUESTS_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  return {
    data: data?.allRequestsBadgeCounter || 0,
    loading,
    error,
  };
};

export default useMyTimeRequestCount;
