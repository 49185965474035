import React from "react";
import Box from "@material-ui/core/Box";
import { Icon } from "@iconify/react";
import accountCheck from "@iconify/icons-mdi/account-check";
import accountBadge from "@iconify/icons-mdi/account-badge";
import useIsExaminerContract from "hooks/use-is-examiner-contract";
import useIsViewerContract from "hooks/use-is-viewer-contract";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

const AvailableRoles = ({ align, row }) => {
  const { t } = useTranslation();

  let { data: isExaminerContract } = useIsExaminerContract({
    contract: row?.contract,
  });

  let { data: isViewerContract } = useIsViewerContract({
    contract: row?.contract,
  });

  return (
    <TableCell align={align || "center"} style={{ padding: "0px" }}>
      <Box display="flex" flexDirection="row">
        <Tooltip title={t("Role.examiner")} placement="top">
          <Box>
            {isExaminerContract && <Icon icon={accountCheck} height="24px" />}
          </Box>
        </Tooltip>
        <Tooltip title={t("Role.viewer")} placement="top">
          <Box>
            {isViewerContract && <Icon icon={accountBadge} height="24px" />}
          </Box>
        </Tooltip>
      </Box>
    </TableCell>
  );
};

export default AvailableRoles;
