import gql from "graphql-tag";

export const GET_CONTRACT_FILTER_LIST = gql`
  query getContractFilterList(
    $offset: Int!
    $limit: Int!
    $search: String
    $contracts: [String]
  ) {
    getContractFilterList(
      offset: $offset
      limit: $limit
      search: $search
      contracts: $contracts
    ) {
      offset
      limit
      availableList {
        code
        employee {
          _id
          code
          prefixName
          lastName
          middleName
          firstName
          nameSuffix
        }
      }
      selectedList {
        code
        employee {
          _id
          code
          prefixName
          lastName
          middleName
          firstName
          nameSuffix
        }
      }
      searchedList {
        code
        employee {
          _id
          code
          prefixName
          lastName
          middleName
          firstName
          nameSuffix
        }
      }
      total
    }
  }
`;
