import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_TAX_DECLARATIONS_LIST } from "graphql/queries";
import { TAX_DECLARATIONS_SUBSCRIPTION } from "graphql/subcriptions";
import useTaxApplications from "./use-tax-applications";

const useTaxDeclarationList = ({ tableData, type }) => {
  const { taxApplications, setTaxApplications, resetTaxApplications } =
    useTaxApplications();

  const { data, loading, error, refetch } = useQuery(
    GET_TAX_DECLARATIONS_LIST,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        page: tableData.page,
        pageSize: tableData.pageSize,
        type,
      },
    }
  );

  const { data: allData, refetch: allRefetch } = useQuery(
    GET_TAX_DECLARATIONS_LIST,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        page: 1,
        pageSize: 200,
        type: "all",
      },
      onCompleted: (data) => {
        resetTaxApplications();
      },
    }
  );

  const allRequests = allData?.taxDeclarationList?.rows || [];
  const currentYear = new Date().getFullYear().toString();
  allRequests.forEach((req) => {
    if (
      req?.year === currentYear &&
      (req?.state === "K" || req?.state === "E")
    ) {
      if (taxApplications[req?.type] === true) {
        const newState = { ...taxApplications, [req?.type]: false };
        setTaxApplications(newState);
      }
    }
  });

  useSubscription(TAX_DECLARATIONS_SUBSCRIPTION, {
    onData: () => {
      resetTaxApplications();
      refetch();
      allRefetch();
    },
  });

  const result = data?.taxDeclarationList || [];

  return { data: result, loading, error };
};

export default useTaxDeclarationList;
