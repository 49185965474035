import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@material-ui/core";
import { InlineIcon } from "@iconify/react";
import emailIcon from "@iconify/icons-mdi/email";
import keyIcon from "@iconify/icons-mdi/key";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
import Validator from "fastest-validator";
import { useLazyQuery } from "@apollo/react-hooks";
import { useHistory, useLocation } from "react-router-dom";
import { OW_ADMIN_LOGIN } from "graphql/queries";
import { REQUEST_PERMIT } from "graphql/mutations";
import usePermit from "hooks/use-permit";
import useOcProperty from "hooks/use-oc-property";

const MyHROwadminLoginPage = ({ onLogin }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { data: permit, error: permitError } = usePermit();

  /** Email states */
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(false);

  /** Password states */
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  /** Button state */
  const [submitButtonState, setSubmitButtonState] = useState(false);

  /** Validator stuff */
  const validator = new Validator();
  const schema = {
    email: {
      type: "email",
      messages: {
        email: t("ERROR_MESSAGE_WRONG_EMAIL_FORMAT"),
        emailEmpty: t("ERROR_MESSAGE_EMAIL_IS_REQUIRED"),
      },
    },
    password: {
      type: "string",
      min: 1,
      messages: {
        string: "Nem megfelelő jelszó!",
        stringMin: t("ERROR_MESSAGE_PASSWORD_IS_REQUIRED"),
      },
    },
  };
  const check = validator.compile(schema);

  const [requestPermit, { data: requestedPermit }] = useMutation(
    REQUEST_PERMIT,
    {
      onCompleted: (data) => {
        setEmailErrorText(t("PERMIT_GRANTED"));
      },
      onError: (error) => {
        console.log({ error });
      },
    }
  );

  const [login] = useLazyQuery(OW_ADMIN_LOGIN, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.owLogin) {
        let loginResult = data?.owLogin;
        onLogin(loginResult);
        let { from } = location.state || { from: { pathname: "/" } };
        setSubmitButtonState(false);
        history.replace(from);
      }
    },
    onError: (error) => {
      let errors = error?.graphQLErrors;
      errors.map((item) => {
        if (item.message === "No user found!") {
          setEmailErrorText(t("ERROR_MESSAGE_NO_USER_FOUND"));
          setEmailError(true);
        }
        if (item.message === "Wrong password!") {
          setPasswordErrorText(t("ERROR_MESSAGE_WRONG_PASSWORD"));
          setPasswordError(true);
        }
        if (item.message === "User account has expired!") {
          setEmailErrorText(t("ERROR_MESSAGE_ACCOUNT_HAS_EXPIRED"));
          setEmailError(true);
        }
        return item;
      });
      setSubmitButtonState(false);
    },
  });

  const { data: passwordVisibilityData } = useOcProperty({
    resource: "system",
    key: "passwordVisibility",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValidated = check({ email, password });
    if (isValidated !== true) {
      isValidated.forEach((item) => {
        if (item.field === "email") {
          setEmailErrorText(item.message);
          setEmailError(true);
        }
        if (item.field === "password") {
          setPasswordErrorText(item.message);
          setPasswordError(true);
        }
      });
      return;
    }
    setSubmitButtonState(true);
    login({ variables: { email, password } });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRequestPermit = () => {
    requestPermit({ variables: { locale: i18n.language } });
  };


  return (
    <Box
      width="100%"
      height="100%"
      style={{ backgroundColor: "#cc0033" }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        width="400px"
        height="100%"
        style={{ backgroundColor: "white" }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box id="orgware-text-logo">
          <img
            alt="orgware-icon"
            src="/img/owLogoColored.png"
            style={{ width: 300 }}
          />
        </Box>
        <Typography
          variant="body1"
          style={{ color: "#cc0033", marginTop: "32px" }}
        >
          Orgware admin login
        </Typography>
        {permit?.permittedAt && !permitError && !permit?.cancelledAt ? (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box width="304px" paddingTop="38px">
              <TextField
                id="email"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                label={t("EMAIL")}
                variant="outlined"
                fullWidth
                error={emailError}
                onFocus={() => {
                  setEmailErrorText("");
                  setEmailError(false);
                }}
                helperText={emailError && emailErrorText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineIcon icon={emailIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="password"
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
                label={t("PASSWORD")}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth
                helperText={passwordErrorText}
                error={passwordError}
                onFocus={() => {
                  setPasswordErrorText("");
                  setPasswordError(false);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineIcon icon={keyIcon} />
                      <Box width="5px" />
                      {passwordVisibilityData === "true" &&
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                        >
                          {showPassword ? (
                            <VisibilityOff
                              style={{ height: "16px", width: "16px" }}
                            />
                          ) : (
                            <Visibility
                              style={{ height: "16px", width: "16px" }}
                            />
                          )}
                        </IconButton>
                      }
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: "36px" }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                disabled={submitButtonState}
                style={{
                  marginTop: "36px",
                }}
              >
                {t("LOGIN")}
              </Button>
            </Box>
          </form>
        ) : (
          <Box width="304px" paddingTop="36px">
            {requestedPermit && !permit?.cancelledAt && !permitError && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingBottom="24px"
              >
                <Typography>{t("PERMIT_REQUESTED")}</Typography>
              </Box>
            )}
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              onClick={handleRequestPermit}
            >
              {t("REQUEST_PERMIT")}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MyHROwadminLoginPage;
