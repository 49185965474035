/**
 * Filter table records with selected unique list
 * @author Pál Pintér
 * @copyright 2020 Orgware LLC
 */
import { makeStyles } from "@material-ui/styles";

/** Added custom styles to elements */
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    backgroundColor: theme.palette.gray30.main,
    borderRadius: 4,
    top: 50,
    zIndex: 10000,
  },
  line: {
    height: 1,
    padding: "0px 8px",
    backgroundColor: theme.palette.gray40.main,
  },
}));

export default useStyles;
