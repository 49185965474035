import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#C6CACC",
    borderRadius: "2px",
    marginLeft: "4px",
    paddingTop: "2px",
  },
  icon: {
    color: theme.palette.gray80.main,
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
}));

export default useStyles;
