export default function getFullName(employee) {
    if (!employee) return null;
    let prefixName = employee.prefixName ? employee.prefixName + " " : "";
    let lastName = employee.lastName ? employee.lastName + " " : "";
    let firstName = employee.firstName ? employee.firstName + " " : "";
    let middleName = employee.middleName ? employee.middleName + " " : "";
    let nameSuffix = employee.nameSuffix ? employee.nameSuffix + " " : "";

    let fullname = prefixName + lastName + firstName + middleName + nameSuffix;
    return fullname?.trim();
}
