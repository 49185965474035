import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "white",
    height: "48px",
  },
  linkItem: {
    height: "48px",
    clipPath: "polygon(0 0, 94% 0, 100% 100%, 0% 100%)",
  },
  emptyBox: {
    minWidth: "20px",
    maxWidth: "20px",
  },
}));

export default useStyles;
