import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import OcDatePicker from "oc/components/oc-date-picker";
import getDateFormatByLocale from "oc/utils/get-date-format-by-locale";

export default function OcValidToInputField({
  value,
  setValue,
  error,
  setError,
  helperText,
  setHelperText,
  errorInput,
  helperTextInput,
  onFocus,
}) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    return () => {
      setValue(null);
      setError(false);
      setHelperText("");
    };
  }, [setValue, setError, setHelperText]);

  function handleChange(date) {
    setValue(date);
  }

  return (
    <OcDatePicker
      error={error || errorInput}
      helperText={helperText || helperTextInput}
      id="user-profile-valid-to-field"
      label={t("VALID_TO")}
      minDate={() => new Date()}
      minDateMessage={t("Leave.notSelectable")}
      onChange={handleChange}
      value={value || null}
      onFocus={onFocus}
      format={getDateFormatByLocale(i18n.language)}
    />
  );
}
