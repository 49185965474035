import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_EMPLOYEE } from "graphql/queries";
import { EMPLOYEE_SUBSCRIPTION } from "graphql/subcriptions";
import usePolling from "hooks/use-polling";
import { crTempDataAtom } from "states";
import { useRecoilState } from "recoil";

export default function useEmployee({ employeeId }) {
  const [crTempData, setCrTempData] = useRecoilState(crTempDataAtom);

  const { data, loading, error, refetch } = useQuery(GET_EMPLOYEE, {
    fetchPolicy: "cache-and-network",
    skip: !employeeId,
    pollInterval: usePolling(1000 * 60),
    variables: { code: employeeId },
    onCompleted: (data) => {
      if (data?.employee && !crTempData) {
        setCrTempData(data?.employee);
      }
    },
  });

  const { error: subscriptionError } = useSubscription(EMPLOYEE_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  if (subscriptionError) {
    console.log({
      component: "useEmployee",
      errorDescription:
        "Error occured when subscribe data with EMPLOYEE_SUBSCRIPTION query",
      error: error,
    });
  }

  return { data: data?.employee, loading, error };
}
