import React from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useOcClientState from "hooks/use-oc-client-state-2";
import MyTimeGeneralSettingsCard from "my-time/components/my-time-general-settings-card";
import MyTimeTimePeriodSettingsCard from "my-time/components/my-time-time-period-settings-card";

const menuDef = [
  {
    id: "myTimeGeneralSettings",
    label: "GENERAL_SETTINGS",
  },
  {
    id: "myTimeTimePeriodSettings",
    label: "MY_TIME_TIME_PERIOD_SETTINGS",
  },
];

function PageSideMenu() {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useOcClientState(
    "myTimeSettingsActiveItem",
    menuDef[0].id
  );

  function handleSelect(itemId) {
    setSelectedItem(itemId);
  }

  return (
    <Box>
      {menuDef.map((item) => (
        <Box
          id={item.id}
          key={item.id}
          width="240px"
          height="40px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="40px"
          onClick={() => handleSelect(item.id)}
          style={
            selectedItem === item.id
              ? {
                  backgroundColor: "white",
                  borderLeft: "4px solid #cc0033",
                }
              : { cursor: "pointer" }
          }
        >
          <Box style={{ fontSize: "14px", letterSpacing: "0.25px" }}>
            {t(item.label)}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default function MyTimeAdminSettingsPage() {
  const [selectedItem] = useOcClientState(
    "myTimeSettingsActiveItem",
    menuDef[0].id
  );

  return (
    <OcPageTransition>
      <Box>
        <Box
          id="my-hr-admin-settings-title"
          height="80px"
          style={{ backgroundColor: "#cc0033" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="14px"
        >
          <Box style={{ color: "white", fontSize: "24px" }}>MyTime</Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <PageSideMenu />
          <Box id="my-time-admin-settings" flexGrow={1} padding="16px">
            {selectedItem === "myTimeGeneralSettings" && (
              <>
                <MyTimeGeneralSettingsCard />
              </>
            )}
            {selectedItem === "myTimeTimePeriodSettings" && (
              <>
                <MyTimeTimePeriodSettingsCard />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
