import gql from "graphql-tag";

export const UPDATE_USER_ROLE_BY_CONTRACT = gql`
  mutation updateUserRoleByContract(
    $roleId: String!
    $contract: String!
    $product: String!
    $role: String
    $roleFilters: RoleFilterInput
  ) {
    updateUserRoleByContract(
      roleId: $roleId
      contract: $contract
      product: $product
      role: $role
      roleFilters: $roleFilters
    ) {
      createdAt
    }
  }
`;
