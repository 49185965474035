import React from "react";
import PropTypes from "prop-types";
import LeaveLoadingItem from "my-leave/components/my-leave-chart-loading-item";
import useStyles from "./my-leave-chart-summary-loading.style";

const LeaveChartSummaryLoading = ({ centerTextLeft, centerTextTop }) => {
  const classes = useStyles({
    centerTextLeft,
    centerTextTop,
  });

  return (
    <div className={classes.root}>
      <div className={classes.summaryValue}>
        <LeaveLoadingItem width={35} height={35} />
      </div>
      <div className={classes.summaryLabel}>
        <LeaveLoadingItem width={75} height={20} />
      </div>
      <div className={classes.summaryType}>
        <LeaveLoadingItem width={75} height={20} />
      </div>
    </div>
  );
};

LeaveChartSummaryLoading.propTypes = {
  /**
   * Left value of the absolut positioned component
   */
  centerTextLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Top value of the absolut positioned component
   */
  centerTextTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LeaveChartSummaryLoading.defaultProps = {
  centerTextLeft: 50,
  centerTextTop: 50,
};

export default LeaveChartSummaryLoading;
