import React from "react";
import { Box, Typography } from "@material-ui/core";
import OcPrivateLayoutFooter from "oc/components/oc-private-layout-footer";
import useStyles from "../family-tax-credit-information/family-tax-credit-information.style";

const FamilyTaxCreditInformation = () => {
  const classes = useStyles();
  return (
    <Box>
      <Box padding="16px" style={{ backgroundColor: "#F1F3F4" }}>
        <Box>
          <Typography
            variant="h4"
            style={{
              fontSize: "34px",
              fontWeight: "400",
              color: "#5E6366",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Tájékoztató
          </Typography>
          <Box height="32px" />
          <Typography
            variant="h5"
            style={{
              fontSize: "24px",
              fontWeight: "400",
              color: "#212121",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Adóelőleg-nyilatkozat a családi kedvezmény és járulékkedvezmény
            érvényesítéséről
          </Typography>
          <Box height="32px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography variant="h5" className={classes.titles}>
                <b>Tudnivalók a nyilatkozathoz</b>
              </Typography>
              <Box height="32px" />
              <Typography variant="body1">
                <i>
                  Amennyiben a kedvezményre saját jogon jogosult, abban az
                  esetben kérjük, hogy a NAV által kiadott adóelőleg-nyilatkozat
                  dokumentumon adja be "papír alapon" kérelmét az illetékes
                  szakember vagy osztály részére (Bővebb információ itt
                  található: https://nav.gov.hu/ado/adoeloleg_nyilatkozat_2022)
                </i>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Az adóelőleg megállapításakor a családi kedvezményt a jogosult
                házastársak, élettársak – az összeg vagy a kedvezményezett
                eltartottak számának felosztásával – közösen is igénybe vehetik.
                Ha a családi kedvezményre ugyanazon kedvezményezett eltartott
                után többen jogosultak, illetve, ha a családi kedvezményt
                közösen veszik igénybe, akkor a nyilatkozatot közösen kell
                megtenni.
              </Typography>
              <Box height="16px" />
              <Typography variant="h6">
                <b>Kinek kell átadni ezt a nyilatkozatot?</b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Ha Ön igényli, hogy a <b>munkáltatója</b>, vagy összevonás alá
                eső
                <b> rendszeres jövedelmet juttató kifizetője</b> a
                járandóságaiból a családi kedvezmény figyelembevételével vonja
                le az adóelőleget, ezt a nyilatkozatot két példányban töltse ki
                és adja át munkáltatójának, kifizetőjének.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                A nyilatkozat egyik példányát a munkáltatónak (kifizetőnek),
                másik példányát pedig{" "}
                <b>
                  Önnek kell a bevallás benyújtásának évét követő 5. év végéig
                  megőriznie
                </b>
                . A munkáltató, kifizető a nyilatkozatot az adóéven belül, az
                átadást követő kifizetéseknél veszi figyelembe.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>
                  Az adóelőleg-nyilatkozatot a munkáltatónak és olyan
                  kifizetőnek is adható, aki összevonás alá eső, rendszeres
                  jövedelmet
                </b>{" "}
                juttat Önnek, például megbízási szerződés alapján.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Mielőtt a nyilatkozatot leadná, a nyilatkozat 7. pontjában az Ön
                kedvezményre jogosult házastársának vagy élettársának adatait is
                meg kell adnia. A kedvezményt a bejegyzett élettársak is igénybe
                vehetik (1.), ezért a <b>házastárs kifejezés alatt</b> a
                továbbiakban a<b> bejegyzett élettársat is érteni kell.</b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <i>
                  Ha Ön a családi kedvezményt jogalap nélkül veszi igénybe, és
                  emiatt az adóbevallásában 10 ezer forintot meghaladó
                  befizetési kötelezettsége, vagyis adóhátraléka keletkezik,
                  akkor ennek 12 százalékát különbözeti bírságként kell
                  megfizetnie az adóhátralékkal együtt.{" "}
                </i>
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Kinek jár a kedvezmény?</b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  Annak a magánszemélynek,{" "}
                  <b>aki jogosult a családi pótlékra</b>, ilyen például
                </li>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  a házastársként, élettársként együtt élő vér szerinti szülők,
                </li>
                <li>
                  a szülővel együtt élő élettárs is, aki az érintett gyermekkel
                  közös lakó- vagy tartózkodási hellyel rendelkezik, és a
                  szülővel élettársként legalább 1 éve szerepel az Élettársi
                  Nyilatkozatok Nyilvántartásában (a jogosultság jogcímeként
                  neki is az „a” jogcímkódot kell szerepeltetnie), vagy
                </li>
                <li>
                  aki élettársi kapcsolatát a szülővel a családi pótlék
                  kérelmezése előtt legalább egy évvel kiállított közokirattal
                  igazolja (a jogosultság jogcímeként neki is az „a” jogcímkódot
                  kell szerepeltetnie) (2.).
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  A gyermeket azonos időtartamban felváltva gondozó, közös
                  szülői felügyeletet gyakorló külön élő szülők a családi
                  pótlékra 50-50%-os arányban jogosultak a Cst. alapján.
                </li>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  A felváltva gondozott gyermek után a szülők a családi
                  kedvezményre is 50-50 százalékos arányban jogosultak, tehát
                  saját eltartottjaik számától függően 33 335 (a 66 670 fele),
                  66 665 (a 133 330 fele), vagy 110 000 (a 220 000 fele)
                  forintot érvényesíthetnek a felváltva gondozott gyermek után.
                </li>
                <li>
                  A kedvezményt egymás között nem érvényesíthetik közösen,
                  viszont a jelenlegi házastársukkal a rájuk vonatkozó
                  kedvezményt közösen is igénybe vehetik.
                </li>
                <li>
                  A közös háztartásban élő, családi kedvezményre nem jogosult
                  élettárssal a kedvezményt nem lehet év közben közösen
                  érvényesíteni, viszont az év végi adóbevallásban a kedvezmény
                  az élettárssal is megosztható.
                </li>
                <li>
                  Ha az egyik szülő részére folyósítják a családi pótlék 100
                  százalékát, akkor csak ő jogosult a családi kedvezményre 100
                  százalékban, a másik szülő nem tekinthető jogosultnak, ezért a
                  családi kedvezményt ő nem érvényesítheti.
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  <b>
                    A családi pótlékra jogosulttal közös háztartásban élő,
                    családi pótlékra nem jogosult házastárs.
                  </b>
                </li>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  Év közben is érvényesítheti a családi kedvezményt az a
                  magánszemély, aki nem jogosult ugyan családi pótlékra, de az
                  arra jogosult házastársával közös háztartásban él. Például, ha
                  a házastársak közül az egyik nevelőszülő, a vonatkozó
                  szabályok alapján csak ő jogosult a nevelt gyermek után
                  családi pótlékra, viszont házastársa is jogosult a házastársa
                  által nevelt gyermek után járó családi kedvezmény
                  érvényesítésére, így adóelőleg-nyilatkozatot tehet.
                </li>
                <li>
                  A közös háztartásban élő, családi kedvezményre nem jogosult
                  élettárssal a kedvezményt nem lehet év közben közösen
                  érvényesíteni, viszont az év végi adóbevallásban a kedvezmény
                  az élettárssal megosztható.
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  <b>
                    A várandós nő és a vele közös háztartásban élő házastársa.
                  </b>
                </li>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  A várandós nővel közös háztartásban élő élettárs a várandósság
                  ideje alatt nem jogosult a kedvezményre, így azt év közben az
                  élettársak nem érvényesíthetik közösen, viszont a várandós
                  nőnek járó kedvezményt a várandós nő a bevallásában
                  megoszthatja élettársával.
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  <b>
                    A családi pótlékra saját jogán jogosult gyermek, továbbá a
                    rokkantsági járadékban részesülő magánszemély.
                  </b>
                </li>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  Esetükben saját maga vagy a vele közös háztartásban élő
                  hozzátartozói közül közös döntéssel kiválasztott magánszemély
                  érvényesíthet családi kedvezményt. (Ettől a döntéstől év végén
                  a bevallás benyújtásakor el lehet térni.)
                </li>
                <li>
                  Hozzátartozónak kell tekinteni a gyermek szüleinek
                  hozzátartozóit is, így például az elhunyt szülő testvére is
                  érvényesítheti a kedvezményt a vele közös háztartásban élő
                  árván maradt gyermek után.
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  Az a magánszemély is érvényesítheti a családi kedvezményt, aki
                  <b>
                    {" "}
                    bármely külföldi állam jogszabálya alapján családi pótlékra,
                    rokkantsági járadékra vagy más hasonló ellátásra jogosult
                  </b>
                  , ha az egyéb feltételek teljesülnek.
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>
                  Ha a családi kedvezményre több magánszemély jogosult,
                  mindenképpen közösen kell nyilatkozatot tenniük, akkor is, ha
                  a kedvezményt teljes egészében a jogosultak egyike
                  érvényesíti.
                </b>{" "}
                Nem kell közös nyilatkozatot tenni a felváltva gondozott gyermek
                vér szerinti szüleinek, mert ők a kedvezmény 50-50 százalékát a
                másik szülőtől függetlenül érvényesíthetik. Ha az őket megillető
                kedvezményt jelenlegi házastársukkal közösen érvényesítik, akkor
                jelenlegi házastársukkal közös nyilatkozatot kell tenniük.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Mennyi kedvezmény jár?</b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>
                  A családi kedvezmény az Ön összevont adóalapját csökkenti.
                  (3.)
                </b>{" "}
                Ezzel a nyilatkozattal az adóelőleg alapja és összege is
                csökken.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                A családi kedvezmény összege az eltartottak számától függően{" "}
                <b>kedvezményezett eltartottanként havonta</b>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  ha egy eltartott van a családban, <b>66 670 forint,</b>
                </li>
                <li>
                  ha két eltartott van a családban, <b>133 330 forint,</b>
                </li>
                <li>
                  ha három vagy annál több eltartott van a családban,{" "}
                  <b>220 000 forint.</b>
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Ez a gyakorlatban azt jelenti, hogy a családi kedvezmény
                igénybevételével <b>kedvezményezett eltartottanként havonta</b>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  ha egy eltartott van a családban, <b>10 ezer forinttal,</b>
                </li>
                <li>
                  ha két eltartott van a családban, <b>20 ezer forinttal,</b>
                </li>
                <li>
                  ha három vagy több eltartott van a családban,{" "}
                  <b>33 ezer forinttal</b>
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>Kedvezményezett eltartott:</b>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  aki után a magánszemély a Cst. szerint családi pótlékra
                  jogosult,
                </li>
                <li>aki a családi pótlékra saját jogán jogosult,</li>
                <li>a rokkantsági járadékban részesülő személy,</li>
                <li>
                  a magzat a fogantatás 91. napjától a világra jöttét megelőző
                  hónapig az erről szóló orvosi igazolás alapján,
                </li>
                <li>
                  a bíróság döntése, egyezség, a családi pótlék folyósítójánál
                  tett közös nyilatkozat alapján felváltva gondozott gyermek
                  mindkét szülőnél, valamint a szülők házastársainál is.
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Ha a magánszemély azért érvényesíthet családi kedvezményt, mert
                külföldi állam jogszabálya alapján külföldön jogosult családi
                pótlékra, rokkantsági járadékra vagy más hasonló ellátásra,
                kedvezményezett eltartottként azt a gyermeket veheti figyelembe,
                aki után Magyarországon a Cst. megfelelő alkalmazásával a
                családi pótlékra való jogosultsága megállapítható lenne.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>Eltartott:</b>
              </Typography>
              <Typography variant="body1" className={classes.listItems}>
                <li>a kedvezményezett eltartott, </li>
                <li>
                  az, aki a családi pótlék összegének megállapítása
                  szempontjából figyelembe vehető vagy figyelembe vehető lenne,
                  akkor is,
                </li>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  ha a kedvezményezett eltartott után nem családi pótlékot,
                  hanem rokkantsági járadékot folyósítanak,
                </li>
                <li>
                  ha a kedvezményezett eltartott után családi pótlékot nem
                  állapítanak meg, például magzat esetében,
                </li>
                <li>
                  vagy a családi pótlék összegét a gyermekek száma nem
                  befolyásolja, például tartósan beteg gyermek után járó emelt
                  összegű családi pótlék.
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Példa: Egy családban négyen élnek: apa, anya, a nappali
                tagozatos egyetemista fiuk és az apa húga, aki rokkantsági
                járadékot kap. A rokkantsági járadékban részesülő személy után a
                testvére, az apa veszi igénybe a családi kedvezményt.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Miután az egyetemista is eltartottnak számít, a családban az
                eltartottak száma két fő. Emiatt a rokkantsági járadékban
                részesülő személy után havi 133 330 forint családi kedvezményt
                érvényesíthet az apa.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>A tartósan beteg, illetve súlyosan fogyatékos</b>{" "}
                kedvezményezett eltartott gyermek után a családi kedvezmény havi
                összege{" "}
                <b>66 670 forinttal emelt összegben vehető igénybe (4.).</b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Tartósan beteg, illetve <b>súlyosan fogyatékos személy (5.)</b>{" "}
                az, aki:
              </Typography>
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  tizennyolc évesnél fiatalabb, és a külön jogszabályban (6.)
                  meghatározott betegsége, illetve fogyatékossága miatt állandó
                  vagy fokozott felügyeletre, gondozásra szorul,
                </li>
                <li>
                  tizennyolc évesnél idősebb, és a tizennyolcadik életévének
                  betöltése előtt munkaképességét legalább 67 százalékban
                  elvesztette, legalább 50 százalékos mértékű egészségkárosodást
                  szenvedett, vagy akinek egészségi állapota a rehabilitációs
                  hatóság minősítése alapján a tizennyolcadik életévének
                  betöltése előtt sem haladja meg az 50 százalékos mértéket, és
                  ez az állapot legalább egy éve tart, vagy előreláthatólag
                  legalább egy évig fennáll,
                </li>
                <li>
                  a 18. életévét betöltötte, és a magasabb összegű családi
                  pótlék helyett fogyatékossági támogatásban részesül (7.).
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Példa: Egy anya két kiskorú gyermeket nevel, akik közül az egyik
                súlyosan fogyatékos. A családi kedvezmény havi összege 333 330
                forint, mert a gyermekek után személyenként havi 133 330 forint
                családi kedvezményt érvényesíthet és a beteg gyermek után még
                további 66 670 forintot.
              </Typography>
              <Box height="16px" />
              <Typography variant="h6">
                <b>Milyen jövedelmet érint?</b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                A kedvezmény az összevont adóalapba tartozó jövedelmekre vehető
                igénybe, mint például a munkabér, adóköteles
                társadalombiztosítási ellátás, a gyermekgondozási díj, más havi,
                heti munkadíj, tiszteletdíj, személyes közreműködés ellenértéke,
                bérbeadásból származó jövedelem, egyéb összevonás alá eső
                jövedelem.
              </Typography>
              <Box height="16px" />
            </Box>
            <Box marginLeft="16px">
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (1.) A bejegyzett élettársi kapcsolatról, az ezzel összefüggő,
                  valamint az élettársi viszony igazolásának megkönnyítéséhez
                  szükséges egyes törvények módosításáról szóló 2009. évi XXIX.
                  törvény (a továbbiakban: Béktv.) 3. § (1) bekezdése alapján.
                </Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">(2.) A Cst. alapján.</Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (3.) A személyi jövedelemadóról szóló 1995. évi CXVII. törvény
                  (a továbbiakban: Szja tv.) 29/A. §-a.
                </Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (4.) Az Szja törvény 29/A. § (2a) bekezdése alapján.
                </Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (5.) A családok támogatásáról szóló 1998. évi LXXXIV. tv. (a
                  továbbiakban: Cst.) 4. § f) pontja alapján.
                </Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (6.) A magasabb összegű családi pótlékra jogosító
                  betegségekről és fogyatékosságokról szóló 5/2003. (II. 19.)
                  ESzCsM rendelet alapján.
                </Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (7.) Szja törvény 29/A. § (2a) bekezdése.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box height="16px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography variant="h5" className={classes.titles}>
                <b>Családi járulékkedvezmény</b>
              </Typography>
              <Box height="32px" />
              <Typography variant="body1">
                A magánszemély az őt megillető családi kedvezményt a
                biztosítottként (8.) fizetendő társadalombiztosítási járulékból
                is elszámolhatja, ha azt az szja-alapból, adóelőleg-alapból nem
                lehetett teljes összegben érvényesíteni.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                A családi járulékkedvezmény összege a családi kedvezmény
                adóalappal, adóelőleg-alappal szemben nem érvényesített részének
                a 15 százaléka, de legfeljebb a tb-járulék összege.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                A családi járulékkedvezményt a munkáltató, kifizető
                automatikusan figyelembe veszi, ha a magánszemély a családi
                kedvezmény érvényesítéséről nyilatkozik. A magánszemély azonban
                kérheti, hogy járulékkedvezményt a munkáltató, kifizető ne
                érvényesítsen, és a családi kedvezményre jogosító keretből csak
                azt az összeget számolja el, ami az szja-előlegből
                érvényesíthető.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Példa: Egy egyedülálló magánszemély 3 kiskorú gyermeke után kéri
                a családi kedvezményt, akkor havonta háromszor 220 000 forint,
                együttesen 660 000 forint családi kedvezményt érvényesíthet. Ha
                egy hónapban 350 ezer forint a munkabére, a munkáltató a 660 000
                forintnak a munkabért meghaladó részére, azaz 310 000 forintra
                érvényesíti a családi járulékkedvezményt. Ezért a munkáltató az
                adott hónapban a 310 000 forint 15 százalékának megfelelő
                összeget, azaz 46 500 forintot vesz figyelembe
                járulékkedvezményként, ezt az összeget nem vonja le
                tb-járulékként a magánszemély munkabéréből.
              </Typography>
              <Box height="16px" />
              <Typography variant="h6">
                <b>Adatváltozáskor mit kell tenni?</b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Ha a nyilatkozatban közölt adatok változnak,{" "}
                <b>Ön köteles haladéktalanul új nyilatkozatot tenni</b> – ezt az
                I. Blokk jobb felső sarkában lévő, <b>Módosított nyilatkozat</b>{" "}
                feliratú kódkockában kell X-szel jelölnie.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>Nem kell új nyilatkozatot tenni,</b> ha az adóévben a
                magánszemély a{" "}
                <b>családi kedvezményt már a magzat után is érvényesítette.</b>{" "}
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Ha év közben új munkáltatónál helyezkedik el és új nyilatkozatot
                tesz, az <b>nem számít módosított nyilatkozatnak. </b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                A családi kedvezményről és családi járulékkedvezményről bővebb
                tájékoztatást olvashat a NAV honlapján (www.nav.gov.hu) az „Szja
                adóalap-kedvezmények” című 73. számú információs füzetben.{" "}
              </Typography>
              <Box height="16px" />
              <Typography variant="h6">
                <b>
                  Külföldi magánszemély milyen feltételek mellett érvényesítheti
                  a kedvezményt?
                </b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Önnek csak akkor jár a családi kedvezmény, ha azonos vagy
                hasonló <b>kedvezményt</b> ugyanarra az időszakra{" "}
                <b>másik államban</b> – ahol önálló és nem önálló
                tevékenységéből, nyugdíjból és más, hasonló, a korábbi
                foglalkoztatásból származó jövedelme megadóztatható -
                figyelemmel a kettős adóztatás elkerüléséről szóló nemzetközi
                egyezmények rendelkezéseire is –{" "}
                <b>nem vett és nem is vesz igénybe.</b>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                A külföldi adóügyi illetőségű magánszemély a kedvezményt
                Magyarországon csak akkor érvényesítheti, ha az adóévben
                megszerzett összes jövedelmének – ideértve a Magyarországon nem
                adóztatható jövedelmet is –{" "}
                <b>75 százaléka Magyarországon adózik. </b>
                Az adóévben megszerzett összes jövedelembe beleszámít az önálló
                és nem önálló tevékenységből származó jövedelem – ideértve
                különösen a vállalkozói jövedelmet és a vállalkozói
                osztalékalapot vagy az átalányadó alapját – valamint a nyugdíj
                és más hasonló, a korábbi foglalkoztatásból megszerzett
                jövedelem, függetlenül attól, hogy mely országban adókötelesek.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>
                  Ha Ön külföldi adóügyi illetőségű, a külföldi magánszemélyek
                  kiegészítő nyilatkozatát is ki kell töltenie a családi
                  kedvezmény igénybevételéhez. Adóazonosító jelet ebben az
                  esetben is meg kell adni.
                </b>
              </Typography>
            </Box>
            <Box marginLeft="16px">
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (8.) A társadalombiztosítás ellátásaira jogosultakról,
                  valamint ezen ellátások fedezetéről szóló 2019. évi CXXII.
                  törvény 6. §-a szerint biztosított.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box height="16px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography variant="h5" className={classes.titles}>
                <b>Az adóelőleg-nyilatkozat kitöltése</b>
              </Typography>
              <Box height="32px" />
              <Typography variant="body1">
                Ön az első részben nyilatkozik munkáltatójának, kifizetőjének az
                érvényesíteni kívánt családi kedvezményről, valamint itt kell
                részleteznie az eltartottak, kedvezményezett eltartottak adatait
                is.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>Az 1. ponthoz:</b> az eltartottak neve mellett az eltartotti
                minőséget (EM) – azaz, hogy miként kell őt a családi kedvezmény
                számításánál figyelembe venni – külön kódkockában kell jelölni.
                Magzatnál név helyett a „magzat” kifejezést tüntesse fel, és az
                „1” kódot (kedvezményezett eltartott) jelölje! A magzat(ok) a
                várandósság orvosi igazolása alapján a fogantatástól számított
                91. nap hónapjától az élve születés napját megelőző hónapig
                vehetők figyelembe. Az orvosi igazolást a nyilatkozathoz nem
                kell csatolni, de elévülési időig, azaz a bevallás benyújtásának
                évét követő 5. év végéig meg kell azt őrizni.{" "}
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Az eltartottak neve mellett a következő eltartotti minőséget
                jelölő kódok közül a megfelelőt kell szerepeltetni:
              </Typography>
              <Typography variant="body1" className={classes.listItems}>
                <li>„1” kód – Kedvezményezett eltartott</li>
                <li>„2” kód – Eltartott</li>
                <li>„3” kód – Felváltva gondozott gyermek</li>
                <li>
                  „4” kód – Tartósan beteg, illetve súlyosan fogyatékos személy
                  (gyermek)
                </li>
                <li>
                  „5” kód – Felváltva gondozott tartósan beteg, illetve súlyosan
                  fogyatékos személy (gyermek)
                </li>
                <li>„0” kód – Kedvezménybe nem számító személy</li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                A „4” és „5” kód szerinti tartósan beteg, illetve súlyosan
                fogyatékos személyen a Cst.-ben meghatározott tartósan beteg,
                illetve súlyosan fogyatékos személyt kell érteni. Tartósan
                beteg, illetve súlyosan fogyatékos személynek minősül az a 18.
                életévét betöltött magánszemély is, aki a magasabb összegű
                családi pótlék helyett fogyatékossági támogatásban részesül.
              </Typography>
              <Box height="32px" />
              <Typography variant="body1">
                Akkor kell valakit kedvezménybe nem számítóként „0” kóddal
                szerepeltetni, ha az eredetileg adott nyilatkozatban őt
                eltartottként, vagy kedvezményezett eltartottként tüntették fel,
                azonban olyan változás következik be az eltartotti minőségében,
                amely alapján őt a családi kedvezmény szempontjából a jövőben
                nem kell figyelembe venni. Azért, hogy a munkáltatónak tudomása
                legyen a valós helyzetről, azaz, hogy a kérdéses magánszemély
                mikortól nem számít eltartottnak, módosított nyilatkozatában a
                változás időpontját mindenképpen meg kell jelölnie, valamint a
                kedvezménybe nem számító személyt is szerepeltetnie kell „0”
                kóddal.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Az összes eltartott nevét, adóazonosító jelét meg kell adni,
                kivéve a magzatét,{" "}
                <b>adóazonosító jel nélkül a nyilatkozat érvénytelen</b>. Az
                eltartottak mellett meg kell jelölni, hogy adott eltartott után
                a nyilatkozatot adó magánszemély milyen jogcímen jogosult a
                kedvezmény érvényesítésére.{" "}
              </Typography>
              <Typography variant="body1">
                <b></b>Jogosultsági jogcímek (JJ):
              </Typography>
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  „a” kód – Gyermek után családi pótlékra jogosult, vagy ilyen
                  jogosulttal közös háztartásban élő házastárs
                </li>
                <li>
                  „b” kód – Várandós nő vagy vele közös háztartásban élő
                  házastársa
                </li>
                <li>
                  „c” kód – Családi pótlékra saját jogán jogosult, vagy ilyen
                  jogosulttal közös háztartásban élő hozzátartozó (ideértve a
                  gyermek szüleinek hozzátartozóit is)
                </li>
                <li>
                  „d” kód – Rokkantsági járadékban részesülő, vagy ilyen
                  jogosulttal közös háztartásban élő hozzátartozó (ideértve a
                  gyermek szüleinek hozzátartozóit is)
                </li>
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Ha év közben olyan változás következik be, amely szükségessé
                teszi az adóelőleg-nyilatkozat módosítását, akkor az ugyanazon
                munkáltatónak adott nyilatkozaton a „Módosított nyilatkozat”
                jelölésére szolgáló négyzetben ezt X-szel kell jelölnie!{" "}
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                A módosított nyilatkozatban megjelölt változás szerinti családi
                kedvezmény legkorábban a nyilatkozattétel keltét követő
                bérszámfejtésnél vehető figyelembe, függetlenül attól, hogy egy
                korábbi dátumot jelöltek meg a változás bekövetkezésének
                időpontjaként. A változás időpontjának megjelölése azt a célt
                szolgálja, hogy a munkáltató a valóságnak megfelelő adatot
                tudjon szolgáltatni a havi adó- és járulékbevallásában arról,
                hogy Ön mely eltartottak után, milyen jogcímen és mikortól
                jogosult, vagy nem jogosult családi kedvezményre. Nem köteles
                azonban a munkáltató arra, hogy a változás időpontjára
                visszamenően rendezze a családi kedvezményt, ha Ön nem a
                változás bekövetkezésének hónapjában, hanem egy későbbi
                időpontban módosítja adóelőleg-nyilatkozatát.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>A 2. pontban</b> kérjük, jelölje az arra szolgáló négyzetben
                X-szel, hogy a családi kedvezményt egyedül, vagy jogosultnak
                minősülő házastársával, élettársával közösen kívánja
                érvényesíteni!
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>A 3. ponthoz:</b> itt kell nyilatkozni arról, hogy jogosult a
                családi kedvezményt Magyarországon érvényesíteni, külföldi
                államban a jövedelme után azonos vagy hasonló kedvezményt nem
                vesz vagy vett igénybe.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>A 4. ponthoz:</b> Ön e pontban kérheti munkáltatóját, hogy az
                alább megjelölt családi kedvezményt kizárólag az szja-előleg
                alapjával érvényesítse, a járulékból ne. Ha e nyilatkozatot
                megteszi, a munkáltató nem fogja érvényesíteni a
                járulékkedvezményt még akkor sem, ha az 5. pontban megjelölt
                családi kedvezmény teljes összegű igénybevételére az Ön
                adóelőleg-alapja nem nyújt fedezetet.{" "}
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>Az 5. pontban</b> nyilatkozik arról, hogy a családi
                kedvezmény érvényesítését kéri. Az érvényesíteni kívánt családi
                kedvezményt meghatározhatja mind az összeg [a) sor], mind a
                kedvezményezett eltartottak számának [b) sor] megjelölésével.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Ha a kedvezményezett eltartottak között van tartósan beteg,
                illetve súlyosan fogyatékos személy (eltartotti minősítése „4”,
                vagy „5”), akkor a kedvezményt csak az összeg megjelölésével
                lehet felosztani a jogosultak között.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Ha a megjelölt összeg vagy a feltüntetett kedvezményezett
                eltartottak száma alapján járó összeg magasabb, mint az Ön
                jövedelme, például munkabére, akkor a különbözetet a kifizető
                járulékkedvezményként veszi figyelembe feltéve, ha Ön
                biztosított és nem tett nyilatkozatot a 4. pontban.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Ha a nyilatkozat a) sorában a kedvezményezett eltartottak száma
                alapján kiszámítható családi kedvezmény maximális összegénél
                kisebb összeget, vagy b) sorában a kedvezményezett eltartottak
                számánál kisebb számot jelöl meg, a fennmaradó kedvezményt
                jogosultnak minősülő házastársa, élettársa ugyancsak a
                munkáltatójának, kifizetőjének adott nyilatkozattal
                érvényesítheti.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>A második részben</b> a sorokat az Ön házastársa vagy
                élettársa adataival kell kitölteni, ha közös nyilatkozatot kell
                tenniük. Ha a házastársának, élettársának van adóelőleget
                megállapító munkáltatója, rendszeres bevételt juttató
                kifizetője, akkor itt kell feltüntetni annak megnevezését,
                adószámát.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                Nem kell közös nyilatkozatot tenni azzal a volt házastárssal,
                élettárssal, aki a felváltva gondozott gyermekük után
                érvényesíti az 50-50 százalékos kedvezményt.
              </Typography>
              <Box height="16px" />
              <Typography variant="body1">
                <b>A harmadik részt</b> az Ön munkáltatójának (kifizetőjének)
                kell kitöltenie.
              </Typography>
            </Box>
            <Box height="6px" />
            <Box marginLeft="16px">
              <Box className={classes.smallBgBoxWithoutBg}></Box>
            </Box>
          </Box>
        </Box>
        <Box height="6px" />
      </Box>
      <OcPrivateLayoutFooter />
    </Box>
  );
};

export default FamilyTaxCreditInformation;
