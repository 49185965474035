import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import UnlockForm from "my-hr/components/my-hr-unlock-form";
import UnlockFormSSO from "my-hr/components/my-hr-unlock-form-sso";
import useEmployee from "hooks/use-myhr-employee";
import useProperty from "hooks/use-oc-property";
import { PROPERTY_SUBSCRIPTION } from "graphql/subcriptions";
import { useSubscription } from "@apollo/client";
import Avatar from "oc/components/oc-avatar";
import getFullname from "oc/utils/get-fullname";
import useCurrentUserState from "hooks/use-current-user-state";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SessionLocked({
  sessionLocked,
  openSession,
  smallScreen,
  onLogout,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  let { employeeCode, emailAddress, ldapUser } = useCurrentUserState();
  const { data } = useEmployee({ employeeId: employeeCode });

  let fullname = "";
  if (data) {
    fullname = getFullname(data);
  }

  const { data: loginTypeData } = useProperty({
    resource: "system",
    key: "loginType",
  });

  useSubscription(PROPERTY_SUBSCRIPTION, {
    variables: {
      resource: "system",
      key: "loginType",
    },
  });

  const isSSO = loginTypeData === "sso" ? true : false;

  return (
    <Dialog
      open={sessionLocked ? sessionLocked : false}
      scroll={"body"}
      fullScreen={smallScreen}
      TransitionComponent={Transition}
      //disableBackdropClick
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return false;
        }
      }}
      BackdropProps={{
        style: {
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.7",
          backgroundImage: "url(/img/loginBg.jpg)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundBlendMode: "darken",
        },
      }}
    >
      <Box
        width={smallScreen ? "auto" : "480px"}
        height={smallScreen ? "100%" : "auto"}
        padding={smallScreen ? "0px 22px" : "36px 22px"}
        id="session-locked-dialog-content"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Box
          id="inner-content"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box
            id="avatar-container"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            paddingRight="24px"
          >
            <Avatar
              employee={data?.code}
              style={{ width: "72px", height: "72px" }}
            />
          </Box>
          <Box>
            <Box paddingBottom="28px">
              <Typography variant="h6">{t("TITLE_UNLOCK")}</Typography>
            </Box>

            <Box color={theme.palette.gray80.main}>
              <Typography>{t("TEXT_UNLOCK")}</Typography>
            </Box>
          </Box>
        </Box>
        <Box paddingTop="32px">
          {isSSO ? (
            <UnlockFormSSO
              onSubmit={openSession}
              smallScreen={true}
              buttonLabel={t("UNLOCK")}
              username={ldapUser}
            />
          ) : (
            <UnlockForm
              onSubmit={openSession}
              smallScreen={true}
              buttonLabel={t("UNLOCK")}
              email={emailAddress}
            />
          )}
          <Box
            onClick={onLogout}
            style={{
              color: "#cc0033",
              fontSize: 16,
              letterSpacing: 0.44,
              cursor: "pointer",
              textAlign: "center",
              paddingTop: "36px",
            }}
          >
            {t("UnlockSession.escapeText").replace("%fullname%", fullname)}
          </Box>
          <Box paddingTop="24px">
            <Button fullWidth onClick={onLogout}>
              {t("LOGOUT")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
