import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { GET_CONTRACTS_ADMIN_LIST } from "graphql/queries";
import { useQuery } from "@apollo/client";
import usePolling from "hooks/use-polling";
import FilterItem from "oc/components/oc-filter-item";
import Grid from "@material-ui/core/Grid";
import useFiltersData from "hooks/use-filters-data";
import useQueryParams from "hooks/use-query-params";
import useFilterChange from "hooks/use-filter-change";

export default function MyHrEmployeeFilter({ role, onUpdateRole }) {
  let filterType = "contract";

  const entity = { contracts: role?.roleFilters?.contract || [] };
  const stateId = "myHrEmployeeFilter";
  const { search, setSearch, setPageSize, queryParams } = useQueryParams({
    stateId,
    entity,
    product: role?.product,
    role: role?.role,
    roleFilters: role?.rolefilters,
    contract: role?.contract
  });

  const {
    data: rawData,
    loading,
    fetchMore,
  } = useQuery(GET_CONTRACTS_ADMIN_LIST, {
    fetchPolicy: "no-cache",
    pollInterval: usePolling(1000 * 60),
    notifyOnNetworkStatusChange: true,
    variables: queryParams,
  });

  const data = rawData?.contractsAdminList;
  const {
    availableItems,
    selectedItems,
    handleFetchMore,
    handleSearch,
    handleClearSearch,
  } = useFiltersData({
    data,
    fetchMore,
    setSearch,
    setPageSize,
    contract: role?.contract,
    product: role?.product,
    role: role?.role,
    filterType
  });

  const { handleAddFilter, handleRemoveFilter } = useFilterChange({
    role,
    onUpdateRole,
  });

  return (
    <OcErrorBoundary>
      <Grid item xs={6}>
        <FilterItem
          filterType={filterType}
          selectedItems={selectedItems || []}
          availableItems={availableItems}
          search={search}
          onSearch={handleSearch}
          onClearSearch={handleClearSearch}
          onAddFilter={handleAddFilter}
          onRemoveFilter={handleRemoveFilter}
          onLoading={loading}
          onFetchMore={handleFetchMore}
          showLists={true}
        />
      </Grid>
    </OcErrorBoundary>
  );
}
