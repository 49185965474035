import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const taxDeclarationPersonalState = atom({
  key: "taxDeclarationPersonalState",
  default: {
    dateOfDeclaration: new Date(),
    isModifiedDeclaration: false,
    disabledStateFrom: "",
    disabledStateTo: "",
    isFinal: false,
    disabilityPension: false,
    disabilityPensionNumber: "",
    disabilitySupport: false,
    disabilitySupportNumber: "",
    applyToWithdrawal: false,
    noRequestRelief: false,
  },
  effects_UNSTABLE: [persistAtom],
});
