import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./oc-info-card-item.style";
//import { useTheme } from "@material-ui/styles";
//import { InlineIcon } from "@iconify/react";
//import pencilIcon from "@iconify/icons-mdi/pencil";
import Tooltip from "@material-ui/core/Tooltip";

export default function OcInfoCardItem({
  label,
  value,
  translation,
  type,
  onEdit,
  editable = false,
  isEditButtonDisabled,
}) {
  const classes = useStyles();
  //const theme = useTheme();

  let effectiveValue = value || "";
  if (value === "not_defined") {
    effectiveValue = "";
  }

  //const handleEdit = () => {
  //if (isEditButtonDisabled) return null;
  //onEdit(type);
  //};

  function getTooLongValueTooltip() {
    if (value?.length > 30) {
      return (
        <Tooltip title={value} placement="top">
          <Box className={classes.value}>{effectiveValue}</Box>
        </Tooltip>
      );
    }
    return <Box className={classes.value}>{effectiveValue}</Box>;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.label}>{label}</Box>
      {getTooLongValueTooltip()}
      <Box width="10px" />
      {editable && (
        <Box
        //onClick={handleEdit}
        ></Box>
      )}
    </Box>
  );
}
