import React from "react";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import arrowLeftIcon from "@iconify/icons-mdi/arrow-left";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

export default function MyHrBackToPage({ label, onBack }) {
    const theme = useTheme();
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            height="64px"
            bgcolor={theme.palette.primary.main}
            borderRadius="8px"
            padding="0px 22px"
            marginBottom="16px"
        >
            <Box
                color={theme.palette.white.main}
                height="24px"
                lineHeight="24px"
                onClick={onBack}
                id="btGoBack"
            >
                <InlineIcon
                    icon={arrowLeftIcon}
                    style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                        color: theme.palette.white.main,
                    }}
                />
            </Box>
            <Box>
                <Typography
                    style={{ color: theme.palette.white.main }}
                    variant="h4"
                >
                    {label}
                </Typography>
            </Box>
            <Box width="24px" />
        </Box>
    );
}
