import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import OcDownload from "oc/components/oc-download";

const MyCafeteriaApplicationsCard = ({ example }) => {
  const { t } = useTranslation();
  return (
    <Box id="my-cafeteria-benefits-card-card-statements">
      <OcCard label={t("APPLICATIONS")}>
        <Box>
          <OcDownload
            id="my-cafeteria-benefits-card-szep"
            label="SZÉP kártya nyilatkozat"
            actionIconType="url"
            href={"#/my-cafeteria/szep-card-application"}
            isSmall={true}
          />
          <Box height="32px" />
          <OcDownload
            id="my-cafeteria-benefits-card-vbk"
            label="VBK nyilatkozat"
            actionIconType="url"
            href={"#/my-cafeteria/vbk-application"}
            isSmall={true}
          />
          <Box height="8px" />
        </Box>
      </OcCard>
    </Box>
  );
};

MyCafeteriaApplicationsCard.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaApplicationsCard.defaultProps = {
  example: "example",
};

export default MyCafeteriaApplicationsCard;
