import React from "react";
import { Box, Typography } from "@material-ui/core";
import OcPrivateLayoutFooter from "oc/components/oc-private-layout-footer";
import useStyles from "../family-tax-credit-information/family-tax-credit-information.style";

const UnderTwentyFiveTaxCreditInformation = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box padding="16px" style={{ backgroundColor: "#F1F3F4" }}>
        <Box>
          <Typography
            variant="h4"
            style={{
              fontSize: "34px",
              fontWeight: "400",
              color: "#5E6366",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Tájékoztató
          </Typography>
          <Box height="32px" />
          <Typography
            variant="h5"
            style={{
              fontSize: "24px",
              fontWeight: "400",
              color: "#212121",
              display: "flex",
              justifyContent: "center",
            }}
          >
            A 25 év alatti fiatalok kedvezményének részben vagy egészben történő
            mellőzéséről szóló nyilatkozatról{" "}
          </Typography>
          <Box height="32px" />
          <Box className={classes.bgBox}>
            <Typography variant="h5" className={classes.titles}>
              <b>Tudnivalók a nyilatkozathoz</b>
            </Typography>
            <Box height="32px" />
            <Typography variant="body1">
              Ha Ön jogosult a 25 év alatti fiatalok kedvezményére,{" "}
              <b>
                akkor azt a munkáltatója, kifizetője mindaddig automatikusan
                figyelembe veszi, amíg nem nyilatkozik annak részben vagy
                egészben történő mellőzéséről.
              </b>
              Nyilatkozni akkor kell, ha döntése szerint nem kéri a kedvezmény
              figyelembevételét, vagy csak a kedvezmény egy részének a
              figyelembevételét kéri az adóelőleg megállapításakor.
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>Kinek kell átadni ezt a nyilatkozatot?</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b>
                Ahhoz, hogy a kedvezmény érvényesítését munkáltatója, kifizetője
                részben vagy egészben mellőzze, ezt a nyilatkozatot két
                példányban kell átadni neki.
              </b>{" "}
              A nyilatkozat egyik példányát a munkáltatónak (kifizetőnek), másik
              példányát pedig Önnek kell az adóbevallás elkészítéséhez szükséges
              bizonylatokkal együtt az elévülési időn belül, azaz a bevallás
              benyújtásának évét követő 5. év végéig megőriznie.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Ha Ön nem tesz nyilatkozatot az érvényesítés teljes vagy részleges
              mellőzéséről, és ezért az adóbevallásában 10 ezer forintot
              meghaladó befizetési kötelezettsége keletkezik, akkor ennek 12
              százalékát különbözeti-bírságként kell megfizetnie az
              adóhátralékával együtt.
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>Mennyi kedvezmény jár?</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A 25 év alatti fiatalok kedvezménye a 25 életévét be nem töltött
              fiatal összevont adóalapjába tartozó egyes jövedelmeit csökkenti.
              2024-ben a kedvezmény összege jogosultsági hónaponként 576 601
              forint, ami 86 490 forint adómegtakarítást jelent.
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>Milyen jövedelmet érint?</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A kedvezménnyel csökkenthető például a bérjövedelem, a megbízási
              díj, az őstermelői jövedelem, az egyéni vállalkozó átalányban
              megállapított jövedelme, vagy vállalkozói kivétje.
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>Adatváltozáskor mit kell tenni?</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Ha a nyilatkozatban közölt adatok változnak,{" "}
              <b>Ön köteles haladéktalanul új nyilatkozatot tenni,</b> vagy a
              korábbi nyilatkozatot visszavonni.
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>
                Külföldi magánszemély milyen feltételek mellett érvényesítheti a
                kedvezményt?
              </b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Önnek csak akkor jár a 25 év alatti fiatalok kedvezménye, ha
              azonos vagy hasonló <b>kedvezményt</b> ugyanarra az időszakra{" "}
              <b>másik államban nem vett és nem is vesz igénybe.</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A külföldi adóügyi illetőségű magánszemély a kedvezményt
              Magyarországon csak akkor érvényesítheti, ha az adóévben
              megszerzett összes jövedelmének – ideértve a Magyarországon nem
              adóztatható jövedelmet is –{" "}
              <b>75 százaléka Magyarországon adózik.</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b>
                Ha Ön külföldi adóügyi illetőségű, a „Kiegészítő nyilatkozat a
                külföldi adóügyi illetőségű magánszemélyek
                adóalap-kedvezményének érvényesítéséhez” nyilatkozatát ki kell
                töltenie és a munkáltató, kifizető részére átadnia a
                kedvezményének igénybevételéhez. Adóazonosító jelét ebben az
                esetben is meg kell adni.
              </b>
            </Typography>
            <Box height="25px" />
          </Box>
          <Box height="16px" />
          <Box className={classes.bgBox}>
            <Typography variant="h5" className={classes.titles}>
              <b style={{ textDecoration: "underline" }}>Példa:</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A 25 év alatti fiatalnak két munkáltatója van. Az egyik helyen
              havi 400 ezer forint a jövedelme, a másik munkaviszonyában havi
              200 ezer forintot keres. Jövedelmeinek összege külön-külön nem
              haladja a meg az adómentes összeghatárt, de együtt igen (600 000
              {" > "}
              576 601). A fiatalnak adóelőleg-nyilatkozatot kell adnia mindkét
              munkáltatójának, hogy az igénybe vett kedvezmény együttesen ne
              haladja meg a kedvezmény maximum összegét, de eldöntheti, hogy
              melyik jövedelméből mennyi kedvezményt szeretne igénybe venni.{" "}
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Például így is kérheti a kedvezmény figyelembevételét:{" "}
            </Typography>
            <Box height="25px" />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <table className={classes.table}>
                <tr>
                  <th className={classes.tr}></th>
                  <th className={classes.th}>I. eset</th>
                  <th className={classes.tr}>II. eset</th>
                  <th className={classes.tr}>III. eset</th>
                </tr>
                <tr className={classes.tr}>
                  <td>első munkáltató</td>
                  <td className={classes.tr}>400 000</td>
                  <td className={classes.tr}>376 601</td>
                  <td className={classes.tr}>370 000</td>
                </tr>
                <tr className={classes.tr}>
                  <td>második munkáltató</td>
                  <td className={classes.tr}>176 601</td>
                  <td className={classes.tr}>200 000</td>
                  <td className={classes.tr}>206 601</td>
                </tr>
              </table>
            </Box>
            <Box height="25px" />
            <Typography variant="body1">
              Azért kell mindkét munkáltatónak nyilatkozatot adni, mert ha
              munkabérén felül többletjövedelmet, például jutalmat kap, akkor a
              nyilatkozat nélkül a munkáltató arra is érvényesíti a kedvezményt.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Például a II. esetben, ha a második munkáltató 100 ezer forint
              jutalmat fizet a fiatalnak, és nem kapott arról nyilatkozatot,
              hogy 200 ezer forint felett ne vegye a figyelembe a kedvezményt,
              akkor azt a jutalomra is figyelembe veszi, ami jogosulatlan
              igénybevételt jelent.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Ha a fiatal csak az egyik munkáltatójának nyilatkozik arról, hogy
              a <b>kedvezmény mellőzését kéri,</b> akkor a másik munkáltatónak
              már nem kell nyilatkozatot adnia, hiszen az csak a kedvezmény
              mértékéig érvényesíti azt.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Ha a kedvezményt az adóelőleg megállapításakor nem veszik
              figyelembe, akkor azt a bevallásában utólag érvényesítheti, ha van
              olyan jövedelme, ami a kedvezmény alapját képezi.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A kedvezményről bővebb tájékoztatást olvashat a NAV honlapján
              (www.nav.gov.hu) az „Szja adóalap-kedvezmények” című 73. számú
              információs füzetben.{" "}
            </Typography>
            <Box height="25px" />
          </Box>
          <Box height="25px" />
        </Box>
      </Box>
      <OcPrivateLayoutFooter />
    </Box>
  );
};

export default UnderTwentyFiveTaxCreditInformation;
