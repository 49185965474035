import React from "react";
import Box from "@material-ui/core/Box";
import getFullname from "oc/utils/get-fullname";
import Typography from "@material-ui/core/Typography";
import Avatar from "oc/components/oc-avatar";
import { Transition } from "react-transition-group";
import { useTheme } from "@material-ui/core/styles";

export default function MyHrStickyPersonalDataHeader({
    pageWidth,
    employee,
    contract,
    trigger,
}) {
    const theme = useTheme();
    const duration = 250;

    const defaultStyle = {
        transition: `height ${duration}ms ease-in-out`,
        height: 0,
    };

    const transitionStyles = {
        entering: { height: "48px" },
        entered: { height: "48px" },
        exiting: { height: "0px" },
        exited: { height: "0px" },
    };

    let jobClassName =
        contract?.jobClass?.name.charAt(0).toUpperCase() +
            contract?.jobClass?.name.slice(1) || "undefined";

    return (
        <Transition in={trigger} timeout={duration}>
            {state => (
                <Box
                    id="my-hr-sticky-menu-data"
                    position="fixed"
                    height="48px"
                    bgcolor={theme.palette.gray20.main}
                    width={pageWidth}
                    zIndex="1110"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    style={{
                        overflow: "hidden",
                        ...defaultStyle,
                        ...transitionStyles[state],
                    }}
                >
                    <Box width="72px" paddingLeft="24px">
                        <Avatar
                            employee={employee?.code}
                            style={{
                                width: "48px",
                                height: "48px",
                            }}
                        />
                    </Box>
                    <Box
                        flexGrow={1}
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box style={{ overflow: "hidden" }}>
                            <Typography noWrap variant="h4">
                                {getFullname(employee)}
                            </Typography>
                        </Box>
                        <Box paddingLeft="24px" style={{ overfolow: "hidden" }}>
                            <Typography noWrap variant="h5">
                                {jobClassName}
                            </Typography>
                        </Box>
                    </Box>
                    <Box width="72px" minWidth="72px" />
                </Box>
            )}
        </Transition>
    );
}
