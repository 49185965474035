import React from "react";
import { Box } from "@material-ui/core";

const CorporateSignature = ({ orientation }) => {
    return (
        <>
            {orientation === "horizontal" ? (
                <>
                    <Box
                        style={{
                            fontFamily: "EB Garamond",
                        }}
                    >
                        Kelt: ...........................................
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                        marginRight="10px"
                    >
                        <Box>
                            ........................................................
                        </Box>
                        <Box
                            marginRight="50px"
                            style={{
                                fontFamily: "EB Garamond",
                            }}
                        >
                            Cégszerű aláírás
                        </Box>
                    </Box>
                </>
            ) : (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginLeft="20px"
                >
                    <Box
                        style={{
                            fontSize: "15px",
                            fontFamily: "EB Garamond",
                            display: "flex",
                        }}
                    >
                        Kelt:
                        <Box width="150px" textAlign="center" marginLeft="10px">
                            ...........................................
                        </Box>
                    </Box>
                    <Box
                        style={{
                            fontSize: "15px",
                            fontFamily: "EB Garamond",
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            Cégszerű aláírás:
                            <Box marginLeft="10px">
                                .............................................................
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default CorporateSignature;
