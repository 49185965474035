import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { Box, TextField, Collapse, InputAdornment } from "@material-ui/core";

const OcFormEditorTextInput = ({
  name,
  label,
  value,
  onChange,
  error,
  inputAdornment,
}) => {
  if (inputAdornment && value?.trim().startsWith("+36")) {
    value = value?.trim().replace("+36", "");
  }
  const { t } = useTranslation();
  const theme = useTheme();

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (name === "district") {
      if (/^\d*$/.test(newValue) && newValue.length <= 2) {
        onChange(event);
      }
    } else {
      onChange(event);
    }
  };
  return (
    <Box key={name} paddingTop="36px" height="60px">
      <TextField
        id={name}
        name={name}
        label={t(label)}
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleChange}
        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault(); }, { passive: false })}
        error={error ? true : false}
        helperText={name === "district" && !error && t("ACCEPTABLE_DISTRICT_FORMAT")}
        InputProps={
          inputAdornment && {
            startAdornment: (
              <InputAdornment position="start">{inputAdornment}</InputAdornment>
            ),
          }
        }
      />
      <Collapse
        in={error ? true : false}
        style={{
          color: theme.palette.primary.main,
          margin: "0",
          fontSize: "12px",
          marginTop: "3px",
          textAlign: "left",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.4px",
          paddingLeft: "14px",
          marginBottom: "-15px",
        }}
      >
        <Box>{t(error)}</Box>
      </Collapse>
    </Box>
  );
};

export default OcFormEditorTextInput;
