import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";

const StyledBadge = withStyles((theme) => ({
  badge: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "2px",
    fontFamily: "Roboto",
    fontSize: "10px",
    minWidth: "16px",
    width: "16px",
    height: "16px",
    paddingTop: "2px",
    fontWeight: 500,
  },
}))(Badge);

export default StyledBadge;
