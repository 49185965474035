import gql from "graphql-tag";

export const GET_TAX_DECLARATIONS_LIST = gql`
  query taxDeclarationList($page: Int, $pageSize: Int, $type: String) {
    taxDeclarationList(page: $page, pageSize: $pageSize, type: $type) {
      rows {
        tableId
        year
        createdAt
        state
        isModifiedDeclaration
        type
      }
      page
      pageSize
      total
      totalPages
    }
  }
`;
