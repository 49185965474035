import gql from "graphql-tag";

export const CREATE_TAX_DECLARATION_MOTHER_UNDER_30 = gql`
  mutation createTaxDeclarationMotherUnder30(
    $dateOfDeclaration: Date!
    $isModifiedDeclaration: Boolean!
    $isEligible: Boolean!
    $eligibleOnOwnChild: Boolean!
    $ownChildName: String
    $ownChildTaxNumber: String
    $eligibleOnFetus: Boolean!
    $pregnancy91thDayYear: String
    $pregnancy91thDayMonth: String
    $eligibleOnAdoptedChild: Boolean!
    $adoptedChildName: String
    $adoptedChildTaxNumber: String
    $applyForTaxRelief: Boolean!
    $applyForTaxReliefAllAmount: Boolean!
    $applyForTaxReliefAmount: Float
    $noRequestReliefFromMonth: String
  ) {
    createTaxDeclarationMotherUnder30(
      dateOfDeclaration: $dateOfDeclaration
      isModifiedDeclaration: $isModifiedDeclaration
      isEligible: $isEligible
      eligibleOnOwnChild: $eligibleOnOwnChild
      ownChildName: $ownChildName
      ownChildTaxNumber: $ownChildTaxNumber
      eligibleOnFetus: $eligibleOnFetus
      pregnancy91thDayYear: $pregnancy91thDayYear
      pregnancy91thDayMonth: $pregnancy91thDayMonth
      eligibleOnAdoptedChild: $eligibleOnAdoptedChild
      adoptedChildName: $adoptedChildName
      adoptedChildTaxNumber: $adoptedChildTaxNumber
      applyForTaxRelief: $applyForTaxRelief
      applyForTaxReliefAllAmount: $applyForTaxReliefAllAmount
      applyForTaxReliefAmount: $applyForTaxReliefAmount
      noRequestReliefFromMonth: $noRequestReliefFromMonth
    ) {
      result
      messages
      tableId
    }
  }
`;
