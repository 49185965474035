import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import useClientState from "hooks/use-oc-client-state";
import AccountIcon from "@iconify/icons-mdi/account";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import LeaveFilterCollapseHeader from "../leave-filter-collapse-header";
import Grid from "@material-ui/core/Grid";
import FilteredListCount from "../filtered-list-count";
import LeaveFilterMonthSelector from "../leave-filter-month-selector";
import useStyles from "./leave-filter-collapse.style";
import MyLeaveFilterDivider from "my-leave/components/my-leave-filter-divider";
import CompanyFilter from "my-leave/components/company-filter";
import OrgUnitFilter from "my-leave/components/org-unit-filter";
import WorkingPlaceFilter from "my-leave/components/working-place-filter";
import JobClassGroupFilter from "my-leave/components/job-class-group-filter";
import PayofficeFilter from "my-leave/components/payoffice-filter";
import EmployeeFilter from "my-leave/components/employee-filter";
import LinearProgress from "@material-ui/core/LinearProgress";
import OcFilterSwitch from "oc/components/oc-filter-switch";

export default function LeaveFilterCollapse({
  buttonDisable,
  currentDate,
  data,
  filterable,
  listCount,
  monthFiltering,
  onAddFilter,
  onClearFilters,
  onCurrentDateChange,
  onFilterableChange,
  onLoadQuery,
  onlyRequested,
  onRemoveFilter,
  onShowRemoteWorksChange,
  product,
  role,
  roleFilters,
  setOnlyRequested,
  showRemoteWorks,
  contract,
  examinerDasboard,
  loading,
}) {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useClientState(
    "leaveFilterCollapseExpanded",
    false
  );

  const classes = useStyles(expanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <OcErrorBoundary>
      <Box id="filter-container" className={classes.filterContainer}>
        <LeaveFilterCollapseHeader
          AccountIcon={AccountIcon}
          handleExpandClick={handleExpandClick}
          expanded={expanded}
        />
        <Collapse in={expanded} timeout="auto">
          <Box id="collapse-body" padding="0px 16px">
            {loading ? (
              <Box height="2px">
                <LinearProgress color="primary" />
              </Box>
            ) : (
              <Box height="2px" />
            )}

            <Box className={classes.topBorder} />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              padding="16px 0px"
            >
              {monthFiltering && (
                <LeaveFilterMonthSelector
                  currentDate={currentDate}
                  onCurrentDateChange={onCurrentDateChange}
                />
              )}
              {!examinerDasboard && (
                <>
                  <LeaveFilterStateSelector
                    onlyRequested={onlyRequested}
                    setOnlyRequested={setOnlyRequested}
                  />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flexStart"
                    alignItems="center"
                    padding="26px 0px 16px 0px"
                    width="100%"
                    marginLeft="3px"
                  >
                    <OcFilterSwitch
                      label={t("SHOW_OTHER_ABSENCES")}
                      checked={showRemoteWorks}
                      name="showRemoteWorks"
                      onChange={onShowRemoteWorksChange}
                    />
                  </Box>
                </>
              )}
              <Box id="selector-container" width="66%">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CompanyFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <OrgUnitFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <WorkingPlaceFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JobClassGroupFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PayofficeFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>
                </Grid>
                <Box height="16px" />
                <MyLeaveFilterDivider />
                <Box height="16px" />
                <Grid item xs={6} style={{ paddingRight: "8px" }}>
                  <EmployeeFilter
                    roleFilters={roleFilters}
                    onAddFilter={onAddFilter}
                    onRemoveFilter={onRemoveFilter}
                    product={product}
                    role={role}
                    contract={contract}
                  />
                </Grid>
                <Box minHeight="16px" />
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <FilteredListCount
                      listCount={listCount}
                      type="filter"
                      requetsCount={data?.allLeavesCount || 0}
                      allUsersCount={data?.allUsersCount || 0}
                    />
                  </Grid>
                </Grid>
                <Box
                  id="buttons-container"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  padding="26px 0px 10px 0px"
                >
                  <Button id="cancel-button" onClick={onClearFilters}>
                    {t("RESET")}
                  </Button>
                  <Box width="16px" />
                  <Button
                    id="filter-button"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={filterable || buttonDisable}
                    onClick={() => {
                      onFilterableChange(true);
                      onLoadQuery();
                    }}
                  >
                    {t("FILTERING")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </OcErrorBoundary>
  );
}

function LeaveFilterStateSelector({ onlyRequested, setOnlyRequested }) {
  const { t } = useTranslation();
  return (
    <Box
      id="state-slector"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      padding="25px 0px 0px 0px"
    >
      <ButtonGroup>
        <Button
          id="leave-filter-collapse-filtered"
          variant={onlyRequested ? "contained" : "outlined"}
          color={onlyRequested ? "primary" : ""}
          disableElevation
          onClick={() => setOnlyRequested(true)}
        >
          {t("TO_BE_ASSESSED")}
        </Button>
        <Button
          id="leave-filter-collapse-all"
          variant={!onlyRequested ? "contained" : "outlined"}
          color={!onlyRequested ? "primary" : ""}
          disableElevation
          onClick={() => setOnlyRequested(false)}
        >
          {t("ALL")}
        </Button>
      </ButtonGroup>
    </Box>
  );
}
