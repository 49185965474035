import React from "react";
import { Box } from "@material-ui/core";

const ApplicationPrintCardInfoItem = ({ label, value }) => {
    return (
        <>
            <Box display="flex">
                {label}
                <Box marginLeft="10px">{value}</Box>
            </Box>
        </>
    );
};

export default ApplicationPrintCardInfoItem;
