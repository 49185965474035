import React from "react";
import MaterialCard from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
//import OcCardLabel from "../oc-card-label/oc-card-label";
import Typography from "@material-ui/core/Typography";

export default function OcCard({ label, children, loading, error }) {
  return (
    <MaterialCard
      variant="outlined"
      style={{
        borderRadius: "4px",
        borderColor: error ? "#cc0033" : "white",
        overflow: "visible",
      }}
    >
      <Box
        style={{
          fontSize: "20px",
          letterSpacing: "0.15px",
          padding: "0px 16px",
          color: "#5E6366",
        }}
      >
        <Box
          style={{
            fontSize: "20px",
            letterSpacing: "0.15px",
            padding: "10px 16px",
            color: "#5E6366",
            borderBottom: loading ? "" : "2px solid #C6CACC",
          }}
        >
          <Typography className="oc-card-h6-title" variant="h6">
            {label}
          </Typography>
        </Box>
        {loading ? <LinearProgress color="primary" /> : <Box height="2px" />}
      </Box>
      <Box padding="16px 16px 16px 16px">
        <>{children}</>
      </Box>
    </MaterialCard>
  );
}
