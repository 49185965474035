import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import ChevronLeft from "@iconify/icons-mdi/chevron-left";
import ChevronRight from "@iconify/icons-mdi/chevron-right";
import moment from "moment";
import clsx from "clsx";
import useStyles from "./leave-filter-month-selector.style";

export default function LeeaveFilterMonthSelector({
    currentDate,
    onCurrentDateChange,
}) {
    const classes = useStyles();

    let monthLabel = moment(currentDate).format("YYYY. MMMM");

    function handlePrev() {
        onCurrentDateChange(moment(currentDate).subtract(1, "month").toDate());
    }

    function handleNext() {
        onCurrentDateChange(moment(currentDate).add(1, "month").toDate());
    }

    return (
        <OcErrorBoundary>
            <Box
                id="month-selector"
                display="flex"
                flexDirection="row"
                alignItems="center"
            >
                <InlineIcon
                    id="chevron-left"
                    icon={ChevronLeft}
                    width="30px"
                    height="30px"
                    className={clsx(classes.chevron, classes.leftChevron)}
                    onClick={handlePrev}
                />
                <Box className={classes.monthLabel}>{monthLabel}</Box>
                <InlineIcon
                    id="chevron-reft"
                    icon={ChevronRight}
                    width="30px"
                    height="30px"
                    className={clsx(classes.chevron, classes.rightChevron)}
                    onClick={handleNext}
                />
            </Box>
        </OcErrorBoundary>
    );
}
