import React from "react";
import { Box, Typography } from "@material-ui/core";
import OcPrivateLayoutFooter from "oc/components/oc-private-layout-footer";
import useStyles from "../family-tax-credit-information/family-tax-credit-information.style";

const FirstMarriageTaxCreditInformation = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box padding="16px" style={{ backgroundColor: "#F1F3F4" }}>
        <Box>
          <Typography
            variant="h4"
            style={{
              fontSize: "34px",
              fontWeight: "400",
              color: "#5E6366",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Tájékoztató
          </Typography>
          <Box height="32px" />
          <Typography
            variant="h5"
            style={{
              fontSize: "24px",
              fontWeight: "400",
              color: "#212121",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Adóelőleg-nyilatkozat az első házasok kedvezményének
            érvényesítéséről
          </Typography>
          <Box height="32px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography variant="h5" className={classes.titles}>
                <b>Tudnivalók a nyilatkozathoz</b>
              </Typography>
              <Box height="32px" />
              <Typography variant="body1">
                Az első házasok kedvezményét a házastársak minden esetben
                közösen érvényesítik, ennek során a kedvezmény teljes összegét
                vagy egyikük veszi igénybe, vagy a kedvezmény összegét
                felosztják egymás között.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Kinek kell átadni ezt a nyilatkozatot?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ahhoz, hogy munkáltatója, kifizetője az Ön járandóságaiból az
                első házasok kedvezményének figyelembevételével vonja le az
                adóelőleget,{" "}
                <b>
                  ezt a nyilatkozatot két példányban töltse ki és adja át
                  munkáltatójának, kifizetőjének.
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A nyilatkozat egyik példányát a munkáltatónak (kifizetőnek),
                másik példányát pedig Önnek kell az adóbevallás elkészítéséhez
                szükséges bizonylatokkal együtt az elévülési időn belül, azaz a
                bevallás benyújtásának évét követő 5. év végéig megőriznie. A
                munkáltató, kifizető a nyilatkozatot az adóéven belül, az
                átadást követő kifizetéseknél veszi figyelembe az adóéven belül,
                vagy kérésére a 24 jogosultsági hónapig.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>
                  Az adóelőleg-nyilatkozat nem kizárólag munkáltató számára
                  adható, hanem olyan kifizető felé is, aki összevonás alá eső
                  rendszeres jövedelmet
                </b>{" "}
                juttat Önnek, például megbízási szerződés alapján.{" "}
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                2024-től a kedvezményt az adóelőleg-nyilatkozat visszavonásáig,
                de legkésőbb a házasságkötést követő 24. hónapig folyamatosan
                figyelembe veheti a munkáltató, rendszeres bevételt juttató
                kifizető (folytatólagos nyilatkozat), ezt a 4. pontban kell
                jelezni. Ekkor a következő évben, években nem kell új
                nyilatkozatot adni. A munkáltató, kifizető a nyilatkozat
                visszavonásáig, vagy a jogosultsági időszak végéig veszi
                figyelembe az első házasok kedvezményét.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <i>
                  Ha Ön az első házasok kedvezményét jogalap nélkül veszi
                  igénybe, és emiatt az adóbevallásában 10 ezer forintot
                  meghaladó befizetési kötelezettsége, vagyis adóhátraléka
                  keletkezik, akkor ennek 12 százalékát különbözeti bírságként
                  kell megfizetnie az adóhátralékkal együtt.
                </i>
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Kinek jár a kedvezmény?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>
                  Az első házasok kedvezménye akkor jár egy házaspárnak, ha
                  legalább egyiküknek ez az első házassága.
                </b>{" "}
                A házaspárnak az a tagja is érvényesítheti, a kedvezményt,
                akinek nem ez az első házassága. Annak nincs jelentősége, hogy a
                házasságkötés belföldön vagy külföldön történt.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A kedvezményt a bejegyzett élettársak is igénybe vehetik (1.),
                ezért a házastárs kifejezés alatt a bejegyzett élettárs is
                értendő.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>A kedvezmény először</b> a házasságkötést követő hónapra
                vehető igénybe és a házassági életközösség alatt legfeljebb 24
                hónapon keresztül jár. A kedvezményt nem veszíti el az, aki
                családi kedvezményre lesz jogosult, vagy ha a házasság
                megkötésekor bármelyik fél már jogosult családi kedvezményre.
                Ilyen esetben az összevont adóalappal szemben először első
                házasok kedvezménye érvényesíthető, ezt követően pedig a családi
                kedvezményt kell figyelembe venni.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Mennyi kedvezmény jár?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>Az első házasok kedvezménye</b> – a családi kedvezményhez
                hasonlóan – <b>az Ön összevont adóalapját csökkenti.</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A házaspár által együttesen érvényesíthető kedvezmény havonta{" "}
                <b>33 335 forint.</b> Ez a gyakorlatban azt jelenti, hogy
                havonta 5 ezer forinttal több lesz a nettó kereset, mint a
                kedvezmény nélkül.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Milyen jövedelmet érint?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A kedvezmény az összevont adóalapba tartozó jövedelmekre vehető
                igénybe, mint például a munkabér, adóköteles
                társadalombiztosítási ellátás, a gyermekgondozási díj, más havi,
                heti munkadíj, tiszteletdíj, személyes közreműködés ellenértéke,
                bérbeadásból származó jövedelem, egyéb összevont adóalapba
                tartozó jövedelem.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Adatváltozáskor mit kell tenni?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha a nyilatkozatban közölt adatok változnak,{" "}
                <b>Ön köteles haladéktalanul új nyilatkozatot tenni</b> – ezt az
                I. Blokk jobb felső sarkában lévő,{" "}
                <b> Módosított nyilatkozat kódkockában</b> kell X-szel jelölnie.
                Ha a továbbiakban nem kívánja igénybe venni az első házasok
                kedvezményét, akkor ezt az 4. sorban kell jelölnie. Nem jár
                Önnek kedvezmény, ha például a házassági életközösség megszakad,
                vagy a kedvezmény teljes összegét a házastársa érvényesíti. Ha
                év közben új munkáltatónál helyezkedik el és új nyilatkozatot
                tesz, az <b>nem számít módosított nyilatkozatnak.</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>
                  Külföldi magánszemély milyen feltételek mellett érvényesítheti
                  a kedvezményt?
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Önnek csak akkor jár az első házasok kedvezménye, ha azonos vagy
                hasonló <b>kedvezményt</b> ugyanarra az időszakra{" "}
                <b>másik államban</b> – ahol önálló, nem önálló tevékenységéből,
                nyugdíjból és más, hasonló, a korábbi foglalkoztatásból származó
                jövedelme megadóztatható - figyelemmel a kettős adóztatás
                elkerüléséről szóló nemzetközi egyezmények rendelkezéseire is -{" "}
                <b>nem vett és nem is vesz igénybe.</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A külföldi adóügyi illetőségű magánszemély a kedvezményt
                Magyarországon csak akkor érvényesítheti, ha az adóévben
                megszerzett összes jövedelmének – ideértve a Magyarországon nem
                adóztatható jövedelmet is –{" "}
                <b>75 százaléka Magyarországon adózik.</b> Az adóévben
                megszerzett összes jövedelembe beleszámít az önálló és nem
                önálló tevékenységből származó jövedelem – ideértve különösen a
                vállalkozói jövedelmet és a vállalkozói osztalékalapot vagy az
                átalányadó alapját – valamint a nyugdíj és más hasonló, a
                korábbi foglalkoztatásból megszerzett jövedelem, függetlenül
                attól, hogy mely országban adókötelesek.{" "}
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha Ön{" "}
                <b>
                  külföldi adóügyi illetőségű, a „Kiegészítő nyilatkozat a
                  külföldi adóügyi illetőségű magánszemélyek
                  adóalap-kedvezményének érvényesítéséhez” nyilatkozatot is ki
                  kell töltenie
                </b>{" "}
                és a munkáltató, rendszeres jövedelmet juttató kifizető részére
                átadnia a kedvezményének igénybevételéhez.{" "}
                <b>Adóazonosító jelét</b> ebben az esetben is meg kell adnia.{" "}
              </Typography>
            </Box>
            <Box height="6px" />
            <Box marginLeft="16px">
              <Box className={classes.smallBgBox}>
                (1.) A bejegyzett élettársi kapcsolatról, az ezzel összefüggő,
                valamint az élettársi viszony igazolásának megkönnyítéséhez
                szükséges egyes törvények módosításáról szóló 2009. évi XXIX.
                törvény (a továbbiakban: Béktv.) 3. § (1) bekezdése alapján.
              </Box>
            </Box>
          </Box>
          <Box height="16px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography variant="h5" className={classes.titles}>
                <b>Példák</b>
              </Typography>
              <Box height="32px" />
              <Typography variant="body1">
                Egy pár február 14-én összeházasodik. Nekik először márciusban,
                utoljára pedig két év múlva februárban jár az első házasok
                kedvezménye, feltéve, hogy házasságuk sem válás, sem haláleset
                miatt nem szakad meg. Az esküvő évében az első házasok
                kedvezménye 10 hónapra, márciustól decemberig jár.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>
                  Hogyan érvényesíthetik az első házasok kedvezményét a 25 év
                  alatti fiatalok?
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha az első házasok kedvezményére jogosult házaspár mindkét tagja
                25 év alatti fiatal, akkor az első házasok kedvezménye
                érvényesítésének első hónapja az a hónap, amelyet megelőző
                hónapban a házastársak valamelyike a 25. életévét betölti. Ha
                csak a házaspár egyik tagja 25 év alatti, akkor az első házasok
                kedvezménye a házasságkötést követő hónaptól érvényesíthető.{" "}
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha a 25 év alatti fiatalok házasságkötése 2021. december előtt
                történt és a fiatalok már érvényesítették az első házasok
                kedvezményét, akkor ennek igénybevételét fel kellett függeszteni
                2022. január 1-től. Ebben az esetben az első házasok kedvezménye
                érvényesítésének időszakából még hátralévő jogosultsági
                hónapokra járó kedvezmény az azt követő hónaptól vehető igénybe,
                amelyben az egyik házastárs betölti a 25. életévét.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Például, ha a házasságkötés időpontja 2021. június 4. volt,
                akkor 2021-ben júliustól decemberig érvényesíthető az első
                házasok kedvezménye, 2022 januárjától már nem. Ha az egyik fél
                2024. augusztus 5-én 25 éves lesz, akkor szeptembertől igénybe
                vehetik az első házasok kedvezményét a jogosultsági időszakból
                még hátralévő 18 hónapra. Ekkor adóelőleg-nyilatkozatot kell
                adnia az első házasok kedvezményéről a munkáltató, rendszeres
                bevételt juttató kifizető részére. Ezt a nyilatkozatot megteheti
                az a házastárs is, aki még nem töltötte be a 25. életévét.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A kedvezményről bővebb tájékoztatást olvashat a NAV honlapján
                (www.nav.gov.hu) az „Szja adóalap- kedvezmények” című 73. számú
                információs füzetben.
              </Typography>
              {/************* ****************/}
            </Box>
            <Box marginLeft="16px">
              <Box className={classes.smallBgBoxWithoutBg}></Box>
            </Box>
          </Box>
          <Box height="16px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography variant="h5" className={classes.titles}>
                <b>Az adóelőleg-nyilatkozat kitöltése</b>
              </Typography>
              <Box height="40px" />
              <Typography variant="body1">
                <b style={{ textDecoration: "underline" }}>I. blokk:</b> Ön
                ebben a blokkban nyilatkozik munkáltatójának arról, hogy
                érvényesíteni kívánja az első házasok kedvezményét.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>Az 1. pontban</b> a házastárs nevét, adóazonosító jelét, és
                ha van adóelőleget megállapító munkáltatója, rendszeres bevételt
                juttató kifizetője, akkor annak megnevezését, adószámát kell
                feltüntetni. Itt tüntesse fel a házasságkötés időpontját is,
                vagy, ha a házasságkötéskor mindketten 25 év alatti fiatalok
                voltak, akkor azt az időpontot, amikor a házastársa 25. életévét
                betölti, betöltötte (erre azért van szükség, hogy a munkáltató
                meg tudja állapítani a jogosultság kezdetét).
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b> A 2. ponthoz:</b> az első házasok kedvezménye a
                házasságkötést követő hónaptól 24 hónapon keresztül
                érvényesíthető. Ha mindkét házastárs 25 év alatti, akkor az első
                házasok kedvezménye azt követő hónaptól érvényesíthető, amikor
                egyikük betölti a 25. életévét (Részleteket a „Hogyan
                érvényesíthetik az első házasok kedvezményét a 25 év alatti
                fiatalok?” cím alatt olvashat). Mivel a házassági életközösség
                ezen időszak alatti felbomlásával a jogosultság is megszűnik,
                ezt a tényt jeleznie kell munkáltatójának, kifizetőjének.{" "}
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha tudomásul veszi a jogosultság időszakára vonatkozó szabályt,
                akkor
              </Typography>
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  a 2.1. sort jelölje, ha a nyilatkozatát a munkáltató, kifizető
                  csak az adóévben vegye figyelembe,
                </li>
                <li>
                  A 2.2. sort jelölje, ha a nyilatkozatát a munkáltató, kifizető
                  folyamatosan, annak visszavonásáig, vagy a jogosultsági idő
                  végéig vegye figyelembe. Ha ezt a sort megjelöli, akkor a
                  munkáltató, rendszeres bevételt juttató kifizető az
                  adóelőleg-nyilatkozatát visszavonásáig, de legkésőbb a
                  házasságkötést követő 24. hónapig veszi figyelembe. Így, ha a
                  jogosultsága a következő évre, évekre átnyúlik, akkor nem kell
                  új adóelőleg-nyilatkozatot adnia.
                </li>
              </Typography>
              <Box height="10px" />
              <Box marginLeft="30px">
                <Typography variant="body1">
                  Az a fiatal pár, aki a 25 év alatti fiatalok kedvezménye miatt
                  nem a házasságkötést követő hónaptól tudja az első házasok
                  kedvezményét érvényesíteni, a kedvezmény érvényesítésére
                  folytatólagos nyilatkozatot az egyik fél 25. életéve
                  betöltését követő hónaptól tehet.
                </Typography>
              </Box>
              <Box height="25px" />
              <Typography variant="body1">
                <b>A 2.1. és 2.2. sor közül csak az egyiket jelölje!</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>A 3. pontban</b> nyilatkoznia kell arról, hogy mikortól és
                milyen összegben kívánja érvényesíteni az első házasok
                kedvezményét.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>Az 4. pontban</b> kell jeleznie, ha a nyilatkozat hónapjától
                nem kéri az első házasok kedvezményét. Ebben az esetben a
                nyilatkozat jobb felső sarkában X-szel kell jelölnie, hogy
                módosított nyilatkozatot nyújt be.{" "}
              </Typography>
              <Box height="25px" />

              <Typography variant="body1">
                <b style={{ textDecoration: "underline" }}>II. Blokk:</b> ezt a
                blokkot az Ön munkáltatójának, kifizetőjének kell kitöltenie.
              </Typography>
            </Box>
            <Box height="6px" />
            <Box marginLeft="16px">
              <Box className={classes.smallBgBoxWithoutBg}></Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <OcPrivateLayoutFooter />
    </Box>
  );
};
export default FirstMarriageTaxCreditInformation;
