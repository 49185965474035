import gql from "graphql-tag";

export const GET_MARITAL_STATUSES = gql`
  query maritalStatuses {
    maritalStatuses {
      code
      name
    }
  }
`;
