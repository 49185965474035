import { atom } from "recoil";
import persistAtom from "./persist-atom";

export const roleFilterState = atom({
  key: "roleFilterState",
  default: {
    company: [],
    orgUnit: [],
    workingPlace: [],
    jobClassGroup: [],
    payOffice: [],
    contract: [],
  },
  effects_UNSTABLE: [persistAtom],
});
