import gql from "graphql-tag";

export const GET_FILTERED_LIST = gql`
  query filteredList(
    $page: Int
    $pageSize: Int
    $isActive: Boolean
    $roleFilters: RoleFilterInput
  ) {
    filteredList(
      page: $page
      pageSize: $pageSize
      isActive: $isActive
      roleFilters: $roleFilters
    ) {
      page
      pageSize
      total
      totalPages
      rows {
        code
        employee {
          code
          prefixName
          lastName
          firstName
          middleName
          nameSuffix
        }
        orgUnit {
          code
          name
        }
        jobClass {
          code
          name
        }
      }
    }
  }
`;
