export default function getCorrectBase64Image(base64src) {
  /** check if it's got */
  const dataAr = base64src?.split(",") || [];

  if (dataAr && dataAr[0]) {
    if (dataAr[0] === "data:image/png;base64") {
      /** it's a correct png file */
      if (dataAr[1]?.trim()?.substring(0, 11) === "iVBORw0KGgo") {
        return base64src;
      }
      /** it's a jpeg file with a png meta data */
      if (dataAr[1]?.trim()?.substring(0, 3) === "/9j") {
        return "data:image/jpeg;base64," + dataAr[1];
      }
    }
    if (dataAr[0] === "data:image/jpeg;base64") {
      /** it's a correct jpeg file */
      if (dataAr[1]?.trim()?.substring(0, 3) === "/9j") {
        return base64src;
      }
      /** it's a png file with a jpeg meta data */
      if (dataAr[1]?.trim()?.substring(0, 11) === "iVBORw0KGgo") {
        return "data:image/png;base64," + dataAr[1];
      }
    }
  }

  if (base64src?.substring(0, 10) !== "data:image") {
    if (base64src?.trim()?.substring(0, 3) === "/9j") {
      return "data:image/jpeg;base64," + base64src;
    }

    if (base64src?.trim()?.substring(0, 11) === "iVBORw0KGgo") {
      return "data:image/png;base64," + base64src;
    }
  }

  if (!base64src || base64src?.trim() === "") {
    return "/img/defaultAvatar.png";
  }

  return base64src;
}
