import React from "react";
import useStyles from "./date-header.style";

export default function DateHeader({ label }) {
  const classes = useStyles();
  return (
    <div>
      <span className={classes.root}>{label}</span>
    </div>
  );
}