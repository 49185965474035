import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import OcDownload from "oc/components/oc-download";
import useCafeteriaRegulation from "hooks/use-cafeteria-regulation";

const MyCafeteriaDownloadsCard = ({ currentStatementData }) => {
  const { t } = useTranslation();
  const { data } = useCafeteriaRegulation();
  return (
    <Box>
      <OcCard label={t("DOWNLOADS")}>
        <OcDownload
          id="my-cafeteria-downloads-card-vkb"
          label={t("VBK_REGULATION")}
          iconType="pdf"
          href={data?.filename}
          isSmall={true}
        />
      </OcCard>
    </Box>
  );
};

MyCafeteriaDownloadsCard.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaDownloadsCard.defaultProps = {
  example: "example",
};

export default MyCafeteriaDownloadsCard;
