import gql from "graphql-tag";

export const GET_ORG_UNITS_ADMIN_LIST = gql`
  query orgUnitsAdminList(
    $searchField: String
    $searchText: String
    $page: Int
    $pageSize: Int
    $orgUnits: [String]
  ) {
    orgUnitsAdminList(
      searchField: $searchField
      searchText: $searchText
      page: $page
      pageSize: $pageSize
      orgUnits: $orgUnits
    ) {
      availableList {
        _id
        code
        name
      }
      selectedList {
        _id
        code
        name
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
