import React from "react";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import ApplicationCheckBox from "my-hr/components/my-hr-applications-data-panel/components/application-check-box";
import useStyles from "my-hr/components/my-hr-applications-data-panel/components/family-tax-credit-application-print/family-tax-credit-application-print.style";
import { DOTTED_LINE } from "my-hr/components/my-hr-applications-data-panel/constants";
import TaxCreditApplicationPrintEmployer from "../tax-credit-application-print-employer/tax-credit-application-print-employer";

const FourChildrenDependentsInfoTableRow = ({
  name,
  taxNumber,
  birthPlace,
  birthDate,
  mothersName,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        marginBottom: "8px",
        marginTop: "8px",
        fontSize: "15px",
        fontFamily: "EB Garamond",
      }}
    >
      <style type="text/css" media="print">
        {"\
            @page { size: landscape; }\
        "}
      </style>

      <Box width="200px" textAlign="center" marginLeft="22px">
        {name}
      </Box>
      <Box width="140px" textAlign="center" marginLeft="80px">
        {taxNumber ? taxNumber : DOTTED_LINE}
      </Box>
      <Box width="274px" textAlign="center" marginLeft="73px">
        {birthPlace}, {birthDate}
      </Box>
      <Box width="200px" textAlign="center">
        {mothersName}
      </Box>
    </Box>
  );
};

export const FourChildrenTaxCreditApplicationPrint = React.forwardRef(
  (props, ref) => {
    const {
      fullName = "Minta Borbála",
      taxNumber = "8124440505",
      employerName,
      employerTaxNumber,
      applicationDate,
      states,
    } = props;
    let year = applicationDate?.slice(0, 4);
    const classes = useStyles();

    return (
      <Box ref={ref}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          fontSize="15px"
          fontFamily="EB Garamond"
          style={{
            paddingTop: "20px",
          }}
        >
          <Box
            style={{
              fontSize: "16px",
              fontFamily: "EB Garamond",
              fontWeight: "bold",
            }}
          >
            Adóelőleg-nyilatkozat a négy vagy több gyermeket nevelő anyák
            kedvezményének érvényesítéséről
            <Box />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              paddingTop="2px"
            >
              A nyilatkozat benyújtásának éve: {year}
            </Box>
          </Box>
          <Box className={classes.blackBorder}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex">
                <Box className={classes.paragraphStart}>I.</Box>
                <Box fontWeight="normal">A nyilatkozatot adó magánszemély</Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                Módosított nyilatkozat:
                <ApplicationCheckBox value={states?.isModifiedDeclaration} />
              </Box>
            </Box>
            <Box height="10px" />
            <Box
              style={{
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <Box display="flex">
                neve:
                <Box width="200px" textAlign="center">
                  {fullName}
                </Box>
                <Box marginLeft="70px">adóazonosító jele:</Box>
                <Box marginLeft="10px">
                  {taxNumber ? taxNumber : DOTTED_LINE}
                </Box>
              </Box>
            </Box>
            <Box height="10px" />
            <Box
              style={{
                fontWeight: "bold",
              }}
            >
              1.
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              marginTop="-20px"
            >
              <Typography className={classes.tableTitle}>
                gyermek neve
              </Typography>
              <Typography className={classes.tableTitle}>
                adóazonosító jele
              </Typography>
              <Typography className={classes.tableTitle}>
                születési helye, ideje
              </Typography>
              <Typography
                className={classes.tableTitle}
                style={{
                  marginLeft: "-20px",
                }}
              >
                anyja neve
              </Typography>
            </Box>
            <Box height="5px" />
            {states?.dependents.map((item, index) => {
              return (
                <FourChildrenDependentsInfoTableRow
                  key={`dependent_${index}`}
                  name={item.name}
                  taxNumber={item.taxNumber}
                  birthPlace={item.placeOfBirth}
                  birthDate={new Date(item.dateOfBirth).toLocaleDateString()}
                  mothersName={item.mothersName}
                />
              );
            })}
            <Box height="20px" />
            <Box display="flex">
              <Box className={classes.paragraphStart}>2.</Box>
              <Box fontWeight="normal" display="flex">
                Nyilatkozom, hogy jogosult vagyok a négy vagy több gyermeket
                nevelő anyák kedvezményére, azt igénybe kívánom venni.
                <ApplicationCheckBox value={states?.isEligible} />
              </Box>
            </Box>
            <Box height="10px" />
            <Box display="flex">
              <Box className={classes.paragraphStart}>3.</Box>
              <Box fontWeight="normal" display="flex">
                Nyilatkozatomat visszavonásig kérem figyelembe venni
                (folytatólagos nyilatkozatot teszek)
                <ApplicationCheckBox value={states?.permanentlyEntitled} />
              </Box>
            </Box>
            <Box height="10px" />
            <Box display="flex">
              <Box className={classes.paragraphStart}>4.</Box>
              <Box fontWeight="normal" display="flex">
                Nyilatkozom, hogy a négy vagy több gyermeket nevelő anyák
                kedvezményét
                <Box
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  {states?.notApplyFromMonth
                    ? states?.notApplyFromMonth
                    : "...."}
                  .
                </Box>
                hónaptól kezdődően nem (erre a hónapra sem) kívánom igénybe
                venni.
              </Box>
            </Box>
            <Box height="20px" />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className={classes.formatText}>
                Kelt: {applicationDate}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                A nyilatkozatot tevő magánszemély aláírása:
                <Box marginLeft="10px">
                  ................................................
                </Box>
              </Box>
            </Box>
          </Box>
          <Box height="5px" />
          <TaxCreditApplicationPrintEmployer
            employerName={employerName}
            employerTaxNumber={employerTaxNumber}
          />
        </Box>
      </Box>
    );
  }
);

export default FourChildrenTaxCreditApplicationPrint;
