import gql from "graphql-tag";

export const GET_CONTRACTS_ADMIN_LIST = gql`
  query contractsAdminList(
    $page: Int!
    $pageSize: Int!
    $searchField: String
    $searchText: String
    $contracts: [String]
  ) {
    contractsAdminList(
      page: $page
      pageSize: $pageSize
      searchField: $searchField
      searchText: $searchText
      contracts: $contracts
    ) {
      availableList {
        _id
        code
        name
      }
      selectedList {
        _id
        code
        name
      }
      page
      pageSize
      total
      totalPages
    }
  }
`;
