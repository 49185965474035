import React from "react";
import useLeaveSummaryChartData from "hooks/use-my-leave-summary-chart-data";
import MyLeaveBar from "my-leave/components/my-leave-bar";

const LeaveBaseCard = ({ selectedYear, contract }) => {
  const { data } = useLeaveSummaryChartData({
    year: selectedYear,
    contract,
  });

  if (!data?.allLeaveDays || data?.allLeaveDays === 0) {
    return null;
  }

  return (
    <MyLeaveBar
      id="base-leave-days-bar"
      label={"Leave.leaveBase"}
      all={data?.allLeaveDays}
      available={data?.availableLeaveDays}
      requested={data?.requestedLeaveDays}
      fulfilled={data?.fulfilledLeaveDays}
    />
  );
};
export default LeaveBaseCard;
