import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

export default function useOcTextInput({ optional, defaultValue = "" }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  function isValid() {
    if (optional) return true;
    return value === "" ? false : true;
  }

  function validate() {
    if (optional) return;
    if (value === "") {
      setError(true);
      setHelperText(t("ERROR_MESSAGE_FIELD_IS_REQUIRED"));
      return;
    }
  }

  return {
    value,
    setValue,
    error,
    setError,
    helperText,
    setHelperText,
    isValid,
    validate,
  };
}
