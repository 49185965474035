import gql from "graphql-tag";

export const INACTIVATE_USERS = gql`
  mutation inactivateUsers(
    $inactivateDate: Date
    $roleFilters: RoleFilterInput
  ) {
    inactivateUsers(inactivateDate: $inactivateDate, roleFilters: $roleFilters)
  }
`;
