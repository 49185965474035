import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { InlineIcon } from "@iconify/react";
import infoIcon from "@iconify/icons-mdi/information-outline";
import useGetVersionDI from "hooks/use-get-version";
import { useTranslation } from "react-i18next";

const OcRefreshDialog = ({ useGetVersion = useGetVersionDI }) => {
  const {
    timeToRefresh,
    showRefreshDialog,
    setShowRefreshDialog,
    onRefresh,
  } = useGetVersion();

  const handleCloseRefreshDialog = () => {
    setShowRefreshDialog(false);
  };

  const { t } = useTranslation();
  return (
    <Dialog open={showRefreshDialog}>
      <Box
        width="480px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="42px 24px 32px 24px"
      >
        <InlineIcon icon={infoIcon} width="62px" color="#5E6366" />
        <Box height="38px" />
        <Typography variant="h5">{t("UPDATEAVAIABLE")}</Typography>
        <Box height="32px" />
        <Typography variant="body1">{t("FIVEMINREFRESH")}</Typography>
        <Box height="42px" />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="108px"
          height="77px"
          style={{
            backgroundColor: "#f1f3f4",
            color: "#cc0033",
            borderRadius: "4px",
            border: "1px solid #C6CACC",
          }}
        >
          <Typography
            style={{
              fontSize: "36px",
              letterSpacing: "-1.5px",
            }}
          >
            {timeToRefresh}
          </Typography>
        </Box>
        <Box height="32px" />
        <Button
          variant="contained"
          fullWidth
          color="primary"
          disableElevation
          onClick={onRefresh}
        >
          {t("REFRESH")}
        </Button>
        <Box height="32px" />
        <Button onClick={handleCloseRefreshDialog} fullWidth>
          {t("CLOSE")}
        </Button>
      </Box>
    </Dialog>
  );
};

OcRefreshDialog.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

OcRefreshDialog.defaultProps = {
  example: "example",
};

export default OcRefreshDialog;
