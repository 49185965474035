import { useQuery } from "@apollo/react-hooks";
import { GET_FRAME_PERIOD } from "my-time/graphql/queries";

const useFramePeriod = ({ contractCode, year, month }) => {
  const { data, loading, error } = useQuery(GET_FRAME_PERIOD, {
    fetchPolicy: "cache-and-network",
    variables: {
      contractCode,
      year,
      month,
    },
  });

  return {
    data: data?.getFramePeriod || {},
    loading,
    error,
  };
};

export default useFramePeriod;
