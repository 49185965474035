import gql from "graphql-tag";

export const GET_ORG_UNITS = gql`
  query orgUnits(
    $searchField: String
    $searchText: String
    $sort: String
    $page: Int
    $pageSize: Int
    $orgUnits: [String]
    $product: String
    $role: String
    $viewedByAdmin: Boolean
    $showAllowedEntities: Boolean
  ) {
    orgUnits(
      searchField: $searchField
      searchText: $searchText
      sort: $sort
      page: $page
      pageSize: $pageSize
      orgUnits: $orgUnits
      product: $product
      role: $role
      viewedByAdmin: $viewedByAdmin
      showAllowedEntities: $showAllowedEntities
    ) {
      _id
      availableList {
        _id
        code
        name
      }
      selectedList {
        _id
        code
        name
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
