import gql from "graphql-tag";

export const SUBSCRIPTION_EMAILS_BY_COMPANY = gql`
  subscription getEmailsByCompanyList {
    getEmailsByCompanyList {
      companyCode
      type
      address
    }
  }
`;
