import React from "react";
import Box from "@material-ui/core/Box";
import CurrentApplications from "../current-applications";

const ApplicationWrapper = ({ label }) => {
  return (
    <>
      <Box height="16px" />
      <CurrentApplications label={label} />
      <Box height="16px" />
    </>
  );
};

export default ApplicationWrapper;
