import React, { useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/hu";
import { useTranslation } from "react-i18next";
import useStyles from "./my-time-calendar.style";
import MyTimeCalendarToolbar from "../my-time-calendar-toolbar/my-time-calendar-toolbar";
import useContractCalendar from "hooks/use-my-leave-contract-calendar";
import DayHeader from "my-leave/components/my-leave-calendar/components/day-header";
import MyTimeCalendarMonthEvent from "my-time/components/my-time-calendar-month-event";
import MyTimeCalendarEventWrapper from "my-time/components/my-time-calendar-event-wrapper";
import MyTimeCalendarWeeklyDayHeader from "my-time/components/my-time-calendar-weekly-day-header";
import MyTimeCalendarWeeklyEvent from "my-time/components/my-time-calendar-weekly-event";
import MyTimeCalendarMonthlyDateHeader from "my-time/components/my-time-calendar-monthly-date-header";

const MyTimeCalendar = ({
  contract,
  events,
  handleSelectRow,
  date,
  onDateChange,
  handleMoreClick,
  onClickDisabled = false,
  isNotExaminer,
  countTimeData,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  let calendarRef = useRef();
  const [currentView, setCurrentView] = useState(null);

  const localizer = momentLocalizer(moment);
  localizer.segmentOffset = 0;
  
  const culture = i18n.language === "hu" ? "hu" : "en";
  let messages = {
    date: t("CALENDAR_MESSAGES_DATE"),
    time: t("CALENDAR_MESSAGES_TIME"),
    event: t("CALENDAR_MESSAGES_EVENT"),
    allDay: t("CALENDAR_MESSAGES_ALL_DAY"),
    week: t("CALENDAR_MESSAGES_WEEK"),
    work_week: t("CALENDAR_MESSAGES_WORK_WEEK"),
    day: t("CALENDAR_MESSAGES_DAY"),
    month: t("CALENDAR_MESSAGES_MONTH"),
    previous: t("CALENDAR_MESSAGES_PREVIOUS"),
    next: t("CALENDAR_MESSAGES_NEXT"),
    yesterday: t("CALENDAR_MESSAGES_YESTERDAY"),
    tomorrow: t("CALENDAR_MESSAGES_TOMORROW"),
    today: t("CALENDAR_MESSAGES_TODAY"),
    agenda: t("CALENDAR_MESSAGES_AGENDA"),

    noEventsInRange: t("CALENDAR_MESSAGES_NO_EVENTS_IN_RANGE"),

    showMore: (total) => `+${total} ${t("CALENDAR_MESSAGES_MORE")}`,
  };

  const firstDate = moment(date).startOf("year").toDate();
  const lastDate = moment(date).endOf("year").toDate();

  const { contractCalendar } = useContractCalendar({
    contract: contract,
    startDate: firstDate,
    endDate: lastDate,
  });

  const customBackground = (date) => {
    if (date && contractCalendar) {
      const day = moment(date).dayOfYear();
      const type = contractCalendar[day - 1];
      if (type === "P") {
        return "rbc-weekend-day";
      }
      if (type === "F") {
        return "rbc-payed-holiday-day";
      }
    }
    return "";
  };

  const eventPropGetter = (event) => {
    let actualClass = "";
    switch (event.myTimeType) {
      case "OVERTIME":
        actualClass = "rbc-event-my-time-overtime";
        break;
      case "WORKING_TIME":
        actualClass = "rbc-event-my-time-working-time";
        break;
      case "WORKING_TIME_ITEM":
        actualClass = "rbc-event-my-time-working-time-item";
        break;
      case "HOMEOFFICE":
        actualClass = "rbc-event-my-time-homeoffice";
        break;
      case "ON_CALL":
        actualClass = "rbc-event-my-time-on-call";
        break;
      case "STAND_BY":
        actualClass = "rbc-event-my-time-stand-by";
        break;
      case "STAND_BY_CALL":
        actualClass = "rbc-event-my-time-stand-by-call";
        break;
      case "LEAVE":
        actualClass = "rbc-event-my-time-leave";
        break;
      case "ABSENCE":
        actualClass = "rbc-event-my-time-leave";
        break;
      case "OTHER":
        actualClass = "rbc-event-my-time-working-time-item";
        break;

      default:
        actualClass = "";
    }
    if (event.state && event.state === "requested") {
      actualClass = "rbc-event-my-time-requested";
    }
    if (event.state && event.state === "approved") {
      actualClass = "rbc-event-my-time-approved";
    }

    return { className: actualClass };
  };

  const onSelectEvent = (event) => {
    if (
      event.myTimeType === "WORKING_TIME_ITEM" ||
      event.myTimeType === "LEAVE" ||
      event.myTimeType === "ABSENCE" ||
      event.myTimeType === "OTHER" ||
      event.myTimeType === "HOMEOFFICE" ||
      event.myTimeType === "ON_CALL"
    ) {
      handleSelectRow({ action: "show", row: event });
    }
    if (event._id) {
      handleSelectRow({ action: "show", row: event });
      return;
    }
    if (isNotExaminer) {
      handleSelectRow({ action: "show", row: event });
      return;
    }
    if (event.myTimeType === "STAND_BY") {
      event.sender = "calendar";
      handleSelectRow({
        action: "show",
        row: event,
      });
      return;
    }
    handleSelectRow({ action: "edit", row: event });
  };

  const onSelectSlot = (slotInfo) => {
    if (onClickDisabled) return;
    const event = {
      date: slotInfo.start,
      myTimeType: "OVERTIME",
      startTime: "",
      endTime: "",
      timeDataTypeCode: "",
      timeDataTypeName: "",
      sender: "calendar",
    };
    handleSelectRow({ action: "add", row: event });
  };

  const onShowMore = (events, date) => {
    handleMoreClick(date);
  };

  return (
    <Box
      style={currentView === "week" ? { height: "auto" } : { height: "900px" }}
      className={classes.root}
    >
      <Calendar
        ref={calendarRef}
        date={new Date(date)}
        onNavigate={(date) => {
          onDateChange(date);
        }}
        onSelectEvent={(props) => onSelectEvent(props)}
        localizer={localizer}
        culture={culture}
        messages={messages}
        events={events}
        startAccessor="start"
        endAccessor="end"
        slotPropGetter={(date) => {
          return { className: customBackground(date) };
        }}
        dayPropGetter={(date) => {
          return { className: customBackground(date) };
        }}
        toolbar={true}
        tooltipAccessor={null}
        views={{
          month: true,
          week: true,
        }}
        components={{
          toolbar: MyTimeCalendarToolbar,
          month: {
            header: DayHeader,
            dateHeader: (props) => {
              const cumulatedProps = { ...props };
              cumulatedProps.countTimeData = countTimeData;
              return MyTimeCalendarMonthlyDateHeader(cumulatedProps);
            },
            event: MyTimeCalendarMonthEvent,
            eventWrapper: MyTimeCalendarEventWrapper,
          },
          week: {
            header: (props) => {
              const cumulatedProps = { ...props };
              cumulatedProps.countTimeData = countTimeData;
              return MyTimeCalendarWeeklyDayHeader(cumulatedProps);
            },
            event: MyTimeCalendarWeeklyEvent,
          },
        }}
        eventPropGetter={eventPropGetter}
        showMultiDayTimes={true}
        onView={(view) => setCurrentView(view)}
        dayLayoutAlgorithm="overlap"
        selectable={true}
        onSelectSlot={(slotInfo) => onSelectSlot(slotInfo)}
        onShowMore={(events, date) => onShowMore(events, date)}
      />
    </Box>
  );
};

export default MyTimeCalendar;
