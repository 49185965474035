import React from "react";
import { Box } from "@material-ui/core";
import ApplicationCheckBox from "my-hr/components/my-hr-applications-data-panel/components/application-check-box";
import ApplicationPrintCardInfoItem from "my-hr/components/my-hr-applications-data-panel/components/application-print-card-info-item";
import CorporateSignature from "my-hr/components/my-hr-applications-data-panel/components/corporate-signature";
import { DOTTED_LINE } from "my-hr/components/my-hr-applications-data-panel/constants";

export const UnderTwentyFiveTaxCreditApplicationPrint = React.forwardRef(
  (props, ref) => {
    const {
      fullName,
      taxNumber,
      applicationDate,
      employerName,
      employerTaxNumber,
      omissionOfApplication,
      underTwentyFiveAmountDiscount,
      underTwentyFiveAmountDiscountCb,
    } = props;
    let year = applicationDate?.slice(0, 4);

    return (
      <Box ref={ref}>
        <Box padding="50px" fontSize="18px" fontFamily="EB Garamond">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ paddingTop: "20px" }}
          >
            <Box
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Nyilatkozat
              <Box />
              <Box display="flex" flexDirection="column" alignItems="center">
                a 25 év alatti fiatalok kedvezményének részben vagy egészben
                történő érvényesítésének mellőzéséről
              </Box>
              <Box height="30px" />
            </Box>
          </Box>
          <Box>A nyilatkozat benyújtásának éve: {year}</Box>
          <Box height="13px" />
          <Box>A nyilatkozatot adó magánszemély</Box>
          <Box height="13px" />

          <ApplicationPrintCardInfoItem label="neve:" value={fullName} />
          <Box height="13px" />
          <ApplicationPrintCardInfoItem
            label="adóazonosító jele:"
            value={taxNumber}
          />
          <Box height="13px" />
          <Box>1. Az adóelőleg megállapítása során kérem</Box>
          <Box height="13px" />
          <Box style={{ display: "flex" }}>
            <Box width="40px" />
            <Box>a)</Box>
            <Box width="10px" />
            <Box>
              a 25 év alatti fiatalok kedvezménye érvényesítésének mellőzését.
            </Box>
            <Box height="13px" />
            <ApplicationCheckBox value={omissionOfApplication} />
          </Box>
          <Box height="13px" />
          <Box style={{ display: "flex" }}>
            <Box width="40px" />
            <Box>b)</Box>
            <Box width="10px" />
            <Box>
              {underTwentyFiveAmountDiscount
                ? underTwentyFiveAmountDiscount
                : "……………"}
            </Box>
            <Box marginLeft="10px">
              forintot meghaladó összegre a kedvezmény érvényesítésének
              mellőzését.
            </Box>
            <Box height="13px" />
            <ApplicationCheckBox value={underTwentyFiveAmountDiscountCb} />
          </Box>
          <Box height="13px" />
          <Box>Kelt: {applicationDate}</Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            marginRight="50px"
          >
            <Box>........................................................</Box>
            <Box marginRight="33px">A magánszemély aláírása</Box>
          </Box>
          <Box height="10px" />
          <Box
            style={{
              border: "1px solid black",
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "8px",
              paddingBottom: "12px",
              width: "auto",
              height: "auto",
            }}
          >
            <Box
              display="flex"
              style={{
                fontWeight: "bold",
              }}
            >
              2.
              <Box marginLeft="15px" fontWeight="normal" display="flex">
                A magánszemély nyilatkozatának tartalmát tudomásul vettem. A
                magánszemély adóelőlegét a nyilatkozat figyelembevételével
                állapítom meg.
              </Box>
            </Box>
            <Box height="13px" />
            <Box>
              Az adóelőleget megállapító munkáltató megnevezése:
              <Box>{employerName ? employerName : DOTTED_LINE}</Box>
            </Box>
            <Box height="13px" />
            <ApplicationPrintCardInfoItem
              label="Az adóelőleget megállapító munkáltató
                        adószáma:"
              value={employerTaxNumber ? employerTaxNumber : DOTTED_LINE}
            />
            <Box height="13px" />
            <CorporateSignature
              applicationDate={applicationDate}
              orientation="horizontal"
            />
          </Box>
        </Box>
      </Box>
    );
  }
);

export default UnderTwentyFiveTaxCreditApplicationPrint;
