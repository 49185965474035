import React from "react";
import useCalendar from "hooks/use-my-leave-calendar";
import { startOfYear, endOfYear, getDayOfYear } from "date-fns";
import useStyles from "./month-date-header.style";

export default function MonthDateHeader({ date, label }) {
  let startDate = startOfYear(date);
  let endDate = endOfYear(date);

  const { data: Calendar } = useCalendar({ startDate, endDate });
  let day = getDayOfYear(date);
  let dayState = Calendar?.charAt(day - 1);

  const classes = useStyles();
  return (
    <div style={{ paddingRight: 8, paddingTop: 8 }}>
      <span
        className={classes.root}
        style={dayState === "F" ? { color: "#cc0033" } : {}}
      >
        {label}
      </span>
    </div>
  );
}

MonthDateHeader.defaultProps = {
  date: new Date(),
  label: "unlabeled",
};
