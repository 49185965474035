import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const MyCafeteriaVbkApplicationTableHeader = () => {
  const { t } = useTranslation();
  const columnWidth = "156px";
  return (
    <Box
      height="48px"
      paddingLeft="8px"
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{ borderBottom: "2px solid #5E6366" }}
    >
      <Box flexGrow={1} textAlign="left" padding="0px 8px" minWidth="180px">
        <Typography variant="h6">{t("BENEFIT")}</Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant="h6">{t("MONTHS_PLURAL")}</Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant="h6">{t("NUMBER_OF_MONTHS")}</Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant="h6">{t("NETTO")}</Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant={"h6"}>{t("COST")}</Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant="h6">{t("BRUTTO")}</Typography>
      </Box>
    </Box>
  );
};

export default MyCafeteriaVbkApplicationTableHeader;
