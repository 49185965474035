import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Currency from "oc/components/oc-currency/oc-currency";
import PartnerLogo from "oc/components/oc-private-layout-sidebar-partner-logo";
import useCurrentCafeteriaStatementItems from "my-cafeteria/hooks/use-current-cafeteria-statement-items";
import useCurrentUserState from "hooks/use-current-user-state";
import useEmployee from "hooks/use-myhr-employee";
import getFullname from "oc/utils/get-fullname";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import MyCafeteriaInfoLine from "../my-cafeteria-info-line";
import MyCafeteriaStatementTitle from "../my-cafeteria-statement-title";
import MyCafeteriaSignatureBlock from "../my-cafeteria-signature-block";

const useStyles = makeStyles((theme) => ({
  szepCardTable: {
    width: "544px",
    "@media print": {
      width: "364px",
    },
  },
}));

const MyCafeteriaSzepApplicationCardPrintContainer = () => {
  let { contractCode, employeeCode } = useCurrentUserState();

  const { data: employeeData } = useEmployee({ employeeId: employeeCode });
  const { data } = useCurrentCafeteriaStatementItems({
    contractCode,
  });

  const filteredData = data?.filter((item) => item?.menuItem?.isSZEPCardItem);
  const uniqFilteredData = _.uniqBy(filteredData, "benefit");

  let params = {
    applicationDate: new Date().toLocaleDateString(),
    employeeName: getFullname(employeeData),
    idNumber: employeeData?.taxNumber,
    contractCode: contractCode,
    currentYear: new Date().getFullYear(),
    data: uniqFilteredData || [],
  };
  return <MyCafeteriaSzepApplicationCardPrint {...params} />;
};

const TableRow = ({ label, value }) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box
        flexGrow={1}
        borderBottom="1px solid #5E6366"
        paddingTop="10px"
        paddingBottom="10px"
      >
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Box
        width="85px"
        textAlign="right"
        borderBottom="1px solid #5E6366"
        paddingTop="10px"
        paddingBottom="10px"
      >
        <Typography variant="body1">
          <Currency value={value} />
        </Typography>
      </Box>
    </Box>
  );
};

const MyCafeteriaSzepApplicationCardPrint = ({
  applicationDate,
  employeeName,
  idNumber,
  contractCode,
  currentYear,
  data,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  let thisYear = new Date().getFullYear().toString();

  const hasPreviousBenefit = data?.length > 0;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box id="upper-component" paddingBottom="48px">
        <MyCafeteriaStatementTitle
          label={t("SZEPSTATEMENT", { currentYear })}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          <Box id="data-block">
            <MyCafeteriaInfoLine
              label={t("CALENDAR_MESSAGES_DATE")}
              value={applicationDate}
            />
            <MyCafeteriaInfoLine
              label={t("STATEMENT_EMPLOYEE_NAME")}
              value={employeeName}
            />
            <MyCafeteriaInfoLine
              label={t("STATEMENT_TAX_ID_SIGN_ID")}
              value={idNumber}
            />
            <MyCafeteriaInfoLine
              label={t("STATEMENT_CONTRACT_CODE")}
              value={contractCode}
            />
          </Box>
          <Box
            sx={{
              display: "none",
              displayPrint: "block",
              paddingRight: "85px",
            }}
          >
            <PartnerLogo />
          </Box>
        </Box>
        <Box padding="0px 56px">
          {hasPreviousBenefit ? (
            <Box>
              <Box paddingBottom="36px">
                <Typography varian="body1">
                  {t("IHAVEBENEFIT", { thisYear })}
                </Typography>
              </Box>
              <Box
                className={classes.szepCardTable}
                display="flex"
                flexDirection="column"
              >
                <Box display="flex" flexDirection="row">
                  <Box
                    flexGrow={1}
                    borderBottom="1px solid #5E6366"
                    paddingBottom="10px"
                  >
                    <Typography variant="h6">{t("SUBACCOUNTS")}</Typography>
                  </Box>
                  <Box
                    width="85px"
                    textAlign="right"
                    borderBottom="1px solid #5E6366"
                    paddingBottom="10px"
                  >
                    <Typography variant="h6">{t("AMOUNT")}</Typography>
                  </Box>
                </Box>
                {data?.map((item) => {
                  return (
                    <TableRow
                      label={item?.menuItem?.name}
                      value={item?.szepCardApplicationAmount}
                    />
                  );
                })}
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1">
                {t("IHAVENOBENEFIT", { thisYear })}
              </Typography>
            </Box>
          )}
          <Box height="36px" />
          <Typography variant="body1">{t("SZEPSTATOFRES")}</Typography>
        </Box>
      </Box>
      <MyCafeteriaSignatureBlock statement="szep" />
    </Box>
  );
};

MyCafeteriaSzepApplicationCardPrint.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaSzepApplicationCardPrint.defaultProps = {
  applicationDate: () => new Date(),
  currentYear: "2021",
  employeeName: "Pista",
  idNumber: "khkjhkjhkj",
  contractCode: "00019",
};

export default MyCafeteriaSzepApplicationCardPrintContainer;
