import { useQuery } from "@apollo/client";
import { GET_GENDERS } from "graphql/queries";

const useGenders = () => {
    const { data, loading, error } = useQuery(GET_GENDERS, {
        fetchPolicy: "cache-and-network",
    });

    const result = data?.genders || [];
    return {
        data: result,
        loading,
        error,
    };
};

export default useGenders;
