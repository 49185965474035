import gql from "graphql-tag";

export const GET_PAYCARD_IN_HTML = gql`
  query getPaycardInHTML(
    $contractCode: String!
    $year: String!
    $month: String!
  ) {
    getPaycardInHTML(contractCode: $contractCode, year: $year, month: $month) {
      paycards
    }
  }
`;
