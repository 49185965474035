/**
 * Custom hook to provide current cafeteria statemnt's data
 * in a card with a dougnut chart
 *
 * Component documentation is available in Storybook
 *
 * Pál Pintér <pinter.pal@orgware.hu>
 * Created at: 2021.06.10
 */
import { GET_CURRENT_CAFETERIA_STATEMENT } from "graphql/queries";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { CAFETERIA_SUBSCRIPTION } from "graphql/subcriptions";
import _ from "lodash";
import usePolling from "hooks/use-polling";

const useCurrentCafeteriaStatement = ({ contractCode }) => {
  const { data, loading, error, refetch } = useQuery(
    GET_CURRENT_CAFETERIA_STATEMENT,
    {
      fetchPolicy: "cache-and-network",
      pollInterval: usePolling(1000 * 60),
      skip: !contractCode,
      variables: { contractCode },
    }
  );

  const { error: subscriptionError } = useSubscription(CAFETERIA_SUBSCRIPTION, {
    onData: () => {
      refetch();
    },
  });

  /** Parse raw data received from server */
  let dataResult = data?.getCurrentCafeteriaStatement;
  let correctedResult = _.cloneDeep(dataResult);
  if (correctedResult) {
    correctedResult.allCosts = dataResult.allCosts - dataResult.allBenefits;
    correctedResult.availableAmount =
      dataResult.allAmount - dataResult.allBenefits - correctedResult.allCosts;
  }

  return {
    data: correctedResult,
    loading: loading && !data,
    error: error || subscriptionError,
  };
};

export default useCurrentCafeteriaStatement;
