import gql from "graphql-tag";

export const GET_PAYCARD_LIST_BY_CONTRACT = gql`
  query getPaycardListByContract($contractCode: String!, $year: String!) {
    getPaycardListByContract(contractCode: $contractCode, year: $year) {
      month
      netWage
      otherBenefits
      allTaxDeductions
      otherDeductions
      grossWage
    }
  }
`;
