import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_WORK_TIME_WEEKLY_DATA } from "my-time/graphql/queries";
import { MY_TIME_REQUESTS_SUBSCRIPTION } from "my-time/graphql/subscriptions";

const useWorkTimeWeeklyData = ({ contractCode, date }) => {
  const { data, loading, error, refetch } = useQuery(
    GET_WORK_TIME_WEEKLY_DATA,
    {
      fetchPolicy: "cache-and-network",
      skip: !contractCode || !date,
      variables: {
        contractCode,
        date,
      },
    }
  );

  useSubscription(MY_TIME_REQUESTS_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  return {
    data: data?.getWorkTimeWeeklyData,
    loading,
    error,
  };
};

export default useWorkTimeWeeklyData;
