import React, { useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import OcMonthSelect from "oc/components/oc-month-select";
import { InlineIcon } from "@iconify/react";
import alertIcon from "@iconify/icons-mdi/arrow-right";
import Button from "@material-ui/core/Button";
import MyCafeteriaCurrentItemIndicator from "my-cafeteria/components/my-cafeteria-current-item-indicator";
import {
  newCafeteriaStatementItemState,
  addCafeteriaItemResultState,
} from "my-cafeteria/states";
import { useRecoilState, useResetRecoilState } from "recoil";
import MyCafeteriaMenuSelect from "my-cafeteria/components/my-cafeteria-menu-select";
import TextField from "@material-ui/core/TextField";
import useCafeteriaMenu from "my-cafeteria/hooks/use-cafeteria-menu";
import { useMutation } from "@apollo/react-hooks";
import {
  ADD_CAFETERIA_STATEMENT_ITEM,
  DELETE_CAFETERIA_STATEMENT_ITEM,
  UPDATE_CAFETERIA_STATEMENT_ITEM,
} from "graphql/mutations";
import Collapse from "@material-ui/core/Collapse";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "oc/components/oc-form-switch";
import axios from "axios";
import parseJdDate from "oc/utils/parse-jd-date";
import MyCafeteriaNettoBruttoFilter from "my-cafeteria/components/my-cafeteria-netto-brutto-filter";
import useClientState from "hooks/use-oc-client-state";
import { useTheme } from "@material-ui/styles";
import MyCafeteriaCostsBox from "my-cafeteria/components/my-cafeteria-costs-box";

let apiUrl = "http://localhost:9000/api/calculate";

if (process.env.NODE_ENV === "production") {
  apiUrl = "/api/calculate";
}

const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 1000);
  };
};

const MyCafeteriaBenefitForm = ({
  id,
  onCloseForm,
  contractCode,
  inlineEdit = false,
  row,
  currentStatement,
  currentStatementItems,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let benefitIndicator = currentStatement?.allBenefits;
  let costIndicator = currentStatement?.allCosts;
  let availableAmountIndicator = currentStatement?.availableAmount;

  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [initialCalculated, setInitialCalculaTed] = React.useState();

  const [errorState, setErrorState] = useRecoilState(
    addCafeteriaItemResultState
  );

  const [cafeteriaAmount, setCafeteriaAmount] = useClientState(
    "cafeteriaAmount",
    "netto"
  );

  const resetErrorState = useResetRecoilState(addCafeteriaItemResultState);

  const [newItem, setNewItem] = useRecoilState(newCafeteriaStatementItemState);

  const [calculatedBenefit, setCalculatedBenefit] = React.useState(() => {
    if (inlineEdit) {
      return {
        benefit: row?.amount,
        cost: row?.cost,
      };
    } else {
      return {
        benefit: 0,
        cost: 0,
      };
    }
  });

  const resetNewItemState = useResetRecoilState(newCafeteriaStatementItemState);

  const [addCafeteriaStatement] = useMutation(ADD_CAFETERIA_STATEMENT_ITEM, {
    onCompleted: () => {
      resetNewItemState();
      setButtonDisabled(false);
      onCloseForm();
    },
    onError: (error) => {
      console.log({ error });
      setErrorState({
        result: true,
        messages: JSON.parse(error.message),
      });
      setButtonDisabled(false);
    },
  });

  const [deleteCafeteriaStatement] = useMutation(
    DELETE_CAFETERIA_STATEMENT_ITEM,
    {
      onCompleted: (data) => {
        resetNewItemState();
        setButtonDisabled(false);
        onCloseForm();
      },
      onError: (error) => {
        console.log({ error });
        setErrorState({
          result: true,
          messages: JSON.parse(error.message),
        });
        setButtonDisabled(false);
      },
    }
  );

  const [updateCafeteriaStatement] = useMutation(
    UPDATE_CAFETERIA_STATEMENT_ITEM,
    {
      onCompleted: (data) => {
        resetNewItemState();
        setButtonDisabled(false);
        onCloseForm();
      },
      onError: (error) => {
        console.log({ error });
        setErrorState({
          result: true,
          messages: JSON.parse(error.message),
        });
        setButtonDisabled(false);
      },
    }
  );

  const { data: cafeteriaMenuData } = useCafeteriaMenu({ contractCode });
  let selectedMenuItem = cafeteriaMenuData?.find(
    (item) => item.code === newItem.benefitType
  );

  const filteredArray = ({ start, end, array }) => {
    if (!start || !end || !array) return [];
    const startMonth = start.slice(4, 6);
    const endMonth = end.slice(4, 6);
    const filtered = array.filter((item) => {
      if (item >= startMonth && item <= endMonth) {
        return true;
      }
      return false;
    });
    return filtered;
  };

  let availableMonthsArray = filteredArray({
    start: currentStatement?.startDate,
    end: currentStatement?.endDate,
    array: selectedMenuItem?.availableMonths,
  });

  function handleCancel() {
    setInitialCalculaTed(false);
    onCloseForm();
    setCafeteriaAmount("netto");
    setTimeout(() => {
      resetNewItemState();
      resetErrorState();
      if (inlineEdit) {
        setCalculatedBenefit({
          benefit: row?.amount,
          cost: row?.cost,
        });
      } else {
        setCalculatedBenefit({
          benefit: 0,
          cost: 0,
        });
      }
    }, 500);
  }

  async function getCalculatedAmounts({ item, currentStatement, onlyCheck }) {
    if (!item?.benefitType) {
      return false;
    }

    if (!item?.startDate) {
      return false;
    }

    let params = {
      amount: item?.amount,
      bruttoAmount: item?.bruttoAmount,
      contractCode: item?.contractCode,
      currentItemStartDate: currentStatement?.startDate,
      endDate: item?.endDate,
      menuItemCode: item?.benefitType,
      startDate: item?.startDate,
      type: item?.reusable ? "repeating" : "oneTime",
      plan: item?.plan ? item?.plan : false,
      szepCardApplicationAmount: item?.szepCardApplicationAmount || 0,
    };

    if (
      (!params.amount || params.amount === 0) &&
      (!params.bruttoAmount || params.bruttoAmount === 0)
    )
      return;

    if (!item?.isPeriodical) {
      params.endDate = params.startDate;
    }

    let { data } = await axios.get(apiUrl, {
      params,
    });
    const calculated = data;
    let success = calculated.result;
    let messages = calculated.messages;

    if (onlyCheck && success) return true;

    if (!success) {
      setErrorState({
        result: true,
        messages,
      });
      return false;
    }

    let result = calculated.resultObject;
    const startDate = parseJdDate(result?.startDate).getMonth();
    const endDate = parseJdDate(result?.endDate).getMonth();
    const numberOfMonths = endDate - startDate + 1;

    if (item.amount === "") {
      const benefit = result?.amount;
      let cost = result?.cost - result?.amount;
      setCalculatedBenefit({
        benefit: benefit,
        cost: cost,
      });
      setNewItem((prevState) => {
        const newState = { ...prevState };
        newState.amount = result.amount;
        newState.bruttoAmount = result.cost;
        newState.totalCost = cost;
        return newState;
      });
    } else {
      const benefit = result?.amount * numberOfMonths;
      let cost = result?.cost - result?.amount * numberOfMonths;
      if (row) {
        cost = result?.cost - result?.amount * numberOfMonths;
      }

      setCalculatedBenefit({
        benefit: benefit,
        cost: cost,
      });
      setNewItem((prevState) => {
        const newState = { ...prevState };
        newState.amount = result.amount;
        newState.bruttoAmount = result.cost;
        newState.totalCost = cost;
        return newState;
      });
    }

    return success;
  }

  const sendRequest = useCallback((value) => {
    getCalculatedAmounts({ item: value, currentStatement, onlyCheck: false });
    // eslint-disable-next-line
  }, []);

  const debouncedCalculate = useMemo(() => {
    return debounce(sendRequest, 1300);
  }, [sendRequest]);

  function handleChange(property) {
    let newValue = Object.assign({}, newItem, property);
    setNewItem((state) => {
      let newItem = Object.assign({}, state, property);
      return newItem;
    });
    if (property.amount || property.bruttoAmount) {
      debouncedCalculate(newValue);
    }
  }

  async function handleSave() {
    let numberOfMonths = 1;
    if (newItem?.endDate) {
      numberOfMonths =
        parseInt(newItem?.endDate) - parseInt(newItem?.startDate) + 1;
    }
    let szepCardAmount = newItem?.szepCardApplicationAmount || -1;

    let newStatementItem = {
      contractCode,
      currentItemStartDate: currentStatement?.startDate,
      menuItemCode: newItem?.benefitType,
      startDate: newItem?.startDate,
      endDate: numberOfMonths === 1 ? newItem?.startDate : newItem?.endDate,
      type: newItem?.isReusable ? "repeating" : "oneTime",
      amount: parseFloat(newItem?.amount),
      bruttoAmount:
        cafeteriaAmount === "brutto" ? parseFloat(newItem?.bruttoAmount) : 0,
      plan: newItem?.plan ? newItem?.plan : false,
      szepCardApplicationAmount: szepCardAmount,
    };

    let errorMessages = [];
    if (!newStatementItem?.menuItemCode)
      errorMessages.push(t("Salary.mandatoryBenefit"));
    if (newItem?.isPeriodical) {
      if (!newStatementItem?.startDate)
        errorMessages.push(t("Salary.mandatoryStarting_month"));
      if (!newStatementItem?.endDate)
        errorMessages.push(t("Salary.mandatoryClosing_month"));
    } else {
      if (!newStatementItem?.startDate)
        errorMessages.push(t("Salary.mandatoryMonth"));
    }
    if (!newItem?.amount) {
      errorMessages.push(t("Salary.mandatoryValue"));
    }

    const isExist = currentStatementItems?.find((item) => {
      const monthArray = item?.months.split(" - ") || [];
      const startDate = monthArray[0];
      const endDate = monthArray[1];
      const overlap =
        newStatementItem?.startDate >= startDate &&
        newStatementItem?.endDate <= endDate;
      if (item.menuItem.code === newStatementItem.menuItemCode && overlap) {
        return item;
      }
      return undefined;
    });

    if (isExist) {
      errorMessages.push(t("Salary.appliedBenefit"));
    }
    if (errorMessages.length > 0) {
      setErrorState((prev) => {
        return {
          result: true,
          messages: errorMessages,
        };
      });
      return;
    }

    let isOK = await getCalculatedAmounts({ item: newItem, currentStatement, onlyCheck: true });
    if (isOK) {
      setButtonDisabled(true);
      addCafeteriaStatement({ variables: newStatementItem });
    }
  }

  async function handleUpdate() {
    let szepCardAmount = newItem?.szepCardApplicationAmount || -1;
    if (szepCardAmount === row?.szepCardApplicationAmount) {
      szepCardAmount = -1;
    }
    let params = {
      contractCode: newItem?.contractCode,
      currentItemStartDate: currentStatement?.startDate,
      menuItemCode: newItem?.menuItemCode,
      itemSerial: row?.itemSerial,
      startDate: newItem?.startDate,
      endDate: newItem?.endDate,
      type: newItem?.type,
      amount: parseFloat(newItem?.amount),
      bruttoAmount:
        cafeteriaAmount === "brutto" ? parseFloat(newItem?.bruttoAmount) : 0,
      szepCardApplicationAmount: szepCardAmount,
      plan: row?.plan,
      origin: row?.origin,
    };
    let isOK = await getCalculatedAmounts({ item: newItem, currentStatement, onlyCheck: true });
    if (isOK) {
      setButtonDisabled(true);
      updateCafeteriaStatement({ variables: params });
    }
  }

  function handleDelete() {
    let params = {
      currentItemStartDate: currentStatement?.startDate, // YYYYMMDD format
      contractCode: contractCode,
      menuItemCode: row?.menuItem?.code,
      startDate: row?.startDate,
      itemSerial: row?.itemSerial,
      endDate: row?.endDate,
      type: row?.type,
    };
    setButtonDisabled(true);
    deleteCafeteriaStatement({ variables: params });
  }

  useEffect(() => {
    if (!newItem.contractCode) {
      let tempState = { ...newItem };
      tempState.contractCode = contractCode;
      setNewItem(tempState);
    }
    // eslint-disable-next-line
  }, []);

  const netto = newItem?.amount || 0;
  const brutto = newItem?.bruttoAmount || 0;
  if (
    (netto && !brutto && !initialCalculated) ||
    (brutto && !netto && !initialCalculated)
  ) {
    setInitialCalculaTed(true);
  }

  const isNewItemValid = () => {
    if (!errorState.result) return true;
    if (newItem?.isPeriodical) {
      return (
        newItem.benefitType &&
        newItem.startDate &&
        newItem.endDate &&
        newItem.amount &&
        newItem.bruttoAmount
      );
    } else {
      return (
        newItem.benefitType &&
        newItem.startDate &&
        newItem.amount &&
        newItem.bruttoAmount
      );
    }
  };

  let showSzepCardComponent = selectedMenuItem?.isSZEPCardItem;

  const [applicationState, setApplicationState] =
    React.useState("hasNotApplication");

  if (
    newItem?.szepCardApplicationAmount > 0 &&
    applicationState === "hasNotApplication"
  ) {
    setApplicationState("hasApplication");
  }

  const handleApplicationStateChange = (event) => {
    setApplicationState(event.target.value);
  };

  let thisYear = new Date().getFullYear().toString();

  let editBenefit = newItem?.bruttoAmount;

  if (inlineEdit) {
    benefitIndicator =
      currentStatement?.allBenefits - row?.amount + calculatedBenefit?.benefit;
    costIndicator =
      currentStatement?.allCosts - row?.cost + calculatedBenefit?.cost;
    availableAmountIndicator =
      currentStatement?.allAmount - benefitIndicator - costIndicator;
    editBenefit = row?.valueOfBenefit;
  } else {
    benefitIndicator =
      currentStatement?.allBenefits + calculatedBenefit?.benefit;
    costIndicator = currentStatement?.allCosts + calculatedBenefit?.cost;
    availableAmountIndicator =
      currentStatement?.allAmount - benefitIndicator - costIndicator;
  }

  const isOverlapedItem = currentStatementItems?.find(
    (item) =>
      (item?.menuItem?.code === newItem?.benefitType &&
        newItem.startDate >= item.startDate &&
        newItem.startDate <= item.endDate) ||
      (item?.menuItem?.code === newItem?.benefitType &&
        newItem.endDate >= item.startDate &&
        newItem.endDate <= item.endDate)
  );
  const isOverlap = isOverlapedItem && !inlineEdit ? true : false;

  return (
    <form>
      <Box
        style={{
          backgroundColor: "#F1F3F4",
          padding: "16px",
          borderRadius: "4px",
          border: "1px solid #E3E5E5",
        }}
      >
        <Typography
          style={{
            fontSize: "20px",
            lineHeight: "28px",
            letterSpacing: "0.15px",
          }}
        >
          {inlineEdit ? t("EDIT_BENEFIT") : t("ADD_NEW_BENEFIT")}
        </Typography>

        <Box height="16px" />
        {cafeteriaMenuData?.length === 0 ? (
          <Box />
        ) : (
          <>
            <MyCafeteriaMenuSelect
              contractCode={contractCode}
              selectedItem={newItem?.benefitType}
              onSelectItem={(e) => {
                resetErrorState();
                return handleChange({
                  benefitType: e.code,
                  isPeriodical: e.isPeriodical,
                  isReusable: e.isReusable,
                  isPlanable: e.planable,
                });
              }}
              disabled={inlineEdit}
              currentStatementItems={currentStatementItems}
            />

            <Box height="16px" />
            <Box display="flex" flexDirection="row">
              <OcMonthSelect
                id={`start-of-period-select-${row ? row.index : "zero"}`}
                label={newItem.isPeriodical ? t("START_OF_PERIOD") : t("MONTH")}
                selectedMonth={newItem.startDate}
                onSelectMonth={(e) => {
                  resetErrorState();
                  let newValue = Object.assign({}, newItem, {
                    startDate: e,
                    endDate: e,
                  });
                  handleChange({ startDate: e, endDate: e });
                  getCalculatedAmounts({ item: newValue, currentStatement, onlyCheck: false });
                }}
                monthList={availableMonthsArray}
                disabled={inlineEdit}
              />
              {newItem.isPeriodical && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  padding="0px 22px"
                >
                  <InlineIcon icon={alertIcon} width="24px" color="#4d4d4d" />
                </Box>
              )}
              {newItem.isPeriodical && (
                <OcMonthSelect
                  id={`end-of-period-select-${row ? row.index : "zero"}`}
                  label={t("END_OF_PERIOD")}
                  selectedMonth={newItem.endDate}
                  onSelectMonth={(e) => {
                    let newValue = Object.assign({}, newItem, {
                      endDate: e,
                    });
                    handleChange({ endDate: e });
                    getCalculatedAmounts({ item: newValue, currentStatement, onlyCheck: false });
                  }}
                  monthList={availableMonthsArray}
                  disabled={inlineEdit}
                  onFocus={() => resetErrorState()}
                  startMonth={newItem.startDate}
                />
              )}
            </Box>
            <Box height="16px" />
            <Collapse in={newItem?.isPlanable} timeout="auto" unmountOnExit>
              <Box
                width="auto"
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box width="45px" textAlign="right">
                  <Typography
                    variant="body1"
                    style={{
                      textTransform: "capitalize",
                      color: theme.palette.owGray.main,
                    }}
                  >
                    {t("PLAN")}
                  </Typography>
                </Box>
                <Box width="16px" />
                <Box>
                  <Switch
                    id="cafeteria-plan"
                    checked={newItem?.plan || false}
                    onChange={(e) => {
                      return handleChange({
                        plan: !newItem?.plan,
                      });
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Box>
                <Box width="16px" />
                <Box>
                  <Typography
                    variant="body1"
                    style={{
                      color: theme.palette.owGray.main,
                    }}
                  >
                    {t("CAFETERIA_PLAN")}
                  </Typography>
                </Box>
              </Box>
            </Collapse>
            <Box height="16px" />
            <Box
              style={{
                border: `1px solid ${theme.palette.gray40.main}`,
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <MyCafeteriaNettoBruttoFilter
                cafeteriaAmount={cafeteriaAmount}
                setCafeteriaAmount={setCafeteriaAmount}
                row={row}
              />
              <Box height="16px" />
              {cafeteriaAmount === "netto" && (
                <Box
                  width="291px"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                >
                  <TextField
                    id={`my-cafeteria-benefit-amount-input-NET_AMOUNT-${
                      row ? row.index : "zero"
                    }`}
                    label={
                      newItem.isPeriodical
                        ? t("MONTHLY_NET_AMOUNT")
                        : t("NET_AMOUNT")
                    }
                    variant="outlined"
                    fullWidth
                    value={Math.round(newItem?.amount) || ""}
                    type="number"
                    onChange={(e) => {
                      let newValue = e.target.value
                        ? Number(e.target.value)
                        : "";
                      handleChange({ amount: newValue, bruttoAmount: "" });
                    }}
                    onFocus={resetErrorState}
                  />
                </Box>
              )}
              {cafeteriaAmount === "brutto" && (
                <Box
                  width="291px"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                >
                  <TextField
                    id={`my-cafeteria-benefit-amount-input-GROSS_AMOUNT-${
                      row ? row.index : "zero"
                    }`}
                    label={t("GROSS_AMOUNT")}
                    variant="outlined"
                    fullWidth
                    value={
                      Math.round(newItem?.bruttoAmount) || editBenefit || ""
                    }
                    type="number"
                    onChange={(e) => {
                      let newValue = e.target.value
                        ? Number(e.target.value)
                        : "";
                      handleChange({ bruttoAmount: newValue, amount: "" });
                    }}
                    onFocus={resetErrorState}
                  />
                </Box>
              )}
              <Box>
                <Box height="16px" />
                <Box display="flex">
                  <MyCafeteriaCostsBox
                    label={
                      newItem.isPeriodical
                        ? t("MONTHLY_NET_AMOUNT")
                        : t("NET_AMOUNT")
                    }
                    currencyValue={newItem?.amount || ""}
                  />
                  <Box width="16px" />
                  <MyCafeteriaCostsBox
                    label={t("GROSS_AMOUNT")}
                    currencyValue={newItem?.bruttoAmount || editBenefit || ""}
                  />
                </Box>
              </Box>

              <Box height="16px" />
              <Typography variant="body1" style={{ color: "#5E6366" }}>
                {t("MONTHLY_BRUTTO_INFO")}
              </Typography>
              <Typography variant="body1" style={{ color: "#5E6366" }}>
                {t("BRUTTO_INFO")}
              </Typography>
            </Box>
          </>
        )}
        <Box height="16px" />

        <Box display="flex" flexDirection="row">
          <MyCafeteriaCurrentItemIndicator
            label={t("USED_BENEFIT")}
            value={benefitIndicator}
            color="#2FA84F"
            width="33%"
          />
          <Box width="16px" />
          <MyCafeteriaCurrentItemIndicator
            label={t("COST")}
            value={costIndicator}
            color="#F3AA18"
            width="33%"
          />
          <Box width="16px" />
          <MyCafeteriaCurrentItemIndicator
            label={t("REMAINS")}
            value={availableAmountIndicator}
            color="#cc0033"
            width="33%"
          />
        </Box>
        <Collapse in={showSzepCardComponent} timeout="auto" unmountOnExit>
          <Box
            style={{
              height: "16px",
              borderBottom: "1px solid #C6CACC",
            }}
          />
          <Box paddingTop="16px">{t("IHAVEBENEFIT", { thisYear })}</Box>
          <Box padding="16px">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="application"
                value={applicationState}
                onChange={handleApplicationStateChange}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="hasNotApplication"
                  control={
                    <Radio
                      id={`my-cafeteria-other-employer-1-${
                        row ? row.index : "zero"
                      }`}
                    />
                  }
                  label={t("IHAVENOBENEFIT", { thisYear })}
                />
                <FormControlLabel
                  value="hasApplication"
                  control={
                    <Radio
                      id={`my-cafeteria-other-employer-2-${
                        row ? row.index : "zero"
                      }`}
                    />
                  }
                  label={t("SZEPBENEFITNAME", { thisYear })}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Collapse
            in={applicationState === "hasApplication"}
            timeout="auto"
            unmountOnExit
          >
            <Box>
              <Box
                width="291px"
                style={{
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
              >
                <TextField
                  //id={stateId}
                  id={`my-cafeteria-other-employer-amount-${
                    row ? row.index : "zero"
                  }`}
                  label={t("AMOUNT")}
                  variant="outlined"
                  fullWidth
                  value={newItem?.szepCardApplicationAmount}
                  type="number"
                  onChange={(e) => {
                    let newValue = e.target.value ? Number(e.target.value) : "";
                    handleChange({
                      szepCardApplicationAmount: newValue,
                    });
                  }}
                />
              </Box>
              <Box height="16px" />
              <Typography variant="body1">{t("SUM_OTHER_BENEFIT")} </Typography>
            </Box>
          </Collapse>
          <Box
            style={{
              height: "16px",
              borderBottom: "1px solid #C6CACC",
            }}
          />
        </Collapse>
        <Collapse
          in={errorState.result || availableAmountIndicator < 0 || isOverlap}
          timeout="auto"
          unmountOnExit
        >
          <Box id="error-box" color="#cc0033" paddingTop="16px">
            <Box id="error-description" paddingLeft="6px">
              {errorState?.messages?.map((message, index) => (
                <Typography key={`error-message-${index}}`} variant="body1">
                  {message}
                </Typography>
              ))}
            </Box>
            {availableAmountIndicator < 0 && (
              <Box paddingLeft="6px">
                <Typography variant="body1">{t("OUT_OF_BOUNDS")}</Typography>
              </Box>
            )}
            {isOverlap === true && (
              <Box paddingLeft="6px">
                <Typography variant="body1">
                  {t("Salary.appliedBenefit")}
                </Typography>
              </Box>
            )}
          </Box>
        </Collapse>
        {cafeteriaMenuData?.length === 0 && (
          <>
            <Box height="16px" />
            <Typography variant="body1" style={{ color: "#CC0033" }}>
              {t("BENEFIT_ELEMENT_IS_NOT_DEFINED")}
            </Typography>
          </>
        )}
        <Box
          paddingTop="40px"
          display="flex"
          flexDirection="row"
          justifyContent={inlineEdit ? "space-between" : "flex-end"}
        >
          {inlineEdit && (
            <Box>
              <Button
                id={`my-cafeteria-benefit-form-delete-button-${
                  row ? row.index : "zero"
                }`}
                onClick={handleDelete}
                variant="text"
                color="secondary"
              >
                {t("DELETE")}
              </Button>
            </Box>
          )}
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              id={`my-cafeteria-benefit-form-cancel-button-${
                row ? row.index : "zero"
              }`}
              onClick={handleCancel}
            >
              {t("CANCEL")}
            </Button>
            <Box width="16px" />
            <Button
              id={`my-cafeteria-benefit-form-add-button-${
                row ? row.index : "zero"
              }`}
              variant="contained"
              color="primary"
              disabled={
                inlineEdit
                  ? (newItem?.amount === row?.amount &&
                      newItem?.szepCardApplicationAmount ===
                        row?.szepCardApplicationAmount) ||
                    availableAmountIndicator < 0 ||
                    buttonDisabled
                  : !isNewItemValid() ||
                    cafeteriaMenuData?.length === 0 ||
                    buttonDisabled ||
                    isOverlap
              }
              onClick={inlineEdit ? handleUpdate : handleSave}
            >
              {inlineEdit ? t("SAVE") : t("ADD")}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

MyCafeteriaBenefitForm.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaBenefitForm.defaultProps = {
  example: "example",
};

export default MyCafeteriaBenefitForm;
