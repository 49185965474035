import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useOcClientState from "hooks/use-oc-client-state-2";
import useCurrentUserState from "hooks/use-current-user-state";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";

const OcPageSideMenu = ({ stateId, menuDef }) => {
  const { t } = useTranslation();

  let { contractCode } = useCurrentUserState();
  const { hasRole } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const [selectedItem, setSelectedItem] = useOcClientState(
    stateId,
    menuDef[0].id
  );

  function handleSelect(itemId) {
    setSelectedItem(itemId);
  }

  const [activeItem, setActiveItem] = React.useState();

  return (
    <Box>
      {menuDef.map((item) => {
        let hasRight = hasRole(item.roles, item.notAllowedRoles);
        if (!hasRight) return null;
        if (!activeItem) setActiveItem(item.id);
        return (
          <Box
            key={item.id}
            width="240px"
            height="40px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            paddingLeft="40px"
            onClick={() => handleSelect(item.id)}
            style={
              selectedItem === item.id
                ? {
                    backgroundColor: "white",
                    borderLeft: "4px solid #cc0033",
                  }
                : { cursor: "pointer" }
            }
          >
            <Box
              id={item.id}
              style={{ fontSize: "14px", letterSpacing: "0.25px" }}
            >
              {t(item.label)}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

OcPageSideMenu.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

OcPageSideMenu.defaultProps = {
  example: "example",
};

export default OcPageSideMenu;
