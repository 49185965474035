import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chevron: {
    cursor: "pointer",
    padding: "10px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.gray20.main,
    },
  },
  icon: {
    color: theme.palette.gray80.main,
    width: "24px",
    height: "24px",
  },
  text: {
    color: theme.palette.gray80.main,
    paddingLeft: "19px",
  },
}));

export default useStyles;
