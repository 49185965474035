import React from "react";
// import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import MyCafeteriaSummaryCard from "my-cafeteria/components/my-cafeteria-summary-card";
import MyCafeteriaBenefitsCard from "my-cafeteria/components/my-cafeteria-benefits-card";
import MyCafeteriaApplicationsCard from "my-cafeteria/components/my-cafeteria-applications-card";
import MyCafeteriaDownloadsCard from "my-cafeteria/components/my-cafeteria-downloads-card";
import MyCafeteriaArchiveApplicationsCard from "my-cafeteria/components/my-cafeteria-archive-applications-card";
import Grid from "@material-ui/core/Grid";
import useCurrentUserState from "hooks/use-current-user-state";
import OcPageTransition from "oc/components/oc-page-transition";
import useCurrentCafeteriaStatement from "my-cafeteria/hooks/use-current-cafeteria-statement";

const MyCafeteriaPage = () => {
  const Divider = () => {
    return <Box height="16px" />;
  };

  let { contractCode } = useCurrentUserState();

  /** Use a custom hook to fetch data from the server */
  const {
    data: currentStatementData,
    loading: currentStatementLoading,
    error: currentStatementError,
  } = useCurrentCafeteriaStatement({
    contractCode,
  });

  return (
    <OcPageTransition>
      <Box
        style={{
          backgroundColor: "#f1f3f4",
          width: "auto",
          padding: "16px",
        }}
      >
        <MyCafeteriaSummaryCard
          contractCode={contractCode}
          data={currentStatementData}
          loading={currentStatementLoading}
          error={currentStatementError}
        />
        <Divider />
        <MyCafeteriaBenefitsCard
          contractCode={contractCode}
          currentStatement={currentStatementData}
        />
        <Divider />
        <Grid container spacing={2}>
          <Grid id="my-cafeteria-benefits-card-statements" item xs={6}>
            <MyCafeteriaApplicationsCard />
          </Grid>
          <Grid id="my-cafeteria-downloads-card" item xs={6}>
            <MyCafeteriaDownloadsCard
              currentStatementData={currentStatementData}
            />
          </Grid>
        </Grid>
        <Divider />
        <MyCafeteriaArchiveApplicationsCard contractCode={contractCode} />
      </Box>
    </OcPageTransition>
  );
};

MyCafeteriaPage.propTypes = {};

MyCafeteriaPage.defaultProps = {};

export default MyCafeteriaPage;
