import React from "react";
import { Box, Typography } from "@material-ui/core";
import OcPrivateLayoutFooter from "oc/components/oc-private-layout-footer";
import useStyles from "../family-tax-credit-information/family-tax-credit-information.style";

const UnderThirtyMothersTaxCreditInformation = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box padding="16px" style={{ backgroundColor: "#F1F3F4" }}>
        <Box>
          <Typography
            variant="h4"
            style={{
              fontSize: "34px",
              fontWeight: "400",
              color: "#5E6366",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Tájékoztató
          </Typography>
          <Box height="32px" />
          <Typography
            variant="h5"
            style={{
              fontSize: "24px",
              fontWeight: "400",
              color: "#212121",
              display: "flex",
              justifyContent: "center",
            }}
          >
            a költségekről szóló adóelőleg-nyilatkozathoz
          </Typography>
          <Box height="32px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography variant="body1">
                Ezt a nyilatkozatot két példányban töltse ki, és járandóságainak
                számfejtése előtt adja át a kifizetőnek! Ha a nyilatkozatban
                közölt adatok változnak, Ön köteles haladéktalanul új
                nyilatkozatot tenni, vagy a korábbi nyilatkozatot visszavonni. A
                nyilatkozat egyik példányát a kifizetőnek, másik példányát pedig
                Önnek kell az adóbevallás elkészítéséhez szükséges
                bizonylatokkal együtt az elévülési időn belül, azaz a bevallás
                benyújtásának évét követő 5. év végéig megőriznie. A
                tevékenységgel összefüggésben felmerülő kiadásokat igazoló
                bizonylatokat a kifizetőnek bemutatni nem kell, azokat csak meg
                kell őrizni.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <i>
                  Ha Ön tételes igazolással elszámolható költség levonását kéri,
                  és a nyilatkozat alapján levont költség a nyilatkozat szerinti
                  költség 5 százalékával meghaladja az adóbevallásban utóbb
                  elszámolt, ténylegesen igazolt költséget, akkor Önnek 39
                  százalék, továbbá ha emiatt az adóbevallás alapján 10 ezer
                  forintot meghaladó befizetési különbözet is mutatkozik, a
                  befizetési különbözet után 12 százalék különbözeti-bírságot is
                  fizetnie kell.
                </i>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>
                  A kifizetőnek nem kell adóelőleget megállapítania az
                  őstermelői tevékenységből származó bevételből, ha a
                  magánszemély legkésőbb a kifizetéskor őstermelői jogállását
                  igazolja. A kifizetőnek a vállalkozói bevételből sem kell
                  adóelőleget megállapítania, ha az egyéni vállalkozó a
                  bizonylaton feltünteti a vállalkozói jogállását bizonyító
                  közokirat számát.
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>Az 1. ponthoz:</b> az önálló tevékenységéből származó
                bevételéből, például a megbízási díjból az adóelőleg alapjának
                megállapításához Ön kétféle költséglevonást kérhet: a 10
                százalékos költséghányad, vagy a tételes költségelszámolás
                szerinti költség figyelembevételét. A tételes költségelszámolás
                választásánál az adóelőleg megállapításakor a kifizető által
                figyelembe vehető költség legfeljebb a bevétel 50 százaléka
                lehet. Ha az igazoltan felmerült költsége az előbbiek 50
                százalékát meghaladja, azt év végén, az adóbevallásában
                érvényesítheti.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>
                  Ha ezt a Nyilatkozatot Ön nem teszi meg, és más igazolás
                  nélkül elszámolható költségek nem merülnek fel, a kifizető
                  önálló tevékenység esetén automatikusan a 10 százalékos
                  költséghányadot alkalmazza, azaz a bevétel 90 százalékát
                  tekinti az adóelőleg megállapításakor jövedelemnek.
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A 10 százalékos költséghányad, vagy a tételes költségelszámolás
                szerinti költség elszámolását Önnek az adóévben valamennyi
                önálló tevékenységből származó bevételére – az önálló
                tevékenységre tekintettel kapott költségtérítést is beleértve –
                azonosan kell alkalmaznia. Ha azonban az adóelőleg-alapok
                meghatározása a 10 százalék költséghányad levonásával történt,
                Ön utóbb az adóbevallásában az adóalap meghatározásához
                alkalmazhatja a tételes költségelszámolást, ha a bevallásban a
                10 százalék költséghányad levonását más bevételeinek egyikénél
                sem érvényesíti. Azonban, ha az adóelőlegnél tételes
                költségelszámolásról nyilatkozott, akkor az év végi
                adóbevallásában nem alkalmazhatja a 10 százalékos
                költséghányadot.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>A 2. ponthoz: </b>az 1. pontban leírtak érvényesek az önálló
                tevékenységre tekintettel kapott költségtérítésre is azzal az
                eltéréssel, hogy az 50 százalékos korlát a költségtérítésre nem
                vonatkozik.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>A 3. ponthoz:</b> a nem önálló tevékenységből, például
                munkaviszonyból származó bevételnél költségnyilatkozatot csak
                akkor lehet adni, ha a kifizető, a munkáltató költségtérítést is
                fizet.{" "}
                <b>
                  Ha Ön nem kér költséglevonást és nem merül fel a törvény által
                  igazolás nélkül elismerhető költsége sem, akkor a
                  költségtérítés teljes összegét figyelembe kell venni az
                  adóelőleg-alap meghatározásakor.
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b> A 4. ponthoz:</b> Ön nyilatkozhat úgy is, hogy az önálló,
                illetve a nem önálló tevékenységgel összefüggésben kapott
                költségtérítés összegéből az igazolás nélkül elszámolható
                költség levonását kéri. Ilyen esetben minden további költséget
                elszámoltnak kell tekinteni, vagyis a bevétellel szemben sem
                költséghányad, sem pedig további elismert költség nem vehető
                figyelembe.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>Az 5. ponthoz:</b> ha Ön magánszemélyként lakást ad bérbe,
                lehetősége van arra, hogy az ebből származó bevételből levonja
                az általa más településen bérbe vett lakás ugyanazon évben
                igazoltan megfizetett bérleti díját. A bevétel ilyen címen
                azonban csak akkor csökkenthető, ha a bérbeadás, illetve a
                bérbevétel időtartama meghaladja a 90 napot és a bérbe vett
                lakással összefüggésben a magánszemély más tevékenységből
                származó bevételével szemben nem számol el költséget, vagy Önnek
                az igazoltan megfizetett bérleti díjat még részben sem térítik
                meg (1.).
              </Typography>
              <Typography variant="body1">
                Ön nyilatkozhat a kifizető bérbevevőnek, hogy ezt a szabályt
                alkalmazza. Ez esetben a kifizetőnek nem kell adóelőleget
                megállapítania. Ha a lakás bérbeadásából mégis származik
                adóköteles jövedelme, e jövedelem után az adóelőleget Önnek kell
                megállapítania és a juttatás negyedévét követő hónap 12-éig
                megfizetnie.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>Az 6. ponthoz:</b> ha Ön belföldi árufuvarozásban és
                személyszállításban gépkocsivezetőként vagy árukísérőként
                foglalkoztatott személyként teszi meg ezt a nyilatkozatot, akkor
                a napi 6 órát meghaladó időtartamú belföldi hivatalos kiküldetés
                címén kapott bevételéből – igazolás nélkül elismert költségként
                – napi 3 000 forint napidíjátalány levonását kérheti (2.).
              </Typography>
              <Typography variant="body1">
                2023. március 30-tól 9 000 forint napidíjátalány számolható el
                igazolás nélkül, figyelemmel az előzőekben leírt további
                feltételekre.(3.)
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha ezt az elszámolási módszert alkalmazza, ezen kívül kizárólag
                a gépjármű belföldi üzemeltetéséhez közvetlenül kapcsolódó,
                számlával, más bizonylattal igazolt költségeket számolhatja el.
                E szabály alkalmazásában az engedélyhez kötött belföldi közúti
                közlekedési szolgáltatással esik egy tekintet alá a saját célú,
                úgynevezett saját számlás áruszállítási, személyszállítási
                tevékenység is, amely más személy részére jogszerűen csak
                engedélyhez kötött belföldi közúti közlekedésként lenne
                nyújtható.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Akkor számolható el ilyen címen napidíj, ha a távollét
                időtartama a napi hat órát meghaladja és a munkáltató a
                kiküldetésre nem alkalmazza a Korm.rendelet 3. §-át, és a
                kiküldetés többletköltségei után más juttatást sem ad, például a
                szállást, utazást nem téríti meg.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>A 7. ponthoz:</b> ha Ön munkaviszonyban külszolgálatot, vagy
                kifizető által elrendelt külföldi kiküldetést teljesítő
                nemzetközi árufuvarozásban és személyszállításban közreműködő
                gépkocsivezetőként, árukísérőként teszi meg ezt a nyilatkozatot,
                a külföldi kiküldetés (külszolgálat) címén kapott bevételéből,
                azaz a napidíjából – igazolás nélkül elismert költségként – napi
                60 eurónak megfelelő forintösszeg vonható le (4.). Ebben az
                esetben kizárólag a gépjármű külföldi üzemeltetéséhez
                közvetlenül kapcsolódó és számlával, más bizonylattal igazolt
                költségeket számolhatja el, azaz nem számít elismert költségnek
                a külföldi szállás díjára fordított összeg. 2023. március 30-tól
                igazolás nélkül napi 85 euró számolható el, figyelemmel az
                előzőekben leírt feltételekre. (5.) A 85 euró napidíjátalányt a
                2023. március 30-án folyamatban levő kiküldetésekre is
                alkalmazni kell.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b> Az 8. ponthoz:</b> ha Ön ezt a nyilatkozatot megteszi, az
                1-7. pontban tett nyilatkozatát a kifizető, a munkáltató az
                adóéven belül folyamatosan, a nyilatkozat visszavonásáig
                figyelembe veszi. Ha ezt a nyilatkozatot nem teszi meg, a
                kifizető, a munkáltató az 1-7. pontban tett nyilatkozatát csak
                az adott kifizetésre vonatkozóan veszi figyelembe.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>A 9. ponthoz:</b> Ezt a pontot a kifizető, a munkáltató tölti
                ki.
              </Typography>
            </Box>
            <Box marginLeft="16px">
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (1.) A személyi jövedelemadóról szóló 1995. évi CXVII. törvény
                  17. § (5) bekezdés.
                </Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (2.) A belföldi hivatalos kiküldetést teljesítő munkavállaló
                  költségtérítéséről szóló 437/2015. (XII. 28.) Korm. rendelet.
                </Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (3.) A hazai közúti fuvarozói vállalkozások
                  versenyképességének javítását célzó egyes kormányrendeletek
                  módosításáról szóló 96/2023. (III. 29.) Korm. rendelete 3.
                  §-a. Hatályos 2023. március 30-tól.
                </Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (4.) A külföldi kiküldetéshez kapcsolódó elismert költségekről
                  szóló 285/2011. (XII. 22.) Korm. rendelet.
                </Typography>
              </Box>
              <Box className={classes.withoutSeparator} height="25px" />
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (5.) A hazai közúti fuvarozói vállalkozások
                  versenyképességének javítását célzó egyes kormányrendeletek
                  módosításáról szóló 96/2023. (III. 29.) Korm. rendelete 1.-2.
                  §-a. Hatályos 2023. március 30-tól.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box height="25px" />
      </Box>
      <OcPrivateLayoutFooter />
    </Box>
  );
};

export default UnderThirtyMothersTaxCreditInformation;
