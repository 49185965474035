import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import LoadingComponent from "@material-ui/lab/Skeleton";
import useStyles from "./my-cafeteria-period-label.style";
import { useTranslation } from "react-i18next";

const MyCafeteriaPeriodLabel = ({
  loading,
  startDate,
  endDate,
  backroundColor,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Box>
      <Box
        id="my-cafeteria-summary-card-period-title"
        className={classes.periodTitle}
      >
        {t("PERIOD")}
      </Box>
      {loading ? (
        <LoadingComponent
          variant="text"
          animation="wave"
          height={50}
          width={448}
          className={classes.skeleton}
        />
      ) : (
        <Box id="my-cafeteria-summary-card-period" className={classes.period}>
          {startDate.toLocaleDateString(i18n.language)} -{" "}
          {endDate.toLocaleDateString(i18n.language)}
        </Box>
      )}
    </Box>
  );
};

MyCafeteriaPeriodLabel.propTypes = {
  /** Example prop type description*/
  loading: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  backgroundColor: PropTypes.string,
};

MyCafeteriaPeriodLabel.defaultProps = {
  loading: false,
  startDate: new Date(),
  endDate: new Date(),
};

export default MyCafeteriaPeriodLabel;
