import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const selectedTaxDeclarationState = atom({
  key: "selectedTaxDeclaration",
  default: {
    editable: true,
    tableId: null,
    year: null,
    type: null,
    state: null,
    isModified: false,
  },
  effects_UNSTABLE: [persistAtom],
});
