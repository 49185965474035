import { atom } from "recoil";
import persistAtom from "./persist-atom";

export const addPermissionEmailState = atom({
  key: "addPermissionEmailState",
  default: {
    value: "",
    error: false,
    errorText: "",
    label: "EMAIL",
  },
  effects_UNSTABLE: [persistAtom],
});
