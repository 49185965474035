import { atom } from "recoil";
import persistAtom from "./persist-atom";

export const customerLicencesState = atom({
  key: "customerLicencesState",
  default: {
    company: "001",
    myHR: true,
    myHRChangeData: true,
    myLeave: true,
    myLeaveEmployeeRequests: true,
    myCafeteria: false,
    myTime: false,
    myTimeEmployeeRequests: false,
  },
  effects_UNSTABLE: [persistAtom],
});
