import React from "react";
import useLeaveSummaryChartData from "hooks/use-my-leave-summary-chart-data";
import MyLeaveBar from "my-leave/components/my-leave-bar";

const LeaveCovidplustenCard = ({ selectedYear, contract }) => {
  const { data } = useLeaveSummaryChartData({
    year: selectedYear,
    contract,
  });

  if (!data?.allCovidPlus10LeaveDays || data?.allCovidPlus10LeaveDays === 0) {
    return null;
  }

  return (
    <MyLeaveBar
      id="covid-leave-days-bar"
      label={"Leave.leaveCovidPlus10"}
      all={data?.allCovidPlus10LeaveDays}
      available={data?.availableCovidPlus10LeaveDays}
      requested={data?.requestedCovidPlus10LeaveDays}
      fulfilled={data?.fulfilledCovidPlus10Days}
    />
  );
};
export default LeaveCovidplustenCard;
