import gql from "graphql-tag";

export const GET_ORPHANED_REQUESTS_LIST = gql`
  query orphanedRequestsList {
    orphanedRequestsList {
      _id
      startDate
      endDate
      type
      workDays
      contract
      employee
      name
      orgUnit
      jobClass
    }
  }
`;
