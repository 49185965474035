import _ from "lodash";
import { roleFiltersState } from "states";
import { useResetRecoilState, useSetRecoilState, useRecoilValue } from "recoil";

const useRoleFilters = ({ contract, product, role }) => {
  const stateId = product + role + contract;
  const prevStateId = "prev" + product + role + contract;

  const roleFilters = useRecoilValue(roleFiltersState(stateId));
  const setRoleFilters = useSetRecoilState(roleFiltersState(stateId));
  const resetRoleFilters = useResetRecoilState(roleFiltersState(stateId));

  const prevRoleFilters = useRecoilValue(roleFiltersState(prevStateId));
  const setPrevRoleFilters = useSetRecoilState(roleFiltersState(prevStateId));

  function handleAddFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    if (typeof value === "object") {
      newState[key] = value;
    } else {
      if (_.isEqual(newState[key], ["-"])) {
        newState[key] = [value];
      } else {
        newState[key] = newState[key].concat([value]);
      }
    }
    setRoleFilters(newState);
  }

  function handleRemoveFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    newState[key] = newState[key].filter((item) => item !== value);
    setRoleFilters(newState);
  }

  return {
    roleFilters,
    setRoleFilters,
    prevRoleFilters,
    setPrevRoleFilters,
    resetRoleFilters,
    handleAddFilter,
    handleRemoveFilter,
  };
};

export default useRoleFilters;
