import { useQuery } from "@apollo/client";
import { GET_EXAMINERS_NAME } from "graphql/queries";
import usePolling from "hooks/use-polling";

const useExaminersName = ({ locale }) => {
  const { data, loading, error } = useQuery(GET_EXAMINERS_NAME, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: { locale },
  });

  const result = data?.getExaminersName || [];

  return {
    data: result,
    loading,
    error,
  };
};

export default useExaminersName;
