import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import moment from "moment";
import "moment/locale/hu";
import MenuItem from "@material-ui/core/MenuItem";

function getMonthList(locale, monthList) {
  if (!monthList) {
    const year = new Date().getFullYear();
    const months = [...Array(12).keys()];

    return months.map((index) => {
      let month = index + 1;
      if (month.toString().length === 1) {
        month = `0${month}`;
      }
      let date = new Date(`${year}-${month}-01`);
      let indexName = moment(date).locale(locale).format("MM");
      let monthName = moment(date).locale(locale).format("MMMM");
      return { index: indexName, name: monthName };
    });
  }
  const year = new Date().getFullYear();

  return monthList.map((month) => {
    let date = new Date(`${year}-${month}-01`);
    let indexName = moment(date).locale(locale).format("MM");
    let monthName = moment(date).locale(locale).format("MMMM");
    return { index: indexName, name: monthName };
  });
}

const useStyles = makeStyles((theme) => ({
  formControl: ({ width }) => ({
    minWidth: width,
    width: width,
    backgroundColor: "white",
  }),
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const OcMonthSelect = ({
  id,
  label,
  selectedMonth,
  onSelectMonth,
  width,
  monthList,
  disabled = false,
  startMonth,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles({ width });
  const months = getMonthList(i18n.language, monthList);

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      disabled={disabled}
    >
      <InputLabel htmlFor={id}>{capitalize(label)}</InputLabel>
      <Select
        id={id}
        value={selectedMonth ? selectedMonth : ""}
        onChange={(o) => onSelectMonth(o.target.value)}
        label={label}
        inputProps={{
          id: id,
          style: {
            backgroundColor: "white",
          },
        }}
        disabled={disabled}
      >
        <MenuItem value="">-- {t("NOT_SELECTED")} --</MenuItem>
        {months.map((item, index) => (
          <MenuItem
            id={`${index}-${id}`}
            key={item.index}
            disabled={item.index < startMonth}
            value={item.index}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

OcMonthSelect.propTypes = {
  /** Example prop type description*/
  label: PropTypes.string.isRequired,
  /** Width of the select component */
  width: PropTypes.string,
};

OcMonthSelect.defaultProps = {
  id: "unspecified-select-id",
  label: "unspecified label",
  width: "290px",
};

export default OcMonthSelect;
