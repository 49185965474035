import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import {
  InMemoryCache,
  ApolloClient,
  HttpLink,
  ApolloLink,
  split,
  from,
} from "@apollo/client/core";
import { ApolloProvider } from "@apollo/react-hooks";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import Loading from "oc/components/oc-loading";
import useCurrentUserState from "hooks/use-current-user-state";
import { useRecoilState } from "recoil";
import { refreshTokenState } from "states";
import _ from "lodash";
import { setContext } from "@apollo/client/link/context";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import ApolloClientContext from "context/apollo-client-context";

let apiUrl = `http://${window.location.hostname}:9000/graphql`;
let wsUrl = `ws://${window.location.hostname}:9000/graphql`;

if (process.env.NODE_ENV === "production") {
  apiUrl = "/graphql";
  let wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
  wsUrl = `${wsProtocol}://${window.location.host}/graphql`;
}

export default function OcApolloContainer({ children }) {
  let { contractCode, accessToken } = useCurrentUserState();

  const [client, setClient] = useState();
  const [persistor, setPersistor] = useState();

  const [refreshTokenError, setRefreshTokenError] =
    useRecoilState(refreshTokenState);

  const [isHidden, setIsHidden] = useState(false);

  useLayoutEffect(() => {
    function handleHiddenState() {
      setIsHidden(document.hidden);
    }
    window.addEventListener("visibilitychange", handleHiddenState);
    return () =>
      window.removeEventListener("visibilitychange", handleHiddenState);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache({
        typePolicies: {
          Subscription: {
            fields: {
              userRolesDataByContract: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
              getEmailsByCompanyList: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
              leaveRequestsByContract: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
              permit: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
          Mutation: {
            fields: {
              userRolesDataByContract: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
          Query: {
            fields: {
              userRolesDataByContract: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
              getCurrentUser: {
                merge(existing, incoming) {
                  return Object.assign({}, existing, incoming);
                },
              },
              getCafeteriaMenu: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
              getCurrentCafeteriaStatement: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
              leaveYearlyDaysByContract: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
              getContractFilterList: {
                keyArgs: false,
                merge(existing, incoming) {
                  if (existing) {
                    let newState = { ...incoming };
                    newState.availableList = [
                      //...existing.availableList,
                      ...incoming.availableList,
                    ];
                    newState.availableList = _.uniqBy(
                      newState.availableList,
                      "code"
                    );
                    newState.selectedList = [
                      //...existing.selectedList,
                      ...incoming.selectedList,
                    ];
                    newState.selectedList = _.uniqBy(
                      newState.selectedList,
                      "code"
                    );
                    newState.searchedList = [
                      //...existing.searchedList,
                      ...incoming.searchedList,
                    ];
                    newState.searchedList = _.uniqBy(
                      newState.searchedList,
                      "code"
                    );
                    return newState.availableList.length > newState.total
                      ? existing
                      : newState;
                  }
                  return incoming;
                },
              },
              orgUnitsAdminList: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              workingPlacesAdminList: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              jobClassGroupsAdminList: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              payOfficesAdminList: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              contractsAdminList: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              filteredCompanies: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              orgUnits: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              workingPlaces: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              jobClassGroups: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              payOffices: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              contracts: {
                keyArgs: false,
                merge(existing, incoming) {
                  const result = { ...existing, ...incoming };
                  return result;
                },
              },
              shortContractList: {
                keyArgs: false,
                merge(existing, incoming) {
                  /*
                  if (existing) {
                    let newState = { ...incoming };
                    newState.availableList = _.unionWith(
                      newState.availableList,
                      existing.availableList,
                      _.isEqual
                    );

                    if (incoming.selectedList.length > 0) {
                      newState.availableList = _.differenceWith(
                        newState.availableList,
                        newState.selectedList,
                        _.isEqual
                      );
                    }

                    return newState.availableList.length > newState.total
                      ? existing
                      : newState;
                  }
                  */
                  return incoming;
                },
              },
            },
          },
        },
      });

      const httpLink = new HttpLink({
        uri: apiUrl,
        credentials: "include",
      });

      const wsLink = new WebSocketLink({
        uri: wsUrl,
        options: {
          reconnect: true,
        },
      });

      const splitLink = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        httpLink
      );

      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : "",
            contractcode: contractCode ? `Contractcode ${contractCode}` : "",
          },
        };
      });

      const filterLink = new ApolloLink((operation, forward) => {
        const action = operation.operationName;
        const exceptions = [
          "login",
          "owLogin",
          "ssoLogin",
          "forgotPassword",
          "property",
          "companies",
          "permit",
          "requestPermit",
          "addPermit",
        ];

        const isInExceptions = exceptions.includes(action);

        if (isInExceptions && !isHidden) {
          return forward(operation);
        }

        if (action === "refreshToken" && accessToken) {
          return forward(operation);
        }

        if (!refreshTokenError && accessToken && !isHidden) {
          return forward(operation);
        }
      });

      const errorLink = onError(
        ({ graphQLErrors, networkError, operation }) => {
          if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path, extensions }) => {
              if (
                message === "Client needs to refresh the tokens!" ||
                message === "All tokens are expired!"
              ) {
                if (!refreshTokenError) {
                  setRefreshTokenError(true);
                }
              }
              const consoleInfo = {
                type: "GraphQL error",
                message,
                locations,
                path,
                action: operation.operationName,
                extensions,
              };
              console.log(consoleInfo);
              return "";
            });
          if (networkError) {
            console.log(`[Network error]: ${networkError}`);
          }
        }
      );

      const links = from([errorLink, authLink, filterLink, splitLink]);

      if (contractCode) {
        let newPersistor = new CachePersistor({
          cache,
          storage: new LocalStorageWrapper(window.sessionStorage),
          trigger: "write",
        });
        await newPersistor.restore();
        setPersistor(newPersistor);
      }

      setClient(
        new ApolloClient({
          link: links,
          cache,
        })
      );
    }

    init().catch(console.error);
  }, [
    isHidden,
    accessToken,
    contractCode,
    refreshTokenError,
    setRefreshTokenError,
  ]);

  const clearCache = useCallback(async () => {
    if (!persistor) {
      return;
    }
    await persistor.pause();
    await client.clearStore();
    await persistor.purge();
    await client.clearStore();
    window.sessionStorage.clear();
    setPersistor(null);
  }, [persistor, client]);

  if (client === undefined) return <Loading show={true} />;

  return (
    <ApolloProvider client={client}>
      <ApolloClientContext.Provider value={{ client, persistor, clearCache }}>
        {children}
      </ApolloClientContext.Provider>
    </ApolloProvider>
  );
}
