import gql from "graphql-tag";

export const GET_CURRENT_CAFETERIA_STATEMENT = gql`
  query getCurrentCafeteriaStatement($contractCode: String!) {
    getCurrentCafeteriaStatement(contractCode: $contractCode) {
      _id
      contractCode
      allAmount
      allBenefits
      allCosts
      availableAmount
      state
      startDate
      endDate
    }
  }
`;
