import React, { useState } from "react";
import OcCard from "oc/components/oc-card/oc-card";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useAdmission from "my-time/hooks/use-admission";
import OcDataTable from "oc/components/oc-data-table/oc-data-table";
import { employeeAdmissionsByAdmin } from "my-time/configs/tableDefs";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import { useHistory } from "react-router-dom";
import MyTimeCalendar from "../my-time-calendar/my-time-calendar";
import MyTimeInfoDialog from "my-time/components/my-time-info-dialog";
import useEmployee from "hooks/use-myhr-employee";
import getFullname from "oc/utils/get-fullname";
import useProperty from "hooks/use-oc-property";
import { parseActions } from "my-time/utils";
import useRecordingPeriod from "my-time/hooks/use-recording-period";
import useActualDate from "my-time/hooks/use-actual-date";
import { countDailyHours } from "my-time/utils";

const MyTimeAdmissionCardByAdmin = ({
  contractCode,
  employeeCode,
  year,
  month,
  changeMyTimeEnabled,
  changeWorkingTimeEnabled: editWorkingTimeEnabled,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const recordingPeriod = useRecordingPeriod();

  const [showCalendar, setShowCalendar] = useState(true);

  const { data: employeeData } = useEmployee({ employeeId: employeeCode });
  const employeeName = getFullname(employeeData);

  const { data: myTimeEnabledData } = useProperty({
    resource: "myTime",
    key: "changeMyTimeEnabled",
  });

  const requestsEnabled = myTimeEnabledData === "true" ? true : false;

  const [openInfoDialog, setOpenInfoDialog] = useState({
    state: false,
    data: null,
  });

  const { data, events, loading, error } = useAdmission({
    contractCode,
    year,
    month,
    changeMyTimeEnabled,
    recordingPeriod,
  });

  const countTimeData = (date) => {
    const result = countDailyHours({ date, list: data });
    return result;
  };

  const parsedData = data?.map((item) => {
    const newItem = { ...item };
    newItem.countTimeData = countTimeData;
    return newItem;
  });

  const list = {
    rows: parsedData,
    total: data?.length,
  };

  const handleSelectRow = ({ action, row }) => {
    parseActions({
      requestsEnabled,
      editWorkingTimeEnabled,
      action,
      row,
      list,
      role: "examiner",
      setOpenNewRequestDialog: () => {},
      setOpenInfoDialog,
      history,
      employeeCode,
      contractCode,
    });
    return;
  };

  let tableId = "employeeAdmissionsTableData";
  initialTableData.sortField = "title";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  if (employeeAdmissionsByAdmin && employeeAdmissionsByAdmin?.columns) {
    const defValues = Object.values(employeeAdmissionsByAdmin?.columns);
    let myTimeActions = null;
    const newDef = defValues.map((item) => {
      if (item?.id === "myTimeActions" || item?.id === "myTimeAdminActions") {
        myTimeActions = { ...item };
        myTimeActions.changeMyTimeEnabled = changeMyTimeEnabled;
        myTimeActions.changeWorkingTimeEnabled = editWorkingTimeEnabled;
        return myTimeActions;
      }
      return item;
    });
    employeeAdmissionsByAdmin.columns = newDef;
  }

  const { date, handleDateChange } = useActualDate();

  const handleMoreClick = () => {
    setShowCalendar(false);
  };

  return (
    <OcCard label={t("MY_WORK_TIME_REPORTS")} loading={loading} error={error}>
      <MyTimeInfoDialog
        open={openInfoDialog?.state}
        handleClose={() => setOpenInfoDialog({ state: false, data: null })}
        data={openInfoDialog?.data}
        employeeName={employeeName}
      />

      <Box
        id="button-group"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        padding="18px 0px"
      >
        <ButtonGroup>
          <Button
            id="calendar-button"
            onClick={() => setShowCalendar(true)}
            variant={!showCalendar ? "outlined" : "contained"}
            color={!showCalendar ? "default" : "primary"}
            disableElevation
          >
            {t("CALENDAR")}
          </Button>
          <Button
            id="table-button"
            onClick={() => setShowCalendar(false)}
            variant={showCalendar ? "outlined" : "contained"}
            color={showCalendar ? "default" : "primary"}
            disableElevation
          >
            {t("TABLE")}
          </Button>
        </ButtonGroup>
      </Box>

      <Box padding="16px 0px">
        {!showCalendar && (
          <OcDataTable
            tableDef={employeeAdmissionsByAdmin}
            tableId={tableId}
            tableData={tableData}
            setTableData={setTableData}
            data={list}
            loading={loading}
            onSelectRow={handleSelectRow}
            userRole="examiner"
          />
        )}
        {showCalendar && (
          <MyTimeCalendar
            date={date}
            contract={contractCode}
            onDateChange={handleDateChange}
            events={events}
            handleSelectRow={handleSelectRow}
            userRole="examiner"
            handleMoreClick={handleMoreClick}
            onClickDisabled={true}
            countTimeData={countTimeData}
          />
        )}
      </Box>
    </OcCard>
  );
};

export default MyTimeAdmissionCardByAdmin;
