import React, { useRef, useEffect, useLayoutEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import ApplicationHeader from "../application-header/application-header";
import OcCard from "oc/components/oc-card";
import FamilyTaxCreditApplicationPrint from "../family-tax-credit-application-print/family-tax-credit-application-print";
import ApplicationCardInfoItem from "../application-card-info-item/application-card-info-item";
import DependentsCollapse from "my-hr/components/my-hr-applications-data-panel/components/dependents-collapse";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import OcDownload from "oc/components/oc-download";
import { aloneOrWithSpouseState } from "../../states/alone-or-with-spouse-state";
import { dependentDataState } from "../../states/dependent-data-state";
import { useRecoilState, useResetRecoilState } from "recoil";
import useDependentsDataDI from "hooks/use-dependents-data";
import useSpousesDataDI from "hooks/use-spouses-data";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import useCurrentUserState from "hooks/use-current-user-state";
import ApplicationCollapseHeader from "../application-collapse-header";
import humanMaleFemale from "@iconify/icons-mdi/human-male-female";
import { NOT_PROVIDED } from "my-hr/components/my-hr-applications-data-panel/constants";
import TaxCreditApplicationButtonBars from "../tax-credit-application-buttonbars/tax-credit-application-buttonbars";
import TaxCreditApplicationCheckboxLine from "../tax-credit-application-checkbox-line/tax-credit-application-checkbox-line";
import { useTranslation } from "react-i18next";
import ModifiedStatementCheck from "../modified-statement-check/modified-statement-check";
import { taxDeclarationFamily } from "../../states/tax-declaration-family";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from "@material-ui/core/Collapse";
import useClientState from "hooks/use-oc-client-state";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_TAX_DECLARATION_FAMILY } from "graphql/queries";
import TaxCreditApplicationErrorBox from "my-hr/components/my-hr-applications-data-panel/components/tax-credit-application-error-box/tax-credit-application-error-box";
import { CREATE_TAX_DECLARATION_FAMILY } from "graphql/mutations";
import useOcSnackbar from "hooks/use-oc-snackbar";

const useStyles = makeStyles((theme) => ({
  topBorder: {
    borderTop: `2px solid ${theme.palette.gray40.main}`,
  },
  backgroundColor: {
    backgroundColor: `${theme.palette.gray20.main}`,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#212121",
    color: "#FFFFFF",
    maxWidth: "400px",
    height: "auto",
    fontSize: "16px",
  },
}))(Tooltip);

const SpouseCollapse = ({ editable, states, onChange }) => {
  const [expanded, setExpanded] = useClientState(
    "spouseFilterCollapseExpanded",
    true
  );

  const classes = useStyles(expanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <ApplicationCollapseHeader
        handleExpandClick={handleExpandClick}
        expanded={expanded}
        icon={humanMaleFemale}
        dependentName={states?.spouseName}
        dependentTaxNumber={
          states?.spouseTaxNumber ? states?.spouseTaxNumber : NOT_PROVIDED
        }
        taxNumberLabel="Adóazonosító jele"
      />
      <Collapse in={expanded} timeout="auto">
        <Box className={classes.backgroundColor} padding="0px 16px">
          <Box className={classes.topBorder} />
          <Box display="flex" flexDirection="column" padding="16px 0px">
            <Typography variant="body1">
              Adóelőleget megállapító munkáltató, kifizető megnevezése
            </Typography>
            <Box height="16px" />
            <Box
              marginRight="16px"
              style={{
                borderRadius: "4px",
              }}
            >
              <TextField
                id="spouse-collapse-companyName"
                value={states?.spouseEmployerName || ""}
                style={{ width: "467px" }}
                variant="outlined"
                label="Megnevezés"
                type="string"
                onChange={(e) => {
                  onChange({
                    key: "spouseEmployerName",
                    value: e.target.value,
                  });
                }}
                disabled={!editable}
              />
              <Box height="36px" />
              <Typography variant="body1">
                Adóelőleget megállapító munkáltató, kifizető adószáma
              </Typography>
              <Box height="16px" />
              <Box
                marginRight="16px"
                style={{
                  borderRadius: "4px",
                }}
              >
                <TextField
                  id="spouse-collapse-companyTaxNumber"
                  value={states?.spouseEmployerTaxNumber || ""}
                  style={{ width: "467px" }}
                  variant="outlined"
                  label="Adószám"
                  type="string"
                  onChange={(e) => {
                    onChange({
                      key: "spouseEmployerTaxNumber",
                      value: e.target.value,
                    });
                  }}
                  disabled={!editable}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Collapse>
      <Box></Box>
    </Box>
  );
};

const useFormData = ({ _id, setSelected }) => {
  const { t } = useTranslation();
  const [saveErrors, setSaveErrors] = React.useState([]);
  const [disabledButton, setDisabledButton] = React.useState(false);
  const [states, setStates] = useRecoilState(taxDeclarationFamily);
  const resetStates = useResetRecoilState(taxDeclarationFamily);

  const { data, loading, error } = useQuery(GET_TAX_DECLARATION_FAMILY, {
    fetchPolicy: "cache-and-network",
    skip: !_id,
    variables: {
      _id: parseInt(_id),
    },
    onCompleted: (data) => {
      const result = data?.taxDeclarationFamily;
      const newStates = { ...result };
      newStates.dateOfDeclaration = new Date(result?.dateOfDeclaration);
      delete newStates.__typename;
      setStates(newStates);
    },
  });

  const result = data?.taxDeclarationFamily;

  const handleChange = ({ key, value }) => {
    const newState = { ...states, [key]: value };
    setStates(newState);
  };

  const handleStateChange = (newState) => {
    setStates(newState);
  };

  const { setOpenMessage } = useOcSnackbar();
  const [saveDeclaration, { loading: mutationLoading }] = useMutation(
    CREATE_TAX_DECLARATION_FAMILY,
    {
      onCompleted: (data) => {
        const res = data?.createTaxDeclarationFamily;
        setDisabledButton(false);
        if (res?.result) {
          if (res?.messages?.length > 0) {
            const message = res?.messages[0];
            if (
              message === "A korábban beadott nyilatkozata törlésre került!"
            ) {
              setOpenMessage({
                type: "success",
                message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
              });
              setOpenMessage({
                type: "warning",
                message: t("PREVIOUS_TAX_DECLARATION_DELETED"),
              });
            }
          } else {
            setOpenMessage({
              type: "success",
              message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
            });
          }
          setSaveErrors([]);
          const params = {
            editable: false,
            tableId: res?.tableId,
            year: states?.dateOfDeclaration?.getFullYear().toString(),
            type: "TaxDeclarationFamily",
            state: "requested",
          };
          setSelected(params);
        } else {
          setSaveErrors(res?.messages);
          setDisabledButton(false);
        }
      },
      onError: () => {
        setSaveErrors([t("TAX_DECLARATION_CREATED_FAILED")]);
        setDisabledButton(false);
      },
    }
  );

  useEffect(() => {
    if (!states?.isModifiedDeclaration) {
      const updatedDependents = states.dependents.map(dependent => ({
        ...dependent,
        changeDate: ""
      }));
      handleStateChange({
        ...states,
        dependents: updatedDependents
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states?.isModifiedDeclaration]);


  const handleSave = () => {
    setDisabledButton(true);
    const params = { ...states };
    saveDeclaration({ variables: params });
  };

  return {
    data: result,
    loading: loading || mutationLoading,
    error,
    states,
    disabledButton,
    resetStates,
    handleChange,
    handleStateChange,
    handleSave,
    saveErrors,
    setSaveErrors,
  };
};

const FamilyTaxCreditApplication = ({
  fullName,
  taxNumber,
  useDependentsData = useDependentsDataDI,
  useSpousesData = useSpousesDataDI,
  taxApplicationSaveEnabled,
  handleRemoveRequest,
  selected,
  setSelected,
}) => {
  const editable = selected?.editable;
  const { t } = useTranslation();
  const history = useHistory();
  const {
    states,
    loading,
    error,
    saveErrors,
    disabledButton,
    setSaveErrors,
    resetStates,
    handleChange,
    handleStateChange,
    handleSave,
  } = useFormData({
    _id: selected?.tableId,
    setSelected,
  });

  const isModified = selected?.isModified;
  if (isModified && !states?.isModifiedDeclaration) {
    handleStateChange({ ...states, isModifiedDeclaration: true });
  }

  const showModifiedButton = states?.state === "E" ? true : false;
  const showRemoveButton =
    states?.state === "K" || selected?.sate === "K" ? true : false;

  const dateOfDeclarationString = new Date(
    states?.dateOfDeclaration
  ).toLocaleDateString();

  const title =
    "Adóelőleg-nyilatkozat a családi kedvezmény és járulékkedvezmény érvényesítéséről";
  const { data: dependents } = useDependentsData();
  const { data: spouses } = useSpousesData();
  if (editable) {
    if (spouses?.length > 0) {
      if (spouses[0].name !== states?.spouseName) {
        handleChange({ key: "spouseName", value: spouses[0].name });
      }
      if (spouses[0].taxNumber !== states?.spouseTaxNumber) {
        handleChange({ key: "spouseTaxNumber", value: spouses[0].taxNumber });
      }
    }
  }

  const componentRef = useRef();

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    print();
  };

  const [radioGroupState, setRadioGroupState] = useRecoilState(
    aloneOrWithSpouseState
  );
  const resetRadioGroupState = useResetRecoilState(aloneOrWithSpouseState);

  const resetDependentDataState = useResetRecoilState(dependentDataState);

  const handleCancel = () => {
    resetRadioGroupState();
    resetDependentDataState();
    resetStates();
    setSaveErrors([]);
  };

  const handleDependentsChange = (props) => {
    const index = states?.dependents.findIndex(
      (item) => item.dependentSerialNumber === props.dependentSerialNumber
    );
    const newStates = { ...states };
    const dependents = [...newStates?.dependents];
    dependents[index] = props;
    newStates.dependents = dependents;
    handleStateChange(newStates);
  };

  if (editable) {
    dependents?.forEach((item) => {
      const newItem = {
        dependentSerialNumber: item.itemSerial,
        name: item.name,
        taxNumber: item.taxNumber,
        dependentType: "",
        entitlementTitle: "",
        changeDate: "",
      };
      const isExist = states?.dependents.find(
        (item) => item.dependentSerialNumber === newItem.dependentSerialNumber
      );
      if (!isExist) {
        const dependents = states?.dependents;
        const newDependents = [...dependents, newItem];
        handleChange({ key: "dependents", value: newDependents });
      }
    });
  }

  let disabled = true;

  let { contractCode, employeeCode } = useCurrentUserState();
  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  let selectedContract = contractsData?.find(
    (item) => item.code === contractCode
  );

  const handleModChange = ({ key, value }) => {
    handleChange({ key, value });
  };

  const handleRadioGroupChange = (e) => {
    if (e.target.value === "alone") {
      const newStates = { ...states, applyAlone: true, applyTogether: false };
      handleStateChange(newStates);
    } else {
      const newStates = { ...states, applyAlone: false, applyTogether: true };
      handleStateChange(newStates);
    }
    setRadioGroupState(e.target.value);
  };

  if (!editable && states?.applyTogether) {
    setRadioGroupState("withSpouse");
  }

  const handleScrollToError = () => {
    const errorElement = document.getElementById("errorBox");
    if (errorElement) {
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    }
  };
  useLayoutEffect(() => {
    handleScrollToError();
  }, [saveErrors, history.action]);

  useEffect(() => {
    if (history.action === "POP" && editable) {
      return handleCancel();
    }
  }, []); //eslint-disable-line

  return (
    <>
      <Box
        style={{
          marginBottom: "-15px",
        }}
      >
        <Box
          sx={{
            display: "none",
            displayPrint: "block",
            paddingRight: "85px",
          }}
        >
          <FamilyTaxCreditApplicationPrint
            ref={componentRef}
            fullName={fullName}
            taxNumber={taxNumber}
            aloneOrWithSpouse={radioGroupState}
            employerName={selectedContract?.company?.name || ""}
            employerTaxNumber={selectedContract?.company?.taxNumber || ""}
            states={states}
            applicationDate={dateOfDeclarationString}
          />
          ;
        </Box>
        <ApplicationHeader title={title} onCancel={handleCancel} />
        <Box height="16px" />
        <Box display="flex" justifyContent="space-around">
          <Box
            style={{
              padding: "7px",
              width: "260px",
              borderRadius: "4px",
            }}
          >
            <OcDownload
              label="Tájékoztató"
              actionIconType="url"
              href={"#/my-hr/family-tax-credit-information"}
              target="_blank"
              isSmall={true}
            />
          </Box>
        </Box>
        <Box height="16px" />
        {saveErrors.length > 0 && (
          <Box id="errorBox">
            <TaxCreditApplicationErrorBox error={saveErrors} />
          </Box>
        )}
        <OcCard
          label="I. A nyilatkozatot adó magánszemély"
          loading={loading}
          error={error}
        >
          <Box height="36px" />
          <ApplicationCardInfoItem
            label="Dátum"
            value={dateOfDeclarationString}
          />
          <ApplicationCardInfoItem label="Dolgozó neve" value={fullName} />
          <ApplicationCardInfoItem
            label="Adóazonosító jele"
            value={taxNumber}
          />
          <Box height="15px" />
          <ModifiedStatementCheck
            label={t("MODIFIED_APPLICATION")}
            name="isModifiedDeclaration"
            value={states?.isModifiedDeclaration}
            onChange={handleModChange}
            disabled={!editable || isModified}
          />
          <Box height="15px" />
          <Box display="flex" flexDirection="row">
            <Typography variant="body1">1.</Typography>
            <Box width="16px" />
            <Typography variant="body1">Az eltartottak adatai</Typography>
          </Box>
          <Box height="36px" />
          {states?.dependents.length !== 0 ? (
            states?.dependents?.map((item, index) => {
              return (
                <Box key={`dependents-${index}`}>
                  <DependentsCollapse
                    index={index}
                    data={item}
                    onChange={handleDependentsChange}
                    isModifiedApplication={states?.isModifiedDeclaration}
                    editable={editable}
                  />
                  <Box height="36px" />
                </Box>
              );
            })
          ) : (
            <Box>
              <ApplicationCollapseHeader
                dependentName={NOT_PROVIDED}
                dependentTaxNumber=""
                expanded={disabled}
                icon={humanMaleFemale}
                taxNumberLabel=""
              />
              <Box height="36px" />
            </Box>
          )}
          <Box display="flex" flexDirection="row">
            <Typography variant="body1">2.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              Nyilatkozom, hogy a családi kedvezményt
            </Typography>
          </Box>
          <FormControl
            component="fieldset"
            width="16px"
            display="flex"
            style={{
              marginLeft: "30px",
              alignItems: "center",
            }}
          >
            <Box height="8px" />
            <RadioGroup
              value={radioGroupState}
              aria-label="gender"
              name="controlled-radio-buttons-group"
              onChange={handleRadioGroupChange}
              disabled={!editable}
            >
              <FormControlLabel
                value="alone"
                control={
                  <Radio
                    id="aloneOrWithSpouse"
                    style={{ color: !editable ? "#919699" : "#212121" }}
                    disabled={!editable}
                  />
                }
                label={
                  <Typography variant="body1" style={{ alignItems: "center" }}>
                    egyedül,
                  </Typography>
                }
              />
              <Box height="3px" />
              <FormControlLabel
                value="withSpouse"
                control={
                  <Radio
                    id="WithSpouse"
                    style={{ color: !editable ? "#919699" : "#212121" }}
                    disabled={!editable}
                  />
                }
                label={
                  <Typography variant="body1" style={{ alignItems: "center" }}>
                    vagy jogosult házastársammal/élettársammal közösen
                    érvényesítem.
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          <Box height="36px" />
          <TaxCreditApplicationCheckboxLine
            id="inHungary"
            type="checkbox"
            prefix="3."
            checked={states?.applyOnHungary}
            onChange={(e) =>
              handleChange({
                key: "applyOnHungary",
                value: e.target.checked,
              })
            }
            text="Nyilatkozom, hogy jogosult vagyok a családi kedvezményt
                Magyarországon érvényesíteni, külföldi államban a jövedelmem után azonos vagy hasonló kedvezményt nem veszek
                (vettem) igénybe."
            checkboxDisabled={!editable}
          />
          <Box height="36px" />
          <TaxCreditApplicationCheckboxLine
            id="noApply"
            type="checkbox"
            prefix="4."
            checked={states?.noRequestRelief}
            onChange={(e) =>
              handleChange({
                key: "noRequestRelief",
                value: e.target.checked,
              })
            }
            text="Nyilatkozom, hogy nem kérem a családi járulékkedvezmény havi
                összegének érvényesítését."
            checkboxDisabled={!editable}
          />
          <Box height="36px" />
          <Box display="flex">
            <Typography variant="body1">5.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              A fentiek alapján nyilatkozom, hogy a családi kedvezményt
            </Typography>
          </Box>
          <Box height="8px" />
          <Box paddingLeft="35px">
            <TaxCreditApplicationCheckboxLine
              id="amount"
              prefix="a."
              text="forint összegben kívánom igénybe venni."
              textInput={true}
              textInputType="number"
              value={states?.taxReliefAmount}
              label={t("AMOUNT")}
              onChangeInput={(e) =>
                handleChange({
                  key: "taxReliefAmount",
                  value: parseFloat(e.target.value.replace(/[^0-9]/g, "")),
                })
              }
              radioDisabled={states?.taxReliefNumberOfChild > 0 || !editable}
              textInputDisabled={
                states?.taxReliefNumberOfChild > 0 || !editable
              }
            />
            <Box height="16px" />
            <TaxCreditApplicationCheckboxLine
              id="numberOfApplicationDependent"
              prefix="b."
              text="fő kedvezményezett eltartott után kívánom igénybe venni."
              textInput={true}
              textInputType="number"
              value={
                states?.taxReliefNumberOfChild === 0
                  ? ""
                  : states?.taxReliefNumberOfChild
              }
              label="Fő"
              onChangeInput={(e) =>
                handleChange({
                  key: "taxReliefNumberOfChild",
                  value: parseFloat(e.target.value.replace(/[^0-9]/g, "")),
                })
              }
              radioDisabled={states?.taxReliefAmount > 0 || !editable}
              textInputDisabled={states?.taxReliefAmount > 0 || !editable}
            />
          </Box>
          <Box height="16px" />
          <Box marginLeft="20px">
            <Typography
              variant="body1"
              style={{
                textIndent: "60px",
                color: "#919699",
              }}
            >
              Az a. vagy b. sor egyikét töltse ki!
            </Typography>
          </Box>
          <Box height="36px" />
          <Typography variant="body1">
            Felelősségem tudatában kijelentem, hogy a nyilatkozat tartalmát
            házastársam, élettársam megismerte és elfogadta.
          </Typography>
          <Box height="26px" />
        </OcCard>
        <Box height="16px" />
        <OcCard label="II. A családi kedvezményt kérő magánszemély jogosult házastársa/ élettársa">
          <Box height="36px" />
          {spouses.length !== 0 ? (
            <SpouseCollapse
              editable={editable}
              states={states}
              onChange={handleChange}
            />
          ) : (
            <ApplicationCollapseHeader
              dependentName={NOT_PROVIDED}
              dependentTaxNumber=""
              expanded={disabled}
              icon={humanMaleFemale}
              taxNumberLabel=""
            />
          )}
          <Box height="36px" />
        </OcCard>
        <Box height="16px" />
        <TaxCreditApplicationButtonBars
          taxApplicationSaveEnabled={taxApplicationSaveEnabled && editable}
          cancelData={handleCancel}
          handlePrint={handlePrint}
          saveData={handleSave}
          editable={editable}
          disabledButton={disabledButton}
          handleRemoveRequest={handleRemoveRequest}
          showModifiedButton={showModifiedButton}
          showRemoveButton={showRemoveButton}
          isModified={isModified}
          isModifiedDeclaration={states?.isModifiedDeclaration}
        />
      </Box>
    </>
  );
};

export default FamilyTaxCreditApplication;
