import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import cityIcon from "@iconify/icons-mdi/city";
import siteMapIcon from "@iconify/icons-mdi/sitemap";
import creditCardIcon from "@iconify/icons-mdi/credit-card-marker-outline";
import fileIcon from "@iconify/icons-mdi/file-account";
import accountPlusIcon from "@iconify/icons-mdi/account-plus";
import accountClockIcon from "@iconify/icons-mdi/account-clock";
import homeAccountIcon from "@iconify/icons-mdi/home-account";
import ContractCardItem from "my-hr/components/my-hr-contract-card-item";

export default function Contract({ contract }) {
  let jobClassName =
    contract?.jobClass?.name.charAt(0).toUpperCase() +
      contract?.jobClass?.name.slice(1) || "";

  return (
    <Box padding="0px 32px">
      <Box style={{ borderTop: "1px solid #C6CACC" }}>
        <Typography
          variant="h5"
          style={{ marginTop: "16px", marginBottom: "4px" }}
        >
          {jobClassName} - {contract?.code}
        </Typography>
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1}>
            {" "}
            <ContractCardItem icon={cityIcon} label={contract?.company?.name} />
            <ContractCardItem
              icon={homeAccountIcon}
              label={contract?.workingPlace?.name}
            />
            <ContractCardItem
              icon={siteMapIcon}
              label={contract?.orgUnit?.name}
            />
            <ContractCardItem
              icon={creditCardIcon}
              label={contract?.payOffice?.name}
            />
          </Box>
          <Box flexGrow={1}>
            {" "}
            <ContractCardItem icon={fileIcon} label={contract?._id} />
            <ContractCardItem
              icon={accountPlusIcon}
              label={new Date(contract?.validFrom).toLocaleDateString()}
            />
            <ContractCardItem
              icon={accountClockIcon}
              label={contract?.employmentType?.name}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
