import gql from "graphql-tag";

export const REFRESH_TOKEN = gql`
  mutation refreshToken($accessToken: String!) {
    refreshToken(accessToken: $accessToken) {
      accessToken
      accessTokenPeriod
      sessionPeriod
    }
  }
`;
