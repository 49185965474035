import gql from "graphql-tag";

export const GET_TAX_DECLARATION_FIRST_MERRIED_PERSON = gql`
  query taxDeclarationFirstMarriedPerson($_id: Int!) {
    taxDeclarationFirstMarriedPerson(_id: $_id) {
      dateOfDeclaration
      isModifiedDeclaration
      state
      spouseName
      spouseTaxNumber
      spouseEmployerName
      spouseEmployerTaxNumber
      marriageDate
      applyFromMonth
      requestAmount
      noApply
      applyInTaxYear
      applyToWithdrawal
    }
  }
`;
