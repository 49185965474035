import gql from "graphql-tag";

export const GET_GENDERS = gql`
  query genders {
    genders {
      code
      name
    }
  }
`;
