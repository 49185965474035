export default function sortObjectList(field) {
  let sortOrder = 1;
  if (field[0] === "-") {
    sortOrder = -1;
    field = field.substr(1);
  }
  return function (a, b) {
    let result = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
    if (typeof a[field] === "object") {
      result =
        a[field]?.props?.label < b[field]?.props?.label
          ? -1
          : a[field]?.props?.label > b[field]?.props?.label
          ? 1
          : 0;
    }

    return result * sortOrder;
  };
}
