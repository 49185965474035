import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import MyLeaveEffectiveWorkDaysHeader from "my-leave/components/my-leave-effective-work-days-header";
import useClientState from "hooks/use-oc-client-state";
import informationOutline from "@iconify/icons-mdi/information-outline";
import { Icon } from "@iconify/react";
import MyLeaveEffectiveWorkDaysTable from "my-leave/components/my-leave-effective-work-days-table";
import Collapse from "@material-ui/core/Collapse";
import { useTranslation } from "react-i18next";

const MyLeaveEffectiveWorkDaysBlock = ({
    effectiveWorkDays,
    effectiveWorkDaysByDays,
    currentYearEffectiveWorkDays,
    nextYearEffectiveWorkDays,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [expanded, setExpanded] = useClientState(`collapseExpanded`, false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box>
            <MyLeaveEffectiveWorkDaysHeader
                handleExpandClick={handleExpandClick}
                expanded={expanded}
                currentYearEffectiveWorkDays={currentYearEffectiveWorkDays}
            />
            <Collapse in={expanded} timeout="auto">
                <Box
                    padding="0px 16px 16px 16px"
                    style={{
                        backgroundColor: theme.palette.gray30.main,
                    }}
                >
                    <Box
                        style={{
                            border: `1px solid ${theme.palette.gray40.main}`,
                            borderRadius: 8,
                            padding: 4,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Icon
                            icon={informationOutline}
                            width="20px"
                            color="#919699"
                        />
                        <Box width="10px" />
                        <Box width="349px">
                            <Typography
                                variant="caption"
                                style={{ color: "#919699" }}
                            >
                                {t("EFFECTIVE_WORK_DAY_INFO")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box width="10px" />
                <MyLeaveEffectiveWorkDaysTable
                    effectiveWorkDaysByDays={effectiveWorkDaysByDays}
                />
            </Collapse>
        </Box>
    );
};

export default MyLeaveEffectiveWorkDaysBlock;
