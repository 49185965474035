import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Collapse from "@material-ui/core/Collapse";
import MyHrUserDataRequestSubCollapseHeader from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-user-data-request-sub-collapse-header";
import useStyles from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-user-data-edit-collapse/my-hr-user-data-edit-collapse.style";
import MyHrRequestOwnMessage from "../my-hr-request-own-message";
import MyHrRequestMessage from "../my-hr-request-message";
import MyHrNewMessage from "../my-hr-new-message";

export default function MyHrUserDataRequesMessage({
  createdDateAt,
  messages,
  typeGroup,
  type,
  employeeCode,
  selectedId,
  typeGroupId,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const [messageExpanded, setMessageExpanded] = useState(false);

  const handleMessageExpandClick = () => {
    setMessageExpanded(!messageExpanded);
  };

  return (
    <>
      <MyHrUserDataRequestSubCollapseHeader
        expanded={messageExpanded}
        handleExpandClick={handleMessageExpandClick}
        createdDateAt={createdDateAt}
        label={t("MESSAGES")}
        badge={messages.length + " " + t("MYHR_MESSAGE")}
        className={classes.messageBackgroundColor}
        classNameCollapse={classes.messageBackgroundColorOnCollapse}
      />
      <Collapse in={messageExpanded} timeout="auto">
        <Box
          maxWidth="600px"
          style={{
            backgroundColor: theme.palette.gray30.main,
            padding: "0px 20px 20px 20px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            marginLeft: "16px",
          }}
        >
          <Box
            maxWidth="600px"
            style={{
              height: "1px",
              backgroundColor: theme.palette.gray40.main,
              width: "600px",
            }}
            marginBottom="20px"
          />
          {messages.map((messageItem, index) => (
            <Box key={`${index}_${messageItem._id}`}>
              {messageItem.createdBy === employeeCode ? (
                <MyHrRequestOwnMessage messageItem={messageItem} />
              ) : (
                <MyHrRequestMessage messageItem={messageItem} />
              )}
            </Box>
          ))}
          <MyHrNewMessage
            selectedRequest={selectedId}
            typeGroupId={typeGroupId}
            typeGroup={typeGroup}
            type={type}
          />
        </Box>
      </Collapse>
    </>
  );
}
