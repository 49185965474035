import React from "react";
import useLeaveSummaryChartData from "hooks/use-my-leave-summary-chart-data";
import MyLeaveBar from "my-leave/components/my-leave-bar";

const LeaveStudyCard = ({ selectedYear, contract }) => {
  const { data } = useLeaveSummaryChartData({
    year: selectedYear,
    contract,
  });

  if (!data?.allStudyLeaveDays || data?.allStudyLeaveDays === 0) {
    return null;
  }

  return (
    <MyLeaveBar
      id="study-leave-days-bar"
      label={"Leave.leaveStudy"}
      all={data?.allStudyLeaveDays}
      available={data?.availableStudyLeaveDays}
      requested={data?.requestedStudyLeaveDays}
      fulfilled={data?.fulfilledStudyLeaveDays}
    />
  );
};
export default LeaveStudyCard;
