import { useState } from "react";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import Validator from "fastest-validator";

const validator = new Validator();

export default function useOcPasswordInput() {
  const { t } = useTranslation();
  const { data: passwordPolicy } = useProperty({
    resource: "system",
    key: "passwordPolicy",
  });

  const passwordSchema = {
    password: {
      type: "string",
      min: 1,
      messages: {
        string: "WRONG_PASSWORD",
        stringMin: t("ERROR_MESSAGE_PASSWORD_IS_REQUIRED"),
        required: t("ERROR_MESSAGE_PASSWORD_IS_REQUIRED"),
      },
    },
  };

  const checkPassword = validator.compile(passwordSchema);

  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  function isValidPassword(value, passwordPolicy) {
    let isValidLength = value.length >= 8;

    let lettersPattern = new RegExp("(?=.*[a-zA-Z])");
    let containsLetter = lettersPattern.test(value);

    let lowerCasePattern = new RegExp("(?=.*[a-z])");
    let containsLowercase = lowerCasePattern.test(value);

    let upperCasePattern = new RegExp("(?=.*[A-Z])");
    let containsUppercase = upperCasePattern.test(value);

    let specialCharacterPattern = new RegExp("(?=.*[-+_!@#$%^&*.,?])");
    let containsSpecialCharacter = specialCharacterPattern.test(value);

    let decimalCharacterPattern = new RegExp("(?=.*[0-9])");
    let containsDecimalCharacter = decimalCharacterPattern.test(value);

    if (passwordPolicy === "strong") {
      return (
        isValidLength &&
        containsLowercase &&
        containsUppercase &&
        containsDecimalCharacter &&
        containsSpecialCharacter
      );
    }
    return containsLetter && containsDecimalCharacter && isValidLength;
  }

  function isValid({ checkPasswordPolicy }) {
    let isValid = checkPassword({ password: value });
    if (checkPasswordPolicy) {
      return isValidPassword(value, passwordPolicy);
    }
    return isValid === true;
  }

  function validate({ checkPasswordPolicy }) {
    let isValidated = checkPassword({ password: value });

    if (checkPasswordPolicy) {
      if (!isValidPassword(value, passwordPolicy)) {
        setError(true);
        setHelperText(t("ERROR_MESSAGE_PASSWORD_IS_WRONG"));
      }
    }
    if (isValidated !== true) {
      isValidated.forEach((item) => {
        if (item.field === "password") {
          setError(true);
          setHelperText(item.message);
        }
      });
    }
  }

  return {
    value,
    setValue,
    error,
    setError,
    helperText,
    setHelperText,
    isValid,
    validate,
  };
}
