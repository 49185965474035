import React from "react";
import { useTheme } from "@material-ui/styles";
import Box from "@material-ui/core/Box";

export default function DatePickerCancelButton({ btText }) {
  const theme = useTheme();
  return (
    <Box
      bgcolor="transparent"
      py="8px"
      px="22px"
      borderRadius="4px"
      color={theme.palette.gray80.main}
    >
      {btText}
    </Box>
  );
}
