import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  iconSend: {
    width: "22px",
    height: "22px",
    color: theme.palette.gray60.main,
  },
  textInput: {
    "& .MuiOutlinedInput-input": {
      padding: 14,
    },
  },
}));

export default useStyles;
