import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    filterContainer: {
        backgroundColor: theme.palette.gray20.main,
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
}));

export default useStyles;
