import React, { useState } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import OcCard from "oc/components/oc-card";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcCollapseHeader from "oc/components/oc-collapse-header";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import OcActiveUserSelector from "oc/components/oc-active-user-selector";
import { useRecoilState, useResetRecoilState } from "recoil";
import { roleFilterState } from "states";
import _ from "lodash";
import OcOrgUnitFilter from "oc/components/oc-org-unit-filter";
import OcWorkingPlaceFilter from "oc/components/oc-working-place-filter";
import OcJobClassGroupFilter from "oc/components/oc-job-class-group-filter";
import OcPayofficeFilter from "oc/components/oc-payoffice-filter";
import OcEmployeeFilter from "oc/components/oc-employee-filter";
import useFilteredListCount from "hooks/use-filtered-list-count.js";
import useFilteredList from "hooks/use-filtered-list";
import OcFilteredListCount from "oc/components/oc-filtered-list-count";
import Button from "@material-ui/core/Button";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import Table from "oc/components/oc-data-table";
import Card from "@material-ui/core/Card";
import OcDatePicker from "oc/components/oc-date-picker";
import { useMutation } from "@apollo/react-hooks";
import { INACTIVATE_USERS } from "graphql/mutations";
import { useHistory } from "react-router-dom";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import MyHrBackToPage from "my-hr/components/my-hr-back-to-page";
import OcCompanyFilter from "oc/components/oc-company-filter";
import MyLeaveFilterDivider from "my-leave/components/my-leave-filter-divider";
import getDateFormatByLocale from "oc/utils/get-date-format-by-locale";
import useOcSnackbar from "hooks/use-oc-snackbar";

const tableDef = {
  id: "employees-list-table",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: false,
      sort: false,
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: false,
      sort: false,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: false,
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
      sort: false,
    },
  ],
};

export default function MyHRAdminInactivateGroupPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [expanded, setExpanded] = React.useState(false);
  const [userActiveState, setUserActiveState] = React.useState("active");
  const [inactivateDate, setInactivateDate] = React.useState(new Date());
  const [errorState, setErrorState] = useState(false);

  const [roleFilters, setRoleFilters] = useRecoilState(roleFilterState);
  const userRoleFilters = {
    company: [],
    orgUnit: [],
    workingPlace: [],
    jobClassGroup: [],
    payOffice: [],
    contract: [],
  };

  let tableId = "filteredListTableData";
  initialTableData.pageSize = 5;
  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const { data: filteredListCount } = useFilteredListCount({
    isActive: userActiveState === "active" ? true : false,
    roleFilters,
  });

  const { data: filteredList, loading } = useFilteredList({
    page: tableData.page,
    pageSize: tableData.pageSize,
    isActive: userActiveState === "active" ? true : false,
    roleFilters,
  });

  const { setOpenMessage } = useOcSnackbar();

  const [inactivateUsers] = useMutation(INACTIVATE_USERS, {
    onCompleted: (data) => {
      setOpenMessage({
        type: "success",
        message: t("INACTIVATE_USERS_SUCCESSFULLY"),
      });
      handleCancel();
    },
    onError: (error) => {
      console.log({ error });
      setOpenMessage({
        type: "error",
        message: t("INACTIVATE_USERS_FAILED"),
      });
    },
  });

  function handleAddFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    newState[key] = newState[key].concat([value]);
    setRoleFilters(newState);
  }

  function handleRemoveFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    newState[key] = newState[key].filter((item) => item !== value);
    setRoleFilters(newState);
  }

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function handleUserFilterClick(state) {
    setUserActiveState(state);
  }

  const resetRoleFilters = useResetRecoilState(roleFilterState);

  function handleInactivateDate(date) {
    setInactivateDate(date);
  }

  const inactivateDateError = new Date(inactivateDate).toLocaleDateString();

  function handleInactivateUsers() {
    const params = {
      inactivateDate,
      roleFilters,
    };
    inactivateUsers({ variables: params });
  }

  function handleCancel() {
    resetRoleFilters();
    history.push("/my-hr/users");
  }

  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);

  function handleShowConfirmationDialog() {
    if (inactivateDateError === "Invalid Date") {
      setErrorState(true);
      return;
    }
    setShowConfirmationDialog(true);
  }

  function handleOKConfirmationDialog() {
    setShowConfirmationDialog(false);
    handleInactivateUsers();
  }

  function handleCancelConfirmationDialog() {
    setShowConfirmationDialog(false);
  }

  function generateConfirmationText() {
    if (
      roleFilters.company.length === 0 &&
      roleFilters.orgUnit.length === 0 &&
      roleFilters.workingPlace.length === 0 &&
      roleFilters.jobClassGroup.length === 0 &&
      roleFilters.payOffice.length === 0 &&
      roleFilters.contract.length === 0
    )
      return "INACTIVATE_USERS_TO_ALL_DIALOG_TEXT";
    return "INACTIVATE_USERS_DIALOG_TEXT";
  }

  return (
    <OcPageTransition>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <OcConfirmationDialog
          showDialog={showConfirmationDialog}
          title={t("INACTIVATE_USERS_DIALOG_TITLE")}
          text={t(generateConfirmationText())}
          okButtonText={t("INACTIVATE")}
          cancelButtonText={t("CANCEL")}
          onClose={handleOKConfirmationDialog}
          onCancel={handleCancelConfirmationDialog}
        />
        <Box padding={"16px"}>
          <MyHrBackToPage
            label={t("INACTIVATE_GROUP_TITLE")}
            onBack={handleCancel}
          />
          <OcCard label={t("User.users")}>
            <>
              <Box
                style={{
                  backgroundColor: "#F1F3F4",
                  borderRadius: "4px",
                }}
              >
                <OcCollapseHeader
                  expanded={expanded}
                  onExpandClick={handleExpandClick}
                />
                <Collapse
                  in={expanded}
                  timeout="auto"
                  style={{
                    backgroundColor: "#F1F3F4",
                    borderRadius: "4px",
                  }}
                >
                  <Box
                    id="collapse-body"
                    padding="0px 16px"
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                  >
                    <Box
                      style={{
                        backgroundColor: "#C6CACC",
                        height: "2px",
                        width: "100%",
                      }}
                    />
                    <OcActiveUserSelector
                      filterState={userActiveState}
                      onClick={handleUserFilterClick}
                    />
                    <Box
                      id="selector-container"
                      width="66%"
                      style={{ paddingBottom: "24px" }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <OcCompanyFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OcOrgUnitFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OcWorkingPlaceFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OcJobClassGroupFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OcPayofficeFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                      </Grid>
                      <Box height="16px" />
                      <MyLeaveFilterDivider />
                      <Box height="16px" />
                      <Grid item xs={6} style={{ paddingRight: "8px" }}>
                        <OcEmployeeFilter
                          roleFilters={roleFilters}
                          userRoleFilters={userRoleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product="MyHR"
                          role="admin"
                        />
                      </Grid>
                      <Box minHeight="16px" />
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <OcFilteredListCount listCount={filteredListCount} />
                        </Grid>
                      </Grid>
                      <Box style={{ paddingTop: "24px" }}>
                        <Button onClick={resetRoleFilters}>{t("RESET")}</Button>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              </Box>
              <Box paddingTop="32px">
                <Table
                  data={filteredList}
                  loading={loading}
                  tableDef={tableDef}
                  tableData={tableData}
                  setTableData={setTableData}
                  pagination={true}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingTop="24px"
              >
                <Card
                  variant="outlined"
                  style={{
                    width: "696px",
                    borderRadius: "4px",
                    borderColor: "white",
                    overflow: "visible",
                    marginBottom: "16px",
                    backgroundColor: "#F1F3F4",
                  }}
                >
                  <Box padding="16px" dispaly="flex" flexDirection="column">
                    <Box
                      fontSize="20px"
                      letterSpacing="0.15px"
                      padding="15px 0px"
                      paddingBottom="30px"
                    >
                      {t("INACTIVATE")}
                    </Box>
                    <Box marginBottom="36px" width="432px">
                      <OcDatePicker
                        error={errorState}
                        helperText={errorState && t("Leave.notSelectable")}
                        id="validFrom"
                        label={t("VALID_TO")}
                        minDate={new Date()}
                        minDateMessage={t("Leave.notSelectable")}
                        onChange={handleInactivateDate}
                        value={inactivateDate}
                        format={getDateFormatByLocale(i18n.language)}
                        onFocus={() => {
                          setErrorState(false);
                        }}
                      />
                    </Box>
                    <Box
                      id="my-hr-add-user-role-button-cancel"
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      padding="10px 0px 0px 0px"
                    >
                      <Button onClick={() => handleInactivateDate(new Date())}>
                        {t("CANCEL")}
                      </Button>
                      <Button
                        id="my-hr-add-user-role-button"
                        variant="contained"
                        color="primary"
                        disableElevation
                        style={{ marginLeft: "16px" }}
                        //disabled={!selectedRole}
                        onClick={handleShowConfirmationDialog}
                      >
                        {inactivateDate ? t("INACTIVATE_BUTTON") : t("SAVE")}
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </>
          </OcCard>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
