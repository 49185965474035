import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    backgroundColorOffCollapse: {
        backgroundColor: `${theme.palette.gray30.main}`,
        borderRadius: "4px",
    },
    backgroundColorOnCollapse: {
        backgroundColor: `${theme.palette.gray30.main}`,
        borderRadius: "unset",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
    },
}));

export default useStyles;
