import useClientState from "hooks/use-oc-client-state-2";
import { useRecoilValue } from "recoil";
import { roleFiltersState } from "states";
import _ from "lodash";

const useQueryParams = ({
  contract,
  entity,
  product,
  role,
  roleFilters,
  stateId,
  ...rest
}) => {
  const [search, setSearch] = useClientState(`${stateId}`, "");
  const [pageSize, setPageSize] = useClientState(`${stateId}PageSize`, 25);

  const id = "prev" + product + role + contract;
  const prevRoleFilters = useRecoilValue(roleFiltersState(id));
  const showLoading = !_.isEqual(roleFilters, prevRoleFilters);

  let queryParams = {
    page: 1,
    pageSize: pageSize,
    searchField: "name",
    searchText: search?.toString().length >= 3 ? search.toString() : "",
  };

  return {
    search,
    setSearch,
    pageSize,
    setPageSize,
    queryParams: { ...queryParams, ...entity, product, role, ...rest },
    showLoading,
  };
};

export default useQueryParams;
