import gql from "graphql-tag";

export const GET_OPEN_REQUEST_LIST_2 = gql`
  query getOpenRequestsList2 {
    getOpenRequestsList2 {
      prefixName
      lastName
      firstName
      middleName
      nameSuffix
      orgUnit
      jobClass
      recordCount
    }
  }
`;
