import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import MyHrUserDataEditCollapse from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-user-data-edit-collapse";
import useChangeRequests from "hooks/use-change-requests";
import useCurrentUserState from "hooks/use-current-user-state";
import OcPageTransition from "oc/components/oc-page-transition";
import _ from "lodash";
import { useParams } from "react-router-dom";
import useEmployee from "hooks/use-myhr-employee";
import UserContractsCard from "my-hr/components/my-hr-user-contract-card";
import useContract from "hooks/use-myhr-contract";
import MyLeaveExaminers from "my-leave/pages/my-leave-my-leave-requests-page/components/my-leave-my-leave-examiners/my-leave-my-leave-examiners";
import useChangeRequestsExaminersName from "hooks/use-change-requests-examiners-name";

export default function MyHrUserDataEditRequests() {
  const { t, i18n } = useTranslation();

  let { employee } = useParams();
  let { employeeCode, contractCode } = useCurrentUserState();

  let param = employeeCode;
  if (employee) {
    param = employee;
  }

  const { data: employeeData } = useEmployee({ employeeId: employee });

  const [onlyRequested, setOnlyRequested] = useState(true);

  const { data: allData, refetch: refetchData } = useChangeRequests(param);

  const requestedData = allData?.filter(
    (item) => item?.approvedAt === null && item?.rejectedAt === null
  );

  let showData = requestedData;
  if (onlyRequested === false) {
    showData = allData;
  }

  const showDataTypeNull = showData?.filter((item) => item?.typeGroup === null);

  const showDataTypeWithoutNull = showData?.filter(
    (item) => item?.typeGroup !== null
  );

  const groupsData = _.uniqBy(showDataTypeWithoutNull, "typeGroupId");
  const allMergedData = groupsData.concat(showDataTypeNull);

  function FilterStateSelector({ onlyRequested, setOnlyRequested }) {
    return (
      <Box
        id="state-slector"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        padding="25px 0px 0px 0px"
      >
        <ButtonGroup>
          <Button
            id="ToBeAssessed-button"
            variant={onlyRequested ? "contained" : "outlined"}
            color={onlyRequested ? "primary" : ""}
            disableElevation
            onClick={() => setOnlyRequested(true)}
          >
            {t("TO_BE_ASSESSED")}
          </Button>
          <Button
            id="All-button"
            variant={!onlyRequested ? "contained" : "outlined"}
            color={!onlyRequested ? "primary" : ""}
            disableElevation
            onClick={() => setOnlyRequested(false)}
          >
            {t("ALL")}
          </Button>
        </ButtonGroup>
      </Box>
    );
  }

  let contract = contractCode;
  if (allData.length > 0) {
    contract = allData[0].contract;
  }

  const {
    data: examinersData,
    loading: examinersLoading,
    error: examinersError,
  } = useChangeRequestsExaminersName({
    locale: i18n.language,
    contract,
  });

  const { data: contractData } = useContract({
    contract,
  });

  const examinerRequestsDisabled = requestedData?.filter(
    (item) => item?.contract === contractCode
  );

  let disabled = false;

  if (examinerRequestsDisabled?.length > 0) {
    disabled = true;
  }

  return (
    <OcPageTransition>
      <Box padding="16px">
        {employeeData && (
          <UserContractsCard employee={employeeData} contract={contractData} />
        )}
        <OcCard
          label={
            employee
              ? t("MenuItems.myLeaveAllRequests")
              : t("MY_CHANGE_REQUEST")
          }
        >
          <>
            <FilterStateSelector
              onlyRequested={onlyRequested}
              setOnlyRequested={setOnlyRequested}
            />
            <Box height="32px" />
            {allMergedData?.map((item, index) => (
              <Box key={`${item}-${index}`}>
                <MyHrUserDataEditCollapse
                  employeeCode={param}
                  index={index}
                  type={item.type}
                  createdDateAt={new Date(item.createdAt).toLocaleString()}
                  validDateFrom={new Date(item.validFrom).toLocaleDateString()}
                  oldValue={item.oldValue}
                  newValue={item.newValue}
                  messages={item.messages}
                  approvedAt={item.approvedAt}
                  rejectedAt={item.rejectedAt}
                  selectedId={item._id}
                  typeGroup={item.typeGroup}
                  refetchData={refetchData}
                  isAdmin={employee ? true : false}
                  typeGroupId={item.typeGroupId}
                  disabled={disabled}
                />
                <Box height="16px" />
              </Box>
            ))}
          </>
        </OcCard>
        <Box height="16px" />
        <Box width="240px">
          <OcCard
            label={t("EXAMINERS")}
            loading={!examinersData && examinersLoading}
            error={examinersError}
          >
            <>
              <MyLeaveExaminers
                examinersData={examinersData}
                loading={examinersLoading}
              />
            </>
          </OcCard>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
