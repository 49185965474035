import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { Box } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import useStyles from "./dependents-four-children-collapse.style";
import useClientState from "hooks/use-oc-client-state";
import ApplicationCollapseHeader from "../application-collapse-header";
import accountChild from "@iconify/icons-mdi/account-child";
import ApplicationCardInfoItem from "../application-card-info-item/application-card-info-item";
import { NOT_PROVIDED } from "my-hr/components/my-hr-applications-data-panel/constants";

export default function DependentsFourChildrenCollapse({ index, data }) {
  const [expanded, setExpanded] = useClientState(
    `leaveFilterCollapseExpanded${index}`,
    true
  );

  const classes = useStyles(expanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <OcErrorBoundary>
      <Box>
        <ApplicationCollapseHeader
          handleExpandClick={handleExpandClick}
          expanded={expanded}
          icon={accountChild}
          dependentName={data?.name}
          dependentTaxNumber={data?.taxNumber ? data?.taxNumber : NOT_PROVIDED}
          taxNumberLabel="Adóazonosító jele"
        />
        <Collapse in={expanded} timeout="auto">
          <Box className={classes.backgroundColor} padding="0px 16px">
            <Box className={classes.topBorder} />
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box
                style={{
                  padding: "16px 0px",
                  marginRight: "8px",
                }}
              >
                <ApplicationCardInfoItem
                  label="Születési helye"
                  value={data?.placeOfBirth}
                />
                <ApplicationCardInfoItem
                  label="Születési ideje"
                  value={new Date(data?.dateOfBirth).toLocaleDateString()}
                />
                <ApplicationCardInfoItem
                  label="Anyja neve"
                  value={data?.mothersName}
                />
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </OcErrorBoundary>
  );
}
