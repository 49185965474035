import React from "react";
import { Box } from "@material-ui/core";

const OcSettingsDivider = () => {
  return (
    <Box
      style={{
        height: "1px",
        backgroundColor: "#C6CACC",
        marginTop: "12px",
        marginBottom: "24px",
      }}
    />
  );
};

export default OcSettingsDivider;
