import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const MyTimeWorkingFrameBox = ({ label, value, color, loading, typeName, framePeriodData, overtimeValue }) => {
  const { t } = useTranslation();

  const formatDate = (date) => new Date(date).toLocaleDateString();

  const renderHeader = (text, bold = false) => (
    <Typography
      variant="body1"
      style={{
        color: "#ffffff",
        padding: "0px",
        margin: "0px",
        fontWeight: bold ? "bold" : "normal",
      }}
    >
      {text}
    </Typography>
  );

  const boxSeparator = () => (
    <Box
      style={{
        backgroundColor: "#ffffff",
        width: "auto",
        height: "1px",
      }}
    />
  );

  return (
    <Box
      minHeight="178px"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: color,
        borderRadius: "8px"
      }}
    >
      <Box padding="12px 16px 0px 16px">
        {typeName === "framePeriod" && (
          <>
            {renderHeader(t("MY_TIME_FRAME_PERIOD"))}
            {renderHeader(loading ? t("LOADING") : `${formatDate(framePeriodData?.startDate)} - ${formatDate(framePeriodData?.endDate)}`, true)}
            <Box height="8px" />
            {boxSeparator()}
          </>
        )}
        {typeName === "frameOvertime" && (
          <>
            {renderHeader(t("ACCEPTED_OVERTIME"))}
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
              <Typography
                variant="h4"
                style={{
                  color: "#ffffff",
                  lineHeight: "42.5px",
                  padding: "0px",
                  margin: "0px",
                  textTransform: "lowercase",
                }}
              >
                {loading ? t("LOADING") : `${overtimeValue} ${t("HOUR")}`}
              </Typography>
            </Box>
            <Box height="8px" />
            {boxSeparator()}
          </>
        )}
      </Box>
      <Box padding="8px 16px 16px 16px">
        <Typography
          variant="body1"
          style={{
            color: "#ffffff",
            padding: "0px",
            margin: "0px",
          }}
        >
          {label}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
          <Typography
            variant="h4"
            style={{
              color: "#ffffff",
              lineHeight: "42.5px",
              padding: "0px",
              margin: "0px",
              textTransform: "lowercase",
            }}
          >
            {loading ? t("LOADING") : `${value} ${t("HOUR")}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MyTimeWorkingFrameBox;
