import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.gray30.main,
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        fontSize: "12px",
        padding: "0px 20px 20px 20px",
        maxWidth: "382px",
    },
    tableHeader: {
        height: "20px",
        width: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        textAlign: "center",
        paddingBottom: "10px",
        borderBottom: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: `${theme.palette.gray40.main}`,
    },
    tableItem: {
        textAlign: "center",
        paddingTop: "8px",
        color: `${theme.palette.gray60.main}`,
    },
}));

export default useStyles;
