import React from "react";
import Box from "@material-ui/core/Box";
import { Icon } from "@iconify/react";
import accountCheck from "@iconify/icons-mdi/account-check";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";

export default function MyLeaveExaminers({ examinersData, loading }) {
  const { t } = useTranslation();
  const theme = useTheme();

  if (examinersData?.length === 0 && !loading) {
    return (
      <Box style={{ color: theme.palette.primary.main, padding: "16px 0px" }}>
        {t("MISSING_EXAMINERS")}
      </Box>
    );
  }

  return (
    <>
      {examinersData?.map((item, index) => {
        return (
          <Box
            key={`my-leave-examiners-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon icon={accountCheck} height="24px" />
            <Box width="10px" />
            <Box paddingBottom="4px">{item}</Box>
          </Box>
        );
      })}
    </>
  );
}
