import { useQuery } from "@apollo/react-hooks";
import { GET_FILTERED_LIST_COUNT } from "graphql/queries";

export default function useFilteredListCount({
  roleFilters,
  isActive,
  withNoUsers,
}) {
  const { data, error, loading, refetch } = useQuery(GET_FILTERED_LIST_COUNT, {
    fetchPolicy: "cache-and-network",
    variables: { roleFilters, isActive, withNoUsers },
  });
  return {
    data: data?.filteredListCount || 0,
    error,
    loading,
    refetch,
  };
}
