import { useCallback, useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import parse from "date-fns/parse";
import ApolloClientContext from "context/apollo-client-context";

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function isDateString(str) {
  try {
    parse(str);
  } catch (error) {
    return false;
  }
  return true;
}

export default function useOcClientState2(key, initialValue) {
  const { client } = useContext(ApolloClientContext);

  const QUERY = gql`
    query get${key} {
      ${key} @client {
        ${key}
      }
    }
  `;

  const { data, loading, error } = useQuery(QUERY);

  if (!data && !loading && !error && initialValue) {
    let dataToWrite = initialValue;
    if (typeof initialValue === "object") {
      dataToWrite = JSON.stringify(initialValue);
    }
    client.writeQuery({
      skip: !key,
      query: QUERY,
      data: {
        [key]: {
          __typename: key,
          [key]: dataToWrite,
        },
      },
    });
  }

  let result = initialValue;

  if (data) {
    let rawResult = data[key];
    if (isJsonString(rawResult[key])) {
      result = JSON.parse(rawResult[key]);
      if (isDateString(result)) {
        result = new Date(result);
      }
    } else {
      result = rawResult[key];
    }
  }

  const setData = useCallback(
    (value) => {
      let valueToWrite = value;
      if (result === "object" && value === "object") {
        let newState = Object.assign({}, result, value);
        valueToWrite = JSON.stringify(newState);
      }
      client.writeQuery({
        skip: !key,
        query: QUERY,
        data: {
          [key]: {
            __typename: key,
            [key]: valueToWrite,
          },
        },
      });
    },
    [client, key, QUERY, result]
  );
  return [result, setData];
}
