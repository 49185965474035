import React from "react";
import { Box, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MyTimeCalendarTooltip from "my-time/components/my-time-calendar-tooltip";

const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    color: "#212121",
  },
  tooltip: {
    backgroundColor: "#212121",
    borderRadius: "8px",
    padding: "12px 8px",
  },
}));

const MyTimeCalendarEventWrapper = (props) => {
  const { event, children } = props;
  const classes = useStylesTooltip();
  return (
    <Tooltip title={<MyTimeCalendarTooltip event={event} />} classes={classes}>
      <Box>{children}</Box>
    </Tooltip>
  );
};

export default MyTimeCalendarEventWrapper;
