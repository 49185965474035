import { useQuery, useSubscription } from "@apollo/client";
import { GET_CHANGE_REQUESTS_BADGES } from "graphql/queries";
import gql from "graphql-tag";

export const CHANGE_REQUESTS_SUBSCRIPTION = gql`
  subscription changeRequestsSubscription {
    changeRequestsSubscription
  }
`;

const useChangeRequestsBadges = (contract) => {
  const { data, loading, error, refetch } = useQuery(
    GET_CHANGE_REQUESTS_BADGES,
    {
      fetchPolicy: "cache-and-network",
      variables: { contract },
    }
  );

  const result = data?.changeRequestsBadges || 0;

  useSubscription(CHANGE_REQUESTS_SUBSCRIPTION, {
    onData: () => {
      refetch();
    },
  });

  return {
    data: result,
    loading,
    error,
  };
};

export default useChangeRequestsBadges;
