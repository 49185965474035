import React from "react";
import moment from "moment";
import { InlineIcon } from "@iconify/react";
import useMyTimeIcon from "my-time/hooks/use-my-time-icon";
import { Box, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MyTimeCalendarTooltip from "my-time/components/my-time-calendar-tooltip";

const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    color: "#212121",
  },
  tooltip: {
    backgroundColor: "#212121",
    borderRadius: "8px",
    padding: "12px 8px",
  },
}));

const MyTimeCalendarWeeklyEvent = ({ event }) => {
  const classes = useStylesTooltip();
  const title = `${moment(event.startTime).format("HH:mm")} - ${moment(
    event.endTime
  ).format("HH:mm")}`;


  const icon = useMyTimeIcon(event);
  let iconColor = `rgba(33, 33, 33, 0.3)`;
  if (
    event.myTimeType === "WORKING_TIME" ||
    event.myTimeType === "WORKING_TIME_ITEM" ||
    event.myTimeType === "STAND_BY" ||
    event.myTimeType === "LEAVE" ||
    event.myTimeType === "ABSENCE" ||
    event.myTimeType === "HOMEOFFICE" ||
    event.myTimeType === "ON_CALL" ||
    event.myTimeType === "OTHER"
  ) {
    iconColor = `rgba(255, 255, 255, 0.3)`;
  }

  return (
    <Tooltip
      title={<MyTimeCalendarTooltip event={event} />}
      classes={classes}
      placement="top"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: "100%" }}
      >
        <Box>{event.timeDataTypeName}</Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <InlineIcon
            icon={icon}
            width="60px"
            height="60px"
            style={{
              color: iconColor,
              minWidth: "20px",
            }}
          />
        </Box>
        <Box>{title}</Box>
      </Box>
    </Tooltip>
  );
};

export default MyTimeCalendarWeeklyEvent;
