import gql from "graphql-tag";

export const GET_FILTERED_LEAVE_TYPES = gql`
  query filteredLeaveTypes {
    filteredLeaveTypes {
      code
      name
      type
      show
      isSelectable
    }
  }
`;
