import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

export default function OcActiveUserSelector({
    filterState,
    onClick,
    margin = "24px",
}) {
    const { t } = useTranslation();
    return (
        <Box margin={margin}>
            <ButtonGroup>
                <Button
                    id="filter-bar-active"
                    variant={
                        filterState === "active" ? "contained" : "outlined"
                    }
                    color={filterState === "active" ? "primary" : "default"}
                    disableElevation
                    onClick={() => onClick("active")}
                >
                    {t("ACTIVE")}
                </Button>
                <Button
                    id="filter-bar-all"
                    variant={filterState === "all" ? "contained" : "outlined"}
                    color={filterState === "all" ? "primary" : "default"}
                    disableElevation
                    onClick={() => onClick("all")}
                >
                    {t("ALL")}
                </Button>
            </ButtonGroup>
        </Box>
    );
}
