import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import useStyles from "./my-leave-chart-summary.style";

const MyLeaveChartSummary = ({
  summaryValue,
  summaryLabel,
  summaryType,
  centerTextLeft,
  centerTextTop,
}) => {
  const classes = useStyles({ centerTextLeft, centerTextTop });
  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.summaryValue}>
          {summaryValue?.toLocaleString()}
        </Typography>
      </div>
      <div>
        <Typography className={classes.summaryLabel}>{summaryLabel}</Typography>
      </div>
      <div>
        <Typography className={classes.summaryType}>{summaryType}</Typography>
      </div>
    </div>
  );
};

MyLeaveChartSummary.propTypes = {
  /**
   * Summary value of the chart
   */
  summaryValue: PropTypes.number,
  /**
   * The label of the summary value
   */
  summaryLabel: PropTypes.string,
  /**
   * Type of the leave
   */
  summaryType: PropTypes.string,
  /**
   * Left value of the absolut positioned component
   */
  centerTextLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Top value of the absolut positioned component
   */
  centerTextTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MyLeaveChartSummary.defaultProps = {
  summaryValue: 45,
  summaryLabel: "summary label",
  summaryType: "summary type",
  centerTextLeft: 50,
  centerTextTop: 50,
};

export default MyLeaveChartSummary;
