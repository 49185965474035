import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    blackBorder: {
        border: "1px solid black",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "8px",
        paddingBottom: "12px",
        width: "auto",
        height: "auto",
        fontSize: "16px",
    },
}));

export default useStyles;
