import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    "& .rbc-calendar": {
      fontFamily: "Mulish",
      minWidth: 880,
      //minHeight: 900,
      //height: "auto",
    },
    "& .rbc-date-cell": {
      fontFamily: "Mulish",
      fontSize: 12,
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 0,
    },
    "& .rbc-today": {
      backgroundColor: "rgba(204, 0, 51, 0.2)",
    },
    "& .rbc-weekend-day": {
      backgroundColor: theme.palette.gray20.main,
    },
    "& .rbc-payed-holiday-day": {
      color: theme.palette.primary.main,
    },
    "& .rbc-off-range": {
      color: theme.palette.white.main,
    },
    "& .rbc-off-range-bg": {
      backgroundColor: theme.palette.gray60.main,
      color: theme.palette.white.main,
    },
    "& .rbc-show-more": {
      color: "#cc0033",
      paddingLeft: 4,
      fontSize: 12,
      textDecoration: "none",
    },
    "& .rbc-event": {
      //position: "relative",
      borderRadius: 4,
      backgroundColor: "transparent",
      marginBottom: 2,
    },
    "& .rbc-event-continues-after": {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    "& .rbc-event-continues-prior": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    "& .rbc-event-content": {
      fontSize: 12,
      padding: 2,
      paddingLeft: 4,
    },
    "& .rbc-event:focus": {
      outline: "none",
    },
    "& .rbc-event-requested-leave": {
      backgroundColor: "#f3aa18",
      color: theme.palette.white.main,
      border: "2px solid #f3aa18",
      marginBottom: 2,
    },
    "& .rbc-event-approved-leave": {
      backgroundColor: "#2fa84f",
      color: theme.palette.white.main,
      border: "2px solid #2fa84f",
      marginBottom: 2,
    },
    "& .rbc-event-requested-absence": {
      backgroundColor: "#ffffff",
      border: "2px solid #f3aa18",
      color: "#f3aa18",
      marginBottom: 2,
    },
    "& .rbc-event-approved-absence": {
      backgroundColor: "#ffffff",
      border: "2px solid #2fa84f",
      color: "#2fa84f",
      marginBottom: 2,
    },
    "& .rbc-event-current-request": {
      backgroundColor: "#ffffff",
      border: "2px solid #cc0033",
      color: "#cc0033",
      marginBottom: 2,
    },
    "& .rbc-agenda-row:hover": {
      backgroundColor: "#F1F3F4",
    },
    "& .rbc-header": {
      fontFamily: "Mulish",
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.gray80.main,
      paddingBottom: 4,
      height: "auto",
      letterSpacing: "0.44px",
      borderBottom: "none",
    },
    "& .rbc-allday-cell": {
      display: "none",
    },
    "& .rbc-event-label": {
      display: "none",
    },
    "& .rbc-event-my-time-stand-by": {
      backgroundColor: "#A97D4F",
      border: "2px solid #A97D4F",
      color: "#fff",
      marginBottom: 2,
      padding: 0,
    },
    "& .rbc-event-my-time-working-time": {
      backgroundColor: "#367FA9",
      border: "2px solid #367FA9",
      color: "#fff",
      marginBottom: 2,
      padding: 0,
    },
    "& .rbc-event-my-time-working-time-item": {
      backgroundColor: "#919699",
      border: "2px solid #919699",
      color: "#fff",
      marginBottom: 2,
      padding: 0,
    },
    "& .rbc-event-my-time-leave": {
      backgroundColor: "#A990DD",
      border: "2px solid #A990DD",
      color: "#fff",
      marginBottom: 2,
      padding: 0,
    },
    "& .rbc-event-my-time-stand-by-call": {
      backgroundColor: "#FFF",
      border: "2px solid #A97D4F",
      color: "#212121",
      marginBottom: 2,
      padding: 0,
    },
    "& .rbc-event-my-time-requested": {
      fontSize: "12px",
      backgroundColor: "#FFF",
      border: "2px solid #F3AA18",
      color: "#212121",
      marginBottom: 2,
      padding: 0,
    },
    "& .rbc-event-my-time-approved": {
      backgroundColor: "#FFF",
      border: "2px solid #2FA84F",
      color: "#212121",
      marginBottom: 2,
      padding: 0,
    },
    "& .rbc-time-slot": {
      fontFamily: "Mulish",
      fontSize: 12,
      letterSpacing: "0.4px",
      color: "#5E6366",
    },
    "& .rbc-event-my-time-overtime": {
      backgroundColor: "#FFF",
      border: "2px solid #367FA9",
      color: "#212121",
      marginBottom: 2,
      padding: 0,
    },
    "& .rbc-event-my-time-homeoffice": {
      backgroundColor: "#f3aa18",
      border: "2px solid #f3aa18",
      color: "#ffffff",
      marginBottom: 2,
      padding: 0,
    },
    "& .rbc-event-my-time-on-call": {
      backgroundColor: "#578887",
      border: "2px solid #578887",
      color: "#ffffff",
      marginBottom: 2,
      padding: 0,
    },
  },
}));

export default useStyles;
