import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const taxDeclarationFirstMarriedPerson = atom({
  key: "taxDeclarationFirstMarriedPerson",
  default: {
    dateOfDeclaration: new Date(),
    isModifiedDeclaration: false,
    spouseName: "",
    spouseTaxNumber: "",
    spouseEmployerName: "",
    spouseEmployerTaxNumber: "",
    marriageDate: "",
    isAcknowledged: false, // deprecated
    applyInTaxYear: true,
    applyToWithdrawal: false,
    applyFromMonth: "",
    requestAmount: 0,
    noApply: false,
  },
  effects_UNSTABLE: [persistAtom],
});
