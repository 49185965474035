import * as Yup from "yup";
import { CREATE_CHANGE_REQUEST } from "graphql/mutations";

export const phonesWorkMobileDialogDef = {
  type: "phones",
  dialogLabel: "EDIT_PHONES_WORK_MOBILE",
  mutation: CREATE_CHANGE_REQUEST,
  items: [
    {
      label: "Common.validity",
      name: "validFrom",
      type: "datePicker",
      validation: Yup.date().required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "PHONE_WORKMOBILE",
      name: "workMobilePhone",
      dataField: "workMobile",
      type: "text",
      validation: Yup.string().test(
        "test-name",
        "NOT_VALID_PHONE_NUMBER",
        function (value) {
          if (!value) return true;
          const parsedValue = value
            .replace(/\s/g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/-/g, "");
          let isNum = /^\d+$/.test(parsedValue);
          if ((parsedValue.length === 8 || parsedValue.length === 9) && isNum) {
            return true;
          }
          return false;
        }
      ),
      inputAdornment: "+36",
    },
    {
      label: "Leave.message",
      name: "initialMessage",
      type: "textarea",
      validation: Yup.string(),
    },
  ],
};
