import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import searchIcon from "@iconify/icons-mdi/search";
import { InlineIcon } from "@iconify/react";
import closeIcon from "@iconify/icons-mdi/close";

const useStyles = makeStyles((theme) => ({
  basicBoxCss: {
    border: `1px solid ${theme.palette.gray40.main}`,
    borderRadius: "4px",
    color: theme.palette.darkBase.main,
  },
  boxError: {
    border: `1px solid ${theme.palette.primary.main} !important`,
    borderRadius: "4px",
    color: theme.palette.darkBase.main,
  },
}));

const OcFormEditorItemList = ({
  search,
  onSearch,
  onScroll,
  value,
  name,
  label,
  onClearSearch,
  list,
  onChange,
  error,
  showCode = false,
  showItem = false,
  paddingTop = "36px",
  showLabel = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  const observer = useRef();

  const handleScroll = () => {
    if (observer.current) {
      const { scrollTop, scrollHeight, clientHeight } = observer.current;
      if (scrollTop + clientHeight === scrollHeight) {
        onScroll();
      }
    }
  };

  const handleChange = (item) => {
    setExpanded(false);
    let value = item?.code;
    if (showItem) {
      value = item;
    }
    onChange(name, value);
  };

  let parsedLabel = t(label);
  if (value) {
    parsedLabel = showCode ? value?.code : value?.name;
  }

  return (
    <Box paddingTop={paddingTop}>
      <Collapse in={value ? true : false}>
        <Box
          style={{
            color: theme.palette.gray80.main,
            background: "white",
            fontSize: "12px",
            margin: "-7px 0px 0px 9px",
            padding: "0px 8px",
            position: "absolute",
          }}
        >
          {t(label)}
        </Box>
      </Collapse>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="536px"
        height="56px"
        paddingLeft="14px"
        className={error === true ? classes.boxError : classes.basicBoxCss}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          color={
            value !== undefined
              ? `${theme.palette.darkBase.main}`
              : error === true
              ? `${theme.palette.primary.main}`
              : `${theme.palette.gray80.main}`
          }
        >
          <Box fontSize="16px">{parsedLabel}</Box>
        </Box>
        <Box paddingRight="28px">
          <IconButton
            id="data-filter-item-header-button"
            onClick={handleExpanded}
            aria-expanded={expanded}
            aria-label="show more"
            style={
              expanded
                ? {
                    padding: 0,
                    transform: "rotate(180deg)",
                  }
                : {
                    padding: 0,
                  }
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded}>
        <Box
          padding="16px"
          style={{
            backgroundColor: theme.palette.gray30.main,
            borderRadius: "4px",
          }}
        >
          <Box
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              height: "48px",
            }}
          >
            <TextField
              id={`search-${name}`}
              label=""
              variant="outlined"
              fullWidth
              value={search}
              onChange={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InlineIcon icon={searchIcon} height={20} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <InlineIcon
                      id={`close-${label}`}
                      icon={closeIcon}
                      onClick={onClearSearch}
                      style={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
                style: {
                  height: "48px",
                },
              }}
            />
          </Box>
          <Box height="16px" />
          <Box
            ref={observer}
            onScroll={handleScroll}
            style={{
              backgroundColor: "white",
              fontSize: "16px",
              letterSpacing: "0.44px",
              lineHeight: "24px",
              padding: "8px 8px",
              maxHeight: "180px",
              overflowY: "auto",
              color: theme.palette.darkBase.main,
              border: "1px solid #C6CACC",
            }}
          >
            {list?.map((item, code) => {
              return (
                <Box key={`${item}-${code}`} onClick={() => handleChange(item)}>
                  <MenuItem
                    id={item?.code}
                    key={`${item}-${code}`}
                    value={item?.code}
                  >
                    {showCode ? item?.code : item?.name}
                  </MenuItem>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default OcFormEditorItemList;
