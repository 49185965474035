import { useQuery, useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import { GET_USERS } from "graphql/queries";
import { EMPLOYEE_SUBSCRIPTION } from "graphql/subcriptions";
import { useTranslation } from "react-i18next";
import useCurrentUserState from "hooks/use-current-user-state";
import usePolling from "hooks/use-polling";

export default function useUsers({
  filterField,
  filterSelected,
  page,
  pageSize,
  searchField,
  searchText,
  sortDirection,
  sortField,
}) {
  const { t, i18n } = useTranslation();

  let { contractCode } = useCurrentUserState();

  let innerSearchField =
    searchField && searchText.length > 2 ? searchField : "";
  let innerSearchText = searchText.length > 2 ? searchText : "";

  const { data, loading, refetch } = useQuery(GET_USERS, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    skip: !contractCode,
    variables: {
      filterField,
      filterSelected,
      page,
      pageSize,
      sortField,
      sortDirection,
      searchField: innerSearchField,
      searchText: innerSearchText,
    },
  });

  useSubscription(EMPLOYEE_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  let users = _.cloneDeep(data?.users) || {
    page: 1,
    pageSize: 25,
    rows: [],
    total: 0,
    totalPages: 1,
  };

  users.rows.map((item) => {
    item.active = true;
    let now = new Date().getTime();
    if (item.validTo) {
      if (item.validTo) {
        let validTo = new Date(item.validTo).getTime();
        if (validTo < now) {
          item.active = false;
        }
      }
    }

    if (item?.lastLogin) {
      item.lastLogin = new Date(item.lastLogin).toLocaleString(i18n.language, {
        hour12: false,
      });
    } else {
      item.lastLogin = t("NOT_LOGGED_IN");
    }

    item.code = item.employee;

    return { item };
  });

  return { data: users, loading };
}
