import React, { useRef } from "react";
import Box from "@material-ui/core/Box";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";

export default function OcFilterItemCheckedList({
  selectedItems,
  availableItems,
  addItem,
  removeItem,
  filterType,
  onFetchMore,
  roleFilters,
}) {
  const { t } = useTranslation();
  let allState =
    roleFilters[filterType]?.length === 1 && roleFilters[filterType][0] === "*";

  if (availableItems?.length === selectedItems?.length) {
    allState = true;
  }

  const observer = useRef();
  if (availableItems?.length === 0) return null;

  const handleScroll = () => {
    if (observer.current) {
      const { scrollTop, scrollHeight, clientHeight } = observer.current;
      if (scrollTop + clientHeight === scrollHeight) {
        onFetchMore();
      }
    }
  };

  const getCheckedState = (item) => {
    const isExist = selectedItems.find(
      (selectedItem) => selectedItem.code === item.code
    );
    return isExist || allState ? true : false;
  };

  const handleAllStateChange = (e) => {
    if (!e.target.checked) {
      addItem({ key: filterType, value: ["-"] });
    } else {
      addItem({ key: filterType, value: [] });
    }
  };

  const handleChange = (item, checked) => {
    if (allState && !checked) {
      const filteredResult = availableItems.filter(
        (filterItem) => filterItem.code !== item.code
      );
      const filteredResultCodes = filteredResult.map(
        (codeItem) => codeItem.code
      );
      if (filteredResultCodes?.length === 0) {
        addItem({ key: filterType, value: ["-"] });
      } else {
        addItem({ key: filterType, value: filteredResultCodes });
      }
      return;
    }

    if (checked) {
      addItem({ key: filterType, value: item?.code });
    } else {
      if (roleFilters[filterType].length === 1) {
        addItem({ key: filterType, value: ["-"] });
      } else {
      }
      removeItem({ key: filterType, value: item?.code });
    }
  };

  return (
    <OcErrorBoundary>
      <Box
        ref={observer}
        onScroll={handleScroll}
        style={{
          backgroundColor: "white",
          fontSize: "16px",
          letterSpacing: "0.44px",
          lineHeight: "24px",
          padding: "8px 8px 8px 8px",
          maxHeight: "180px",
          overflowY: "auto",
          border: "1px solid #C6CACC",
          borderRadius: "4px",
        }}
      >
        <Box padding="0px 11px">
          <FormControlLabel
            control={
              <Checkbox
                checked={allState}
                onChange={handleAllStateChange}
                name="checkedA"
              />
            }
            label={"Összes"}
          />
        </Box>
        <Box padding="0px 8px">
          <Box style={{ height: "1px", backgroundColor: "#919699" }} />
        </Box>
        {availableItems?.map((item) => {
          let label = `${item.name} (${item.code})`;
          if (item?._id === "empty") {
            label = t(item?.name?.toUpperCase());
          }

          return (
            <Box key={item.code} padding="0px 43px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getCheckedState(item)}
                    onChange={(e) => handleChange(item, e.target.checked)}
                    name="checkedA"
                  />
                }
                label={label}
              />
            </Box>
          );
        })}
      </Box>
    </OcErrorBoundary>
  );
}
