import gql from "graphql-tag";

export const GET_RECORDING_PERIOD = gql`
  query getRecordingPeriod {
    getRecordingPeriod {
      year
      month
    }
  }
`;
