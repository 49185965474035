import gql from "graphql-tag";

export const APPROVE_CHANGE_REQUEST = gql`
  mutation approveChangeRequest(
    $_id: String!
    $locale: String!
    $message: String
  ) {
    approveChangeRequest(_id: $_id, locale: $locale, message: $message) {
      result
      messages
    }
  }
`;
