import React from "react";
import moment from "moment";
import "moment/locale/hu";
import useClientState from "hooks/use-oc-client-state";
import PayrollMonthlyData from "my-hr/components/my-hr-payroll-monthly-data";
import PayrollYearlyData from "my-hr/components/my-hr-payroll-yearly-data";

export default function MyHrPayrollDataPanel({ contract }) {
    let currentYear = moment().format("YYYY");
    let currentMonth = moment().format("MM");

    const [selectedYear, setSelectedYear] = useClientState(
        "selectedPayrollYear",
        currentYear
    );

    const [selectedMonth, setSelectedMonth] = useClientState(
        "selectedPayrollMonth",
        currentMonth
    );

    let showBackToDate =
        selectedYear === currentYear && selectedMonth === currentMonth
            ? false
            : true;

    function handleSelectMonth(month) {
        setSelectedMonth(month);
    }

    function handleSelectYear(year) {
        setSelectedYear(year);
    }

    function handleBackToCurrent() {
        setSelectedYear(currentYear);
        setSelectedMonth(currentMonth);
    }

    const [showPaycard] = useClientState("showPaycard", false);

    return (
        <div>
            <PayrollMonthlyData
                year={selectedYear}
                month={selectedMonth}
                contract={contract}
                onBack={handleBackToCurrent}
                showComponent={showBackToDate}
            />

            {!showBackToDate && !showPaycard && (
                <PayrollYearlyData
                    contract={contract}
                    onSelectMonth={handleSelectMonth}
                    onSelectYear={handleSelectYear}
                />
            )}
        </div>
    );
}
