import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import useGenders from "hooks/use-genders";

const OcFormEditorGenderSelector = ({ name, value, label, onChange }) => {
  const { t } = useTranslation();
  const { data: genders } = useGenders();
  const getGender = (code) => genders.find((item) => item.code === code);
  return (
    <Box paddingTop="36px">
      <FormControl
        id={"gender-form-control"}
        variant="outlined"
        style={{
          height: "56px",
          width: "100%",
        }}
      >
        <InputLabel htmlFor={"gender-select"}>{t(label)}</InputLabel>
        <Select
          id={name}
          name={name}
          value={value.code || ""}
          label={t(label)}
          defaultValue=""
          onChange={(e) => onChange(name, getGender(e.target.value))}
        >
          {genders.map((item, code) => (
            <MenuItem key={`${item}-${code}`} id={`${code}`} value={item?.code}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default OcFormEditorGenderSelector;
