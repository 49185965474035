import gql from "graphql-tag";

export const GET_WORK_TIME_WEEKLY_DATA = gql`
  query getWorkTimeWeeklyData($contractCode: String!, $date: Date!) {
    getWorkTimeWeeklyData(contractCode: $contractCode, date: $date) {
      weeklyVoluntaryOvertimeLimit
      weeklyWorktimeLimit
    }
  }
`;
