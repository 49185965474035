import React from "react";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@material-ui/core";

const OcFormEditorMessageTextInput = ({ name, value, label, onChange }) => {
  const { t } = useTranslation();
  return (
    <Box paddingTop="36px">
      <TextField
        id={name}
        name={name}
        label={t(label)}
        multiline
        value={value}
        onChange={onChange}
        fullWidth
        variant="outlined"
        helperText={
          value.length === 0
            ? `(${t("Common.optional")})`
            : `${value.length}/255`
        }
        style={{
          height: "auto",
        }}
        inputProps={{ maxLength: 255 }}
        FormHelperTextProps={
          value.length === 0
            ? { style: { textAlign: "left" } }
            : { style: { textAlign: "right" } }
        }
      />
    </Box>
  );
};

export default OcFormEditorMessageTextInput;
