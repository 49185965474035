import gql from "graphql-tag";

export const GET_OPENED_LEAVE_REQUESTS = gql`
  query getOpenedRequests(
    $onlyRequested: Boolean!
    $filter: Boolean!
    $startDate: Date!
    $endDate: Date!
    $roleFilters: RoleFilterInput!
  ) {
    getOpenedRequests(
      onlyRequested: $onlyRequested
      filter: $filter
      startDate: $startDate
      endDate: $endDate
      roleFilters: $roleFilters
    ) {
      contract
      employee
      prefixName
      lastName
      firstName
      middleName
      nameSuffix
      orgUnit
      jobClass
      requests {
        _id
        startDate
        endDate
        workDays
        state
        approvedAt
        approvedBy
        rejectedAt
        rejectedBy
        createdAt
        createdBy
      }
    }
  }
`;
