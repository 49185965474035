import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    messageItemContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 24,
    },
    messageItemInnerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    messageItem: {
        backgroundColor: theme.palette.gray60.main,
        color: "white",
        fontFamily: "Mulish",
        padding: "10px 15px 10px 15px",
        borderRadius: 4,
        border: `1px solid ${theme.palette.gray60.main}`,
        textAlign: "right",
    },
    avatar: {
        width: "40px",
        height: "40px",
        marginTop: 12,
        marginRight: 10,
    },
    smallText: {
        fontFamily: "Mulish",
        fontSize: 10,
        paddingLeft: 10,
        color: theme.palette.gray60.main,
        textTransform: "capitalize",
        paddingRight: 2,
        textAlign: "center",
        marginBottom: "3px",
    },
}));

export default useStyles;
