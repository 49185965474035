import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_EMPLOYEES } from "graphql/queries";
import { EMPLOYEES_SUBSCRIPTION } from "graphql/subcriptions";
import { useTranslation } from "react-i18next";
import getFullname from "oc/utils/get-fullname";
import usePolling from "hooks/use-polling";
import getMothersFullname from "oc/utils/get-mothers-fullname";

export default function useEmployees({
  page,
  pageSize,
  searchField,
  searchText,
  sortDirection,
  sortField,
}) {
  const { i18n } = useTranslation();

  let innerSearchField =
    searchField && searchText.length > 2 ? searchField : "";
  let innerSearchText = searchText.length > 2 ? searchText : "";

  const { data, loading, error, refetch } = useQuery(GET_EMPLOYEES, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: {
      page,
      pageSize,
      sortField,
      sortDirection,
      searchField: innerSearchField,
      searchText: innerSearchText,
    },
  });

  if (error) {
    console.log({
      component: "useEmployees",
      errorDescription:
        "Error occured when fetch data with GET_EMPLOYEES query",
      error: error,
    });
  }

  if (error) {
    console.log({
      component: "useEmployees",
      errorDescription:
        "Error occured when fetch data with GET_EMPLOYEES query",
      error: error,
    });
  }

  const { error: subscriptionError } = useSubscription(EMPLOYEES_SUBSCRIPTION, {
    onData: () => {
      refetch();
    },
  });

  if (subscriptionError) {
    console.log({
      component: "useEmployees",
      errorDescription:
        "Error occured when fetch data with EMPLOYEES_SUBSCRIPTION query",
      error: error,
    });
  }

  let resultData = data?.employees || {
    page: 1,
    pageSize: 25,
    rows: [],
    total: 0,
    totalPages: 1,
  };

  let employees = JSON.parse(JSON.stringify(resultData));

  employees.rows.map((item) => {
    item.dateOfBirth = new Date(item.dateOfBirth).toLocaleDateString(
      i18n.language
    );

    item.fullname = getFullname(item);
    item.mothersfullname = getMothersFullname(item);

    return { item };
  });

  return { data: employees, loading };
}
