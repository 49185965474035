import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {
  mainMenu,
  settingsSubmenu,
  mainMenuChangeRequestsEnabled,
  mainMenuChangeRequestsDisabled,
} from "./config";
import Selector from "oc/components/oc-private-layout-sidebar-selector";
import Slide from "@material-ui/core/Slide";
import { InlineIcon } from "@iconify/react";
import backburgerIcon from "@iconify/icons-mdi/backburger";
import { Transition } from "react-transition-group";
import PartnerLogo from "oc/components/oc-private-layout-sidebar-partner-logo";
import Fade from "@material-ui/core/Fade";
import { useRecoilState } from "recoil";
import { activeMenuItemState } from "states/active-menu-item-state";
import { activeMenuState } from "states/active-menu-state";
import useProperty from "hooks/use-oc-property";
import _ from "lodash";
const { useRecoilValue } = require("recoil");
const { customerLicencesState } = require("states");

export default function OcSidebar({
  narrowMenu,
  narrowMenuOpened,
  onNarrowMenuChange,
  onNarrowMenuOpened,
  open,
  showDrawerTransition,
  variant,
}) {
  const theme = useTheme();

  //const { licences } = useCustomerLicences();
  const licences = useRecoilValue(customerLicencesState);
  const [menu, setMenu] = useState(mainMenu);

  const { data: changeRequestsEnabled } = useProperty({
    resource: "system",
    key: "changeRequestsEnabled",
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const changeRequestsState = data?.value === "true" ? true : false;
      const newMenu = [...mainMenu];
      if (changeRequestsState) {
        newMenu.splice(1, 0, mainMenuChangeRequestsEnabled);
      } else {
        newMenu.splice(1, 0, mainMenuChangeRequestsDisabled);
      }

      if (!_.isEqual(newMenu, menu)) {
        setMenu(newMenu);
      }
    },
  });

  let changeRequestsState = changeRequestsEnabled === "true" ? true : false;

  const [activeMenuItem, setActiveMenuItem] =
    useRecoilState(activeMenuItemState);

  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);

  const isSettingsSubmenu = _.find(menu, { id: "backToMainMenu" });
  const isMainMenu = _.find(menu, { id: "menuGroup" });

  if (activeMenu === "settingsSubmenu") {
    if (!isSettingsSubmenu) {
      setMenu(settingsSubmenu);
    }
  }

  if (activeMenu === "mainMenu") {
    if (!isMainMenu) {
      const newMenu = [...mainMenu];
      if (changeRequestsState) {
        newMenu.splice(1, 0, mainMenuChangeRequestsEnabled);
      } else {
        newMenu.splice(1, 0, mainMenuChangeRequestsDisabled);
      }

      if (!_.isEqual(newMenu, menu)) {
        setMenu(newMenu);
      }
    }
  }

  function handleMenuItemChange(id) {
    setActiveMenuItem(id);
  }

  function handleMenuChange(id) {
    setActiveMenu(id);
  }

  const duration = 250;

  let width = narrowMenu ? 52 : 310;

  const defaultStyle = showDrawerTransition
    ? {
        transition: `width ${duration}ms ease-in-out`,
        width: width,
      }
    : {};

  const transitionStyles = {
    entering: { width: 52 },
    entered: { width: 52 },
    exiting: { width: 310 },
    exited: { width: 310 },
  };

  let transition = narrowMenu;
  if (narrowMenuOpened) {
    transition = false;
  }

  return (
    <Transition in={transition} timeout={duration}>
      {(state) => (
        <Drawer
          variant={variant}
          anchor="left"
          open={open}
          id="leftDrawer"
          onMouseEnter={() => {
            if (narrowMenu) {
              onNarrowMenuOpened(true);
            }
          }}
          onMouseLeave={() => {
            if (narrowMenu) {
              onNarrowMenuOpened(false);
            }
          }}
        >
          <Box
            id="oc-sidebar-menu"
            style={{
              overflow: "hidden",
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <Box
              id="oc-sidebar-menu-top"
              height="64px"
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.white.main,
              }}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Fade in={!narrowMenu || narrowMenuOpened} unmountOnExit>
                <PartnerLogo />
              </Fade>
              <Fade in={narrowMenuOpened} unmountOnExit>
                <Box
                  id="oc-sidebar-narrow-menu-icon"
                  height="64px"
                  width="52px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => onNarrowMenuChange(!narrowMenu)}
                  style={narrowMenu ? { transform: "rotate(180deg)" } : null}
                >
                  <InlineIcon icon={backburgerIcon} height="26px" />
                </Box>
              </Fade>
              <Fade in={!narrowMenu && !narrowMenuOpened} unmountOnExit>
                <Box
                  id="oc-sidebar-narrow-menu-icon"
                  height="64px"
                  width="52px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => onNarrowMenuChange(!narrowMenu)}
                  style={narrowMenu ? { transform: "rotate(180deg)" } : null}
                >
                  <InlineIcon icon={backburgerIcon} height="26px" />
                </Box>
              </Fade>
              <Fade in={narrowMenu && !narrowMenuOpened} unmountOnExit>
                <Box
                  height="64px"
                  width="52px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => onNarrowMenuChange(!narrowMenu)}
                >
                  <img
                    src="/img/owIconLogoTransparent.png"
                    alt="ow-logo"
                    width="32"
                  ></img>
                </Box>
              </Fade>
            </Box>
            {activeMenu === "mainMenu" && (
              <Slide in={activeMenu === "mainMenu"} direction="right">
                <List style={{ padding: "0px" }}>
                  {menu.map((item, index) => {
                    if (!licences[item?.licence]) {
                      return null;
                    }
                    return (
                      <Selector
                        key={`${index}_${item.id}`}
                        item={item}
                        index={index}
                        onMenuChange={handleMenuChange}
                        onMenuItemChange={handleMenuItemChange}
                        activeMenuItem={activeMenuItem}
                        narrowMenu={narrowMenu}
                        onNarrowMenuChange={onNarrowMenuChange}
                        narrowMenuOpened={narrowMenuOpened}
                      />
                    );
                  })}
                </List>
              </Slide>
            )}
            {activeMenu !== "mainMenu" && (
              <Slide in={activeMenu !== "mainMenu"} direction="left">
                <List>
                  {menu.map((item, index) => {
                    if (!licences[item?.licence]) {
                      return null;
                    }

                    return (
                      <Selector
                        key={`${index}_${item.id}`}
                        item={item}
                        index={index}
                        onMenuChange={handleMenuChange}
                        onMenuItemChange={handleMenuItemChange}
                        activeMenuItem={activeMenuItem}
                        narrowMenu={narrowMenu}
                        onNarrowMenuChange={onNarrowMenuChange}
                        narrowMenuOpened={narrowMenuOpened}
                      />
                    );
                  })}
                </List>
              </Slide>
            )}
          </Box>
        </Drawer>
      )}
    </Transition>
  );
}
