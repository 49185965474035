import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { GET_JOB_CLASS_GROUPS_ADMIN_LIST } from "graphql/queries";
import { JOB_CLASS_GROUPS_SUBSCRIPTION } from "graphql/subcriptions";
import { useQuery, useSubscription } from "@apollo/client";
import usePolling from "hooks/use-polling";
import FilterItem from "oc/components/oc-filter-item";
import Grid from "@material-ui/core/Grid";
import useFiltersData from "hooks/use-filters-data";
import useQueryParams from "hooks/use-query-params";
import useFilterChange from "hooks/use-filter-change";

export default function MyHrJobClassGroupFilter({ role, onUpdateRole }) {
  const filterType = "jobClassGroup";

  const entity = { jobClassGroups: role?.roleFilters?.jobClassGroup || [] };
  const stateId = "myHrJobClassGroupsFilter";
  const { search, setSearch, setPageSize, queryParams } = useQueryParams({
    stateId,
    entity,
    product: role?.product,
    role: role?.role,
    roleFilters: role?.rolefilters,
    contract: role?.contract,
  });

  const {
    data: rawData,
    loading,
    fetchMore,
  } = useQuery(GET_JOB_CLASS_GROUPS_ADMIN_LIST, {
    fetchPolicy: "no-cache",
    pollInterval: usePolling(1000 * 60),
    variables: queryParams,
  });

  useSubscription(JOB_CLASS_GROUPS_SUBSCRIPTION);

  const data = rawData?.jobClassGroupsAdminList;

  const {
    availableItems,
    selectedItems,
    handleFetchMore,
    handleSearch,
    handleClearSearch,
  } = useFiltersData({
    data,
    fetchMore,
    setSearch,
    setPageSize,
    product: role?.product,
    role: role?.role,
    filterType,
    contract: role?.contract,
  });

  const { handleAddFilter, handleRemoveFilter } = useFilterChange({
    role,
    onUpdateRole,
  });

  return (
    <OcErrorBoundary>
      <Grid item xs={6}>
        <FilterItem
          filterType={filterType}
          selectedItems={selectedItems}
          availableItems={availableItems}
          search={search}
          onLoading={loading}
          onSearch={handleSearch}
          onClearSearch={handleClearSearch}
          onAddFilter={handleAddFilter}
          onRemoveFilter={handleRemoveFilter}
          withCode={true}
          onFetchMore={handleFetchMore}
          showLists={true}
        />
      </Grid>
    </OcErrorBoundary>
  );
}
