import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import ApplicationHeader from "../application-header/application-header";
import OcCard from "oc/components/oc-card";
import { useReactToPrint } from "react-to-print";
import FirstMarriageTaxCreditApplicationPrint from "../first-marriage-tax-credit-application-print/first-marriage-tax-credit-application-print";
import OcDownload from "oc/components/oc-download";
import ApplicationCardInfoItem from "../application-card-info-item/application-card-info-item";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { NOT_PROVIDED } from "my-hr/components/my-hr-applications-data-panel/constants";
import Collapse from "@material-ui/core/Collapse";
import useSpousesDataDI from "hooks/use-spouses-data";
import OcMonthSelect from "oc/components/oc-month-select";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import useCurrentUserState from "hooks/use-current-user-state";
import ApplicationCollapseHeader from "../application-collapse-header";
import humanMaleFemale from "@iconify/icons-mdi/human-male-female";
import ModifiedStatementCheck from "../modified-statement-check/modified-statement-check";
import TaxCreditApplicationButtonBars from "../tax-credit-application-buttonbars/tax-credit-application-buttonbars";
import TaxCreditApplicationCheckboxLine from "../tax-credit-application-checkbox-line/tax-credit-application-checkbox-line";
import { taxDeclarationFirstMarriedPerson } from "../../states/tax-declaration-first-married-person";
import useClientState from "hooks/use-oc-client-state";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import OcDatePicker from "oc/components/oc-date-picker";
import getDateFormatByLocale from "oc/utils/get-date-format-by-locale";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { firstApplyInTaxYearOrToWithDrawal } from "../../states/first-apply-in-tax-year-or-to-with-drawal";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_TAX_DECLARATION_FIRST_MERRIED_PERSON } from "graphql/queries";
import { CREATE_TAX_DECLARATION_FIRST_MARRIED_PERSON } from "graphql/mutations";
import TaxCreditApplicationErrorBox from "my-hr/components/my-hr-applications-data-panel/components/tax-credit-application-error-box/tax-credit-application-error-box";
import useOcSnackbar from "hooks/use-oc-snackbar";

const useStyles = makeStyles((theme) => ({
  topBorder: {
    borderTop: `2px solid ${theme.palette.gray40.main}`,
  },
  backgroundColor: {
    backgroundColor: `${theme.palette.gray20.main}`,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#212121",
    color: "#FFFFFF",
    maxWidth: "400px",
    height: "auto",
    fontSize: "16px",
  },
}))(Tooltip);

const SpouseCollapse = ({ editable, states, onChange }) => {
  const [expanded, setExpanded] = useClientState(
    "spouseFilterCollapseExpanded",
    true
  );

  const classes = useStyles(expanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <ApplicationCollapseHeader
        handleExpandClick={handleExpandClick}
        expanded={expanded}
        icon={humanMaleFemale}
        dependentName={states?.spouseName}
        dependentTaxNumber={
          states?.spouseTaxNumber ? states?.spouseTaxNumber : NOT_PROVIDED
        }
        taxNumberLabel="Adóazonosító jele"
      />
      <Collapse in={expanded} timeout="auto">
        <Box className={classes.backgroundColor} padding="0px 16px">
          <Box className={classes.topBorder} />
          <Box display="flex" flexDirection="column" padding="16px 0px">
            <Typography variant="body1">
              Adóelőleget megállapító munkáltató, kifizető megnevezése
            </Typography>
            <Box height="16px" />
            <Box
              marginRight="16px"
              style={{
                borderRadius: "4px",
              }}
            >
              <TextField
                id="spouse-collapse-companyName"
                value={states?.spouseEmployerName || ""}
                style={{ width: "467px" }}
                variant="outlined"
                label="Megnevezés"
                type="string"
                onChange={(e) => {
                  onChange({
                    key: "spouseEmployerName",
                    value: e.target.value,
                  });
                }}
                disabled={!editable}
              />
              <Box height="36px" />
              <Typography variant="body1">
                Adóelőleget megállapító munkáltató, kifizető adószáma
              </Typography>
              <Box height="16px" />
              <Box
                marginRight="16px"
                style={{
                  borderRadius: "4px",
                }}
              >
                <TextField
                  id="spouse-collapse-companyTaxNumber"
                  value={states?.spouseEmployerTaxNumber || ""}
                  style={{ width: "467px" }}
                  variant="outlined"
                  label="Adószám"
                  type="string"
                  onChange={(e) => {
                    onChange({
                      key: "spouseEmployerTaxNumber",
                      value: e.target.value,
                    });
                  }}
                  disabled={!editable}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Collapse>
      <Box></Box>
    </Box>
  );
};

const useFormData = ({ _id, setSelected }) => {
  const { t } = useTranslation();
  const [saveErrors, setSaveErrors] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [states, setStates] = useRecoilState(taxDeclarationFirstMarriedPerson);
  const resetStates = useResetRecoilState(taxDeclarationFirstMarriedPerson);

  const { data, loading, error } = useQuery(
    GET_TAX_DECLARATION_FIRST_MERRIED_PERSON,
    {
      fetchPolicy: "cache-and-network",
      skip: !_id,
      variables: {
        _id: parseInt(_id),
      },
      onCompleted: (data) => {
        const result = data?.taxDeclarationFirstMarriedPerson;
        const newStates = { ...result };
        newStates.dateOfDeclaration = new Date(result?.dateOfDeclaration);
        delete newStates.__typename;
        setStates(newStates);
      },
    }
  );

  const result = data?.taxDeclarationFirstMarriedPerson;

  const handleChange = ({ key, value }) => {
    const newState = { ...states, [key]: value };
    setStates(newState);
  };

  const handleStateChange = (newState) => {
    setStates(newState);
  };

  const { setOpenMessage } = useOcSnackbar();
  const [saveDeclaration, { loading: mutationLoading }] = useMutation(
    CREATE_TAX_DECLARATION_FIRST_MARRIED_PERSON,
    {
      onCompleted: (data) => {
        const res = data?.createTaxDeclarationFirstMarriedPerson;
        setDisabledButton(false);
        if (res?.result) {
          if (res?.messages?.length > 0) {
            const message = res?.messages[0];
            if (
              message === "A korábban beadott nyilatkozata törlésre került!"
            ) {
              setOpenMessage({
                type: "success",
                message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
              });
              setOpenMessage({
                type: "warning",
                message: t("PREVIOUS_TAX_DECLARATION_DELETED"),
              });
            }
          } else {
            setOpenMessage({
              type: "success",
              message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
            });
          }
          setSaveErrors([]);
          const params = {
            editable: false,
            tableId: res?.tableId,
            year: states?.dateOfDeclaration?.getFullYear().toString(),
            type: "TaxDeclarationFirstMarriedPerson",
            state: "requested",
          };
          setSelected(params);
        } else {
          setSaveErrors(res?.messages);
          setDisabledButton(false);
        }
      },
      onError: () => {
        setSaveErrors([t("TAX_DECLARATION_CREATED_FAILED")]);
        setDisabledButton(false);
      },
    }
  );

  useEffect(() => {
    if (!states?.isModifiedDeclaration) {
      handleStateChange({ ...states, noApply: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states?.isModifiedDeclaration]);

  const handleSave = () => {
    setDisabledButton(true);
    const params = { ...states };
    saveDeclaration({ variables: params });
  };

  return {
    data: result,
    loading: loading || mutationLoading,
    error,
    states,
    disabledButton,
    resetStates,
    handleChange,
    handleStateChange,
    handleSave,
    saveErrors,
    setSaveErrors,
  };
};

const FirstMarriageTaxCreditApplication = ({
  fullName,
  taxNumber,
  useSpousesData = useSpousesDataDI,
  taxApplicationSaveEnabled,
  handleRemoveRequest,
  selected,
  setSelected,
}) => {
  const editable = selected?.editable;
  const history = useHistory();

  const title =
    "Adóelőleg-nyilatkozat az első házasok kedvezményének érvényesítéséről ";

  const componentRef = useRef();

  const {
    states,
    loading,
    error,
    saveErrors,
    disabledButton,
    setSaveErrors,
    resetStates,
    handleChange,
    handleStateChange,
    handleSave,
  } = useFormData({
    _id: selected?.tableId,
    setSelected,
  });

  const isModified = selected?.isModified;
  if (isModified && !states?.isModifiedDeclaration) {
    handleStateChange({ ...states, isModifiedDeclaration: true });
  }
  const showModifiedButton = states?.state === "E" ? true : false;
  const showRemoveButton =
    states?.state === "K" || selected?.sate === "K" ? true : false;

  const dateOfDeclarationString = new Date(
    states?.dateOfDeclaration
  ).toLocaleDateString();

  const [marriageDate, setMarriageDate] = useState(states?.marriageDate);

  useEffect(() => {
    setMarriageDate(states?.marriageDate);
  }, [states?.marriageDate]);

  const changeMarriageDate = (newDate) => {
    setMarriageDate(newDate);
    handleChange({
      key: "marriageDate",
      value: new Date(newDate).toLocaleDateString(),
    });
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    print();
  };

  const { t, i18n } = useTranslation();

  const { data: spouses } = useSpousesData();

  if (editable) {
    if (spouses?.length > 0) {
      if (spouses[0].name !== states?.spouseName) {
        handleChange({ key: "spouseName", value: spouses[0].name });
      }
      if (spouses[0].taxNumber !== states?.spouseTaxNumber) {
        handleChange({ key: "spouseTaxNumber", value: spouses[0].taxNumber });
      }
    }
  }

  const handleCancel = () => {
    resetStates();
    resetRadioGroupState();
    setSaveErrors([]);
  };

  useEffect(() => {
    return () => {
      handleCancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let { contractCode, employeeCode } = useCurrentUserState();
  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  let selectedContract = contractsData?.find(
    (item) => item.code === contractCode
  );

  let disabled = true;

  const handleModChange = ({ key, value }) => {
    handleChange({ key, value });
  };

  const [radioGroupState, setRadioGroupState] = useRecoilState(
    firstApplyInTaxYearOrToWithDrawal
  );

  const resetRadioGroupState = useResetRecoilState(
    firstApplyInTaxYearOrToWithDrawal
  );

  const handleRadioGroupChange = (e) => {
    if (e.target.value === "applyInTaxYear") {
      const newStates = {
        ...states,
        applyInTaxYear: true,
        applyToWithdrawal: false,
      };
      handleStateChange(newStates);
    } else {
      const newStates = {
        ...states,
        applyToWithdrawal: true,
        applyInTaxYear: false,
      };
      handleStateChange(newStates);
    }
    setRadioGroupState(e.target.value);
  };

  if (!editable && states?.applyToWithdrawal) {
    setRadioGroupState("applyToWithdrawal");
  }

  const handleScrollToError = () => {
    const errorElement = document.getElementById("errorBox");
    if (errorElement) {
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    }
  };

  useLayoutEffect(() => {
    handleScrollToError();
  }, [saveErrors, history.action]);

  useEffect(() => {
    if (history.action === "POP" && editable) {
      return handleCancel();
    }
  }, []); //eslint-disable-line

  return (
    <>
      <Box
        style={{
          marginBottom: "-15px",
        }}
      >
        <Box
          sx={{
            display: "none",
            displayPrint: "block",
            paddingRight: "85px",
          }}
        >
          <FirstMarriageTaxCreditApplicationPrint
            ref={componentRef}
            fullName={fullName}
            taxNumber={taxNumber}
            isModifiedApplication={states?.isModifiedDeclaration}
            marriageDiscountContract={states?.isAcknowledged}
            marriageMounth={states?.applyFromMonth}
            marriageAmount={states?.requestAmount}
            noneMarriageDiscountContract={states?.noApply}
            states={states}
            spouses={spouses}
            employerName={selectedContract?.company?.name || ""}
            employerTaxNumber={selectedContract?.company?.taxNumber || ""}
            marriageDateDate={states?.marriageDate || ""}
            applicationDate={dateOfDeclarationString}
          />
        </Box>
        <ApplicationHeader title={title} onCancel={handleCancel} />
        <Box height="16px" />
        <Box display="flex" justifyContent="space-around">
          <Box
            style={{
              padding: "7px",
              width: "260px",
              borderRadius: "4px",
            }}
          >
            <OcDownload
              label="Tájékoztató"
              actionIconType="url"
              href={"#/my-hr/first-marriage-tax-credit-information"}
              target="_blank"
              isSmall={true}
            />
          </Box>
        </Box>
        <Box height="16px" />
        {saveErrors.length > 0 && (
          <Box id="errorBox">
            <TaxCreditApplicationErrorBox error={saveErrors} />
          </Box>
        )}
        <OcCard
          label="I. A nyilatkozatot adó magánszemély"
          loading={loading}
          error={error}
        >
          <Box height="52px" />
          <ApplicationCardInfoItem
            label="Dátum"
            value={dateOfDeclarationString}
          />
          <ApplicationCardInfoItem label="Dolgozó neve" value={fullName} />
          <ApplicationCardInfoItem
            label="Adóazonosító jele"
            value={taxNumber}
          />
          <Box height="15px" />
          <ModifiedStatementCheck
            label={t("MODIFIED_APPLICATION")}
            name="isModifiedDeclaration"
            value={states?.isModifiedDeclaration}
            onChange={handleModChange}
            disabled={!editable || isModified}
          />
          <Box height="15px" />
          <Box display="flex" flexDirection="row">
            <Typography variant="body1">1.</Typography>
            <Box width="16px" />
            <Typography variant="body1">Házastársam</Typography>
          </Box>
          <Box height="30px" />
          {spouses.length !== 0 ? (
            <SpouseCollapse
              editable={editable}
              states={states}
              onChange={handleChange}
            />
          ) : (
            <ApplicationCollapseHeader
              dependentName={NOT_PROVIDED}
              dependentTaxNumber=""
              expanded={disabled}
              icon={humanMaleFemale}
              taxNumberLabel=""
            />
          )}
          <Box height="30px" />
          <Box display="flex" alignItems="center">
            <Box>
              A házasságkötés ideje, vagy a 25 év alatti fiatalok házasságkötése
              esetén a házastárs 25. életéve betöltésének időpontja:
            </Box>
            <Box id="marriageDate">
              <Box marginLeft="30px" width="200px">
                <OcDatePicker
                  label={t("DATE")}
                  value={marriageDate || null}
                  onChange={(date) => changeMarriageDate(date)}
                  format={getDateFormatByLocale(i18n.language)}
                  disabled={!editable}
                />
              </Box>
            </Box>
          </Box>
          <Box height="30px" />
          <Box display="flex" flexDirection="row">
            <Typography variant="body1">2.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              Tudomásul veszem, hogy az első házasok kedvezménye maximum 24
              jogosultsági hónapon keresztül vehető figyelembe.
            </Typography>
          </Box>
          <Box height="26px" />
          <Box marginLeft="32px">
            <FormControl component="fieldset" width="16px" display="flex">
              <Box height="8px" />
              <RadioGroup
                value={radioGroupState}
                aria-label="gender"
                name="controlled-radio-buttons-group"
                onChange={handleRadioGroupChange}
                disabled={!editable}
              >
                <Box display="flex" alignItems="center">
                  2.1.
                  <Box width="16px" />
                  <FormControlLabel
                    value="applyInTaxYear"
                    control={
                      <Radio
                        id="applyInTaxYear"
                        style={{ color: !editable ? "#919699" : "#212121" }}
                        disabled={!editable}
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{ alignItems: "center" }}
                      >
                        Nyilatkozatomat az adóévben, legfeljebb a jogosultsági
                        időszak végéig kérem figyelembe venni.
                      </Typography>
                    }
                  />
                </Box>
                <Box height="3px" />
                <Box display="flex" alignItems="center">
                  2.2.
                  <Box width="16px" />
                  <FormControlLabel
                    value="applyToWithdrawal"
                    control={
                      <Radio
                        id="applyToWithdrawal"
                        style={{ color: !editable ? "#919699" : "#212121" }}
                        disabled={!editable}
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{ alignItems: "center" }}
                      >
                        Nyilatkozatomat visszavonásig, legfeljebb a jogosultsági
                        időszak végéig kérem figyelembe venni (folytatólagos
                        nyilatkozatot teszek).
                      </Typography>
                    }
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box height="36px" />
          <Box display="flex" flexDirection="row">
            <Typography variant="body1">3.</Typography>
            <Box width="16px" />
            <Typography variant="body1">Nyilatkozom, hogy</Typography>
          </Box>
          <Box height="16px" />
          <Box
            display="inline-flex"
            alignItems="center"
            style={{
              marginLeft: "15px",
            }}
          >
            <Box
              width="200px"
              marginLeft="16px"
              marginRight="16px"
              display="flex"
              flexDirection="row"
              style={{
                backgroundColor: "white",
                borderRadius: "4px",
              }}
            >
              <OcMonthSelect
                id="start-of-application-select"
                label="Hó"
                selectedMonth={states?.applyFromMonth || ""}
                onSelectMonth={(e) =>
                  handleChange({ key: "applyFromMonth", value: e })
                }
                width="200px"
                disabled={!editable}
              />
            </Box>
            <Typography
              variant="body1"
              style={{
                color: editable ? "#212121" : "#919699",
                marginLeft: "6px",
              }}
            >
              hónaptól kezdődően (e hónapra is) az első házasok kedvezményét
            </Typography>
          </Box>
          <Box height="16px" />
          <Box marginLeft="16px">
            <TaxCreditApplicationCheckboxLine
              id="marriageAmount"
              text="forint összegben kívánom igénybe venni."
              textInput={true}
              textInputType="number"
              value={states?.requestAmount === 0 ? "" : states?.requestAmount}
              label={t("AMOUNT")}
              onChangeInput={(e) =>
                handleChange({
                  key: "requestAmount",
                  value: parseFloat(e.target.value.replace(/[^0-9]/g, "")),
                })
              }
              textInputDisabled={!editable}
            />
          </Box>
          <Box height="36px" />
          <TaxCreditApplicationCheckboxLine
            id="noneMarriageDiscountContract"
            type="checkbox"
            prefix="4."
            checked={states?.noApply}
            onChange={(e) =>
              handleChange({ key: "noApply", value: e.target.checked })
            }
            text="A nyilatkozattétel időpontjától kezdődően (erre a hónapra
                  sem) az első házasok kedvezményét nem kívánom igénybe venni."
            checkboxDisabled={!states?.isModifiedDeclaration || !editable}
          />
          <Box height="36px" />
          <Typography variant="body1">
            Felelősségem tudatában kijelentem, hogy a nyilatkozat tartalmát
            házastársam megismerte és elfogadta.
          </Typography>
          <Box height="26px" />
        </OcCard>
        <Box height="16px" />
        <TaxCreditApplicationButtonBars
          taxApplicationSaveEnabled={editable && taxApplicationSaveEnabled}
          cancelData={handleCancel}
          handlePrint={handlePrint}
          saveData={handleSave}
          editable={editable}
          disabledButton={disabledButton}
          handleRemoveRequest={handleRemoveRequest}
          showModifiedButton={showModifiedButton}
          showRemoveButton={showRemoveButton}
          isModified={isModified}
          isModifiedDeclaration={states?.isModifiedDeclaration}
        />
      </Box>
    </>
  );
};

export default FirstMarriageTaxCreditApplication;
