import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_MY_TIME_REQUESTS_LIST } from "my-time/graphql/queries";
import { MY_TIME_REQUESTS_SUBSCRIPTION } from "my-time/graphql/subscriptions";

const useMyTimeRequests = ({
  contractCode,
  startDate,
  endDate,
  requested,
  page,
  pageSize,
}) => {
  const { data, loading, error, refetch } = useQuery(
    GET_MY_TIME_REQUESTS_LIST,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        contractCode,
        startDate,
        endDate,
        requested,
        page,
        pageSize,
      },
    }
  );

  useSubscription(MY_TIME_REQUESTS_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  return {
    data: data?.getMyTimeRequestsList || [],
    loading,
    error,
  };
};

export default useMyTimeRequests;
