import gql from "graphql-tag";

export const PERMIT_SUBSCRIPTION = gql`
  subscription permit {
    permit {
      _id
      requestedAt
      permittedAt
      permittedBy
      cancelledAt
    }
  }
`;
