import React, { useState } from "react";
import { Box, TextField, InputAdornment, Typography } from "@material-ui/core";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import ButtonBar from "my-leave/components/my-leave-email-card/components/button-bar";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import OcFormSwitch from "oc/components/oc-form-switch/oc-form-switch";
import { InlineIcon } from "@iconify/react";
import emailIcon from "@iconify/icons-mdi/email";
import useOcSnackbar from "hooks/use-oc-snackbar";

export default function MyHrAdminSettingsChangeRequestsMenu({
  changeRequestsEnabled,
}) {
  const { t } = useTranslation();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [prevEnabled, setPrevEnabled] = useState(enabled);
  const [email, setEmail] = useState("");
  const [prevEmail, setPrevEmail] = useState(email);
  const [error, setError] = useState(false);

  const { setOpenMessage } = useOcSnackbar();

  const [updateProperty, { loading }] = useMutation(UPDATE_PROPERTY, {
    onError({ ...err }) {
      alert("error" + err.toString());
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
    onCompleted(data) {
      if (data?.updateProperty.key === "changeRequestsEnabled") {
        setEnabled(data?.updateProperty.value);
        setPrevEnabled(data?.updateProperty.value);
      } else if (data?.updateProperty.key === "changeRequestsEmail") {
        setEmail(data?.updateProperty.value);
        setPrevEmail(data?.updateProperty.value);
      }

      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
  });

  const onCompleted = (property) => {
    if (property?.key === "changeRequestsEnabled") {
      let parsedValue = property?.value === "true" ? true : false;
      setEnabled(parsedValue);
      setPrevEnabled(parsedValue);
    } else if (property?.key === "changeRequestsEmail") {
      const value = property?.value || "";
      setEmail(value);
      setPrevEmail(value);
    }
  };

  useProperty({
    resource: "system",
    key: "changeRequestsEnabled",
    onCompleted,
    fetchPolicy: "network-only",
  });

  useProperty({
    resource: "system",
    key: "changeRequestsEmail",
    onCompleted,
    fetchPolicy: "network-only",
  });

  const handleChangeEnabled = (event) => {
    const newEnabled = event.target.checked;
    setEnabled(newEnabled);
  };

  const handleChangeEmail = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const handleSaveEnabled = () => {
    updateProperty({
      variables: {
        resource: "system",
        key: "changeRequestsEnabled",
        value: enabled ? "true" : "false",
      },
    });
    setPrevEnabled(enabled);
    setShowConfirmDialog(false);
  };

  const handleSaveEmail = () => {
    updateProperty({
      variables: {
        resource: "system",
        key: "changeRequestsEmail",
        value: email,
      },
    });
    setPrevEmail(email);
    setShowConfirmDialog(false);
  };

  const handleCancel = () => {
    setEnabled(prevEnabled);
    setEmail(prevEmail);
    setError(false);
  };

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  const handleOpenDialog = () => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) {
      setError(true);
      return;
    }
    setShowConfirmDialog(true);
  };

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={() => {
          handleSaveEnabled();
          handleSaveEmail();
        }}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcCard label={t("CHANGE_REQUEST_SETTINGS")} loading={loading}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box width="200px" textAlign="right" color="#4D4D4D">
            <Typography variant="caption">
              {t("CHANGE_REQUEST_SETTINGS")}
            </Typography>
          </Box>
          <Box width="16px" />
          <OcFormSwitch
            id="prohibitChangeRequests"
            checked={enabled}
            onChange={handleChangeEnabled}
            inputProps={{ "aria-label": "controlled" }}
            disabled={!changeRequestsEnabled}
          />
          <Box width="16px" />
          <Box width="300px">
            <Box
              fontSize="12px"
              letterSpacing="0.4px"
              color="#4D4D4D"
              width="300px"
            >
              {t("CHANGE_REQUEST_SETTINGS_INFO")}
            </Box>
          </Box>
        </Box>
        <Box height="36px" />
        <Box width="448px" marginBottom="48px" paddingLeft="32px">
          <Box height="16px" />
          <Typography variant="body1" style={{ color: "#5E6366" }}>
            {t("MY_HR_CHANGE_REQUEST_EMAIL")}
          </Typography>
          <Box height="16px" />
          <TextField
            variant="outlined"
            value={email}
            onChange={handleChangeEmail}
            label="Email"
            fullWidth={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InlineIcon icon={emailIcon} />
                </InputAdornment>
              ),
            }}
            error={error}
            helperText={error && t("ERROR_MESSAGE_WRONG_EMAIL_FORMAT")}
            onFocus={() => setError(false)}
            disabled={!changeRequestsEnabled}
          />
        </Box>
        <ButtonBar
          handleCancel={handleCancel}
          handleSave={handleOpenDialog}
          disabled={loading || (enabled === prevEnabled && email === prevEmail)}
        />
      </OcCard>
    </>
  );
}
