/**
 * Filter table records with selected unique list
 * @author Pál Pintér
 * @copyright 2020 Orgware LLC
 */
import React, { useEffect } from "react";
import { useTheme } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { useTranslation } from "react-i18next";
import useStyles from "./oc-data-table-filter-form.style";

export default function FilterForm({ tableData, setTableData, anchor }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const { filterField, filterSelected, filterValues, filterShowForm } =
    tableData;

  useEffect(() => {
    const handleEscKeypress = (e) => {
      if (e.key === "Escape") {
        setTableData({ filterShowForm: false });
      }
    };

    window.addEventListener("keydown", handleEscKeypress);
    return () => {
      window.removeEventListener("keydown", handleEscKeypress);
    };
  }, [filterShowForm, setTableData]);

  const handleChange = (e) => {
    const name = e.target.name;
    if (name === "all") {
      return setTableData({
        filterSelected: [],
      });
    }
    if (filterSelected.includes(name)) {
      const selected = filterSelected.filter((item) => item !== name);
      return setTableData({
        filterSelected: selected,
      });
    }

    return setTableData({
      filterSelected: filterSelected.concat(e.target.name),
    });
  };

  return (
    <OcErrorBoundary>
      <ClickAwayListener
        onClickAway={() => setTableData({ filterShowForm: false })}
      >
        <Box
          id="table-filter-search-form"
          className={classes.root}
          style={{ right: "0px" }}
        >
          <FormControl component="fieldset" style={{ padding: "10px 17px" }}>
            <FormGroup>
              <FormControlLabel
                value="right"
                control={
                  <Checkbox
                    id={filterField}
                    style={{ color: theme.palette.gray80.main }}
                    onChange={handleChange}
                    name="all"
                    checked={
                      filterSelected.length === filterValues.length ||
                      filterSelected.length === 0
                    }
                  />
                }
                label="(Minden)"
                labelPlacement="end"
              />
              <Box className={classes.line}></Box>
              {filterValues &&
                filterValues.map((item, idx) => (
                  <FormControlLabel
                    key={`filter-value-${idx}`}
                    value="right"
                    control={
                      <Checkbox
                        id={`filter-value-${idx}`}
                        style={{ color: theme.palette.gray80.main }}
                        name={item}
                      />
                    }
                    label={
                      filterField === "state" ||
                        filterField === "type" ||
                        filterField === "typeName"
                        ? t(`Leave.${item}`)
                        : item
                    }
                    labelPlacement="end"
                    onChange={handleChange}
                    checked={filterSelected.includes(item)}
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Box>
      </ClickAwayListener>
    </OcErrorBoundary>
  );
}
