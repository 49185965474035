import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  toolbarMainContainer: ({ monthFiltering }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: monthFiltering ? "flex-end" : "space-between",
    padding: 10,
    paddingBottom: 32,
    fontSize: 20,
    letterSpacing: "0.15px",
    color: theme.palette.gray80.main,
  }),
  centerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    paddingTop: 2,
    minWidth: 164,
    textAlign: "center",
    textTransform: "capitalize",
  },
  leftChevron: {
    paddingTop: 2,
    paddingRight: 8,
    "&:hover": {
      cursor: "pointer",
    },
  },
  month: {
    textTransform: "capitalize",
    marginLeft: 6,
  },
  rightChevron: {
    paddingTop: 2,
    paddingLeft: 8,
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    color: theme.palette.gray80.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconDisabled: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
