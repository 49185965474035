import React from "react";
import { Box, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
//import RadioGroup from "@material-ui/core/RadioGroup";
//import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function TaxCreditApplicationCheckboxLine({
  id,
  prefix,
  checked,
  onChange,
  text,
  type,
  textInput,
  textInputType,
  checkboxDisabled,
  value,
  label,
  textInputDisabled,
  radioDisabled,
  onChangeInput,
  textInputBeforeText,
  //radioLabels,
}) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{
        color:
          radioDisabled || textInputDisabled || checkboxDisabled
            ? "#919699"
            : "#212121",
      }}
    >
      {prefix}
      <Box width="7px" />
      {type === "checkbox" && (
        <Checkbox
          id={id}
          checked={checked}
          onChange={onChange}
          style={{ color: checkboxDisabled ? "#919699" : "#212121" }}
          disabled={checkboxDisabled}
        />
      )}
      {type === "radio" && (
        <Radio
          disabled={radioDisabled}
          checked={checked}
          onChange={onChange}
          id={id}
          style={{ color: radioDisabled ? "#919699" : "#212121" }}
        />

        /*
        ****** UPGRADE ******

        <RadioGroup
          value={checked}
          onChange={onChange}
          id={id}
          style={{ flexDirection: "row" }}
        >
          {radioLabels.map((radioData, index) => (
            <Box display="flex" alignItems="center" key={index}>
              <Typography variant="body1">{radioData?.radioPrefix}</Typography>
              <Box width="16px" />
              <FormControlLabel
                value={radioData?.value}
                control={
                  <Radio
                    id={`${id}-${index}`}
                    disabled={radioDisabled}
                    checked={radioData?.checked}
                    onChange={(e) =>
                      radioData.onChange({
                        key: radioData?.key,
                        value: e.target.checked,
                      })
                    }
                    style={{ color: radioDisabled ? "#919699" : "#212121" }}
                  />
                }
                label={
                  <Typography variant="body1">{radioData.label}</Typography>
                }
                disabled={radioDisabled}
              />
            </Box>
          ))}
        </RadioGroup>*/
      )}
      {textInput && (
        <Box display="flex" alignItems="center">
          {textInputBeforeText?.length > 0 && (
            <>
              <Box marginLeft="7px" />
              <Typography variant="body1">{textInputBeforeText}</Typography>
              <Box marginLeft="11px" />
            </>
          )}
          <Box
            width="200px"
            marginLeft="7px"
            marginRight="16px"
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
            }}
          >
            <TextField
              id={id}
              disabled={textInputDisabled}
              value={value}
              variant="outlined"
              fullWidth
              label={label}
              type={textInputType}
              onChange={onChangeInput}
              InputProps={{
                inputProps: {
                  min: "0",
                },
              }}
            />
          </Box>
        </Box>
      )}
      <Box width="7px" />
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
}
