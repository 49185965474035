import gql from "graphql-tag";

export const OPENED_LEAVE_REQUESTS_SUBSCRIPTION = gql`
  subscription getOpenedRequests(
    $filter: Boolean!
    $startDate: Date!
    $endDate: Date!
    $roleFilters: RoleFilterInput!
  ) {
    getOpenedRequests(
      filter: $filter
      startDate: $startDate
      endDate: $endDate
      roleFilters: $roleFilters
    ) {
      contract
      employee
      prefixName
      lastName
      firstName
      middleName
      nameSuffix
      orgUnit
      jobClass
      requests {
        _id
        startDate
        endDate
        workDays
        state
        approvedAt
        approvedBy
        rejectedAt
        rejectedBy
        createdAt
        createdBy
      }
    }
  }
`;
