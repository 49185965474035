import gql from "graphql-tag";

export const OW_ADMIN_LOGIN = gql`
  query owLogin($email: String!, $password: String!) {
    owLogin(email: $email, password: $password) {
      accessToken
      sessionPeriod
      accessTokenPeriod
      currentUser {
        _id
        email
        phone
        ldapUser
        employee
        lastPasswordChange
        validFrom
        validTo
      }
      isTemporaryPassword
    }
  }
`;
