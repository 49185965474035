import { useContext } from "react";
import { useHistory } from "react-router-dom";
import useCurrentUserState from "hooks/use-current-user-state";
import {
  refreshTokenTimestampState,
  isTemporaryPasswordState,
  currentUserState,
  sessionPeriodState,
  narrowMenuState,
  refreshTokenState,
} from "states";
import { activeMenuItemState } from "states/active-menu-item-state";
import { activeMenuState } from "states/active-menu-state";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import ApolloClientContext from "context/apollo-client-context";

export default function useOcLoginState() {
  const { clearCache } = useContext(ApolloClientContext);
  const { emailAddress, setData: setCurrentUserState } = useCurrentUserState();
  const setTimestamp = useSetRecoilState(refreshTokenTimestampState);
  const setSessionPeriod = useSetRecoilState(sessionPeriodState);
  const setRefreshTokenTimestamp = useSetRecoilState(
    refreshTokenTimestampState
  );
  const setIsTemporaryPassword = useSetRecoilState(isTemporaryPasswordState);

  const history = useHistory();

  async function onLogin({
    accessToken,
    currentUser,
    sessionPeriod,
    accessTokenPeriod,
    isTemporaryPassword,
    contractCode,
  }) {
    setSessionPeriod(sessionPeriod);
    setIsTemporaryPassword(isTemporaryPassword);
    let now = new Date().toUTCString();
    setTimestamp(now);
    setRefreshTokenTimestamp(now);
    setCurrentUserState({
      employeeCode: currentUser.employee,
      contractCode: contractCode,
      emailAddress: currentUser.email,
      ldapUser: currentUser.ldapUser,
      accessTokenPeriod: accessTokenPeriod,
      accessToken: accessToken,
    });
  }

  const resetCurrentUserState = useResetRecoilState(currentUserState);
  const resetRefreshTokenTimestamp = useResetRecoilState(
    refreshTokenTimestampState
  );
  const resetNarrowMenuState = useResetRecoilState(narrowMenuState);
  const resetRefreshTokenErrorState = useResetRecoilState(refreshTokenState);
  const resetActiveMenuState = useResetRecoilState(activeMenuState);
  const resetActiveMenuItemState = useResetRecoilState(activeMenuItemState);

  async function onLogout() {
    resetRefreshTokenTimestamp();
    resetCurrentUserState();
    resetNarrowMenuState();
    resetRefreshTokenErrorState();
    resetActiveMenuState();
    resetActiveMenuItemState();
    clearCache();

    return history.push("/");
  }

  return { isLoggedIn: emailAddress ? true : false, onLogin, onLogout };
}
