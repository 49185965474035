import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_MY_LEAVE_BADGES } from "graphql/queries";
import { LEAVE_REQUESTS_SUBSCRIPTION } from "graphql/subcriptions";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useCurrentUserState from "hooks/use-current-user-state";

export default function useMyLeaveBadges(userType = "examiner") {
  let { contractCode } = useCurrentUserState();

  const { hasRole } = useUserRolesDataByContract({
    contract: contractCode,
  });

  let allowedRoles = ["MyLeave.examiner"];
  if (userType !== "examiner") {
    allowedRoles = ["MyLeave.examiner", "MyLeave.viewer"];
  }
  let notAllowedRoles = [];

  let hasRight = hasRole(allowedRoles, notAllowedRoles);

  const { data, loading, error, refetch } = useQuery(GET_MY_LEAVE_BADGES, {
    skip: !contractCode || !hasRight,
    fetchPolicy: "cache-and-network",
    variables: { contract: contractCode },
  });

  if (error) {
    console.log({
      component: "useMyLeaveBadges",
      errorDescription:
        "Error occured when fetch data with GET_MY_LEAVE_BADGES query",
      error: error,
    });
  }

  const { error: subscriptionError } = useSubscription(
    LEAVE_REQUESTS_SUBSCRIPTION,
    {
      skip: !contractCode || !hasRight,
      onData: () => {
        refetch();
      },
    }
  );

  if (subscriptionError) {
    console.log({
      component: "useMyLeaveBadges",
      errorDescription:
        "Error occured when subscribe data with LEAVE_REQUESTS_SUBSCRIPTION query",
      error: error,
    });
  }

  return {
    data: data?.myLeaveBadges,
    loading: loading || false,
    error: error || undefined,
  };
}
