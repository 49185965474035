// export const CREATE_ROLE_BY_CONTRACT = gql`
import gql from "graphql-tag";

export const CREATE_USER_ROLE_BY_CONTRACT = gql`
  mutation createUserRoleByContract(
    $contract: String!
    $product: String!
    $role: String!
    $roleFilters: RoleFilterInput
  ) {
    createUserRoleByContract(
      contract: $contract
      product: $product
      role: $role
      roleFilters: $roleFilters
    ) {
      createdAt
    }
  }
`;
