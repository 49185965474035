import { useQuery } from "@apollo/client";
import { GET_STREET_TYPES } from "graphql/queries";

const useStreetTypes = () => {
  const { data, loading, error } = useQuery(GET_STREET_TYPES, {
    fetchPolicy: "cache-and-network",
  });

  const result = data?.streetTypes || [];

  return {
    data: result,
    loading,
    error,
  };
};

export default useStreetTypes;
