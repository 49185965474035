import gql from "graphql-tag";

export const CREATE_LEAVE_MESSAGE = gql`
  mutation createLeaveMessage(
    $request: String!
    $message: String!
    $locale: String!
  ) {
    createLeaveMessage(request: $request, message: $message, locale: $locale) {
      createdAt
      createdBy
      message
      employeeName
      employeeAvatar
    }
  }
`;
