import { selectedTaxDeclarationState } from "states/selected-tax-declaration-state";
import { useRecoilState, useResetRecoilState } from "recoil";

const useSelectedTaxDeclaration = () => {
  const [selected, setSelected] = useRecoilState(selectedTaxDeclarationState);
  const reset = useResetRecoilState(selectedTaxDeclarationState);
  return {
    selected,
    setSelected,
    reset,
  };
};

export default useSelectedTaxDeclaration;
