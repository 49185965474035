export default function getDateFormatByLocale(lang = "hu") {
  let res = "";

  switch (lang.toLowerCase()) {
    case "hu":
      res = "yyyy.MM.dd";
      break;
    case "en":
      res = "MM/dd/yyyy";
      break;
    default:
      res = "yyyy-MM-dd";
  }

  return res;
}
