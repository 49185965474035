import React from "react";
import { useSnackbar, closeSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close as IconClose } from "@material-ui/icons";

const useOcSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const SnackBarCloseButton = (key) => {
    const size = "16px";
    return (
      <IconButton onClick={() => closeSnackbar(key)}>
        <IconClose style={{ color: "#fff", width: size, height: size }} />
      </IconButton>
    );
  };

  const setOpenMessage = ({ type, message }) => {
    let backgroundColor;
    switch (type) {
      case "error":
        backgroundColor = "#CC0033";
        break;
      case "success":
        backgroundColor = "#2FA84F";
        break;
      case "warning":
        backgroundColor = "#F3AA18";
        break;
      default:
        backgroundColor = "#2FA84F";
    }

    const snackBarProps = {
      variant: type,
      autoHideDuration: type === "error" ? 16000 : 6000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      style: {
        backgroundColor: backgroundColor,
        fontSize: "16px",
        letterSpacing: "0.25px",
        minWidth: "auto",
      },

      action: (key) => SnackBarCloseButton(key),
    };

    enqueueSnackbar(message, snackBarProps);
  };
  return { setOpenMessage };
};

export default useOcSnackbar;
