import gql from "graphql-tag";

export const GET_LAST_PAYCARD_DATA_BY_CONTRACT = gql`
  query getLastPaycardDataByContract($contractCode: String!) {
    getLastPaycardDataByContract(contractCode: $contractCode) {
      year
      month
      netWage
      otherBenefits
      allTaxDeductions
      otherDeductions
      grossWage
    }
  }
`;
