import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: ({ width }) => ({
    display: "flex",
    flexDirection: "row",
    width: width,
    marginTop: 5,
    marginBottom: 3,
  }),
  innerBlock: ({ width, height }) => ({
    display: "flex",
    width: width - 1,
    height: height - 1,
    backgroundColor: theme.palette.gray30.main,
    border: `1px solid ${theme.palette.gray30.main}`,
    borderRadius: 2,
  }),
}));

export default useStyles;
