export const timeConvert = (n) => {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return (
    rhours.toString().padStart(2, "0") +
    ":" +
    rminutes.toString().padStart(2, "0")
  );
};

export default timeConvert;
