import getCorrectBase64Image from "oc/utils/get-correct-base64-image";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { ASSETS_SUBSCRIPTION } from "graphql/subcriptions";
import gql from "graphql-tag";

export const GET_AVATAR = gql`
  query readFileAsset($employeeCode: String!, $type: String!) {
    readFileAsset(employeeCode: $employeeCode, type: $type) {
      _id
      filename
      createdAt
    }
  }
`;

export default function useOcAvatar({ employee }) {
  const { data, loading, error, refetch } = useQuery(GET_AVATAR, {
    fetchPolicy: "cache-and-network",
    skip: !employee,
    variables: {
      employeeCode: employee,
      type: "avatar",
    },
  });

  useSubscription(ASSETS_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    skip: !employee,
    onData: () => {
      refetch();
    },
  });

  let src = data?.readFileAsset?.filename
    ? getCorrectBase64Image(data?.readFileAsset?.filename)
    : "/img/defaultAvatar.png";

  return {
    data: src,
    loading,
    error,
    refetch,
  };
}
