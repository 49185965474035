import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/hu.json"));

export default function getCountryName(locale) {
  const list = Object.keys(countries.getNames(locale));
  const codedList = list.map((item) => {
    const code = countries.alpha2ToAlpha3(item);
    const name = countries.getName(code, locale);
    return { code, name };
  });

  return codedList;
}
