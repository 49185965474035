import gql from "graphql-tag";

export const DELETE_CAFETERIA_STATEMENT_ITEM = gql`
  mutation deleteCafeteriaStatementItem(
    $contractCode: String
    $currentItemStartDate: String
    $itemSerial: String
    $menuItemCode: String
    $startDate: String
    $endDate: String
    $type: String
  ) {
    deleteCafeteriaStatementItem(
      contractCode: $contractCode
      currentItemStartDate: $currentItemStartDate
      itemSerial: $itemSerial
      menuItemCode: $menuItemCode
      startDate: $startDate
      endDate: $endDate
      type: $type
    ) {
      result
      messages
    }
  }
`;
