import { currentUserState } from "states/current-user-state";
import { useRecoilValue, useSetRecoilState } from "recoil";

const useCurrentUserState = () => {
  const setCurrentUserState = useSetRecoilState(currentUserState);
  const data = useRecoilValue(currentUserState);
  const setData = (value) =>
    setCurrentUserState((prevState) => Object.assign({}, prevState, value));
  return { ...data, setData };
};

export default useCurrentUserState;
