import Box from "@material-ui/core/Box";
import React, { Fragment } from "react";
import LoginForm from "my-hr/components/my-hr-login-form";
import SSOLoginForm from "my-hr/components/my-hr-login-form-sso";
import PublicLayoutData from "my-hr/components/my-hr-public-layout-data";
import PublicLayoutLink from "my-hr/components/my-hr-public-layout-link";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import { useHistory, useLocation } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MyHrHelpdeskContact from "my-hr/components/my-hr-helpdesk-contact";

export default function MyHrUserLoginPage({ onLogin }) {
  const smallScreen = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { data } = useProperty({
    resource: "system",
    key: "loginType",
  });

  const isSSO = data && data === "sso" ? true : false;

  function handleLogin(props) {
    onLogin(props);
    let { from } = location.state || { from: { pathname: "/" } };
    history.replace(from);
  }
  return (
    <Box
      id="page-container"
      width="100%"
      display="flex"
      flexDirection={smallScreen ? "column" : "row"}
      justifyContent="space-between"
      height="100%"
    >
      {!smallScreen && <PublicLayoutData smallScreen={smallScreen} />}
      <Box
        id="form-container"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        style={{ backgroundColor: "white" }}
        textAlign="center"
        width={smallScreen ? "100%" : "400px"}
        height="100%"
      >
        <Box
          id="form-inner-container"
          width="100%"
          textAlign="center"
          padding="60px 0px"
        >
          <Box id="orgware-logo">
            <img
              alt="orgware-icon"
              src="/img/owIconLogoColored.png"
              style={{ marginBottom: 30 }}
            />
          </Box>
          <Box id="orgware-text-logo">
            <img
              alt="orgware-icon"
              src="/img/owLogoColored.png"
              style={{ width: 300 }}
            />
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center">
            {isSSO ? (
              <SSOLoginForm onLogin={handleLogin} smallScreen={smallScreen} />
            ) : (
              <Fragment>
                <Box display="flex" flexDirection="column">
                  <Box id="login-form" width="304px" padding="16px 48px">
                    <LoginForm
                      onSubmit={handleLogin}
                      smallScreen={smallScreen}
                      buttonLabel={t("LOGIN")}
                    />
                  </Box>
                  <Box paddingTop="18px">
                    <PublicLayoutLink
                      label={t("LINK_FORGOT_PASSWORD")}
                      path="/forgot-password"
                    />
                  </Box>
                  <Box height="36px" />
                  <MyHrHelpdeskContact />
                </Box>
              </Fragment>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
