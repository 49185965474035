import React from "react";
import EventRow from "react-big-calendar/lib/EventRow";

export default function TimelineWeekWrapper(props) {
  // TODO: implement grouping
  // We need to find out, which is the best grouping method
  // Unitl that the grouping feature is didabled
  //const groupBy = (levels) => {
  //  let result = levels.reduce(
  //    (result, item) => ({
  //      ...result,
  //      [item.event.title]: [...(result[item.event.title] || []), item],
  //    }),
  //    {}
  //  );
  //  return Object.values(result);
  //};

  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const titleA = a[0].event.start.toUpperCase();
    const titleB = b[0].event.start.toUpperCase();

    let comparison = 0;
    if (titleA > titleB) {
      comparison = 1;
    } else if (titleA < titleB) {
      comparison = -1;
    }
    return comparison;
  }

  let levels = [];
  let origLevels = props.slotMetrics.levels;

  origLevels.map((item) => {
    levels = levels.concat(item);
    return item;
  });

  let arrayLevels = levels.map((item) => {
    let newItem = [];
    newItem.push(item);
    return newItem;
  });

  arrayLevels.sort(compare);

  return (
    <React.Fragment>
      {arrayLevels.map((item, idx) => {
        return <EventRow key={idx} segments={item} {...props} />;
      })}
    </React.Fragment>
  );
}
