import React, { useRef } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import MyCafeteriaVbkApplicationPrint from "my-cafeteria/components/my-cafeteria-vbk-application-print";
import OcPrintHeader from "oc/components/oc-print-header/oc-print-header";
import { useHistory } from "react-router-dom";
import OcPageTransition from "oc/components/oc-page-transition";
import { useReactToPrint } from "react-to-print";
import Card from "@material-ui/core/Card";

const MyCafeteriaVbkApplicationPage = ({ example }) => {
    const history = useHistory();
    const componentRef = useRef();

    function historyBack() {
        history.goBack();
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <OcPageTransition>
            <Box padding="16px">
                <OcPrintHeader
                    title="VBK Nyilatkozat"
                    onBack={historyBack}
                    onPrint={handlePrint}
                />
                <Box height="16px" />
                <Card
                    id="my-cafeteria-summary-card"
                    variant="outlined"
                    style={{ borderRadius: "4px", border: "1px solid #E3E5E6" }}
                >
                    <MyCafeteriaVbkApplicationPrint ref={componentRef} />
                </Card>
            </Box>
        </OcPageTransition>
    );
};

MyCafeteriaVbkApplicationPage.propTypes = {
    /** Example prop type description*/
    example: PropTypes.string,
};

MyCafeteriaVbkApplicationPage.defaultProps = {
    example: "example",
};

export default MyCafeteriaVbkApplicationPage;
