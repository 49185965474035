import React from "react";
import PaycardItem from "my-hr/components/my-hr-paycard-item";

export default function PaycardWrapper({ contract, source, collapse, slot }) {
    return source?.map((paycard, index) => {
        return (
            <PaycardItem
                key={`${source}-${index}`}
                paycard={paycard}
                index={index}
                contract={contract}
                collapse={collapse}
                slot={slot}
            />
        );
    });
}
