import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  counterContainer: {
    width: "182px",
    backgroundColor: theme.palette.gray30.main,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 6,
  },
  myTimeCounterContainer: {
    width: "204px",
    backgroundColor: theme.palette.gray30.main,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 6,
  },
  counter: {
    fontSize: "96px",
    lineHeight: "120px",
    letterSpacing: -1.5,
    color: theme.palette.primary.main,
  },
  counterLabel: {
    letterSpacing: "0.44px",
    color: theme.palette.gray80.main,
    fontSize: "14px",
    lineHeight: "20px",
  },
  workTimeCounter: {
    fontSize: "64px",
    lineHeight: "120px",
    letterSpacing: -1.5,
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
