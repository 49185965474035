import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InlineIcon } from "@iconify/react";
import AccountIcon from "@iconify/icons-mdi/account";
import { useTranslation } from "react-i18next";
import useStyles from "./leave-filter-collapse-header.style";

export default function CollapseHeader({ handleExpandClick, expanded }) {
    const { t } = useTranslation();
    const classes = useStyles(expanded);
    return (
        <OcErrorBoundary>
            <Box
                id="collapse-header"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <Box
                        padding="10px 28px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <InlineIcon
                            icon={AccountIcon}
                            height="24px"
                            className={classes.accountIcon}
                        />
                    </Box>
                    <Box fontSize="24px">{t("FILTERING")}</Box>
                </Box>
                <Box paddingRight="28px">
                    <IconButton
                        id="leave-filter-collapse-button"
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        style={
                            expanded
                                ? {
                                      padding: 0,
                                      transform: "rotate(180deg)",
                                  }
                                : {
                                      padding: 0,
                                  }
                        }
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Box>
            </Box>
        </OcErrorBoundary>
    );
}
