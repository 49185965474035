import React from "react";
import { useTranslation } from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItem";
import chevronDoubleLeft from "@iconify/icons-mdi/chevron-double-left";
import Chevron from "oc/components/oc-private-layout-sidebar-chevron";
import useStyles from "./oc-private-layout-sidebar-back-menu.style";

export default function OcPrivateLayoutSidebarBackMenu({
  id,
  label,
  translation,
  onMenuChange,
  targetMenu,
  narrowMenu,
}) {
  const { t } = useTranslation();
  let menuLabel = t(translation) || label;
  const classes = useStyles();
  return (
    <ListItem
      id={id}
      button
      className={classes.linkItem}
      onClick={() => onMenuChange(targetMenu)}
    >
      <Chevron icon={chevronDoubleLeft} />
      <ListItemText
        style={{
          textTransform: "uppercase",
          paddingLeft: narrowMenu ? "16px" : "8px",
          paddingTop: "10px",
          fontSize: "14px",
          letterSpacing: "0.25px",
        }}
      >
        {menuLabel}
      </ListItemText>
    </ListItem>
  );
}
