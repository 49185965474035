import React from "react";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import BodyCell from "oc/components/oc-data-table-body-cell";
import HeaderCell from "oc/components/oc-data-table-header-cell";
import PaginationActions from "oc/components/oc-data-table-pagination-actions";
import LoadingRows from "oc/components/oc-data-table-loading-rows";
import useStyles from "./oc-data-table.style";
import Collapse from "@material-ui/core/Collapse";
import TableCell from "@material-ui/core/TableCell";
import useLocalState from "hooks/use-local-state";
import useCurrentUserState from "hooks/use-current-user-state";
import { useTheme } from "@material-ui/core/styles";
import MyleaveExaminerDashboardCollapse from "my-leave/components/my-leave-examiner-dashboard-collapse/my-leave-examiner-dashboard-collapse";
import moment from "moment";

export default function OcDataTable({
  loading,
  pagination,
  data,
  tableDef,
  tableData,
  setTableData,
  onSelectRow,
  localPaging,
  inlineEditForm = <></>,
  onShowInlineEditForm,
  inlineEdit,
  canCopy = () => { },
  tableId,
  selectedRowId,
  onRowSelection,
  examinerDasboard,
  role,
  isNotExaminer
}) {
  const theme = useTheme();
  const classes = useStyles({ select: tableDef?.select });
  let loadingTypes = tableDef?.columns.map((item) => {
    return item.type;
  });

  let loadingRows = LoadingRows({
    rows: 2,
    columns: loadingTypes,
  });

  const { employeeCode } = useCurrentUserState();
  const tableRowsKey = `${employeeCode}:${tableDef?.id}`;
  const [rowsPerPage, setRowsPerPage] = useLocalState(
    tableRowsKey,
    tableData?.pageSize
  );

  function handleChangeRowsPerPage(event) {
    let pageSize = +event.target.value;
    if (pageSize !== tableData?.pageSize) {
      let newState = { ...tableData, page: 1, pageSize };
      setTableData(newState);
      setRowsPerPage(pageSize);
    }
  }

  function handlePageChange(page) {
    if (page > 0 && page !== tableData?.page) {
      setTableData({ page: page });
    }
  }

  if (rowsPerPage !== tableData?.pageSize) {
    let newState = { ...tableData, page: 1, pageSize: rowsPerPage };
    setTableData(newState);
  }

  let list = data?.rows;

  if (localPaging) {
    let limit = tableData?.pageSize;
    let offset = (tableData?.page - 1) * tableData?.pageSize;
    list = data?.rows.slice(offset, offset + limit);
  }

  function handleRowSelection(row) {
    onRowSelection(row);
  }

  const dailyTable = tableId === "allLeaveRequestByDayTable";

  return (
    <Box
      style={{
        overflowX: "visible",
      }}
    >
      <TableContainer
        component={Paper}
        elevation={0}
        style={{
          width: "100%",
          maxHeight: dailyTable && 1000,
          minWidth: tableDef.minWidth,
          overflowX: data?.rows?.length < 3 && "visible",
        }}
      >
        <MaterialTable
          //stickyHeader={true}
          id={tableDef?.id}
          aria-label="users-table"
          style={{
            tableLayout: "fixed",
            overflowX: "visible",
          }}
        >
          <TableHead style={{ overflowX: "visible" }}>
            <TableRow style={{ overflowX: "visible" }}>
              {tableDef.columns.map((item, index) => {
                let length = tableDef?.columns?.length;
                item.anchor = index > length / 2 ? "right" : "left";
                return (
                  <HeaderCell
                    key={`${tableDef.id}-${item.id}`}
                    tableData={tableData}
                    setTableData={setTableData}
                    column={item}
                    dailyTable={dailyTable}
                    style={{ overflowX: "visible" }}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              list?.map((row, index) => {
                let rowSpanData = [];
                if (tableDef?.rowSpan && tableDef?.rowSpanField) {
                  const startDate = moment(row?.date).format("YYYY-MM-DD");
                  const filteredData = list?.filter(
                    (item) =>
                      moment(item.date).format("YYYY-MM-DD") === startDate
                  );
                  rowSpanData = filteredData;
                }
                let showForm = onShowInlineEditForm
                  ? onShowInlineEditForm(row)
                  : false;
                return (
                  <React.Fragment key={`${index}-${row.email}`}>
                    <TableRow
                      id={new Date(row?.date).toLocaleDateString()}
                      onClick={() => {
                        if (tableDef?.select === "row") onSelectRow(row);
                      }}
                      className={classes.root}
                    >
                      {tableDef.columns.map((item, index) => {
                        return (
                          <BodyCell
                            key={`${tableDef.id}-body-cell-${item.id}`}
                            row={row}
                            id={item.id}
                            align={item.align}
                            type={item.type}
                            onSelectRow={onSelectRow}
                            selectedRowValue={tableData?.selectedRowValue}
                            inlineEdit={inlineEdit}
                            canCopy={canCopy(row)}
                            rowSpanData={rowSpanData}
                            rowSpanField={tableDef?.rowSpanField}
                            dayFormat={tableDef?.dayFormat}
                            colorize={item.colorize}
                            columnItem={item}
                            isNotExaminer={isNotExaminer}
                          />
                        );
                      })}
                    </TableRow>
                    {onShowInlineEditForm && (
                      <TableRow>
                        <TableCell colSpan={8} style={{ padding: "0px" }}>
                          <Collapse in={showForm} timeout="auto">
                            <Box>
                              {React.cloneElement(
                                inlineEditForm,
                                { row },
                                null
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                    {examinerDasboard && (
                      <TableRow
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        onClick={() => {
                          if (tableDef?.select === "row")
                            handleRowSelection(row);
                        }}
                      >
                        <TableCell
                          colSpan={5}
                          style={{
                            padding: "0px",
                          }}
                        >
                          <Collapse
                            in={selectedRowId === row?.contract}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              style={{
                                width: "auto",
                                backgroundColor: theme.palette.gray20.main,
                              }}
                            >
                              <MyleaveExaminerDashboardCollapse
                                row={row}
                                role={role}
                              />
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            {loading && loadingRows}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      {pagination && data?.total > 0 && (
        <Box id={`${tableDef.id}-pagination`}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={data?.total || 0}
            rowsPerPage={rowsPerPage}
            page={tableData?.page - 1}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </Box>
      )}
    </Box>
  );
}
