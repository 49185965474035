import React from "react";
import { Box, Card } from "@material-ui/core";
import CalendarDay from "my-leave/components/my-leave-calendar-day";
import useStyles from "./my-time-show-request-card-examiner.style";
import { useTranslation } from "react-i18next";
import useEmployeeDI from "hooks/use-myhr-employee";
import getFullname from "oc/utils/get-fullname";
import State from "my-leave/components/my-leave-state";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Button from "@material-ui/core/Button";
import MyTimeRequestTypeInterface from "my-time/components/my-time-request-type-interface";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import Avatar from "oc/components/oc-avatar";
import MyTimeRequestCardMessages from "my-time/components/my-time-request-card-messages/my-time-request-card-messages";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { myTimeMessageState } from "my-time/states/my-time-message";
import { APPROVE_MY_TIME_REQUEST } from "my-time/graphql/mutations/approve-my-time-request";
import { REJECT_MY_TIME_REQUEST } from "my-time/graphql/mutations/reject-my-time-request";
import { DELETE_APPROVED_MY_TIME_REQUEST } from "my-time/graphql/mutations/delete-approved-my-time-request";
import useOcSnackbar from "hooks/use-oc-snackbar";

const MyTimeShowRequestCardExaminer = ({
  data,
  messages,
  loading,
  error,
  employeeCode,
  contractCode,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles(data);
  const history = useHistory();

  const { setOpenMessage } = useOcSnackbar();
  const message = useRecoilValue(myTimeMessageState);
  const clearMessageState = useSetRecoilState(myTimeMessageState);

  const { data: employeeData } = useEmployeeDI({ employeeId: employeeCode });

  let locale = i18n.language.substr(0, 2).toLowerCase();

  function formatDateTime(dateTime, locale) {
    return moment(dateTime).locale(locale).format("YYYY. MM. DD. HH:mm");
  }

  let createdAt = formatDateTime(data?.createdAt, locale);
  let startTime = formatDateTime(data?.startTime, locale);
  let endTime = formatDateTime(data?.endTime, locale);

  let initialMessage =
    messages?.length > 0 && messages[0].createdBy === employeeCode
      ? messages[0]?.message
      : "";

  if (initialMessage) {
    const newMessages = messages.slice(1);
    messages = newMessages;
  }

  let disabled = false;

  if (data?.state !== "requested") {
    disabled = true;
  }

  const handleCancel = () => {
    history.goBack();
  };

  let QUERY = REJECT_MY_TIME_REQUEST;
  if (data?.state === "approved") {
    QUERY = DELETE_APPROVED_MY_TIME_REQUEST;
  }

  const [approveRequest] = useMutation(APPROVE_MY_TIME_REQUEST, {
    onCompleted: (data) => {
      const res = data?.approveMyTimeRequest;
      if (res.result) {
        setOpenMessage({
          type: "success",
          message: t("MY_TIME_APPROVE_SUCCEEDED"),
        });
        clearMessageState("");
        history.goBack();
      } else {
        setOpenMessage({
          type: "error",
          message: res.message || t("MY_TIME_APPROVE_FAILED"),
        });
        clearMessageState("");
      }
    },
    onError: () => {
      setOpenMessage({
        type: "error",
        message: t("MY_TIME_APPROVE_FAILED"),
      });
    },
  });

  const [rejectRequest] = useMutation(QUERY, {
    onCompleted: (data) => {
      let res = data?.rejectMyTimeRequest;
      if (data?.deleteApprovedMyTimeRequest) {
        res = data?.deleteApprovedMyTimeRequest;
      }
      if (res.result) {
        setOpenMessage({
          type: "success",
          message: t("MY_TIME_REJECT_SUCCEEDED"),
        });
        clearMessageState("");
        history.goBack();
      } else {
        setOpenMessage({
          type: "error",
          message: res.message || t("MY_TIME_REJECT_FAILED"),
        });
        clearMessageState("");
      }
    },
    onError: () => {
      setOpenMessage({
        type: "error",
        message: t("MY_TIME_REJECT_FAILED"),
      });
    },
  });

  const handleDelete = () => {
    let params = { _id: data?._id, message };
    if (data?.state === "approved") {
      params = {
        contractCode: contractCode,
        myTimeType: data?.myTimeType,
        startTime: data?.startTime,
        endTime: data?.endTime,
        message: message,
      };
    }
    rejectRequest({ variables: params });
  };

  const handleApprove = () => {
    const params = {
      _id: data?._id,
      message: message,
    };
    approveRequest({ variables: params });
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: "4px",
        borderColor: error ? "#cc0033" : "white",
        overflow: "visible",
      }}
      elevation={0}
    >
      <Box className={classes.box}>
        <Box className={classes.title}>{t("REQUEST")}</Box>
        <Box height="24px" />
        <Box className={classes.divider} />
        <Box height="24px" />
        <Box display="flex">
          <Box className={classes.organizer}>
            <Avatar employee={employeeCode} className={classes.avatar} />
            <Box width="16px" />
          </Box>
          <Box width="100%">
            <Box className={classes.organizer}>
              <Typography className={classes.fullnameText}>
                {getFullname(employeeData)}
              </Typography>
              <Box className={classes.createdAtOrganizer}>
                <Box fontSize="9px" style={{ color: "#919699" }}>
                  {createdAt}
                </Box>
                <State
                  label={t(`Leave.${data?.state}`)}
                  state={data?.state}
                  loading={loading}
                />
              </Box>
            </Box>
            <Box height="22px" />
            <Box display="flex">
              <CalendarDay date={data?.hours} loading={loading} hours="true" />
              <Box width="16px" />
              <Box className={classes.periodContainer}>
                <Typography>{t("PERIOD")}</Typography>
                <Box>
                  {startTime} - {endTime}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {initialMessage?.length > 0 && !loading && (
          <Box paddingLeft="35px">
            <Box className={classes.triangle} />
          </Box>
        )}
        {initialMessage?.length > 0 && (
          <Box
            marginTop="16px"
            className={
              loading ? classes.initialMessageLoading : classes.initialMessage
            }
          >
            {initialMessage}
          </Box>
        )}
        <Box height="24px" />
        <MyTimeRequestTypeInterface data={data} />
        <Box height="24px" />
        <Box className={classes.messageContainer}>
          {!loading && (
            <MyTimeRequestCardMessages
              data={data}
              messages={messages}
              loading={loading}
            />
          )}
        </Box>
        <Box height="24px" />
        <Box className={classes.divider} />
        <Box height="24px" />
        <Box
          className={
            data?.state === "rejected"
              ? classes.buttonSeparatorWidthRejected
              : classes.buttonSeparator
          }
        >
          {data?.state !== "rejected" && (
            <Button
              id="request-page-delete-button"
              color="secondary"
              disableElevation
              disabled={loading}
              onClick={handleDelete}
            >
              {t("REJECT")}
            </Button>
          )}
          <Box display="flex" flexDirection="row">
            <Button
              id="cancel-button"
              onClick={handleCancel}
              style={{ marginRight: "16px" }}
              disabled={loading}
            >
              {t("CANCEL")}
            </Button>
            <Button
              id="approve-button"
              variant="contained"
              color="primary"
              onClick={handleApprove}
              disabled={loading || disabled}
            >
              {t("APPROVE")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default MyTimeShowRequestCardExaminer;
