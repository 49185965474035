import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import usePolling from "hooks/use-polling";

export const GET_CAFETERIA_PERIODS = gql`
  query getCafeteriaPeriods {
    getCafeteriaPeriods {
      contractCode
      periodStartDate
      periodEndDate
    }
  }
`;

const useCafeteriaPeriods = () => {
  const { data, loading, error } = useQuery(GET_CAFETERIA_PERIODS, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
  });

  const result = data?.getCafeteriaPeriods;

  return {
    data: result,
    loading,
    error,
  };
};

export default useCafeteriaPeriods;
