import gql from "graphql-tag";

export const GET_TAX_DECLARATION_FOUR_CHILDREN = gql`
  query taxDeclarationFourChildren($_id: Int!) {
    taxDeclarationFourChildren(_id: $_id) {
      dateOfDeclaration
      isModifiedDeclaration
      state
      isEligible
      permanentlyEntitled
      notApplyFromMonth
      dependents {
        dependentSerialNumber
        name
        taxNumber
        placeOfBirth
        dateOfBirth
        mothersName
      }
    }
  }
`;
