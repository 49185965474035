import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

export default function PageMenu({ step, onChangeStep }) {
  const { t } = useTranslation();

  function getColor(step, activeStep) {
    if (step === activeStep) {
      return "white";
    }
    if (step > activeStep) {
      return "#212121";
    }
    return "#919699";
  }

  return (
    <Box width="240px" minWidth="240px" paddingTop="16px">
      <List style={{ padding: "0px" }}>
        <ListItem
          style={{
            padding: "0px 14px 0px 14px",
            height: "40px",
            backgroundColor: step === 1 ? "#cc0033" : "transparent",
            clipPath:
              step === 1 ? "polygon(0 0, 94% 0, 100% 100%, 0% 100%)" : "",
            borderLeft: step > 1 ? "4px solid #cc0033" : "",
            cursor: step > 1 ? "pointer" : "auto",
          }}
          onClick={() => {
            if (step > 1) {
              onChangeStep(1);
            }
          }}
        >
          <ListItemText
            style={{
              color: getColor(step, 1),
              fontSize: "14px",
              letterSpacing: "0.25px",
              cursor: "pointer",
            }}
          >
            <Tooltip title={t("SELECT_EMPLOYEE")} placement="right">
              <Typography noWrap={true}>1. {t("SELECT_EMPLOYEE")}</Typography>
            </Tooltip>
          </ListItemText>
        </ListItem>
        <ListItem
          style={{
            padding: "0px 14px 0px 14px",
            height: "40px",
            backgroundColor: step === 2 ? "#cc0033" : "transparent",
            clipPath:
              step === 2 ? "polygon(0 0, 94% 0, 100% 100%, 0% 100%)" : "",
            borderLeft: step > 1 ? "4px solid #cc0033" : "",
            cursor: step > 1 ? "pointer" : "auto",
          }}
          onClick={() => {
            if (step > 2) {
              onChangeStep(2);
            }
          }}
        >
          <ListItemText
            style={{
              color: getColor(step, 2),
              fontSize: "14px",
              letterSpacing: "0.25px",
            }}
          >
            <Typography>2. {t("PERMISSION")}</Typography>
          </ListItemText>
        </ListItem>
        <ListItem
          style={{
            padding: "0px 14px 0px 14px",
            height: "40px",
            backgroundColor: step === 3 ? "#cc0033" : "transparent",
            clipPath:
              step === 3 ? "polygon(0 0, 94% 0, 100% 100%, 0% 100%)" : "",
          }}
        >
          <ListItemText
            style={{
              color: step === 3 ? "white" : "#919699",
              fontSize: "14px",
              letterSpacing: "0.25px",
            }}
          >
            3. {t("ROLE")}
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
}
