import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    bgBox: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "16px",
        letterSpacing: "0.44px",
        color: "#000000",
    },
    smallBgBox: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "16px",
        letterSpacing: "0.44px",
        color: "#000000",
        width: "300px",
    },
    smallBgBoxWithoutBg: {
        backgroundColor: "none",
        width: "300px",
        padding: "16px",
    },
    titles: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
    },
    listItems: {
        listStyleType: "disc",
        marginLeft: "33px",
        textIndent: "-1.4em",
    },
    insideListItems: {
        listStyleType: "disc",
        marginLeft: "66px",
        textIndent: "-1.4em",
    },
    inlineFlexBoxs: {
        display: "inline-flex",
    },
    [theme.breakpoints.down("sm")]: {
        smallBgBox: {
            marginTop: "16px",
            marginLeft: "-16px",
        },
        smallBgBoxWithoutBg: {
            display: "none",
        },
        inlineFlexBoxs: {
            display: "block",
        },
        withoutSeparator: {
            display: "none",
        },
    },
    table: {
        border: "1px solid black",
        borderCollapse: "collapse",
    },
    tr: {
        border: "1px solid black",
        width: "180px",
        textAlign: "center",
    },
}));

export default useStyles;
