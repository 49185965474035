import { useQuery, useSubscription } from "@apollo/client";
import { GET_PERMIT } from "graphql/queries";
import { PERMIT_SUBSCRIPTION } from "graphql/subcriptions";
import usePolling from "hooks/use-polling";

const usePermit = () => {
  const { data, loading, error, refetch } = useQuery(GET_PERMIT, {
    fetchPolicy: "network-only",
    pollInterval: usePolling(1000 * 10),
  });

  const { data: subsData } = useSubscription(PERMIT_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  let result = subsData?.permit ? subsData?.permit : data?.permit;

  return {
    data: result,
    loading,
    error,
    refetch,
  };
};

export default usePermit;
