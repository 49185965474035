import gql from "graphql-tag";

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      status
      statusMessage
    }
  }
`;
