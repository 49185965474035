import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import ChevronLeft from "@iconify/icons-mdi/chevron-left";
import ChevronRight from "@iconify/icons-mdi/chevron-right";
import CalendarToday from "@iconify/icons-mdi/calendar-today";
import ViewModuleOutline from "@iconify/icons-mdi/view-module-outline";
import ViewDayOutline from "@iconify/icons-mdi/view-day-outline";
import ViewStreamOutline from "@iconify/icons-mdi/view-stream-outline";
import ViewSequentialOutline from "@iconify/icons-mdi/view-sequential-outline";
import { InlineIcon } from "@iconify/react";
import useStyles from "./toolbar.style";
import { useTranslation } from "react-i18next";

const CustomToolbar = (props) => {
  const { i18n } = useTranslation();
  let {
    view,
    onView,
    onNavigate,
    date,
    monthFiltering,
    setToolbarButton,
    setShowCalendar,
  } = props;
  let title = moment(date).locale(i18n.language).format("YYYY. MMMM");
  const iconSize = 30;
  const Divider = () => {
    return <div style={{ display: "inline-block", width: 16 }}></div>;
  };

  if (view === "weeklyTimeline") {
    let start = moment(date)
      .locale(i18n.language)
      .startOf("week")
      .format("YYYY.MM.D");
    let end = moment(date)
      .locale(i18n.language)
      .endOf("week")
      .format("YYYY.MM.D");
    if (i18n.language !== "hu") {
      start = moment(date)
        .locale(i18n.language)
        .startOf("week")
        .format("DD/MM/YYYY");
      end = moment(date)
        .locale(i18n.language)
        .endOf("week")
        .format("DD/MM/YYYY");
    }
    title = `${start} - ${end}.`;
  }
  /*if (view === "agenda") {
    let start = moment(date).locale(i18n.language).format("YYYY.MM.D.");
    let end = moment(date).add(30, "days").format("YYYY.MM.D.");
    if (i18n.language !== "hu") {
      start = moment(date)
        .locale(i18n.language)
        .startOf("week")
        .format("DD/MM/YYYY");
      end = moment(date)
        .locale(i18n.language)
        .endOf("week")
        .format("DD/MM/YYYY");
    }

    title = `${start} - ${end}`;
  }*/

  const classes = useStyles({ monthFiltering });

  return (
    <div className={classes.toolbarMainContainer}>
      {!monthFiltering && (
        <div className={classes.centerContainer}>
          <InlineIcon
            icon={ChevronLeft}
            width={iconSize}
            height={iconSize}
            className={classes.leftChevron}
            onClick={() => onNavigate("PREV")}
          />
          <span className={classes.title}>{title}</span>
          <InlineIcon
            icon={ChevronRight}
            width={iconSize}
            height={iconSize}
            className={classes.rightChevron}
            onClick={() => onNavigate("NEXT")}
          />
        </div>
      )}
      <div
        style={{
          paddingTop: 2,
        }}
      >
        <InlineIcon
          icon={CalendarToday}
          width={iconSize}
          height={iconSize}
          onClick={() => onNavigate("TODAY")}
          className={view !== "day" ? classes.icon : classes.iconDisabled}
        />
        <Divider />
        <div style={{ display: "inline-block", width: 24, height: 24 }}></div>
        <Divider />
        <InlineIcon
          icon={ViewModuleOutline}
          width={iconSize}
          height={iconSize}
          onClick={() => onView("month")}
          className={view !== "month" ? classes.icon : classes.iconDisabled}
        />
        <Divider />
        <InlineIcon
          icon={ViewSequentialOutline}
          width={iconSize}
          height={iconSize}
          onClick={() => onView("timeline")}
          className={view !== "timeline" ? classes.icon : classes.iconDisabled}
        />
        <Divider />
        <InlineIcon
          icon={ViewStreamOutline}
          width={iconSize}
          height={iconSize}
          onClick={() => onView("weeklyTimeline")}
          className={
            view !== "weeklyTimeline" ? classes.icon : classes.iconDisabled
          }
        />
        <Divider />
        <InlineIcon
          icon={ViewDayOutline}
          width={iconSize}
          height={iconSize}
          onClick={() => {
            setToolbarButton("dailyTable");
            setShowCalendar(false);
          }}
          className={classes.icon}
        />
      </div>
    </div>
  );
};

export default CustomToolbar;
