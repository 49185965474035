import * as Yup from "yup";
import { CREATE_CHANGE_REQUEST } from "graphql/mutations";

export const countryOfBirthDialogDef = {
  type: "countryOfBirth",
  dialogLabel: "EDIT_COUNTRY_OF_BIRTH",
  mutation: CREATE_CHANGE_REQUEST,
  items: [
    {
      label: "Common.validity",
      name: "validFrom",
      type: "datePicker",
      validation: Yup.date().required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Employee.countryOfBirth",
      name: "countryOfBirth",
      dataField: "countryOfBirth",
      type: "country",
      validation: Yup.string(),
    },
    {
      label: "Leave.message",
      name: "initialMessage",
      type: "textarea",
      validation: Yup.string(),
    },
  ],
};
