import gql from "graphql-tag";

export const GET_WORK_DAYS = gql`
  query workDays($contract: String!, $startDate: Date!, $endDate: Date!) {
    workDays(contract: $contract, startDate: $startDate, endDate: $endDate) {
      result
      workDays
      effectiveWorkDays
      effectiveWorkDaysByDays {
        date
        workDays
        effectiveWorkDay
      }
    }
  }
`;
