import gql from "graphql-tag";

export const CREATE_TAX_DECLARATION_FOUR_CHILDREN = gql`
  mutation createTaxDeclarationFourChildren(
    $dateOfDeclaration: Date!
    $isModifiedDeclaration: Boolean!
    $isEligible: Boolean!
    $permanentlyEntitled: Boolean!
    $notApplyFromMonth: String!
    $dependents: [TaxDeclarationFourChildrenDependentInput]
  ) {
    createTaxDeclarationFourChildren(
      dateOfDeclaration: $dateOfDeclaration
      isModifiedDeclaration: $isModifiedDeclaration
      isEligible: $isEligible
      permanentlyEntitled: $permanentlyEntitled
      notApplyFromMonth: $notApplyFromMonth
      dependents: $dependents
    ) {
      result
      messages
      tableId
    }
  }
`;
