import { useQuery } from "@apollo/client";
import { GET_CHANGE_REQUESTS_ATTACHEMENT } from "graphql/queries";

const useChangeRequestAttachement = (type, employeeCode) => {
  const { data, loading, error, refetch } = useQuery(
    GET_CHANGE_REQUESTS_ATTACHEMENT,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        employeeCode,
        type,
      },
    }
  );

  const result = data?.listAttachementByEmployee || [];
  return {
    data: result,
    loading,
    error,
    refetch,
  };
};

export default useChangeRequestAttachement;
