import gql from "graphql-tag";

export const IMPORT_SSHR_USERS = gql`
  mutation importSSHRUsers($automaticPasswordGeneration: Boolean!) {
    importSSHRUsers(automaticPasswordGeneration: $automaticPasswordGeneration) {
      totalProcessedRecordCount
      importedUsersCount
      failedUsersCount
      importErrors {
        employee
        employeeName
        error
        errorPayload {
          existedEmployee
          existedEmployeeName
          existedEmail
        }
      }
    }
  }
`;
