import React from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import { startOfYear, endOfYear, getDayOfYear } from "date-fns";
import useCalendar from "hooks/use-my-leave-calendar";
import useStyles from "./timeline-day-header.style";
import isWeekendDay from "oc/utils/is-weekend-day";
import { useTranslation } from "react-i18next";

export default function TimelineDayHeader({ date, isOffRange }) {
  const { i18n } = useTranslation();
  let startDate = startOfYear(date);
  let endDate = endOfYear(date);

  const { data: Calendar } = useCalendar({ startDate, endDate });
  let day = getDayOfYear(date);
  let dayState = Calendar.charAt(day - 1);

  let color = "#212121";
  if (isOffRange) {
    color = "#ffffff";
  }
  if (dayState === "F") {
    color = "#cc0033";
  }

  const label = moment(date).locale(i18n.language).format("dd").substring(0, 2);
  const classes = useStyles();
  let selectedClass = isWeekendDay(date)
    ? classes.timelineWeekend
    : classes.timeline;
  return (
    <Box className={classes.root}>
      <span className={selectedClass} style={{ color: color }}>
        {label}
      </span>
    </Box>
  );
}
