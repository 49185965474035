import { atom } from "recoil";
import persistAtom from "./persist-atom";

export const currentUserState = atom({
  key: "currentUser",
  default: {
    contractCode: "",
    employeeCode: "",
    emailAddress: "",
    accessTokenPeriod: 0,
    accessToken: "",
  },
  effects_UNSTABLE: [persistAtom],
});
