import gql from "graphql-tag";

export const GET_LEAVE_REQUEST = gql`
    query leaveRequest($_id: String!) {
        leaveRequest(_id: $_id) {
            _id
            contract
            startDate
            endDate
            workDays
            effectiveWorkDays
            leaveType
            type
            state
            approvedAt
            approvedBy
            rejectedAt
            rejectedBy
            createdAt
            createdBy
            messages {
                createdAt
                createdBy
                message
                employeeName
                employeeAvatar
            }
            leaveTypeName
        }
    }
`;
