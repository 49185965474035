import gql from "graphql-tag";

export const APPROVE_MY_TIME_REQUEST = gql`
  mutation approveMyTimeRequest($_id: String!, $message: String) {
    approveMyTimeRequest(_id: $_id, message: $message) {
      result
      message
    }
  }
`;
