import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    "& .rbc-calendar": {
      fontFamily: "Mulish",
      minWidth: 880,
      overflow: "visible",
      height: "fit-content",
      minHeight: 900,
      //height: "auto",
    },
    "& .rbc-date-cell": {
      fontFamily: "Mulish",
      fontSize: 12,
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 0,
    },
    "& .rbc-today": {
      backgroundColor: "rgba(204, 0, 51, 0.2)",
    },
    "& .rbc-weekend-day": {
      backgroundColor: theme.palette.gray20.main,
    },
    "& .rbc-payed-holiday-day": {
      color: theme.palette.primary.main,
    },
    "& .rbc-off-range": {
      color: theme.palette.white.main,
    },
    "& .rbc-off-range-bg": {
      backgroundColor: theme.palette.gray60.main,
      color: theme.palette.white.main,
    },
    "& .rbc-show-more": {
      color: "#cc0033",
      paddingLeft: 4,
      fontSize: 12,
      textDecoration: "none",
    },
    "& .rbc-event": {
      position: "relative",
      borderRadius: 4,
      backgroundColor: "#2FA84F",
      marginBottom: 2,
    },
    "& .rbc-event-continues-after": {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    "& .rbc-event-continues-prior": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    "& .rbc-event-content": {
      fontSize: 12,
      padding: 2,
      paddingLeft: 4,
    },
    "& .rbc-event:focus": {
      outline: "none",
    },
    "& .rbc-event-requested-leave": {
      backgroundColor: "#f3aa18",
      color: theme.palette.white.main,
      border: "2px solid #f3aa18",
      marginBottom: 2,
    },
    "& .rbc-event-approved-leave": {
      backgroundColor: "#2fa84f",
      color: theme.palette.white.main,
      border: "2px solid #2fa84f",
      marginBottom: 2,
    },
    "& .rbc-event-requested-absence": {
      backgroundColor: "#ffffff",
      border: "2px solid #f3aa18",
      color: "#f3aa18",
      marginBottom: 2,
    },
    "& .rbc-event-approved-absence": {
      backgroundColor: "#ffffff",
      border: "2px solid #2fa84f",
      color: "#2fa84f",
      marginBottom: 2,
    },
    "& .rbc-event-current-request": {
      backgroundColor: "#ffffff",
      border: "2px solid #cc0033",
      color: "#cc0033",
      marginBottom: 2,
    },
    "& .rbc-agenda-row:hover": {
      backgroundColor: "#F1F3F4",
    },
    "& .rbc-month-row": {
      overflow: "visible",
      height: "fit-content",
    },
    "& .rbc-month-view": {
      overflow: "visible",
      height: "fit-content",
    },
  },
}));

export default useStyles;
