import gql from "graphql-tag";

export const DELETE_APPROVED_MY_TIME_REQUEST = gql`
  mutation deleteApprovedMyTimeRequest(
    $contractCode: String!
    $myTimeType: String!
    $startTime: Date!
    $endTime: Date!
    $message: String
  ) {
    deleteApprovedMyTimeRequest(
      contractCode: $contractCode
      myTimeType: $myTimeType
      startTime: $startTime
      endTime: $endTime
      message: $message
    ) {
      result
      message
    }
  }
`;
