import moment from "moment";

export const parseDateTime = (date) => {
  const separator = "-";
  const colon = ":";
  const space = " ";

  let resultArr = date.split("");
  resultArr.splice(4, 0, separator);
  resultArr.splice(7, 0, separator);
  resultArr.splice(10, 0, space);
  resultArr.splice(13, 0, colon);
  const result = resultArr.join("");
  return moment(result).toDate();
};

export default parseDateTime;
