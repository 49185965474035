import { useQuery, useSubscription } from "@apollo/client";
import { GET_PROPERTY } from "graphql/queries";
import { PROPERTY_SUBSCRIPTION } from "graphql/subcriptions";
import useOcClientState from "hooks/use-oc-client-state";

export default function useOcProperty({
  resource,
  key,
  onCompleted,
  fetchPolicy = "cache-and-network",
  pollInterval = 0,
  skip,
  subscription = true,
  onError,
}) {
  let skipValue = skip || !resource || !key;

  const { data, loading, error, refetch } = useQuery(GET_PROPERTY, {
    skip: skipValue,
    fetchPolicy: fetchPolicy,
    pollInterval: pollInterval,
    variables: {
      resource,
      key,
    },
    onCompleted: (res) => {
      if (onCompleted) {
        return onCompleted({ key, value: res?.property?.value });
      }
    },
    onError: (err) => {
      if (onError) {
        return onError({ key, value: err });
      }
    },
  });

  useSubscription(PROPERTY_SUBSCRIPTION, {
    skip: !subscription || !resource || !key,
    variables: {
      resource,
      key,
    },
    onData: ({ data }) => {
      refetch();
    },
  });

  const [, setOpenMessage] = useOcClientState("notification", {
    type: "",
    message: "",
  });

  if (error) {
    setOpenMessage({
      type: "error",
      message: "NETWORK_ERROR",
    });
  }

  return {
    data: data?.property?.value,
    loading,
    error,
    refetch,
    onCompleted,
    onError,
  };
}
