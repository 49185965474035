import gql from "graphql-tag";

export const GET_CURRENT_CAFETERIA_STATEMENT_ITEMS = gql`
  query getCurrentCafeteriaStatementItems($contractCode: String!) {
    getCurrentCafeteriaStatementItems(contractCode: $contractCode) {
      _id
      origin
      contractCode
      menuItem {
        code
        name
        planable
        isPeriodical
        isSZEPCardItem
      }
      startDate
      endDate
      itemSerial
      type
      amount
      cost
      plan
      szepCardApplicationAmount
    }
  }
`;
