import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const taxApplicationsState = atom({
  key: "taxApplicationsState",
  default: {
    TaxDeclarationPersonal: true,
    TaxDeclarationFirstMarriedPerson: true,
    TaxDeclarationFourChildren: true,
    TaxDeclarationFamily: true,
    TaxDeclarationUnder25: true,
    TaxDecarationMotherUnder30: true,
  },
  effects_UNSTABLE: [persistAtom],
});
