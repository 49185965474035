import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const taxDeclarationMothersUnder30State = atom({
  key: "taxDeclarationMothersUnder30State",
  default: {
    dateOfDeclaration: new Date(),
    isModifiedDeclaration: false,
    isEligible: false,
    eligibleOnOwnChild: false,
    ownChildName: "",
    ownChildTaxNumber: "",
    eligibleOnFetus: false,
    pregnancy91thDayYear: "",
    pregnancy91thDayMonth: "",
    eligibleOnAdoptedChild: false,
    adoptedChildName: "",
    adoptedChildTaxNumber: "",
    applyForTaxRelief: false,
    applyForTaxReliefAllAmount: false,
    applyForTaxReliefAmount: 0,
    noRequestReliefFromMonth: "",
  },
  effects_UNSTABLE: [persistAtom],
});
