import React from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import useStyles from "./weekly-timeline-day-header.style";
import { useTranslation } from "react-i18next";

export default function WeeklyTimelineDayHeader({ date, isOffRange }) {
  const { i18n } = useTranslation();
  let label = moment(date).locale(i18n.language).format("dddd");
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <span className={classes.root}>{label}</span>
    </Box>
  );
}
