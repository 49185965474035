import gql from "graphql-tag";

export const GET_EMAILS_BY_COMPANY = gql`
  query getEmailsByCompanyList {
    getEmailsByCompanyList {
      companyCode
      type
      address
    }
  }
`;
