import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

const ButtonBar = ({ handleCancel, handleSave, disabled }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box paddingTop="32px" style={{ borderBottom: "1px solid #C6CACC" }} />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        paddingTop="16px"
      >
        <Button id="cancel-button" variant="text" onClick={handleCancel}>
          {t("CANCEL")}
        </Button>
        <Box width="16px" />
        <Button
          id="save-button"
          variant="contained"
          color="primary"
          disableElevation
          disabled={disabled}
          onClick={handleSave}
        >
          {t("SAVE")}
        </Button>
      </Box>
    </Box>
  );
};

export default ButtonBar;
