import React, { useEffect, useState } from "react";
import useWorkDays from "hooks/use-my-leave-work-days";
import useLeaveTypesByContract from "hooks/use-my-leave-types-by-contract";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import useContractCalendar from "hooks/use-my-leave-contract-calendar";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import "date-fns";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import OcTimeDateCounter from "oc/components/oc-time-date-counter";
import useStyles from "./my-leave-new-request-dialog.style";
import { CREATE_LEAVE_REQUEST } from "graphql/mutations";
import useLeaveSummaryChartData from "hooks/use-my-leave-summary-chart-data";
import Box from "@material-ui/core/Box";
import useCurrentUserState from "hooks/use-current-user-state";
import OcDatePicker from "oc/components/oc-date-picker";
import LeaveBaseCard from "my-leave/components/my-leave-leave-base-card";
import LeaveCovidplustenCard from "my-leave/components/my-leave-leave-covid-plus-ten-card";
import LeaveEszjtvCard from "my-leave/components/my-leave-leave-eszjtv-card";
import LeaveStudyCard from "my-leave/components/my-leave-leave-study-card";
import OcCard from "oc/components/oc-card";
import Collapse from "@material-ui/core/Collapse";
import useReqestLeavesData from "hooks/use-request-leave-data";
import LeaveEmergencyCard from "my-leave/components/my-leave-leave-emergency-card";
import MyLeaveEffectiveWorkDaysBlock from "my-leave/components/my-leave-effective-work-days-block";
import { Typography } from "@material-ui/core";
import getDateFormatByLocale from "oc/utils/get-date-format-by-locale";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const MyLeaveNewRequestDialog = ({ showDialog, onShowDialog, onCoverage }) => {
  let now = new Date();
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [leaveType, setLeaveType] = useState("S");
  const [leaveStart, setLeaveStart] = useState(now);
  const [leaveEnd, setLeaveEnd] = useState(now);
  const [message, setMessage] = useState("");
  const [leaveStartError, setLeaveStartError] = useState(false);
  const [leaveEndError, setLeaveEndError] = useState(false);
  const [leaveTypeError, setLeaveTypeError] = useState(false);
  const [coverageError, setCoverageError] = useState(false);
  const [workDaysError, setWorkDaysError] = useState(false);
  const [moreDaysError, setMoreDaysError] = useState(false);
  const [currentDate, setCurrentDate] = useState(now);
  const [expiredContractError, setExpiredContractError] = useState(false);

  let { contractCode, employeeCode } = useCurrentUserState();

  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  const expiredContractDate = contractsData.map((contract) => {
    if (contract.validTo !== null) {
      const validToDate = new Date(contract.validTo);
      validToDate.setDate(validToDate.getDate() + 1);
      return validToDate.toISOString();
    }
    return null;
  });

  const selectedYear = leaveStart?.getFullYear().toString();
  const leaveStartYear = moment(leaveStart)?.format("YYYY");
  const leaveEndYear = moment(leaveEnd)?.format("YYYY");
  let nextYear = null;

  if (leaveStartYear !== leaveEndYear && leaveType === "S") {
    nextYear = leaveEndYear;
  }

  const { requestedLeaveDays } = useReqestLeavesData({
    selectedYear,
    contractCode,
  });

  const { data: leaveSummary } = useLeaveSummaryChartData({
    year: selectedYear,
    requestedLeaveDays,
    contract: contractCode,
  });
  let thisYear = new Date().getFullYear().toString();

  const { requestedLeaveDaysNextYear } = useReqestLeavesData({
    nextYear,
    contractCode,
  });

  const { data: leaveSummaryNextYear } = useLeaveSummaryChartData({
    year: nextYear,
    requestedLeaveDaysNextYear,
    contract: contractCode,
  });

  function getStartOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  const handleCurrentDate = (date) => {
    if (moment(date).format("YYYY") !== moment(currentDate).format("YYYY")) {
      setCurrentDate(moment(date).endOf("day"));
    }
  };

  const startOfYear = moment(currentDate).startOf("year");
  const endOfYear = moment(currentDate).endOf("year");

  const { contractCalendar } = useContractCalendar({
    contract: contractCode,
    startDate: startOfYear,
    endDate: endOfYear,
  });

  const {
    workDays,
    effectiveWorkDays,
    effectiveWorkDaysByDays,
    currentYearWorkDays,
    currentYearEffectiveWorkDays,
    nextYearWorkDays,
    nextYearEffectiveWorkDays,
    workDaysloading,
  } = useWorkDays({
    contract: contractCode,
    startDate: leaveStart,
    endDate: leaveEnd,
  });

  const { leaveTypesByContract } = useLeaveTypesByContract({
    contract: contractCode,
    startDate: leaveStart,
    endDate: leaveEnd,
  });

  const [createRequest] = useMutation(CREATE_LEAVE_REQUEST, {
    onCompleted: () => {
      setSaveButtonDisabled(false);
    },
    onError: () => {
      setSaveButtonDisabled(false);
    },
  });

  const handleLeaveType = (e) => {
    setLeaveType(e.target.value);
    setLeaveTypeError(false);
  };

  const handleLeaveStart = (date) => {
    if (!isValidDate(date)) return;
    const localTime = moment(date).format("HHmmSS");
    if (localTime === "000000") {
      date = moment(date).add(12, "hours").toDate();
    }

    let startOfMonth = getStartOfMonth(new Date());
    if (date < startOfMonth) {
      setLeaveStartError(true);
      setLeaveEndError(true);
    } else {
      setLeaveStartError(false);
      setLeaveEndError(false);
    }
    setLeaveStart(date);
    setLeaveEnd(date);
    setWorkDaysError(false);
  };

  const handleLeaveEnd = (date) => {
    if (!isValidDate(date)) return;

    const localTime = moment(date).format("HHmmSS");
    if (localTime === "000000") {
      date = moment(date).add(12, "hours").toDate();
    }

    let start = new Date(leaveStart);
    let end = new Date(date);
    if (start > end) {
      setLeaveEndError(true);
    } else {
      setLeaveEndError(false);
    }
    setLeaveEnd(date);
    setWorkDaysError(false);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const [doCoverage, setDoCoverage] = React.useState(true);

  const handleClear = () => {
    setLeaveType("S");
    setLeaveStart(now);
    setLeaveEnd(now);
    setMessage("");
    setLeaveStartError(false);
    setLeaveEndError(false);
    setLeaveTypeError(false);
    setCoverageError(false);
    setWorkDaysError(false);
    setMoreDaysError(false);
    setExpiredContractError(false);
    setDoCoverage(true);
  };

  const handleCancel = () => {
    onShowDialog(false);
    setTimeout(() => {
      handleClear();
    }, 500);
  };

  const handleSave = async () => {
    const leaveStartMonth = moment(leaveStart).format("YYYY-MM");
    const referenceDate = moment().format("YYYY-MM");
    if (
      leaveStartMonth <
      referenceDate
    ) {
      setLeaveStartError(true);
      return;
    }

    setSaveButtonDisabled(true);
    if (
      leaveStartError ||
      leaveEndError ||
      leaveTypeError ||
      coverageError ||
      moreDaysError ||
      expiredContractError
    ) {
      setSaveButtonDisabled(false);
      return;
    }

    if (workDays === 0) {
      setWorkDaysError(true);
      setSaveButtonDisabled(false);
      return;
    }
    if (!leaveType) {
      setSaveButtonDisabled(false);
      setLeaveTypeError(true);
      return;
    }

    let variables = {
      contract: contractCode,
      startDate: leaveStart,
      endDate: leaveEnd,
      workDays: workDays,
      effectiveWorkDays: effectiveWorkDays,
      type: leaveType,
      locale: i18n.language,
    };
    if (message?.trim().length > 0) {
      variables.message = message;
    }
    setMoreDaysError(false);
    setCoverageError(false);
    setDoCoverage(false);
    await createRequest({
      variables,
    });
    onShowDialog(false);
    handleClear();
  };

  useEffect(() => {
    if (leaveStart && leaveEnd && leaveType && doCoverage) {
      setCoverageError(
        onCoverage({
          startDate: leaveStart,
          endDate: leaveEnd,
          leaveType,
        })
      );
    }

    if (leaveType) {
      let requestable;
      let requestableNextYear;
      switch (leaveType) {
        case "S":
          requestable = leaveSummary?.availableLeaveDays;
          requestableNextYear = leaveSummaryNextYear?.availableLeaveDays;
          break;
        case "Q":
          requestable = leaveSummary?.availableCovidPlus10LeaveDays;
          break;
        case "T":
          requestable = leaveSummary?.availableStudyLeaveDays;
          break;
        case "D":
          requestable = leaveSummary?.availableESZJTV19LeaveDays;
          break;
        case "V":
          requestable = leaveSummary?.availableEmergencyLeaveDays;
          break;

        default:
          break;
      }

      let error = false;
      if (nextYear) {
        error =
          requestable - currentYearWorkDays < 0 ||
          requestableNextYear - nextYearWorkDays < 0;
      } else {
        error = requestable?.toFixed(3) - effectiveWorkDays < 0;
      }

      if (error && showDialog && !saveButtonDisabled) {
        setMoreDaysError(true);
      } else {
        setMoreDaysError(false);
      }

      if (
        leaveStart > new Date(expiredContractDate) ||
        leaveEnd > new Date(expiredContractDate)
      ) {
        setExpiredContractError(true);
      } else {
        setExpiredContractError(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    leaveStart,
    leaveEnd,
    leaveType,
    leaveTypesByContract,
    onCoverage,
    requestedLeaveDays,
    t,
    leaveSummary,
    leaveSummaryNextYear,
    workDays,
    currentYearWorkDays,
    nextYearWorkDays,
    doCoverage,
    nextYear,
    effectiveWorkDays,
    moreDaysError,
    saveButtonDisabled,
    expiredContractDate,
  ]);
  const renderDay = (day, selectedDate, isInCurrentMonth, dayComponent) => {
    let className = "";
    let isToday = moment(day).isSame(moment(), "day");

    if (contractCalendar && contractCalendar.length > 0) {
      let dayOfYear = moment(day).dayOfYear();
      let dayState = contractCalendar?.charAt(dayOfYear - 1);
      if (isInCurrentMonth && (dayState === "P" || dayState === "T")) {
        className = classes.weekendDay;
      } else if (isInCurrentMonth && dayState === "F") {
        className = classes.paidHolliday;
      } else if (dayState === ".") {
        className = "";
      } else if (
        isInCurrentMonth &&
        (moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 0)
      ) {
        className = classes.weekendDay;
      }
    }
    let CustomDay = (
      <span className={className}>{moment(day).format("D")}</span>
    );

    if (isToday) {
      className = classes.today;
      CustomDay = (
        <span style={{ color: "#212121" }}>{moment(day).format("D")}</span>
      );
    }

    if (dayComponent.props.selected) {
      className = classes.selected;
      CustomDay = (
        <span style={{ color: "white" }}>{moment(day).format("D")}</span>
      );
    }
    let element = React.cloneElement(dayComponent, [], CustomDay);
    return <div className={className}>{element}</div>;
  };

  const selectValues =
    leaveTypesByContract.length === 0
      ? [
        {
          code: "S",
          name: "Szabadság",
          type: "leave",
        },
      ]
      : leaveTypesByContract.filter(
        (item) => item?.code !== "6" && item?.code !== "7"
      );

  const leaveTypelist =
    leaveType === "Q" ||
    leaveType === "S" ||
    leaveType === "D" ||
    leaveType === "T" ||
    leaveType === "V";

  return (
    <Dialog
      //onClose={onClose}
      aria-labelledby="new-request-dialog-title"
      open={showDialog}
      //disableBackdropClick
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return false;
        }
      }}
      disableEscapeKeyDown
      scroll="body"
    >
      <Box height="75px">
        <OcCard label={t("Leave.newRequest")} />
      </Box>
      <DialogContent className={classes.content}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="type-select-label-id" className={classes.selectInput}>
            <span style={{ paddingLeft: 8, paddingRight: 8 }}>
              {t("Leave.type")}
            </span>
          </InputLabel>
          <Select
            labelId="type-select-label-id"
            id="leaveType"
            name="leaveType"
            value={leaveType ? leaveType : "S"}
            onChange={handleLeaveType}
            error={leaveTypeError}
            style={{
              fontSize: 16,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            autoWidth
            fullWidth
            className={classes.selectControl}
            inputProps={{
              classes: {
                root: classes.selectInput,
              },
            }}
          >
            {selectValues?.map((event, idx) => (
              <MenuItem
                key={`select-item-${idx}`}
                value={event?.code}
                style={{ width: 400 }}
              >
                <div
                  style={{
                    width: 360,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {t(`Leave.${event?.code}`)}
                </div>
              </MenuItem>
            ))}
          </Select>
          {leaveTypeError && (
            <FormHelperText style={{ color: "#cc0033", minHeight: 32 }}>
              {leaveTypeError && "A kérelem típusának a megadása kötelező!"}
            </FormHelperText>
          )}
        </FormControl>
        <Box height="16px" />
        <Collapse in={leaveType === "S"} timeout="auto" unmountOnExit>
          {nextYear && (
            <Typography variant="body1" style={{ marginBottom: "-12px" }}>
              {selectedYear}
            </Typography>
          )}
          <LeaveBaseCard
            selectedYear={thisYear}
            contract={contractCode}
            workDays={currentYearWorkDays}
          />
        </Collapse>
        <Collapse
          in={leaveType === "S" && nextYear}
          timeout="auto"
          unmountOnExit
        >
          <Typography
            variant="body1"
            style={{ marginBottom: "-12px", marginTop: "30px" }}
          >
            {nextYear}
          </Typography>
          <LeaveBaseCard
            selectedYear={nextYear}
            contract={contractCode}
            workDays={nextYearWorkDays}
          />
        </Collapse>
        <Collapse in={leaveType === "Q"} timeout="auto" unmountOnExit>
          <LeaveCovidplustenCard
            selectedYear={thisYear}
            contract={contractCode}
            workDays={workDays}
          />
        </Collapse>
        <Collapse in={leaveType === "D"} timeout="auto" unmountOnExit>
          <LeaveEszjtvCard
            selectedYear={thisYear}
            contract={contractCode}
            workDays={workDays}
          />
        </Collapse>
        <Collapse in={leaveType === "T"} timeout="auto" unmountOnExit>
          <LeaveStudyCard
            selectedYear={thisYear}
            contract={contractCode}
            workDays={workDays}
          />
        </Collapse>
        <Collapse in={leaveType === "V"} timeout="auto" unmountOnExit>
          <LeaveEmergencyCard
            selectedYear={thisYear}
            contract={contractCode}
            workDays={workDays}
          />
        </Collapse>
        <form noValidate>
          <div className={classes.centerContainer}>
            <div className={classes.pickerContainer}>
              <OcDatePicker
                error={leaveStartError}
                helperText={leaveStartError && t("Leave.notSelectable")}
                id="leaveStart"
                label={t("LEAVE_START")}
                minDate={new Date("2020-09-01")}
                minDateMessage={t("Leave.notSelectable")}
                onChange={handleLeaveStart}
                onMonthChange={handleCurrentDate}
                renderDay={renderDay}
                value={leaveStart}
                format={getDateFormatByLocale(i18n.language)}
                onFocus={() => {
                  setLeaveStartError(false);
                }}
              />
              <OcDatePicker
                error={leaveEndError}
                helperText={leaveEndError && t("Leave.notSelectable")}
                id="leaveEnd"
                label={t("LEAVE_END")}
                minDate={new Date("2020-09-01")}
                minDateMessage={t("Leave.notSelectable")}
                onChange={handleLeaveEnd}
                renderDay={renderDay}
                value={leaveEnd}
                format={getDateFormatByLocale(i18n.language)}
                onFocus={() => {
                  setLeaveEndError(false);
                }}
              />
            </div>
            <OcTimeDateCounter
              leaveStart={leaveStart}
              leaveEnd={leaveEnd}
              time={workDays}
              timeloading={workDaysloading}
              cssClasses="leave"
              counterLabel={t("COUNT_OF_WORKDAYS")}
            />
          </div>
          <div style={{ marginTop: 28, marginBottom: 16 }}>
            {workDays !== effectiveWorkDays && leaveTypelist === true && (
              <>
                <MyLeaveEffectiveWorkDaysBlock
                  effectiveWorkDays={effectiveWorkDays}
                  effectiveWorkDaysByDays={effectiveWorkDaysByDays}
                  currentYearEffectiveWorkDays={currentYearEffectiveWorkDays}
                  nextYearEffectiveWorkDays={nextYearEffectiveWorkDays}
                />
                <Box height="32px" />
              </>
            )}
            <TextField
              label={t("Leave.message")}
              multiline
              value={message}
              onChange={handleMessage}
              fullWidth
              variant="outlined"
              helperText={
                message.length === 0
                  ? `(${t("Common.optional")})`
                  : `${message.length}/255`
              }
              style={{
                height: "auto",
              }}
              inputProps={{ maxLength: 255 }}
              FormHelperTextProps={
                message.length === 0
                  ? { style: { textAlign: "left" } }
                  : { style: { textAlign: "right" } }
              }
            />
          </div>
          {(coverageError ||
            workDaysError ||
            moreDaysError ||
            expiredContractError) && (
              <Box
                style={{
                  display: "inline-box",
                  minHeight: "20px",
                  color: "#cc0033",
                  textAlign: "center",
                  width: 400,
                  padding:
                    coverageError ||
                      workDaysError ||
                      moreDaysError ||
                      expiredContractError
                      ? "26px 0px 36px 0px"
                      : "0px 0px 20px 0px",
                }}
              >
                {coverageError && <div>{t("COVERAGE_ERROR")}</div>}
                {workDaysError && <div>{t("WORKDAYS_ERROR")}</div>}
                {moreDaysError && <div>{t("MORE_DAYS_ERROR")}</div>}
                {expiredContractError && <div>{t("EXPIRED_CONTRACT_ERROR")}</div>}
              </Box>
            )}
          <div className={classes.line} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={saveButtonDisabled}
          >
            {t("Common.save")}
          </Button>
          <div className={classes.divider} />
          <Button
            id="myleave-new-request-dialog-cancel-button"
            text={t("Common.cancel")}
            fullWidth
            onClick={handleCancel}
          >
            {t("Common.cancel")}
          </Button>
          <div className={classes.divider} />
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default MyLeaveNewRequestDialog;
