import { GET_LEAVE_REQUESTS_BY_CONTRACT } from "graphql/queries";
import { LEAVE_REQUESTS_BY_CONTRACT_SUBSCRIPTION } from "graphql/subcriptions";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import usePolling from "hooks/use-polling";

const useReqestLeavesData = ({ selectedYear, contractCode }) => {
  const { data, error } = useQuery(GET_LEAVE_REQUESTS_BY_CONTRACT, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    skip: !contractCode,
    variables: { contract: contractCode, year: selectedYear },
  });

  if (error) {
    console.log({
      component: "MyLeaveRequests",
      errorDescription:
        "Error occured when fetch data with GET_LEAVE_REQUESTS_BY_CONTRACT query",
      error: error,
    });
  }

  const { data: subscriptionData, error: subscriptionError } = useSubscription(
    LEAVE_REQUESTS_BY_CONTRACT_SUBSCRIPTION,
    {
      fetchPolicy: "network-only",
      variables: { contract: contractCode, year: selectedYear },
    }
  );

  if (subscriptionError) {
    console.log({
      component: "MyLeaveRequests",
      errorDescription:
        "Error occured when subscribe data with LEAVE_REQUESTS_SUBSCRIPTION query",
      error: error,
    });
  }

  let resultData =
    subscriptionData?.leaveRequestsByContract ||
    data?.leaveRequestsByContract ||
    [];

  let result = _.cloneDeep(resultData);

  let requestedLeaveDays = {
    base: 0,
    study: 0,
    covid: 0,
    eszjtv: 0,
    emergency: 0,
  };

  result.map((item) => {
    const now = new Date(selectedYear);
    const itemDate = new Date(item?.startDate);
    const inCurrentYear = now.getFullYear() === itemDate.getFullYear();

    if (item.type === "leave" && item.state === "requested" && inCurrentYear) {
      if (item.leaveType === "Q") {
        requestedLeaveDays.covid =
          requestedLeaveDays.covid + item.effectiveWorkDays;
      } else if (item.leaveType === "D") {
        requestedLeaveDays.eszjtv =
          requestedLeaveDays.eszjtv + item.effectiveWorkDays;
      } else if (item.leaveType === "T") {
        requestedLeaveDays.study =
          requestedLeaveDays.study + item.effectiveWorkDays;
      } else if (item.leaveType === "V") {
        requestedLeaveDays.emergency =
          requestedLeaveDays.emergency + item.effectiveWorkDays;
      } else {
        requestedLeaveDays.base =
          requestedLeaveDays.base + item.effectiveWorkDays;
      }
    }
    return item;
  });

  return { contractCode, selectedYear, requestedLeaveDays };
};

export default useReqestLeavesData;
