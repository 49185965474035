import React from "react";
import OcErrorBoundaryInfo from "oc/components/oc-error-boundary-info";

export default class OcErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <OcErrorBoundaryInfo
          sourceComponent={this.props?.children?.type?.name}
          error={this.state.error}
          errorInfo={this.state.errorInfo}
        />
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
