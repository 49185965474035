import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import useStyles from "./my-hr-application-state.style";

export default function TaxState({ label }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const getColor = (label) => {
    switch (label) {
      case "K":
        return "#F3AA18";
      case "E":
        return "#2FA84F";
      case "T":
        return "#5E6366";
      case "V":
        return theme.palette.primary.main;

      default:
        return "white";
    }
  };

  const classes = useStyles({ borderColor: getColor(label) });

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Typography className={classes.text} variant="body2">
        {t(`TAX_STATE_${label}`)}
      </Typography>
    </Box>
  );
}
