import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_LEAVE_REQUESTS_BY_CONTRACT } from "graphql/queries";
import { LEAVE_REQUESTS_SUBSCRIPTION } from "graphql/subcriptions";
import usePolling from "hooks/use-polling";

export default function useUserLeaveRequests({ contract }) {
  const year = new Date().getFullYear().toString();

  const { data, loading, error, refetch } = useQuery(
    GET_LEAVE_REQUESTS_BY_CONTRACT,
    {
      skip: !contract || !year,
      variables: { contract, year },
      fetchPolicy: "cache-and-network",
      pollInterval: usePolling(1000 * 60),
    }
  );

  if (error) {
    console.log({
      component: "useUserLeaveRequests",
      errorDescription:
        "Error occured when fetch data with GET_LEAVE_REQUESTS_BY_CONTRACT query",
      error: error,
    });
  }

  const { error: subscriptionError } = useSubscription(
    LEAVE_REQUESTS_SUBSCRIPTION,
    {
      onData: () => {
        refetch();
      },
    }
  );

  if (subscriptionError) {
    console.log({
      component: "useUserLeaveRequests",
      errorDescription:
        "Error occured when subscribe data with LEAVE_REQUESTS_SUBSCRIPTION query",
      error: error,
    });
  }

  let result = data?.leaveRequestsByContract || [];

  return { data: result, loading, error };
}
