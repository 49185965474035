import React from "react";
import { Box } from "@material-ui/core";
import MyTimePageHeader from "my-time/components/my-time-page-header";
import OcPageTransition from "oc/components/oc-page-transition";
import useActualDate from "my-time/hooks/use-actual-date";
import UserContractsCard from "my-hr/components/my-hr-user-contract-card";
import useContract from "hooks/use-myhr-contract";
import useEmployee from "hooks/use-myhr-employee";
import MyTimeEmployeeRequestsCard from "my-time/components/my-time-employee-requests-card";
import MyTimeAdmissionCardByAdmin from "my-time/components/my-time-admission-card-by-admin";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useProperty from "hooks/use-oc-property";

const MyTimeEmployeeRequestsPage = () => {
  const { t } = useTranslation();

  let { contract, employee } = useParams();

  const { date, formattedDate, year, month, handleDateChange } =
    useActualDate();

  const { data: contractData } = useContract({
    contract: contract,
  });

  const { data: employeeData } = useEmployee({
    employeeId: employee,
  });

  const { data: changeMyTimeEnabledData } = useProperty({
    resource: "myTime",
    key: "changeMyTimeEnabled",
  });

  const { data: changeWorkingTimeEnabledData } = useProperty({
    resource: "myTime",
    key: "changeWorkingTimeEnabled",
  });

  const { data: approveAllRequestsEnabledData } = useProperty({
    resource: "myTime",
    key: "approveAllRequestsEnabled",
  });

  const changeMyTimeEnabled = changeMyTimeEnabledData === "true" ? true : false;
  const approveAllRequestsEnabled =
    approveAllRequestsEnabledData === "true" ? true : false;
  const changeWorkingTimeEnabled =
    changeWorkingTimeEnabledData === "true" ? true : false;
  const showApproveAllButton = changeMyTimeEnabled && approveAllRequestsEnabled;

  return (
    <OcPageTransition>
      <Box padding="16px">
        <MyTimePageHeader
          formattedDate={formattedDate}
          date={date}
          onDateChange={handleDateChange}
        />
        <Box height="16px" />
        <UserContractsCard employee={employeeData} contract={contractData} />
        <Box height="16px" />
        <MyTimeEmployeeRequestsCard
          label={t("MY_TIME_REQUESTS")}
          contractCode={contract}
          employeeCode={employee}
          date={date}
          examinerView={true}
          showApproveAllButton={showApproveAllButton}
        />
        <Box height="16px" />
        <MyTimeAdmissionCardByAdmin
          contractCode={contract}
          employeeCode={employee}
          year={year}
          month={month}
          changeMyTimeEnabled={changeMyTimeEnabled}
          changeWorkingTimeEnabled={changeWorkingTimeEnabled}
        />
      </Box>
    </OcPageTransition>
  );
};

export default MyTimeEmployeeRequestsPage;
