import * as Yup from "yup";
import { CREATE_CHANGE_REQUEST } from "graphql/mutations";

export const addressDef = ({ addressType }) => {
  return {
    mutation: CREATE_CHANGE_REQUEST,
    items: [
      {
        label: "Common.validity",
        name: "validFrom",
        type: "datePicker",
        validation: Yup.date().required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
      },
      {
        label: "Address.country",
        name: "country",
        dataField: "country",
        type: "country",
        validation: Yup.string(),
      },
      {
        label: "Address.country",
        name: "addressSelector",
        type: "addressSelector",
      },
      {
        label: "Address.editZip",
        name: "zip",
        dataField: "zip",
        type: "postalCodeSelector",
        validation: Yup.string(),
      },
      {
        label: "Address.editCity",
        name: "city",
        dataField: "city",
        type: "postalCodeSelector",
        validation: Yup.string(),
      },
      {
        label: "Address.district",
        name: "district",
        dataField: "district",
        type: "districtCode",
        validation: Yup.string()
          .matches(/^\d{2}$/, "INCORRECT_FORMAT")
      },
      {
        label: "Address.streetName",
        name: "streetName",
        dataField: "streetName",
        type: "text",
        validation: Yup.string()
          .trim()
          .required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
      },
      {
        label: "Address.streetType",
        name: "streetType",
        dataField: "streetType",
        type: "streetType",
        validation: Yup.mixed().required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
      },
      {
        label: "Address.streetNumber",
        name: "streetNumber",
        dataField: "streetNumber",
        type: "text",
        validation: Yup.string()
          .trim()
          .required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
      },
      {
        label: "Address.building",
        name: "building",
        dataField: "building",
        type: "text",
        validation: Yup.string(),
      },
      {
        label: "Address.stairway",
        name: "stairway",
        dataField: "stairway",
        type: "text",
        validation: Yup.string(),
      },
      {
        label: "Address.floor",
        name: "floor",
        dataField: "floor",
        type: "text",
        validation: Yup.string(),
      },
      {
        label: "Address.door",
        name: "door",
        dataField: "door",
        type: "text",
        validation: Yup.string(),
      },
      {
        label: "Leave.message",
        name: "initialMessage",
        type: "textarea",
        validation: Yup.string(),
      },
    ],
  };
};
