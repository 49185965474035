import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/hu";
import useStyles from "./my-leave-calendar-day.style";

export default function CalendarDay({ date, loading, hours }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const LoadingState = () => {
    return <div className={classes.loading} />;
  };

  if (loading) return <LoadingState />;

  let locale = i18n.language.substr(0, 2).toLowerCase();
  let day = date ? new Date(date).getDate() : "";
  let month = date ? moment(date).locale(locale).format("MMM") : "";

  if (hours) {
    return (
      <div className={classes.rootHours}>
        <div className={classes.month}>{t("HOUR")}</div>
        <div className={classes.day}>{date}</div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.month}>{month}</div>
      <div className={classes.day}>{day}</div>
    </div>
  );
}

CalendarDay.propTypes = {
  date: PropTypes.string,
};
