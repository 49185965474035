import { useQuery } from "@apollo/react-hooks";
import { GET_POLLING } from "graphql/queries";

const usePolling = (polling = 0) => {
  const { data, loading, error } = useQuery(GET_POLLING, {
    fetchPolicy: "cache-first",
  });

  let result = polling;

  if (data?.getPolling === false || loading === true) {
    result = 0;
  }

  if (error) {
    console.log({
      component: "usePolling",
      errorDescription: "Error occured when fetch data with GET_POLLING query",
      error: error,
    });
  }

  return result;
};

export default usePolling;
