import gql from "graphql-tag";

export const GET_TAX_DECLARATION_UNDER_25 = gql`
  query taxDeclarationUnder25($_id: Int!) {
    taxDeclarationUnder25(_id: $_id) {
      dateOfDeclaration
      state
      noApply
      discountLimit
      noApplyAboveLimit
    }
  }
`;
