import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import OcAppRefreshCounter from "oc/components/oc-app-refresh-counter";
import { useTranslation } from "react-i18next";

const OcAppBarCounter = ({ timeToRefresh, onRefresh }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <OcAppRefreshCounter timeToRefresh={timeToRefresh} />
      <Box width="16px" />
      <Button onClick={onRefresh} variant="contained" color="primary">
        {t("REFRESH")}
      </Button>
    </Box>
  );
};

OcAppBarCounter.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

OcAppBarCounter.defaultProps = {
  example: "example",
};

export default OcAppBarCounter;
