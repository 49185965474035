import { useQuery, useSubscription } from "@apollo/client";
import { GET_EMAILS_BY_COMPANY } from "graphql/queries";
import { SUBSCRIPTION_EMAILS_BY_COMPANY } from "graphql/subcriptions";

const useEmailsByCompany = () => {
  const { data, loading, error } = useQuery(GET_EMAILS_BY_COMPANY, {
    fetchPolicy: "cache-and-network",
  });

  const result = data?.getEmailsByCompanyList || [];

  useSubscription(SUBSCRIPTION_EMAILS_BY_COMPANY, {
    fetchPolicy: "network-only",
  });

  return {
    data: result,
    loading,
    error,
  };
};

export default useEmailsByCompany;
