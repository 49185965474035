import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  selectInput: {
    backgroundColor: theme.palette.white.main,
    borderRadius: "4px",
    "&:focus": {
      backgroundColor: theme.palette.white.main,
    },
  },
}));

export default useStyles;
