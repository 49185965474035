import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const ConfirmDialog = ({
    showDialog,
    closeDialog,
    onConfirm,
    title,
    children,
    confirmLabel,
    cancelLabel,
}) => {
    const handleCancel = () => {
        closeDialog();
    };

    const handleConfirm = () => {
        closeDialog();
        onConfirm();
    };

    return (
        <Dialog
            aria-labelledby="new-request-dialog-title"
            open={showDialog}
            //disableBackdropClick
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    return false;
                }
            }}
            disableEscapeKeyDown
            scroll="body"
            PaperProps={{
                style: { padding: "0px 24px 24px 24px", width: 528 },
            }}
        >
            <div
                style={{
                    paddingTop: 24,
                    paddingBottom: 24,
                    paddingLeft: 0,
                    paddingRight: 0,
                    textAlign: "center",
                    fontSize: 24,
                    fontFamily: "Mulish",
                }}
            >
                {title}
            </div>
            <DialogContent>
                <div style={{ textAlign: "center", paddingBottom: 24 }}>
                    <span
                        style={{
                            fontFamily: "Mulish",
                            fontSize: "16px",
                            letterSpacing: 0.44,
                        }}
                    >
                        {children}
                    </span>
                </div>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Button
                        id="my-leave-confirm-dialog-delete-button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleConfirm}
                        style={{ marginBottom: 44 }}
                    >
                        {confirmLabel}
                    </Button>
                    <Button fullWidth onClick={handleCancel}>
                        {cancelLabel}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
