import { useMutation } from "@apollo/react-hooks";
import { LEAVE_EVENTS } from "graphql/mutations";
import usePolling from "hooks/use-polling";
import useContractCalendar from "hooks/use-my-leave-contract-calendar";
import moment from "moment";

export default function useLeaveEvents({
  roleFilters,
  currentDate,
  viewedByAdmin,
  contract,
}) {
  const { contractCalendar } = useContractCalendar({
    contract: contract,
    startDate: moment(currentDate).startOf("month").toDate(),
    endDate: moment(currentDate).endOf("month").toDate(),
  });

  const [loadEvents, { data, loading, error }] = useMutation(LEAVE_EVENTS, {
    fetchPolicy: "network-only",
    pollInterval: usePolling(1000 * 60),
    variables: {
      roleFilters,
      currentDate: new Date(currentDate),
      viewedByAdmin: viewedByAdmin ? viewedByAdmin : false,
    },
  });

  if (error) {
    console.log({
      component: "useLeaveEvents",
      errorDescription:
        "Error occured when fetch data with GET_LEAVE_EVENTS query",
      error: error,
    });
  }

  const events = data?.leaveEvents || [];

  const parseDayType = (dayType) => {
    switch (dayType) {
      case ".":
        return "WORKDAY";
      case "P":
        return "REST_DAY";
      case "F":
        return "PAID_HOLLIDAY";
      default:
        return "OTHER_DAY_TYPE";
    }
  };

  let daysArray = contractCalendar?.split("") || [];

  const eventsByDays = [];

  daysArray.forEach((item, index) => {
    const date = moment(currentDate).startOf("month").add(index, "days");
    let newItem = {
      date: date.toDate(),
      dayType: parseDayType(item),
    };

    const filteredEvents = events.filter((filterItem) => {
      if (
        date.format("YYYY-MM-DD") >=
          moment(filterItem.start).format("YYYY-MM-DD") &&
        date.format("YYYY-MM-DD") <= moment(filterItem.end).format("YYYY-MM-DD")
      )
        return true;
      return false;
    });

    filteredEvents.forEach((item) => {
      const returningItem = { ...newItem, ...item };
      eventsByDays.push(returningItem);
      return returningItem;
    });

    return item;
  });

  let result = {
    data: events,
    eventsByDays: eventsByDays,
    loading: loading,
    error: error,
    loadEvents,
  };

  return result;
}
