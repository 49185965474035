import React from "react";
import Box from "@material-ui/core/Box";
import NewLeaveMessage from "my-leave/components/my-leave-new-message";
import LeaveRequestMessage from "my-leave/components/my-leave-request-message";
import LeaveRequestOwnMessage from "my-leave/components/my-leave-request-own-message";
import _ from "lodash";
import useCurrentUserState from "hooks/use-current-user-state";

export default function LeaveCardMessageBox({
  leaveRequest,
  loading,
  initialMessage,
  disabled,
}) {
  let { employeeCode } = useCurrentUserState();
  let messages = leaveRequest?.messages
    ? _.cloneDeep(leaveRequest.messages)
    : [];

  if (
    messages.length > 0 &&
    messages[0].createdBy === leaveRequest?.createdBy
  ) {
    initialMessage = messages[0].message;
    messages.shift();
  }

  return (
    <Box
      style={{
        backgroundColor: "#E3E5E6",
        minHeight: "96px",
        borderRadius: "8px",
      }}
    >
      <Box style={{ padding: "24px 24px 16px 24px" }}>
        {messages.map((messageItem, index) => (
          <Box key={`${index}_${messageItem._id}`}>
            {messageItem.createdBy === employeeCode ? (
              <LeaveRequestOwnMessage messageItem={messageItem} />
            ) : (
              <LeaveRequestMessage messageItem={messageItem} />
            )}
          </Box>
        ))}

        <NewLeaveMessage
          loading={loading}
          selectedRequest={leaveRequest?._id}
          selectedEmployeeId={employeeCode}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
}
