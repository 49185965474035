export default function getFilterValues(key, list) {
  const keyList = [
    ...new Set(
      list.map((item) => {
        const result = item[key];
        if (typeof result === "object") {
          return result.props.state;
        }
        return result;
      })
    ),
  ];
  return keyList;
}
