import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InlineIcon } from "@iconify/react";
import useStyles from "./application-collapse-header.style";

export default function ApplicationCollapseHeader({
    handleExpandClick,
    expanded,
    icon,
    dependentName,
    dependentTaxNumber,
    taxNumberLabel,
}) {
    const classes = useStyles(expanded);

    return (
        <OcErrorBoundary>
            <Box
                className={classes.filterContainer}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <Box
                        padding="10px 28px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <InlineIcon icon={icon} height="32px" color="#919699" />
                    </Box>
                    <Box fontSize="24px">{dependentName}</Box>
                    <Box width="32px"></Box>
                    <Box fontSize="16px" paddingTop="4px">
                        {taxNumberLabel}
                    </Box>
                    <Box width="16px"></Box>
                    <Box
                        fontSize="16px"
                        style={{ fontWeight: "bold" }}
                        paddingTop="5px"
                    >
                        {dependentTaxNumber ? dependentTaxNumber : ""}
                    </Box>
                </Box>
                <Box paddingRight="28px">
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        style={
                            expanded
                                ? {
                                      padding: 0,
                                      transform: "rotate(180deg)",
                                  }
                                : {
                                      padding: 0,
                                  }
                        }
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Box>
            </Box>
        </OcErrorBoundary>
    );
}
