import gql from "graphql-tag";

export const WORK_DAYS_SUBSCRIPTION = gql`
  subscription workDays(
    $contract: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    workDays(contract: $contract, startDate: $startDate, endDate: $endDate) {
      result
      workDays
      effectiveWorkDays
      effectiveWorkDaysByDays {
        date
        workDays
        effectiveWorkDay
      }
    }
  }
`;
