import gql from "graphql-tag";

export const CREATE_CHANGE_REQUEST = gql`
  mutation createChangeRequest(
    $locale: String!
    $typeGroupId: String
    $typeGroup: String
    $values: [ChangeRequestValues]
    $initialMessage: String
    $validFrom: Date!
  ) {
    createChangeRequest(
      locale: $locale
      typeGroupId: $typeGroupId
      typeGroup: $typeGroup
      values: $values
      initialMessage: $initialMessage
      validFrom: $validFrom
    )
  }
`;
