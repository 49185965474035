import gql from "graphql-tag";

export const GET_DEPENDENTS = gql`
  query dependents($contractCode: String!) {
    dependents(contractCode: $contractCode) {
      contractCode
      name
      mothersName
      taxNumber
      dependentType
      itemSerial
      birthDate
      birthPlace
      conceptionDate
      dependentType2
      adoptedDate
    }
  }
`;
