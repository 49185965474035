import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import OcPageTransition from "oc/components/oc-page-transition";

export default function NotFound() {
  const history = useHistory();
  function handleBack() {
    history.push("/");
  }
  return (
    <OcPageTransition>
      <Box
        id="not-found-page"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" color="primary">
          404
        </Typography>
        <Box paddingTop="32px">
          <Typography variant="h4">
            Sajnáljuk, de nem találjuk a keresett oldalt
          </Typography>
        </Box>

        <Box
          marginTop="32px"
          onClick={handleBack}
          style={{ cursor: "pointer" }}
        >
          <Typography variant="body1" color="primary">
            Vissza a főoldalra
          </Typography>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
