import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import OcDatePicker from "oc/components/oc-date-picker";
import getDateFormatByLocale from "oc/utils/get-date-format-by-locale";

const OcFormEditorValidFromSelector = ({
  name,
  value,
  label,
  onChange,
  def,
}) => {
  const { t, i18n } = useTranslation();
  const handleChange = (date) => {
    onChange(name, date);
  };

  let minDate = "";
  if (
    def?.type === "permanentAddress" ||
    def?.type === "temporaryAddress" ||
    def?.type === "serviceAddress"
  ) {
    minDate = new Date();
  }
  return (
    <Box paddingTop="36px">
      <OcDatePicker
        id={name}
        name={name}
        label={t(label)}
        onChange={handleChange}
        value={value}
        minDate={minDate}
        format={getDateFormatByLocale(i18n.language)}
      />
    </Box>
  );
};

export default OcFormEditorValidFromSelector;
