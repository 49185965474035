import gql from "graphql-tag";

export const GET_PERIOD = gql`
  query getPeriod($contractCode: String!, $year: String!, $month: String!) {
    getPeriod(contractCode: $contractCode, year: $year, month: $month) {
      startDate
      endDate
      hoursByLaw
      hoursByFact
    }
  }
`;
