import gql from "graphql-tag";

export const SEND_GROUP_PASSWORDS = gql`
  mutation sendGroupPasswords(
    $roleFilters: RoleFilterInput!
    $replaceExistingPasswords: Boolean!
    $locale: String!
  ) {
    sendGroupPasswords(
      roleFilters: $roleFilters
      replaceExistingPasswords: $replaceExistingPasswords
      locale: $locale
    ) {
      processed
      added
      errors {
        employee
        employeeName
        contract
        error
      }
    }
  }
`;
