import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: ({ align }) => ({
    float: align === "right" ? "left" : "right",
    height: 28,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    aligneItems: "center",
  }),
  icon: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: theme.palette.gray60.main,
  },
  spacer: {
    float: "right",
    minWidth: 56,
  },
}));

export default useStyles;
