import React from "react";
import MaterialCard from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useClientState from "hooks/use-oc-client-state";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import useProperty from "hooks/use-oc-property";
import MyHrAdminImportCollapseLabel from "my-hr/components/my-hr-admin-users-import-collapse-label";
import ImportDialog from "my-hr/components/my-hr-admin-users-import-dialog";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { Typography } from "@material-ui/core";
import OcSwitch from "oc/components/oc-form-switch";

export default function MyHrAdminUsersImportSSHRCard() {
  const { t } = useTranslation();

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const [showDialog, setShowDialog] = useClientState(
    "showSSHRUsersImportDialog",
    false
  );

  const { data: importLockedData } = useProperty({
    resource: "system",
    key: "importLocked",
    pollInterval: showDialog ? 1000 : 0,
  });

  let importLocked = importLockedData === "false" ? false : true;

  const [showImportUsersCollapse, setShowImportUsersCollapse] = useClientState(
    "showImportSSHRUsersRoleCollapse",
    false
  );

  function handleShowDialog() {
    setShowDialog(!showDialog);
  }

  const handleExpandClick = () => {
    setShowImportUsersCollapse(!showImportUsersCollapse);
  };

  function handleCloseImportDialog() {
    setShowDialog(false);
  }

  function handleFinishImport() {
    updateProperty({
      variables: {
        resource: "system",
        key: "importType",
        value: "jdolber",
      },
    });
    setShowDialog(false);
  }

  const { data: loginType } = useProperty({
    resource: "system",
    key: "loginType",
  });

  const [automaticPasswordGeneration, setAutomaticPasswordGeneration] =
    React.useState(false);

  return (
    <div>
      <ImportDialog
        onShowDialog={handleShowDialog}
        showDialog={showDialog}
        finishImport={handleFinishImport}
        importType="sshr"
        onCloseDialog={handleCloseImportDialog}
        automaticPasswordGeneration={automaticPasswordGeneration}
      />
      <MaterialCard elevation={0}>
        <MyHrAdminImportCollapseLabel
          expanded={showImportUsersCollapse}
          handleExpandClick={handleExpandClick}
          label={t("IMPORT_USERS_FROM_SSHR")}
        />
        <Collapse
          id="my-hr-users-import-card"
          in={showImportUsersCollapse}
          timeout="auto"
          unmountOnExit
        >
          <Box padding="0px 16px">
            <Box style={{ borderTop: "2px solid #C6CACC" }} />
            {loginType === "legacy" && (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  padding="36px 0px 0px 0px"
                  paddingBottom="12px"
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    paddingBottom="12px"
                  >
                    <OcSwitch
                      id="automaticPasswordGeneration"
                      checked={automaticPasswordGeneration}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e) =>
                        setAutomaticPasswordGeneration(e.target.checked)
                      }
                      disabled={false}
                    />
                    <Box width="16px" />
                    <Typography variant="body1">
                      {t("AUTOMATIC_PASSWORD_GENERATION")}
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    {t("AUTOMATIC_PASSWORD_GENERATION_INFO")}
                  </Typography>
                </Box>
              </>
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              padding="38px 0px"
              style={{
                position: "relative",
              }}
            >
              <Button
                id="my-hr-users-import-button"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setShowDialog(true)}
                disabled={importLocked}
              >
                {t("IMPORT_USERS")}
              </Button>
            </Box>
            {importLocked ? (
              <>
                <Typography variant="caption">
                  {t("IMPORT_SSHR_USERS_LOCKED_INFO")}
                </Typography>
                <Box height="32px" />
              </>
            ) : (
              <>
                <Typography variant="caption">
                  {t("IMPORT_SSHR_USERS_TEXT_INFO")}
                </Typography>
                <Box height="32px" />
              </>
            )}
          </Box>
        </Collapse>
      </MaterialCard>
    </div>
  );
}
