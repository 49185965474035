import React from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import { InlineIcon } from "@iconify/react";
import PDFIcon from "@iconify/icons-mdi/file-pdf-box-outline";
import DownloadIcon from "@iconify/icons-mdi/download";
import Link from "@material-ui/core/Link";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useCurrentUserState from "hooks/use-current-user-state";
import { useTheme } from "@material-ui/styles";

function ListItem({ icon, label, href, id }) {
  const theme = useTheme();
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        style={{
          height: "72px",
          backgroundColor: theme.palette.gray20.main,
          border: `1px solid ${theme.palette.gray40.main}`,
          display: "flex",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="40px"
          height="40px"
          style={{ borderRadius: "50%", marginLeft: "7px" }}
        >
          <InlineIcon icon={PDFIcon} width="18px" height="18px" />
        </Box>

        <Box paddingLeft="10px" flexGrow={1}>
          {label}
        </Box>
        <Link
          id={label.replace(/ /g, "_")}
          href={href}
          download
          style={{
            textDecoration: "none",
            padding: "0px",
            margin: "0px",
            outline: "none",
          }}
        >
          <Box
            id={id}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="40px"
            height="40px"
            style={{
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <InlineIcon
              icon={DownloadIcon}
              width="18px"
              height="18px"
              style={{ color: "#4d4d4d", marginRight: "10px" }}
            />
          </Box>
        </Link>
      </Box>
      <Box height="16px" />
    </>
  );
}

export default function MyHrUserGuides() {
  const { t } = useTranslation();
  let { contractCode } = useCurrentUserState();
  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  let isMyHrExaminer =
    rolesData?.find(
      (item) => item.product === "MyHR" && item.role === "examiner"
    ) && false;

  let isMyHrAdmin = rolesData?.find(
    (item) => item.product === "MyHR" && item.role === "admin"
  )
    ? true
    : false;

  let isMyLeaveUser =
    rolesData?.find(
      (item) => item.product === "MyLeave" && item.role === "user"
    ) ? true
      : false;

  let isMyLeaveAdmin =
    rolesData?.find(
      (item) => item.product === "MyLeave" && item.role === "admin"
    ) && false;

  let isMyLeaveExaminer =
    rolesData?.find(
      (item) => item.product === "MyLeave" && item.role === "examiner"
    ) && false;

  let isMyLeaveViewer =
    rolesData?.find(
      (item) => item.product === "MyLeave" && item.role === "viewer"
    ) && false;

  let isMyCafeteriaUser =
    rolesData?.find(
      (item) => item.product === "MyCafeteria" && item.role === "user"
    ) && false;

  let isMyCafeteriaAdmin =
    rolesData?.find(
      (item) => item.product === "MyCafeteria" && item.role === "admin"
    ) && false;

  let isMyTimeUser = rolesData?.find(
    (item) => item.product === "MyTime" && item.role === "user"
  )
    ? true
    : false;

  let isMyTimeExaminer = rolesData?.find(
    (item) => item.product === "MyTime" && item.role === "examiner"
  )
    ? true
    : false;

  let isMyTimeAdmin = rolesData?.find(
    (item) => item.product === "MyTime" && item.role === "admin"
  )
    ? true
    : false;

  return (
    <OcPageTransition>
      <Box>
        <Box
          height="80px"
          style={{ backgroundColor: "#cc0033" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="14px"
        >
          <Box style={{ color: "white", fontSize: "24px" }}>
            {t("USER_GUIDES")}
          </Box>
        </Box>
        <Box padding="16px">
          <Box width="520px">
            <OcCard label={t("DOWNLOADS")} loading={false}>
              <>
                <Box>
                  <ListItem
                    label={t("MY_HR_USER_GUIDE")}
                    href="/manual/Orgware Connect MyHR felhasználói kézikönyv csökkentett.pdf"
                  />
                </Box>
                {isMyHrAdmin && (
                  <Box id="my-hr-user-guides-admin">
                    <ListItem
                      label={t("MY_HR_ADMIN_GUIDE")}
                      href="/manual/Orgware Connect adminisztrátor kézikönyv csökkentett.pdf"
                    />
                  </Box>
                )}
                {isMyLeaveUser && (
                  <Box id="my-hr-user-guides-leave-user">
                    <ListItem
                      label={t("MY_LEAVE_USER_GUIDE")}
                      href="/manual/Orgware Connect MyLeave felhasználói kézikönyv csökkentett.pdf"
                    />
                  </Box>
                )}
                {isMyLeaveAdmin && (
                  <Box id="my-hr-user-guides-leave-admin">
                    <ListItem
                      label={t("MY_LEAVE_ADMIN_GUIDE")}
                      href="/manual/Orgware Connect MyLeave adminisztrátor kézikönyv.pdf"
                    />
                  </Box>
                )}
                {isMyLeaveExaminer && (
                  <Box id="my-hr-user-leave-examiner">
                    <ListItem
                      label={t("MY_LEAVE_EXAMINER_GUIDE")}
                      href="/manual/Orgware Connect elbíráló kézikönyv.pdf"
                    />
                  </Box>
                )}
                {isMyLeaveViewer && (
                  <Box id="my-hr-user-leave-viewer">
                    <ListItem
                      label={t("MY_LEAVE_VIEWER_GUIDE")}
                      href="/manual/Orgware Connect MyLeave megtekintő kézikönyv.pdf"
                    />
                  </Box>
                )}
                {isMyCafeteriaAdmin && (
                  <Box id="my-cafeteria-admin">
                    <ListItem
                      label={t("MY_CAFETERIA_ADMIN_GUIDE")}
                      href="/manual/Orgware Connect MyCafeteria adminisztrátor kézikönyv.pdf"
                    />
                  </Box>
                )}
                {isMyCafeteriaUser && (
                  <Box id="my-cafeteria-user">
                    <ListItem
                      label={t("MY_CAFETERIA_USER_GUIDE")}
                      href="/manual/Orgware Connect MyCafeteria felhasználói kézikönyv.pdf"
                    />
                  </Box>
                )}
                {isMyHrExaminer && (
                  <Box id="myhr-examiner">
                    <ListItem
                      label={t("MYHR_EXAMINER_USER_GUIDE")}
                      href="/manual/OrgwareConnect_MyHR_elbiralo_kezikonyv.pdf"
                    />
                  </Box>
                )}
                {isMyLeaveUser && (
                  <Box id="my-hr-user-guides-leave-user">
                    <ListItem
                      label={t("MY_LEAVE_USER_GUIDE")}
                      href="/manual/OrgwareConnect_MyLeave_felhasznaloi_kezikonyv.pdf"
                    />
                  </Box>
                )}
                {isMyLeaveAdmin && (
                  <Box id="my-hr-user-guides-leave-admin">
                    <ListItem
                      label={t("MY_LEAVE_ADMIN_GUIDE")}
                      href="/manual/OrgwareConnect_MyLeave_adminisztrator_kezikonyv.pdf"
                    />
                  </Box>
                )}
                {isMyLeaveExaminer && (
                  <Box id="my-hr-user-leave-examiner">
                    <ListItem
                      label={t("MY_LEAVE_EXAMINER_GUIDE")}
                      href="/manual/OrgwareConnect_MyLeave_elbiralo_kezikonyv.pdf"
                    />
                  </Box>
                )}
                {isMyLeaveViewer && (
                  <Box id="my-hr-user-leave-viewer">
                    <ListItem
                      label={t("MY_LEAVE_VIEWER_GUIDE")}
                      href="/manual/OrgwareConnect_MyLeave_megtekinto_kezikonyv.pdf"
                    />
                  </Box>
                )}
                {isMyCafeteriaAdmin && (
                  <Box id="my-cafeteria-admin">
                    <ListItem
                      label={t("MY_CAFETERIA_ADMIN_GUIDE")}
                      href="/manual/OrgwareConnect_MyCafeteria_adminisztrator_kezikonyv.pdf"
                    />
                  </Box>
                )}
                {isMyCafeteriaUser && (
                  <Box id="my-cafeteria-user">
                    <ListItem
                      label={t("MY_CAFETERIA_USER_GUIDE")}
                      href="/manual/OrgwareConnect_MyCafeteria_felhasznaloi_kezikonyv.pdf"
                    />
                  </Box>
                )}
                {isMyTimeUser && (
                  <Box id="mytime-user">
                    <ListItem
                      label={t("MYTIME_USER_GUIDE")}
                      href="/manual/OrgwareConnect_MyTime_felhasznaloi_kezikonyv.pdf"
                    />
                  </Box>
                )}
                {isMyTimeExaminer && (
                  <Box id="mytime-user">
                    <ListItem
                      label={t("MYTIME_EXAMINER_GUIDE")}
                      href="/manual/OrgwareConnect_MyTime_elbiraloi_kezikonyv.pdf"
                    />
                  </Box>
                )}
                {isMyTimeAdmin && (
                  <Box id="mytime-user">
                    <ListItem
                      label={t("MYTIME_ADMIN_GUIDE")}
                      href="/manual/OrgwareConnect_MyTime_adminisztratori_kezikonyv.pdf"
                    />
                  </Box>
                )}
              </>
            </OcCard>
          </Box>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
