import gql from "graphql-tag";

export const GET_FILTERED_LIST_COUNT = gql`
  query filteredListCount(
    $roleFilters: RoleFilterInput
    $isActive: Boolean
    $withNoUsers: Boolean
  ) {
    filteredListCount(
      roleFilters: $roleFilters
      isActive: $isActive
      withNoUsers: $withNoUsers
    )
  }
`;
