import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: ({ select }) => ({
    cursor: select === "row" ? "pointer" : "auto",
    "&:hover": {
      backgroundColor:
        select === "row" ? theme.palette.gray20.main : "transparent",
    },
  }),
}));

export default useStyles;
