import React from "react";
import "moment/locale/hu";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import LeftArrowIcon from "@iconify/icons-mdi/arrow-left";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import chevronLeft from "@iconify/icons-mdi/chevron-left";
import chevronRight from "@iconify/icons-mdi/chevron-right";
import { useTheme } from "@material-ui/core/styles";

export default function MyHrMonthSelector({
  year,
  month,
  onClose,
  previousonClick,
  nextOnClick,
  backChevron,
}) {
  const theme = useTheme();

  return (
    <Box>
      <Grow in={true}>
        <Box
          height="64px"
          bgcolor={`${theme.palette.primary.main}`}
          borderRadius="8px"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box
            width="24px"
            height="24px"
            color="white"
            paddingLeft="22px"
            style={{ cursor: "pointer" }}
            id="my-hr-month-svg-click"
          >
            {backChevron === true && (
              <InlineIcon
                icon={LeftArrowIcon}
                style={{
                  color: "white",
                  width: "24px",
                  height: "24px",
                }}
                onClick={onClose}
              />
            )}
          </Box>
          <Box
            flexGrow={1}
            textAlign="center"
            color="white"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
            }}
          >
            <InlineIcon
              id="my-hr-month-left"
              icon={chevronLeft}
              width="24px"
              color={`${theme.palette.white.main}`}
              style={{ cursor: "pointer", marginRight: "21px" }}
              onClick={previousonClick}
            />
            <Typography
              variant="h4"
              style={{ color: theme.palette.white.main }}
            >
              {year}. {month}
            </Typography>
            <InlineIcon
              id="my-hr-month-right"
              icon={chevronRight}
              width="24px"
              color={`${theme.palette.white.main}`}
              style={{ cursor: "pointer", marginLeft: "21px" }}
              onClick={nextOnClick}
            />
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}
