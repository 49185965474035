import gql from "graphql-tag";

export const CALCULATE_NEW_CAFETERIA_STATEMENT_ITEM_AMOUNT = gql`
  query calculateNewCafeteriaStatementItemAmounts(
    $contractCode: String!
    $currentItemStartDate: String
    $menuItemCode: String
    $startDate: String
    $endDate: String
    $type: String
    $amount: Float
    $bruttoAmount: Float
    $plan: Boolean
    $szepCardApplicationAmount: Float
  ) {
    calculateNewCafeteriaStatementItemAmounts(
      contractCode: $contractCode
      currentItemStartDate: $currentItemStartDate
      menuItemCode: $menuItemCode
      startDate: $startDate
      endDate: $endDate
      type: $type
      amount: $amount
      bruttoAmount: $bruttoAmount
      plan: $plan
      szepCardApplicationAmount: $szepCardApplicationAmount
    ) {
      result
      messages
      resultObject {
        contractCode
        menuItemCode
        startDate
        endDate
        itemSerial
        type
        amount
        cost
        origin
      }
    }
  }
`;
