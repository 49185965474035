import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useStyles from "./my-leave-request-own-message.style";

const LeaveRequestOwnMessage = ({ messageItem }) => {
  const classes = useStyles();

  const { i18n } = useTranslation();
  let locale = i18n.language.substr(0, 2).toLowerCase();

  let createdAt = moment(messageItem?.createdAt)
    .locale(locale)
    .format("MMM.DD HH:mm");

  return (
    <div className={classes.messageItemContainer}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div className={classes.messageItemInnerContainer}>
          <div className={classes.smallText}>{createdAt}</div>
        </div>
        <div className={classes.messageItem}>{messageItem.message}</div>
      </div>
    </div>
  );
};

LeaveRequestOwnMessage.propTypes = {
  /** Message item to show */
  messageItem: PropTypes.object,
};

export default LeaveRequestOwnMessage;
