import { useContext } from "react";
import { useCallback } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import ApolloClientContext from "context/apollo-client-context";

function encode(value, type) {
  switch (type) {
    case "object":
      return JSON.stringify(value);
    case "boolean":
      return value.toString();
    default:
      return value;
  }
}

function decode(value, type) {
  switch (type) {
    case "object":
      return JSON.parse(value);
    case "boolean":
      return value === "true" ? true : false;
    default:
      return value;
  }
}

function merge(prevState, newState, type) {
  switch (type) {
    case "object":
      if (newState instanceof Date) {
        return newState;
      }
      return Object.assign(prevState, newState);
    default:
      return newState;
  }
}

export default function useOcClientState(key, initialValue) {
  const QUERY = gql`
    query get${key} {
      ${key} @client {
        value
      }
    }
  `;

  let type = typeof initialValue;
  const { client } = useContext(ApolloClientContext);
  const { data } = useQuery(QUERY);

  let result = initialValue;

  if (type === "object") {
    if (initialValue instanceof Date) {
      result = new Date(result);
    }
    let keys = Object.keys(initialValue);
    keys.forEach((item) => {
      if (initialValue[item] instanceof Date) {
        result[item] = new Date(result[item]);
      }
    });
  }

  if (data) {
    result = decode(data[key].value, type);
  } else {
    let encoded = encode(initialValue, type);
    client.writeQuery({
      query: QUERY,
      data: {
        [key]: {
          __typename: key,
          value: encoded,
        },
      },
    });
  }

  const setData = useCallback(
    (value) => {
      let newState = merge(result, value, type);
      let valueToWrite = encode(newState, type);
      client.writeQuery({
        query: QUERY,
        data: {
          [key]: {
            __typename: key,
            value: valueToWrite,
          },
        },
      });
    },
    [client, key, QUERY, result, type]
  );

  return [result, setData];
}
