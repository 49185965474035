import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MyTimeSelect from "my-time/components/my-time-select";

const MyTimeSelectMyTimeType = ({
  data,
  selectedMyTimeType,
  onSelectMyTimeType,
}) => {
  const { t } = useTranslation();
  const [showMyTimeSelect, setShowMyTimeSelect] = useState(false);

  if (data?.showMyTimeTypeSelect && !showMyTimeSelect) {
    setShowMyTimeSelect(true);
  }
  
  const selectableMyTimeTypes = [
    { label: t("STAND_BY_CALL"), value: "STAND_BY_CALL" },
    { label: t("OVERTIME"), value: "OVERTIME" },
  ];

  return (
    <MyTimeSelect
      id="id-my-time-type-select"
      label={t("MY_TIME_TYPE")}
      show={showMyTimeSelect}
      value={selectedMyTimeType || ""}
      setValue={onSelectMyTimeType}
      values={selectableMyTimeTypes}
    />
  );
};

export default MyTimeSelectMyTimeType;
