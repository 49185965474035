import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useFramePeriod from "my-time/hooks/use-frame-period";
import { useTheme } from "@material-ui/styles";
import MyTimeWorkingFrameBox from "my-time/components/my-time-working-frame-box";

const MyTimeWorkingTimeFrame = ({ contractCode, year, month }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data, loading } = useFramePeriod({
    contractCode,
    year: year,
    month: month,
  });

  const formatValue = (value) => {
    if (value % 1 !== 0) {
      return value?.toFixed(3);
    } else {
      return value?.toString();
    }
  };

  const mergedValue = formatValue(data?.hoursByFact) + " / " + data?.hoursByLaw

  return (
    <Box>
      <Box>
        <MyTimeWorkingFrameBox label={`${t("WORKED_TIME_FRAME")} / ${t("WORK_TIME_FRAME")}`}
          value={mergedValue}
          color={theme.palette.greenBase.main}
          loading={loading}
          framePeriodData={data}
          typeName="framePeriod"
        />
      </Box>
    </Box>
  );
};

export default MyTimeWorkingTimeFrame;
