import moment from "moment";

export const timeDifference = ({ startTime, endTime }) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));
  const minutes = duration.asMinutes();
  return minutes;
};

export default timeDifference;
