import { useTranslation } from "react-i18next";
import { useState } from "react";
import Validator from "fastest-validator";

const validator = new Validator();

export default function useOcEmailInput() {
  const { t } = useTranslation();

  const emailSchema = {
    email: {
      type: "email",
      messages: {
        email: t("ERROR_MESSAGE_WRONG_EMAIL_FORMAT"),
        emailEmpty: t("ERROR_MESSAGE_EMAIL_IS_REQUIRED"),
      },
    },
  };

  const checkEmail = validator.compile(emailSchema);

  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  function isValid() {
    let isValid = checkEmail({
      email: value,
    });
    return isValid !== true ? false : true;
  }

  function validate() {
    let isValidated = checkEmail({
      email: value,
    });
    if (isValidated !== true) {
      isValidated.forEach((item) => {
        if (item.field === "email") {
          setError(true);
          setHelperText(item.message);
        }
      });
    }
  }

  return {
    value,
    setValue,
    error,
    setError,
    helperText,
    setHelperText,
    isValid,
    validate,
  };
}
