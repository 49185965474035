import gql from "graphql-tag";

export const GET_STREET_TYPES = gql`
  query streetTypes {
    streetTypes {
      code
      name
    }
  }
`;
