import PropTypes from "prop-types";
import React from "react";
import { findDOMNode } from "react-dom";
import clsx from "clsx";
import DateContentRow from "react-big-calendar/lib/DateContentRow";
import Popup from "react-big-calendar/lib/Popup";
import Header from "react-big-calendar/lib/Header";
import DateHeader from "react-big-calendar/lib/DateHeader";
import * as dates from "react-big-calendar/lib/utils/dates";
import chunk from "lodash/chunk";
import { navigate, views } from "react-big-calendar/lib/utils/constants";
import { notify } from "react-big-calendar/lib/utils/helpers";
import getPosition from "dom-helpers/position";
import * as animationFrame from "dom-helpers/animationFrame";
import Overlay from "react-overlays/Overlay";
import { sortEvents, eventsForWeek, isToday } from "oc/utils/calendar-utils";

class TimelineView extends React.Component {
  constructor(...args) {
    super(...args);

    this._bgRows = [];
    this._pendingSelection = [];
    this.slotRowRef = React.createRef();
    this.state = {
      rowLimit: 5,
      needLimitMeasure: true,
    };
  }

  UNSAFE_componentWillReceiveProps({ date }) {
    this.setState({
      needLimitMeasure: !dates.eq(date, this.props.date, "month"),
    });
  }

  componentDidMount() {
    let running;

    if (this.state.needLimitMeasure) this.measureRowLimit(this.props);

    window.addEventListener(
      "resize",
      (this._resizeListener = () => {
        if (!running) {
          animationFrame.request(() => {
            running = false;
            this.setState({ needLimitMeasure: true }); //eslint-disable-line
          });
        }
      }),
      false
    );
  }

  componentDidUpdate() {
    if (this.state.needLimitMeasure) this.measureRowLimit(this.props);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._resizeListener, false);
  }

  getContainer = () => {
    return findDOMNode(this);
  };

  render() {
    let { date, localizer, className, events, accessors } = this.props,
      month = dates.visibleDays(date, localizer),
      weeks = chunk(month, month.length);

    this._weekCount = weeks.length;

    events = eventsForWeek(
      events,
      month[0],
      month[month.length - 1],
      accessors
    );

    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const titleA = a.start.toUpperCase();
      const titleB = b.start.toUpperCase();

      let comparison = 0;
      if (titleA > titleB) {
        comparison = 1;
      } else if (titleA < titleB) {
        comparison = -1;
      }
      return comparison;
    }

    events.sort(compare);

    return (
      <div
        className={clsx("rbc-month-view", className)}
      //style={{ height: "auto" }}
      >
        <div className="rbc-row rbc-month-header">
          {this.renderHeaders(month, date)}
        </div>
        {weeks.map(this.renderMonth)}
        {this.props.popup && this.renderOverlay()}
      </div>
    );
  }

  renderMonth = (month, monthIdx) => {
    let {
      events,
      components,
      selectable,
      getNow,
      selected,
      date,
      localizer,
      longPressThreshold,
      accessors,
      getters,
    } = this.props;

    const { needLimitMeasure, rowLimit } = this.state;
    events = eventsForWeek(
      events,
      month[0],
      month[month.length - 1],
      accessors
    );

    events.sort((a, b) => sortEvents(a, b, accessors));

    return (
      <DateContentRow
        key={monthIdx}
        ref={monthIdx === 0 ? this.slotRowRef : undefined}
        container={this.getContainer}
        className="rbc-month-row"
        getNow={getNow}
        date={date}
        range={month}
        events={events}
        maxRows={rowLimit}
        selected={selected}
        selectable={selectable}
        components={components}
        accessors={accessors}
        getters={getters}
        localizer={localizer}
        renderHeader={this.readerDateHeading}
        renderForMeasure={needLimitMeasure}
        onShowMore={this.handleShowMore}
        onSelect={this.handleSelectEvent}
        onDoubleClick={this.handleDoubleClickEvent}
        onSelectSlot={this.handleSelectSlot}
        longPressThreshold={longPressThreshold}
        rtl={this.props.rtl}
      //onCustomCellBackground={this?.props?.onCustomCellBackground}
      />
    );
  };

  readerDateHeading = ({ date, className, ...props }) => {
    let { date: currentDate, getDrilldownView, localizer } = this.props;

    let isOffRange = dates.month(date) !== dates.month(currentDate);
    let isCurrent = dates.eq(date, currentDate, "day");
    let drilldownView = getDrilldownView(date);
    let label = localizer.format(date, "dateFormat");
    let DateHeaderComponent = this.props.components.dateHeader || DateHeader;

    return (
      <div
        {...props}
        className={clsx(
          className,
          isOffRange && "rbc-off-range",
          isCurrent && "rbc-current",
          //this.props.onCustomCellBackground(currentDate)
        )}
      >
        <DateHeaderComponent
          label={label}
          date={date}
          drilldownView={drilldownView}
          isOffRange={isOffRange}
          onDrillDown={(e) => this.handleHeadingClick(date, drilldownView, e)}
        />
      </div>
    );
  };

  renderHeaders(row, date) {
    let { date: currentDate, localizer, components } = this.props;
    let first = row[0];
    let last = row[row.length - 1];
    let HeaderComponent = components.header || Header;

    const defineClass = (day) => {
      let now = date;
      if (isToday(day)) {
        return "rbc-today";
      }
      if (dates.month(now) !== dates.month(day)) {
        return "rbc-off-range-bg";
      }
    };

    return dates.range(first, last, "day").map((day, idx) => {
      let isOffRange = dates.month(day) !== dates.month(currentDate);
      return (
        <div
          key={"header_" + idx}
          className={clsx(
            "rbc-header",
            defineClass(day),
            //this.props.onCustomCellBackground(day)
          )}
        >
          <HeaderComponent
            date={day}
            localizer={localizer}
            label={localizer.format(day, "weekdayFormat")}
            isOffRange={isOffRange}
          />
        </div>
      );
    });
  }

  renderOverlay() {
    let overlay = (this.state && this.state.overlay) || {};
    let { accessors, localizer, components, getters, selected, popupOffset } =
      this.props;

    return (
      <Overlay
        rootClose
        placement="bottom"
        show={!!overlay.position}
        onHide={() => this.setState({ overlay: null })}
        target={() => overlay.target}
      >
        {({ props }) => (
          <Popup
            {...props}
            popupOffset={popupOffset}
            accessors={accessors}
            getters={getters}
            selected={selected}
            components={components}
            localizer={localizer}
            position={overlay.position}
            show={this.overlayDisplay}
            events={overlay.events}
            slotStart={overlay.date}
            slotEnd={overlay.end}
            onSelect={this.handleSelectEvent}
            onDoubleClick={this.handleDoubleClickEvent}
            handleDragStart={this.props.handleDragStart}
          />
        )}
      </Overlay>
    );
  }

  measureRowLimit() {
    this.setState({
      needLimitMeasure: false,
      rowLimit: this.slotRowRef.current.getRowLimit(),
    });
  }

  handleSelectSlot = (range, slotInfo) => {
    this._pendingSelection = this._pendingSelection.concat(range);

    clearTimeout(this._selectTimer);
    this._selectTimer = setTimeout(() => this.selectDates(slotInfo));
  };

  handleHeadingClick = (date, view, e) => {
    e.preventDefault();
    this.clearSelection();
    notify(this.props.onDrillDown, [date, view]);
  };

  handleSelectEvent = (...args) => {
    this.clearSelection();
    notify(this.props.onSelectEvent, args);
  };

  handleDoubleClickEvent = (...args) => {
    this.clearSelection();
    notify(this.props.onDoubleClickEvent, args);
  };

  handleShowMore = (events, date, cell, slot, target) => {
    const { popup, onDrillDown, onShowMore, getDrilldownView } = this.props;
    //cancel any pending selections so only the event click goes through.
    this.clearSelection();

    if (popup) {
      let position = getPosition(cell, findDOMNode(this));

      this.setState({
        overlay: { date, events, position, target },
      });
    } else {
      notify(onDrillDown, [date, getDrilldownView(date) || views.DAY]);
    }

    notify(onShowMore, [events, date, slot]);
  };

  overlayDisplay = () => {
    this.setState({
      overlay: null,
    });
  };

  selectDates(slotInfo) {
    let slots = this._pendingSelection.slice();

    this._pendingSelection = [];

    slots.sort((a, b) => +a - +b);

    notify(this.props.onSelectSlot, {
      slots,
      start: slots[0],
      end: slots[slots.length - 1],
      action: slotInfo.action,
      bounds: slotInfo.bounds,
      box: slotInfo.box,
    });
  }

  clearSelection() {
    clearTimeout(this._selectTimer);
    this._pendingSelection = [];
  }
}

TimelineView.propTypes = {
  events: PropTypes.array.isRequired,
  date: PropTypes.instanceOf(Date),

  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),

  step: PropTypes.number,
  getNow: PropTypes.func.isRequired,

  scrollToTime: PropTypes.instanceOf(Date),
  rtl: PropTypes.bool,
  width: PropTypes.number,

  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object.isRequired,

  selected: PropTypes.object,
  selectable: PropTypes.oneOf([true, false, "ignoreEvents"]),
  longPressThreshold: PropTypes.number,

  onNavigate: PropTypes.func,
  onSelectSlot: PropTypes.func,
  onSelectEvent: PropTypes.func,
  onDoubleClickEvent: PropTypes.func,
  onShowMore: PropTypes.func,
  onDrillDown: PropTypes.func,
  getDrilldownView: PropTypes.func.isRequired,

  popup: PropTypes.bool,
  handleDragStart: PropTypes.func,

  popupOffset: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  ]),
};

TimelineView.range = (date, { localizer }) => {
  let start = dates.firstVisibleDay(date, localizer);
  let end = dates.lastVisibleDay(date, localizer);
  return { start, end };
};

TimelineView.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -1, "month");

    case navigate.NEXT:
      return dates.add(date, 1, "month");

    default:
      return date;
  }
};

TimelineView.title = (date, { localizer }) =>
  localizer.format(date, "monthHeaderFormat");

export default TimelineView;
