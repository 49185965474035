import React from "react";
import MyCafeteriaVbkApplicationCardPrint from "my-cafeteria/components/my-cafeteria-vbk-application-card-print";

class MyCafeteriaVbkApplication extends React.PureComponent {
    render() {
        return <MyCafeteriaVbkApplicationCardPrint />;
    }
}

export default MyCafeteriaVbkApplication;
