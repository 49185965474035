import moment from "moment";

export const useProtectedPeriodsData = ({ list, data }) => {
  const currentDayData = list?.filter(
    (filterItem) =>
      filterItem?.myTimeType &&
      moment(filterItem?.date).format("YYYY-MM-DD") ===
        moment(data?.date).format("YYYY-MM-DD")
  );

  const previousDayData = list?.filter(
    (filterItem) =>
      filterItem?.myTimeType &&
      moment(filterItem?.startTime).format("YYYY-MM-DD") <
        moment(data?.date).format("YYYY-MM-DD") &&
      moment(filterItem?.endTime).format("YYYY-MM-DD") ===
        moment(data?.date).format("YYYY-MM-DD")
  );

  const nextDayData = list?.filter(
    (filterItem) =>
      filterItem?.myTimeType &&
      moment(filterItem?.startTime).format("YYYY-MM-DD") ===
        moment(data?.date).add(1, "days").format("YYYY-MM-DD") &&
      moment(filterItem?.endTime).format("YYYY-MM-DD") ===
        moment(data?.date).add(1, "days").format("YYYY-MM-DD")
  );

  const protectedTimeRanges = [];
  previousDayData?.forEach((item) => {
    protectedTimeRanges.push({
      startTime: moment(data?.date).startOf("day").toDate(),
      endTime: moment(item?.endTime).toDate(),
      myTimeType: item?.myTimeType,
    });
  });

  currentDayData?.forEach((item) => {
    if (
      item?.myTimeType === "STAND_BY" &&
      data?.myTimeType === "STAND_BY_CALL"
    ) {
      const startTime = moment(item?.startTime).toDate();
      const endTime = moment(item?.endTime).toDate();
      if (
        moment(startTime).format("YYYY-MM-DD") <
        moment(endTime).format("YYYY-MM-DD")
      ) {
        const dataStart = {
          startTime: moment(startTime).startOf("day").toDate(),
          endTime: moment(startTime).toDate(),
          myTimeType: item?.myTimeType,
        };
        const dataEnd = {
          startTime: moment(endTime).toDate(),
          endTime: moment(endTime).endOf("day").toDate(),
          myTimeType: item?.myTimeType,
        };
        protectedTimeRanges.push(dataStart);
        protectedTimeRanges.push(dataEnd);
      } else {
        protectedTimeRanges.push({
          startTime: moment(startTime).startOf("days").toDate(),
          endTime: moment(startTime).toDate(),
          myTimeType: item?.myTimeType,
        });
        protectedTimeRanges.push({
          startTime: moment(endTime).toDate(),
          endTime: moment(endTime).endOf("days").toDate(),
          myTimeType: item?.myTimeType,
        });
      }
    } else {
      protectedTimeRanges.push({
        startTime: moment(item.startTime).toDate(),
        endTime: moment(item.endTime).toDate(),
        myTimeType: item?.myTimeType,
      });
    }
  });

  nextDayData?.forEach((item) => {
    protectedTimeRanges.push({
      startTime: moment(item.startTime).toDate(),
      endTime: moment(item.endTime).endOf("day").toDate(),
      myTimeType: item?.myTimeType,
    });
  });

  return { currentDayData, previousDayData, nextDayData, protectedTimeRanges };
};

export default useProtectedPeriodsData;
