import gql from "graphql-tag";

export const CREATE_CHANGE_MESSAGE = gql`
  mutation createChangeMessage(
    $request: String!
    $message: String!
    $createdBy: String!
    $locale: String!
    $type: String!
  ) {
    createChangeMessage(
      request: $request
      message: $message
      createdBy: $createdBy
      locale: $locale
      type: $type
    ) {
      createdAt
      message
    }
  }
`;
