import gql from "graphql-tag";

export const GET_WORK_TIME = gql`
  query getWorkTime(
    $contractCode: String!
    $startTime: Date!
    $endTime: Date!
    $myTimeType: String!
  ) {
    getWorkTime(
      contractCode: $contractCode
      startTime: $startTime
      endTime: $endTime
      myTimeType: $myTimeType
    ) {
      result
      message
      timeDataTypeCode
      timeDataTypeName
      startTime
      endTime
      workTime
    }
  }
`;
