import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_LEAVE_TYPES_BY_CONTRACT } from "graphql/queries";
import { LEAVE_TYPES_BY_CONTRACT_SUBSCRIPTION } from "graphql/subcriptions";
import useCurrentUserState from "hooks/use-current-user-state";
import usePolling from "hooks/use-polling";

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export default function useLeaveTypesByContract({ startDate, endDate }) {
  let { contractCode } = useCurrentUserState();

  let skip = !isValidDate(startDate) || !isValidDate(endDate);

  const { data, loading, error } = useQuery(GET_LEAVE_TYPES_BY_CONTRACT, {
    skip: !contractCode || skip,
    pollInterval: usePolling(1000 * 60),
    variables: { contract: contractCode, startDate, endDate },
  });

  const { data: subsData } = useSubscription(
    LEAVE_TYPES_BY_CONTRACT_SUBSCRIPTION,
    {
      skip: !contractCode || skip,
      variables: { contract: contractCode, startDate, endDate },
    }
  );

  return {
    leaveTypesByContract:
      subsData?.leaveTypesByContract || data?.leaveTypesByContract || [],
    leaveTypesByContractLoading: loading,
    leaveTypesByContractError: error,
  };
}
