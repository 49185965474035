import gql from "graphql-tag";

export const USERS_WITH_ADMIN_REQUEST = gql`
  mutation usersWithViewerRequest(
    $roleFilters: RoleFilterInput
    $processFilters: Boolean
    $onlyRequested: Boolean
    $sortField: String
    $sortDirection: String
    $searchField: String
    $searchText: String
    $showRemoteWorks: Boolean
    $adminView: Boolean!
    $startDate: Date
    $endDate: Date
    $page: Int
    $pageSize: Int
  ) {
    usersWithViewerRequest(
      roleFilters: $roleFilters
      processFilters: $processFilters
      onlyRequested: $onlyRequested
      sortField: $sortField
      sortDirection: $sortDirection
      searchField: $searchField
      searchText: $searchText
      showRemoteWorks: $showRemoteWorks
      adminView: $adminView
      startDate: $startDate
      endDate: $endDate
      page: $page
      pageSize: $pageSize
    ) {
      rows {
        contract
        employee
        name
        orgUnit
        jobClass
        allLeavesCount
        requestedLeavesCount
        urgentRequestedLeavesCount
      }
      total
      page
      pageSize
      totalPages
      allLeavesCount
      allUsersCount
    }
  }
`;
