import { useQuery } from "@apollo/react-hooks";
import { GET_ADMISSION } from "my-time/graphql/queries";
import useContractCalendar from "hooks/use-my-leave-contract-calendar";
import moment from "moment";
import useMyTimeRequests from "my-time/hooks/use-my-time-requests";
import { parseDayType, getDateRangeArray } from "my-time/utils";

const useAdmission = ({
  contractCode,
  year,
  month,
  changeMyTimeEnabled,
  recordingPeriod,
}) => {
  const referenceDate = new Date(`${year}-${month}-15`);

  const startDate = moment(referenceDate)
    .startOf("month")
    .subtract(7, "days")
    .toDate();
  const endDate = moment(referenceDate).endOf("month").add(7, "days").toDate();

  const { contractCalendar } = useContractCalendar({
    contract: contractCode,
    startDate: moment(referenceDate).startOf("month").toDate(),
    endDate: moment(referenceDate).endOf("month").toDate(),
  });

  const { contractCalendar: yearlyCalendar } = useContractCalendar({
    contract: contractCode,
    startDate: moment(referenceDate).startOf("year").toDate(),
    endDate: moment(referenceDate).endOf("year").toDate(),
  });

  const dates = getDateRangeArray(startDate, endDate);

  const {
    data: requestsData,
    loading: requestsLoading,
    error: requestsError,
  } = useMyTimeRequests({
    contractCode,
    startDate: startDate,
    endDate: endDate,
    requested: false,
    page: 1,
    pageSize: 100,
  });

  const extendedRequestsData = requestsData?.map((request) => {
    if (contractCalendar && yearlyCalendar) {
      const newRequest = { ...request };
      const dayOfYear = moment(request?.startTime).dayOfYear();
      newRequest.date = moment(request?.startTime).toDate();
      newRequest.title = `${moment(request?.startTime).format(
        "HH:mm"
      )} - ${moment(request.endTime).format("HH:mm")}`;
      newRequest.end = moment(request?.endTime).toDate();
      newRequest.start = moment(request?.startTime).toDate();
      newRequest.dayType = parseDayType(yearlyCalendar[dayOfYear - 1]);
      newRequest.icons = {
        arrow: true,
        add: false,
        edit: false,
      };
      return newRequest;
    }
    return request;
  });

  const { data, loading, error } = useQuery(GET_ADMISSION, {
    fetchPolicy: "cache-and-network",
    variables: {
      contractCode,
      year,
      month,
    },
  });

  const admissionsData = data?.getAdmission || [];
  const extendedAdmissionsData = admissionsData?.map((admission) => {
    const newAdmission = { ...admission };
    newAdmission.title = `${moment(admission.startTime).format(
      "HH:mm"
    )} - ${moment(admission.endTime).format("HH:mm")}`;
    newAdmission.end = moment(admission.endTime).toDate();
    newAdmission.start = moment(admission.startTime).toDate();
    const icons = {
      arrow: true,
      add: false,
      edit: false,
    };
    if (admission.myTimeType === "WORKING_TIME") {
      icons.arrow = false;
      icons.edit = true;
      icons.add = true;
    }
    if (admission.myTimeType === "STAND_BY") {
      icons.arrow = false;
      icons.edit = false;
      icons.add = true;
    }
    if (!changeMyTimeEnabled) {
      icons.arrow = true;
      icons.edit = false;
      icons.add = false;
    }
    if (recordingPeriod) {
      if (
        moment(admission?.date).format("YYYY-MM-DD") <
        recordingPeriod?.startDate ||
        moment(admission?.date).format("YYYY-MM-DD") > recordingPeriod?.endDate
      ) {
        icons.arrow = true;
        icons.edit = false;
        icons.add = false;
      }
    }

    newAdmission.icons = icons;
    return newAdmission;
  });

  const events = [];

  dates.forEach((date) => {
    if (contractCalendar && yearlyCalendar) {
      const dayOfYear = moment(date).dayOfYear();
      let icons = {
        arrow: false,
        add: true,
        edit: false,
      };
      if (!changeMyTimeEnabled) {
        icons.arrow = true;
        icons.edit = false;
        icons.add = false;
      }
      if (recordingPeriod) {
        if (
          date < recordingPeriod?.startDate ||
          date > recordingPeriod?.endDate
        ) {
          icons.arrow = true;
          icons.edit = false;
          icons.add = false;
        }
      }

      const item = {
        date,
        dayType: parseDayType(yearlyCalendar[dayOfYear - 1]),
        endTime: null,
        startTime: null,
        myTimeType: null,
        itemType: null,
        icons: icons,
      };
      const filteredRequests = extendedRequestsData?.filter(
        (requestItem) =>
          moment(requestItem.date).format("YYYY-MM-DD") ===
          moment(date).format("YYYY-MM-DD") &&
          (requestItem?.state === "approved" ||
            requestItem?.state === "requested")
      );

      const filteredAdmissions = extendedAdmissionsData?.filter(
        (admissionItem) =>
          moment(admissionItem.date).format("YYYY-MM-DD") ===
          moment(date).format("YYYY-MM-DD")
      );



      filteredAdmissions.forEach((admission) => {
        const isExist = filteredRequests?.find(
          (item) =>
            moment(item.startTime).format("YYYYMMDDHHmm") ===
            moment(admission.startTime).format("YYYYMMDDHHmm") &&
            moment(item.endTime).format("YYYYMMDDHHmm") ===
            moment(admission.endTime).format("YYYYMMDDHHmm") &&
            item.myTimeType === admission.myTimeType
        );

        const isSameDay = filteredRequests?.find((filterItem) => {
          if (
            moment(filterItem.date).format("YYYY-MM-DD") ===
            moment(admission.date).format("YYYY-MM-DD") &&
            admission.myTimeType === "WORKING_TIME" &&
            filterItem.myTimeType === "WORKING_TIME"
          ) {
            return true;
          }
          return false;
        });
        if (!isExist && !isSameDay) {
          events.push(admission);
        }
      });
      filteredRequests.forEach((request) => {
        const matchingData = extendedAdmissionsData.find(
          (data) => data.startTime === request.startTime
        );
        if (matchingData) {
          request.workBreakInMinutes = matchingData.workBreakInMinutes;
        }
        events.push(request);
      });
      if (filteredAdmissions.length === 0 && filteredRequests.length === 0) {
        events.push(item);
      }
    }
  });

  const tableData = events.filter(
    (item) =>
      moment(item.date).format("YYYY-MM-DD") >=
      moment(referenceDate).startOf("month").format("YYYY-MM-DD") &&
      moment(item.date).format("YYYY-MM-DD") <=
      moment(referenceDate).endOf("month").format("YYYY-MM-DD")
  );

  const filteredEvents = events.filter(
    (event) => event.startTime && event.endTime
  );

  return {
    data: tableData || [],
    events: filteredEvents || [],
    loading: (loading && !data) || (requestsLoading && !requestsData),
    error: error || requestsError,
  };
};

export default useAdmission;
