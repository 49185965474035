import { useQuery } from "@apollo/react-hooks";
import { GET_POSTAL_CODES } from "graphql/queries";

const usePostalCodes = () => {
  const { data, loading, error } = useQuery(GET_POSTAL_CODES, {
    fetchPolicy: "cache-and-network",
  });

  return {
    data: data?.postalCodes || [],
    loading,
    error,
  };
};

export default usePostalCodes;
