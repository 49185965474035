import React, { useRef } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import OcPageTransition from "oc/components/oc-page-transition";
import OcPrintHeader from "oc/components/oc-print-header/oc-print-header";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import MyCafeteriaSzepApplicationPrint from "my-cafeteria/components/my-cafeteria-szep-application-print";
import Card from "@material-ui/core/Card";

const MyCafeteriaSzepApplicationPage = ({ example }) => {
  const history = useHistory();
  const componentRef = useRef();

  function historyBack() {
    history.goBack();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <OcPageTransition>
      <Box padding="16px">
        <OcPrintHeader
          title="SZÉP kártya nyilatkozat"
          onBack={historyBack}
          onPrint={handlePrint}
        />
        <Box height="16px" />
        <Card
          id="my-cafeteria-summary-card"
          variant="outlined"
          style={{ borderRadius: "4px", border: "1px solid #E3E5E6" }}
        >
          <MyCafeteriaSzepApplicationPrint ref={componentRef} />
        </Card>
      </Box>
    </OcPageTransition>
  );
};

MyCafeteriaSzepApplicationPage.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaSzepApplicationPage.defaultProps = {
  example: "example",
};

export default MyCafeteriaSzepApplicationPage;
