import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_CONTRACTS_BY_EMPLOYEE } from "graphql/queries";
import { CONTRACTS_SUBSCRIPTION } from "graphql/subcriptions";
import usePolling from "hooks/use-polling";

export default function useContractsByEmployee({ employee }) {
  const { data, loading, error, refetch } = useQuery(
    GET_CONTRACTS_BY_EMPLOYEE,
    {
      fetchPolicy: "cache-and-network",
      skip: !employee,
      pollInterval: usePolling(1000 * 60),
      variables: { employee },
    }
  );

  if (error) {
    console.log({
      component: "useContractsByEmployee",
      errorDescription:
        "Error occured when fetch data with GET_CONTRACTS_BY_EMPLOYEE query",
      error: error,
    });
  }

  const { error: subscriptionError } = useSubscription(CONTRACTS_SUBSCRIPTION, {
    onData: () => {
      refetch();
    },
  });

  if (subscriptionError) {
    console.log({
      component: "useContractsByEmployee",
      errorDescription:
        "Error occured when subscribe data with CONTRACTS_SUBSCRIPTION query",
      error: error,
    });
  }

  return {
    data: data?.contractsByEmployee || [],
    loading,
    error,
  };
}
