import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Currency from "oc/components/oc-currency";
import { useTheme } from "@material-ui/styles";

export default function MyCafeteriaCostsBox({ label, currencyValue }) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="258px"
      padding="16px"
      borderRadius="8px"
      border={`1px solid ${theme.palette.gray40.main}`}
    >
      <Typography variant="body1" style={{ color: "#919699" }}>
        {label}
      </Typography>
      <Typography variant="body1" style={{ color: "#5E6366" }}>
        <Currency value={currencyValue} />
      </Typography>
    </Box>
  );
}
