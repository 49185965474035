import _ from "lodash";
import { useRecoilState } from "recoil";
import { unitListState } from "states";
import { useEffect } from "react";

const useFiltersData = ({
  data,
  fetchMore,
  setSearch,
  setPageSize,
  entityLength = 0,
  selectAll = false,
  filterType,
  product,
  role,
  contract,
}) => {
  let stateId = filterType + product + role;
  if (contract) {
    stateId = contract + filterType + product + role;
  }
  const [unitList, setUnitList] = useRecoilState(unitListState(stateId));

  function handleSearch(e) {
    setSearch(e.target.value);
  }

  function handleClearSearch() {
    setSearch("");
  }

  function handleFetchMore() {
    if (availableItems?.length >= data?.total) {
      return;
    }
    setPageSize(availableItems?.length + 10);
    fetchMore({
      variables: {
        pageSize: availableItems?.length + 10,
      },
    });
  }

  /*
  Due to a console log error, this condition was transferred to useEffect

  Error:
  "react-dom.development.js:86 Warning: Cannot update a component (`CompanyFilter`) while rendering a different component (`CompanyFilter`). To locate the bad setState() call inside `CompanyFilter`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render"

  if (data && !_.isEqual(unitList, data)) {
    setUnitList(data);
  }*/

  let availableItems = unitList?.availableList || [];

  availableItems.map((item) => {
    return {
      code: item.code,
      name: item.name + " (" + item.code + ")",
    };
  });

  let selectedItems = unitList?.selectedList || [];

  selectedItems.map((item) => {
    return {
      code: item.code,
      name: item.name + " (" + item.code + ")",
    };
  });

  if (entityLength === 0 && selectAll) {
    selectedItems = availableItems;
  }

  useEffect(() => {
    if (data && !_.isEqual(unitList, data)) {
      setUnitList(data);
    }
  }, [data, unitList, setUnitList]);

  return {
    availableItems,
    selectedItems,
    handleFetchMore,
    handleSearch,
    handleClearSearch,
  };
};

export default useFiltersData;
