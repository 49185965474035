import React from "react";
import usePayrollData from "hooks/use-myhr-payroll-data";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import useClientState from "hooks/use-oc-client-state";
import PayrollYearSelector from "my-hr/components/my-hr-payroll-year-selector";
import PayrollLineChart from "my-hr/components/my-hr-payroll-line-chart";
import PayrollTooltip from "my-hr/components/my-hr-payroll-yearly-data-tooltip";
import useStyles from "./my-hr-payroll-yearly-data.style";

export default function MyHrPayrollYearlyData({
    contract,
    onSelectMonth,
    onSelectYear,
}) {
    const classes = useStyles();
    let now = new Date();
    let currentYear = now.getFullYear().toString();

    const [selectedYear, setSelectedYear] = useClientState(
        "selectedPayrollYearToShow",
        currentYear
    );

    const { data, lastPaycardData } = usePayrollData({
        year: selectedYear,
        contract: contract?.code,
    });

    const [goBack, setGoBack] = React.useState(true);

    if (
        lastPaycardData?.year &&
        lastPaycardData?.year !== selectedYear &&
        goBack
    ) {
        setSelectedYear(lastPaycardData?.year);
        setGoBack(false);
    }

    function handleSelectYear(year) {
        setSelectedYear(year);
    }

    function handleSelectMonth(month) {
        onSelectYear(selectedYear);
        onSelectMonth(month);
    }
    //if (!data && loading) return <div>Loading...</div>;

    return (
        <Card
            id="my-hr-payroll-year-selector-data"
            style={{
                marginTop: "30px",
                backgroundColor: "#5e6366",
                color: "white",
                borderRadius: "8px",
                padding: "16px 16px",
            }}
        >
            <PayrollYearSelector
                year={selectedYear}
                onSelectYear={handleSelectYear}
                contract={contract}
            />
            <Box position="relative">
                <Box height="280px" marginTop="16px">
                    <PayrollLineChart data={data} />
                </Box>
                <Box
                    id="payroll-tooltip-diagram"
                    marginTop="24px"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    position="absolute"
                    bottom="0"
                    left="0"
                    width="100%"
                >
                    {data?.map((item, index) => {
                        return (
                            <PayrollTooltip
                                key={`${item.month}-${index}`}
                                item={item}
                                placement="top"
                            >
                                <div
                                    id={`${item.month}`}
                                    onClick={() =>
                                        handleSelectMonth(item.month)
                                    }
                                    className={classes.hovered}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        height: "280px",
                                        cursor: "pointer",
                                        borderRadius: "8px",
                                    }}
                                />
                            </PayrollTooltip>
                        );
                    })}
                </Box>
            </Box>
        </Card>
    );
}
