import gql from "graphql-tag";

export const GET_MY_LEAVE_BADGES = gql`
  query myLeaveBadges($contract: String!) {
    myLeaveBadges(contract: $contract) {
      urgentLeaveRequestsCount
      allLeaveRequestsCount
    }
  }
`;
