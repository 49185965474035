import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";

const MyLeaveFilterDivider = () => {
  const theme = useTheme();

  return (
    <Box
      style={{
        backgroundColor: theme.palette.gray40.main,
        height: "1px",
        width: "auto",
      }}
    ></Box>
  );
};

export default MyLeaveFilterDivider;
