import * as Yup from "yup";
import { CREATE_CHANGE_REQUEST } from "graphql/mutations";

export const socialSecurityNumberDialogDef = {
  type: "socialSecurityNumber",
  dialogLabel: "EDIT_SOCIAL_SECURITY_NUMBER",
  mutation: CREATE_CHANGE_REQUEST,
  items: [
    {
      label: "Common.validity",
      name: "validFrom",
      type: "datePicker",
      validation: Yup.date().required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Employee.socialSecurityNumber",
      name: "socialSecurityNumber",
      dataField: "socialSecurityNumber",
      type: "text",
      validation: Yup.string()
        .trim()
        .required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Leave.message",
      name: "initialMessage",
      type: "textarea",
      validation: Yup.string(),
    },
  ],
};
