import gql from "graphql-tag";

export const GET_LEAVES_BY_CONTRACT = gql`
  query leavesByContract($contract: String!, $year: String) {
    leavesByContract(contract: $contract, year: $year) {
      year
      startDate
      endDate
      workDays
      effectiveWorkDays
      name
      type
      sourcekey
    }
  }
`;
