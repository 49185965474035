import React from "react";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

export default function MyCafeteriaNettoBruttoFilter({
  cafeteriaAmount,
  setCafeteriaAmount,
  row,
}) {
  const { t } = useTranslation();
  return (
    <Box
      id={`state-slector-${row ? row.index : "zero"}`}
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <ButtonGroup>
        <Button
          id={`netto-amount-filter-${row ? row.index : "zero"}`}
          variant={cafeteriaAmount === "netto" ? "contained" : "outlined"}
          color={cafeteriaAmount === "netto" ? "primary" : ""}
          disableElevation
          onClick={() => setCafeteriaAmount("netto")}
        >
          {t("NETTO")}
        </Button>
        <Button
          id={`brutto-amount-filter-${row ? row.index : "zero"}`}
          variant={cafeteriaAmount === "brutto" ? "contained" : "outlined"}
          color={cafeteriaAmount === "brutto" ? "primary" : ""}
          disableElevation
          onClick={() => setCafeteriaAmount("brutto")}
        >
          {t("BRUTTO")}
        </Button>
      </ButtonGroup>
    </Box>
  );
}
