import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { InlineIcon } from "@iconify/react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItem";
import MaterialCollapse from "@material-ui/core/Collapse";
import chevronDown from "@iconify/icons-mdi/chevron-down";
import chevronRight from "@iconify/icons-mdi/chevron-right";
import Selector from "oc/components/oc-private-layout-sidebar-selector";
import Chevron from "oc/components/oc-private-layout-sidebar-chevron";
import { useLocation } from "react-router-dom";

export default function OcPrivateLayoutSidebarCollapse({
  id,
  badge,
  icon,
  label,
  translation,
  children,
  activeMenuItem,
  onMenuItemChange,
  onMenuChange,
  narrowMenu,
  narrowMenuOpened,
  onChangeTemporaryDrawer,
}) {
  const { t } = useTranslation();

  const handleClick = () => {
    if (activeMenuItem === id) {
      onMenuItemChange("");
    } else {
      onMenuItemChange(id);
    }
  };

  let menuLabel = t(translation) || label;

  const { pathname } = useLocation();

  let isActiveRoute = children?.find((item) => item.path === pathname);

  let openCollapse = isActiveRoute || activeMenuItem === id ? true : false;

  return (
    <Box>
      <ListItem
        id={id}
        button
        style={{
          color: "white",
          backgroundColor: openCollapse && isActiveRoute ? "#3E4345" : "unset",
        }}
        onClick={handleClick}
      >
        {icon && (
          <Box minWidth="20px" maxWidth="20px">
            <InlineIcon icon={icon} width="20px" />
          </Box>
        )}
        <ListItemText>{menuLabel}</ListItemText>
        {badge && <Box marginRight="10px">{badge}</Box>}
        {openCollapse ? (
          <Chevron icon={chevronDown} />
        ) : (
          <Chevron icon={chevronRight} />
        )}
      </ListItem>
      <MaterialCollapse in={openCollapse} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{
            backgroundColor:
              openCollapse && isActiveRoute ? "#3E4345" : "unset",
          }}
        >
          {children.map((item, index) => {
            return (
              <Selector
                key={`${index}_${item.id}`}
                item={item}
                index={index}
                onMenuChange={onMenuChange}
                onMenuItemChange={onMenuItemChange}
                activeMenuItem={activeMenuItem}
                narrowMenu={narrowMenu}
                narrowMenuOpened={narrowMenuOpened}
                narrowMenuIcon={item.narrowMenuIcon}
                onChangeTemporaryDrawer={onChangeTemporaryDrawer}
              />
            );
          })}
        </List>
      </MaterialCollapse>
    </Box>
  );
}
