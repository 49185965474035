export const nameCardDef = {
  name: "Name",
  translation: "NAME",
  type: "name",
  children: [
    {
      label: "Prefix name",
      translation: "PREFIX_NAME",
      value: "prefixName",
    },
    {
      label: "Last name",
      translation: "LAST_NAME",
      value: "lastName",
    },
    {
      label: "First name",
      translation: "FIRST_NAME",
      value: "firstName",
    },
    {
      label: "Middle name",
      translation: "MIDDLE_NAME",
      value: "middleName",
    },
    {
      label: "Name suffix",
      translation: "NAME_SUFFIX",
      value: "nameSuffix",
    },
  ],
};

export const birthCardDef = {
  label: "Birth card",
  translation: "BIRTH_CARD_TITLE",
  children: [
    {
      label: "Date of birth",
      translation: "DATE_OF_BIRTH",
      value: "dateOfBirth",
      type: "dateOfBirth",
    },
    {
      label: "Country of birth",
      translation: "COUNTRY_OF_BIRTH",
      value: "countryOfBirth",
      type: "countryOfBirth",
    },
    {
      label: "Place of birth",
      translation: "PLACE_OF_BIRTH",
      value: "placeOfBirth",
      type: "placeOfBirth",
    },
    {
      label: "Mother's name",
      translation: "MOTHERS_NAME",
      value: "mothersName",
      type: "mothersName",
    },
    {
      label: "Gender",
      translation: "GENDER",
      value: "gender",
      type: "gender",
    },
    {
      label: "Marital status",
      translation: "MARITAL_STATUS",
      value: "maritalStatus",
      type: "maritalStatus",
    },
  ],
};

export const codeCardDef = {
  label: "Codes",
  translation: "CODE_CARD_TITLE",
  children: [
    {
      label: "Tax number",
      translation: "TAX_NUMBER",
      value: "taxNumber",
      type: "taxNumber",
    },
    {
      label: "Social security number",
      translation: "SOCIAL_SECURITY_NUMBER",
      value: "socialSecurityNumber",
      type: "socialSecurityNumber",
    },
    {
      label: "Identity card number",
      translation: "IDENTITY_CARD_NUMBER",
      value: "identityCardNumber",
      type: "identityCardNumber",
    },
    {
      label: "Nationality",
      translation: "NATIONALITY",
      value: "nationality",
      type: "nationality",
    },
  ],
};

const address = [
  {
    label: "Nationality",
    translation: "NATIONALITY",
    value: "nationality",
    type: "nationality",
  },
];

export const permanentAddress = {
  label: "Permanent address",
  translation: "PERMANENT_ADDRESS",
  type: "address",
  address: "permanentAddress",
  children: address,
};

export const temporaryAddress = {
  label: "Temporary address",
  translation: "TEMPORARY_ADDRESS",
  type: "address",
  address: "temporaryAddress",
  children: address,
};

export const serviceAddress = {
  label: "Service address",
  translation: "SERVICE_ADDRESS",
  type: "address",
  address: "serviceAddress",
  children: address,
};

export const email = {
  label: "Email",
  translation: "EMAIL",
  type: "email",
};

export const phone = {
  label: "Phone",
  translation: "TELEPHONE",
  type: "phone",
};

export const contract = {
  type: "contract",
  children: [
    {
      label: "Period",
      type: "period",
    },
    {
      label: "Contract type",
      translation: "CONTRACT_TYPE",
      type: "subName",
      value: "typeOfContract",
    },
    {
      label: "Employment type",
      translation: "EMPLOYMENT_TYPE",
      type: "subName",
      value: "employmentType",
    },
    {
      label: "Job class",
      translation: "JOB_CLASS",
      type: "subName",
      value: "jobClass",
    },
    {
      type: "divider",
    },
    {
      label: "Company",
      translation: "COMPANY",
      type: "subName",
      value: "company",
    },
    {
      label: "Org unit",
      translation: "ORGUNIT",
      type: "subName",
      value: "orgUnit",
    },
    {
      label: "Working place",
      translation: "WORKINGPLACE",
      type: "subName",
      value: "workingPlace",
    },
    {
      label: "Job class group",
      translation: "JOBCLASSGROUP",
      type: "subName",
      value: "jobClassGroup",
    },
    {
      label: "Payoffice",
      translation: "PAYOFFICE",
      type: "subName",
      value: "payOffice",
    },
    {
      type: "divider",
    },
    {
      label: "Shift type",
      translation: "SHIFT_TYPE",
      type: "subName",
      value: "shiftType",
    },
    {
      label: "Max overtime in hour",
      translation: "MAX_OVERTIME_IN_HOUR",
      value: "maxOvertimeInHour",
    },
  ],
};
