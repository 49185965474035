import { atom } from "recoil";
import persistAtom from "./persist-atom";

export const addPermissionValidToState = atom({
  key: "addPermissionValidToState",
  default: {
    value: "",
    error: false,
    errorText: "",
  },
  effects_UNSTABLE: [persistAtom],
});
