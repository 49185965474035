import React, { Fragment, useCallback } from "react";
import Box from "@material-ui/core/Box";
import OcPrivateLayoutAppBar from "oc/components/oc-private-layout-app-bar";
import Sidebar from "oc/components/oc-private-layout-sidebar";
import OcPrivateLayoutFooter from "oc/components/oc-private-layout-footer";
import SessionLocked from "my-hr/pages/my-hr-user-session-locked-page";
import { useIdleTimer } from "react-idle-timer";
import useClientState from "hooks/use-oc-client-state";
import useRefreshToken from "hooks/use-oc-refresh-token";
import { Transition } from "react-transition-group";
import MyHrChangeTemporaryPasswordDialog from "my-hr/components/my-hr-change-temporary-password-dialog";
import { isTemporaryPasswordState } from "states";
import OcRefreshDialog from "oc/components/oc-refresh-dialog";
import { sessionLockedState } from "./oc-private-layout-states";
import { useRecoilState, useRecoilValue } from "recoil";
import { narrowMenuState } from "states";

export default function OcPrivateLayout({ children, onLogout, isLoggedIn }) {
  const [sessionLocked, setSessionLocked] = useRecoilState(sessionLockedState);
  const [narrowMenu, setNarrowMenu] = useRecoilState(narrowMenuState);
  const [narrowMenuOpened, setNarrowMenuOpened] = useClientState(
    "narrowMenuOpened",
    true
  );
  const [showDrawerTransition, setShowDraverTransition] = useClientState(
    "showDrawerTransition",
    true
  );

  const temporaryPassword = useRecoilValue(isTemporaryPasswordState);

  const handleOnIdle = (event) => {
    if (isLoggedIn) {
      setSessionLocked(true);
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  function handleUnlock() {
    setSessionLocked(false);
  }

  const [open, setOpen] = React.useState(true);

  function handleLogout() {
    setSessionLocked(false);
    setNarrowMenu(false);
    setNarrowMenuOpened(false);
    setShowDraverTransition(false);
    setOpen(false);
    onLogout();
  }

  function handleIconMenuChange(state) {
    setNarrowMenu(state);
    if (state === true) {
      setNarrowMenuOpened(false);
    }
  }

  function handleNarrowMenuOpened(state) {
    setNarrowMenuOpened(state);
  }

  useRefreshToken({ isLoggedIn });

  let leftMargin = narrowMenu ? "52px" : "310px";

  const duration = 250;

  const defaultStyle = {
    transition: `margin-left ${duration}ms ease-in-out`,
    marginLeft: leftMargin,
  };

  const transitionStyles = {
    entering: { marginLeft: 52 },
    entered: { marginLeft: 52 },
    exiting: { marginLeft: 310 },
    exited: { marginLeft: 310 },
  };

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      const width = node.getBoundingClientRect().width;
      if (width < 600) {
        handleIconMenuChange(true);
      }
    }
  }, []); //eslint-disable-line

  return (
    <Fragment>
      {isLoggedIn ? (
        <Fragment>
          <OcRefreshDialog />
          <SessionLocked
            sessionLocked={sessionLocked}
            openSession={handleUnlock}
            smallScreen={false}
            onLogout={handleLogout}
          />
          <MyHrChangeTemporaryPasswordDialog
            onLogout={onLogout}
            open={temporaryPassword}
          />
          <Box
            ref={measuredRef}
            minHeight="100vh"
            display="flex"
            flexDirection="row"
            width="100%"
          >
            <Box>
              <Sidebar
                narrowMenu={narrowMenu}
                onNarrowMenuChange={handleIconMenuChange}
                variant="permanent"
                open={open}
                onNarrowMenuOpened={handleNarrowMenuOpened}
                narrowMenuOpened={narrowMenuOpened}
                showDrawerTransition={showDrawerTransition}
                setShowDraverTransition={setShowDraverTransition}
              />
            </Box>

            <Transition in={narrowMenu} timeout={duration}>
              {(state) => (
                <Box
                  id="private-layout-container"
                  display="flex"
                  flexDirection="column"
                  minHeight="100vh"
                  minWidth="1024px"
                  width="100%"
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  <OcPrivateLayoutAppBar onLogout={onLogout} />
                  {children}
                  <OcPrivateLayoutFooter />
                </Box>
              )}
            </Transition>
          </Box>
        </Fragment>
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </Fragment>
  );
}
