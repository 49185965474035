import moment from "moment";
import { timeDifference } from "./time-difference";
import { getNettoWorkTime } from "./get-netto-work-time";

export const countHours = (item) => {
  const startTime = moment(item.startTime).toDate();
  const endTime = moment(item.endTime).toDate();
  let nettoWorkTime = 0;
  if (item.state === "requested" || item.state === "approved") {
    nettoWorkTime = getNettoWorkTime(item.hours);
  } else {
    nettoWorkTime =
      timeDifference({ startTime, endTime }) - item.workBreakInMinutes;
  }
  return nettoWorkTime;
};
