import gql from "graphql-tag";

export const APPROVE_ALL_MY_TIME_REQUESTS = gql`
  mutation approveAllMyTimeRequests($contractCode: String!) {
    approveAllMyTimeRequests(contractCode: $contractCode) {
      result
      message
    }
  }
`;
