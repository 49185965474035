import useCurrentUserState from "hooks/use-current-user-state";
import { DELETE_ASSET } from "graphql/mutations";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { GET_CHANGE_REQUESTS_ATTACHEMENT } from "graphql/queries";

const useAssets = ({ type }) => {
  let { employeeCode } = useCurrentUserState();
  const { data, loading, error, refetch } = useQuery(
    GET_CHANGE_REQUESTS_ATTACHEMENT,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        employeeCode,
        type,
      },
    }
  );
  const result = data?.listAttachementByEmployee || [];

  const [deleteAsset] = useMutation(DELETE_ASSET, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  return {
    data: result,
    loading,
    error,
    refetch,
    deleteAsset,
  };
};

export default useAssets;
