import gql from "graphql-tag";

export const LEAVE_TYPES_BY_CONTRACT_SUBSCRIPTION = gql`
  subscription leaveTypesByContract(
    $contract: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    leaveTypesByContract(
      contract: $contract
      startDate: $startDate
      endDate: $endDate
    ) {
      code
      name
      type
    }
  }
`;
