import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formatText: {
    fontSize: "15px",
    fontFamily: "EB Garamond",
  },
  tableTitle: {
    fontSize: "15px",
    fontFamily: "EB Garamond",
    fontWeight: "bold",
  },
  customBox: {
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "8px",
    paddingBottom: "12px",
    width: "auto",
    height: "auto",
    fontSize: "16px",
  },
  separator: {
    borderTop: `2px solid black`,
    height: "2px",
  },
  startChapter: {
    fontWeight: "bold",
    marginRight: "8px",
    marginLeft: "20px",
  },
  paragraphStart: {
    fontWeight: "bold",
    marginRight: "8px",
  },
  blackBorder: {
    border: "1px solid black",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "6px",
    paddingBottom: "12px",
    margin: "auto",
    width: "1000px",
    marginTop: "5px",
  },
}));

export default useStyles;
