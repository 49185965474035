import gql from "graphql-tag";

export const GET_CHANGE_REQUESTS_ATTACHEMENT = gql`
query listAttachementByEmployee(
  $employeeCode: String!, 
  $type: String
  ) {
    listAttachementByEmployee(
      employeeCode: $employeeCode, 
      type:$type
      ) {
          _id
          filename
          createdAt
  }
}
`;
