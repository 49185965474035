import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 1,
    backgroundColor: theme.palette.gray30.main,
  },
  messageContainer: {
    backgroundColor: theme.palette.gray30.main,
    padding: 24,
    borderRadius: 8,
  },
  initialMessage: {
    backgroundColor: theme.palette.gray60.main,
    color: "white",
    fontFamily: "Mulish",
    padding: "15px 11px 13px 11px",
    borderRadius: 4,
  },
  initialMessageLoading: {
    backgroundColor: theme.palette.gray30.main,
    color: theme.palette.gray30.main,
    fontFamily: "Mulish",
    padding: "15px 11px 13px 11px",
    borderRadius: 4,
    marginTop: 20,
    marginBottom: 24,
  },
  textInput: {
    "& .MuiOutlinedInput-input": {
      padding: 14,
    },
  },
  box: {
    width: 540,
    padding: "36px 24px",
  },
  avatar: {
    width: "100px",
    height: "100px",
  },
  title: {
    textAlign: "center",
    fontSize: 34,
    fontWeight: 400,
    letterSpacing: 0.25,
  },
  organizer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  createdAtOrganizer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  periodContainer: {
    height: 52,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.44px",
  },
  fullnameText: {
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0.15px",
  },
  triangle: {
    width: "30px",
    height: "16px",
    backgroundColor: "#919699",
    clipPath: `polygon(50% 0, 0 100%, 100% 100%)`,
    marginBottom: "-17px",
  },
  buttonSeparator: (data) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: data?.state === "requested" ? "space-between" : "flex-end",
    width: "100%",
  }),
}));

export default useStyles;
