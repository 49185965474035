import { useTranslation } from "react-i18next";
import Validator from "fastest-validator";
import {
  addPermissionEmailState,
  addPermissionValidFromState,
  addPermissionValidToState,
} from "states";
import { useRecoilState, useResetRecoilState } from "recoil";

const validator = new Validator();

export default function usePermissionForm() {
  const { t } = useTranslation();
  const schema = {
    email: {
      type: "email",
      messages: {
        email: t("ERROR_MESSAGE_WRONG_EMAIL_FORMAT"),
        emailEmpty: t("ERROR_MESSAGE_EMAIL_IS_REQUIRED"),
      },
    },
    validFrom: {
      type: "date",
    },
    validTo: [
      {
        type: "date",
        optional: true,
      },
      {
        type: "string",
        optional: true,
      },
    ],
  };

  const check = validator.compile(schema);

  const [email, setEmail] = useRecoilState(addPermissionEmailState);
  const [validFrom, setValidFrom] = useRecoilState(addPermissionValidFromState);
  const [validTo, setValidTo] = useRecoilState(addPermissionValidToState);

  function onChangeEmail(value) {
    setEmail({ value });
  }

  function onChangeValidFrom(date) {
    setValidFrom({ value: date });
  }

  function onChangeValidTo(date) {
    setValidTo({ value: date });
  }

  function isValid() {
    let isValidated = check({
      email: email.value,
      validFrom: validFrom.value,
      validTo: validTo.value,
    });
    if (isValidated !== true) {
      isValidated.forEach((item) => {
        if (item.field === "email") {
          setEmail({
            value: email.value,
            error: true,
            errorText: item.message,
          });
        }
      });
      return false;
    }
    return true;
  }

  const resetAddPermissionEmailState = useResetRecoilState(
    addPermissionEmailState
  );
  const resetAddPermissionValidFromState = useResetRecoilState(
    addPermissionValidFromState
  );

  const resetAddPermissionValidToState = useResetRecoilState(
    addPermissionValidToState
  );

  function onClear() {
    resetAddPermissionEmailState();
    resetAddPermissionValidFromState();
    resetAddPermissionValidToState();
  }

  function onClearError() {
    setEmail({ value: email.value, error: false, errorText: "" });
  }

  return {
    email,
    setEmail,
    isValid,
    onChangeEmail,
    onChangeValidFrom,
    onChangeValidTo,
    onClear,
    onClearError,
    validFrom,
    validTo,
  };
}
