import React from "react";
import { Box } from "@material-ui/core";
import ApplicationCheckBox from "my-hr/components/my-hr-applications-data-panel/components/application-check-box";
import { DOTTED_LINE } from "my-hr/components/my-hr-applications-data-panel/constants";
import useStyles from "my-hr/components/my-hr-applications-data-panel/components/family-tax-credit-application-print/family-tax-credit-application-print.style";
import TaxCreditApplicationPrintEmployer from "../tax-credit-application-print-employer/tax-credit-application-print-employer";

export const UnderThirtyMothersTaxCreditApplicationPrint = React.forwardRef(
  (props, ref) => {
    const {
      fullName,
      taxNumber,
      isSuitable,
      isSuitableForChild,
      isSuitableForFetus,
      isSuitableForAdopted,
      allDiscountsThirtyMothers,
      partDiscountsThirtyMothers,
      underThirtyMothersAmountDiscount,
      isNoneApplyStartMonthThirtyMothers,
      employerName,
      employerTaxNumber,
      isModifiedApplication,
      applicationDate,
      childName,
      childTaxNumber,
      fetusYear,
      fetusMonth,
      adoptedChildName,
      adoptedChildTaxNumber,
    } = props;
    let year = applicationDate?.slice(0, 4);

    const classes = useStyles();

    return (
      <Box ref={ref}>
        <style type="text/css" media="print">
          {"\
            @page { size: landscape; }\
        "}
        </style>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          fontFamily="EB Garamond"
          fontSize="15px"
          style={{ paddingTop: "20px" }}
        >
          <Box
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Adóelőleg-nyilatkozat a 30 év alatti anyák kedvezményének
            érvényesítéséről
            <Box />
            <Box
              key="nyilatkozat-ev"
              display="flex"
              flexDirection="column"
              alignItems="center"
              paddingTop="2px"
            >
              A nyilatkozat benyújtásának éve: {year}
            </Box>
          </Box>
          <Box className={classes.blackBorder}>
            <Box marginLeft="-20px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Box display="flex">
                    <Box className={classes.startChapter}>I.</Box>
                    <Box fontWeight="normal">
                      A nyilatkozatot adó magánszemély
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  neve:
                  <Box width="200px" textAlign="center">
                    {fullName}
                  </Box>
                  <Box>adóazonosító jele:</Box>
                  <Box marginLeft="-40px">
                    {taxNumber ? taxNumber : DOTTED_LINE}
                  </Box>
                  <Box display="flex" alignItems="center">
                    Módosított nyilatkozat:
                    <ApplicationCheckBox value={isModifiedApplication} />
                  </Box>
                </Box>
                <Box height="15px" />
                <Box display="flex">
                  <Box className={classes.startChapter}>1.</Box>
                  <Box fontWeight="normal" display="flex">
                    Nyilatkozom, hogy a 30 év alatti anyák kedvezményére, a 2.
                    pont szerinti gyermekre, magzatra tekintettel jogosult
                    vagyok
                    <ApplicationCheckBox value={isSuitable ? "X" : ""} />
                  </Box>
                </Box>
                <Box height="15px" />
                <Box display="flex">
                  <Box className={classes.startChapter}>2.</Box>
                  <Box>A 30 év alatti anyák kedvezményére</Box>
                </Box>
                <Box height="15px" />
                <Box marginLeft="20px">
                  <Box display="flex">
                    a) <ApplicationCheckBox value={isSuitableForChild} />
                    <Box width="5px" /> a 2022. december 31 után született{" "}
                    {childName?.length !== 0 ? childName : DOTTED_LINE} nevű,{" "}
                    {childTaxNumber?.length !== 0
                      ? childTaxNumber
                      : DOTTED_LINE}{" "}
                    adóazonosító jelű gyermekre tekintettel vagyok jogosult,
                  </Box>
                  <Box height="15px" />
                  <Box display="flex">
                    b) <ApplicationCheckBox value={isSuitableForFetus} />
                    <Box width="5px" /> magzat után vagyok jogosult, a
                    várandósság 91. napját{" "}
                    {fetusYear?.length !== 0 ? fetusYear : DOTTED_LINE} év{" "}
                    {fetusMonth?.length !== 0 ? fetusMonth : DOTTED_LINE}{" "}
                    hónapban töltöttem be
                  </Box>
                  <Box height="15px" />
                  <Box display="flex">
                    c) <ApplicationCheckBox value={isSuitableForAdopted} />
                    <Box width="5px" /> a 2022. december 31 után örökbefogadott{" "}
                    {adoptedChildName?.length !== 0
                      ? adoptedChildName
                      : DOTTED_LINE}{" "}
                    nevű,{" "}
                    {adoptedChildTaxNumber?.length !== 0
                      ? adoptedChildTaxNumber
                      : DOTTED_LINE}{" "}
                    adóazonosító jelű gyermekre tekintettel vagyok jogosult.
                  </Box>
                </Box>
                <Box height="15px" />
                <Box display="flex">
                  <Box className={classes.startChapter}>3.</Box>
                  <Box fontWeight="normal" display="flex">
                    Kérem a kedvezményt az adóelőleg megállapítása során: teljes
                    összegben{" "}
                    <ApplicationCheckBox
                      value={allDiscountsThirtyMothers ? "X" : ""}
                    />
                    <Box marginLeft="2px">,</Box>
                    <Box width="5px" />
                    {underThirtyMothersAmountDiscount === 0
                      ? DOTTED_LINE
                      : underThirtyMothersAmountDiscount}{" "}
                    forint összegben
                    <ApplicationCheckBox
                      value={partDiscountsThirtyMothers ? "X" : ""}
                    />
                    <Box width="5px" />
                    figyelembe venni.
                  </Box>
                </Box>
                <Box height="15px" />
                <Box display="flex">
                  <Box className={classes.startChapter}>4.</Box>
                  <Box fontWeight="normal" display="flex">
                    Nyilatkozom, hogy a 30 év alatti anyák kedvezményét
                    <Box width="5px" />
                    {isNoneApplyStartMonthThirtyMothers?.length !== 0
                      ? isNoneApplyStartMonthThirtyMothers
                      : DOTTED_LINE}{" "}
                    <Box width="5px" />
                    hónaptól kezdődően nem (erre a hónapra sem) kívánom igénybe
                    venni.
                  </Box>
                </Box>
                <Box height="15px" />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginLeft="20px"
                >
                  <Box
                    style={{
                      display: "flex",
                    }}
                  >
                    Kelt:
                    <Box width="150px" textAlign="center">
                      {applicationDate}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    A nyilatkozatot tevő magánszemély aláírása:
                    <Box marginLeft="10px">
                      .............................................................
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <TaxCreditApplicationPrintEmployer
            employerName={employerName}
            employerTaxNumber={employerTaxNumber}
          />
        </Box>
      </Box>
    );
  }
);

export default UnderThirtyMothersTaxCreditApplicationPrint;
