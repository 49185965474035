import gql from "graphql-tag";
import { useQuery, useSubscription } from "@apollo/client";
import usePolling from "hooks/use-polling";

export const GET_COMPANIES = gql`
  query companies(
    $searchField: String
    $searchText: String
    $sort: String
    $page: Int
    $pageSize: Int
  ) {
    companies(
      searchField: $searchField
      searchText: $searchText
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      rows {
        _id
        code
        name
        shortName
        companyLogo
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;

export const COMPANIES_SUBSCRIPTION = gql`
  subscription companiesSubscription {
    companiesSubscription
  }
`;

const useCompanies = () => {
  const { data, loading, error, refetch } = useQuery(GET_COMPANIES, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
  });

  if (error) {
    console.log({
      component: "CompanyFilter",
      errorDescription:
        "Error occured when fetch data with GET_COMPANIES query",
      error: error,
    });
  }

  const { error: subscriptionError } = useSubscription(COMPANIES_SUBSCRIPTION, {
    onData: (data) => {
      refetch();
    },
  });

  if (subscriptionError) {
    console.log({
      component: "CompanyFilter",
      errorDescription:
        "Error occured when subscribe data with COMPANIES_COMPANIES query",
      error: error,
    });
  }

  return { data, loading, error, companies: data?.companies?.rows || [] };
};

export default useCompanies;
