import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./my-hr-payroll-collapse-header.style";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "@material-ui/core/styles";
import { InlineIcon } from "@iconify/react";
import fileAccount from "@iconify/icons-mdi/file-account";

export default function MyHrPayrollCollapseHeader({
    label,
    ContractID,
    handleExpandClick,
    expanded,
}) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box>
            <Box
                className={
                    expanded === true
                        ? classes.backgroundColorOnCollapse
                        : classes.backgroundColorOffCollapse
                }
                padding="0px 16px"
            >
                <Box
                    className={classes.filterContainer}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="contents" className={classes.topBorder} />
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography
                            variant="h6"
                            style={{
                                padding: "16px 0px",
                                marginRight: "28px",
                                marginLeft: "16px",
                                color: `${theme.palette.gray80.main}`,
                            }}
                        >
                            {label}
                        </Typography>
                        <InlineIcon
                            icon={fileAccount}
                            width="24px"
                            style={{ color: `${theme.palette.gray80.main}` }}
                        />
                        <Box
                            style={{
                                marginLeft: "12px",
                                color: `${theme.palette.gray80.main}`,
                            }}
                        >
                            {ContractID}
                        </Box>
                        <Box width="6px"></Box>
                        <Box paddingRight="28px"></Box>
                    </Box>
                    <Box display="flex" alignItems="center"></Box>
                    <Box width="22px" />
                    <IconButton
                        id={ContractID}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        style={
                            expanded
                                ? {
                                      padding: 0,
                                      marginRight: "20px",
                                      transform: "rotate(180deg)",
                                  }
                                : {
                                      padding: 0,
                                      marginRight: "20px",
                                  }
                        }
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}
