import { useQuery, useSubscription } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { LEAVES_SUBSCRIPTION } from "graphql/subcriptions";
import useReqestLeavesData from "hooks/use-request-leave-data";
import usePolling from "hooks/use-polling";

const QUERY = gql`
  query leaveYearlyDaysByContract($contract: String!, $year: String!) {
    leaveYearlyDaysByContract(contract: $contract, year: $year) {
      _id
      year
      availableLeaveDays
      availableStudyLeaveDays
      availableESZJTV19LeaveDays
      availableCovidPlus10LeaveDays
      availableEmergencyLeaveDays
      fulfilledLeaveDays
      fulfilledStudyLeaveDays
      fulfilledAbsenceDays
      fulfilledCovidPlus10Days
      fulfilledESZJTV19Days
      fulfilledEmergencyDays
    }
  }
`;

export default function useLeaveSummaryChartData({ year, contract }) {
  if (!year) {
    year = new Date().getFullYear().toString();
  }

  const { data, loading, error, refetch } = useQuery(QUERY, {
    skip: !contract || !year,
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: {
      contract: contract,
      year: year,
    },
  });

  useSubscription(LEAVES_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    skip: !contract,
    onData: () => {
      refetch();
    },
  });

  const { requestedLeaveDays: requestedLeaveDaysState } = useReqestLeavesData({
    selectedYear: year,
    contractCode: contract,
  });

  let result = data;

  let myFulfilledLeaveDays = result?.leaveYearlyDaysByContract || [];

  let filteredByYear = myFulfilledLeaveDays?.find((item) => item.year === year);

  const fulfilledCovidPlus10Days =
    filteredByYear?.fulfilledCovidPlus10Days || 0;

  const requestedCovidPlus10LeaveDays = requestedLeaveDaysState?.covid;

  const availableCovidPlus10LeaveDays =
    filteredByYear?.availableCovidPlus10LeaveDays -
      requestedCovidPlus10LeaveDays || 0;

  const allCovidPlus10LeaveDays =
    availableCovidPlus10LeaveDays +
    fulfilledCovidPlus10Days +
    requestedCovidPlus10LeaveDays;

  const fulfilledLeaveDays = filteredByYear?.fulfilledLeaveDays || 0;

  const requestedLeaveDays = requestedLeaveDaysState?.base;

  const availableLeaveDays =
    filteredByYear?.availableLeaveDays - requestedLeaveDays || 0;

  const allLeaveDays =
    availableLeaveDays + fulfilledLeaveDays + requestedLeaveDays;

  const fulfilledStudyLeaveDays = filteredByYear?.fulfilledStudyLeaveDays || 0;

  const requestedStudyLeaveDays = requestedLeaveDaysState?.study;

  const availableStudyLeaveDays =
    filteredByYear?.availableStudyLeaveDays - requestedStudyLeaveDays || 0;

  const allStudyLeaveDays =
    availableStudyLeaveDays + fulfilledStudyLeaveDays + requestedStudyLeaveDays;

  const fulfilledESZJTV19Days = filteredByYear?.fulfilledESZJTV19Days || 0;

  const requestedESZJTV19LeaveDays = requestedLeaveDaysState?.eszjtv;

  const availableESZJTV19LeaveDays =
    filteredByYear?.availableESZJTV19LeaveDays - requestedESZJTV19LeaveDays ||
    0;

  const allESZJTV19LeaveDays =
    availableESZJTV19LeaveDays +
    fulfilledESZJTV19Days +
    requestedESZJTV19LeaveDays;

  const requestedEmergencyLeaveDays = requestedLeaveDaysState?.emergency;
  const fulfilledEmergencyDays = filteredByYear?.fulfilledEmergencyDays;
  const availableEmergencyLeaveDays =
    filteredByYear?.availableEmergencyLeaveDays - requestedEmergencyLeaveDays;

  const allEmergencyLeaveDays =
    availableEmergencyLeaveDays +
    fulfilledEmergencyDays +
    requestedEmergencyLeaveDays;

  const currentLeaveYear = result?.leaveYearlyDaysByContract?.filter(
    (item) => item?.year === year
  );
  return {
    data: {
      year,
      availableLeaveDays,
      fulfilledLeaveDays,
      requestedLeaveDays,
      allLeaveDays,
      fulfilledCovidPlus10Days,
      allCovidPlus10LeaveDays,
      requestedCovidPlus10LeaveDays,
      availableCovidPlus10LeaveDays,
      fulfilledStudyLeaveDays,
      availableStudyLeaveDays,
      requestedStudyLeaveDays,
      allStudyLeaveDays,
      fulfilledESZJTV19Days,
      availableESZJTV19LeaveDays,
      requestedESZJTV19LeaveDays,
      allESZJTV19LeaveDays,
      fulfilledEmergencyDays,
      availableEmergencyLeaveDays,
      requestedEmergencyLeaveDays,
      allEmergencyLeaveDays,
    },
    loading,
    error,
    noLeave:
      result?.leaveYearlyDaysByContract?.length === undefined ||
      result?.leaveYearlyDaysByContract?.length === 0 ||
      currentLeaveYear?.length === 0
        ? true
        : false,
  };
}
