import * as Yup from "yup";
import { CREATE_CHANGE_REQUEST } from "graphql/mutations";

export const mothersNameDialogDef = {
  type: "mothersName",
  dialogLabel: "EDIT_MOTHERS_NAME",
  mutation: CREATE_CHANGE_REQUEST,
  items: [
    {
      label: "Common.validity",
      name: "validFrom",
      type: "datePicker",
      validation: Yup.date().required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Employee.prefixName",
      name: "mothersNamePrefix",
      dataField: "mothersNamePrefix",
      type: "text",
      validation: Yup.string(),
    },
    {
      label: "Employee.lastName",
      name: "mothersLastName",
      dataField: "mothersLastName",
      type: "text",
      validation: Yup.string()
        .trim()
        .required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Employee.firstName",
      name: "mothersFirstName",
      dataField: "mothersFirstName",
      type: "text",
      validation: Yup.string()
        .trim()
        .required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Employee.middleName",
      name: "mothersFirstName2",
      dataField: "mothersFirstName2",
      type: "text",
      validation: Yup.string(),
    },
    {
      label: "Employee.nameSuffix",
      name: "mothersNameSuffix",
      dataField: "mothersNameSuffix",
      type: "text",
      validation: Yup.string(),
    },
    {
      label: "Leave.message",
      name: "initialMessage",
      type: "textarea",
      validation: Yup.string(),
    },
  ],
};
