import { useMutation } from "@apollo/react-hooks";
import {
  USERS_WITH_REQUEST,
  USERS_TO_EXAMINER_DASHBOARD,
} from "graphql/mutations";
import moment from "moment";

export default function useUsersWithLeaveRequest({
  onlyRequested,
  roleFilters,
  tableData,
  showRemoteWorks,
  startDate,
  endDate,
  onCompleted,
  dashboard,
  role,
}) {

  delete roleFilters.__typename;

  if (role === "examiner") {
    startDate = moment(startDate)
      .subtract(1, "month")
      .startOf("month")
      .toDate();
  }

  let queryParams = {
    roleFilters: roleFilters,
    onlyRequested,
    sortField: tableData?.sortField,
    sortDirection: tableData?.sortDirection,
    showRemoteWorks,
    role,
    startDate,
    endDate,
    page: tableData?.page,
    pageSize: tableData?.pageSize,
  };

  if (tableData?.searchField && tableData?.searchText) {
    queryParams.searchField = tableData?.searchField;
    queryParams.searchText = tableData?.searchText;
  }

  let QUERY = [];

  if (dashboard) {
    QUERY = USERS_TO_EXAMINER_DASHBOARD;
  } else {
    QUERY = USERS_WITH_REQUEST;
  }

  const [loadQuery, { data, loading, error }] = useMutation(QUERY, {
    variables: queryParams,
    onCompleted: (data) => {
      let result = [];
      if (dashboard) {
        result = data?.usersToExaminerDashboard;
      } else {
        result = data?.usersWithRequest;
      }
      onCompleted(result ? result : []);
    },
  });

  let result = [];

  if (dashboard) {
    result = data?.usersToExaminerDashboard;
  } else {
    result = data?.usersWithRequest;
  }

  return {
    data: result ? result : [],
    loading,
    error,
    loadQuery,
  };
}
