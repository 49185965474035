import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 50,
    width: 50,
    border: "2px solid #5e6366",
    borderRadius: 2,
  },
  rootHours: {
    height: 50,
    width: 80,
    border: "2px solid #5e6366",
    borderRadius: 2,
  },
  month: {
    fontFamily: "Mulish",
    textAlign: "center",
    backgroundColor: "#5e6366",
    color: "white",
    textTransform: "capitalize",
  },
  day: {
    height: 26,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Mulish",
    fontSize: 24,
    paddingTop: "2px",
  },
  loading: {
    backgroundColor: theme.palette.gray30.main,
    width: 50,
    height: 50,
  },
}));

export default useStyles;
