import gql from "graphql-tag";

export const GET_LEAVE_YEARLY_DAYS_BY_CONTRACT = gql`
  query leaveYearlyDaysByContract($contract: String!) {
    leaveYearlyDaysByContract(contract: $contract) {
      _id
      year
      availableLeaveDays
      availableStudyLeaveDays
      fulfilledLeaveDays
      fulfilledStudyLeaveDays
      fulfilledAbsenceDays
    }
  }
`;
