import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "moment/locale/hu";
import LeaveState from "my-leave/components/my-leave-state";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";

const PathFillPrior = ({ color }) => {

  return (
    <path
      //style={"stroke-width:1.02948"}
      id="path74"
      fill={color}
      d="M 16.041827,0.00593567 15.634804,0 c -1.338387,-0.01951788 -2.617475,0.457399 -3.53312,1.2636 l -10.7904334,9.5 c -1.74785584,1.5388 -1.74785584,3.934 0,5.4728 l 10.7904334,9.5 c 0.915645,0.8062 2.211928,1.219105 3.536584,1.243046 l 0.405097,0.0073 z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  );
};

const PathFillAfter = ({ color }) => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m 0.00152901,26.987165 0.407023,0.0059 C 1.746939,27.012585 3.026027,26.535666 3.941672,25.729465 l 10.790433,-9.5 c 1.747856,-1.5388 1.747856,-3.934 0,-5.4728 L 3.941672,1.2567007 C 3.026027,0.45050068 1.729744,0.03759568 0.40508801,0.01365468 l -0.4050969975,-0.0073 z"
      fill={color}
    />
  );
};

const PathOutlinePrior = ({ color }) => {
  return (
    <React.Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 12.910059,0 16.041827,0.00593567 16.039654,1.9814606 12.910059,2 c -0.55259,0.00327 -1.0807,0.2287 -1.4588,0.6318 l -8.9099802,9.5 c -0.7217,0.7694 -0.7217,1.967 0,2.7364 l 8.9099802,9.5 c 0.3781,0.4031 0.9062,0.6318 1.4588,0.6318 l 3.128706,0.0074 0.0046,1.979368 -3.133315,0.0069 c -1.105297,0.0024 -2.1614,-0.4511 -2.9175402,-1.2573 l -8.91004,-9.5 c -1.44330505,-1.5388 -1.44330505,-3.934 0,-5.4728 l 8.91004,-9.5 C 10.748659,0.457399 11.804759,0 12.910059,0 Z"
        fill={color}
      />
    </React.Fragment>
  );
};

const PathOutlineAfter = ({ color }) => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m 3.133297,26.993101 -3.13176799,-0.0059 0.0022,-1.975525 3.12959499,-0.01854 c 0.55259,-0.0033 1.0807,-0.2287 1.4588,-0.6318 l 8.90998,-9.5 c 0.7217,-0.7694 0.7217,-1.967 0,-2.7364 L 4.592097,2.6249007 c -0.3781,-0.4031 -0.9062,-0.6318 -1.4588,-0.6318 L 0.00459101,1.9857007 -8.9875e-6,0.00633268 3.133306,-5.67324e-4 C 4.238603,-0.00296732 5.294706,0.45053268 6.0508462,1.2567327 l 8.9100398,9.5000003 c 1.443305,1.5388 1.443305,3.934 0,5.4728 l -8.9100398,9.5 C 5.294697,26.535702 4.238597,26.993101 3.133297,26.993101 Z"
      fill={color}
    />
  );
};

const EventArrow = ({ event, continues }) => {
  let pathFill, pathOutline;
  const PathFill = continues === "prior" ? PathFillPrior : PathFillAfter;
  const PathOutline =
    continues === "prior" ? PathOutlinePrior : PathOutlineAfter;
  let eventState = event.state;
  let eventType = event.leaveType;
  let caseState = `${eventState}${eventType.charAt(0).toUpperCase() + eventType.slice(1)
    }`;
  switch (caseState) {
    case "requestedLeave":
      pathFill = <PathFill color="#f3aa18" />;
      pathOutline = null;
      break;
    case "approvedLeave":
      pathFill = <PathFill color="#2fa84f" />;
      pathOutline = null;
      break;
    case "requestedAbsence":
      pathFill = pathFill = <PathFill color="#fff" />;
      pathOutline = <PathOutline color="#fsaa18" />;
      break;
    case "approvedAbsence":
      pathFill = pathFill = <PathFill color="#fff" />;
      pathOutline = <PathOutline color="#2fa84f" />;
      break;
    case "currentLeaveRequest":
      pathFill = pathFill = <PathFill color="#fff" />;
      pathOutline = <PathOutline color="#cc0033" />;
      break;
    case "recordedLeave":
      pathFill = <PathFill color="#2fa84f" />;
      pathOutline = null;
      break;
    case "recordedAbsence":
      pathFill = <PathFill color="#2fa84f" />;
      pathOutline = null;
      break;
    default:
      pathFill = <PathFill color="#f3aa18" />;
      pathOutline = null;
  }

  return (
    <div
      style={{
        overflow: "hidden",
        height: 27,
        maxHeight: 27,
        marginRight: continues === "prior" ? -6 : 0,
        marginLeft: continues === "prior" ? 1 : -6,
        zIndex: 10000,
        width: 20,
      }}
    >
      <svg
        width="20"
        height="27"
        viewBox="0 0 16 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {pathFill}
        {pathOutline}
      </svg>
    </div>
  );
};

const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    color: "#212121",
  },
  tooltip: {
    backgroundColor: "#212121",
    borderRadius: "8px",
  },
}));

export const EventTooltip = ({ event }) => {
  const { t } = useTranslation();
  let type = t(event.typeName);
  let start = new Date(event.start).toLocaleDateString();
  let end = new Date(event.end).toLocaleDateString();

  return (
    <div
      style={{
        width: 242,
        minWidth: 242,
        height: 110,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "12px 8px",
      }}
    >
      <span
        style={{
          color: "#fff",
          fontSize: 16,
          letterSpacing: 0.44,
          marginBottom: 6,
        }}
      >
        {event.title}
      </span>
      <span
        style={{
          color: "#919699",
          fontSize: 14,
          letterSpacing: 0.44,
        }}
      >
        {t(`Leave.${type}`)}
      </span>
      <div
        style={{ width: "100%", height: 8, borderBottom: "1px solid #A6ADAF" }}
      />
      <span
        style={{
          fontSize: 12,
          letterSpacing: 0.4,
          color: "#fff",
          paddingTop: 8,
        }}
      >{`${start} - ${end}`}</span>
      <Grid container spacing={2} style={{ fontSize: 12, paddingTop: 6 }}>
        <Grid
          item
          xs={6}
          style={{ textAlign: "right", textTransform: "capitalize" }}
        >
          {t("WORKDAYS")}
        </Grid>
        <Grid item xs={6}>
          {event.workDays}
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ fontSize: 12, paddingTop: 6 }}>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          {t("STATE")}
        </Grid>
        <Grid item xs={6}>
          <Box>
            <LeaveState
              label={t(`Leave.${event?.state}`)}
              state={event?.state}
              variant="caption"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default function EventWrapper(props) {
  let { event, continuesPrior, continuesAfter } = props;
  const classes = useStylesTooltip();
  return (
    <Tooltip arrow title={<EventTooltip event={event} />} classes={classes}>
      <div
        className="custom-event-rapper"
        style={{ display: "flex", flexDirection: "row" }}
      >
        {continuesPrior && <EventArrow event={event} continues="prior" />}
        {props.children}
        {continuesAfter && <EventArrow event={event} continues="after" />}
      </div>
    </Tooltip>
  );
}
