import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import ApplicationHeader from "../application-header/application-header";
import ApplicationCardInfoItem from "../application-card-info-item/application-card-info-item";
import OcDownload from "oc/components/oc-download";
import { useRecoilState, useResetRecoilState } from "recoil";
import { underTwentyFiveAmountDiscountCbState } from "../../states/under-twenty-five-amount-discountcb-state";
import UnderTwentyFiveTaxCreditApplicationPrint from "../under-twenty-five-tax-credit-application-print";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import useCurrentUserState from "hooks/use-current-user-state";
import TaxCreditApplicationButtonBars from "../tax-credit-application-buttonbars/tax-credit-application-buttonbars";
import TaxCreditApplicationCheckboxLine from "../tax-credit-application-checkbox-line/tax-credit-application-checkbox-line";
import { useTranslation } from "react-i18next";
import { taxDeclarationUnder25 } from "../../states/tax-declaration-under-25";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_TAX_DECLARATION_UNDER_25 } from "graphql/queries";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { underTwentyFiveTaxNoApplyOrLimit } from "../../states/under-twenty-five-tax-no-apply-or-limit";
import OcCard from "oc/components/oc-card";
import TaxCreditApplicationErrorBox from "my-hr/components/my-hr-applications-data-panel/components/tax-credit-application-error-box/tax-credit-application-error-box";
import { CREATE_TAX_DECLARATION_UNDER_25 } from "graphql/mutations";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useOcSnackbar from "hooks/use-oc-snackbar";

const useFormData = ({ _id, setSelected }) => {
  const { t } = useTranslation();
  const [saveErrors, setSaveErrors] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [states, setStates] = useRecoilState(taxDeclarationUnder25);
  const resetStates = useResetRecoilState(taxDeclarationUnder25);

  const { data, loading, error } = useQuery(GET_TAX_DECLARATION_UNDER_25, {
    fetchPolicy: "cache-and-network",
    skip: !_id,
    variables: {
      _id: parseInt(_id),
    },
    onCompleted: (data) => {
      const result = data?.taxDeclarationUnder25;
      const newStates = { ...result };
      newStates.dateOfDeclaration = new Date(result?.dateOfDeclaration);
      delete newStates.__typename;
      setStates(newStates);
    },
  });

  const result = data?.taxDeclarationUnder25;

  const handleChange = ({ key, value }) => {
    const newState = { ...states, [key]: value };
    setStates(newState);
  };

  const handleStateChange = (newState) => {
    setStates(newState);
  };

  const { setOpenMessage } = useOcSnackbar();
  const [saveDeclaration, { loading: mutationLoading }] = useMutation(
    CREATE_TAX_DECLARATION_UNDER_25,
    {
      onCompleted: (data) => {
        const res = data?.createTaxDeclarationUnder25;
        setDisabledButton(false);
        if (res?.result) {
          if (res?.messages?.length > 0) {
            const message = res?.messages[0];
            if (
              message === "A korábban beadott nyilatkozata törlésre került!"
            ) {
              setOpenMessage({
                type: "success",
                message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
              });
              setOpenMessage({
                type: "warning",
                message: t("PREVIOUS_TAX_DECLARATION_DELETED"),
              });
            }
          } else {
            setOpenMessage({
              type: "success",
              message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
            });
          }
          setSaveErrors([]);
          const params = {
            editable: false,
            tableId: res?.tableId,
            year: states?.dateOfDeclaration?.getFullYear().toString(),
            type: "TaxDeclarationUnder25",
            state: "requested",
          };
          setSelected(params);
        } else {
          setSaveErrors(res?.messages);
          setDisabledButton(false);
        }
      },
      onError: () => {
        setSaveErrors([t("TAX_DECLARATION_CREATED_FAILED")]);
        setDisabledButton(false);
      },
    }
  );

  const handleSave = () => {
    setDisabledButton(true);
    const params = { ...states };
    saveDeclaration({ variables: params });
  };

  return {
    data: result,
    loading: loading || mutationLoading,
    error,
    states,
    disabledButton,
    resetStates,
    handleChange,
    handleStateChange,
    handleSave,
    saveErrors,
    setSaveErrors,
  };
};

const UnderTwentyFiveTaxCreditApplication = ({
  fullName,
  taxNumber,
  taxApplicationSaveEnabled,
  handleRemoveRequest,
  selected,
  setSelected,
}) => {
  const editable = selected?.editable;
  const { t } = useTranslation();
  const history = useHistory();

  const {
    states,
    loading,
    error,
    saveErrors,
    disabledButton,
    setSaveErrors,
    resetStates,
    handleChange,
    handleStateChange,
    handleSave,
  } = useFormData({
    _id: selected?.tableId,
    setSelected,
  });

  const showRemoveButton = states?.state === "K" || selected?.sate === "K" ? true : false;

  const dateOfDeclarationString = new Date(
    states?.dateOfDeclaration
  ).toLocaleDateString();

  const title =
    "Nyilatkozat a 25 év alatti fiatalok kedvezményének részben vagy egészben történő érvényesítésének mellőzéséről ";

  const componentRef = useRef();

  const [underTwentyFiveAmountDiscountCb, setUnderTwentyFiveAmountDiscountCb] =
    useRecoilState(underTwentyFiveAmountDiscountCbState);

  const resetUnderTwentyFiveAmountDiscountCbState = useResetRecoilState(
    underTwentyFiveAmountDiscountCbState
  );

  const [radioGroupState, setRadioGroupState] = useRecoilState(
    underTwentyFiveTaxNoApplyOrLimit
  );

  const resetRadioGroupState = useResetRecoilState(
    underTwentyFiveTaxNoApplyOrLimit
  );

  const handleRadioGroupChange = (e) => {
    if (e.target.value === "noApply") {
      const newStates = {
        ...states,
        noApply: true,
        noApplyAboveLimit: false,
        discountLimit: 0,
      };
      setUnderTwentyFiveAmountDiscountCb(false);
      handleStateChange(newStates);
    } else {
      const newStates = {
        ...states,
        noApply: false,
        noApplyAboveLimit: true,
        discountLimit: parseFloat(e.target.value),
      };
      setUnderTwentyFiveAmountDiscountCb(true);
      handleStateChange(newStates);
    }
    setRadioGroupState(e.target.value);
  };

  if (!editable && states?.noApplyAboveLimit && !states?.noApply) {
    setUnderTwentyFiveAmountDiscountCb(true);
  }

  if (!editable && states?.noApplyAboveLimit) {
    setRadioGroupState("limit");
  }

  let { contractCode, employeeCode } = useCurrentUserState();
  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  let selectedContract = contractsData?.find(
    (item) => item.code === contractCode
  );

  const handleCancel = () => {
    resetUnderTwentyFiveAmountDiscountCbState();
    resetRadioGroupState();
    resetStates();
    setSaveErrors([]);
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    print();
  };

  const handleScrollToError = () => {
    const errorElement = document.getElementById("errorBox");
    if (errorElement) {
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    }
  };
  useLayoutEffect(() => {
    handleScrollToError();
  }, [saveErrors, history.action]);

  useEffect(() => {
    if (history.action === "POP" && editable) {
      return handleCancel();
    }
  }, []); //eslint-disable-line

  return (
    <>
      <Box
        style={{
          marginBottom: "-15px",
        }}
      >
        <Box
          sx={{
            display: "none",
            displayPrint: "block",
            paddingRight: "85px",
          }}
        >
          <UnderTwentyFiveTaxCreditApplicationPrint
            ref={componentRef}
            fullName={fullName}
            taxNumber={taxNumber}
            omissionOfApplication={states?.noApply}
            underTwentyFiveAmountDiscount={states?.discountLimit}
            underTwentyFiveAmountDiscountCb={underTwentyFiveAmountDiscountCb}
            employerName={selectedContract?.company?.name || ""}
            employerTaxNumber={selectedContract?.company?.taxNumber || ""}
            applicationDate={dateOfDeclarationString}
          />
        </Box>
        <ApplicationHeader title={title} onCancel={handleCancel} />
        <Box height="16px" />
        <Box display="flex" justifyContent="space-around">
          <Box
            style={{
              padding: "7px",
              width: "260px",
              borderRadius: "4px",
            }}
          >
            <OcDownload
              label="Tájékoztató"
              actionIconType="url"
              href={"#/my-hr/under-twenty-five-tax-credit-information"}
              target="_blank"
              isSmall={true}
            />
          </Box>
        </Box>
        <Box height="16px" />
        {saveErrors.length > 0 && (
          <Box id="errorBox">
            <TaxCreditApplicationErrorBox error={saveErrors} />
          </Box>
        )}
        <OcCard
          label="I. A nyilatkozatot adó magánszemély"
          loading={loading}
          error={error}
        >
          <Box
            style={{
              padding: "0px 17px",
            }}
          >
            <Box height="52px" />
            <ApplicationCardInfoItem
              label="Dátum"
              value={dateOfDeclarationString}
            />
            <ApplicationCardInfoItem label="Dolgozó neve" value={fullName} />
            <ApplicationCardInfoItem
              label="Adóazonosító jele"
              value={taxNumber}
            />
            <Box height="34px" />
            <Box variant="body1">
              1.
              <Box
                display="inline-flex"
                style={{
                  textIndent: "16px",
                }}
              >
                Az adóelőleg megállapítása során kérem
              </Box>
            </Box>
            <Box height="16px" />
            <FormControl component="fieldset" width="16px" display="flex">
              <Box height="8px" />
              <RadioGroup
                value={radioGroupState}
                aria-label="gender"
                name="controlled-radio-buttons-group"
                onChange={handleRadioGroupChange}
                disabled={!editable}
              >
                <Box display="flex" alignItems="center">
                  a)
                  <Box width="16px" />
                  <FormControlLabel
                    value="noApply"
                    control={
                      <Radio
                        id="omissionOfApplication"
                        style={{ color: !editable ? "#919699" : "#212121" }}
                        disabled={!editable}
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{ alignItems: "center" }}
                      >
                        a 25 év alatti fiatalok kedvezménye érvényesítésének
                        mellőzését.
                      </Typography>
                    }
                  />
                </Box>
                <Box height="10px" />
                <Box display="flex" alignItems="center">
                  b)
                  <Box width="16px" />
                  <FormControlLabel
                    value="limit"
                    control={
                      <Radio
                        id="underTwentyFiveAmountDiscountCb"
                        style={{ color: !editable ? "#919699" : "#212121" }}
                        disabled={!editable}
                      />
                    }
                    label={
                      <TaxCreditApplicationCheckboxLine
                        id="amount"
                        text="forintot meghaladó összegre a kedvezmény érvényesítésének mellőzését."
                        textInput={true}
                        textInputType="number"
                        value={
                          states?.discountLimit !== 0
                            ? states?.discountLimit
                            : ""
                        }
                        label={t("AMOUNT")}
                        onChangeInput={(e) =>
                          handleChange({
                            key: "discountLimit",
                            value: parseFloat(
                              e.target.value.replace(/[^0-9]/g, "")
                            ),
                          })
                        }
                        textInputDisabled={!editable}
                      />
                    }
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box height="36px" />
        </OcCard>
        <Box height="16px" />
        <TaxCreditApplicationButtonBars
          taxApplicationSaveEnabled={taxApplicationSaveEnabled && editable}
          cancelData={handleCancel}
          handlePrint={handlePrint}
          saveData={handleSave}
          editable={editable}
          disabledButton={disabledButton}
          handleRemoveRequest={handleRemoveRequest}
          showModifiedButton={false}
          showRemoveButton={showRemoveButton}
        />
      </Box>
    </>
  );
};

export default UnderTwentyFiveTaxCreditApplication;
