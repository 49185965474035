import React from "react";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import useStyles from "./oc-form-switch.style";

export default function OcFormSwitch({ ...props }) {
  const classes = useStyles();
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...props}
    />
  );
}

OcFormSwitch.propTypes = {
  value: PropTypes.any,
};
