import React, { createRef } from "react";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import arrowRightBold from "@iconify/icons-mdi/arrow-right-bold";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import useWidth from "hooks/use-oc-component-width";
import useStyles from "./my-hr-user-data-change-box.style";
import MyHrUserDataNewDataRequestBox from "../my-hr-user-data-new-data-request-box";

const MyHrUserDataChangeBox = ({ oldValue, newValue, type }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();

  const oldWidthRef = createRef();
  const newWidthRef = createRef();

  const [oldWidth] = useWidth(oldWidthRef);
  const [newWidth] = useWidth(newWidthRef);

  const exceedsThreshold = oldWidth > 308 || newWidth > 308;

  const oldLabel = (
    <Box className={classes.labelStyles}>
      <Typography className={classes.textStyles}>{t("OLD")}</Typography>
      <Typography>{t(type)}</Typography>
    </Box>
  );

  const newLabel = (
    <Box className={classes.labelStyles} style={{ textAlign: "center" }}>
      <Typography className={classes.textStyles}>{t("NEW")}</Typography>
      <Typography>{t(type)}</Typography>
    </Box>
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection={exceedsThreshold ? "column" : "row"}
        maxWidth={exceedsThreshold ? "640px" : "unset"}
      >
        <Box
          className={`${classes.dataBox} ${
            oldWidth > 308 ? classes.exceedsThreshold : ""
          }`}
          ref={oldWidthRef}
        >
          <MyHrUserDataNewDataRequestBox label={oldLabel} value={oldValue} />
        </Box>
        <Box height="10px" />
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: exceedsThreshold ? "space-around" : "unset",
          }}
        >
          <Icon
            width="24px"
            height="24px"
            color={theme.palette.gray80.main}
            icon={arrowRightBold}
            style={{
              transform: exceedsThreshold ? "rotate(90deg)" : "rotate(0deg)",
              marginBottom: exceedsThreshold ? "6px" : "unset",
            }}
          />
        </Box>
        <Box
          className={`${classes.dataBox} ${
            newWidth > 308 ? classes.exceedsThreshold : ""
          }`}
          ref={newWidthRef}
        >
          <MyHrUserDataNewDataRequestBox label={newLabel} value={newValue} />
        </Box>
      </Box>
    </>
  );
};

export default MyHrUserDataChangeBox;
