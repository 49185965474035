import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modalTitle: {
    fontSize: "16px",
    letterSpacing: "0.44px",
    lineHeight: "24px",
    paddingBottom: "4px",
    textTransform: "capitalize",
  },
  modalContent: {
    width: "200px",
    fontSize: theme.typography.caption.fontSize,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "2px",
  },
}));

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    borderRadius: "8px",
    backgroundColor: theme.palette.common.black,
  },
}));
