import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import LeaveLoadingItem from "my-leave/components/my-leave-chart-loading-item";
import useStyles from "./my-leave-chart-legend.style";

export default function LeaveChartLegend({ item, index, listCount, loading }) {
  const classes = useStyles({
    item,
    loading,
  });

  return (
    <div key={`item-${index}`}>
      <div className={classes.legendContainer}>
        <span className={classes.bullet}></span>
        <div className={classes.textContainer}>
          <div>
            {!loading && (
              <Typography className={classes.valueTypography}>
                {item.value?.toLocaleString()}
              </Typography>
            )}
            {loading && <LeaveLoadingItem width={40} height={24} />}
          </div>
          <div>
            {!loading && (
              <Typography className={classes.labelTypography}>
                {item.label}
              </Typography>
            )}
          </div>
          {loading && <LeaveLoadingItem width={90} height={16} />}
          {index + 1 < listCount && (
            <div className={classes.dividerContainer}>
              <div className={classes.divider}></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

LeaveChartLegend.propTypes = {
  /**
   * A legend item with props to define display params
   */
  item: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    color: PropTypes.string,
  }).isRequired,
  /**
   * This property shows the index of this component in the data array */
  index: PropTypes.number.isRequired,
  /**
   * How many items are in the legend list. It helps to determine showing divider.
   */
  listCount: PropTypes.number.isRequired,
  /**
   * Property to show the loading state of the legend
   */
  loading: PropTypes.bool,
};

LeaveChartLegend.defaultProps = {
  loading: false,
};
