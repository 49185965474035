import { InlineIcon } from "@iconify/react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import accountIcon from "@iconify/icons-mdi/account-network";
import gql from "graphql-tag";
import InputAdornment from "@material-ui/core/InputAdornment";
import keyIcon from "@iconify/icons-mdi/key";
import Loading from "oc/components/oc-loading";
import React, { useEffect, Fragment, useState } from "react";
import TextField from "@material-ui/core/TextField";
import useClientState from "hooks/use-oc-client-state";
import Validator from "fastest-validator";
import ForgotPasswordSSODialog from "my-hr/components/my-hr-forgot-password-dialog-sso";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import MyHrHelpdeskContact from "my-hr/components/my-hr-helpdesk-contact";
import useOcProperty from "hooks/use-oc-property";

const validator = new Validator();

const SSO_LOGIN = gql`
  query ssoLogin($username: String!, $password: String!) {
    ssoLogin(username: $username, password: $password) {
      accessToken
      sessionPeriod
      accessTokenPeriod
      currentUser {
        _id
        email
        phone
        ldapUser
        employee
        lastPasswordChange
        validFrom
        validTo
      }
      isTemporaryPassword
    }
  }
`;

export default function MyHrLoginFormSSO({ onLogin }) {
  const { t } = useTranslation();
  const schema = {
    username: {
      type: "string",
      min: 1,
      messages: {
        string: "rossz azonosító",
        stringMin: t("ERROR_MESSAGE_USERNAME_IS_REQUIRED"),
      },
    },
    password: {
      type: "string",
      min: 1,
      messages: {
        string: "Nem megfelelő jelszó!",
        stringMin: t("ERROR_MESSAGE_PASSWORD_IS_REQUIRED"),
      },
    },
  };
  const check = validator.compile(schema);

  const [showDialog, setShowDialog] = useClientState("loginShowDialog", false);

  const [username, setUsername] = useClientState("loginUsername", "");
  const [usernameError, setUsernameError] = useClientState(
    "loginUsernameError",
    false
  );
  const [usernameErrorText, setUsernameErrorText] = useClientState(
    "loginUsernameErrorText",
    ""
  );
  const [password, setPassword] = useClientState("loginPassword", "");
  const [showPassword, setShowPassword] = useState(false);

  const [passwordError, setPasswordError] = useClientState(
    "loginPasswordError",
    false
  );
  const [passwordErrorText, setPasswordErrorText] = useClientState(
    "loginPasswordErrorText",
    ""
  );

  const { data: passwordVisibilityData } = useOcProperty({
    resource: "system",
    key: "passwordVisibility",
  });

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const [ssoLogin, { loading }] = useLazyQuery(SSO_LOGIN, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.ssoLogin) {
        let loginResult = data?.ssoLogin;
        onLogin(loginResult);
      }
    },
    onError: (error) => {
      let errors = error?.graphQLErrors;
      errors.map((item) => {
        if (item.message === "No user found!") {
          setUsernameErrorText(t("ERROR_MESSAGE_NO_USER_FOUND"));
          setUsernameError(true);
        }
        if (item.message === "Wrong username or password!") {
          setUsernameErrorText(t("ERROR_MESSAGE_WRONG_USERNAME_OR_PASSWORD"));
          setUsernameError(true);
          setPasswordErrorText(t("ERROR_MESSAGE_WRONG_USERNAME_OR_PASSWORD"));
          setPasswordError(true);
        }
        return item;
      });
    },
  });

  function handleLogin(e) {
    e.preventDefault();
    let isValidated = check({ username, password });

    if (isValidated !== true) {
      isValidated.forEach((item) => {
        if (item.field === "username") {
          setUsernameErrorText(item.message);
          setUsernameError(true);
        }
        if (item.field === "password") {
          setPasswordErrorText(item.message);
          setPasswordError(true);
        }
      });
      return;
    }

    ssoLogin({ variables: { username, password } });
  }

  useEffect(() => {
    function handleClear() {
      setShowDialog(false);
      setUsername("");
      setUsernameError(false);
      setUsernameErrorText("");
      setPassword("");
      setPasswordError(false);
      setPasswordErrorText("");
    }
    return function cleanup() {
      handleClear();
    };
    //eslint-disable-next-line
  }, []);

  function handleCloseDialog() {
    setShowDialog(false);
  }

  return (
    <Fragment>
      <Loading show={loading} />
      <Box id="login-form" width="304px" padding="16px 48px">
        <form noValidate autoComplete="off" onSubmit={handleLogin}>
          <Box paddingTop="18px" paddingBottom="14px">
            <TextField
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              label={t("USERNAME")}
              variant="outlined"
              fullWidth
              error={usernameError}
              onFocus={() => {
                setUsernameErrorText("");
                setUsernameError(false);
              }}
              helperText={usernameError && usernameErrorText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InlineIcon icon={accountIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box paddingTop="18px" paddingBottom="14px">
            <TextField
              id="password"
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
              label={t("PASSWORD")}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              fullWidth
              helperText={passwordErrorText}
              error={passwordError}
              onFocus={() => {
                setPasswordErrorText("");
                setPasswordError(false);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InlineIcon icon={keyIcon} />
                    <Box width="5px" />
                    {passwordVisibilityData === "true" &&
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? (
                          <VisibilityOff
                            style={{ height: "16px", width: "16px" }}
                          />
                        ) : (
                          <Visibility style={{ height: "16px", width: "16px" }} />
                        )}
                      </IconButton>
                    }
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box paddingTop="24px">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
            >
              {t("LOGIN")}
            </Button>
          </Box>
          <Box height="36px" />
          <MyHrHelpdeskContact />
        </form>
      </Box>
      <ForgotPasswordSSODialog
        showDialog={showDialog}
        onShowDialog={handleCloseDialog}
      />
    </Fragment>
  );
}
