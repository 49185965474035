import gql from "graphql-tag";

export const GET_FRAME_PERIOD = gql`
  query getFramePeriod(
    $contractCode: String!
    $year: String!
    $month: String!
  ) {
    getFramePeriod(contractCode: $contractCode, year: $year, month: $month) {
      startDate
      endDate
      hoursByLaw
      hoursByFact
    }
  }
`;
