import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import Chart from "my-leave/components/my-leave-chart";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { LEAVES_SUBSCRIPTION } from "graphql/subcriptions";
import useCurrentUserState from "hooks/use-current-user-state";
import usePolling from "hooks/use-polling";

export const QUERY = gql`
  query leaveDetailsByContract($contract: String!, $year: String!) {
    leaveDetailsByContract(contract: $contract, year: $year) {
      baseLeaveDays
      onJobYears
      onAge
      onYoungAge
      onJobClass
      onManagerState
      onTrainerState
      onCountOfChildren
      onExposedToRadiationState
      onPensionerState
      onBlindnessSate
      onStudy
      onJobGrade
      onAge50Years
      onESZJTV19
      onCovidPlus10
      onEmergency
      other
      onPreviousYear
      onPreviousYearBaseLeaves
      onPreviousYearAdditionalLeaves
      proportionalLeaveDays
      availableLeaveDays
      availableStudyLeaveDays
      availableESZJTV19LeaveDays
      availableCovidPlus10LeaveDays
      availableEmergencyLeaveDays
      fulfilledLeaveDays
      fulfilledStudyDays
      fulfilledAbsenceDays
      fulfilledESZJTV19Days
      fulfilledCovidPlus10Days
      fulfilledEmergencyDays
      _id
    }
  }
`;

const LeaveDetailsChartContainer = ({ selectedYear }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  let { contractCode } = useCurrentUserState();

  const colorPalette = {
    baseLeaveDays: theme.chartPalette.denimBlue.main,
    onAge: theme.chartPalette.lightOrange.main,
    onCountOfChildren: theme.chartPalette.camellia.main,
    onStudy: theme.chartPalette.turquoise.main,
    onPreviousYear: theme.chartPalette.pollen.main,
    onCovidPlus10: theme.chartPalette.lavenderGray.main,
    onExposedToRadiationState: theme.chartPalette.mint.main,
    onBlindnessSate: theme.chartPalette.pebble.main,
    onJobClass: theme.chartPalette.stormGray.main,
    onJobGrade: theme.chartPalette.cream.main,
    onManagerState: theme.chartPalette.cerulian.main,
    onPensionerState: theme.chartPalette.mistBlue.main,
    onTrainerState: theme.chartPalette.flamingo.main,
    onYoungAge: theme.chartPalette.darkOlive.main,
    other: theme.chartPalette.grapefruit.main,
    onESZJTV19: theme.chartPalette.roseGold.main,
    onJobYears: theme.chartPalette.flash.main,
    onPreviousYearBaseLeaves: theme.chartPalette.redRose.main,
    onAge50Years: theme.chartPalette.peach.main,
    onPreviousYearAdditionalLeaves: theme.chartPalette.camel.main,
    onEmergency: theme.chartPalette.papaya.main,
  };

  const [loaded, setLoaded] = React.useState(false);

  const {
    data: LeaveDetails,
    loading: LeaveDetailsLoading,
    refetch,
  } = useQuery(QUERY, {
    skip: !contractCode || !selectedYear,
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: { contract: contractCode, year: selectedYear },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setLoaded(true);
    },
    onError: (err) => {
      console.log("err", err);
    },
  });

  useSubscription(LEAVES_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    skip: !contractCode || !selectedYear,
    onData: () => {
      refetch();
    },
  });

  let myFulfilledLeaveDetails = LeaveDetails?.leaveDetailsByContract || [];
  /** Result */
  let chartData = [];

  /**
   * Contruct result array
   * Filter only non zero values
   */
  if (myFulfilledLeaveDetails) {
    let resultData = myFulfilledLeaveDetails;

    /** Fill up result array */
    for (const property in resultData) {
      if (resultData[property] !== 0) {
        let item = {
          label: t(`Leave.${property}`),
          value: resultData[property],
          color: colorPalette[property],
        };
        if (
          property !== "_id" &&
          property !== "__typename" &&
          property !== "proportionalLeaveDays" &&
          property !== "availableLeaveDays" &&
          property !== "availableStudyLeaveDays" &&
          property !== "availableESZJTV19LeaveDays" &&
          property !== "availableCovidPlus10LeaveDays" &&
          property !== "availableEmergencyLeaveDays" &&
          property !== "fulfilledLeaveDays" &&
          property !== "fulfilledStudyDays" &&
          property !== "fulfilledAbsenceDays" &&
          property !== "fulfilledESZJTV19Days" &&
          property !== "fulfilledCovidPlus10Days" &&
          property !== "fulfilledEmergencyDays"
        ) {
          chartData.push(item);
        }
      }
    }

    const allAvailableLeaveDays =
      myFulfilledLeaveDetails?.availableLeaveDays +
      myFulfilledLeaveDetails?.availableStudyLeaveDays +
      myFulfilledLeaveDetails?.availableESZJTV19LeaveDays +
      myFulfilledLeaveDetails?.availableCovidPlus10LeaveDays +
      myFulfilledLeaveDetails?.availableEmergencyLeaveDays +
      myFulfilledLeaveDetails?.fulfilledLeaveDays +
      myFulfilledLeaveDetails?.fulfilledStudyDays +
      myFulfilledLeaveDetails?.fulfilledESZJTV19Days +
      myFulfilledLeaveDetails?.fulfilledCovidPlus10Days +
      myFulfilledLeaveDetails?.fulfilledEmergencyDays +
      myFulfilledLeaveDetails?.onPreviousYearBaseLeaves +
      myFulfilledLeaveDetails?.onPreviousYearAdditionalLeaves;

    return (
      <Chart
        chartSize={216}
        dataSet={chartData}
        summaryLabel={t("Leave.summary")}
        summaryType={t("Leave.leave")}
        summaryValue={isNaN(allAvailableLeaveDays) ? 0 : allAvailableLeaveDays}
        loading={loaded ? false : LeaveDetailsLoading}
      />
    );
  }
  return null;
};

export default LeaveDetailsChartContainer;
