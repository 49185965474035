import { useQuery } from "@apollo/react-hooks";
import { GET_MY_TIME_EXAMINERS } from "my-time/graphql/queries";

const useExaminers = ({ contractCode, locale }) => {
  const { data, loading, error } = useQuery(GET_MY_TIME_EXAMINERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      contractCode,
      locale,
    },
  });

  return {
    data: data?.getMyTimeExaminers || [],
    loading,
    error,
  };
};

export default useExaminers;
