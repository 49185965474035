import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: ({ centerTextLeft, centerTextTop }) => ({
    position: "absolute",
    left: centerTextLeft,
    top: centerTextTop,
    width: 100,
    textAlign: "center",
  }),
  summaryValue: {
    height: 35,
    width: 40,
    margin: "auto",
    marginBottom: 4,
    marginTop: 4,
  },
  summaryLabel: {
    height: 20,
    width: 80,
    margin: "auto",
    marginBottom: 2,
    marginTop: 2,
  },
  summaryType: {
    height: 20,
    width: 80,
    margin: "auto",
    marginBottom: 2,
    marginTop: 2,
  },
});

export default useStyles;
