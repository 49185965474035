import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    leaveTitle: {
        fontSize: "20px",
        letterSpacing: "0.15px",
        padding: "10px 16px",
        color: theme.palette.gray80.main,
    },
    leaveLineChart: {
        marginBottom: "24px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "8px",
        overflow: "hidden",
        position: "relative",
    },
    availableLeaveDays: {
        textAlign: "center",
        backgroundColor: theme.palette.greenBase.main,
        color: theme.palette.white.main,
        height: "24px",
        fontSize: "12px",
        lineHeight: "24px",
        borderTopLeftRadius: "8px",
    },
    availableLeaveDaysEdited: {
        textAlign: "center",
        backgroundColor: theme.palette.greenBase.main,
        color: theme.palette.white.main,
        height: "24px",
        fontSize: "12px",
        lineHeight: "24px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
    requestedLeaveDays: {
        textAlign: "center",
        backgroundColor: theme.palette.yellowBase.main,
        color: theme.palette.white.main,
        height: "24px",
        fontSize: "12px",
        lineHeight: "24px",
    },
    requestedLeaveDaysEdited: {
        textAlign: "center",
        backgroundColor: theme.palette.yellowBase.main,
        color: theme.palette.white.main,
        height: "24px",
        fontSize: "12px",
        lineHeight: "24px",
        borderTopRightRadius: "8px",
    },
    requestedLeaveDaysEditedTLTR: {
        textAlign: "center",
        backgroundColor: theme.palette.yellowBase.main,
        color: theme.palette.white.main,
        height: "24px",
        fontSize: "12px",
        lineHeight: "24px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
    requestedLeaveDaysEditedTR: {
        textAlign: "center",
        backgroundColor: theme.palette.yellowBase.main,
        color: theme.palette.white.main,
        height: "24px",
        fontSize: "12px",
        lineHeight: "24px",
        borderTopRightRadius: "8px",
    },
    requestedLeaveDaysEditedTL: {
        textAlign: "center",
        backgroundColor: theme.palette.yellowBase.main,
        color: theme.palette.white.main,
        height: "24px",
        fontSize: "12px",
        lineHeight: "24px",
        borderTopLeftRadius: "8px",
    },
    fulfilledLeaveDays: {
        textAlign: "center",
        color: theme.palette.white.main,
        height: "24px",
        backgroundColor: theme.palette.primary.main,
        fontSize: "12px",
        lineHeight: "24px",
        borderTopRightRadius: "8px",
    },
    fulfilledLeaveDaysEdited: {
        textAlign: "center",
        color: theme.palette.white.main,
        height: "24px",
        backgroundColor: theme.palette.primary.main,
        fontSize: "12px",
        lineHeight: "24px",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
    },
    fulfilledLeaveDaysEditedTR: {
        textAlign: "center",
        color: theme.palette.white.main,
        height: "24px",
        backgroundColor: theme.palette.primary.main,
        fontSize: "12px",
        lineHeight: "24px",
        borderTopRightRadius: "8px",
    },
    fulfilledLeaveDaysEditedTLTR: {
        textAlign: "center",
        color: theme.palette.white.main,
        height: "24px",
        backgroundColor: theme.palette.primary.main,
        fontSize: "12px",
        lineHeight: "24px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
    leaveTooltip: {
        fontSize: "16px",
        lineHeight: "20px",
        padding: "2px 8px",
    },
    leaveTooltipLeaveTitleLine: {
        color: theme.palette.gray60.main,
        fontSize: "12px",
        textAlign: "center",
    },
    leaveTooltipLeaveTitle: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: "4px",
    },
}));

export default useStyles;
