import { useQuery } from "@apollo/react-hooks";
import { GET_VERSION } from "graphql/queries";
import { currentVersionState, showDialogState } from "states";
import { useRecoilState } from "recoil";
import useCountDown from "react-countdown-hook";
import usePolling from "hooks/use-polling";

/**
 * Custom hook to produce all data for refresh process
 * @returns {Object}
 */
export default function useGetVersion() {
  const INITIAL_COUNTER_TIME = 5 * 60 * 1000; // initial time in milliseconds, defaults to 60000
  const COUNTER_INTERVAL = 1000; // interval to change remaining time amount, defaults to 1000

  const [timeToRefresh, { start }] = useCountDown(
    INITIAL_COUNTER_TIME,
    COUNTER_INTERVAL
  );

  const [currentVersion, setCurrentVersion] =
    useRecoilState(currentVersionState);

  const [showRefreshDialog, setShowRefreshDialog] =
    useRecoilState(showDialogState);

  const { data } = useQuery(GET_VERSION, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    pollInterval: usePolling(1000 * 60 * 5),
    onCompleted: ({ getVersion }) => {
      if (!currentVersion) {
        setCurrentVersion(getVersion);
      }
      if (currentVersion && getVersion !== currentVersion) {
        setShowRefreshDialog(true);
        start();
      }
    },
  });

  const formatTime = (timeInMs) => {
    let seconds = Math.floor((timeInMs / 1000) % 60);
    let minutes = Math.floor((timeInMs / 1000 / 60) << 0);

    //minutes = minutes < 1 ? "0" : minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 1 ? "00" : seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${seconds}`;
  };

  const onRefresh = () => {
    setShowRefreshDialog(false);
    setCurrentVersion(data?.getVersion);
    window.location.reload(true);
  };

  if (
    data &&
    currentVersion &&
    currentVersion !== data?.getVersion &&
    timeToRefresh === 1000
  ) {
    onRefresh();
  }

  return {
    hasToRefresh: data && currentVersion !== data?.getVersion,
    timeToRefreshValue: timeToRefresh,
    timeToRefresh: formatTime(timeToRefresh),
    startCounter: start,
    showRefreshDialog,
    setShowRefreshDialog,
    onRefresh,
    currentVersion,
  };
}
