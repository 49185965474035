const parseJdDate = (dateString) => {
  if (!dateString) return new Date();
  if (dateString?.length !== 8) return new Date();
  let trimmed = dateString?.trim();
  if (trimmed?.length === 0) {
    return null;
  }
  let year = dateString.slice(0, 4);
  let month = dateString.slice(4, 6);
  let day = dateString.slice(6, 8);
  let dateStringISO = `${year}-${month}-${day}`;
  return new Date(dateStringISO);
};

export default parseJdDate;
