import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useStyles from "./day-header.style";

export default function DayHeader({ date }) {
  const { i18n } = useTranslation();
  let label = moment(date).locale(i18n.language).format("dddd");
  const classes = useStyles();
  return (
    <Box
      height="45px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography className={classes.root}>{label}</Typography>
    </Box>
  );
}
