import React from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function CollapseLabel({ label, expanded, handleExpandClick }) {
    return (
        <Box
            id="my-hr-admin-users-import-title"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 16px"
        >
            <Box
                style={{
                    fontSize: 20,
                    letterSpacing: 1.15,
                    padding: "10px 16px",
                    color: "#5e6366",
                }}
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Box marginRight="26px">{label}</Box>
            </Box>
            <Box>
                <IconButton
                    id="my-hr-users-import-iconbutton"
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    style={
                        expanded
                            ? { padding: 0, transform: "rotate(180deg)" }
                            : { padding: 0 }
                    }
                >
                    <ExpandMoreIcon />
                </IconButton>
            </Box>
        </Box>
    );
}
