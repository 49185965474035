import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
//import LeaveSummaryChart from "my-leave/components/my-leave-leave-summary-chart";
import LeaveDetailsChart from "my-leave/components/my-leave-leave-details-chart";
import MyFulfilledLeaves from "my-leave/components/my-leave-my-fulfilled-leaves";
import PreviousFulfilledLeaves from "my-leave/components/my-leave-previous-fulfilled-leaves-table";
import { useHistory } from "react-router-dom";
import MyLeaveRequestsTable from "my-leave/components/my-leave-my-leave-requests-table";
import OcPageTransition from "oc/components/oc-page-transition";
import { currentUserState } from "states/current-user-state";
import { useRecoilValue } from "recoil";
/*import { useRecoilState } from "recoil";
import { leaveSelectedTabState } from "states/leave-selected-tab-state";
import useLeaveSummaryChartData from "hooks/use-my-leave-summary-chart-data";*/
import LeaveBaseCard from "my-leave/components/my-leave-leave-base-card";
import LeaveStudyCard from "my-leave/components/my-leave-leave-study-card";
import LeaveCovidplustenCard from "my-leave/components/my-leave-leave-covid-plus-ten-card";
import LeaveEszjtvCard from "my-leave/components/my-leave-leave-eszjtv-card";
import LeaveEmergencyCard from "my-leave/components/my-leave-leave-emergency-card";
import MyLeaveExaminers from "./components/my-leave-my-leave-examiners/my-leave-my-leave-examiners";
import useExaminersName from "hooks/use-examiners-name";

export default function MyLeaveRequestsPage() {
  let thisYear = new Date().getFullYear().toString();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  let { contractCode } = useRecoilValue(currentUserState);

  async function handleSelectedYear(o) {
    history.push(`/my-leave/my-yearly-leaves/${o.year}`);
  }

  const {
    data: examinersData,
    loading: examinersLoading,
    error: examinersError,
  } = useExaminersName({ locale: i18n.language });

  let disabledNewRequest = false;

  /* const [leaveSelectedTab, setLeaveSelectedTab] = useRecoilState(
        leaveSelectedTabState
    );

    const handleChangeLeaveTab = (event, newValue) => {
        setLeaveSelectedTab(newValue);
    };

    const { data } = useLeaveSummaryChartData({
        year: thisYear,
        contract: contractCode,
    });*/

  return (
    <OcPageTransition>
      <Box>
        <Grid container spacing={0} style={{ padding: 8, paddingBottom: 0 }}>
          <Grid item xs={5} sm={5} md={5} lg={5} style={{ padding: 8 }}>
            <OcCard label={t("Leave.leaveSummary")}>
              <>
                <LeaveBaseCard
                  selectedYear={thisYear}
                  contract={contractCode}
                />
                <LeaveStudyCard
                  selectedYear={thisYear}
                  contract={contractCode}
                />
                <LeaveCovidplustenCard
                  selectedYear={thisYear}
                  contract={contractCode}
                />
                <LeaveEszjtvCard
                  selectedYear={thisYear}
                  contract={contractCode}
                />
                <LeaveEmergencyCard
                  selectedYear={thisYear}
                  contract={contractCode}
                />
              </>
            </OcCard>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={5} style={{ padding: 8 }}>
            <Box id="leave-details-chart">
              <OcCard label={t("Leave.leaveDetails")}>
                <LeaveDetailsChart selectedYear={thisYear} />
              </OcCard>
            </Box>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} style={{ padding: 8 }}>
            <OcCard
              label={t("EXAMINERS")}
              loading={!examinersData && examinersLoading}
              error={examinersError}
            >
              <>
                <MyLeaveExaminers examinersData={examinersData} />
              </>
            </OcCard>
          </Grid>
        </Grid>
        <Grid container spacing={0} style={{ padding: 8, paddingBottom: 0 }}>
          <Grid container spacing={0} style={{ padding: 8 }}>
            <Grid item sm={12} md={12} lg={12} style={{ padding: "0px" }}>
              <OcCard label={t("MY_LEAVES_AND_ABSENCES")}>
                <MyLeaveRequestsTable
                  selectedYear={thisYear}
                  disabledNewRequest={disabledNewRequest}
                />
              </OcCard>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} lg={12} style={{ padding: 8 }}>
            <OcCard label={t("MY_FULFILLED_LEAVES_AND_ABSENCES")}>
              <MyFulfilledLeaves selectedYear={thisYear} />
            </OcCard>
          </Grid>

          <Grid item sm={12} md={12} lg={12} style={{ padding: 8 }}>
            <OcCard label={t("Leave.myPreviousLeaves")}>
              <PreviousFulfilledLeaves onSelectYear={handleSelectedYear} />
            </OcCard>
          </Grid>
        </Grid>
      </Box>
    </OcPageTransition>
  );
}
