import gql from "graphql-tag";

export const ADD_MASS_ROLE = gql`
  mutation addMassRole(
    $roleFilters: RoleFilterInput!
    $roleFiltering: RoleFilterInput!
    $module: String!
    $role: String!
  ) {
    addMassRole(
      roleFilters: $roleFilters
      roleFiltering: $roleFiltering
      module: $module
      role: $role
    ) {
      processed
      added
      errors {
        employee
        employeeName
        contract
        error
        employmentType
      }
    }
  }
`;
