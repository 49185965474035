import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_IS_EXAMINER_CONTRACT } from "graphql/queries";
import { CONTRACT_SUBSCRIPTION } from "graphql/subcriptions";
import usePolling from "hooks/use-polling";

export default function useIsExaminerContract({ contract }) {
  const { data, loading, error, refetch } = useQuery(GET_IS_EXAMINER_CONTRACT, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    skip: !contract,
    variables: { contract },
  });

  useSubscription(CONTRACT_SUBSCRIPTION, {
    onData: () => {
      refetch();
    },
  });

  return { data: data?.isExaminerContract || "", loading, error };
}
