import gql from "graphql-tag";

export const LEAVE_YEARLY_DAYS_BY_CONTRACT_SUBSCRIPTION = gql`
  subscription leaveYearlyDaysByContract($contract: String!) {
    leaveYearlyDaysByContract(contract: $contract) {
      year
      availableLeaveDays
      fulfilledLeaveDays
      fulfilledAbsenceDays
    }
  }
`;
