import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_RECORDING_PERIOD } from "my-time/graphql/queries";
import { MY_TIME_SUBSCRIPTION } from "my-time/graphql/subscriptions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import 'moment/locale/hu';

const useRecordingPeriod = () => {
  const { data, loading, error, refetch } = useQuery(GET_RECORDING_PERIOD, {
    fetchPolicy: "cache-and-network",
  });

  useSubscription(MY_TIME_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  const { i18n } = useTranslation();

  const recordingPeriod = data?.getRecordingPeriod || {};

  const dateString = `${recordingPeriod?.year}-${recordingPeriod?.month}-15`;

  moment.locale(i18n.language);

  const monthTranslation = i18n.t(moment(dateString).format("MMMM"));
  const date = `${moment(dateString).format("YYYY.")} ${monthTranslation}`;

  return {
    data: date || "",
    startDate: moment(dateString).startOf("month").format("YYYY-MM-DD"),
    endDate: moment(dateString).endOf("month").format("YYYY-MM-DD"),
    loading,
    error,
  };
};

export default useRecordingPeriod;
