import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "unset",
        height: 152,
        borderRadius: 8,
        color: theme.palette.gray60.main,
    },
    muiSvgIcon: {
        marginTop: 24,
        color: theme.palette.gray60.main,
    },
    textContainer: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
    },
    text: {
        fontSize: 16,
        marginTop: 16,
        marginBottom: 24,
        color: theme.palette.gray60.main,
        "&:after": {
            content: "'ide'",
            color: "red",
            transform: "translateX(-100%)",
            position: "absolute",
        },
    },
    input: {
        width: "360px",
        height: "4px",
    },
}));

export default useStyles;
