import React, { useState } from "react";
import "moment/locale/hu";
import usePayrollData from "hooks/use-myhr-payroll-data";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import moment from "moment";
import "moment/locale/hu";
import { useTranslation } from "react-i18next";
import Currency from "oc/components/oc-currency";
import CurrencyBar from "my-hr/components/my-hr-currency-bar";
import SalarySummary from "my-hr/components/my-hr-salary-summary";
import Button from "@material-ui/core/Button";
import BackToDate from "my-hr/components/my-hr-back-to-date";
import Paycard from "my-hr/components/my-hr-paycard";
import useClientState from "hooks/use-oc-client-state";
import Skeleton from "@material-ui/lab/Skeleton";

export default function MyHrPayrollMonthlyData({
  year,
  month,
  contract,
  showComponent,
  onBack,
}) {
  const { t, i18n } = useTranslation();

  const [showPaycard, setShowPaycard] = useClientState("showPaycard", false);

  const { data, lastPaycardData, loading } = usePayrollData({
    year,
    month,
    contract: contract?.code,
  });

  let monthlyData = data?.find((item) => item.month === month);

  let label = moment(`${year}-${month}`).locale(i18n.language).format("MMMM");

  let currentYear = moment().format("YYYY");
  let currentMonth = moment().format("MM");

  if (year === currentYear && month === currentMonth) {
    if (monthlyData?.netWage === 0 && monthlyData?.grossWage === 0) {
      if (lastPaycardData) {
        monthlyData = lastPaycardData;
        label = moment(`${monthlyData?.year}-${monthlyData?.month}`)
          .locale(i18n.language)
          .format("YYYY. MMMM");
      }
    }
  }

  let grossWage = 0 || monthlyData?.grossWage;

  let yearToShow = monthlyData?.year ? monthlyData?.year : year;
  let monthToShow = monthlyData?.month ? monthlyData?.month : year;

  const [selectedPayroll, setSelectedPayroll] = useState(monthToShow);

  function handleNextPaycard() {
    setSelectedPayroll((item) => item - 1 + 2);
  }

  function handlePreviousPaycard() {
    setSelectedPayroll((item) => item - 1);
  }

  const monthChanger = ("0" + selectedPayroll).slice(-2);

  if (selectedPayroll === 13) {
    setSelectedPayroll(1);
  }

  if (selectedPayroll === 0) {
    setSelectedPayroll(12);
  }

  function handleShowPaycard() {
    setShowPaycard(true);
    setSelectedPayroll(monthlyData?.month);
  }

  function handleClosePaycard() {
    setShowPaycard(false);
    setSelectedPayroll(lastPaycardData?.month);
  }

  if (showPaycard) {
    return (
      <Paycard
        contract={contract?.code}
        year={yearToShow}
        month={monthChanger}
        onClose={handleClosePaycard}
        previousPaycard={handlePreviousPaycard}
        nextPaycard={handleNextPaycard}
      />
    );
  }

  return (
    <React.Fragment>
      <BackToDate
        selectedYear={year}
        showComponent={showComponent}
        onBack={onBack}
      />
      <Card
        variant="outlined"
        style={{
          borderRadius: "4px",
          borderColor: "white",
          padding: "16px",
        }}
        id="payroll-monthly-card"
      >
        <Box
          style={{
            fontSize: "34px",
            letterSpacing: "0.25px",
            padding: "20px 0px 16px 0px",
            color: "#5E6366",
            borderBottom: "2px solid #C6CACC",
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          <Box id="date-label">{label}</Box>
        </Box>
        {grossWage === 0 ? (
          <Box padding="30px 16px 14px 16px" style={{ textAlign: "center" }}>
            {loading ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Skeleton width="330px" height="24px" />
              </Box>
            ) : (
              <span>{t("NO_PAYROLL_DATA")}</span>
            )}
          </Box>
        ) : (
          <React.Fragment>
            <Box
              id="currency-data"
              style={{
                fontSize: "48px",
                letterSpacing: "0.25px",
                padding: "16px 0px",
                color: "#CC0033",
                textAlign: "center",
              }}
            >
              <Currency value={monthlyData?.netWage} />
            </Box>
            <SalarySummary data={monthlyData} />
            <Box bgcolor="#5E6366" borderRadius="8px" padding="16px 24px">
              <CurrencyBar
                label={t("GROSS_WAGE")}
                value={monthlyData?.grossWage}
                total={monthlyData?.grossWage}
              />
              <CurrencyBar
                label={t("NET_WAGE")}
                value={monthlyData?.netWage}
                total={monthlyData?.grossWage}
              />
              <CurrencyBar
                label={t("ALL_TAX_DEDUCTIONS")}
                value={monthlyData?.allTaxDeductions}
                total={monthlyData?.grossWage}
              />
              <CurrencyBar
                label={t("OTHER_DEDUCTIONS")}
                value={Math.abs(monthlyData?.otherDeductions)}
                total={monthlyData?.grossWage}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              marginTop="40px"
              marginBottom="16px"
            >
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleShowPaycard}
              >
                {t("VIEW_MY_PAYCARD")}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
}
