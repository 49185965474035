import React, { useState } from "react";
import { Box, Dialog, Typography, Collapse } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import WorkDaysCounter from "oc/components/oc-time-date-counter";
import useStyles from "my-leave/components/my-leave-new-request-dialog/my-leave-new-request-dialog.style";
import OcTimePicker from "oc/components/oc-time-picker";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { CREATE_MY_TIME_REQUEST } from "my-time/graphql/mutations/create-my-time-request";
import { useMutation } from "@apollo/react-hooks";
import TextField from "@material-ui/core/TextField";
import MyTimeRequestTypeInterface from "my-time/components/my-time-request-type-interface";
import useProperty from "hooks/use-oc-property";
import {
  parseDateTime,
  useNotification,
  useProtectedPeriodsData,
  useWorkTimeLimits,
  validateNewRequest,
  timeConvert,
} from "my-time/utils";
import MyTimeSelectMyTimeType from "my-time/components/my-time-select-my-time-type";

const MyTimeNewRequestDialog = ({
  action,
  contractCode,
  data,
  handleClose,
  open,
  list,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const setNotification = useNotification();
  const [selectedMyTimeType, setSelectedMyTimeType] = useState("");

  if (data?.myTimeType && !selectedMyTimeType) {
    const isExist = list?.find(
      (item) =>
        moment(item.date).format("YYYY-MM-DD") ===
          moment(data?.date).format("YYYY-MM-DD") &&
        item?.myTimeType === "OVERTIME"
    );
    const isWorkingTime = list?.find(
      (item) =>
        moment(item?.date).format("YYYY-MM-DD") ===
          moment(data?.date).format("YYYY-MM-DD") &&
        item?.myTimeType === "WORKING_TIME"
    );
    if (isWorkingTime) {
      data.showMyTimeTypeSelect = false;
    }

    if (isExist && !isWorkingTime) {
      setSelectedMyTimeType("STAND_BY_CALL");
      data.showMyTimeTypeSelect = false;
    } else {
      if (data?.myTimeType !== "WORKING_TIME") {
        setSelectedMyTimeType("OVERTIME");
      }
    }
  }

  const label = selectedMyTimeType
    ? t(selectedMyTimeType)
    : t(data?.myTimeType);

  const effectiveMyTimeType = selectedMyTimeType || data?.myTimeType;

  const { protectedTimeRanges } = useProtectedPeriodsData({
    list,
    data: { ...data, myTimeType: selectedMyTimeType },
  });

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState({
    state: false,
    messages: [],
  });
  const [timePeriod, setTimePeriod] = useState(0);
  const [timePeriodInfo, setTimePeriodInfo] = useState("");

  useProperty({
    resource: "myTime",
    key: "timePeriod",
    onCompleted: ({ value }) => {
      const newTimePeriod = parseInt(value);
      setTimePeriod(newTimePeriod);
      if (newTimePeriod === 10) {
        setTimePeriodInfo(t("10_MINUTES_TIME_PERIOD_INFO"));
      } else if (newTimePeriod === 30) {
        setTimePeriodInfo(t("30_MINUTES_TIME_PERIOD_INFO"));
      }
    },
  });

  const {
    workTimeData,
    weeklyWorktimeLimit,
    weeklyVoluntaryOvertimeLimit,
    timeSummary,
  } = useWorkTimeLimits({
    contractCode,
    date: data?.date,
    startTime,
    endTime,
    myTimeType: effectiveMyTimeType,
    error,
    setError,
    list,
  });

  if (
    action === "add" &&
    data?.myTimeType === "OVERTIME" &&
    data?.startTime &&
    !startTime
  ) {
    setStartTime(data?.startTime);
  }

  if (action === "edit" && !startTime) {
    setStartTime(data?.startTime);
  }

  if (action === "edit" && !endTime) {
    setEndTime(data?.endTime);
  }

  if (startTime && endTime && saveButtonDisabled) {
    setSaveButtonDisabled(false);
  }

  const handleStartTimeChange = (newValue) => {
    if (error.state) {
      setError({ state: false, messages: [] });
    }
    if (moment(newValue).isValid()) {
      const time = moment(newValue).format("HH:mm");
      const date = moment(data?.date).format("YYYY-MM-DD");
      const value = moment(`${date} ${time}`).toDate();
      setStartTime(value);
    }
  };

  const handleEndTimeChange = (newValue) => {
    if (error.state) {
      setError({ state: false, messages: [] });
    }
    if (moment(newValue).isValid()) {
      const time = moment(newValue).format("HH:mm");
      const date = moment(data?.date).format("YYYY-MM-DD");
      let value = moment(`${date} ${time}`).toDate();
      if (value < startTime) {
        value = moment(value).add(1, "days").toDate();
      }
      setEndTime(value);
    }
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const [createRequest] = useMutation(CREATE_MY_TIME_REQUEST, {
    onCompleted: () => {
      setNotification({
        type: "success",
        message: t("MY_TIME_REQUEST_CREATE_SUCCEEDED"),
      });

      handleClear();
      handleClose();
    },
    onError: () => {
      setSaveButtonDisabled(true);
    },
  });

  const handleClear = () => {
    setError({ state: false, messages: [] });
    setStartTime(null);
    setEndTime(null);
    setSelectedMyTimeType("");
    setMessage("");
  };

  const handleCancel = () => {
    handleClose();
    setError({ state: false, messages: [] });
    setSelectedMyTimeType("");
    setMessage("");
    setTimeout(() => {
      handleClear();
    }, 500);
  };

  const handleSave = () => {
    const createParams = workTimeData?.map((item) => {
      const startTime = parseDateTime(item?.startTime);
      const endTime = parseDateTime(item?.endTime);
      const params = {
        contractCode: contractCode,
        myTimeType: effectiveMyTimeType,
        hours: timeConvert(item?.workTime),
        state: "requested",
        timeDataTypeCode: item?.timeDataTypeCode,
        timeDataTypeName: item?.timeDataTypeName,
        startTime: startTime,
        endTime: endTime,
        message: message,
      };
      return params;
    });
    const newData = { ...data };
    newData.myTimeType = effectiveMyTimeType;
    validateNewRequest({
      requests: createParams,
      list,
      data: newData,
      protectedTimeRanges,
      timePeriod,
      weeklyWorktimeLimit,
      weeklyVoluntaryOvertimeLimit,
      error,
      setError,
      startTime,
      endTime,
    });

    if (
      data?.myTimeType === "WORKING_TIME" &&
      data?.startTime === startTime &&
      data?.endTime === endTime
    ) {
      setError({ state: true, messages: [t("DATA_NOT_CHANGED")] });
      return;
    }

    if (error.messages.length > 0) {
      return;
    }
    createParams?.forEach((params) => {
      createRequest({ variables: params });
    });
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return false;
        }
        handleClose();
      }}
    >
      <Box padding="16px" width="460px">
        <Box
          style={{
            fontSize: "20px",
            letterSpacing: "0.15px",
            padding: "0px 16px 10px 16px",
            color: "#5E6366",
            borderBottom: "2px solid #C6CACC",
          }}
        >
          <Typography className="oc-card-h6-title" variant="h6">
            {label}
          </Typography>
        </Box>
        <Box height="32px" />
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" style={{ color: "#CC0033" }}>
            {new Date(data?.date)
              .toLocaleDateString("hu-HU", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
              .replace(",", "")}
          </Typography>
        </Box>
        <MyTimeSelectMyTimeType
          data={data}
          selectedMyTimeType={selectedMyTimeType}
          onSelectMyTimeType={setSelectedMyTimeType}
        />
        <Box className={classes.centerContainer}>
          <Box className={classes.pickerContainer}>
            <OcTimePicker
              label={t("START_DATE")}
              id="start-time-input"
              value={startTime}
              onChange={handleStartTimeChange}
            />
            <OcTimePicker
              label={t("END_DATE")}
              id="end-time-input"
              value={endTime}
              onChange={handleEndTimeChange}
            />
          </Box>
          <WorkDaysCounter
            time={timeSummary}
            counterLabel={t("WORKING_TIME")}
            //workDaysloading={workDaysloading}
          />
        </Box>
        {timePeriodInfo && (
          <Box marginTop="-30px" marginBottom="24px">
            <Typography variant="body2">{timePeriodInfo}</Typography>
          </Box>
        )}
        <Collapse in={workTimeData?.length > 0}>
          {workTimeData?.map((item, index) => {
            return (
              <Box key={`type-interface-${index}}`}>
                <MyTimeRequestTypeInterface
                  data={item}
                  myTimeType={data?.myTimeType}
                />
                <Box height="16px" />
              </Box>
            );
          })}
        </Collapse>
        <Box height="16px" />
        <TextField
          id="my-time-message-input"
          label={t("Leave.message")}
          multiline
          value={message}
          onChange={handleMessage}
          fullWidth
          variant="outlined"
          helperText={
            message.length === 0
              ? `(${t("Common.optional")})`
              : `${message.length}/255`
          }
          style={{
            height: "auto",
          }}
          inputProps={{ maxLength: 255 }}
          FormHelperTextProps={
            message.length === 0
              ? { style: { textAlign: "left" } }
              : { style: { textAlign: "right" } }
          }
        />
        <Collapse in={error.state}>
          <Box
            style={{
              width: "100%",
              color: "#cc0033",
              textAlign: "center",
              paddingTop: "18px",
            }}
          >
            {error.messages.map((message, index) => {
              return (
                <Typography
                  key={`error-message-${index}`}
                  className={classes.errorMessage}
                  variant="body2"
                >
                  {t(message)}
                </Typography>
              );
            })}
          </Box>
        </Collapse>
        <Box height="32px" />
        <Box className={classes.line} />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={saveButtonDisabled || error.state}
        >
          {t("Common.save")}
        </Button>
        <Box className={classes.divider} />
        <Button
          id="myleave-new-request-dialog-cancel-button"
          text={t("Common.cancel")}
          fullWidth
          onClick={handleCancel}
        >
          {t("Common.cancel")}
        </Button>
        <Box height="16px" />
      </Box>
    </Dialog>
  );
};

export default MyTimeNewRequestDialog;
