import * as Yup from "yup";
import { CREATE_CHANGE_REQUEST } from "graphql/mutations";

export const placeOfBirthDialogDef = {
  type: "placeOfBirth",
  dialogLabel: "EDIT_PLACE_OF_BIRTH",
  mutation: CREATE_CHANGE_REQUEST,
  items: [
    {
      label: "Common.validity",
      name: "validFrom",
      type: "datePicker",
      validation: Yup.date().required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Employee.placeOfBirth",
      name: "placeOfBirth",
      dataField: "placeOfBirth",
      type: "text",
      validation: Yup.string()
        .trim()
        .required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Leave.message",
      name: "initialMessage",
      type: "textarea",
      validation: Yup.string(),
    },
  ],
};
