import React from "react";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CLEAN_CACHE } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import useOcSnackbar from "hooks/use-oc-snackbar";

export default function MyHrAdminSystemSettingsCacheMenu() {
  const { t } = useTranslation();

  const { setOpenMessage } = useOcSnackbar();

  const [cleanCache] = useMutation(CLEAN_CACHE, {
    onCompleted(data) {
      if (data.cleanCache) {
        setOpenMessage({
          type: "success",
          message: t("CLEAR_CACHE_SUCCEEDED"),
        });
      }

      // TODO: Implement notifications?
    },
    onError(err) {
      console.log(err);
    },
  });

  function handleClearCache() {
    cleanCache();
  }

  return (
    <OcCard label={t("CLEAR_CACHE")}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="16px 0px"
      >
        <Button
          id="clear-cache-buttom"
          variant="contained"
          disableElevation
          color="primary"
          onClick={handleClearCache}
        >
          {t("CLEAR_CACHE")}
        </Button>
        <Box padding="32px 0px 0px 0px">
          <Typography variant="caption">{t("CLEAR_CACHE_TEXT")}</Typography>
        </Box>
      </Box>
    </OcCard>
  );
}
