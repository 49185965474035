import React from "react";
import { useTranslation } from "react-i18next";
import getCountries from "oc/utils/get-countries";
import OcFormEditorItemList from "../oc-form-editor-item-list";
import useOcFormEditorSelector from "../../hooks/use-oc-form-editor-selector";

const OcFormEditorCountrySelector = ({
  name,
  label,
  value,
  onChange,
  error,
}) => {
  const { i18n } = useTranslation();
  const countries = getCountries(i18n.language);

  if (!value) {
    value = "HUN";
    onChange("country", value);
  }

  const {
    list,
    search,
    selectedItem,
    handleSearch,
    handleClearSearch,
    handleHasMore,
  } = useOcFormEditorSelector({
    value,
    list: countries,
  });

  return (
    <OcFormEditorItemList
      search={search}
      onSearch={handleSearch}
      onChange={onChange}
      onClearSearch={handleClearSearch}
      list={list}
      onScroll={handleHasMore}
      value={selectedItem}
      label={label}
      name={name}
      error={error}
    />
  );
};

export default OcFormEditorCountrySelector;
