import gql from "graphql-tag";

export const GET_PERMIT = gql`
  query permit {
    permit {
      _id
      requestedAt
      permittedAt
      permittedBy
      cancelledAt
    }
  }
`;
