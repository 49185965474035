import React from "react";
import PropTypes from "prop-types";
import useStyles from "./my-leave-chart-loading-item.style";

const LeaveLoadingItem = ({ width, height }) => {
  const classes = useStyles({ width, height });

  return (
    <div className={classes.root}>
      <div className={classes.innerBlock} />
    </div>
  );
};

LeaveLoadingItem.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

LeaveLoadingItem.defaultProps = {
  width: 100,
  height: 24,
};

export default LeaveLoadingItem;
