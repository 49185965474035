import gql from "graphql-tag";

export const GET_MODULE_ROLES = gql`
  query moduleRoles {
    moduleRoles {
      module
      roles
    }
  }
`;
