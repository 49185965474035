import * as dates from "react-big-calendar/lib/utils/dates";

export const sortEvents = (evtA, evtB, accessors) => {
  var startSort =
    +dates.startOf(accessors.start(evtA), "day") -
    +dates.startOf(accessors.start(evtB), "day");
  var durA = dates.diff(
    accessors.start(evtA),
    dates.ceil(accessors.end(evtA), "day"),
    "day"
  );
  var durB = dates.diff(
    accessors.start(evtB),
    dates.ceil(accessors.end(evtB), "day"),
    "day"
  );
  return (
    startSort || // sort by start Day first
    Math.max(durB, 1) - Math.max(durA, 1) || // events spanning multiple days go first
    !!accessors.allDay(evtB) - !!accessors.allDay(evtA) || // then allDay single day events
    +accessors.start(evtA) - +accessors.start(evtB)
  ); // then sort by start time
};

export const inRange = (e, start, end, accessors) => {
  var eStart = dates.startOf(accessors.start(e), "day");
  var eEnd = accessors.end(e);
  var startsBeforeEnd = dates.lte(eStart, end, "day"); // when the event is zero duration we need to handle a bit differently

  var endsAfterStart = !dates.eq(eStart, eEnd, "minutes")
    ? dates.gt(eEnd, start, "minutes")
    : dates.gte(eEnd, start, "minutes");
  return startsBeforeEnd && endsAfterStart;
};

export const eventsForWeek = (evts, start, end, accessors) => {
  return evts.filter((e) => inRange(e, start, end, accessors));
};

export const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};
