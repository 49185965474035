import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white.main,
  },
  container: {
    color: theme.palette.darkBase.main,
  },
  chevron: {
    cursor: "pointer",
    padding: "10px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.gray20.main,
    },
  },
}));

export default useStyles;
