import React from "react";
import Box from "@material-ui/core/Box";
import useCalendar from "hooks/use-my-leave-calendar";
import { startOfYear, endOfYear, getDayOfYear } from "date-fns";

export default function WeeklyTimelineDateHeader({ date, label, isOffRange }) {
  let startDate = startOfYear(date);
  let endDate = endOfYear(date);

  const { data: Calendar } = useCalendar({ startDate, endDate });
  let day = getDayOfYear(date);
  let dayState = Calendar.charAt(day - 1);

  let color = "#212121";
  if (isOffRange) {
    color = "#ffffff";
  }
  if (dayState === "F") {
    color = "#cc0033";
  }

  return (
    <Box style={{ textAlign: "center" }}>
      <span
        style={{
          fontSize: 16,
          fontWeight: "normal",
          color: color,
        }}
      >
        {label}
      </span>
    </Box>
  );
}
