import gql from "graphql-tag";

export const APPROVE_ALL_MY_LEAVE_REQUESTS = gql`
  mutation approveAllMyLeaveRequests($contractCode: String!) {
    approveAllMyLeaveRequests(contractCode: $contractCode) {
      result
      message
    }
  }
`;
