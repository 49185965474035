import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { InlineIcon } from "@iconify/react";
import plusIcon from "@iconify/icons-mdi/plus";
import useClientState from "hooks/use-oc-client-state";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useQuery } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import getModuleIcon from "oc/utils/get-modul-icon";
import styled from "styled-components";
import { GET_MODULE_ROLES } from "graphql/queries";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import usePolling from "hooks/use-polling";

const StyledSelect = styled(Select)`
  .MuiOutlinedInput-input {
    padding: 16px 14px;
    background-color white
  }
`;

export default function MyHrAddUserRole({ contract, onAddRole, roles }) {
  const [showAddRoleCollapse, setShowAddRoleCollapse] = useClientState(
    `showAddRoleCollapse${contract?.code}`,
    false
  );
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useClientState(
    "selectedModule",
    "MyHR"
  );

  const [selectedRole, setSelectedRole] = useClientState("selectedRole", "");

  const handleChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  useEffect(() => {
    return () => {
      setShowAddRoleCollapse(false);
      setSelectedProduct("MyHR");
      setSelectedRole("");
    };
    // eslint-disable-next-line
  }, []);

  const { data } = useQuery(GET_MODULE_ROLES, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
  });

  let selectedModule = data?.moduleRoles?.find(
    (item) => item.module === selectedProduct
  );

  let availableRoles = selectedModule?.roles || [];

  function handleCancel() {
    setShowAddRoleCollapse(false);
    setSelectedProduct("MyHR");
    setSelectedRole("");
  }

  function handleSelectRole(role) {
    setSelectedRole(role);
  }

  function handleAddRole() {
    let module = selectedModule?.module;
    let role = selectedRole;
    onAddRole({ contract: contract?.code, module, role });
    setShowAddRoleCollapse(false);
    setSelectedProduct("MyHR");
    setSelectedRole("");
  }

  const excludedCodes = [
    "0",
    "5",
    "6",
    "7",
    "8",
    "9",
    "V",
    "Y",
    "N",
    "O",
    "X",
  ];

  function isDisabled({ contract, module, role, typeOfContract }) {
    let isExist = roles?.find(
      (item) =>
        item.contract === contract &&
        item.product === module &&
        item.role === role
    );
    if (isExist) return true;
    if (
      typeOfContract === "Z" &&
      module === "MyLeave" &&
      role === "user"
    ) {
      return true;
    }
    return false;
  }

  return (
    <OcErrorBoundary>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          paddingTop="9px"
          paddingBottom="36px"
        >
          <Button
            id="showDialog"
            name="showDialog"
            variant="contained"
            color="primary"
            startIcon={<InlineIcon icon={plusIcon} />}
            disabled={showAddRoleCollapse}
            onClick={() => setShowAddRoleCollapse(true)}
            disableElevation
          >
            {t("ADD_ROLE")}
          </Button>
        </Box>
        <Collapse in={showAddRoleCollapse} timeout="auto" unmountOnExit>
          <Box display="flex" justifyContent="center">
            <Card
              variant="outlined"
              style={{
                borderRadius: "4px",
                borderColor: "white",
                overflow: "visible",
                marginBottom: "16px",
                backgroundColor: "#F1F3F4",
                width: "728px",
              }}
            >
              <Box padding="16px" dispaly="flex" flexDirection="column">
                <Box
                  fontSize="20px"
                  letterSpacing="0.15px"
                  padding="15px 0px"
                  paddingBottom="30px"
                >
                  {t("ADD_ROLE")}
                </Box>
                <Box display="flex" flexDirection="row" paddingBottom="8px">
                  <Box width="50%" paddingRight="8px">
                    <FormControl
                      variant="outlined"
                      fullWidth
                      style={{ backgroundColor: "white" }}
                    >
                      <InputLabel>{t("MODULE")}</InputLabel>
                      <StyledSelect
                        labelId="demo-simple-outlined-label"
                        id="module-select"
                        value={selectedProduct}
                        onChange={handleChange}
                        label={t("MODULE")}
                      >
                        {data?.moduleRoles
                          ?.filter((module) =>
                            ["MyTime", "MyHR", "MyLeave"].includes(
                              module.module
                            )
                          )
                          .map((module) => {
                            const isDisabled = excludedCodes?.includes(
                              contract?.employmentType?.code
                            );
                            return (
                              <MenuItem
                                disabled={isDisabled}
                                key={module.module}
                                value={module.module}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  {getModuleIcon(module.module)}
                                  <Typography
                                    variant="inherit"
                                    style={{
                                      marginLeft: "22px",
                                    }}
                                  >
                                    {module.module}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            );
                          })}
                      </StyledSelect>
                    </FormControl>
                  </Box>
                  <Box width="50%" paddingLeft="8px">
                    <ButtonGroup
                      orientation="vertical"
                      aria-label="vertical outlined primary button group"
                      fullWidth
                      style={{
                        color: "#E3E5E5",
                      }}
                      variant="contained"
                      disableElevation
                    >
                      {availableRoles
                        ?.filter((role) => {
                          if (
                            (selectedModule?.module === "MyHR" &&
                              role === "examiner") ||
                            (selectedModule?.module === "MyLeave" &&
                              (role === "examiner" ||
                                role === "viewer" ||
                                role === "admin"))
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map((role) => {
                          let disabled = isDisabled({
                            contract: contract?.code,
                            module: selectedModule?.module,
                            role: role,
                          });

                          return (
                            <Button
                              id={role}
                              key={role}
                              onClick={() => handleSelectRole(role)}
                              disableElevation
                              variant="contained"
                              disabled={disabled}
                              style={
                                role === selectedRole
                                  ? {
                                    backgroundColor: "#cc0033",
                                    color: "white",
                                  }
                                  : {
                                    backgroundColor: "white",
                                  }
                              }
                            >
                              {t(`ROLE_${role.toUpperCase()}`)}
                            </Button>
                          );
                        })}
                    </ButtonGroup>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  padding="10px 0px 0px 0px"
                >
                  <Button
                    id="my-hr-add-user-role-cancel-button"
                    onClick={handleCancel}
                  >
                    {t("CANCEL")}
                  </Button>
                  <Button
                    id="my-hr-add-user-role-button"
                    variant="contained"
                    color="primary"
                    disableElevation
                    style={{ marginLeft: "16px" }}
                    disabled={!selectedRole}
                    onClick={handleAddRole}
                  >
                    {t("ADD")}
                  </Button>
                </Box>
              </Box>
            </Card>
          </Box>
        </Collapse>
      </Box>
    </OcErrorBoundary>
  );
}
