import React from "react";
import { Box } from "@material-ui/core";
import OcPageTransition from "oc/components/oc-page-transition";
import { useParams } from "react-router-dom";
import MyTimeShowRequestCard from "my-time/components/my-time-show-request-card/my-time-show-request-card";
import useMyTimeRequest from "my-time/hooks/use-my-time-request";
import useMyTimeMessagesList from "my-time/hooks/use-my-time-messages-list";

const MyTimeShowRequestPage = () => {
  const { id, employee } = useParams();
  const { data, loading, error } = useMyTimeRequest({ _id: id });

  const {
    data: messagesData,
    loading: messagesLoading,
    error: messagesError,
  } = useMyTimeMessagesList({ request: id });

  return (
    <OcPageTransition>
      <Box
        padding="16px"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <MyTimeShowRequestCard
          data={data}
          messages={messagesData}
          loading={loading || messagesLoading}
          error={error || messagesError}
          employeeCode={employee}
        />
      </Box>
    </OcPageTransition>
  );
};

export default MyTimeShowRequestPage;
