import gql from "graphql-tag";

export const CREATE_TAX_DECLARATION_UNDER_25 = gql`
  mutation createTaxDeclarationUnder25(
    $dateOfDeclaration: Date!,
    $noApply: Boolean!,
    $discountLimit: Float!,
    $noApplyAboveLimit: Boolean!,
    ) {
    createTaxDeclarationUnder25(
        dateOfDeclaration: $dateOfDeclaration,
        noApply: $noApply,
        discountLimit: $discountLimit,
        noApplyAboveLimit: $noApplyAboveLimit,
    ) {
      result
      messages
      tableId
    }
  }`;
