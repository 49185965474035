import React from "react";
import { PropTypes } from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import useOcAvatarDI from "hooks/use-oc-avatar";

export default function OcAvatar({
    employee,
    style,
    className,
    useOcAvatar = useOcAvatarDI,
}) {
    const { data } = useOcAvatar({ employee });
    return (
        <Avatar
            id="avatar-img"
            alt="avatar-image"
            style={style}
            src={data}
            className={className}
        />
    );
}

OcAvatar.propTypes = {
    /** employee prop type description*/
    employee: PropTypes.string,
    /** OcAvatar style*/
    style: PropTypes.object,
    /** OcAvatar className*/
    className: PropTypes.string,
};
