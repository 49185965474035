import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/hu";
import TimelineWeekWrapper from "./components/timeline-week-wrapper";
import TimelineDateHeader from "./components/timeline-date-header";
import WeeklyTimelineDateHeader from "./components/weekly-timeline-date-header";
import DateHeader from "./components/date-header";
import MonthDateHeader from "./components/month-date-header";
import DayHeader from "./components/day-header";
import TimelineDayHeader from "./components/timeline-day-header";
import WeeklyTimelineDayHeader from "./components/weekly-timeline-day-header";
import TimelineView from "./components/timeline-view";
import WeeklyTimelineView from "./components/weekly-timeline-view";
import Toolbar from "./components/toolbar";
import EventWrapper from "./components/event-wrapper";
import MonthEventWrapper from "./components/month-event-wrapper";
import useStyles from "./my-leave-calendar.style";
import { useTranslation } from "react-i18next";
import useContractCalendar from "hooks/use-my-leave-contract-calendar";
import useCurrentUserState from "hooks/use-current-user-state";

export default function MyLeaveCalendar({
  height,
  language,
  currentDate,
  onCurrentDateChange,
  events,
  onEventSelected,
  defaultView,
  calendar,
  monthFiltering,
  setToolbarButton,
  setShowCalendar,
  setSelectedDate,
  handleAgendaViewClick,
  loading,
}) {
  const { t, i18n } = useTranslation();
  let { contractCode } = useCurrentUserState();
  const formats = {
    monthHeaderFormat: "YYYY MMMM",
    agendaDateFormat: "MMM.DD. dddd",
    dateFormat: "D",
    weekdayFormat: "dddd",
  };

  const localizer = momentLocalizer(moment);
  localizer.segmentOffset = 0;

  const classes = useStyles();

  const culture = i18n.language === "hu" ? "hu" : "en";

  const eventPropGetter = (event, start, end, isSelected) => {
    let actualClass = `rbc-event-${event.state}-${event.leaveType}`;
    return { className: actualClass };
  };

  let [, /*containerHeight*/ setContainerHeight] = useState(height || 900);
  let calendarRef = useRef();

  let messages = {
    date: t("CALENDAR_MESSAGES_DATE"),
    time: t("CALENDAR_MESSAGES_TIME"),
    event: t("CALENDAR_MESSAGES_EVENT"),
    allDay: t("CALENDAR_MESSAGES_ALL_DAY"),
    week: t("CALENDAR_MESSAGES_WEEK"),
    work_week: t("CALENDAR_MESSAGES_WORK_WEEK"),
    day: t("CALENDAR_MESSAGES_DAY"),
    month: t("CALENDAR_MESSAGES_MONTH"),
    previous: t("CALENDAR_MESSAGES_PREVIOUS"),
    next: t("CALENDAR_MESSAGES_NEXT"),
    yesterday: t("CALENDAR_MESSAGES_YESTERDAY"),
    tomorrow: t("CALENDAR_MESSAGES_TOMORROW"),
    today: t("CALENDAR_MESSAGES_TODAY"),
    agenda: t("CALENDAR_MESSAGES_AGENDA"),

    noEventsInRange: t("CALENDAR_MESSAGES_NO_EVENTS_IN_RANGE"),

    showMore: (total) => `+${total} ${t("CALENDAR_MESSAGES_MORE")}`,
  };

  const date = new Date();
  const firstDate = moment(date).startOf("year").toDate();
  const lastDate = moment(date).endOf("year").toDate();

  const { contractCalendar } = useContractCalendar({
    contract: contractCode,
    startDate: firstDate,
    endDate: lastDate,
  });

  const customBackground = (date) => {
    if (date && contractCalendar) {
      const day = moment(date).dayOfYear();
      const type = contractCalendar[day - 1];
      if (type === "P") {
        return "rbc-weekend-day";
      }
      if (type === "F") {
        return "rbc-payed-holiday-day";
      }
    }
    return "";
  };

  return (
    <div style={{ height: "auto" }} className={classes.root}>
      <BigCalendar
        date={new Date(currentDate)}
        onNavigate={(date) => onCurrentDateChange(date)}
        localizer={localizer}
        culture={culture}
        events={events}
        onSelectEvent={onEventSelected}
        tooltipAccessor={null}
        views={{
          day: true,
          month: true,
          week: true,
          work_week: true,
          agenda: loading ? false : true,
          timeline: TimelineView,
          weeklyTimeline: WeeklyTimelineView,
        }}
        defaultView={defaultView}
        startAccessor="start"
        endAccessor="end"
        toolbar={true}
        components={{
          toolbar: (props) => (
            <Toolbar
              {...props}
              currentDate={currentDate}
              onCurrentDateChange={onCurrentDateChange}
              monthFiltering={monthFiltering}
              setToolbarButton={setToolbarButton}
              setShowCalendar={setShowCalendar}
            />
          ),
          eventWrapper: EventWrapper,
          day: {
            header: DayHeader,
            dateHeader: DateHeader,
          },
          week: {
            header: DayHeader,
            dateHeader: DateHeader,
          },
          month: {
            header: DayHeader,
            dateHeader: MonthDateHeader,
            eventWrapper: MonthEventWrapper,
          },
          timeline: {
            header: TimelineDayHeader,
            weekWrapper: TimelineWeekWrapper,
            dateHeader: TimelineDateHeader,
          },
          weeklyTimeline: {
            header: WeeklyTimelineDayHeader,
            dateHeader: WeeklyTimelineDateHeader,
          },
          agenda: {
            event: handleAgendaViewClick,
          },
        }}
        eventPropGetter={eventPropGetter}
        messages={messages}
        formats={formats}
        setContainerHeight={setContainerHeight}
        onView={(view) => {
          setContainerHeight("fit-content");
        }}
        ref={calendarRef}
        drilldownView="agenda"
        slotPropGetter={(date) => {
          return { className: customBackground(date) };
        }}
        dayPropGetter={(date) => {
          return { className: customBackground(date) };
        }}
      />
    </div>
  );
}

MyLeaveCalendar.propTypes = {
  /**
   * The height of the calendar when it is initialised
   */
  height: PropTypes.number,
  /**
   * The display language of the calendar
   */
  language: PropTypes.string,
  /**
   * Events object array to show
   */
  events: PropTypes.array,
  /**
   * Initial view of the calendar
   */
  defaultView: PropTypes.string,
  /**
   * Handler called when event selected
   */
  onEventSelected: PropTypes.func,
};

MyLeaveCalendar.defaultProps = {
  height: 900,
  language: "hu",
  defaultView: "month",
  toolbar: Toolbar,
};
