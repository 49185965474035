import gql from "graphql-tag";

export const GET_TAX_DECLARATION_ELIGIBLE_COSTS = gql`
  query taxDeclarationEligibleCosts($_id: Int!) {
    taxDeclarationEligibleCosts(_id: $_id) {
      dateOfDeclaration
      state
      independentIncome10Percent
      independentIncomeAmount
      independentIncomeCost10Percent
      independentIncomeCostAmount
      notIndependentIncomeCostAmount
      woCertificateIndependentCost
      woCertificateNotIndependentCost
      appartamentRentalCost
      postingStatementCost
      foreignServiceCost
      validUntilWithdrawn
    }
  }
`;
