import React, { useState } from "react";
import useStreetTypes from "hooks/use-street-types";
import useOcFormEditorSelector from "../../hooks/use-oc-form-editor-selector";
import OcFormEditorItemList from "../oc-form-editor-item-list";
import { Collapse, ButtonGroup, Button, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";

const OcFormEditorStreetTypeSelector = ({
  name,
  label,
  value,
  onChange,
  error,
  isHungary,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showList, setShowList] = useState(false);

  const { data: streetTypes } = useStreetTypes();
  const {
    list,
    search,
    selectedItem,
    handleSearch,
    handleClearSearch,
    handleHasMore,
  } = useOcFormEditorSelector({
    value: value?.name,
    list: streetTypes,
    selectName: true,
  });

  const shortList = ["utca", "út", "köz", "tér"];
  const isInShortList = shortList.find((item) => item === value?.name);

  if (!isInShortList && !showList) {
    setShowList(true);
  }

  const handleShortButtonChange = (name) => {
    setShowList(false);
    const selectedItem = list.find((item) => item.name === name);
    onChange("streetType", selectedItem);
  };

  if (!isHungary) return null;

  return (
    <Box paddingTop="22px">
      <Box marginBottom={showList ? "36px" : "0px"}>
        <Box
          style={{
            color: theme.palette.gray80.main,
            background: "white",
            fontSize: "12px",
            padding: "0px 8px 8px 8px",
          }}
        >
          {t(label)}
        </Box>
        <ButtonGroup>
          {shortList.map((item, index) => {
            const isSelectedType = item === value?.name;
            return (
              <Button
                key={`${item}-${index}`}
                name={item}
                variant={isSelectedType && !showList ? "contained" : "outlined"}
                color={isSelectedType && !showList ? "primary" : ""}
                disableElevation
                onClick={() => handleShortButtonChange(item)}
              >
                {item}
              </Button>
            );
          })}
          <Button
            variant={showList ? "contained" : "outlined"}
            color={showList ? "primary" : ""}
            disableElevation
            onClick={() => setShowList(true)}
          >
            egyéb
          </Button>
        </ButtonGroup>
      </Box>
      <Collapse in={showList} timeout="auto" unmountOnExit>
        <Box height="36px" />
        <OcFormEditorItemList
          search={search}
          onSearch={handleSearch}
          onChange={onChange}
          onClearSearch={handleClearSearch}
          list={list}
          onScroll={handleHasMore}
          value={selectedItem}
          label={label}
          name={name}
          error={error}
          paddingTop="0px"
          showItem={true}
        />
      </Collapse>
    </Box>
  );
};

export default OcFormEditorStreetTypeSelector;
