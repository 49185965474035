import _ from "lodash";

const useFilterChange = ({ role, onUpdateRole }) => {
  const handleAddFilter = (e) => {
    let newState = _.cloneDeep(role);
    newState?.roleFilters[e.key].push(e?.value);
    onUpdateRole(newState);
  };

  const handleRemoveFilter = (e) => {
    let newState = _.cloneDeep(role);
    let filtered = newState?.roleFilters[e.key].filter(function (value) {
      return value !== e.value;
    });
    newState.roleFilters[e.key] = filtered;
    onUpdateRole(newState);
  };

  return { handleAddFilter, handleRemoveFilter };
};

export default useFilterChange;
