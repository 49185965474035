import gql from "graphql-tag";

export const GET_TYPES_BY_MY_TIME_TYPE = gql`
  query getTypesByMyTimeType {
    getTypesByMyTimeType {
      code
      name
      type
    }
  }
`;
