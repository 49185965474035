import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import PublicLayoutData from "my-hr/components/my-hr-public-layout-data";
import React, { useEffect } from "react";
import useClientState from "hooks/use-oc-client-state";
import ForgotPasswordForm from "my-hr/components/my-hr-forgot-password-form";
import ForgotPasswordEmailSentDialog from "my-hr/components/my-hr-forgot-password-sent-email-dialog";

export default function MyHrUserForgotPasswordPage({ smallScreen }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useClientState("emailSent", false);

  function handleSendEmail() {
    setEmailSent(true);
  }

  useEffect(() => {
    return function cleanup() {
      setEmailSent(false);
    };
    // eslint-disable-next-line
  }, []);

  if (emailSent)
    return <ForgotPasswordEmailSentDialog smallScreen={smallScreen} />;

  return (
    <Box
      id="page-container"
      width="100%"
      display="flex"
      flexDirection={smallScreen ? "column" : "row"}
      justifyContent="space-between"
      height="100%"
    >
      {!smallScreen && <PublicLayoutData smallScreen={smallScreen} />}
      <Box
        id="form-container"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        style={{ backgroundColor: "white" }}
        textAlign="center"
        width={smallScreen ? "100%" : "400px"}
        height="100%"
      >
        <Box
          id="form-inner-container"
          width="100%"
          textAlign="center"
          padding="60px 0px"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Box padding="24px 48px" textAlign="center" width="304px">
            <Box
              marginBottom="24px"
              textAlign="center"
              color={theme.palette.darkBase.main}
            >
              <Typography variant="h6">{t("RECOVER_YOUR_PASSWORD")}</Typography>
            </Box>
            <Box color={theme.palette.gray80.main} textAlign="left">
              <Typography variant="caption">
                {t("TEXT_SEND_EMAIL_DESCRIPTION")}
              </Typography>
            </Box>
            <ForgotPasswordForm onEmailSent={handleSendEmail} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
