import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    height: 660,
  },
  title: {
    paddingTop: 36,
    paddingBottom: 24,
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "center",
    fontSize: 34,
    fontFamily: "Mulish",
    letterSpacing: 0.25,
    width: 600,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.gray30.main,
    margin: "0px 24px",
  },
  requestData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingTop: 40,
    paddingBottom: 24,
  },
  messageContainer: {
    backgroundColor: theme.palette.gray30.main,
    padding: 24,
    borderRadius: 8,
  },
  iconSend: {
    width: "22px",
    height: "22px",
    color: theme.palette.gray60.main,
  },
  initialMessage: {
    backgroundColor: theme.palette.gray60.main,
    color: "white",
    fontFamily: "Mulish",
    padding: "15px 11px 13px 11px",
    borderRadius: 4,
    marginTop: 20,
    marginBottom: 24,
  },
  initialMessageLoading: {
    backgroundColor: theme.palette.gray30.main,
    color: theme.palette.gray30.main,
    fontFamily: "Mulish",
    padding: "15px 11px 13px 11px",
    borderRadius: 4,
    marginTop: 20,
    marginBottom: 24,
  },
  textInput: {
    "& .MuiOutlinedInput-input": {
      padding: 14,
    },
  },
  editItemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.gray60.main}`,
    borderRadius: "50%",
    width: 36,
    height: 36,
    marginLeft: 4,
    cursor: "pointer",
  },
  editItemContainerLoading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.gray30.main,
    borderRadius: "50%",
    width: 36,
    height: 36,
    marginLeft: 4,
    cursor: "pointer",
  },
  editItemIcon: {
    width: 20,
    height: 20,
    alignItems: "center",
    color: theme.palette.gray60.main,
  },
  editItemIconLoading: {
    width: 20,
    height: 20,
    alignItems: "center",
    color: theme.palette.gray30.main,
  },
  arrowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 4px",
  },
  leaveTypeContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "flex-start",
    paddingLeft: 20,
    marginTop: "-4px",
    fontFamily: "Mulish",
  },
  leaveTypeText: {
    fontSize: 16,
    letterSpacing: 0.44,
  },
  leaveTypeTextLoading: {
    fontSize: 16,
    letterSpacing: 0.44,
    color: theme.palette.gray30.main,
    backgroundColor: theme.palette.gray30.main,
    width: 88,
    height: 20,
  },
  workDaysText: {
    display: "inline-block",
    fontSize: 14,
    letterSpacing: 0.25,
    minWidth: 100,
  },
  workDaysTextLoading: {
    display: "inline-block",
    fontSize: 14,
    letterSpacing: 0.25,
    width: 100,
    color: theme.palette.gray30.main,
    backgroundColor: theme.palette.gray30.main,
    height: 18,
  },
  rightContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: 0,
  },
  stateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  createdAtText: {
    fontFamily: "Mulish",
    fontSize: 10,
    paddingBottom: 2,
    color: theme.palette.gray60.main,
    textAlign: "center",
    textTransform: "capitalize",
  },
  createdAtTextLoading: {
    fontFamily: "Mulish",
    fontSize: 10,
    paddingBottom: 2,
    textAlign: "center",
    textTransform: "capitalize",
    color: theme.palette.gray30.main,
    backgroundColor: theme.palette.gray30.main,
    height: 13,
    marginBottom: 2,
  },
  editIconContainer: {
    paddingTop: 10,
    marginLeft: 36,
    width: 42,
  },
}));

export default useStyles;
