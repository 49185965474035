import React from "react";
import useLeaveSummaryChartData from "hooks/use-my-leave-summary-chart-data";
import MyLeaveBar from "my-leave/components/my-leave-bar";

const LeaveEszjtvCard = ({ selectedYear, contract }) => {
  const { data } = useLeaveSummaryChartData({
    year: selectedYear,
    contract,
  });

  if (!data?.allESZJTV19LeaveDays || data?.allESZJTV19LeaveDays === 0) {
    return null;
  }

  return (
    <MyLeaveBar
      id="eszjtv-leave-days-bar"
      label={"Leave.leaveEszjtv"}
      all={data?.allESZJTV19LeaveDays}
      available={data?.availableESZJTV19LeaveDays}
      requested={data?.requestedESZJTV19LeaveDays}
      fulfilled={data?.fulfilledESZJTV19Days}
    />
  );
};
export default LeaveEszjtvCard;
