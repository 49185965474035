import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import ChevronLeft from "@iconify/icons-mdi/chevron-left";
import ChevronRight from "@iconify/icons-mdi/chevron-right";
import CalendarToday from "@iconify/icons-mdi/calendar-today";
import ViewModuleOutline from "@iconify/icons-mdi/view-module-outline";
import ViewStreamOutline from "@iconify/icons-mdi/view-stream-outline";
import { InlineIcon } from "@iconify/react";
import useStyles from "./my-time-calendar-toolbar.style";
import { useTranslation } from "react-i18next";

const MyTimeToolbar = (props) => {
  const { i18n } = useTranslation();
  let {
    view,
    onView,
    onNavigate,
    date,
    monthFiltering,
  } = props;
  let title = moment(date).locale(i18n.language).format("YYYY. MMMM");
  const iconSize = 30;
  const Divider = () => {
    return <div style={{ display: "inline-block", width: 16 }}></div>;
  };

  if (view === "week") {
    let start = moment(date)
      .locale(i18n.language)
      .startOf("week")
      .format("YYYY. MMMM D.");
    let end = moment(date)
      .locale(i18n.language)
      .endOf("week")
      .format("YYYY. MMMM D");
    if (i18n.language !== "hu") {
      start = moment(date)
        .locale(i18n.language)
        .startOf("week")
        .format("DD/MM/YYYY");
      end = moment(date)
        .locale(i18n.language)
        .endOf("week")
        .format("DD/MM/YYYY");
    }
    title = `${start} - ${end}.`;
  }

  const classes = useStyles({ monthFiltering });

  return (
    <div className={classes.toolbarMainContainer}>
      {view === "week" ? (
        <div className={classes.centerContainer}>
          <InlineIcon
            icon={ChevronLeft}
            width={iconSize}
            height={iconSize}
            className={classes.leftChevron}
            onClick={() => onNavigate("PREV")}
          />
          <span className={classes.title}>{title}</span>
          <InlineIcon
            icon={ChevronRight}
            width={iconSize}
            height={iconSize}
            className={classes.rightChevron}
            onClick={() => onNavigate("NEXT")}
          />
        </div>
      ) : (
        <div></div>
      )}
      <div
        style={{
          paddingTop: 2,
        }}
      >
        <InlineIcon
          icon={CalendarToday}
          width={iconSize}
          height={iconSize}
          onClick={() => onNavigate("TODAY")}
          className={view !== "day" ? classes.icon : classes.iconDisabled}
        />
        <Divider />
        <div style={{ display: "inline-block", width: 24, height: 24 }}></div>
        <Divider />
        <InlineIcon
          icon={ViewModuleOutline}
          width={iconSize}
          height={iconSize}
          onClick={() => onView("month")}
          className={view !== "month" ? classes.icon : classes.iconDisabled}
        />
        <Divider />
        <InlineIcon
          icon={ViewStreamOutline}
          width={iconSize}
          height={iconSize}
          onClick={() => onView("week")}
          className={view !== "week" ? classes.icon : classes.iconDisabled}
        />
      </div>
    </div>
  );
};

export default MyTimeToolbar;
