import { useMutation } from "@apollo/react-hooks";
import { USERS_WITH_CHANGE_REQUEST } from "graphql/mutations";

export default function useUsersWithChangeRequest({
  onlyRequested,
  roleFilters,
  onCompleted = () => {},
  tableData,
}) {
  const paramFilter = { ...roleFilters };
  delete paramFilter.__typeName;

  let queryParams = {
    roleFilters: paramFilter,
    onlyRequested,
    searchText: tableData?.searchText || "",
    page: tableData?.page,
    pageSize: tableData?.pageSize,
  };

  const [loadQuery, { data, loading, error }] = useMutation(
    USERS_WITH_CHANGE_REQUEST,
    {
      variables: queryParams,
      onCompleted: (data) => {
        onCompleted(data?.usersWithChangeRequest || []);
      },
    }
  );

  const result = data?.usersWithChangeRequest || [];

  let requestsCount = 0;
  let usersCount = 0;
  let allUserCount = 0;

  const parsedResult = result.map((item) => {
    requestsCount += item.requestCount;
    usersCount += 1;
    allUserCount = item.allUserCount;
    return {
      employee: item.employee,
      name: item.name,
      orgUnit: item.orgUnit.name,
      jobClass: item.jobClass.name,
      requestCount: item.requestCount,
    };
  });

  return {
    data: parsedResult,
    usersCount: usersCount,
    allUserCount: allUserCount,
    requestsCount,
    loading,
    error,
    loadQuery,
  };
}
