import React from "react";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { InlineIcon } from "@iconify/react";
import LeftArrowIcon from "@iconify/icons-mdi/arrow-left";
import useStyles from "./my-leave-year-header.style";

const YearHeader = ({ handleIconClick, children }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box className={classes.sideContainer}>
                <Box
                    className={classes.iconContainer}
                    onClick={handleIconClick}
                >
                    <InlineIcon
                        id="my-leaves-year-header-button"
                        className={classes.icon}
                        icon={LeftArrowIcon}
                    />
                </Box>
            </Box>
            <Box>
                <Typography className={classes.text}>{children}</Typography>
            </Box>
            <Box className={classes.sideContainer} />
        </Box>
    );
};

export default YearHeader;
