import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    backgroundColor: theme.palette.gray20.main,
    borderRadius: "4px",
  },
  topBorder: {
    borderTop: `2px solid ${theme.palette.gray40.main}`,
  },
}));

export default useStyles;
