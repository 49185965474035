import React from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import fingerprint from "@iconify/icons-mdi/fingerprint";
import { InlineIcon } from "@iconify/react";

export default function CollapseLabel({
    name,
    code,
    expanded,
    handleExpandClick,
}) {
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 16px"
        >
            <Box
                style={{
                    fontSize: 20,
                    letterSpacing: 1.15,
                    padding: "10px 16px",
                    color: "#5e6366",
                }}
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Box marginRight="26px">{name}</Box>
                <InlineIcon icon={fingerprint} width="16px" height="16px" />
                <Box fontSize="14px" letterSpacing="0.25px" marginLeft="10px">
                    {code}
                </Box>
            </Box>
            <Box>
                <IconButton
                    id="collapse-label-icon"
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    style={
                        expanded
                            ? { padding: 0, transform: "rotate(180deg)" }
                            : { padding: 0 }
                    }
                >
                    <ExpandMoreIcon id="collapse-label-icon" />
                </IconButton>
            </Box>
        </Box>
    );
}
