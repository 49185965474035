export const initialTableData = {
  filterField: "",
  filterSelected: [],
  filterValues: [],
  filterShowForm: false,
  searchField: "",
  searchText: "",
  showSearchForm: false,
  sortDirection: "asc",
  sortField: "",
  page: 1,
  pageSize: 25,
  selectedRowKey: "",
  selectedRowValue: "",
};
