import gql from "graphql-tag";

export const REJECT_LEAVE_REQUEST = gql`
  mutation rejectLeaveRequest(
    $_id: String!
    $message: String!
    $locale: String!
    $closedByAdmin: Boolean
  ) {
    rejectLeaveRequest(
      _id: $_id
      message: $message
      locale: $locale
      closedByAdmin: $closedByAdmin
    ) {
      _id
      state
      rejectedAt
      rejectedBy
    }
  }
`;
