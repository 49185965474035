import React from "react";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import MyTimeDateHeaderTooltip from "my-time/components/my-time-date-header-tooltip/my-time-date-header-tooltip";

const MyTimeCalendarWeeklyDayHeader = (props) => {
  const { date, isOffRange, countTimeData } = props;
  const dayName = moment(date).format("dddd");
  const dayNumber = moment(date).format("D");

  const { workTime, overtime, voluntaryOvertime } = countTimeData(date);

  return (
    <MyTimeDateHeaderTooltip
      workTime={workTime}
      overtime={overtime}
      voluntaryOvertime={voluntaryOvertime}
      isOffRange={isOffRange}
      date={date}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="48px"
        padding="8px 0px"
        fontWeight={400}
      >
        <Typography
          variant="body1"
          style={{ padding: 0, margin: 0, lineHeight: "16px" }}
        >
          {dayName}
        </Typography>
        <Typography
          variant="body1"
          style={{ padding: 0, margin: 0, lineHeight: "16px" }}
        >
          {dayNumber}
        </Typography>
      </Box>
    </MyTimeDateHeaderTooltip>
  );
};

export default MyTimeCalendarWeeklyDayHeader;
