import React, { useState, useEffect } from "react";
import useLoginState from "./hooks/use-oc-login-state";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateLayout from "oc/components/oc-private-layout";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { routeDefs } from "oc/configs/routes";
import Background from "oc/components/oc-public-layout-background";
import ScrollToTop from "oc/components/oc-scroll-to-top";
import axios from "axios";
import MyHRErrorPage from "my-hr/pages/my-hr-error-page/my-hr-error-page";
import useCustomerLicences from "hooks/use-customer-licences";

let apiUrl = `http://${window.location.hostname}:9000/api`;

if (process.env.NODE_ENV === "production") {
  apiUrl = "/api";
}

function PrivateRoute({ children, isLoggedIn, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
let interval;

export default function App() {
  const { onLogin, onLogout, isLoggedIn } = useLoginState();
  const [errorState, setErrorState] = useState(false);
  const [errorStateDb, setErrorStateDb] = useState(false);
  const [errorStateType, setErrorStateType] = useState("");
  useCustomerLicences();
  useEffect(() => {
    function checkDb() {
      if (!document.hidden) {
        axios
          .get(`${apiUrl}/read-db-test`)
          .then((data) => {
            setErrorStateDb(false);
            setErrorStateType("");
          })
          .catch((error) => {
            setErrorStateDb(true);
            setErrorStateType("db");
          });
      }
    }

    function check() {
      if (!document.hidden) {
        axios
          .get(apiUrl)
          .then(() => {
            setErrorState(false);
            setErrorStateType("");
          })
          .catch((error) => {
            setErrorState(true);
            setErrorStateType("net");
          });
      }
    }
    interval = setInterval(() => {
      check();
      checkDb();
    }, 1000 * 5);
    check();
    checkDb();
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (errorState || errorStateDb)
    return (
      <MyHRErrorPage
        errorStateDb={errorStateDb}
        errorState={errorState}
        errorStateType={errorStateType}
        apiUrl={apiUrl}
      />
    );

  return (
    <React.Fragment>
      <ScrollToTop>
        <PrivateLayout isLoggedIn={isLoggedIn} onLogout={onLogout}>
          <OcErrorBoundary>
            <Switch>
              {routeDefs?.map((item) => (
                <Route key={item.id} path={item.path} exact={item.exact}>
                  {item.private ? (
                    <PrivateRoute path={item.path} isLoggedIn={isLoggedIn}>
                      {React.createElement(item.component, {
                        roles: item.roles,
                      })}
                    </PrivateRoute>
                  ) : (
                    <Background>
                      {React.createElement(item.component, { onLogin })}
                    </Background>
                  )}
                </Route>
              ))}
              <Redirect to="/404" />
            </Switch>
          </OcErrorBoundary>
        </PrivateLayout>
      </ScrollToTop>
    </React.Fragment>
  );
}
