import gql from "graphql-tag";

export const CREATE_LEAVE_REQUEST = gql`
  mutation createLeaveRequest(
    $contract: String!
    $startDate: Date!
    $endDate: Date!
    $workDays: Int!
    $effectiveWorkDays: Float!
    $type: String!
    $message: String
    $locale: String!
  ) {
    createLeaveRequest(
      contract: $contract
      startDate: $startDate
      endDate: $endDate
      workDays: $workDays
      effectiveWorkDays: $effectiveWorkDays
      type: $type
      message: $message
      locale: $locale
    ) {
      createdAt
    }
  }
`;
