import React from "react";
import Box from "@material-ui/core/Box";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import MyHrUserProfilesRolesItem from "./components/my-hr-user-profiles-roles-item"

const MyHrUserProfilesRolesData = ({ contract }) => {
  const { t } = useTranslation();

  const { data: rolesData, loading } = useUserRolesDataByContract({
    contract: contract?.code,
  });

  let jobClassName =
    contract?.jobClass?.name.charAt(0).toUpperCase() +
      contract?.jobClass?.name.slice(1) || "";

  return (
    <OcCard
      label={`${jobClassName} - ${contract?.company?.name} (${
        contract?.code
      }) - ${t("ROLES")}`}
      loading={loading}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        {rolesData.map((role, index) => {
          return (
            <MyHrUserProfilesRolesItem role={role} key={`role-item-${index}`} />
          );
        })}
      </Box>
    </OcCard>
  );
};

export default function MyHrUserProfileRoles({ contracts }) {
  return (
    <Box>
      {contracts.map((contract) => (
        <MyHrUserProfilesRolesData
          key={`${contract?.code}-edit-form`}
          contract={contract}
          editable={false}
        />
      ))}
    </Box>
  );
}
