import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import Item from "oc/components/oc-info-card-item";
import React from "react";
import getCountryName from "oc/utils/get-country-name";
import OcCardLabel from "../oc-card-label/oc-card-label";

export default function OcInfoCardAddress({
  address,
  label,
  translation,
  def,
  onEdit,
  isEditButtonDisabled,
  editable,
  defaultLabel,
}) {
  const { t, i18n } = useTranslation();
  function getFullAddress({ address }) {
    let result = "";
    result = address?.streetName ? result + " " + address?.streetName : result;
    result = address?.streetType?.name
      ? result + " " + address?.streetType?.name
      : result;
    result = address?.streetNumber
      ? result + " " + address?.streetNumber
      : result;
    result = address?.building ? result + " " + address?.building : result;
    result = address?.stairway ? result + " " + address?.stairway : result;
    result = address?.floor ? result + " " + address?.floor : result;
    result = address?.door ? result + " " + address?.door : result;
    return result?.trim();
  }

  function isEmptyAddress({ address }) {
    if (
      address?.country === "" &&
      address?.city === "" &&
      address?.zip === "" &&
      getFullAddress({ address }) === ""
    )
      return true;
    return false;
  }

  if (isEmptyAddress({ address })) return null;

  return (
    <OcCard
      label={
        <OcCardLabel
          label={t(defaultLabel) || t(translation) || label}
          translation={translation}
          employee={address}
          editable={editable}
          def={def}
          onEdit={onEdit}
          isEditButtonDisabled={isEditButtonDisabled}
        />
      }
    >
      <>
        <Item
          label={t("COUNTRY") || ""}
          value={getCountryName(address?.country, i18n?.language)}
          translation={translation}
        />
        <Item
          label={t("ZIP") || ""}
          value={address?.zip}
          translation={translation}
        />
        <Item
          label={t("CITY") || ""}
          value={address?.city}
          translation={translation}
        />
        <Item
          label={t("DISTRICT") || ""}
          value={address?.district}
          translation={translation}
        />
        <Item
          label={t("ADDRESS") || ""}
          value={getFullAddress({ address })}
          translation={translation}
        />
      </>
    </OcCard>
  );
}
