import gql from "graphql-tag";

export const GET_CONTRACTS = gql`
  query contracts(
    $searchField: String
    $searchText: String
    $sort: String
    $page: Int
    $pageSize: Int
    $contracts: [String]
  ) {
    contracts(
      searchField: $searchField
      searchText: $searchText
      sort: $sort
      page: $page
      pageSize: $pageSize
      contracts: $contracts
    ) {
      rows {
        _id
        code
        employee {
          _id
          code
          prefixName
          lastName
          middleName
          firstName
          nameSuffix
        }
        company {
          _id
          code
          name
          companyLogo
        }
        orgUnit {
          code
          name
        }
        jobClassGroup {
          code
          name
        }
        validTo
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
