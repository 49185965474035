import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 36,
  },
  leftContainer: {
    width: 180,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  leftContainerExaminerView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  newItemButton: {
    height: "40px",
    padding: "0 22px",
    letterSpacing: "0.75px",
  },
  allApprove: {
    height: "40px",
    padding: "0 22px",
    letterSpacing: "0.75px",
  },
}));

export default useStyles;
