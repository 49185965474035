import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import gql from "graphql-tag";
import usePolling from "hooks/use-polling";

export const GET_CAFETERIA_MENU = gql`
  query getCafeteriaMenu($contractCode: String!) {
    getCafeteriaMenu(contractCode: $contractCode) {
      code
      name
      isPeriodical
      isReusable
      availableMonths
      startDate
      endDate
      planable
      isSZEPCardItem
    }
  }
`;

const useCafeteriaMenu = ({ contractCode }) => {
  const { data, loading, error } = useQuery(GET_CAFETERIA_MENU, {
    skip: !contractCode,
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: { contractCode },
  });

  let result = [];
  result = data?.getCafeteriaMenu.filter((item) => {
    let currentDate = moment().format("YYYYMMDD");
    if (item.endDate >= currentDate || item.endDate === "") {
      return true;
    }
    return false;
  });

  return {
    data: result,
    loading,
    error,
  };
};

export default useCafeteriaMenu;
