import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { InlineIcon } from "@iconify/react";
import searchIcon from "@iconify/icons-mdi/search";
import CloseIcon from "@iconify/icons-mdi/close";

export default function FilterItemSearchForm({
    search,
    onSearch,
    onClearSearch,
    disabled,
}) {
    if (disabled) return null;
    return (
        <OcErrorBoundary>
            <Box padding="16px">
                <Box
                    style={{
                        backgroundColor: "white",
                        borderRadius: "4px",
                        height: "48px",
                    }}
                >
                    <TextField
                        label=""
                        id="oc-filter-item-search-form-input"
                        variant="outlined"
                        fullWidth
                        value={search}
                        onChange={onSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InlineIcon icon={searchIcon} height={20} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <InlineIcon
                                        icon={CloseIcon}
                                        onClick={onClearSearch}
                                        style={{ cursor: "pointer" }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                height: "48px",
                            },
                        }}
                    />
                </Box>
            </Box>
        </OcErrorBoundary>
    );
}
