import gql from "graphql-tag";

export const CREATE_TAX_DECLARATION_FIRST_MARRIED_PERSON = gql`
  mutation createTaxDeclarationFirstMarriedPerson(
    $dateOfDeclaration: Date!,
    $isModifiedDeclaration: Boolean!,
    $spouseName: String!,
    $spouseTaxNumber: String!,
    $spouseEmployerName: String,
    $spouseEmployerTaxNumber: String,
    $marriageDate: Date,
    $applyFromMonth: String!,
    $requestAmount: Float!,
    $noApply: Boolean!,
    $applyInTaxYear: Boolean!,
    $applyToWithdrawal: Boolean!,
    ) {
    createTaxDeclarationFirstMarriedPerson(
        dateOfDeclaration: $dateOfDeclaration,
        isModifiedDeclaration: $isModifiedDeclaration,
        spouseName: $spouseName,
        spouseTaxNumber: $spouseTaxNumber,
        spouseEmployerName: $spouseEmployerName,
        spouseEmployerTaxNumber: $spouseEmployerTaxNumber,
        marriageDate: $marriageDate,
        applyFromMonth: $applyFromMonth,
        requestAmount: $requestAmount,
        noApply: $noApply,
        applyInTaxYear: $applyInTaxYear,
        applyToWithdrawal: $applyToWithdrawal,
    ) {
      result
      messages
      tableId
    }
  }`;
