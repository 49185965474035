import { useQuery, useSubscription } from "@apollo/react-hooks";
import { ASSETS_SUBSCRIPTION } from "graphql/subcriptions";
import gql from "graphql-tag";

export const GET_CAFETERIA_REGULATION = gql`
  query getVBKRegulation {
    getVBKRegulation {
      _id
      filename
      createdAt
    }
  }
`;

export default function useCafeteriaRegulation() {
  const { data, loading, error, refetch } = useQuery(GET_CAFETERIA_REGULATION, {
    fetchPolicy: "cache-and-network",
  });

  useSubscription(ASSETS_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  return {
    data: data?.getVBKRegulation || "",
    loading,
    error,
    refetch,
  };
}
