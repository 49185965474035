import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import usePermit from "hooks/use-permit";
import { ADD_PERMIT } from "graphql/mutations";

const MyHROwadminAddPermitPage = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const userId = params.get("userId");
  const requestId = params.get("requestId");

  const [text, setText] = useState();
  const [sent, setSent] = useState(false);
  const { data: permit } = usePermit();

  const [addPermit] = useMutation(ADD_PERMIT, {
    onCompleted: (data) => {
      setSent(true);
      setText(t("PERMIT_GRANTED"));
    },
    onError: (error) => {
      setSent(true);
      setText(t(error?.message));
    },
  });

  useEffect(() => {
    if (userId && requestId && !sent) {
      addPermit({
        variables: {
          _id: requestId,
          userId,
        },
      });
    }
  }, [permit, userId, requestId, sent, setText, addPermit]);

  return (
    <Box
      width="100%"
      height="100%"
      style={{ backgroundColor: "#cc0033" }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        width="400px"
        height="100%"
        style={{ backgroundColor: "white" }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box id="orgware-text-logo">
          <img
            alt="orgware-icon"
            src="/img/owLogoColored.png"
            style={{ width: 300 }}
          />
        </Box>
        <Typography
          variant="body1"
          style={{
            color: "#cc0033",
            marginTop: "32px",
            padding: "0px 48px",
            textAlign: "center",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default MyHROwadminAddPermitPage;
