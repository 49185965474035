import React from "react";
import "moment/locale/hu";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Currency from "oc/components/oc-currency";
import Tooltip from "@material-ui/core/Tooltip";

function CurrencyTooltip({ label, value }) {
    return (
        <Box
            style={{ fontSize: "16px", lineHeight: "20px", padding: "2px 8px" }}
        >
            <span>{`${label}: `}</span>
            <Currency value={value} />
        </Box>
    );
}

export default function MyHrSalarySummary({ data }) {
    const { t } = useTranslation();
    const { netWage, otherBenefits, grossWage } = data;
    const netSalary = netWage + otherBenefits;

    const deductions = grossWage - netSalary;
    let salaryPercentage = (
        (100 * Math.abs(netSalary || 0)) /
        grossWage
    ).toFixed(2);
    let deductionsPercentage = 100 - salaryPercentage;

    return (
        <Box
            marginTop="8px"
            marginBottom="24px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            borderRadius="8px"
            overflow="hidden"
        >
            <Tooltip
                title={
                    <CurrencyTooltip label={t("NET_WAGE")} value={netSalary} />
                }
                placement="top"
            >
                <Box
                    style={{
                        width: `${salaryPercentage}%`,
                        textAlign: "center",
                        backgroundColor: "#cc0033",
                        color: "white",
                        height: "24px",
                        fontSize: "12px",
                        lineHeight: "24px",
                    }}
                >
                    <Currency value={netSalary} />
                </Box>
            </Tooltip>
            <Tooltip
                title={
                    <CurrencyTooltip
                        label={t("DEDUCTIONS")}
                        value={deductions}
                    />
                }
                placement="top"
            >
                <Box
                    style={{
                        width: `${deductionsPercentage}%`,
                        textAlign: "center",
                        color: "white",
                        height: "24px",
                        backgroundColor: "#5E6366",
                        fontSize: "12px",
                        lineHeight: "24px",
                    }}
                >
                    <Currency value={deductions} />
                </Box>
            </Tooltip>
        </Box>
    );
}
