import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  filterLine: {
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.gray30.main,
    },
  },
}));

export default useStyles;
