import React from "react";
import { Box } from "@material-ui/core";

const ApplicationCheckBox = ({ value }) => {
    return (
        <>
            <Box
                style={{
                    border: "1px solid black",
                    width: "14px",
                    height: "17px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    alignContent: "center",
                    marginLeft: "5px",
                    marginTop: "3px",
                    fontWeight: "normal",
                }}
            >
                {value ? "X" : ""}
            </Box>
        </>
    );
};

export default ApplicationCheckBox;
