import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_LAST_PASSWORD_CHANGE } from "graphql/queries";
import { LAST_PASSWORD_CHANGE_SUBSCRIPTION } from "graphql/subcriptions";
import usePolling from "hooks/use-polling";

export default function useLastPasswordChange({ email }) {
  const { data, loading, error } = useQuery(GET_LAST_PASSWORD_CHANGE, {
    skip: !email,
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: {
      email,
    },
  });

  const { data: subsData } = useSubscription(
    LAST_PASSWORD_CHANGE_SUBSCRIPTION,
    {
      fetchPolicy: "network-only",
      skip: !email,
      variables: {
        email,
      },
    }
  );

  return {
    data: subsData?.lastPasswordChange || data?.lastPasswordChange,
    loading,
    error,
  };
}
