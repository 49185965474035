import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  labelStyles: {
    textTransform: "lowercase",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.gray80.main,
    borderTopLeftRadius: "2px",
    borderTopRightRadius: "2px",
    color: theme.palette.white.main,
    padding: "0px 10px",
  },
  textStyles: {
    textTransform: "capitalize",
    marginRight: "5px",
  },
}));

export default useStyles;
