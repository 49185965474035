import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  text: ({ borderColor }) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: 12,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 2,
    paddingBottom: 2,
  }),
}));

export default useStyles;
