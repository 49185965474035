import gql from "graphql-tag";

export const REJECT_MY_TIME_REQUEST = gql`
  mutation rejectMyTimeRequest($_id: String!, $message: String) {
    rejectMyTimeRequest(_id: $_id, message: $message) {
      result
      message
    }
  }
`;
