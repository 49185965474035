import React, { useEffect } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import AllChangeRequestsCard from "my-hr/components/my-hr-all-change-requests-card";
import useClientState from "hooks/use-oc-client-state";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useUsersWithChangeRequests from "hooks/use-my-hr-users-with-change-requests";
import useCurrentUserState from "hooks/use-current-user-state";
import useRoleFilters from "hooks/use-role-filters.js";

export default function MyHrViewUserDataEditRequests() {
  const history = useHistory();

  let { contractCode: contract } = useCurrentUserState();

  const {
    roleFilters,
    prevRoleFilters,
    resetRoleFilters,
    setPrevRoleFilters,
    handleAddFilter,
    handleRemoveFilter,
  } = useRoleFilters({
    contract: contract,
    product: "MyHR",
    role: "examiner",
  });

  const [onlyRequested, setOnlyRequested] = useClientState(
    "showOnlyRequestedChangeRequests",
    true
  );
  const [prevOnlyRequested, setPrevOnlyRequested] = useClientState(
    "prevShowOnlyRequestedChangeRequests",
    true
  );

  let tableId = "allChangeRequestsTableData";
  initialTableData.sortField = "name";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);
  const [prevTableData, setPrevTableData] = useClientState(
    `${tableId}Prev`,
    initialTableData
  );

  const handleCompleted = () => {
    //console.log("completed");
  };

  const { data, loading, loadQuery, usersCount, requestsCount, allUserCount } =
    useUsersWithChangeRequests({
      onlyRequested,
      roleFilters,
      tableData,
      onCompleted: handleCompleted,
    });
  const isTabledataChanged = !_.isEqual(tableData, prevTableData);
  if (isTabledataChanged) {
    loadQuery();
    setPrevTableData(tableData);
  }

  useEffect(() => {
    loadQuery();
    setPrevRoleFilters(roleFilters);
  }, []); // eslint-disable-line

  const buttonDisable =
    _.isEqual(roleFilters, prevRoleFilters) &&
    onlyRequested === prevOnlyRequested;

  const sortedArray = _.orderBy(
    data,
    tableData.sortField,
    tableData.sortDirection
  );

  const total = data?.length || 0;

  const list = {
    page: tableData.page,
    pageSize: tableData.pageSize,
    rows: sortedArray,
    total: total,
    totalPages: Math.floor(
      (total + tableData.pageSize - 1) / tableData.pageSize
    ),
  };

  function onSelectRow(o) {
    history.push(`/my-hr/view-request/${o.employee}`);
  }

  function handleOnlyReqestedChange(state) {
    setOnlyRequested(state);
  }

  function handleTableDataChange(state) {
    setTableData(state);
  }

  function handleLoadQuery() {
    loadQuery();
    setPrevRoleFilters(roleFilters);
    setPrevOnlyRequested(onlyRequested);
  }

  const handleClearFilters = () => {
    resetRoleFilters();
  };

  return (
    <OcPageTransition>
      <Box padding="16px">
        <AllChangeRequestsCard
          buttonDisable={buttonDisable}
          onAddFilter={handleAddFilter}
          onRemoveFilter={handleRemoveFilter}
          onlyRequested={onlyRequested}
          onOnlyRequestedChange={handleOnlyReqestedChange}
          data={list}
          roleFilters={roleFilters}
          loading={(loading && !data.rows) || (loading && tableData.searchText)}
          tableData={tableData}
          onTableDataChange={handleTableDataChange}
          onSelectRow={onSelectRow}
          tableId={tableId}
          contract={contract}
          onLoadQuery={handleLoadQuery}
          onClearFilters={handleClearFilters}
          requestsCount={requestsCount}
          usersCount={usersCount}
          allUserCount={allUserCount}
        />
      </Box>
    </OcPageTransition>
  );
}
