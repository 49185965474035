import React from "react";
import { Box } from "@material-ui/core";
import Switch from "oc/components/oc-form-switch";

export default function OcFilterSwitch({ label, checked, name, onChange }) {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box display="flex" alignItems="center">
        <Box
          style={{
            fontSize: "12px",
            letterSpacing: "0.4px",
            paddingRight: "16px",
            width: "49%",
            textAlign: "end",
          }}
        >
          {label}
        </Box>
        <Switch
          checked={checked}
          onChange={onChange}
          name={name}
          inputProps={{
            "aria-label": "secondary checkbox",
          }}
        />
      </Box>
    </Box>
  );
}
