import { atomFamily } from "recoil";
import persistAtom from "./persist-atom";

export const unitListState = atomFamily({
  key: "unitListState",
  default: {
    availableList: [],
    page: 1,
    pageSize: 25,
    selectedList: [],
    total: 0,
    totalPages: 1,
  },
  effects_UNSTABLE: [persistAtom],
});
