import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import ApplicationHeader from "../application-header/application-header";
import { useReactToPrint } from "react-to-print";
import FourChildrenTaxCreditApplicationPrint from "../four-children-tax-credit-application-print/four-children-tax-credit-application-print";
import OcDownload from "oc/components/oc-download";
import ApplicationCardInfoItem from "../application-card-info-item/application-card-info-item";
import { useRecoilState, useResetRecoilState } from "recoil";
import DependentsFourChildrenCollapse from "my-hr/components/my-hr-applications-data-panel/components/dependents-four-children-collapse";
import { useTranslation } from "react-i18next";
import OcMonthSelect from "oc/components/oc-month-select";
import useDependentsDataDI from "hooks/use-dependents-data";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import useCurrentUserState from "hooks/use-current-user-state";
import ApplicationCollapseHeader from "../application-collapse-header";
import humanMaleFemale from "@iconify/icons-mdi/human-male-female";
import { NOT_PROVIDED } from "my-hr/components/my-hr-applications-data-panel/constants";
import TaxCreditApplicationButtonBars from "../tax-credit-application-buttonbars/tax-credit-application-buttonbars";
import ModifiedStatementCheck from "../modified-statement-check/modified-statement-check";
import TaxCreditApplicationCheckboxLine from "../tax-credit-application-checkbox-line/tax-credit-application-checkbox-line";
import { taxDeclarationFourChildren } from "../../states/tax-declaration-four-children";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_TAX_DECLARATION_FOUR_CHILDREN } from "graphql/queries";
import OcCard from "oc/components/oc-card";
import TaxCreditApplicationErrorBox from "my-hr/components/my-hr-applications-data-panel/components/tax-credit-application-error-box/tax-credit-application-error-box";
import { CREATE_TAX_DECLARATION_FOUR_CHILDREN } from "graphql/mutations";
import useOcSnackbar from "hooks/use-oc-snackbar";

const useFormData = ({ _id, setSelected }) => {
  const { t } = useTranslation();
  const [saveErrors, setSaveErrors] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [states, setStates] = useRecoilState(taxDeclarationFourChildren);
  const resetStates = useResetRecoilState(taxDeclarationFourChildren);

  const { data, loading, error } = useQuery(GET_TAX_DECLARATION_FOUR_CHILDREN, {
    fetchPolicy: "cache-and-network",
    skip: !_id,
    variables: {
      _id: parseInt(_id),
    },
    onCompleted: (data) => {
      const result = data?.taxDeclarationFourChildren;
      const newStates = { ...result };
      newStates.dateOfDeclaration = new Date(result?.dateOfDeclaration);
      delete newStates.__typename;
      setStates(newStates);
    },
  });

  const result = data?.taxDeclarationFourChildren;

  const handleChange = ({ key, value }) => {
    const newState = { ...states, [key]: value };
    setStates(newState);
  };

  const { setOpenMessage } = useOcSnackbar();
  const [saveDeclaration, { loading: mutationLoading }] = useMutation(
    CREATE_TAX_DECLARATION_FOUR_CHILDREN,
    {
      onCompleted: (data) => {
        const res = data?.createTaxDeclarationFourChildren;
        setDisabledButton(false);
        if (res?.result) {
          if (res?.messages?.length > 0) {
            const message = res?.messages[0];
            if (
              message === "A korábban beadott nyilatkozata törlésre került!"
            ) {
              setOpenMessage({
                type: "success",
                message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
              });
              setOpenMessage({
                type: "warning",
                message: t("PREVIOUS_TAX_DECLARATION_DELETED"),
              });
            }
          } else {
            setOpenMessage({
              type: "success",
              message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
            });
          }
          setSaveErrors([]);
          const params = {
            editable: false,
            tableId: res?.tableId,
            year: states?.dateOfDeclaration?.getFullYear().toString(),
            type: "TaxDeclarationFourChildren",
            state: "requested",
          };
          setSelected(params);
        } else {
          setSaveErrors(res?.messages);
          setDisabledButton(false);
        }
      },
      onError: () => {
        setSaveErrors([t("TAX_DECLARATION_CREATED_FAILED")]);
        setDisabledButton(false);
      },
    }
  );

  useEffect(() => {
    if (!states?.isModifiedDeclaration) {
      handleStateChange({ ...states, notApplyFromMonth: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states?.isModifiedDeclaration]);

  const handleStateChange = (newState) => {
    setStates(newState);
  };

  const handleSave = () => {
    setDisabledButton(true);
    const params = { ...states };
    saveDeclaration({ variables: params });
  };

  return {
    data: result,
    loading: loading || mutationLoading,
    error,
    states,
    disabledButton,
    resetStates,
    handleChange,
    handleSave,
    saveErrors,
    setSaveErrors,
    handleStateChange,
  };
};

const FourChildrenTaxCreditApplication = ({
  fullName,
  taxNumber,
  useDependentsData = useDependentsDataDI,
  taxApplicationSaveEnabled,
  handleRemoveRequest,
  selected,
  setSelected,
}) => {
  const editable = selected?.editable;
  const history = useHistory();
  const {
    states,
    loading,
    error,
    saveErrors,
    disabledButton,
    setSaveErrors,
    resetStates,
    handleChange,
    handleSave,
    handleStateChange,
  } = useFormData({
    _id: selected?.tableId,
    setSelected,
  });

  const isModified = selected?.isModified;
  if (isModified && !states?.isModifiedDeclaration) {
    handleStateChange({ ...states, isModifiedDeclaration: true });
  }

  const showModifiedButton = states?.state === "E" ? true : false;
  const showRemoveButton =
    states?.state === "K" || selected?.sate === "K" ? true : false;

  const dateOfDeclarationString = new Date(
    states?.dateOfDeclaration
  ).toLocaleDateString();

  const title =
    "Adóelőleg-nyilatkozat a négy vagy több gyermeket nevelő anyák kedvezményének érvényesítéséről ";

  const componentRef = useRef();

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    print();
  };

  const { data: dependents } = useDependentsData();

  if (editable) {
    dependents?.forEach((item, index) => {
      const dateOfBirth = new Date(item.birthDate);
      if (dateOfBirth.toString() !== "Invalid Date") {
        const newItem = {
          dependentSerialNumber: item.itemSerial,
          name: item.name,
          taxNumber: item.taxNumber,
          placeOfBirth: item.birthPlace,
          dateOfBirth: dateOfBirth.toLocaleDateString(),
          mothersName: item.mothersName,
        };
        const isExist = states?.dependents.find(
          (item) => item.dependentSerialNumber === newItem.dependentSerialNumber
        );
        if (!isExist) {
          const dependents = states?.dependents;
          const newDependents = [...dependents, newItem];
          handleChange({ key: "dependents", value: newDependents });
        }
      }
    });
  }

  const { t } = useTranslation();

  const handleCancel = () => {
    resetStates();
    setSaveErrors([]);
  };

  useEffect(() => {
    if (states?.isModifiedDeclaration === false) {
      handleChange({ key: "notApplyFromMonth", value: "" });
    }
    return () => {
      handleCancel();
    };
  }, []); // eslint-disable-line

  let { contractCode, employeeCode } = useCurrentUserState();
  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  let selectedContract = contractsData?.find(
    (item) => item.code === contractCode
  );

  let disabled = true;

  const handleModChange = ({ key, value }) => {
    handleChange({ key, value });
  };

  const handleScrollToError = () => {
    const errorElement = document.getElementById("errorBox");
    if (errorElement) {
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    }
  };
  useLayoutEffect(() => {
    handleScrollToError();
  }, [saveErrors, history.action]);

  useEffect(() => {
    if (history.action === "POP" && editable) {
      return handleCancel();
    }
  }, []); //eslint-disable-line

  return (
    <>
      <Box
        style={{
          marginBottom: "-15px",
        }}
      >
        <Box
          sx={{
            display: "none",
            displayPrint: "block",
            paddingRight: "85px",
          }}
        >
          <FourChildrenTaxCreditApplicationPrint
            ref={componentRef}
            fullName={fullName}
            taxNumber={taxNumber}
            employerName={selectedContract?.company?.name || ""}
            employerTaxNumber={selectedContract?.company?.taxNumber || ""}
            isModifiedApplication={states?.isModifiedDeclaration}
            isApplyNetak={states?.isEligible}
            isApplyNetakPermanently={states?.permanentlyEntitled}
            dependents={dependents}
            isNoneApplyStartMonth={states?.notApplyFromMonth}
            states={states}
            applicationDate={dateOfDeclarationString}
          />
        </Box>
        <ApplicationHeader
          title={title}
          onPrint={handlePrint}
          onCancel={handleCancel}
          noWrap={false}
        />
        <Box
          padding="16px"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Box
            width="260px"
            style={{
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            <OcDownload
              label="Tájékoztató"
              actionIconType="url"
              href={"#/my-hr/four-children-tax-credit-information"}
              target="_blank"
              isSmall={true}
            />
          </Box>
        </Box>
        {saveErrors.length > 0 && (
          <Box id="errorBox">
            <TaxCreditApplicationErrorBox error={saveErrors} />
          </Box>
        )}
        <OcCard
          label="I. A nyilatkozatot adó magánszemély"
          loading={loading}
          error={error}
        >
          <Box
            style={{
              padding: "0px 17px",
            }}
          >
            <Box height="52px" />
            <ApplicationCardInfoItem
              label="Dátum"
              value={dateOfDeclarationString}
            />
            <ApplicationCardInfoItem label="Dolgozó neve" value={fullName} />
            <ApplicationCardInfoItem
              label="Adóazonosító jele"
              value={taxNumber}
            />
          </Box>
          <Box height="15px" />
          <ModifiedStatementCheck
            label={t("MODIFIED_APPLICATION")}
            name="isModifiedDeclaration"
            value={states?.isModifiedDeclaration}
            onChange={handleModChange}
            disabled={!editable || isModified}
          />
          <Box height="15px" />
          <Box display="flex" flexDirection="row">
            <Typography variant="body1">1.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              Gyermek neve, adóazonosító jele
            </Typography>
          </Box>
          <Box height="36px" />
          {states?.dependents.length !== 0 ? (
            states?.dependents?.map((item, index) => {
              return (
                <Box key={`dependents-${index}`}>
                  <DependentsFourChildrenCollapse index={index} data={item} />
                  <Box height="36px" />
                </Box>
              );
            })
          ) : (
            <Box>
              <ApplicationCollapseHeader
                dependentName={NOT_PROVIDED}
                dependentTaxNumber=""
                expanded={disabled}
                icon={humanMaleFemale}
                taxNumberLabel=""
              />
              <Box height="36px" />
            </Box>
          )}
          <TaxCreditApplicationCheckboxLine
            id="isApplyNetak"
            type="checkbox"
            prefix="2."
            checked={states?.isEligible}
            checkboxDisabled={!editable}
            onChange={(e) =>
              handleChange({ key: "isEligible", value: e.target.checked })
            }
            text="Nyilatkozom, hogy jogosult vagyok a négy vagy több gyermeket
                nevelő anyák kedvezményére, azt igénybe kívánom venni."
          />
          <Box height="36px" />
          <TaxCreditApplicationCheckboxLine
            id="isApplyNetakPermanently"
            type="checkbox"
            prefix="3."
            checked={states?.permanentlyEntitled}
            checkboxDisabled={!editable}
            onChange={(e) =>
              handleChange({
                key: "permanentlyEntitled",
                value: e.target.checked,
              })
            }
            text="Nyilatkozatomat visszavonásig kérem figyelembe venni (folytatólagos nyilatkozatot teszek)."
          />
          <Box height="36px" />
          <Box
            style={{
              color: (!states?.isModifiedDeclaration || !editable) && "#919699",
            }}
          >
            <Box display="flex" flexDirection="row">
              <Typography variant="body1">4.</Typography>
              <Box width="16px" />
              <Typography variant="body1">
                Nyilatkozom, hogy a négy vagy több gyermeket nevelő anyák
                kedvezményét
              </Typography>
            </Box>
            <Box height="16px" />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ marginLeft: "30px" }}
            >
              <OcMonthSelect
                width="160px"
                label={t("TAXCREDITMONTH")}
                disabled={!states?.isModifiedDeclaration || !editable}
                selectedMonth={states?.notApplyFromMonth}
                onSelectMonth={(e) =>
                  handleChange({ key: "notApplyFromMonth", value: e })
                }
              />
              <Box width="16px" />
              <Typography variant="body1">
                hónaptól kezdődően nem (erre a hónapra sem) kívánom igénybe
                venni.
              </Typography>
            </Box>
          </Box>
          <Box height="36px" />
        </OcCard>
        <Box height="16px" />
        <TaxCreditApplicationButtonBars
          taxApplicationSaveEnabled={editable && taxApplicationSaveEnabled}
          cancelData={handleCancel}
          handlePrint={handlePrint}
          saveData={handleSave}
          editable={editable}
          disabledButton={disabledButton}
          handleRemoveRequest={handleRemoveRequest}
          showModifiedButton={showModifiedButton}
          showRemoveButton={showRemoveButton}
          isModified={isModified}
          isModifiedDeclaration={states?.isModifiedDeclaration}
        />
      </Box>
    </>
  );
};

export default FourChildrenTaxCreditApplication;
