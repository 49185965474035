import { atomFamily } from "recoil";

export const myTimeGeneralSettingsState = atomFamily({
  key: "myTimeSettingsState",
  default: {
    value: false,
    prevValue: false,
    loading: false,
  },
});
