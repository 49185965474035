import React from "react";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const ApplicationCardInfoItem = ({ label, value, disabled }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      paddingBottom="10px"
    >
      <Box width="221px" textAlign="right">
        <Typography
          variant="body1"
          style={{ color: disabled ? "#919699" : "#212121" }}
        >
          {label}
        </Typography>
      </Box>
      <Box width="16px" />
      <Box>
        <Typography
          style={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default ApplicationCardInfoItem;
