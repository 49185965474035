import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_WORK_TIME } from "my-time/graphql/queries";
import { MY_TIME_REQUESTS_SUBSCRIPTION } from "my-time/graphql/subscriptions";

const useWorkTime = ({ contractCode, startTime, endTime, myTimeType }) => {
  const { data, loading, error, refetch } = useQuery(GET_WORK_TIME, {
    fetchPolicy: "cache-and-network",
    skip: !contractCode || !startTime || !endTime || !myTimeType,
    variables: {
      contractCode,
      startTime,
      endTime,
      myTimeType,
    },
  });

  useSubscription(MY_TIME_REQUESTS_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  return {
    data: data?.getWorkTime || [],
    loading,
    error,
  };
};

export default useWorkTime;
