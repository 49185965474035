import { useQuery } from "@apollo/client";
import { GET_DEPENDENTS } from "graphql/queries";
import useCurrentUserState from "hooks/use-current-user-state";
import usePolling from "hooks/use-polling";

const useDependentsData = () => {
  let { contractCode } = useCurrentUserState();
  const { data, loading, error } = useQuery(GET_DEPENDENTS, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: {
      contractCode,
    },
  });
  const result = data?.dependents || [];
  return {
    data: result,
    loading,
    error,
  };
};

export default useDependentsData;
