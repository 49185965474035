import React from "react";
import Typography from "@material-ui/core/Typography";
import OcFormSwitch from "oc/components/oc-form-switch/oc-form-switch";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";

const OcGeneralSwitchButtonSetting = ({
  label,
  infoText,
  propertyKey,
  disabled = false,
  useMyTimePropertyHook,
}) => {
  const { t } = useTranslation();

  const { state, loading, handleChange } = useMyTimePropertyHook({
    propertyKey,
  });

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      paddingTop="16px"
      paddingBottom="24px"
    >
      <Box width="200px" textAlign="right" color="#4D4D4D">
        <Typography variant="caption">{t(label)}</Typography>
      </Box>
      <Box width="16px" />
      <OcFormSwitch
        id={propertyKey}
        checked={state.value}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
        disabled={disabled || loading}
      />
      <Box width="16px" />
      <Box width="300px">
        <Box
          fontSize="12px"
          letterSpacing="0.4px"
          color="#4D4D4D"
          width="300px"
        >
          {t(infoText)}
        </Box>
      </Box>
    </Box>
  );
};

export default OcGeneralSwitchButtonSetting;
