import gql from "graphql-tag";

export const GET_TAX_DECLARATION_PERSONAL = gql`
  query taxDeclarationPersonal($_id: Int!) {
    taxDeclarationPersonal(_id: $_id) {
      dateOfDeclaration
      isModifiedDeclaration
      state
      disabledStateFrom
      disabledStateTo
      isFinal
      disabilityPension
      disabilityPensionNumber
      disabilitySupport
      disabilitySupportNumber
      applyToWithdrawal
      noRequestRelief
    }
  }
`;
