import { useQuery } from "@apollo/react-hooks";
import {
  GET_PAYCARD_LIST_BY_CONTRACT,
  GET_LAST_PAYCARD_DATA_BY_CONTRACT,
} from "graphql/queries";
import usePolling from "hooks/use-polling";

export default function usePayrollData({ contract, year }) {
  const { data, loading, error } = useQuery(GET_PAYCARD_LIST_BY_CONTRACT, {
    fetchPolicy: "cache-and-network",
    skip: !contract || !year,
    variables: { contractCode: contract, year },
  });

  const { data: lastPaycardData } = useQuery(
    GET_LAST_PAYCARD_DATA_BY_CONTRACT,
    {
      skip: !contract,
      fetchPolicy: "cache-and-network",
      pollInterval: usePolling(1000 * 60),
      variables: { contractCode: contract },
    }
  );

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  let queryResult = data?.getPaycardListByContract || [];
  let result = months.map((month) => {
    let newItem = {
      __typename: "PayCardDataType",
      month: month,
      netWage: 0,
      otherBenefits: 0,
      allTaxDeductions: 0,
      otherDeductions: 0,
      grossWage: 0,
    };
    let existMonth = queryResult.find((item) => item.month === month);
    return existMonth || newItem;
  });

  return {
    data: result,
    lastPaycardData: lastPaycardData?.getLastPaycardDataByContract,
    loading,
    error,
  };
}
