import gql from "graphql-tag";

export const CREATE_TAX_DECLARATION_PERSONAL = gql`
  mutation createTaxDeclarationPersonal(
    $dateOfDeclaration: Date!
    $isModifiedDeclaration: Boolean!
    $disabledStateFrom: Date
    $disabledStateTo: Date
    $isFinal: Boolean!
    $disabilityPension: Boolean!
    $disabilityPensionNumber: String
    $disabilitySupport: Boolean!
    $disabilitySupportNumber: String
    $applyToWithdrawal: Boolean
    $noRequestRelief: Boolean
  ) {
    createTaxDeclarationPersonal(
      dateOfDeclaration: $dateOfDeclaration
      isModifiedDeclaration: $isModifiedDeclaration
      disabledStateFrom: $disabledStateFrom
      disabledStateTo: $disabledStateTo
      isFinal: $isFinal
      disabilityPension: $disabilityPension
      disabilityPensionNumber: $disabilityPensionNumber
      disabilitySupport: $disabilitySupport
      disabilitySupportNumber: $disabilitySupportNumber
      applyToWithdrawal: $applyToWithdrawal
      noRequestRelief: $noRequestRelief
    ) {
      result
      messages
      tableId
    }
  }
`;
