import React from "react";
import { Box, Typography } from "@material-ui/core";
import MyTimePageHeader from "my-time/components/my-time-page-header";
import OcPageTransition from "oc/components/oc-page-transition";
import useActualDate from "my-time/hooks/use-actual-date";
import OcCard from "oc/components/oc-card/oc-card";
import useOpenRequests from "my-time/hooks/use-open-requests";
import { useTranslation } from "react-i18next";
import OcDataTable from "oc/components/oc-data-table/oc-data-table";
import { employeeMyTimeClosingPeriodRequest } from "my-time/configs/tableDefs";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import Button from "@material-ui/core/Button";
import moment from "moment";
import useRecordingPeriod from "my-time/hooks/use-recording-period";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import { useMutation } from "@apollo/client";
import { CLOSE_RECORDING_PERIOD } from "my-time/graphql/mutations";
import { useSetRecoilState } from "recoil";
import { myTimeMessageState } from "my-time/states/my-time-message";
import useOcSnackbar from "hooks/use-oc-snackbar";

const MyTimeClosingCurrentPeriodPage = () => {
  const { t } = useTranslation();

  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);

  const { date, formattedDate, handleDateChange } = useActualDate();
  const { data, loading, error } = useOpenRequests({
    startDate: moment(date).startOf("month").toDate(),
    endDate: moment(date).endOf("month").toDate(),
  });

  let tableId = "employeeAdmissionsTableData";
  initialTableData.sortField = "title";
  initialTableData.pageSize = 5;

  const list = {
    rows: data,
    total: data?.length,
  };

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const { setOpenMessage } = useOcSnackbar();
  const clearMessageState = useSetRecoilState(myTimeMessageState);

  const [closeRecordingPeriod] = useMutation(CLOSE_RECORDING_PERIOD, {
    onCompleted: (data) => {
      const res = data?.closeRecordingPeriod;
      if (res.result) {
        setOpenMessage({
          type: "success",
          message: t("CLOSE_RECORDING_PERIOD_SUCCEEDED"),
        });
        clearMessageState("");
      } else {
        setOpenMessage({
          type: "error",
          message: res.message || t("CLOSE_RECORDING_PERIOD_FAILED"),
        });
        clearMessageState("");
      }
    },
    onError: () => {
      setOpenMessage({
        type: "error",
        message: t("CLOSE_RECORDING_PERIOD_FAILED"),
      });
    },
  });

  const handleCloseMonth = () => {
    setShowConfirmationDialog(true);
  };

  const { data: recordingPeriod } = useRecordingPeriod();

  let disabled = false;
  if (
    data?.length > 0 ||
    recordingPeriod !== moment(date).format("YYYY. MMMM")
  ) {
    disabled = true;
  }

  if (loading || error) {
    disabled = true;
  }

  const handleCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
    closeRecordingPeriod();
  };

  const handleCancelConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  return (
    <OcPageTransition>
      <Box padding="16px">
        <OcConfirmationDialog
          showDialog={showConfirmationDialog}
          onClose={handleCloseConfirmationDialog}
          onCancel={handleCancelConfirmationDialog}
          title={t("CONFIRM_CLOSE_MONTH_TITLE")}
          text={t("CONFIRM_CLOSE_MONTH_TEXT")}
          okButtonText={t("CLOSE_MONTH")}
          cancelButtonText={t("CANCEL")}
        />

        <MyTimePageHeader
          formattedDate={formattedDate}
          date={date}
          onDateChange={handleDateChange}
        />
        <Box height="16px" />
        <Box>
          <OcCard label={t("ACTIVE_REQUESTS")} loading={loading} error={error}>
            {data?.length === 0 && !loading && (
              <Box
                style={{
                  textAlign: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <img
                    alt="orgware-icon"
                    src="/img/OK.svg"
                    style={{ width: 500 }}
                  />
                </Box>
                <Box height="36px" />
                <Typography variant="body1">
                  {t("REQUEST_NOT_FOUND")}
                </Typography>
              </Box>
            )}
            {data?.length > 0 && (
              <Box>
                <OcDataTable
                  tableDef={employeeMyTimeClosingPeriodRequest}
                  tableId={tableId}
                  tableData={tableData}
                  setTableData={setTableData}
                  data={list}
                  loading={loading}
                  //onSelectRow={handleSelectRow}
                  pagination={true}
                  localPaging={true}
                />
              </Box>
            )}
            <Box height="36px" />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                id="closing-period-button"
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={handleCloseMonth}
              >
                {t("CLOSING_PERIOD")}
              </Button>
            </Box>
            <Box height="36px" />
          </OcCard>
        </Box>
      </Box>
    </OcPageTransition>
  );
};

export default MyTimeClosingCurrentPeriodPage;
