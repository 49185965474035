import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: 36,
    paddingBottom: 24,
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "center",
  },
  titleSpan: {
    fontSize: 34,
    fontFamily: "Mulish",
    letterSpacing: 0.25,
  },
  content: {
    padding: "8px 16px",
  },
  formControl: {
    minWidth: 410,
    width: "100%",
  },
  selectControl: {
    height: "56px",
    backgroundColor: theme.palette.white.main,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.gray80.main,
    border: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selectInput: {
    backgroundColor: theme.palette.white.main,
    "&:focus": {
      backgroundColor: theme.palette.white.main,
    },
  },
  selectLabel: {
    backgroundColor: theme.palette.white,
    color: theme.palette.gray80.main,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    top: `calc(${theme.spacing(1)} * -0.4px)`,
    zIndex: 2,
  },

  centerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 32,
    marginBottom: 36,
  },
  pickerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 148,
    width: 182,
  },
  line: {
    border: `1px solid ${theme.palette.gray30.main}`,
    marginBottom: 36,
  },
  button: {
    marginBottom: 36,
  },
  divider: {
    height: 36,
  },
  selectItem: {
    width: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  weekendDay: {
    backgroundColor: theme.palette.gray20.main,
  },
  paidHolliday: {
    color: "#cc0033",
  },
  today: {
    backgroundColor: "rgba(204, 0, 51, 0.2)",
    color: "#212121",
  },
  selected: {
    "& .MuiPickersDay-daySelected": {
      borderRadius: 0,
    },
  },
}));

export default useStyles;
