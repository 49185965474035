import React from "react";
import { Box } from "@material-ui/core";
import ApplicationCardInfoItem from "../application-card-info-item/application-card-info-item";
import Checkbox from "@material-ui/core/Checkbox";
import { CustomTooltip } from "../dependents-collapse/dependents-collapse.style";
import ModifiedStatementInfo from "../modified-statement-info/modified-statement-info";
import { Icon } from "@iconify/react";
import IconButton from "@material-ui/core/IconButton";
import informationOutline from "@iconify/icons-mdi/information-outline";

export default function ModifiedStatementCheck({
  label,
  name,
  value,
  onChange,
  disabled,
}) {
  return (
    <Box display="flex" alignItems="center">
      <ApplicationCardInfoItem
        label={label}
        disabled={disabled}
        value={
          <Checkbox
            id="modified-application-box"
            checked={value}
            onChange={(e) => onChange({ key: name, value: e.target.checked })}
            color="secondary"
            style={{
              backgroundColor: "transparent",
              color: disabled ? "#919699" : "#212121",
            }}
            disabled={disabled}
          />
        }
      />
      <CustomTooltip title={<ModifiedStatementInfo />} placement="right">
        <IconButton
          aria-label="Info"
          style={{
            marginTop: "-9px",
            color: disabled ? "#919699" : "#212121",
          }}
        >
          <Icon
            icon={informationOutline}
            width="20px"
            color={disabled ? "#919699" : "#212121"}
          />
        </IconButton>
      </CustomTooltip>
    </Box>
  );
}
