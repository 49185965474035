import React from "react";
import { Box } from "@material-ui/core";
import MyTimePageHeader from "my-time/components/my-time-page-header";
import OcPageTransition from "oc/components/oc-page-transition";
import useCurrentUserState from "hooks/use-current-user-state";
import Grid from "@material-ui/core/Grid";
//import MyTimeFramePeriodCard from "my-time/components/my-time-frame-period-card";
//import MyTimePeriodCard from "my-time/components/my-time-period-card";
import MyTimeExaminersCard from "my-time/components/my-time-examiners-card";
import MyTimeEmployeeRequestsCard from "my-time/components/my-time-employee-requests-card";
import MyTimeAdmissionCard from "my-time/components/my-time-admission-card";
import useActualDate from "my-time/hooks/use-actual-date";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import useExaminers from "my-time/hooks/use-examiners";
import MyTimeWorkingTimeFrame from "my-time/components/my-time-working-time-frame";
import MyTimeRemainsFrame from "my-time/components/my-time-remains-frame";
import MyTimeOvertimeFrame from "my-time/components/my-time-overtime-frame";


const MyTimeEmployeeSchedulePage = () => {
  const { t, i18n } = useTranslation();
  let { contractCode, employeeCode } = useCurrentUserState();

  const { date, formattedDate, year, month, handleDateChange } =
    useActualDate();

  const { data: changeMyTimeEnabledData } = useProperty({
    resource: "myTime",
    key: "changeMyTimeEnabled",
  });

  const { data, loading, error } = useExaminers({
    contractCode,
    locale: i18n.language,
  });

  const { data: changeWorkingTimeEnabledData } = useProperty({
    resource: "myTime",
    key: "changeWorkingTimeEnabled",
  });

  const changeMyTimeEnabled = changeMyTimeEnabledData === "true" ? true : false;
  const changeWorkingTimeEnabled =
    changeWorkingTimeEnabledData === "true" ? true : false;

  const isNotExaminer = data?.length === 0

  return (
    <OcPageTransition>
      <Box padding="16px">
        <MyTimePageHeader
          formattedDate={formattedDate}
          date={date}
          onDateChange={handleDateChange}
        />
        <Box padding="16px 0px">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <MyTimeWorkingTimeFrame
                contractCode={contractCode}
                year={year}
                month={month}
              />
            </Grid>
            <Grid item xs={3}>
              <MyTimeRemainsFrame
                contractCode={contractCode}
                year={year}
                month={month}
              />
            </Grid>
            <Grid item xs={3}>
              <MyTimeOvertimeFrame
                contractCode={contractCode}
                year={year}
                month={month}
              />
            </Grid>
            <Grid item xs={3}>
              <MyTimeExaminersCard data={data} loading={loading} error={error} />
            </Grid>
          </Grid>
          <Box height="16px" />
          <MyTimeEmployeeRequestsCard
            label={t("MY_ADMISSION")}
            contractCode={contractCode}
            employeeCode={employeeCode}
            date={date}
            examinerView={false}
            userRole="user"
          />
          <Box height="16px" />
          <MyTimeAdmissionCard
            contractCode={contractCode}
            employeeCode={employeeCode}
            year={year}
            month={month}
            changeMyTimeEnabled={changeMyTimeEnabled}
            changeWorkingTimeEnabled={changeWorkingTimeEnabled}
            userRole="user"
            isNotExaminer={isNotExaminer}
          />
        </Box>
      </Box>
    </OcPageTransition>
  );
};

export default MyTimeEmployeeSchedulePage;
