import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InlineIcon } from "@iconify/react";
import SendIcon from "@iconify/icons-mdi/send";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_CHANGE_MESSAGE } from "graphql/mutations";
import useStyles from "./my-hr-new-message.style";
import useCurrentUserState from "hooks/use-current-user-state";
import useChangeRequests from "hooks/use-change-requests";

const MyHrNewMessage = ({
  loading,
  selectedRequest,
  disabled,
  type,
  typeGroupId,
  typeGroup,
}) => {
  const { employeeCode } = useCurrentUserState();
  const { refetch } = useChangeRequests(employeeCode);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [newMessageText, setNewMessageText] = useState("");
  const [newMessageError, setNewMessageError] = useState(false);

  const [createChangeMessage] = useMutation(CREATE_CHANGE_MESSAGE, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleSubmitNewMessage = () => {
    if (newMessageText === "") {
      setNewMessageError(true);
      return;
    }

    let variables = {
      request: selectedRequest,
      message: newMessageText,
      locale: i18n.language,
      createdBy: employeeCode,
      type: t(type),
    };

    if (typeGroup) {
      variables.request = typeGroupId;
    }

    createChangeMessage({
      variables: variables,
    });

    setNewMessageText("");
    setNewMessageError(false);
  };

  useEffect(() => {
    return () => {
      setNewMessageText("");
      setNewMessageError(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        placeholder={t("Leave.writeMessage")}
        value={newMessageText}
        onChange={(e) => setNewMessageText(e.target.value)}
        onFocus={() => setNewMessageError(false)}
        error={newMessageError}
        helperText={
          newMessageError
            ? `${t("EMPTY_MESSAGE")}`
            : `${newMessageText.length}/255`
        }
        style={{
          height: "auto",
        }}
        disabled={loading || disabled}
        inputProps={{ maxLength: 255 }}
        FormHelperTextProps={
          newMessageError
            ? { style: { textAlign: "left" } }
            : { style: { textAlign: "right" } }
        }
        InputProps={{
          style: {
            backgroundColor: "white",
          },
          endAdornment: (
            <InputAdornment position="end">
              <InlineIcon
                id="my-hr-new-message-button"
                icon={SendIcon}
                className={classes.iconSend}
                onClick={handleSubmitNewMessage}
                style={{ cursor: "pointer" }}
              />
            </InputAdornment>
          ),
        }}
      />{" "}
    </>
  );
};

export default MyHrNewMessage;
