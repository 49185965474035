import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import SwitchInput from "my-hr/components/my-hr-switch-input";
import MyHrSSOForm from "my-hr/components/my-hr-sso-form";
import ButtonBar from "my-leave/components/my-leave-email-card/components/button-bar";
import useOcProperty from "hooks/use-oc-property";
import useClientState from "hooks/use-oc-client-state-2";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_PROPERTY } from "graphql/mutations";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import useOcSnackbar from "hooks/use-oc-snackbar";
import useOcTextInput from "hooks/use-oc-text-input";
import useProperty from "hooks/use-oc-property";

export default function MyHrAdminSystemSettingsLoginMenu() {
  const { t } = useTranslation();

  const { setOpenMessage } = useOcSnackbar();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { data: loginType } = useOcProperty({
    resource: "system",
    key: "loginType",
  });

  const [loginTypeTempState, setLoginTypeTempState] = useClientState(
    "loginTypeTempState",
    loginType
  );

  const { data: passwordPolicy } = useOcProperty({
    resource: "system",
    key: "passwordPolicy",
  });

  const [passwordPolicyTempState, setPasswordPolicyTempState] = useClientState(
    "passwordPolicyTempState",
    ""
  );

  if (passwordPolicy) {
    if (!passwordPolicyTempState) {
      setPasswordPolicyTempState(passwordPolicy);
    }
  }

  function handlePasswordPolicyChange() {
    let newState = passwordPolicyTempState === "strong" ? "weak" : "strong";
    setPasswordPolicyTempState(newState);
  }

  function handleLoginTypeChange() {
    let newState = loginTypeTempState === "sso" ? "legacy" : "sso";
    setLoginTypeTempState(newState);
  }

  const { data: passwordVisibility } = useOcProperty({
    resource: "system",
    key: "passwordVisibility",
  });

  const isPasswordVisible = passwordVisibility === "true" ? true : false;

  const [passwordVisibilityTempState, setPasswordVisibilityTempState] = useState(isPasswordVisible);

  const ssoAddress = useOcTextInput({ optional: false, defaultValue: "" });
  const ssoPort = useOcTextInput({ optional: true, defaultValue: "" });
  const ssoCN = useOcTextInput({ optional: false, defaultValue: "" });
  const domainSuffix = useOcTextInput({ optional: true, defaultValue: "" });
  const systemUserName = useOcTextInput({ optional: true, defaultValue: "" });
  const systemUserPassword = useOcTextInput({ optional: true, defaultValue: "" });

  const { data: ldapAddress } = useProperty({
    resource: "system",
    key: "ldapAddress",
    onCompleted: ({ value }) => {
      if (value) {
        ssoAddress.setValue(value);
      }
    },
  });

  const { data: ldapPort } = useProperty({
    resource: "system",
    key: "ldapPort",
    onCompleted: ({ value }) => {
      if (value) {
        ssoPort.setValue(value);
      }
    },
  });

  const { data: ldapCN } = useProperty({
    resource: "system",
    key: "ldapCN",
    onCompleted: ({ value }) => {
      if (value) {
        ssoCN.setValue(value);
      }
    },
  });

  const { data: ldapDomainSuffix } = useProperty({
    resource: "system",
    key: "ldapDomainSuffix",
    onCompleted: ({ value }) => {
      if (value) {
        domainSuffix.setValue(value);
      }
    },
  });

  const { data: ldapSystemUserName } = useProperty({
    resource: "system",
    key: "ldapSystemUserName",
    onCompleted: ({ value }) => {
      if (value) {
        systemUserName.setValue(value);
      }
    },
  });

  const { data: ldapSystemUserPassword } = useProperty({
    resource: "system",
    key: "ldapSystemUserPassword",
    onCompleted: ({ value }) => {
      if (value) {
        systemUserPassword.setValue(value);
      }
    },
  });

  let passwordPolicyInfoText =
    passwordPolicyTempState === "strong"
      ? t("INFO_TEXT_STRONG_PASSWORD")
      : t("INFO_TEXT_NOT_STRONG_PASSWORD");

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onCompleted: () => {
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
    onError({ ...err }) {
      alert("error" + err.toString());
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
  });

  function handleSave() {
    if (loginTypeTempState === "legacy") {
      updateProperty({
        variables: {
          resource: "system",
          key: "loginType",
          value: loginTypeTempState,
        },
      });
      if (passwordPolicy !== passwordPolicyTempState) {
        let newState = "strong";
        if (passwordPolicy === "strong") {
          newState = "weak";
        }
        updateProperty({
          variables: {
            resource: "system",
            key: "passwordPolicy",
            value: newState,
          },
        });
      }
    }
    if (loginTypeTempState === "sso") {
      updateProperty({
        variables: {
          resource: "system",
          key: "loginType",
          value: loginTypeTempState,
        },
      });
      if (!ssoAddress.isValid()) {
        ssoAddress.validate();
      }

      if (!ssoPort.isValid()) {
        ssoPort.validate();
      }

      updateProperty({
        variables: {
          resource: "system",
          key: "ldapAddress",
          value: ssoAddress.value,
        },
      });
      updateProperty({
        variables: {
          resource: "system",
          key: "ldapPort",
          value: ssoPort.value,
        },
      });
      updateProperty({
        variables: {
          resource: "system",
          key: "ldapCN",
          value: ssoCN.value,
        },
      });
      updateProperty({
        variables: {
          resource: "system",
          key: "ldapDomainSuffix",
          value: domainSuffix.value,
        },
      });
      updateProperty({
        variables: {
          resource: "system",
          key: "ldapSystemUserName",
          value: systemUserName.value,
        },
      });
      updateProperty({
        variables: {
          resource: "system",
          key: "ldapSystemUserPassword",
          value: systemUserPassword.value,
        },
      });
    }
    if (passwordVisibility !== passwordVisibilityTempState) {
      updateProperty({
        variables: {
          resource: "system",
          key: "passwordVisibility",
          value: passwordVisibilityTempState.toString(),
        },
      });
    }
    setShowConfirmDialog(false);
  }

  function handleCancel() {
    if (passwordPolicy !== passwordPolicyTempState) {
      setPasswordPolicyTempState(passwordPolicy);
    }
    if (loginTypeTempState !== loginType) {
      setLoginTypeTempState(loginType);
    }
    if (isPasswordVisible !== passwordVisibilityTempState) {
      setPasswordVisibilityTempState(isPasswordVisible);
    }
  }

  let disabled = true;

  if (passwordPolicy !== passwordPolicyTempState) {
    disabled = false;
  }
  if (loginTypeTempState !== loginType) {
    disabled = false;
  }
  if (isPasswordVisible !== passwordVisibilityTempState) {
    disabled = false;
  }
  if (
    loginType === "sso" &&
    (ldapAddress !== ssoAddress.value ||
      ldapPort !== ssoPort.value ||
      ldapCN !== ssoCN.value ||
      ldapDomainSuffix !== domainSuffix.value ||
      ldapSystemUserName !== systemUserName.value ||
      ldapSystemUserPassword !== systemUserPassword.value)
  ) {
    disabled = false;
  }

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  useEffect(() => {
    setPasswordVisibilityTempState(isPasswordVisible);
  }, [isPasswordVisible]);

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcCard label={t("SIGN_IN")}>
        <Box>
          <SwitchInput
            id="single-sign-on-switch"
            label="Single Sign-On (SSO)"
            info={t("INFO_TEXT_SSO")}
            checked={loginTypeTempState === "sso"}
            onChange={handleLoginTypeChange}
          />
          <Box height="28px" />
          {loginTypeTempState === "sso" ? (
            <Box>
              <MyHrSSOForm
                ssoAddress={ssoAddress}
                ssoPort={ssoPort}
                ssoCN={ssoCN}
                domainSuffix={domainSuffix}
                systemUserName={systemUserName}
                systemUserPassword={systemUserPassword}
              />
              <Box height="28px" />
              <SwitchInput
                id="password-visibility-info-switch"
                label={t("PASSWORD_VISIBILITY")}
                info={t("PASSWORD_VISIBILITY_INFO")}
                checked={passwordVisibilityTempState}
                onChange={(e) => setPasswordVisibilityTempState(e.target.checked)}
              />
            </Box>
          ) : (
            <Box>
              <SwitchInput
                id="password-policy-info-switch"
                label={t("USING_STRONG_PASSWORD")}
                info={passwordPolicyInfoText}
                checked={passwordPolicyTempState === "strong"}
                onChange={handlePasswordPolicyChange}
              />
              <Box height="28px" />
              <SwitchInput
                id="password-visibility-info-switch"
                label={t("PASSWORD_VISIBILITY")}
                info={t("PASSWORD_VISIBILITY_INFO")}
                checked={passwordVisibilityTempState}
                onChange={(e) => setPasswordVisibilityTempState(e.target.checked)}
              />
            </Box>
          )}
          <ButtonBar
            handleCancel={handleCancel}
            handleSave={() => setShowConfirmDialog(true)}
            disabled={disabled}
          />
        </Box>
      </OcCard>
    </>
  );
}
