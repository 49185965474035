import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const taxDeclarationFourChildren = atom({
  key: "taxDeclarationFourChildren",
  default: {
    dateOfDeclaration: new Date(),
    isModifiedDeclaration: false,
    isEligible: false,
    permanentlyEntitled: false,
    notApplyFromMonth: "",
    dependents: [],
  },
  effects_UNSTABLE: [persistAtom],
});
