import React from "react";
import { Box, Typography } from "@material-ui/core";
import OcPrivateLayoutFooter from "oc/components/oc-private-layout-footer";
import useStyles from "../family-tax-credit-information/family-tax-credit-information.style";

const UnderThirtyMothersTaxCreditInformation = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box padding="16px" style={{ backgroundColor: "#F1F3F4" }}>
        <Box>
          <Typography
            variant="h4"
            style={{
              fontSize: "34px",
              fontWeight: "400",
              color: "#5E6366",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Tájékoztató
          </Typography>
          <Box height="32px" />
          <Typography
            variant="h5"
            style={{
              fontSize: "24px",
              fontWeight: "400",
              color: "#212121",
              display: "flex",
              justifyContent: "center",
            }}
          >
            a 30 év alatti anyák kedvezményének érvényesítéséről szóló
            adóelőleg-nyilatkozathoz
          </Typography>
          <Box height="32px" />
          <Box className={classes.inlineFlexBoxs}>
            <Box className={classes.bgBox}>
              <Typography
                variant="h5"
                style={{
                  fontSize: "24px",
                  fontWeight: "400",
                  color: "#212121",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Tudnivalók a nyilatkozathoz
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Kinek kell átadni ezt a nyilatkozatot?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ahhoz, hogy munkáltatója, kifizetője az Ön törvényben
                meghatározott jövedelmeire a 30 év alatti anyák kedvezményét
                figyelembe véve állapítsa meg az adóelőleget,{" "}
                <b>ezt a nyilatkozatot két példányban kell átadnia</b> részére.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A nyilatkozat egyik példányát a munkáltatónak (kifizetőnek),
                másik példányát pedig Önnek kell a bevallás benyújtásának évét
                követő 5. év végéig megőriznie. A munkáltató, kifizető a
                nyilatkozatot az adóéven belül, az átadást követő kifizetéseknél
                veszi figyelembe.
              </Typography>
              <Box />
              <Box height="25px" />
              <Typography variant="body1">
                Az adóelőleg-nyilatkozat nem kizárólag munkáltatónak adható át,
                hanem olyan kifizetőnek is, aki Önnek összevonás alá eső, a
                törvényben meghatározott jövedelmet juttat.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <i>
                  Ha Ön a 30 év alatti anyák kedvezményét jogalap nélkül veszi
                  igénybe, és ezért az adóbevallásában 10 ezer forintot
                  meghaladó befizetési kötelezettsége, vagyis adóhátraléka
                  keletkezik, akkor ennek 12 százalékát különbözeti bírságként
                  kell megfizetnie az adóhátralékkal együtt.
                </i>
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Kinek jár a kedvezmény?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A 30 év alatti anyák kedvezményére jogosult az a 25. életévét
                betöltött fiatal anya, aki
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  az Szja tv. 29/A. § (3) bekezdés a) pontja szerint a{" "}
                  <b>
                    vér szerinti vagy örökbe fogadott gyermekére tekintettel,
                  </b>
                  vagy
                </li>
                <li>
                  az Szja tv. 29/A. § (3) bekezdés b) pontja szerint
                  <b> magzatra tekintette</b>
                </li>
              </Typography>
              <Box height="10px" />
              <Typography variant="body1">
                <b>családi kedvezmény érvényesítésére jogosult.</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>
                  A kedvezmény akkor illeti meg a 30 év alatti anyát, ha vér
                  szerinti vagy örökbe fogadott gyermekére, magzatára
                  tekintettel a családi kedvezményre való jogosultsága
                  2022.december 31-e után, az anya 30. életéve betöltését
                  megelőző napon nyílik meg.
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Például a kedvezményre jogosult a fiatal anya 2024-ben, ha:
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>február 8-án betölti a várandósság 91. napját, vagy </li>
                <li>március 15-én megszüli második gyermekét, vagy </li>
                <li>augusztus 6-án örökbe fogadja a férje gyermekét, </li>
              </Typography>
              <Box height="10px" />
              <Typography variant="body1">
                mindhárom esetben elmúlt 25 éves és legkorábban ezen események
                után tölti be a 30. életévét.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Kinek nem jár a kedvezmény?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Nem jogosult a kedvezményre az anya 2024-ben például, ha
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>23 évesen szül,</li>
                <li>egy 2 és egy 4 éves gyermeket nevel,</li>
                <li>31 évesen, májusban tölti be a várandósság 91. napját.</li>
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Mennyi kedvezmény jár?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A 30 év alatti anyák kedvezményének összege jogosultsági
                hónaponként legfeljebb a nemzetgazdasági szintű bruttó
                átlagkereset összege lehet, ez{" "}
                <b>2024-ben jogosultsági hónaponként 576 601 forint,</b> ami{" "}
                <b>86 490 forint adómegtakarítást</b> jelent.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Milyen jövedelmet érint?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A 30 év alatti anyák kedvezménye{" "}
                <b>
                  a jogosultsági hónapokban megszerzett, a jogosultsági
                  hónapokra elszámolt, alábbi jövedelmekre érvényesíthető:
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                a bérnek minősülő és más nem önálló tevékenységből származó
                jövedelemre, ilyenek különösen:
              </Typography>
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  a bérnek minősülő és más nem önálló tevékenységből származó
                  jövedelemre, ilyenek különösen:
                </li>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  a munkaviszonyból, közfoglalkoztatási jogviszonyból származó
                  jövedelem,
                </li>
                <li>
                  az adóköteles társadalombiztosítási ellátás, például: táppénz,
                  csecsemőgondozási díj, gyermekgondozási díj,
                </li>
                <li>
                  a szociális igazgatásról és szociális ellátásokról szóló
                  törvény alapján folyósított adóköteles ellátások,
                </li>

                <li>
                  álláskeresési járadék, nyugdíj előtti álláskeresési segély,
                  álláskeresési segély, kereset-kiegészítés, keresetpótló
                  juttatás, és álláskeresést ösztönző juttatás (1.),
                </li>
                <li>
                  a Magyar Honvédségnél tartalékos katonai szolgálatot teljesítő
                  magánszemély e jogviszonyból származó jövedelme,
                </li>
                <li>
                  ez előzőekben meghatározott jövedelmet pótló kártérítés
                  (keresetpótló járadék),
                </li>
                <li>
                  a nevelőszülői foglalkoztatási jogviszonyban végzett
                  tevékenység díjazása,
                </li>
                <li>
                  a munkaviszony megszűnése miatt kifizetett végkielégítés, de a
                  végkielégítés törvényben meghatározott mértéket meghaladó
                  része nem képezi a kedvezmény alapját,
                </li>
                <li>
                  a társas vállalkozás magánszemély tagjának személyes
                  közreműködése ellenértékeként kifizetett jövedelem,
                </li>
                <li>
                  a gazdasági társaság vezető tisztségviselőjének tevékenységére
                  tekintettel adott juttatás,
                </li>
                <li>
                  a jogszabály alapján választott vagy kijelölt tisztségviselő
                  tevékenysége (például igazgatótanácsi tag, felügyelőbizottsági
                  tag) ellenértékeként kapott juttatás,
                </li>
                <li>
                  az Európai Unió Tanácsa 2024. második félévi magyar
                  elnökségével kapcsolatos feladatok ellátására irányuló
                  jogviszony keretében végzett tevékenység,
                </li>
                <li>
                  nemzetközi szerződés hatálya alatt a nem önálló munkából,
                  ennek hiányában az adott állam joga szerinti munkaviszonyból
                  származó jövedelem,
                </li>
                <li>
                  az országgyűlési képviselők, nemzetiségi szószólók,
                  polgármesterek e tevékenységből származó jövedelme,
                </li>
                <li>
                  az állami projektértékelői jogviszonyból származó jövedelem.
                </li>
              </Typography>
              <Box height="15px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>Az önálló tevékenységből származó jövedelmek közül:</li>
              </Typography>
              <Typography variant="body1" className={classes.insideListItems}>
                <li>
                  a vállalkozói jövedelem szerinti adózást alkalmazó egyéni
                  vállalkozó vállalkozói kivétjére, átalányadózás esetén az
                  átalányban megállapított jövedelmére,
                </li>
                <li>
                  o a mezőgazdasági őstermelő e tevékenységéből származó
                  jövedelmére,
                </li>
                <li>
                  az európai parlamenti képviselő e tevékenységéből származó
                  jövedelmére,
                </li>
                <li>
                  a helyi önkormányzati képviselő e tevékenységéből származó
                  jövedelmére,
                </li>

                <li>
                  a választott könyvvizsgáló e tevékenységéből származó
                  jövedelmére,
                </li>

                <li>
                  a magánszemély által nem egyéni vállalkozóként kötött, díjazás
                  ellenében történő munkavégzésre irányuló, más szerződés
                  alapján folytatott tevékenységéből, például megbízásból
                  származó jövedelmére.
                </li>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>
                  Jogosultsági hónapként az a hónap vehető figyelembe, amelyben
                  a 30 év alatti anya családi kedvezményre való jogosultsága a
                  vér szerinti vagy örökbe fogadott gyermekére, illetve
                  magzatára tekintettel fennáll, de legkorábban a 25. életéve
                  betöltésének hónapját követő hónap.
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A kedvezmény legfeljebb annak az évnek az utolsó jogosultsági
                hónapjáig érvényesíthető, amely évben az anya betölti a 30.
                életévét.{" "}
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                <b>Például, </b>
                ha
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                <li>
                  a 26 éves nő 2024. április 4-én éri el a várandósság 91.
                  napját, akkor a jogosultsága áprilistól kezdődően fennáll,{" "}
                </li>
                <li>
                  az édesanya 2023. január 8-án gyermeket szült és 2024 március
                  8-án betölti a 30. életévét, akkor a kedvezmény 2023
                  januárjától 2024 decemberéig jár,
                </li>
                <li>
                  ha a 24 éves édesanya márciusban megszüli a második gyermekét
                  és augusztus 5-én betölti a 25. életévét, akkor augusztus
                  31-ig a 25 év alatti fiatalok kedvezményére jogosult,
                  szeptembertől pedig a 30 év alatti anyák kedvezményére.
                </li>
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Hogyan érvényesíthető egyidejűleg több szja-kedvezmény?</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Kedvezmények érvényesítésének sorrendje:
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                1. Négy vagy több gyermeket nevelő anyák kedvezménye
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                2. 25 év alatti fiatalok kedvezménye
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                3. 30 év alatti anyák kedvezménye
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                4. Személyi kedvezmény
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                5. Első házasok kedvezménye
              </Typography>
              <Box height="10px" />
              <Typography variant="body1" className={classes.listItems}>
                6. Családi kedvezmény
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A 30 év alatti anyák kedvezménye és a 25 év alatti fiatalok
                kedvezménye együtt nem érvényesíthető. Az a 30 év alatti anya,
                aki még nem töltötte be a 25. életévét, csak a 25. születésnapja
                utáni hónaptól érvényesítheti a kedvezményt.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha a kedvezmény igénybevétele miatt az adóelőleg-alap nullára
                csökken, az említett jövedelmekre nem lehet a személyi
                kedvezményt, az első házasok kedvezményét, vagy a családi
                kedvezményt érvényesíteni, annak azonban nincs akadálya, hogy
                például a bérjövedelmére <b>családi járulékkedvezményt</b>{" "}
                érvényesítsen a magánszemély.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Azért, hogy a családi járulékkedvezményt igénybe tudja venni, a
                családi kedvezményre vonatkozó adóelőleg-nyilatkozatot is ki
                kell töltenie és el kell juttatnia a munkáltatójának.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Hasonlóan kell eljárnia akkor is, ha van olyan összevont
                adóalapba tartozó jövedelme, amelyre a 30 év alatti anyák
                kedvezményét nem tudja érvényesíteni, de a személyi kedvezményt,
                az első házasok kedvezményét, a családi kedvezményt vagy a
                családi járulékkedvezményt igen.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha Ön a házastársával, élettársával közösen érvényesít családi
                kedvezményt, járulékkedvezményt a nyilatkozattételkor vegye
                figyelembe a közös érvényesítés kedvezményre gyakorolt hatását
                is! A szabálytalan igénylés miatti visszafizetési kötelezettség
                elkerüléséhez figyelmébe ajánljuk a www.nav.gov.hu oldalon
                elérhető családikedvezménypéldatárat és -kalkulátorokat.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>Adatváltozáskor mit kell tenni? </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha a nyilatkozatban közölt adatok változnak,{" "}
                <b>Ön köteles haladéktalanul új nyilatkozatot tenni</b> – ezt az
                I. Blokk jobb felső sarkában lévő, <b>Módosított nyilatkozat</b>{" "}
                feliratú kódkockában kell X-szel jelölnie.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha év közben új munkáltatónál helyezkedik el és új nyilatkozatot
                tesz, az nem számít módosított nyilatkozatnak.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A kedvezményről bővebb tájékoztatást olvashat a NAV honlapján az
                „Szja-adóalap- kedvezmények” című 73. számú információs
                füzetben.
              </Typography>
              <Box height="25px" />
              <Typography variant="h6">
                <b>
                  Külföldi magánszemély milyen feltételek mellett érvényesítheti
                  a kedvezményt?
                </b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Önnek csak akkor jár a 30 év alatti anyák kedvezménye, ha azonos
                vagy hasonló <b>kedvezményt</b> ugyanarra az időszakra{" "}
                <b>másik államban</b>, ahol önálló, nem önálló tevékenységéből,
                nyugdíjból és más, hasonló, a korábbi foglalkoztatásból származó
                jövedelme megadóztatható - figyelemmel a kettős adóztatás
                elkerüléséről szóló nemzetközi egyezmények rendelkezéseire is -{" "}
                <b>nem vett és nem is vesz igénybe.</b>
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                A külföldi adóügyi illetőségű magánszemély a kedvezményt
                Magyarországon csak akkor érvényesítheti, ha az adóévben
                megszerzett összes jövedelmének – ideértve a Magyarországon nem
                adóztatható jövedelmet is –{" "}
                <b>75 százaléka Magyarországon adózik.</b> Az adóévben
                megszerzett összes jövedelembe beleszámít az önálló és nem
                önálló tevékenységből származó jövedelem – ideértve különösen a
                vállalkozói jövedelmet és a vállalkozói osztalékalapot vagy az
                átalányadó alapját – valamint a nyugdíj és más hasonló, a
                korábbi foglalkoztatásból megszerzett jövedelem, függetlenül
                attól, hogy mely országban adókötelesek.
              </Typography>
              <Box height="25px" />
              <Typography variant="body1">
                Ha Ön{" "}
                <b>
                  külföldi adóügyi illetőségű, a „Kiegészítő nyilatkozat a
                  külföldi adóügyi illetőségű magánszemélyek
                  adóalap-kedvezményének érvényesítéséhez” nyilatkozatot is ki
                  kell töltenie
                </b>{" "}
                és a munkáltató, bevételt juttató kifizető részére átadnia a
                kedvezményének igénybevételéhez. <b>Adóazonosító</b> jelét ebben
                az esetben is meg kell adnia.{" "}
              </Typography>
            </Box>
            <Box height="25px" />
            <Box marginLeft="16px">
              <Box className={classes.smallBgBox}>
                <Typography variant="body1">
                  (1.) A foglalkoztatás elősegítéséről és a munkanélküliek
                  ellátásáról szóló törvény alapján.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box height="16px" />
        <Box className={classes.bgBox}>
          <Typography variant="h5" className={classes.titles}>
            Az adóelőleg-nyilatkozat kitöltése
          </Typography>
          <Box height="32px" />
          <Typography variant="body1">
            <b style={{ textDecoration: "underline" }}>I. Blokk:</b> Ön az{" "}
            <b>1. pontban</b> nyilatkozik munkáltatójának, kifizetőjének arról,
            hogy jogosult a 30 év alatti anyák kedvezményére.
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            <b>A 2.</b> pontban kell jelölni a jogosultság jogcímét.
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            <b>Az a) pontban</b> kell feltüntetni a családi kedvezmény
            igénybevételére jogosító, 2022. december 31. után született vér
            szerinti <b>gyermek</b> nevét és adóazonosító jelét.
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            <b>A b) pontot kell jelölni</b> akkor, ha várandós nőként{" "}
            <b>magzatra tekintettel válik a családi kedvezményre jogosulttá.</b>{" "}
            Fel kell tüntetni továbbá a várandósság 91. napjának betöltése évét
            és hónapját.
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            A <b>c) pontban</b> kell feltüntetni a családi kedvezmény
            igénybevételére jogosító, 2022. december 31. után örökbe fogadott{" "}
            <b>gyermek</b> nevét és adóazonosító jelét.
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            <b>
              A kedvezmény érvényesítéséhez elegendő csak az egyik sor
              kitöltése, az a) - c) pont választásakor elegendő egy, a 30 év
              alatti anyák kedvezményére jogosító gyermeket feltüntetni.
            </b>
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            <b>A 3. pontban kell</b> nyilatkozni arról, hogy az adóelőleg
            megállapításakor a <b>kedvezmény teljes összegét</b> vagy csak egy
            meghatározott összeget vegyen figyelembe a munkáltató, kifizető.
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            <b>
              A 4. pontban kell jelölni, ha a kedvezmény érvényesítését egy
              adott hónaptól már nem kéri,{" "}
            </b>
            például azért, mert a gyermeke után családi kedvezményre már nem
            jogosult, vagy több munkahelye van és az egyik munkáltatójától
            kapott munkabére már meghaladja a kedvezmény havi maximális
            összegét, így a másik munkáltatójától kapott munkabérre a kedvezmény
            jogszerűen nem vehető igénybe.
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            Ekkor a „Módosított nyilatkozat” kódkockát jelölje X-szel, és
            tüntesse fel azt a hónapot, amelyben a kedvezményt már nem kívánja
            igénybe venni. Ha ezt a pontot kitölti, akkor az 1. és 2. pontot nem
            kell kitöltenie.
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            <b>
              A nyilatkozatot vissza kell vonni, ha az adóév egészére vonatkozó
              befizetési különbözet a 10 ezer forintot meghaladná.
            </b>{" "}
            Ha a fiatal anya nem tesz visszavonó nyilatkozatot, és az emiatt
            keletkező befizetési különbözet az adóévben több mint 10 ezer
            forint, a befizetési különbözet után a fiatal anya az adóévre
            vonatkozó bevallásában külön feltüntetve 12 százalék különbözeti-
            bírságot állapít meg, amelyet a személyi jövedelemadó-fizetési
            kötelezettségre vonatkozó rendelkezések szerint fizet meg.
          </Typography>
          <Box height="25px" />
          <Typography variant="body1">
            <b style={{ textDecoration: "underline" }}>II. blokk:</b> ezt a
            blokkot az Ön munkáltatójának, kifizetőjének kell kitöltenie.
          </Typography>
        </Box>
        <Box height="25px" />
      </Box>
      <OcPrivateLayoutFooter />
    </Box>
  );
};

export default UnderThirtyMothersTaxCreditInformation;
