import React from "react";
import { useTheme } from "@material-ui/styles";
import MaterialFilterIcon from "@iconify/icons-mdi/filter";
import { InlineIcon } from "@iconify/react";
import useStyles from "./oc-data-table-filter-icon.style";

export default function FilterIcon({ align, id, tableData, setTableData }) {
    const classes = useStyles({
        align,
    });
    const theme = useTheme();

    const {
        filterField,
        filterSelected,
        filterValues,
        filterShowForm,
    } = tableData;

    function handleShowForm(field) {
        let newFormState = filterShowForm ? false : true;
        setTableData({
            filterShowForm: newFormState,
            filterField: field,
        });
    }

    if (
        filterField === id &&
        filterSelected.length !== filterValues.length &&
        filterShowForm === false &&
        filterSelected.length !== 0
    )
        return (
            <div className={classes.root}>
                <InlineIcon
                    icon={MaterialFilterIcon}
                    className={classes.icon}
                    color={theme.palette.primary.main}
                    onClick={() => handleShowForm(id)}
                />
            </div>
        );

    if (filterSelected.length === filterValues.length || filterField === id)
        return (
            <div className={classes.root}>
                <InlineIcon
                    id="oc-data-table-filter-icon-leave"
                    icon={MaterialFilterIcon}
                    className={classes.icon}
                    onClick={() => handleShowForm(id)}
                />
            </div>
        );

    if (
        filterField !== id &&
        filterSelected.length === 0 &&
        filterShowForm === false
    )
        return (
            <div className={classes.root}>
                <InlineIcon
                    id="oc-data-table-filter-icon"
                    icon={MaterialFilterIcon}
                    className={classes.icon}
                    onClick={() => handleShowForm(id)}
                />
            </div>
        );

    return <div className={classes.spacer}></div>;
}
