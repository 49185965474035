import gql from "graphql-tag";

export const UPDATE_PROPERTY = gql`
  mutation updateProperty($resource: String!, $key: String!, $value: String!) {
    updateProperty(resource: $resource, key: $key, value: $value) {
      resource
      key
      value
    }
  }
`;
