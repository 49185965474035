import React from "react";
import Box from "@material-ui/core/Box";
import MyHrChangePasswordForm from "my-hr/components/my-hr-change-password-form";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyHrChangeTemporaryPasswordDialog({ onLogout, open }) {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            BackdropProps={{
                style: {
                    width: "100%",
                    backgroundColor: "rgba(0,0,0,0.7",
                    backgroundImage: "url(/img/loginBg.jpg)",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundBlendMode: "darken",
                },
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
            >
                <Box width="480px" padding="36px 24px">
                    <Typography variant="h6" align="center">
                        {t("User.changePwd")}
                    </Typography>
                    <Box
                        padding="32px 0px 8px 0px"
                        style={{ color: "#5e6366" }}
                    >
                        <Typography variant="body1">
                            {t("CHANGE_TEMPORARY_PASSWORD_TEXT")}
                        </Typography>
                        <Typography variant="body1">
                            {t("CHANGE_TEMPORARY_PASSWORD_TAX_NUMBER")}
                        </Typography>
                        <Box height="20px" />
                    </Box>
                    <MyHrChangePasswordForm onLogout={onLogout} />
                </Box>
            </Box>
        </Dialog>
    );
}
