import React, { Suspense } from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import "oc/configs/i18n";
import owTheme from "oc/configs/ow-theme";
import { HashRouter } from "react-router-dom";
import ApolloContainer from "oc/components/oc-apollo-container";
import App from "./App";
import Loading from "oc/components/oc-loading";
import OcSnackbar from "oc/components/oc-snackbar";
import { RecoilRoot as GlobalState } from "recoil";
import { SnackbarProvider } from "notistack";

function AppRoot() {
  return (
    <GlobalState>
      <ApolloContainer>
        <Suspense fallback={<Loading show={true} />}>
          <ThemeProvider theme={owTheme}>
            <HashRouter>
              <SnackbarProvider>
                <OcSnackbar />
                <App />
              </SnackbarProvider>
            </HashRouter>
          </ThemeProvider>
        </Suspense>
      </ApolloContainer>
    </GlobalState>
  );
}

export default AppRoot;
