const nationalities = require("@palpinter/i18n-nationality");
nationalities.registerLocale(
  require("@palpinter/i18n-nationality/langs/en.json")
);
nationalities.registerLocale(
  require("@palpinter/i18n-nationality/langs/hu.json")
);

export default function getNationality(locale) {
  const list = Object.keys(nationalities.getNames(locale));
  const codedList = list.map((item) => {
    const code = nationalities.alpha2ToAlpha3(item);
    const name = nationalities.getName(code, locale);
    return { code, name };
  });
  return codedList;
}
