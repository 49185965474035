import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useStyles from "./oc-filtered-list-count.style";

export default function OcFilteredListCount({ listCount }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <OcErrorBoundary>
      <Box
        height="56px"
        className={classes.container}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          paddingRight="16px"
          fontSize="16px"
          letterSpacing="0.44px"
          color="#4d4d4d"
          textAlign="center"
        >
          {t("COUNT_OF_EMPLOYEES")}
        </Box>
        <Box fontSize="34px" letterSpacing="0.25px" className={classes.counter}>
          {listCount}
        </Box>
      </Box>
    </OcErrorBoundary>
  );
}
