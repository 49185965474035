import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useStyles from "./filtered-list-count.style";

export default function FilteredListCount({
  listCount,
  requetsCount,
  type = "rights",
  allUsersCount = 0,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <OcErrorBoundary>
      <Box className={classes.container}>
        <Box className={classes.lineAlignment}>
          <Box width="50%" display="flex" justifyContent="end">
            <Box className={classes.titles}>
              {t("TOTAL_COUNT_OF_EMPLOYEES")}
            </Box>
          </Box>
          <Box width="50%" display="flex" justifyContent="start">
            <Box className={classes.employeeCounter}>{allUsersCount}</Box>
          </Box>
        </Box>
        {type === "filter" && (
          <Box className={classes.lineAlignment}>
            <Box width="50%" display="flex" justifyContent="end">
              <Box className={classes.titles}>{t("COUNT_OF_REQUESTS")}</Box>
            </Box>
            <Box width="50%" display="flex" justifyContent="start">
              <Box className={classes.requestsCounter}>{requetsCount}</Box>
            </Box>
          </Box>
        )}
      </Box>
    </OcErrorBoundary>
  );
}
