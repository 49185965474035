import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import alertCircleOutline from "@iconify/icons-mdi/alert-circle-outline";
import { InlineIcon } from "@iconify/react";

const TaxCreditApplicationErrorBox = ({ error }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          background: "#FFFFFF",
          border: "2px solid red",
          padding: "16px",
        }}
      >
        <InlineIcon icon={alertCircleOutline} width="100px" color="#CC0033" />
        <Box width="32px" />
        <Box>
          <Typography variant="h4" style={{ color: "#CC0033" }}>
            {t("TAX_APPLICATION_SAVE_FAILED")}
          </Typography>
          <Box height="16px" />
          {error.map((errorMessage, index) => (
            <Typography key={index} variant="body1">
              {errorMessage}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box height="16px" />
    </Box>
  );
};

export default TaxCreditApplicationErrorBox;
