import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const taxDeclarationFamily = atom({
  key: "taxDeclarationFamily",
  default: {
    dateOfDeclaration: new Date(),
    isModifiedDeclaration: false,
    applyAlone: true,
    applyTogether: false,
    applyOnHungary: false,
    noRequestRelief: false,
    taxReliefAmount: 0,
    taxReliefNumberOfChild: 0,
    spouseName: "",
    spouseTaxNumber: "",
    spouseEmployerName: "",
    spouseEmployerTaxNumber: "",
    dependents: [],
  },
  effects_UNSTABLE: [persistAtom],
});
