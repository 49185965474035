import gql from "graphql-tag";

export const SEND_TEMPORARY_PASSWORD = gql`
  mutation sendTemporaryPassword($email: String!, $locale: String!) {
    sendTemporaryPassword(email: $email, locale: $locale) {
      status
      statusMessage
    }
  }
`;
