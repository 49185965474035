import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_MY_TIME_MESSAGES_LIST } from "my-time/graphql/queries";
import { MY_TIME_MESSAGES_SUBSCRIPTION } from "my-time/graphql/subscriptions";

const useMyTimeMessagesList = ({ request }) => {
  const { data, loading, error, refetch } = useQuery(
    GET_MY_TIME_MESSAGES_LIST,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        request,
      },
    }
  );

  useSubscription(MY_TIME_MESSAGES_SUBSCRIPTION, {
    onData: () => {
      refetch();
    },
  });

  return {
    data: data?.getMyTimeMessagesList || {},
    loading: loading && !data,
    error,
  };
};

export default useMyTimeMessagesList;
