import * as Yup from "yup";
import { CREATE_CHANGE_REQUEST } from "graphql/mutations";

export const emailsOtherDialogDef = {
  type: "emails",
  dialogLabel: "EDIT_EMAILS_OTHER",
  mutation: CREATE_CHANGE_REQUEST,
  items: [
    {
      label: "Common.validity",
      name: "validFrom",
      type: "datePicker",
      validation: Yup.date().required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "EMAIL_OTHER",
      name: "otherEmail",
      dataField: "other",
      type: "text",
      validation: Yup.string().matches(
        /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
        "ERROR_MESSAGE_WRONG_EMAIL_FORMAT"
      ),
    },
    {
      label: "Leave.message",
      name: "initialMessage",
      type: "textarea",
      validation: Yup.string(),
    },
  ],
};
