import gql from "graphql-tag";

export const CREATE_ADDRESS_CHANGE_REQUEST = gql`
  mutation createAddressChangeRequest(
    $locale: String!
    $typeGroupId: String
    $typeGroup: String
    $values: [AddressValues]
    $validFrom: Date!
    $initialMessage: String
  ) {
    createAddressChangeRequest(
      locale: $locale
      typeGroupId: $typeGroupId
      typeGroup: $typeGroup
      values: $values
      validFrom: $validFrom
      initialMessage: $initialMessage
    )
  }
`;
