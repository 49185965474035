import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import useClientState from "hooks/use-oc-client-state";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useStyles from "./oc-private-layout-app-bar-contract-select.style";
import useCurrentUserState from "hooks/use-current-user-state";

export default function OcPrivateLayoutAppBarContractSelect() {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    let {
        contractCode,
        employeeCode,
        setData: setCurrentUser,
    } = useCurrentUserState();
    const { data: contractsData } = useContractsByEmployee({
        employee: employeeCode,
    });

    const [, setActiveMenu] = useClientState("activeMenu", "mainMenu");

    const { setData: setCurrentUserState } = useCurrentUserState();

    React.useEffect(() => {
        if (contractsData && contractsData.length >= 1 && !contractCode) {
            let selectedId = contractsData[0]._id;
            if (contractCode !== selectedId) {
                setCurrentUser({ contractCode: selectedId });
            }
            if (selectedId && (!contractCode || contractCode === "")) {
                setCurrentUser({ contractCode: selectedId });
                setCurrentUserState({ contractCode: selectedId });
            }
        }
    });

    const handleChange = event => {
        setCurrentUser({ contractCode: event.target.value });
        setActiveMenu("mainMenu");
        history.push("/");
    };

    if (contractsData.length === 0)
        return (
            <Box style={{ paddingRight: "16px", color: "#CC0033" }}>
                {t("CONTRACT_DATA_NOT_AVAIABLE")}
            </Box>
        );

    if (contractsData && contractsData?.length < 2) return <Box></Box>;

    return (
        <Box marginRight="16px">
            <FormControl
                variant="outlined"
                size="small"
                className={classes.formControl}
            >
                <InputLabel id="demo-simple-select-outlined-label">
                    {t("GET_CONTRACT")}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    defaultValue=""
                    value={contractCode}
                    onChange={handleChange}
                    label="Szerződés"
                    inputProps={{
                        classes: {
                            root: classes.selectInput,
                        },
                    }}
                >
                    {contractsData?.map((item, index) => {
                        let label = item?.jobClass?.name;
                        if (
                            item?.employmentType?.code === "5" ||
                            item?.employmentType?.code === "6"
                        ) {
                            label = t("CONTRACT_OF_SERVICES");
                        }
                        return (
                            <MenuItem
                                key={`${item?.code} ${index}`}
                                value={item.code}
                            >
                                {label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}
