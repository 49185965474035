import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { REFRESH_TOKEN } from "graphql/mutations";
import { refreshTokenTimestampState, sessionPeriodState } from "states";
import { useRecoilState } from "recoil";
import useCurrentUserState from "hooks/use-current-user-state";
import { refreshTokenState } from "states";

export default function useOcRefreshToken({ isLoggedIn }) {
  let {
    accessTokenPeriod,
    accessToken,
    setData: setCurrentUser,
  } = useCurrentUserState();
  const [sessionPeriod, setSessionPeriod] = useRecoilState(sessionPeriodState);
  const [refreshTokenError, setRefreshTokenError] =
    useRecoilState(refreshTokenState);

  const [refreshTokenTimestamp, setRefreshTokenTimestamp] = useRecoilState(
    refreshTokenTimestampState
  );

  const [refreshToken] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: "network-only",
    variables: { accessToken },
    onCompleted: (data) => {
      const result = data?.refreshToken;
      let now = new Date().toUTCString();
      setRefreshTokenTimestamp(now);
      if (refreshTokenError) {
        setRefreshTokenError(false);
      }

      if (
        result.accessTokenPeriod !== accessTokenPeriod ||
        result.accessToken !== accessToken
      ) {
        setCurrentUser({
          accessTokenPeriod: result.accessTokenPeriod,
          accessToken: result.accessToken,
        });
      }
      if (result.sessionPeriod !== sessionPeriod) {
        setSessionPeriod(result.sessionPeriod);
      }
    },
    onError: (err) => {
      console.log({ err });
    },
  });

  const refreshInTime = ({
    accessToken,
    accessTokenPeriod,
    refreshTokenTimestamp,
  }) => {
    if (accessToken && accessTokenPeriod) {
      let now = new Date();
      let lastRefresh = new Date(refreshTokenTimestamp);
      let difference = now - lastRefresh;
      let refreshTokenPeriod = 1000 * 60 * accessTokenPeriod - 15000;
      if (difference > refreshTokenPeriod) {
        refreshToken();
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (refreshTokenError) {
        refreshToken();
      }

      let timer = setInterval(() => {
        refreshInTime({
          accessToken,
          accessTokenPeriod,
          refreshTokenTimestamp,
        });
      }, 1000 * 10);
      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line
  }, [
    refreshTokenError,
    isLoggedIn,
    refreshTokenTimestamp,
    accessToken,
    accessTokenPeriod,
  ]);
}
