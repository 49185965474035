import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  messageItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 24,
  },
  messageItemInnerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  messageItem: {
    backgroundColor: "white",
    color: "#5e6366",
    fontFamily: "Mulish",
    padding: "10px 15px 10px 15px",
    borderRadius: 4,
    border: `1px solid ${theme.palette.gray40.main}`,
  },
  avatar: {
    width: "40px",
    height: "40px",
    marginTop: 12,
    marginRight: 10,
  },
  smallText: {
    fontFamily: "Mulish",
    fontSize: 10,
    paddingLeft: 4,
    paddingRight: 2,
    color: theme.palette.gray60.main,
    textTransform: "capitalize",
  },
}));

export default useStyles;
