import React from "react";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function LeaveCardTitle({ label, loading }) {
  return (
    <Box>
      <Box
        paddingTop="34px"
        paddingBottom="24px"
        textAlign="center"
        fontSize="34px"
        letterSpacing="0.25px"
      >
        {label}
      </Box>

      <Box padding="0px 24px">
        {loading ? (
          <LinearProgress color="primary" />
        ) : (
          <Box
            style={{
              height: "3px",
              maxHeight: "3px",
              borderBottom: "1px solid #C6CACC",
            }}
          />
        )}
      </Box>
    </Box>
  );
}
