import gql from "graphql-tag";

export const LEAVE_REQUESTS_BY_CONTRACT_SUBSCRIPTION = gql`
  subscription leaveRequestsByContract(
    $contract: String!
    $state: String
    $year: String
  ) {
    leaveRequestsByContract(contract: $contract, state: $state, year: $year) {
      _id
      contract
      startDate
      endDate
      workDays
      effectiveWorkDays
      leaveType
      leaveTypeName
      type
      state
      approvedAt
      approvedBy
      rejectedAt
      rejectedBy
      createdAt
      createdBy
      messages {
        createdAt
        createdBy
        message
        employeeName
        employeeAvatar
      }
    }
  }
`;
