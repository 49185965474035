import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles((theme) => ({
  topBorder: {
    borderTop: `2px solid ${theme.palette.gray40.main}`,
  },
  backgroundColor: {
    backgroundColor: `${theme.palette.gray20.main}`,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#212121",
    color: "#FFFFFF",
    maxWidth: "400px",
    height: "auto",
    fontSize: "16px",
  }
}))(Tooltip);

export default useStyles;
