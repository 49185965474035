import gql from "graphql-tag";

export const CREATE_TAX_DECLARATION_ELIGIBLE_COSTS = gql`
  mutation createTaxDeclarationEligibleCosts(
    $dateOfDeclaration: Date!
    $independentIncome10Percent: Boolean!
    $independentIncomeAmount: Float!
    $independentIncomeCost10Percent: Boolean!
    $independentIncomeCostAmount: Float!
    $notIndependentIncomeCostAmount: Float!
    $woCertificateIndependentCost: Boolean!
    $woCertificateNotIndependentCost: Boolean!
    $appartamentRentalCost: Boolean!
    $postingStatementCost: Boolean!
    $foreignServiceCost: Boolean!
    $validUntilWithdrawn: Boolean!
  ) {
    createTaxDeclarationEligibleCosts(
      dateOfDeclaration: $dateOfDeclaration
      independentIncome10Percent: $independentIncome10Percent
      independentIncomeAmount: $independentIncomeAmount
      independentIncomeCost10Percent: $independentIncomeCost10Percent
      independentIncomeCostAmount: $independentIncomeCostAmount
      notIndependentIncomeCostAmount: $notIndependentIncomeCostAmount
      woCertificateIndependentCost: $woCertificateIndependentCost
      woCertificateNotIndependentCost: $woCertificateNotIndependentCost
      appartamentRentalCost: $appartamentRentalCost
      postingStatementCost: $postingStatementCost
      foreignServiceCost: $foreignServiceCost
      validUntilWithdrawn: $validUntilWithdrawn
    ) {
      result
      messages
      tableId
    }
  }
`;
