import gql from "graphql-tag";

export const GET_SHORT_CONTRACT_LIST_WO_USERS = gql`
  query shortContractListWoUsers(
    $page: Int!
    $pageSize: Int!
    $searchField: String
    $searchText: String
    $contracts: [String]
    $product: String!
    $role: String!
  ) {
    shortContractListWoUsers(
      page: $page
      pageSize: $pageSize
      searchField: $searchField
      searchText: $searchText
      contracts: $contracts
      product: $product
      role: $role
    ) {
      page
      pageSize
      total
      totalPages
      availableList {
        _id
        code
        name
      }
      selectedList {
        _id
        code
        name
      }
    }
  }
`;
