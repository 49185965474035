import moment from "moment";
import { countHours, timeConvert } from "my-time/utils";

export const countDailyHours = ({ date, list }) => {
  const dailyList = list.filter(
    (item) =>
      moment(item.date).format("YYYY-MM-DD") ===
      moment(date).format("YYYY-MM-DD")
  );
  let workTime = 0;
  let overtime = 0;
  let voluntaryOvertime = 0;

  dailyList.forEach((item) => {
    if (
      item.myTimeType === "STAND_BY" ||
      item.myTimeType === "LEAVE" ||
      item.myTimeType === "ABSENCE" ||
      item.myTimeType === "ON_CALL"
    ) {
      return;
    } else {
      const myTimeType = item.myTimeType;
      switch (myTimeType) {
        case "WORKING_TIME":
          workTime = workTime + countHours(item);
          break;
        case "HOMEOFFICE":
          workTime = workTime + countHours(item);
          break;
        case "OVERTIME":
          if (item.timeDataTypeCode === "Tö") {
            voluntaryOvertime = voluntaryOvertime + countHours(item);
          } else if (item.state === "approved" || !item.state) {
            overtime = overtime + countHours(item);
          }
          break;
        default:
          break;
      }
    }
  });

  const result = {
    workTime: timeConvert(workTime),
    overtime: timeConvert(overtime),
    voluntaryOvertime: timeConvert(voluntaryOvertime),
    rawWorkTime: workTime,
    rawOvertime: overtime,
    rawVoluntaryOvertime: voluntaryOvertime,
  };
  return result;
};
