import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import emailOutlineIcon from "@iconify/icons-mdi/email-outline";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import PublicLayoutLink from "my-hr/components/my-hr-public-layout-link";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyHrForgotPasswordEmailSentDialog({ smallScreen }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog
      open={true}
      scroll={"body"}
      fullScreen={smallScreen}
      TransitionComponent={Transition}
      BackdropProps={{
        style: {
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.7",
          backgroundImage: "url(/img/loginBg.jpg)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundBlendMode: "darken",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="inherit"
      >
        <Box width={smallScreen ? "auto" : "400px"} padding="24px">
          <Box textAlign="center" p={0} marginTop="14px">
            <InlineIcon
              icon={emailOutlineIcon}
              width="64px"
              height="64px"
              color={theme.palette.gray80.main}
            />
          </Box>
          <Box textAlign="center" marginTop="30px">
            <Typography variant="h6">{t("TITLE_EMAIL_SENT")}</Typography>
            <Box marginTop="26px" style={{ textAlign: "left" }}>
              <Typography>{t("TEXT_EMAIL_SENT")}</Typography>
            </Box>
          </Box>
          <Box textAlign="center" paddingTop="32px" paddingBottom="6px">
            <PublicLayoutLink label={t("LINK_BACK_TO_LOGIN")} path="/" />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
