import gql from "graphql-tag";

export const GET_WORKING_PLACES_ADMIN_LIST = gql`
  query workingPlacesAdminList(
    $searchField: String
    $searchText: String
    $page: Int
    $pageSize: Int
    $workingPlaces: [String]
  ) {
    workingPlacesAdminList(
      searchField: $searchField
      searchText: $searchText
      page: $page
      pageSize: $pageSize
      workingPlaces: $workingPlaces
    ) {
      availableList {
        _id
        code
        name
      }
      selectedList {
        _id
        code
        name
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
