import { useQuery } from "@apollo/client";
import { GET_CHANGE_REQUESTS_EXAMINERS_NAME } from "graphql/queries";
import usePolling from "hooks/use-polling";

const useChangeRequestsExaminersName = ({ locale, contract }) => {
  const { data, loading, error } = useQuery(
    GET_CHANGE_REQUESTS_EXAMINERS_NAME,
    {
      skip: !contract,
      fetchPolicy: "cache-and-network",
      pollInterval: usePolling(1000 * 60),
      variables: { locale, contract },
    }
  );

  const result = data?.getChangeRequestsExaminersName || [];

  return {
    data: result,
    loading,
    error,
  };
};

export default useChangeRequestsExaminersName;
