import gql from "graphql-tag";

export const GET_MY_TIME_REQUESTS_LIST = gql`
  query getMyTimeRequestsList(
    $contractCode: String!
    $startDate: Date!
    $endDate: Date!
    $requested: Boolean!
    $page: Int!
    $pageSize: Int!
  ) {
    getMyTimeRequestsList(
      contractCode: $contractCode
      startDate: $startDate
      endDate: $endDate
      requested: $requested
      page: $page
      pageSize: $pageSize
    ) {
      _id
      myTimeType
      startTime
      endTime
      hours
      state
      timeDataTypeCode
      timeDataTypeName
      createdAt
      createdBy
    }
  }
`;
