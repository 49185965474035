import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "8px 0px",
  },
  label: {
    color: theme.palette.gray80.main,
    fontSize: "12px",
    leineHeight: "24px",
    letterSpacing: "0.4px",
    minWidth: "220px",
    textAlign: "right",
    paddingRight: "16px",
  },
  value: {
    color: theme.palette.darkBase.main,
    fontSize: "16px",
    letterSpacing: "0.25px",
    minWidth: "324px",
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "1px",
    whiteSpace: "nowrap",
  },
  dropzoneRoot: {
    minHeight: "unset !important",
    height: 152,
    borderRadius: 8,
    color: theme.palette.gray60.main,
  },
  muiSvgIcon: {
    marginTop: 24,
    color: "#919699 !important",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
    width: "547px",
  },
  text: {
    fontSize: 16,
    marginTop: "17px !important",
    color: theme.palette.gray60.main,
    "&:after": {
      content: "'ide'",
      color: "red",
      transform: "translateX(-100%)",
      position: "absolute",
    },
  },
  tooLongValueTooltip: {
    fontSize: "14px",
    lineHeight: "20px",
    padding: "2px 8px",
  },
  input: {
    width: "360px",
    height: "4px",
  },
  errorText: {
    color: theme.palette.primary.main,
    margin: "0",
    fontSize: "12px",
    marginTop: "3px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    paddingLeft: "14px",
    marginBottom: "-15px",
  },
  labelTitle: {
    letterSpacing: "0.15px",
    padding: "28px 10px 10px 16px",
    color: theme.palette.darkBase.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
}));

export default useStyles;
