import gql from "graphql-tag";

export const INVALIDATE_ADDRESS = gql`
  mutation invalidateAddress(
    $type: String!
    $validFrom: Date!
    $message: String
    $locale: String!
  ) {
    invalidateAddress(
      type: $type
      validFrom: $validFrom
      message: $message
      locale: $locale
    )
  }
`;
