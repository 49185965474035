import React from "react";
import { Box } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";

const MyTimeCalendarTooltip = ({ event }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const startTime = moment(event.start).format("HH:mm");
  const endTime = moment(event.end).format("HH:mm");

  const getColor = (label) => {
    switch (label) {
      case "requested":
        return "#F3AA18";
      case "approved":
        return "#2FA84F";
      case "rejected":
        return theme.palette.primary.main;
      default:
        return "";
    }
  };

  return (
    <Box
      style={{
        width: "226px",
        textAlign: "center",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
      }}
    >
      <Box paddingBottom={"4px"}>{event.timeDataTypeName}</Box>
      <Box paddingBottom={"4px"}>{t(event.myTimeType)}</Box>
      <Box
        style={{
          height: "1px",
          backgroundColor: "#A6ADAF",
          marginBottom: "4px",
        }}
      />
      <Box>{`${startTime} - ${endTime}`}</Box>
      {event.state && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingTop="4px"
        >
          <Box width="50%" textAlign="right" paddingRight="2px">
            {t("STATE")}
          </Box>
          <Box width="50%" padding="0px 6px">
            <Box
              style={{
                border: `1px solid ${getColor(event.state)}`,
                borderRadius: 12,
                padding: "2px 6px",
              }}
            >
              {t(`Leave.${event.state}`)}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MyTimeCalendarTooltip;
