import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "oc/components/oc-avatar";
import Radio from "@material-ui/core/Radio";
import { InlineIcon } from "@iconify/react";
import MessageOutlineIcon from "@iconify/icons-mdi/message-outline";
import MessageTextIcon from "@iconify/icons-mdi/message-text";
import plusIcon from "@iconify/icons-mdi/plus";
import pencilIcon from "@iconify/icons-mdi/pencil";
import arrowIcon from "@iconify/icons-mdi/arrow-right";
import CheckBold from "@iconify/icons-mdi/check-bold";
import CloseThick from "@iconify/icons-mdi/close-thick";
import State from "oc/components/oc-data-table-body-cell-state";
import { useTranslation } from "react-i18next";
import Currency from "oc/components/oc-currency";
import MyLeaveBarDashboard from "my-leave/components/my-leave-bar-dashboard/my-leave-bar-dashboard";
import AvailableRoles from "my-leave/components/my-leave-available-roles/my-leave-available-roles";
import moment from "moment";
import useMyTimeMessagesList from "my-time/hooks/use-my-time-messages-list";
import getFullname from "oc/utils/get-fullname";
import ApplicationState from "my-hr/components/my-hr-application-state";
import { CustomTooltip } from "my-hr/components/my-hr-applications-data-panel/components/dependents-collapse/dependents-collapse.style";
import MyTimeDateHeaderTooltip from "my-time/components/my-time-date-header-tooltip/my-time-date-header-tooltip";

const MyTimeMessagesIndicator = ({ row }) => {
  const { data: messages } = useMyTimeMessagesList({ request: row._id });
  return (
    <InlineIcon
      width="20px"
      height="20px"
      icon={messages?.length > 0 ? MessageTextIcon : MessageOutlineIcon}
    />
  );
};

export default function BodyCell({
  align,
  row,
  id,
  type,
  onSelectRow,
  selectedRowValue,
  inlineEdit,
  canCopy,
  rowSpanData,
  rowSpanField,
  dayFormat,
  colorize = false,
  columnItem,
  isNotExaminer,
}) {
  let rowSpan = 1;

  if (rowSpanData?.length > 1) {
    if (rowSpanData[0]?.[rowSpanField] === row?.[rowSpanField]) {
      rowSpan = rowSpanData?.length;
    } else {
      rowSpan = 0;
    }
  }

  let color = "";
  let dayColor = "";
  if (colorize) {
    if (row?.state === "requested") {
      color = "#F3AA18";
    }
    if (row?.state === "approved") {
      color = "#2FA84F";
    }
    if (row.dayType === "REST_DAY") {
      dayColor = "#1EA5FC";
    }
    if (row.dayType === "PAID_HOLLIDAY") {
      dayColor = "#cc0033";
    }
  }

  const { t, i18n } = useTranslation();
  function handleRadioSelected(row) {
    onSelectRow({ key: "code", value: row.code });
  }

  function handleLeaveAction(action) {
    onSelectRow({ id: row._id, action: action });
  }

  function handleCafeteriaAction(action) {
    onSelectRow({ item: row, action: action });
  }

  if (id === "examinerFullname")
    return (
      <TableCell align={align} style={{ padding: "0px 8px" }}>
        <Box>{getFullname(row)}</Box>
      </TableCell>
    );

  if (
    (id === "requestCount" && type === "myTimeBadges") ||
    (id === "recordCount" && type === "myTimeBadges")
  ) {
    return (
      <TableCell align={align} style={{ padding: "0px 8px" }}>
        {row[id] > 0 && (
          <Box
            display="inline-block"
            width="22px"
            height="22px"
            style={{
              backgroundColor: "#cc0033",
              color: "white",
              borderRadius: "2px",
              textAlign: "center",
              fontSize: "12px",
              letterSpacing: "0.4px",
              paddingTop: "1px",
              marginRight: "8px",
            }}
          >
            {row[id]}
          </Box>
        )}
      </TableCell>
    );
  }

  if (id === "role")
    return (
      <TableCell align={align} style={{ padding: "0px 8px" }}>
        <Box>
          {t(`Role.${row.role}`)}
        </Box>
      </TableCell>
    );

  if (type === "pipedName" || (id === "name" && align !== "left")) {
    const parsePipedName = (pipedName, i18n) => {
      const nameArray = pipedName.split("|");
      const huName =
        `${nameArray[0]} ${nameArray[1]} ${nameArray[2]} ${nameArray[3]} ${nameArray[4]}`.trim();
      const enName =
        `${nameArray[2]} ${nameArray[3]} ${nameArray[1]} ${nameArray[4]}`.trim();
      return i18n.language === "hu" ? huName : enName;
    };
    return <TableCell align={align}>{parsePipedName(row[id], i18n)}</TableCell>;
  }

  if (type === "availableRoles")
    return <AvailableRoles align={align} row={row} />;

  if (type === "baseLeaveBar")
    return (
      <TableCell align={align || "center"} style={{ padding: "0px 8px" }}>
        <Box>
          <MyLeaveBarDashboard id="base-leave-days-bar" row={row} />
        </Box>
      </TableCell>
    );
  if (type === "avatar")
    return (
      <TableCell align={align || "center"} style={{ padding: "0px 8px" }}>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Avatar
            employee={row.employee ? row.employee : row.code}
            style={{
              width: "36px",
              height: "36px",
              marginLeft: "-8px",
            }}
          />
        </Box>
      </TableCell>
    );
  if (type === "checkbox")
    return (
      <TableCell align={align || "center"} style={{ padding: "0px 8px" }}>
        <Box>
          <Checkbox
            checked={row.active}
            inputProps={{
              "aria-labelledby": row.fullname,
            }}
            style={{ padding: "5px" }}
          />
        </Box>
      </TableCell>
    );
  if (type === "radio")
    return (
      <TableCell align={align || "center"} style={{ padding: "0px 8px" }}>
        <Radio
          id={row?.code}
          checked={row.code === selectedRowValue}
          onChange={() => handleRadioSelected(row)}
          value="a"
          name="radio-button-demo"
          size="small"
          inputProps={{ "aria-label": "A" }}
          style={{ padding: "5px", color: "#5E6366" }}
        />
      </TableCell>
    );

  if (type === "date") {
    let dateFormat = new Date(row[id]).toLocaleDateString();
    return <TableCell align={align}>{dateFormat}</TableCell>;
  }

  if (type === "dateTime") {
    let dateFormat =
      new Date(row[id]).toLocaleDateString() +
      " " +
      new Date(row[id]).toLocaleTimeString();
    return <TableCell align={align}>{dateFormat}</TableCell>;
  }

  if (type === "dateWithdayName") {
    if (rowSpan === 0) return null;
    let date = new Date(row[id]);
    let dayName = moment(date).locale(i18n.language).format("dd");
    dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
    let dateFormat = i18n.language === "en" ? moment(date).format("MM.DD.YYYY. ") + dayName :
      moment(date).format("YYYY.MM.DD. ") + dayName;

    return (
      <TableCell align={align} rowSpan={rowSpan} style={{ color: dayColor }}>
        {dateFormat}
      </TableCell>
    );
  }
  if (type === "myTimeType") {
    return <TableCell align={align}>{t(row.myTimeType)}</TableCell>;
  }

  if (type === "dayType") {
    if (rowSpan === 0) return null;
    return (
      <TableCell align={align} style={{ color: dayColor }} rowSpan={rowSpan}>
        {t(row.dayType)}
      </TableCell>
    );
  }

  if (type === "myTimeDate") {
    if (rowSpan === 0) return null;
    let date = new Date(row[id]);
    let dayName = moment(date).locale(i18n.language).format("dd");
    dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
    let dateFormat =
      moment(date).locale(i18n.language).format("YYYY.MM.DD. ") + dayName;

    const { workTime, overtime, voluntaryOvertime } = row?.countTimeData(date);

    return (
      <MyTimeDateHeaderTooltip
        workTime={workTime}
        overtime={overtime}
        voluntaryOvertime={voluntaryOvertime}
        isOffRange={false}
        date={date}
      >
        <TableCell align={align} rowSpan={rowSpan} style={{ color: dayColor }}>
          {dateFormat}
        </TableCell>
      </MyTimeDateHeaderTooltip>
    );
  }

  if (type === "myTimeType") {
    return <TableCell align={align}>{t(row.myTimeType)}</TableCell>;
  }

  if (type === "dayType") {
    if (rowSpan === 0) return null;
    return (
      <TableCell align={align} style={{ color: dayColor }} rowSpan={rowSpan}>
        {t(row.dayType)}
      </TableCell>
    );
  }

  if (type === "i18nText") {
    return <TableCell align={align}>{t(row[id])}</TableCell>;
  }

  if (type === "taxDeclarationType") {
    if (row?.isModifiedDeclaration === 1) {
      return (
        <TableCell align={align}>{`${t(row[id])} (${t(
          "MODIFIED_DECLARATION"
        )})`}</TableCell>
      );
    }
    return <TableCell align={align}>{t(row[id])}</TableCell>;
  }

  if (id === "effectiveWorkDays" || id === "fulfilledLeave") {
    return <TableCell align={align}>{row[id].toLocaleString()}</TableCell>;
  }

  if (type === "period") {
    let startTime =
      new Date(row.startTime).toLocaleDateString() +
      " " +
      moment(row.startTime).format("HH:mm");

    let endTime =
      new Date(row.endTime).toLocaleDateString() +
      " " +
      moment(row.endTime).format("HH:mm");

    const period = row.startTime ? `${startTime} - ${endTime}` : "";

    return (
      <TableCell align={align} style={{ color: color }}>
        {period}
      </TableCell>
    );
  }

  if (type === "messageIndicator") {
    return (
      <TableCell style={{ textAlign: align }}>
        <InlineIcon
          width="20px"
          height="20px"
          icon={
            row?.messages?.length > 0 ? MessageTextIcon : MessageOutlineIcon
          }
        />
      </TableCell>
    );
  }

  if (type === "myTimeMessageIndicator") {
    return (
      <TableCell style={{ textAlign: align }}>
        <MyTimeMessagesIndicator row={row} />
      </TableCell>
    );
  }

  if (type === "state") {
    return (
      <TableCell align={align}>
        <State label={t(row[id])} state={row.state} />
      </TableCell>
    );
  }

  if (type === "leaveActions") {
    return (
      <TableCell align="center">
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="36px"
            height="36px"
            style={{
              backgroundColor: "#2FA84F",
              borderRadius: "4px",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => handleLeaveAction("accepted")}
          >
            <InlineIcon width="20px" height="20px" icon={CheckBold} />
          </Box>
          <Box width="4px" />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="36px"
            height="36px"
            style={{
              backgroundColor: "#cc0033",
              borderRadius: "4px",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => handleLeaveAction("rejected")}
          >
            <InlineIcon width="20px" height="20px" icon={CloseThick} />
          </Box>
        </Box>
      </TableCell>
    );
  }

  if (type === "nameAndJobClass") {
    return (
      <TableCell style={{ padding: "8px 8px" }}>
        <Box style={{ fontSize: "16px", letterSpacing: "0.44px" }}>
          {row.name}
        </Box>
        <Box
          style={{
            fontSize: "14px",
            letterSpacing: "0.25px",
            color: "#919699",
            paddingLeft: "16px",
          }}
        >
          {row.jobClass}
        </Box>
      </TableCell>
    );
  }

  if (type === "periodAndWorkDays") {
    return (
      <TableCell style={{ padding: "8px 8px" }}>
        <Box style={{ fontSize: "16px", letterSpacing: "0.44px" }}>
          {new Date(row.startDate).toLocaleDateString()} -{" "}
          {new Date(row.endDate).toLocaleDateString()}
        </Box>
        <Box
          style={{
            fontSize: "14px",
            letterSpacing: "0.25px",
            color: "#919699",
            paddingLeft: "16px",
          }}
        >
          {row.workDays} {t("WORKDAYS")}
        </Box>
      </TableCell>
    );
  }

  if (type === "leaveBadges") {
    let urgent = row?.urgentRequestedLeavesCount;
    let notUrgent = row.requestedLeavesCount - row.urgentRequestedLeavesCount;
    return (
      <TableCell align="center">
        {urgent > 0 && (
          <Box
            display="inline-block"
            width="22px"
            height="22px"
            style={{
              backgroundColor: "#cc0033",
              color: "white",
              borderRadius: "2px",
              textAlign: "center",
              fontSize: "12px",
              letterSpacing: "0.4px",
              paddingTop: "1px",
              marginRight: "8px",
            }}
          >
            {urgent}
          </Box>
        )}
        {notUrgent > 0 && (
          <Box
            display="inline-block"
            width="22px"
            height="22px"
            style={{
              backgroundColor: "#F3AA18",
              color: "white",
              borderRadius: "2px",
              textAlign: "center",
              fontSize: "12px",
              letterSpacing: "0.4px",
              paddingTop: "1px",
              marginRight: "8px",
            }}
          >
            {notUrgent}
          </Box>
        )}
      </TableCell>
    );
  }

  if (type === "myHRBadges") {
    let requestCount = row?.requestCount;
    return (
      <TableCell align="center">
        {requestCount > 0 && (
          <Box
            display="inline-block"
            width="22px"
            height="22px"
            style={{
              backgroundColor: "#cc0033",
              color: "white",
              borderRadius: "2px",
              textAlign: "center",
              fontSize: "12px",
              letterSpacing: "0.4px",
              paddingTop: "1px",
              marginRight: "8px",
            }}
          >
            {requestCount}
          </Box>
        )}
      </TableCell>
    );
  }

  if (type === "importError") {
    let emailAddress = `${row.errorPayload?.existedEmail} (${row.errorPayload?.existedEmployee})`;
    return (
      <TableCell align={align}>
        {row.errorPayload ? (
          <span>
            {t(row[id])} {emailAddress}
          </span>
        ) : (
          <span>{t(row[id])}</span>
        )}
      </TableCell>
    );
  }

  if (type === "importSSHRError") {
    let emailAddress = `${row.errorPayload?.existedEmail} (${row.errorPayload?.existedEmployee})`;
    return (
      <TableCell align={align}>
        {row.errorPayload ? (
          <span>
            {t(row[id])} {emailAddress}
          </span>
        ) : (
          <span>{t(row[id])}</span>
        )}
      </TableCell>
    );
  }

  if (type === "localizedName") {
    return (
      <TableCell align={align}>
        <Box>{t(`Leave.${row.type}`)}</Box>
      </TableCell>
    );
  }

  if (type === "leaveTypeName") {
    return (
      <TableCell align={align}>
        <Box>{t(`Leave.${row.leaveType}`)}</Box>
      </TableCell>
    );
  }

  if (id === "typeName") {
    return (
      <TableCell align={align}>
        <Box>{t(`Leave.${row.typeName}`)}</Box>
      </TableCell>
    );
  }

  if (type === "currency") {
    return (
      <TableCell align={align}>
        <Currency value={t(row[id])}>{t(row[id])}</Currency>
      </TableCell>
    );
  }

  if (type === "copyButton") {
    let editable = true;
    return (
      <TableCell align={align} style={{ padding: "0px 8px" }}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={!canCopy || !row?.canCopy}
            onClick={editable ? () => handleCafeteriaAction("copy") : null}
          >
            {t("COPY")}
          </Button>
        </Box>
      </TableCell>
    );
  }

  if (type === "editButton") {
    let editable = inlineEdit;
    if (row?.menuItem?.planable === "true" && !row?.plan) {
      editable = false;
    }

    return (
      <TableCell align={align} style={{ padding: "0px 8px" }}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Box
            id={`menu-edit-${row ? row.index : "zero"}`}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{
              border: `1px solid ${editable ? "#919699" : "#C6CACC"}`,
              width: "36px",
              height: "36px",
              borderRadius: "50%",
              cursor: editable ? "pointer" : "auto",
            }}
            onClick={editable ? () => handleCafeteriaAction("edit") : null}
          >
            <InlineIcon
              width="18px"
              height="18px"
              icon={pencilIcon}
              color={editable ? "#919699" : "#C6CACC"}
            />
          </Box>
        </Box>
      </TableCell>
    );
  }

  if (type === "i18nDate") {
    const date = new Date(row[id]).toLocaleDateString();
    return <TableCell align={align}>{date}</TableCell>;
  }

  if (type === "taxState") {
    return (
      <TableCell align={align}>
        <ApplicationState label={t(row[id])} state={row.state} />
      </TableCell>
    );
  }

  if (type === "myTimeActions") {
    const buttonStyle = {
      border: "1px solid #919699",
      borderRadius: "50%",
      cursor: "pointer",
      width: "36px",
      height: "36px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: "#919699",
    };

    const handleSelectRow = ({ action, row }) => {
      const newData = { ...row };
      newData.sender = "table";
      if (row?.myTimeType === "WORKING_TIME" && action === "add") {
        newData.myTimeType = "OVERTIME";
        newData.start = "";
        newData.startTime = "";
        newData.end = "";
        newData.endTime = "";
        newData.timeDataTypeCode = "";
        newData.timeDataTypeName = "";
      }
      onSelectRow({ action, row: newData });
    };

    const date = new Date().toLocaleDateString();
    const currentMonth = date.slice(6, 8);
    const periodMonth = new Date(row?.date).toLocaleDateString().slice(6, 8);

    const AddIcon = () => {
      if (row?.icons?.add === false || isNotExaminer) return null;
      let style = { ...buttonStyle };
      if (row?.icons?.arrow || row?.icons?.edit) {
        style = { ...buttonStyle, marginRight: "8px" };
      }
      return (
        <Box
          onClick={() => handleSelectRow({ action: "add", row: row })}
          style={style}
        >
          <InlineIcon
            width="24px"
            height="24px"
            id={`add-${new Date(row?.date).getDate()}`}
            icon={plusIcon}
          />
        </Box>
      );
    };

    const ArrowIcon = () => {
      if (isNotExaminer && currentMonth === periodMonth) return null;
      if (row?.icons?.arrow === false) return null;
      return (
        <Box
          onClick={() => handleSelectRow({ action: "show", row: row })}
          style={buttonStyle}
        >
          <InlineIcon
            width="24px"
            height="24px"
            id={`show-${new Date(row?.date).getDate()}`}
            icon={arrowIcon}
          />
        </Box>
      );
    };

    const ArrowIconIsNotExaminer = () => {
      if (currentMonth !== periodMonth) return null;
      if (
        !isNotExaminer ||
        (row?.dayType === "REST_DAY" && row?.myTimeType === null)
      ) {
        return null;
      } else {
        return (
          <CustomTooltip title={t("NOT_SET_EXAMINER_INFO")} placement="top">
            <Box style={buttonStyle}>
              <InlineIcon
                width="24px"
                height="24px"
                id={`show-${new Date(row?.date).getDate()}`}
                icon={arrowIcon}
              />
            </Box>
          </CustomTooltip>
        );
      }
    };

    const EditIcon = () => {
      if (row?.icons?.edit === false || isNotExaminer) return null;
      return (
        <Box
          onClick={() => handleSelectRow({ action: "edit", row: row })}
          style={buttonStyle}
        >
          <InlineIcon
            width="24px"
            height="24px"
            id={`edit-${new Date(row?.date).getDate()}`}
            icon={pencilIcon}
          />
        </Box>
      );
    };

    return (
      <TableCell align={align}>
        <Box
          id={`add-${new Date(row?.date).getDate()}`}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          style={{ minHeight: "38px" }}
        >
          <Box display="flex" flexDirection="row">
            <AddIcon />
            <ArrowIcon />
            <ArrowIconIsNotExaminer />
            <EditIcon />
          </Box>
        </Box>
      </TableCell>
    );
  }

  if (type === "myTimeType") {
    <TableCell align={align} style={{ color: color }}>
      {t(row[id])}
    </TableCell>;
  }

  return (
    <TableCell align={align} style={{ color: color }}>
      {row[id]}
    </TableCell>
  );
}
