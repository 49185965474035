import React from "react";
import Box from "@material-ui/core/Box";
import OcInfoCard from "oc/components/oc-info-card";
import {
  nameCardDef,
  birthCardDef,
  codeCardDef,
} from "my-hr/configs/my-hr-info-card-defs";

export default function MyHrPersonalDataPanel({
  employee,
  onEdit,
  hasChangeRequest,
  editable = false,
}) {
  return (
    <>
      <Box id="tab-panel-container">
        <Box paddingBottom="16px">
          <OcInfoCard
            def={nameCardDef}
            data={employee}
            editable={editable}
            onEdit={onEdit}
            hasChangeRequest={hasChangeRequest}
          />
        </Box>
        <Box paddingBottom="16px">
          <OcInfoCard
            def={birthCardDef}
            data={employee}
            onEdit={onEdit}
            hasChangeRequest={hasChangeRequest}
            editable={editable}
          />
        </Box>
        <Box paddingBottom="16px">
          <OcInfoCard
            def={codeCardDef}
            data={employee}
            onEdit={onEdit}
            hasChangeRequest={hasChangeRequest}
            editable={editable}
          />
        </Box>
      </Box>
    </>
  );
}
