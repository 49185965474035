import * as Yup from "yup";
import { CREATE_CHANGE_REQUEST } from "graphql/mutations";

export const nameDialogDef = {
  type: "name",
  dialogLabel: "EDIT_NAME",
  mutation: CREATE_CHANGE_REQUEST,
  items: [
    {
      label: "Common.validity",
      name: "validFrom",
      type: "datePicker",
      validation: Yup.date().required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Employee.prefixName",
      name: "prefixName",
      dataField: "prefixName",
      type: "text",
      validation: Yup.string(),
    },
    {
      label: "Employee.lastName",
      name: "lastName",
      dataField: "lastName",
      type: "text",
      validation: Yup.string()
        .trim()
        .required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Employee.firstName",
      name: "firstName",
      dataField: "firstName",
      type: "text",
      validation: Yup.string()
        .trim()
        .required("ERROR_MESSAGE_FIELD_IS_REQUIRED"),
    },
    {
      label: "Employee.middleName",
      name: "middleName",
      dataField: "middleName",
      type: "text",
      validation: Yup.string(),
    },
    {
      label: "Employee.nameSuffix",
      name: "nameSuffix",
      dataField: "nameSuffix",
      type: "text",
      validation: Yup.string(),
    },
    {
      label: "Leave.message",
      name: "initialMessage",
      type: "textarea",
      validation: Yup.string(),
    },
  ],
};
