import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #C6CACC",
    borderRadius: "4px",
  },
  titles: {
    paddingRight: "16px",
    fontSize: "16px",
    letterSpacing: "0.44px",
    color: theme.palette.owGray.main,
    textAlign: "center",
  },
  allCountTitles: {
    paddingRight: "16px",
    fontSize: "16px",
    letterSpacing: "0.44px",
    color: theme.palette.gray60.main,
    textAlign: "center",
  },
  requestsCounter: {
    color: theme.palette.primary.main,
    fontSize: "34px",
    letterSpacing: "0.25px",
    paddingLeft: "4px",
  },
  employeeCounter: {
    color: theme.palette.gray80.main,
    fontSize: "34px",
    letterSpacing: "0.25px",
    paddingLeft: "4px",
  },
  allEmployeeCounter: {
    color: theme.palette.gray60.main,
    fontSize: "34px",
    letterSpacing: "0.25px",
    paddingLeft: "4px",
  },
  lineAlignment: {
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
