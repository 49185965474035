import companyIcon from "@iconify/icons-mdi/city";
import orgUnitIcon from "@iconify/icons-mdi/sitemap";
import workingPlaceIcon from "@iconify/icons-mdi/home-account";
import jobClassGroupIcon from "@iconify/icons-mdi/account-box-multiple";
import payOfficeIcon from "@iconify/icons-mdi/credit-card-marker-outline";
import employeeIcon from "@iconify/icons-mdi/account";

export default function getFilterIcon({ filterType }) {
  let icon = null;
  switch (filterType) {
    case "company":
      icon = companyIcon;
      break;
    case "orgUnit":
      icon = orgUnitIcon;
      break;
    case "workingPlace":
      icon = workingPlaceIcon;
      break;
    case "jobClassGroup":
      icon = jobClassGroupIcon;
      break;
    case "payOffice":
      icon = payOfficeIcon;
      break;
    case "contract":
      icon = employeeIcon;
      break;
    default:
      break;
  }
  return icon;
}
