import gql from "graphql-tag";

export const GET_PROPERTY = gql`
  query property($resource: String!, $key: String!) {
    property(resource: $resource, key: $key) {
      key
      resource
      resourceId
      value
    }
  }
`;
