import React, { useState } from "react";
import useStyles from "./my-time-new-message.style";
import useCurrentUserState from "hooks/use-current-user-state";
import { useTranslation } from "react-i18next";
import SendIcon from "@iconify/icons-mdi/send";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { InlineIcon } from "@iconify/react";
import { useMutation } from "@apollo/react-hooks";
import { useRecoilState } from "recoil";
import { myTimeMessageState } from "my-time/states/my-time-message";

import gql from "graphql-tag";

const CREATE_MY_TIME_MESSAGE = gql`
  mutation createMyTimeMessage(
    $request: String!
    $message: String!
    $employee: String!
  ) {
    createMyTimeMessage(
      request: $request
      message: $message
      employee: $employee
    ) {
      _id
      request
      message
      createdAt
      createdBy
    }
  }
`;

const MyTimeNewMessage = ({ loading, selectedRequest, disabled = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let { employeeCode: employee } = useCurrentUserState();

  const [message, setMessage] = useRecoilState(myTimeMessageState);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const [createMyTimeMessage] = useMutation(CREATE_MY_TIME_MESSAGE);

  const handleSubmit = () => {
    const params = {
      request: selectedRequest,
      message: message,
      employee: employee,
    };
    createMyTimeMessage({
      variables: params,
    });
    setMessage("");
  };

  return (
    <TextField
      id="new-message-box-input"
      multiline
      fullWidth
      variant="outlined"
      placeholder={t("Leave.writeMessage")}
      value={message}
      onChange={handleChange}
      onFocus={() => setError(false)}
      error={error}
      helperText={error ? `${t("EMPTY_MESSAGE")}` : `${message.length}/255`}
      style={{
        height: "auto",
      }}
      disabled={disabled}
      inputProps={{ maxLength: 255 }}
      FormHelperTextProps={
        error
          ? { style: { textAlign: "left" } }
          : { style: { textAlign: "right" } }
      }
      InputProps={{
        disabled: loading || disabled,
        style: {
          backgroundColor: "white",
        },
        endAdornment: (
          <InputAdornment position="end">
            <InlineIcon
              id="my-leave-new-message-button"
              disabled={disabled}
              icon={SendIcon}
              className={classes.iconSend}
              onClick={handleSubmit}
              style={{ cursor: "pointer" }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MyTimeNewMessage;
