import React from "react";
import OcCard from "oc/components/oc-card/oc-card";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import accountCheck from "@iconify/icons-mdi/account-check";
import { useTheme } from "@material-ui/core/styles";

const MyTimeExaminersCard = ({ data, loading, error }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (data?.length === 0 && !loading) {
    return (
      <OcCard label={t("EXAMINERS")} loading={loading} error={error}>
        <Box style={{ color: theme.palette.primary.main, padding: "16px 0px" }}>
          {t("MISSING_EXAMINERS")}
        </Box>
      </OcCard>
    );
  }

  return (
    <OcCard label={t("EXAMINERS")} loading={loading} error={error}>
      <Box>
        {data?.map((item, index) => {
          return (
            <Box
              key={`my-time-examiners-${index}`}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon icon={accountCheck} height="24px" />
              <Box width="10px" />
              <Box paddingBottom="4px">{item}</Box>
            </Box>
          );
        })}
      </Box>
    </OcCard>
  );
};

export default MyTimeExaminersCard;
