import React from "react";
import Box from "@material-ui/core/Box";
import Currency from "oc/components/oc-currency";

export default function MyHrCurrencyBar({ label, value, total, loading }) {
  let percentage = ((100 * Math.abs(value || 0)) / total).toFixed(2);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      margin="16px 8px"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        width="50%"
        padding="0px 8px"
      >
        <span
          style={{
            color: "white",
            fontSize: "16px",
            letterSpacing: "0.44px",
            paddingRight: "8px",
          }}
        >
          {label}
        </span>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        padding="0px 8px"
        width="50%"
        borderRadius="4px"
        style={{
          backgroundImage: `linear-gradient(to left, ${
            loading ? "gray" : "#cc0033"
          } ${percentage}%, transparent ${percentage}% 100%)`,
        }}
      >
        <span
          style={{
            color: "white",
            fontSize: "16px",
            letterSpacing: "0.44px",
            padding: "4px 0px",
          }}
        >
          <Currency value={value} />
        </span>
      </Box>
    </Box>
  );
}
