import React from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const CustomTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#212121",
    color: "#FFFFFF",
    maxWidth: "400px",
    height: "auto",
    fontSize: "16px",
  },
}))(Tooltip);

const MyTimeDateHeaderTooltip = ({
  workTime,
  overtime,
  voluntaryOvertime,
  children,
  isOffRange,
  date,
}) => {
  const { t } = useTranslation();

  if (isOffRange) {
    return <Box>{children}</Box>;
  }

  const localizedDate = new Date(date).toLocaleDateString();

  const Title = ({ workTime, overtime, voluntaryOvertime }) => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box height="8px" />
        <Typography variant="caption">{localizedDate}</Typography>
        <Box height="8px" />
        <Typography variant="caption">{t("WORKING_TIME")}: {workTime}</Typography>
        <Typography variant="caption">{t("OVERTIME")}: {overtime}</Typography>
        <Typography variant="caption">
          {t("VOLUNTARY_OVERTIME")}: {voluntaryOvertime}
        </Typography>
        <Box height="8px" />
      </Box>
    );
  };

  return (
    <CustomTooltip
      title={
        <Title
          workTime={workTime}
          overtime={overtime}
          voluntaryOvertime={voluntaryOvertime}
        />
      }
      placement="top"
      arrow
    >
      {children}
    </CustomTooltip>
  );
};

export default MyTimeDateHeaderTooltip;
