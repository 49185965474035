import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation updateUser(
    $_id: String!
    $employee: String
    $email: String
    $phone: String
    $ldapUser: String
    $validFrom: Date
    $validTo: Date
    $roles: [RoleInput]
  ) {
    updateUser(
      _id: $_id
      employee: $employee
      email: $email
      phone: $phone
      ldapUser: $ldapUser
      validFrom: $validFrom
      validTo: $validTo
      roles: $roles
    ) {
      _id
      email
      phone
      ldapUser
      validFrom
      validTo
    }
  }
`;
