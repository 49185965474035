import gql from "graphql-tag";

export const GET_USER_ROLES_DATA_BY_CONTRACT = gql`
  query userRolesDataByContract($contract: String!) {
    userRolesDataByContract(contract: $contract) {
      id
      roleId
      contract
      product
      role
      roleFilters {
        company
        orgUnit
        workingPlace
        jobClassGroup
        payOffice
        contract
      }
    }
  }
`;
