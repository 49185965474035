import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  chartContainerRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chartContainer: ({ width, chartSize }) => ({
    display: "flex",
    position: "relative",
    width: width,
    height: chartSize,
    padding: 5,
    textAlign: "center",
    marginRight: 28,
    marginLeft: 12,
  }),
  legendMainContainer: ({ chartSize, legendWidth }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "auto",
    width: "100%",
    paddingTop: 5,
  }),
});

export default useStyles;
