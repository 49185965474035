import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    textTransform: "capitalize",
    letterSpacing: 0.4,
    paddingTop: 4,
    paddingBottom: 4,
    fontWeight: "normal",
  },
  timeline: {
    fontSize: 14,
    textTransform: "capitalize",
    letterSpacing: 0.4,
  },
  timelineWeekend: {
    fontSize: 14,
    textTransform: "capitalize",
  },
}));

export default useStyles;
