import gql from "graphql-tag";

export const APPROVE_CURRENT_CAFETERIA_STATEMENT = gql`
  mutation approveCurrentCafeteriaStatement(
    $contractCode: String
    $currentItemStartDate: String
  ) {
    approveCurrentCafeteriaStatement(
      contractCode: $contractCode
      currentItemStartDate: $currentItemStartDate
    ) {
      result
      messages
    }
  }
`;
