import gql from "graphql-tag";

export const GET_MOTHERS_UNDER_THIRTY_DATA = gql`
  query taxDeclarationMotherUnder30($_id: Int!) {
    taxDeclarationMotherUnder30(_id: $_id) {
      dateOfDeclaration
      isModifiedDeclaration
      state
      isEligible
      eligibleOnOwnChild
      ownChildName
      ownChildTaxNumber
      eligibleOnFetus
      pregnancy91thDayYear
      pregnancy91thDayMonth
      eligibleOnAdoptedChild
      adoptedChildName
      adoptedChildTaxNumber
      applyForTaxRelief
      applyForTaxReliefAllAmount
      applyForTaxReliefAmount
      noRequestReliefFromMonth
    }
  }
`;
