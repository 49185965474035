import gql from "graphql-tag";

export const UPDATE_CAFETERIA_STATEMENT_ITEM = gql`
  mutation updateCafeteriaStatementItem(
    $contractCode: String
    $currentItemStartDate: String
    $menuItemCode: String
    $itemSerial: String
    $startDate: String
    $endDate: String
    $type: String
    $amount: Float
    $plan: Boolean
    $szepCardApplicationAmount: Float
  ) {
    updateCafeteriaStatementItem(
      contractCode: $contractCode
      currentItemStartDate: $currentItemStartDate
      menuItemCode: $menuItemCode
      itemSerial: $itemSerial
      startDate: $startDate
      endDate: $endDate
      type: $type
      amount: $amount
      plan: $plan
      szepCardApplicationAmount: $szepCardApplicationAmount
    ) {
      contractCode
    }
  }
`;
