import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  hovered: {
    backgroundColor: "transparent",
    flexGrow: 1,

    "&:first-child": {
      marginLeft: "-10px",
      flexGrow: 0.8,
    },
    "&:last-child": {
      flexGrow: 0.8,
      marginRight: "-10px",
    },
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.10)",
    },
  },
}));

export default useStyles;
