import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";

export default function MyHrPublicLayoutLink({ label, path }) {
  const theme = useTheme();
  return (
    <Link
      to={path}
      style={{
        textDecoration: "none",
        fontSize: 16,
        letterSpacing: 0.44,
        color: theme.palette.primary.main,
      }}
    >
      {label}
    </Link>
  );
}
