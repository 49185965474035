import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcEmailInputField from "oc/components/oc-email-input-field";
import useEmailInput from "hooks/use-oc-email-input";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import ButtonBar from "../button-bar";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import useOcSnackbar from "hooks/use-oc-snackbar";

const EmailForm = ({
  setLoading,
  type,
  emailByCompanyState,
  emailByCompany,
}) => {
  const { t } = useTranslation();
  const { setOpenMessage } = useOcSnackbar();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const {
    value: email,
    setValue: setEmail,
    isValid: isEmailValid,
    validate: validateEmail,
    error: emailError,
    setError: setEmailError,
    helperText: emailHelperText,
    setHelperText: setEmailHelperText,
  } = useEmailInput();

  const { data: emailProperty } = useProperty({
    resource: "myLeave",
    key: type,
    onCompleted: ({ value }) => {
      if (!email && value) {
        setEmail(value);
      }
    },
  });

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onCompleted: () => {
      setLoading(false);
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
    onError: (error) => {
      setLoading(false);
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
  });

  let disabled = false;
  if (emailProperty === email && emailByCompany === emailByCompanyState) {
    disabled = true;
  }

  useEffect(() => {
    if (emailProperty && !email) {
      setEmail(emailProperty.value);
    }
  }, [emailProperty, email, setEmail]);

  function handleCancel() {
    setEmail(emailProperty?.value || "");
    setEmailError(false);
    setEmailHelperText("");
  }

  function handleSave() {
    if (!isEmailValid()) {
      validateEmail();
      setShowConfirmDialog(false);
      return;
    }
    setLoading(true);
    updateProperty({
      variables: {
        resource: "myLeave",
        key: type,
        value: email,
      },
    });
    if (!emailByCompanyState) {
      updateProperty({
        variables: {
          resource: "myLeave",
          key:
            type === "administrativeEmail"
              ? "administrativeEmailByCompany"
              : "payrollEmailByCompany",
          value: emailByCompanyState ? "true" : "false",
        },
      });
    }
    setShowConfirmDialog(false);
  }

  const cardLabel =
    type === "administrativeEmail"
      ? t("ADMINISTRATIVE_EMAIL")
      : t("PAYROLL_EMAIL_ADDRESS");

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <Box>
        <Box width="432px">
          <OcEmailInputField
            value={email}
            setValue={setEmail}
            error={emailError}
            setError={setEmailError}
            helperText={emailHelperText}
            setHelperText={setEmailHelperText}
            label={cardLabel}
          />
        </Box>
        <ButtonBar
          handleCancel={handleCancel}
          handleSave={() => setShowConfirmDialog(true)}
          disabled={disabled}
        />
      </Box>
    </>
  );
};

export default EmailForm;
