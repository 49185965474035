import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
    fontSize: 16,
    letterSpacing: "0.44px",
  },
}));

export default useStyles;
