import gql from "graphql-tag";

export const GET_JOB_CLASS_GROUPS_ADMIN_LIST = gql`
  query jobClassGroupsAdminList(
    $searchField: String
    $searchText: String
    $page: Int
    $pageSize: Int
    $jobClassGroups: [String]
  ) {
    jobClassGroupsAdminList(
      searchField: $searchField
      searchText: $searchText
      page: $page
      pageSize: $pageSize
      jobClassGroups: $jobClassGroups
    ) {
      availableList {
        _id
        code
        name
      }
      selectedList {
        _id
        code
        name
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
