import React from "react";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { useQuery, useSubscription, useMutation } from "@apollo/react-hooks";
import { GET_FILTERED_LEAVE_TYPES } from "graphql/queries";
import { LEAVES_SUBSCRIPTION } from "graphql/subcriptions";
import {
  ADD_LEAVE_TYPE_FILTER,
  DELETE_LEAVE_TYPE_FILTER,
} from "graphql/mutations";
import usePolling from "hooks/use-polling";

export default function MyLeaveAdminSettingsTypeFilterCard() {
  const { t } = useTranslation();

  const [
    addLeaveTypeFilter,
    { loading: addMutationLoading, error: addMutationError },
  ] = useMutation(ADD_LEAVE_TYPE_FILTER, {
    onCompleted: (data) => {
      //console.log("completed");
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const [
    deleteLeaveTypeFilter,
    { loading: deleteMutationLoading, error: deleteMutationError },
  ] = useMutation(DELETE_LEAVE_TYPE_FILTER, {
    onCompleted: (data) => {
      //console.log("completed");
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const {
    data: queryData,
    loading,
    error,
    refetch,
  } = useQuery(GET_FILTERED_LEAVE_TYPES, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
  });

  useSubscription(LEAVES_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  const data = queryData?.filteredLeaveTypes || [];

  function handleChange(e) {
    const code = e.target.name;
    const show = e.target.checked;
    if (show) {
      deleteLeaveTypeFilter({ variables: { code } });
    } else {
      addLeaveTypeFilter({ variables: { code } });
    }
  }

  return (
    <OcCard
      label={t("GRANT_LEAVE_TYPES")}
      loading={loading || addMutationLoading || deleteMutationLoading}
      error={error || addMutationError || deleteMutationError}
    >
      <Box>
        {data?.map((item, index) => {
          if (!item?.isSelectable) return null;
          return (
            <Box key={`filter-${index}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.show}
                    onChange={handleChange}
                    name={item.code}
                    color="secondary"
                    disabled={
                      loading || addMutationLoading || deleteMutationLoading
                    }
                  />
                }
                label={t(`Leave.${item.code}`)}
              />
            </Box>
          );
        })}
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => refetch()}
          >
            Frissít
          </Button>
        </Box>
      </Box>
    </OcCard>
  );
}
