import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./my-leave-effective-work-days-header.style";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function MyLeaveEffectiveWorkDaysHeader({
  handleExpandClick,
  expanded,
  currentYearEffectiveWorkDays,
}) {
  const classes = useStyles(expanded);
  const { t } = useTranslation();

  return (
    <OcErrorBoundary>
      <Box
        className={
          expanded === false
            ? classes.filterContainer
            : classes.openFilterContainer
        }
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="body1" className={classes.titleColor}>
            {t("EFFECTIVE_WORK_DAY")}
          </Typography>
          <Box width="74px" display="flex" justifyContent="center">
            <Typography variant="h4" className={classes.titleColor}>
              {currentYearEffectiveWorkDays?.toLocaleString()}
            </Typography>
          </Box>
        </Box>
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          style={
            expanded
              ? {
                  padding: 0,
                  transform: "rotate(180deg)",
                }
              : {
                  padding: 0,
                }
          }
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
    </OcErrorBoundary>
  );
}
