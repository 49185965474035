import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import LeaveDetailsChart from "my-leave/components/my-leave-leave-details-chart";
import MyFulfilledLeaves from "my-leave/components/my-leave-my-fulfilled-leaves";
import YearHeader from "my-leave/components/my-leave-year-header";
import { useHistory } from "react-router-dom";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import useCurrentUserState from "hooks/use-current-user-state";
import LeaveCovidplustenCard from "my-leave/components/my-leave-leave-covid-plus-ten-card";
import LeaveStudyCard from "my-leave/components/my-leave-leave-study-card";
import LeaveEszjtvCard from "my-leave/components/my-leave-leave-eszjtv-card";
import LeaveBaseCard from "my-leave/components/my-leave-leave-base-card";
import LeaveEmergencyCard from "my-leave/components/my-leave-leave-emergency-card";

export default function MyYearlyLeaves() {
  const { t } = useTranslation();
  let { year } = useParams();
  const history = useHistory();

  function handleIconClick() {
    history.goBack();
  }

  const { contractCode: contract } = useCurrentUserState();

  return (
    <OcPageTransition>
      <Box>
        <YearHeader handleIconClick={handleIconClick}>{year}</YearHeader>
        <Grid container spacing={0} style={{ padding: 8, paddingBottom: 0 }}>
          <Grid item sm={12} md={12} lg={5} style={{ padding: 8 }}>
            <OcCard label={t("Leave.leaveSummary")}>
              <>
                <LeaveBaseCard selectedYear={year} contract={contract} />
                <LeaveStudyCard selectedYear={year} contract={contract} />
                <LeaveCovidplustenCard
                  selectedYear={year}
                  contract={contract}
                />
                <LeaveEszjtvCard selectedYear={year} contract={contract} />
                <LeaveEmergencyCard selectedYear={year} contract={contract} />
              </>
            </OcCard>
          </Grid>
          <Grid item sm={12} md={12} lg={5} style={{ padding: 8 }}>
            <Box id="leave-details-chart">
              <OcCard label={t("Leave.leaveDetails")}>
                <LeaveDetailsChart selectedYear={year} />
              </OcCard>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ padding: 8, paddingTop: 0 }}>
          <Grid item sm={12} md={12} lg={6} style={{ padding: 8 }}>
            <OcCard label={t("MY_FULFILLED_LEAVES_AND_ABSENCES")}>
              <MyFulfilledLeaves selectedYear={year} />
            </OcCard>
          </Grid>
        </Grid>
      </Box>
    </OcPageTransition>
  );
}
