import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_LEAVE_REQUEST } from "graphql/queries";
import { LEAVE_REQUESTS_SUBSCRIPTION } from "graphql/subcriptions";
import usePolling from "hooks/use-polling";

export default function useLeaveRequest({ _id }) {
  const { data, loading, error, refetch } = useQuery(GET_LEAVE_REQUEST, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    skip: !_id,
    variables: { _id },
  });

  if (error) {
    console.log({
      component: "useLeaveRequest",
      errorDescription:
        "Error occured when fetch data with GET_LEAVE_REQUEST query",
      error: error,
    });
  }

  const { error: subscriptionError } = useSubscription(
    LEAVE_REQUESTS_SUBSCRIPTION,
    {
      onData: () => {
        refetch();
      },
    }
  );

  if (subscriptionError) {
    console.log({
      component: "useLeaveRequest",
      errorDescription:
        "Error occured when subscribe data with LEAVE_REQUESTS_SUBSCRIPTION query",
      error: error,
    });
  }

  let result = {
    leaveRequest: data?.leaveRequest,
    leaveRequestLoading: loading,
    leaveRequestError: error,
  };

  return result;
}
