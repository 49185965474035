import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import MyTimePageHeader from "my-time/components/my-time-page-header";
import OcPageTransition from "oc/components/oc-page-transition";
import useActualDate from "my-time/hooks/use-actual-date";
import OcCard from "oc/components/oc-card/oc-card";
import OcCollapseHeader from "oc/components/oc-collapse-header";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CompanyFilter from "my-leave/components/company-filter";
import OrgUnitFilter from "my-leave/components/org-unit-filter";
import WorkingPlaceFilter from "my-leave/components/working-place-filter";
import JobClassGroupFilter from "my-leave/components/job-class-group-filter";
import PayofficeFilter from "my-leave/components/payoffice-filter";
import EmployeeFilter from "my-leave/components/employee-filter";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FilteredListCount from "my-leave/components/my-leave-all-leaves-card/components/filtered-list-count";
import { useTranslation } from "react-i18next";
import useCurrentUserState from "hooks/use-current-user-state";
import useRoleFilters from "hooks/use-role-filters.js";
import _ from "lodash";
import useClientState from "hooks/use-oc-client-state";
import OcFilterSwitch from "oc/components/oc-filter-switch";
import { allMyTimeRequests } from "my-time/configs/tableDefs";
import Table from "oc/components/oc-data-table";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useMyTimeUsersWithRequests from "my-time/hooks/use-my-time-users-with-requests/use-my-time-users-with-requests";
import moment from "moment";
import { useHistory } from "react-router-dom";

const MyTimeApproveRequestsPage = ({
  product = "MyTime",
  role = "examiner",
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { date, formattedDate, year, month, handleDateChange } =
    useActualDate();

  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const [onlyRequested, setOnlyRequested] = useState(true);
  const [prevOnlyRequested, setPrevOnlyRequested] = useState(true);

  const [showOvertime, setShowOvertime] = useState(true);
  const [prevShowOvertime, setPrevShowOvertime] = useState(true);

  const [showWorkingTimeChange, setShowWorkingTimeChange] = useState(true);
  const [prevShowWorkingTimeChange, setPrevShowWorkingTimeChange] =
    useState(true);

  const [showStandbyCall, setShowStandbyCall] = useState(true);
  const [prevShowStandbyCall, setPrevShowStandbyCall] = useState(true);

  let tableId = "allMyTimeRequestsTableData";
  initialTableData.sortField = "name";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const [, /*filterable*/ setFilterable] = useState(false);

  let { contractCode: contract } = useCurrentUserState();

  const handleCompleted = (data) => {
    handleChangeFilterable(false);
  };

  let startDate = moment(date).startOf("month").toDate();
  let endDate = moment(date).endOf("month").toDate();

  const {
    roleFilters,
    prevRoleFilters,
    resetRoleFilters,
    setPrevRoleFilters,
    handleAddFilter,
    handleRemoveFilter,
  } = useRoleFilters({
    contract: contract,
    product: product,
    role: role,
  });

  const { loadQuery, data, loading } = useMyTimeUsersWithRequests({
    showOvertime,
    showWorkingTimeChange,
    requestsOnly: onlyRequested,
    showStandbyCall,
    roleFilters,
    tableData,
    year,
    month,
    date: date,
    startDate: startDate,
    endDate: endDate,
    onCompleted: handleCompleted,
    contract,
  });

  const handleClearFilters = () => {
    resetRoleFilters();
  };

  function handleChangeFilterable(state) {
    setFilterable(state);
  }

  const buttonDisable =
    _.isEqual(roleFilters, prevRoleFilters) &&
    onlyRequested === prevOnlyRequested &&
    showOvertime === prevShowOvertime &&
    showWorkingTimeChange === prevShowWorkingTimeChange &&
    showStandbyCall === prevShowStandbyCall;

  function handleLoadQuery() {
    loadQuery();
    setPrevRoleFilters(roleFilters);
    setPrevOnlyRequested(onlyRequested);
    setPrevShowOvertime(prevShowOvertime);
    setPrevShowWorkingTimeChange(showWorkingTimeChange);
    setPrevShowStandbyCall(showStandbyCall);
  }

  const list = {
    rows: data?.rows,
    total: data?.total,
  };

  function handleShowOvertimeChange(event) {
    setShowOvertime(event.target.checked);
  }

  function handleShowWorkingTimeChange(event) {
    setShowWorkingTimeChange(event.target.checked);
  }

  function handleShowStandbyCallChange(event) {
    setShowStandbyCall(event.target.checked);
  }

  function onSelectRow(o) {
    history.push(`/my-time/employee-requests/${o.employee}/${o.contract}`);
  }

  const [currentDate, setCurrentDate] = useState(date);
  const [prevCurrentDate, setPrevCurrentDate] = useState(date);

  function handleCurrentDateChange(date) {
    setPrevCurrentDate(currentDate);
    setCurrentDate(date);
    loadQuery();
  }

  if (currentDate !== prevCurrentDate) {
    loadQuery();
    setPrevCurrentDate(currentDate);
  }

  function handleTableDataChange(state) {
    setTableData(state);
    loadQuery();
  }

  useEffect(() => {
    loadQuery();
    setPrevRoleFilters(roleFilters);
  }, []); // eslint-disable-line

  return (
    <OcPageTransition>
      <Box padding="16px">
        <MyTimePageHeader
          formattedDate={formattedDate}
          date={date}
          onDateChange={(date) => {
            handleDateChange(date);
            handleCurrentDateChange(date);
          }}
        />
        <Box height="16px" />
        <Box>
          <OcCard label={t("MY_TIME_REQUESTS")}>
            <Box
              style={{
                backgroundColor: "#F1F3F4",
                borderRadius: "4px",
              }}
            >
              <OcCollapseHeader
                expanded={expanded}
                onExpandClick={handleExpandClick}
              />
              <Collapse
                in={expanded}
                timeout="auto"
                style={{
                  backgroundColor: "#F1F3F4",
                  borderRadius: "4px",
                }}
              >
                <Box
                  id="collapse-body"
                  padding="0px 16px"
                  display="flex"
                  flexDirection={"column"}
                  alignItems="center"
                >
                  <Box
                    style={{
                      backgroundColor: "#C6CACC",
                      height: "2px",
                      width: "100%",
                    }}
                  />
                  <Box width="66%">
                    <Box
                      id="state-slector"
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      padding="25px 0px 0px 0px"
                    >
                      <ButtonGroup>
                        <Button
                          id="leave-filter-collapse-filtered"
                          variant={onlyRequested ? "contained" : "outlined"}
                          color={onlyRequested ? "primary" : ""}
                          disableElevation
                          onClick={() => setOnlyRequested(true)}
                        >
                          {t("TO_BE_ASSESSED")}
                        </Button>
                        <Button
                          id="leave-filter-collapse-all"
                          variant={!onlyRequested ? "contained" : "outlined"}
                          color={!onlyRequested ? "primary" : ""}
                          disableElevation
                          onClick={() => setOnlyRequested(false)}
                        >
                          {t("ALL")}
                        </Button>
                      </ButtonGroup>
                    </Box>
                    <Box height="26px" />
                    <OcFilterSwitch
                      label={t("REPORTS_CHANGES")}
                      checked={showWorkingTimeChange}
                      name="reportsChanges"
                      onChange={handleShowWorkingTimeChange}
                    />
                    <Box height="16px" />
                    <OcFilterSwitch
                      label={t("OVERTIMES")}
                      checked={showOvertime}
                      name="overtimes"
                      onChange={handleShowOvertimeChange}
                    />
                    <Box height="16px" />
                    <OcFilterSwitch
                      label={t("CONSCRIPTIONS")}
                      checked={showStandbyCall}
                      name="conscriptions"
                      onChange={handleShowStandbyCallChange}
                    />
                    <Box height="26px" />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <CompanyFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OrgUnitFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <WorkingPlaceFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <JobClassGroupFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <PayofficeFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                    </Grid>
                    <Box height="16px" />
                    <Box
                      style={{
                        backgroundColor: "#C6CACC",
                        height: "1px",
                        width: "100%",
                      }}
                    />
                    <Box height="16px" />
                    <Grid container spacing={2}>
                      <Grid item xs={6} style={{ paddingRight: "8px" }}>
                        <EmployeeFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                    </Grid>
                    <Box height="16px" />
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                    >
                      <Grid item xs={12}>
                        <FilteredListCount
                          listCount="0"
                          type="filter"
                          requetsCount={data?.allRequestCount || 0}
                          allUsersCount={data?.allUsersCount || 0}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      id="buttons-container"
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      padding="26px 0px 26px 0px"
                    >
                      <Button id="cancel-button" onClick={handleClearFilters}>
                        {t("RESET")}
                      </Button>
                      <Box width="16px" />
                      <Button
                        id="filter-button"
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={buttonDisable}
                        onClick={() => {
                          handleLoadQuery();
                          handleChangeFilterable(true);
                        }}
                      >
                        {t("FILTERING")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Collapse>
            </Box>
            <Box paddingTop="32px">
              <Table
                tableDef={allMyTimeRequests}
                tableId={tableId}
                tableData={tableData}
                setTableData={handleTableDataChange}
                data={list}
                loading={loading}
                onSelectRow={onSelectRow}
                pagination={true}
              />
            </Box>
          </OcCard>
        </Box>
      </Box>
    </OcPageTransition>
  );
};

export default MyTimeApproveRequestsPage;
