import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_USER_BY_EMPLOYEE } from "graphql/queries";
import { USER_BY_EMPLOYEE_SUBSCRIPTION } from "graphql/subcriptions";
import _ from "lodash";
import usePolling from "hooks/use-polling";

export default function useUserByEmployee({ employee }) {
  const { data, loading, error } = useQuery(GET_USER_BY_EMPLOYEE, {
    fetchPolicy: "cache-and-network",
    skip: !employee,
    pollInterval: usePolling(1000 * 60),
    variables: { employee },
  });

  const { data: subsData } = useSubscription(USER_BY_EMPLOYEE_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    skip: !employee,
    variables: { employee },
  });

  let resultData = subsData?.userByEmployee || data?.userByEmployee;
  let result = _.cloneDeep(resultData);

  if (result) {
    result.phone = result.phone === null ? "" : result.phone;
    result.validTo = result.validTo === null ? "" : result.validTo;
    result.ldapUser = result.ldapUser === null ? "" : result.ldapUser;
  }

  return { data: result, loading, error };
}
