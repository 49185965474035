import gql from "graphql-tag";

export const ADD_CAFETERIA_STATEMENT_ITEM = gql`
  mutation addCafeteriaStatementItem(
    $contractCode: String
    $currentItemStartDate: String
    $menuItemCode: String
    $startDate: String
    $endDate: String
    $type: String
    $amount: Float
    $plan: Boolean
    $szepCardApplicationAmount: Float
  ) {
    addCafeteriaStatementItem(
      contractCode: $contractCode
      currentItemStartDate: $currentItemStartDate
      menuItemCode: $menuItemCode
      startDate: $startDate
      endDate: $endDate
      type: $type
      amount: $amount
      plan: $plan
      szepCardApplicationAmount: $szepCardApplicationAmount
    ) {
      result
      messages
    }
  }
`;
