import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "hu",
    debug: false,
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (value instanceof Date) {
          const res = value.toLocaleDateString(lng, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });

          return res;
        }
        return value;
      },
    },
    load: "languageOnly",
    whitelist: ["hu", "en"],
    nonExplicitWhitelist: true,
    detection: { checkWhitelist: true },
    react: {
      useSuspense: false,
      //wait: false,
    },
  });

const language =
  window?.navigator?.userLanguage?.substr(0, 2).toLowerCase() ||
  window?.navigator?.language?.substr(0, 2).toLowerCase() ||
  process?.env?.REACT_APP_DEFAULT_LANG ||
  "hu";

i18n.changeLanguage(language);

export default i18n;
