
import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";


const OcAppRefreshCounter = ({ timeToRefresh }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      justifyContent="center"
      textAlign="center"
      width="56px"
      height="40px"
      style={{
        backgroundColor: "#f1f3f4",
        color: "#cc0033",
        borderRadius: "4px",
        border: "1px solid #C6CACC",
      }}
    >
      <Typography
        style={{
          fontSize: "24px",
          letterSpacing: "-1.5px"
        }}
      >
        {timeToRefresh}
      </Typography>
    </Box>

  )
};

OcAppRefreshCounter.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

OcAppRefreshCounter.defaultProps = {
  example: "example",
};

export default OcAppRefreshCounter
