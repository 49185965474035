import useWorkTimeWeeklyData from "my-time/hooks/use-work-time-weekly-data";
import useWorkTime from "my-time/hooks/use-work-time";
import { timeConvert } from "my-time/utils/time-convert";

export const useWorkTimeLimits = ({
  contractCode,
  date,
  startTime,
  endTime,
  myTimeType,
}) => {
  const { data: workTimeWeeklyData } = useWorkTimeWeeklyData({
    contractCode,
    date: date,
  });

  const weeklyVoluntaryOvertimeLimit =
    workTimeWeeklyData?.weeklyVoluntaryOvertimeLimit * 60;

  const weeklyWorktimeLimit = workTimeWeeklyData?.weeklyWorktimeLimit * 60;

  const { data: workTimeData } = useWorkTime({
    contractCode,
    startTime,
    endTime,
    myTimeType: myTimeType,
  });

  const initialValue = 0;
  const allTime = workTimeData?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.workTime,
    initialValue
  );

  const timeSummary = timeConvert(allTime || 0);

  return {
    workTimeData,
    workTimeWeeklyData,
    weeklyVoluntaryOvertimeLimit,
    weeklyWorktimeLimit,
    timeSummary,
  };
};

export default useWorkTimeLimits;
