import gql from "graphql-tag";

export const DELETE_USER_ROLE_BY_CONTRACT = gql`
  mutation deleteUserRoleByContract($roleId: String!) {
    deleteUserRoleByContract(roleId: $roleId) {
      id
      roleId
      deletedAt
    }
  }
`;
