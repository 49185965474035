import gql from "graphql-tag";

export const REJECT_CHANGE_REQUEST = gql`
  mutation rejectChangeRequest(
    $_id: String!
    $locale: String!
    $message: String
  ) {
    rejectChangeRequest(_id: $_id, locale: $locale, message: $message) {
      _id
      type
    }
  }
`;
