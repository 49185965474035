import gql from "graphql-tag";

export const CREATE_USER = gql`
  mutation createUser(
    $employee: String!
    $fullname: String!
    $email: String!
    $phone: String
    $ldapUser: String
    $validFrom: Date!
    $validTo: Date
    $roles: [RoleInput]
    $locale: String
  ) {
    createUser(
      employee: $employee
      fullname: $fullname
      email: $email
      phone: $phone
      ldapUser: $ldapUser
      validFrom: $validFrom
      validTo: $validTo
      roles: $roles
      locale: $locale
    ) {
      _id
      email
      phone
      ldapUser
      validFrom
      validTo
    }
  }
`;
