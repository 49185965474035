import React, { useState } from "react";
import { Collapse } from "@material-ui/core";
import ButtonBar from "my-leave/components/my-leave-email-card/components/button-bar/button-bar";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import { useRecoilState } from "recoil";
import { myTimeGeneralSettingsState } from "states/my-time-settings-state";
import useCustomerLicences from "hooks/use-customer-licences";
import useOcSnackbar from "hooks/use-oc-snackbar";
import OcGeneralSwitchButtonSetting from "oc/components/oc-general-switch-button-settings/oc-general-switch-button-settings";
import OcSettingsDivider from "oc/components/oc-settings-divider/oc-settings-divider";

const useMyTimeProperty = ({ propertyKey }) => {
  const { t } = useTranslation();
  const { setOpenMessage } = useOcSnackbar();

  const [state, setState] = useRecoilState(
    myTimeGeneralSettingsState(propertyKey)
  );

  const { data, loading, error, refetch } = useProperty({
    resource: "myTime",
    key: propertyKey,
    onCompleted: ({ value }) => {
      const isEnabled = value === "true" ? true : false;
      const newValue = {
        value: isEnabled,
        prevValue: isEnabled,
      };
      setState((prev) => {
        return { ...prev, ...newValue };
      });
    },
  });

  const handleChange = (event) => {
    const newValue = { value: event.target.checked };
    setState((prev) => {
      return { ...prev, ...newValue };
    });
  };

  const handleCancel = () => {
    const isEnabled = data === "true" ? true : false;
    const newValue = {
      value: isEnabled,
      prevValue: isEnabled,
    };
    setState((prev) => {
      return { ...prev, ...newValue };
    });
  };

  const [update] = useMutation(UPDATE_PROPERTY, {
    onError({ ...err }) {
      const loading = { loading: false };
      setState((prev) => {
        return { ...prev, ...loading };
      });

      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
    onCompleted(data) {
      const isEnabled = data === "true" ? true : false;
      const newValue = {
        loading: false,
        value: isEnabled,
        prevValue: isEnabled,
      };
      setState((prev) => {
        return { ...prev, ...newValue };
      });
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
  });

  const handleSave = () => {
    const propertyValue = state.value ? "true" : "false";
    if (propertyValue !== data) {
      update({
        variables: {
          resource: "myTime",
          key: propertyKey,
          value: propertyValue,
        },
      });
    }
  };

  const isDirty = state.value !== state.prevValue;
  return {
    data,
    error,
    handleCancel,
    handleChange,
    handleSave,
    isDirty,
    loading,
    refetch,
    state,
  };
};

const MyTimeGeneralSettingsCard = () => {
  const { t } = useTranslation();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { licences } = useCustomerLicences();
  const myTimeEmployeeRequestsEnabled =
    licences?.myTimeEmployeeRequests || false;

  const {
    state: { value: changeMyTimeEnabled },
    handleCancel: changeMyTimeAllowedHandleCancel,
    handleSave: changeMyTimeAllowedSave,
    isDirty: changeMyTimeAllowedIsDirty,
  } = useMyTimeProperty({
    propertyKey: "changeMyTimeEnabled",
  });

  const {
    handleCancel: changeWorkingTimeAllowedHandleCancel,
    handleSave: changeWorkingTimeAllowedSave,
    isDirty: changeWorkingTimeAllowedDirty,
  } = useMyTimeProperty({
    propertyKey: "changeWorkingTimeEnabled",
  });

  const {
    handleCancel: approveAllRequestsAllowedHandleCancel,
    handleSave: approveAllRequestsAllowedHandleSave,
    isDirty: approveAllRequestsAllowedIsDirty,
  } = useMyTimeProperty({
    propertyKey: "approveAllRequestsEnabled",
  });

  const handleSave = () => {
    changeMyTimeAllowedSave();
    changeWorkingTimeAllowedSave();
    approveAllRequestsAllowedHandleSave();
    setShowConfirmDialog(false);
  };

  const handleCancel = () => {
    changeMyTimeAllowedHandleCancel();
    changeWorkingTimeAllowedHandleCancel();
    approveAllRequestsAllowedHandleCancel();
  };

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  let disabled =
    !changeMyTimeAllowedIsDirty &&
    !changeWorkingTimeAllowedDirty &&
    !approveAllRequestsAllowedIsDirty;

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcCard label={t("GENERAL_SETTINGS")} loading={false}>
        <OcGeneralSwitchButtonSetting
          label={t("MY_TIME_CHANGE_ALLOWED")}
          infoText={t("MY_TIME_CHANGE_ALLOWED_INFO")}
          propertyKey="changeMyTimeEnabled"
          disabled={!myTimeEmployeeRequestsEnabled}
          useMyTimePropertyHook={useMyTimeProperty}
        />
        <Collapse in={changeMyTimeEnabled && myTimeEmployeeRequestsEnabled}>
          <OcSettingsDivider />
          <OcGeneralSwitchButtonSetting
            label={t("MY_TIME_CHANGE_WORKING_TIME_ALLOWED")}
            infoText={t("MY_TIME_CHANGE_WORKING_TIME_ALLOWED_INFO")}
            propertyKey="changeWorkingTimeEnabled"
            disabled={!changeMyTimeEnabled}
            useMyTimePropertyHook={useMyTimeProperty}
          />
          <OcGeneralSwitchButtonSetting
            label={t("MY_TIME_APPROVE_ALL_REQUESTS_ALLOWED")}
            infoText={t("MY_TIME_APPROVE_ALL_REQUESTS_ALLOWED_INFO")}
            propertyKey="approveAllRequestsEnabled"
            disabled={!changeMyTimeEnabled}
            useMyTimePropertyHook={useMyTimeProperty}
          />
        </Collapse>
        <ButtonBar
          handleCancel={handleCancel}
          handleSave={() => setShowConfirmDialog(true)}
          disabled={disabled}
        />
      </OcCard>
    </>
  );
};

export default MyTimeGeneralSettingsCard;
