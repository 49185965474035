import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import FilterItem from "oc/components/oc-filter-item";
import { GET_IMPORT_EMPLOYEE_LIST } from "graphql/queries";
import { useQuery } from "@apollo/client";
import usePolling from "hooks/use-polling";
import useQueryParams from "hooks/use-query-params";
import useFiltersData from "hooks/use-filters-data";

export default function OcEmployeeImportFilter({
  roleFilters,
  onAddFilter,
  onRemoveFilter,
  product,
  role,
  contract
}) {
  let filterType = "contract";

  const entity = { contracts: roleFilters?.contract || [] };
  const stateId = "employeeImportFilter";

  const { search, setSearch, setPageSize, queryParams } = useQueryParams({
    stateId,
    entity,
    product,
    role,
    contract
  });

  const {
    data: rawData,
    loading,
    fetchMore,
  } = useQuery(GET_IMPORT_EMPLOYEE_LIST, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    notifyOnNetworkStatusChange: true,
    variables: queryParams,
  });

  const data = rawData?.importEmployeeList;

  const {
    availableItems,
    selectedItems,
    handleFetchMore,
    handleSearch,
    handleClearSearch,
  } = useFiltersData({
    data,
    fetchMore,
    setSearch,
    setPageSize,
    entityLength: roleFilters?.orgUnit?.length || 0,
    //selectAll: true,
  });

  const parsedAvailableItems = availableItems.map((item) => {
    return {
      code: item.code,
      name: `${item.name.replace(/\|/g, " ").trim()}`,
    };
  });

  const parsedSelectedItems = selectedItems.map((item) => {
    return {
      code: item.code,
      name: `${item.name.replace(/\|/g, " ").trim()})`,
    };
  });

  return (
    <OcErrorBoundary>
      <FilterItem
        filterType={filterType}
        selectedItems={parsedSelectedItems || []}
        availableItems={parsedAvailableItems}
        search={search}
        onSearch={handleSearch}
        onClearSearch={handleClearSearch}
        onAddFilter={onAddFilter}
        onRemoveFilter={onRemoveFilter}
        onLoading={loading}
        onFetchMore={handleFetchMore}
        showLists={true}
      />
    </OcErrorBoundary>
  );
}
