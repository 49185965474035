import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  text: ({ borderColor }) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: 12,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 1,
    paddingBottom: 1,
  }),
  textLoading: {
    backgroundColor: theme.palette.gray30.main,
    color: theme.palette.gray30.main,
    borderRadius: 12,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 1,
    paddingBottom: 1,
    width: 125,
    height: 22,
  },
}));

export default useStyles;
