// closeRecordingPeriod

import gql from "graphql-tag";

export const CLOSE_RECORDING_PERIOD = gql`
  mutation closeRecordingPeriod {
    closeRecordingPeriod {
      result
      message
    }
  }
`;
