import React from "react";
import Box from "@material-ui/core/Box";
import Switch from "oc/components/oc-form-switch";

export default function MyHrSwitchInput({
  id,
  label,
  info,
  checked,
  onChange,
}) {
  return (
    <Box
      id={id}
      display="flex"
      flexDirection="row"
      alignItems="center"
      flexWrap="no-wrap"
    >
      <Box
        width="200px"
        textAlign="right"
        fontSize="12px"
        letterSpacing="0.4px"
        color="#4D4D4D"
        paddingRight="16px"
      >
        {label}
      </Box>
      <Switch
        id={id}
        checked={checked}
        onChange={onChange}
        color="primary"
        name="checkedB"
        inputProps={{ "aria-label": "primary checkbox" }}
      />
      <Box
        fontSize="12px"
        letterSpacing="0.4px"
        color="#4D4D4D"
        width="300px"
        paddingLeft="16px"
      >
        {info}
      </Box>
    </Box>
  );
}
