import React from "react";
import useOcProperty from "hooks/use-oc-property";
import MyHrAdminUsersImportJDolBerCard from "my-hr/components/my-hr-admin-users-import-jdolber-card";
import MyHrAdminUsersImportSSHRCard from "my-hr/components/my-hr-admin-users-import-sshr-card";
import Box from "@material-ui/core/Box";

export default function MyHrAdminUsersImportWrapper() {
  const { data: importType } = useOcProperty({
    resource: "system",
    key: "importType",
  });

  if (!importType || importType === "none") return null;

  let showSSHRImportCard = importType === "sshr";
  let showJDolBerImportCard = importType === "jdolber";

  return (
    <React.Fragment>
      {showJDolBerImportCard && <MyHrAdminUsersImportJDolBerCard />}
      {showSSHRImportCard && <MyHrAdminUsersImportSSHRCard />}
      {(showSSHRImportCard || showJDolBerImportCard) && <Box height="16px" />}
    </React.Fragment>
  );
}
