import moment from "moment";
import { useRecoilState } from "recoil";
import { myTimeActualYearState } from "my-time/states/actual-year";
import { myTimeActualMonthState } from "my-time/states/actual-month";
import { myTimeActualDateState } from "my-time/states/actual-date";

const useActualDate = () => {
  const [year, setYear] = useRecoilState(myTimeActualYearState);
  const [month, setMonth] = useRecoilState(myTimeActualMonthState);
  const [date, setDate] = useRecoilState(myTimeActualDateState);
  const calculatedDate = moment(`${year}-${month}`).toDate();
  const formattedDate = moment(calculatedDate).format("YYYY. MMMM");
  const handleDateChange = (date) => {
    const year = moment(date).format("YYYY");
    const month = moment(date).format("MM");
    setYear(year);
    setMonth(month);
    setDate(new Date(date));
  };
  return { date: date, formattedDate, year, month, handleDateChange };
};

export default useActualDate;
