import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const taxDeclarationUnder25 = atom({
  key: "taxDeclarationUnder25",
  default: {
    dateOfDeclaration: new Date(),
    noApply: true,
    discountLimit: 0,
    noApplyAboveLimit: false,
  },
  effects_UNSTABLE: [persistAtom],
});
