export const getAddressType = ({ def, data }) => {
  const isAddressType =
    def?.type === "permanentAddress" ||
    def?.type === "temporaryAddress" ||
    def?.type === "serviceAddress";

  let isNewAddress = false;
  if (isAddressType && data[def?.type].city === "") {
    isNewAddress = true;
  }

  return { isAddressType, addressType: def?.type, isNewAddress };
};

export const getFormData = ({ values, data, def }) => {
  const valuesObject = { ...values };
  delete valuesObject.initialMessage;
  delete valuesObject.validFrom;
  delete valuesObject.addressSelector;

  const { isAddressType, addressType } = getAddressType({ def, data });

  const objectKeys = Object.keys(valuesObject);
  const rawDataArray = objectKeys.map((key) => {
    let oldValue = typeof data[key] === "object" ? data[key].name : data[key];
    let newValue =
      typeof values[key] === "object" ? values[key].name : values[key];
    let newValueCode =
      typeof values[key] === "object" ? values[key].code : data[key];

    if (isAddressType) {
      const address = data[addressType];
      if (key === "streetType") {
        oldValue = address[key].name;
        newValueCode = address[key].code;
      } else {
        oldValue = address[key];
      }
    }

    if (def?.type === "phones") {
      const type = data[def?.type].find(
        (arrayItem) => arrayItem.type === key.slice(0, -5)
      );
      oldValue = type?.number || "";
      if (!newValue.startsWith("+36")) {
        newValue = `+36 ${newValue.trim()}`;
      }
      if (newValue.trim() === "+36") {
        newValue = "";
      }
      console.log(newValue);
    }

    if (def?.type === "emails") {
      const type = data[def?.type].find(
        (arrayItem) => arrayItem.type === key.slice(0, -5)
      );
      oldValue = type?.address || "";
    }

    const item = {
      type: key,
      oldValue: oldValue,
      newValue: newValue,
      newValueCode: newValueCode || "",
    };

    if (key === "dateOfBirth") {
      item.newValue = values[key];
    }
    return item;
  });

  const dataArray = rawDataArray.filter(
    (item) => item.oldValue !== item.newValue
  );
  return { data: dataArray };
};

export const getFormValues = ({ def, data, isAddressType }) => {
  const initialValues = {};
  const validationSchema = {};
  def?.items?.map((item) => {
    if (item?.name === "validFrom") {
      initialValues.validFrom = new Date();
    } else if (item?.name === "initialMessage") {
      initialValues.initialMessage = "";
    } else if (isAddressType) {
      initialValues[item.name] = data[def?.type][item.dataField] || "";
    } else if (def?.type === "phones") {
      const type = data[def?.type].find(
        (arrayItem) => arrayItem.type === item?.dataField
      );
      initialValues[item?.name] = type?.number || "";
    } else if (def?.type === "emails") {
      const type = data[def?.type].find(
        (arrayItem) => arrayItem.type === item?.dataField
      );
      initialValues[item?.name] = type?.address || "";
    } else if (isAddressType) {
      initialValues[item.name] = data[def?.type][item.dataField] || "";
    } else {
      initialValues[item?.name] = data[item.dataField] || "";
    }
    if (item?.validation) {
      validationSchema[item.name] = item?.validation;
    }
    return item;
  });
  return { initialValues, validationSchema };
};
