import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { InlineIcon } from "@iconify/react";

export default function MyHrContractCardItem({ icon, label }) {
  if (!label || label === "N/A" || label === "not_defined") return null;
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      padding="2px 8px"
    >
      <Box width="20px" height="20px" marginRight="16px">
        <InlineIcon
          icon={icon}
          style={{
            width: "20px",
            height: "20px",
            margin: "0 8px 0 0",
            color: "#212121",
          }}
        />
      </Box>
      <Typography variant="body1">{label}</Typography>
    </Box>
  );
}
