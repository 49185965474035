import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_PAYCARD_IN_HTML } from "graphql/queries";
import { PAYCARDS_SUBSCRIPTION } from "graphql/subcriptions";
import usePolling from "hooks/use-polling";

export default function usePaycard({ contract, year, month }) {
  const { data, loading, error, refetch } = useQuery(GET_PAYCARD_IN_HTML, {
    skip: !contract || !year || !month,
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    variables: { contractCode: contract, year, month },
  });

  if (error) {
    console.log({
      component: "usePaycard",
      errorDescription:
        "Error occured when fetch data with GET_PAYCARD_IN_HTML query",
      error: error,
    });
  }

  const { error: subscriptionError } = useSubscription(PAYCARDS_SUBSCRIPTION, {
    onData: () => {
      refetch();
    },
  });

  if (subscriptionError) {
    console.log({
      component: "usePaycard",
      errorDescription:
        "Error occured when fetch data with PAYCARDS_SUBSCRIPTION query",
      error: error,
    });
  }

  return {
    data: data?.getPaycardInHTML,
    loading,
    error,
  };
}
