import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import FilterItem from "oc/components/oc-filter-item";
import { GET_JOB_CLASS_GROUPS } from "graphql/queries";
import { JOB_CLASS_GROUPS_SUBSCRIPTION } from "graphql/subcriptions";
import { useQuery, useSubscription } from "@apollo/client";
import usePolling from "hooks/use-polling";
import useFiltersData from "hooks/use-filters-data";
import useQueryParams from "hooks/use-query-params";

export default function JobClassGroupFilter({
  roleFilters,
  onAddFilter,
  onRemoveFilter,
  product,
  role,
  editable = true,
  contract
}) {
  let filterType = "jobClassGroup";

  const entity = {
    jobClassGroups: roleFilters?.jobClassGroup,
  };

  const stateId = "jobClassGroupsFilter";
  const { search, setSearch, setPageSize, queryParams, showLoading } =
    useQueryParams({
      stateId,
      entity,
      product,
      role,
      roleFilters,
      showAllowedEntities: editable,
      contract
    });

  const {
    data: rawData,
    loading,
    fetchMore,
  } = useQuery(GET_JOB_CLASS_GROUPS, {
    fetchPolicy: "no-cache",
    pollInterval: usePolling(1000 * 60),
    variables: queryParams,
  });

  useSubscription(JOB_CLASS_GROUPS_SUBSCRIPTION);

  const data = rawData?.jobClassGroups;
  const {
    availableItems,
    selectedItems,
    handleFetchMore,
    handleSearch,
    handleClearSearch,
  } = useFiltersData({
    data,
    fetchMore,
    setSearch,
    setPageSize,
    entityLength: roleFilters?.jobClassGroup?.length || 0,
    selectAll: true,
    product,
    role,
    filterType,
  });

  return (
    <OcErrorBoundary>
      <FilterItem
        availableItems={availableItems}
        editable={editable}
        filterType={filterType}
        onAddFilter={onAddFilter}
        onClearSearch={handleClearSearch}
        onFetchMore={handleFetchMore}
        onLoading={loading && showLoading}
        onRemoveFilter={onRemoveFilter}
        onSearch={handleSearch}
        roleFilters={roleFilters}
        search={search}
        selectedItems={selectedItems}
        withCode={true}
      />
    </OcErrorBoundary>
  );
}
