import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  periodTitle: {
    color: "#5E6366",
    fontFamily: "Mulish",
    fontSize: "16px",
    lineHeight: "25px",
    letterSpacing: "0.44px",
  },
  period: {
    fontFamily: "Mulish",
    fontSize: "34px",
    letterSpacing: "0.25px",
    color: "#5E6366",
    lineHeight: "42px",
  },
  divider: {
    padding: "16px 8px 0px 8px",
    borderBottom: "1px solid #E3E5E6",
    marginBottom: "32px",
  },
  skeleton: {
    marginTop: "-8px",
  },
}));

export default useStyles;
