import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import useStyles from "./my-leave-state.style";

export const LeaveState = ({ label, state, loading, variant }) => {
  const theme = useTheme();
  const getColor = (state) => {
    switch (state) {
      case "requested":
        return "#F3AA18";
      case "approved":
        return "#2FA84F";
      case "rejected":
        return theme.palette.primary.main;
      case "recorded":
        return "#2FA84F";
      default:
        return "white";
    }
  };

  const classes = useStyles({ borderColor: getColor(state) });

  return (
    <div className={classes.root}>
      <Typography
        className={loading ? classes.textLoading : classes.text}
        variant={variant}
      >
        {label}
      </Typography>
    </div>
  );
};

export default LeaveState;
