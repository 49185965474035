import React from "react";
import { Box, Typography } from "@material-ui/core";
import OcPrivateLayoutFooter from "oc/components/oc-private-layout-footer";
import useStyles from "../family-tax-credit-information/family-tax-credit-information.style";

const FourChildrenTaxCreditInformation = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box padding="16px" style={{ backgroundColor: "#F1F3F4" }}>
        <Box>
          <Typography
            variant="h4"
            style={{
              fontSize: "34px",
              fontWeight: "400",
              color: "#5E6366",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Tájékoztató
          </Typography>
          <Box height="32px" />
          <Typography
            variant="h5"
            style={{
              fontSize: "24px",
              fontWeight: "400",
              color: "#212121",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Adóelőleg-nyilatkozat a négy vagy több gyermeket nevelő anyák
            kedvezményének érvényesítéséről
          </Typography>
          <Box height="32px" />
          <Box className={classes.bgBox}>
            <Typography variant="h5" className={classes.titles}>
              <b>Tudnivalók a nyilatkozathoz</b>
            </Typography>
            <Box height="32px" />
            <Typography variant="h6">
              <b>Kinek kell átadni ezt a nyilatkozatot?</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Ahhoz, hogy a munkáltatója, kifizetője az Ön törvényben
              meghatározott jövedelmeire a négy vagy több gyermeket nevelő anyák
              kedvezményét figyelembe véve állapítsa meg az adóelőleget, ezt a
              nyilatkozatot két példányban kell átadnia részére.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A nyilatkozat egyik példányát a munkáltatónak (kifizetőnek), másik
              példányát pedig Önnek kell a bevallás benyújtásának évét követő 5.
              év végéig megőriznie.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              2024-től már minden négy vagy több gyermeket nevelő anyának
              lehetősége van arra, hogy kérje a munkáltatót, kifizetőt, hogy a
              kedvezményt az adóelőleg-nyilatkozat visszavonásáig vegye
              folyamatosan figyelembe (folytatólagos nyilatkozat), amit a 3.
              pontban kell jelezni. Ekkor a következő évben, években nem kell új
              nyilatkozatot adnia.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <i>
                Ha Ön a négy vagy több gyermeket nevelő anyák kedvezményét
                jogalap nélkül kéri, és emiatt az adóbevallásában 10 ezer
                forintot meghaladó befizetési kötelezettsége, vagyis
                adóhátraléka keletkezik, akkor ennek 12 százalékát különbözeti
                bírságként kell megfizetnie az adóhátralékkal együtt.
              </i>
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>Kinek jár a kedvezmény?</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b>
                Négy vagy több gyermeket nevelő anya az a nő, aki vér szerinti
                vagy örökbefogadó szülőként
              </b>{" "}
              az általa nevelt, legalább négy gyermek után:
            </Typography>
            <Box height="10px" />
            <Box marginLeft="20px">
              <Typography variant="body1">
                a) családi pótlékra jogosult, vagy
              </Typography>
              <Box height="10px" />
              <Typography variant="body1">
                b) családi pótlékra már nem jogosult, de legalább 12 éven
                keresztül jogosult volt,
              </Typography>
              <Box height="10px" />
              <Typography variant="body1">
                c) a családi pótlékra való jogosultság a gyermek elhunyta miatt
                szűnt meg.{" "}
              </Typography>
            </Box>
            <Typography variant="body1">
              Az a) pont szerinti gyermeknek kell tekinteni azt a gyermeket is,
              aki fogyatékkal élőként szociális intézményben látnak el, utána az
              anya családi pótlékra nem jogosult, de a gyermeket az anyát
              megillető családi pótlék összegének meghatározásakor figyelembe
              veszik.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A b) pont szerinti gyermeknek kell tekinteni azt a gyermeket is,
              aki után az anya vagy a szociális intézmény vezetője családi
              pótlékra való jogosultsága már nem áll fenn, de legalább 12 éven
              keresztül e két személy valamelyike, vagy mindkettejük esetében
              összesen fennállt, feltéve, hogy a szociális intézmény vezetőjénél
              fennálló jogosultság időszaka alatt az előző mondatban foglaltak
              teljesültek.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Például <b>négy vagy több gyermeket nevelő anyának számít</b> az a
              nő, aki:
            </Typography>
            <Typography variant="body1" className={classes.listItems}>
              <li>
                saját háztartásában neveli a 3, 6, 9 és 12 éves gyermekeit;
              </li>
              <li>
                négy gyermeket szült, ebből hármat felnevelt (már felnőttek),
                egyikük pedig kisgyermekként elhunyt;
              </li>
              <li>
                öt gyermeket szült, a szülők elváltak, amikor a két legidősebb
                gyermek 16 és 14 éves volt, a három fiatalabb gyermek az anyánál
                maradt, a két idősebb az apához került;
              </li>
              <li>
                három gyermeket szült és nevelt: a legidősebb dolgozik,
                elköltözött otthonról, a középső egyetemista, a harmadik
                középiskolába jár, és korábban örökbefogadott egy 3 éves
                kislányt, aki 22 évesen költözött el a szülői házból.{" "}
              </li>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b>Nem tekinthető négy vagy több gyermeket nevelő anyának</b> az a
              nő, aki:
            </Typography>
            <Typography variant="body1" className={classes.listItems}>
              <li>
                négy gyermeket szült, de váláskor az apához került 10 éves
                kislánya;
              </li>
              <li>
                második férjével közösen nevel négy kiskorú gyermeket, akik
                közül kettő a sajátja, kettő a férjéé (kivéve, ha a férje
                gyermekeit örökbe fogadta).
              </li>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Ha a továbbiakban nem kívánja igénybe venni a négy vagy több
              gyermeket nevelő anyák kedvezményét, (például, mert a kedvezményre
              már nem jogosult) akkor ezt a 4. sorban kell jelölnie.
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>Milyen jövedelmekre érvényesíthető a kedvezmény?</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A négy vagy több gyermeket nevelő anyák kedvezménye a{" "}
              <b>
                törvényi előírások szerint az alábbi jövedelmekre
                érvényesíthető:
              </b>
            </Typography>
            <Typography variant="body1" className={classes.listItems}>
              <li>
                a bérnek minősülő és más nem önálló tevékenységből származó
                jövedelemre, ilyenek különösen:
              </li>
            </Typography>
            <Typography variant="body1" className={classes.insideListItems}>
              <li>
                a munkaviszonyból, közfoglalkoztatási jogviszonyból származó
                jövedelem,
              </li>
              <li>
                az adóköteles társadalombiztosítási ellátás (például: táppénz,
                csecsemőgondozási díj, gyermekgondozási díj),
              </li>
              <li>
                a szociális igazgatásról és szociális ellátásokról szóló törvény
                alapján folyósított adóköteles ellátások,
              </li>
              <li>
                a foglalkoztatás elősegítéséről és a munkanélküliek ellátásáról
                szóló törvény alapján folyósított álláskeresési járadék, nyugdíj
                előtti álláskeresési segély, álláskeresési segély,
                kereset-kiegészítés és keresetpótló juttatás, álláskeresést
                ösztönző juttatás;
              </li>
              <li>
                a Magyar Honvédségnél tartalékos katonai szolgálatot teljesítő
                magánszemély e jogviszonyból származó jövedelme,
              </li>
              <li>
                ez előzőekben meghatározott jövedelmet pótló kártérítés
                (keresetpótló járadék),
              </li>
              <li>
                a nevelőszülői foglalkoztatási jogviszonyban végzett tevékenység
                díjazása,
              </li>
              <li>
                a munkaviszony megszűnése miatt kifizetett végkielégítés, de a
                végkielégítés törvényben meghatározott mértéket meghaladó része
                nem képezi a kedvezmény alapját,
              </li>
              <li>
                a társas vállalkozás magánszemély tagjának személyes
                közreműködése ellenértékeként kifizetett jövedelem,
              </li>
              <li>
                a gazdasági társaság vezető tisztségviselőjének tevékenységére
                tekintettel adott juttatás,
              </li>
              <li>
                a jogszabály alapján választott vagy kijelölt tisztségviselő
                tevékenysége (például igazgatótanácsi tag, felügyelőbizottsági
                tag) ellenértékeként kapott juttatás,
              </li>
              <li>
                az Európai Unió Tanácsa 2024. második félévi magyar elnökségével
                kapcsolatos feladatok ellátására irányuló jogviszony keretében
                végzett tevékenységből származó jövedelem,
              </li>
              <li>
                nemzetközi szerződés hatálya alatt a nem önálló munkából, ennek
                hiányában az adott állam joga szerinti munkaviszonyból származó
                jövedelem,
              </li>
              <li>
                az országgyűlési képviselők, nemzetiségi szószólók,
                polgármesterek e tevékenységből származó jövedelme,
              </li>
              <li>
                az állami projektértékelői jogviszonyból származó jövedelem.
              </li>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1" className={classes.listItems}>
              <li>Az önálló tevékenységből származó jövedelmek közül:</li>
            </Typography>
            <Typography variant="body1" className={classes.insideListItems}>
              <li>
                a vállalkozói jövedelem szerinti adózást alkalmazó egyéni
                vállalkozó vállalkozói kivétjére, átalányadózás esetén az
                átalányban megállapított jövedelmére,
              </li>
              <li>
                a mezőgazdasági őstermelő e tevékenységéből származó
                jövedelmére,
              </li>
              <li>
                az európai parlamenti képviselő e tevékenységéből származó
                jövedelmére,
              </li>
              <li>
                a helyi önkormányzati képviselő e tevékenységéből származó
                jövedelmére,
              </li>
              <li>
                a választott könyvvizsgáló e tevékenységéből származó
                jövedelmére,
              </li>
              <li>
                a magánszemély által nem egyéni vállalkozóként kötött, díjazás
                ellenében történő munkavégzésre irányuló, más szerződés alapján
                folytatott tevékenységéből, például megbízásból származó
                jövedelmére.
              </li>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A kedvezményre való{" "}
              <b>
                jogosultság annak a hónapnak az első napján kezdődik, amelyben
                Ön először – akár csak egy napig is – négy vagy több gyermeket
                nevelő anyának minősül.
              </b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A kedvezményre való{" "}
              <b>
                jogosultság annak a hónapnak az utolsó napján szűnik meg,
                amelyben Ön utoljára, legalább egy napig négy vagy több
                gyermeket nevelő anyának minősül.
              </b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Például egy házaspár négy gyermeket nevel, akik 3, 6, 9 és 12
              évesek. A szülők júniusban elválnak és a két idősebb gyermek az
              édesapával marad. Ebben az esetben az anya utoljára júniusban
              tekinthető négy vagy több gyermeket nevelő anyának, így az
              adóelőleg kitöltésekor július hónapot kell megjelölnie a
              jogosultság megszűnésének hónapjaként.
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>Hogyan érvényesülhet együtt több szja-kedvezmény?</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A négy vagy több gyermeket nevelő anyák kedvezményét{" "}
              <b>minden más kedvezményt megelőzően</b> érvényesíti a munkáltató,
              kifizető. Ez azt jelenti, hogy Ön az említett jövedelmeire nem
              tudja az első házasok kedvezményét, a családi kedvezményt és a
              személyi kedvezményt érvényesíteni, annak azonban nincs akadálya,
              hogy a bérjövedelmére <b>családi járulékkedvezményt</b>{" "}
              érvényesítsen. Annak érdekében, hogy Ön a családi
              járulékkedvezményt is igénybe tudja venni, a családi kedvezmény
              igénybevételére vonatkozó adóelőleg- nyilatkozatot is ki kell
              töltenie és el kell juttatnia a munkáltatójának.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Hasonlóan kell eljárnia akkor is, ha van olyan összevont adóalapba
              tartozó jövedelme, amelyre a négy vagy több gyermeket nevelő anyák
              kedvezményét nem tudja érvényesíteni, de a személyi kedvezményt,
              az első házasok kedvezményét, a családi kedvezményt vagy családi
              járulékkedvezményt igen.
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>Adatváltozáskor mit kell tenni?</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Ha a nyilatkozatban közölt adatok változnak,{" "}
              <b>Ön köteles haladéktalanul új nyilatkozatot tenni</b> – ezt az
              I. Blokk jobb felső sarkában{" "}
              <b>(a „Módosított nyilatkozat” kódkockában)</b> kell X-szel
              jelölnie.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Ha év közben új munkáltatónál helyezkedik el és új nyilatkozatot
              tesz, az <b>nem számít módosított nyilatkozatnak.</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A kedvezményről bővebb tájékoztatást olvashat a NAV honlapján az
              „Szja adóalap-kedvezmények” című 73. számú információs füzetben.
            </Typography>
            <Box height="25px" />
            <Typography variant="h6">
              <b>
                Külföldi magánszemély milyen feltételek mellett érvényesítheti a
                kedvezményt?
              </b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              Ön csak akkor érvényesítheti a négy vagy több gyermeket nevelő
              anyák kedvezményét, ha azonos vagy hasonló <b>kedvezményt</b>{" "}
              ugyanarra az időszakra <b>másik államban</b> – ahol önálló és nem
              önálló tevékenységéből, nyugdíjból és más, hasonló, a korábbi
              foglalkoztatásból származó jövedelme megadóztatható (figyelemmel a
              kettős adóztatás elkerüléséről szóló nemzetközi egyezmények
              rendelkezéseire is) - <b>nem vett és nem is vesz igénybe.</b>
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              A külföldi adóügyi illetőségű magánszemély a kedvezményt
              Magyarországon csak akkor érvényesítheti, ha az adóévben
              megszerzett összes jövedelmének – ideértve a Magyarországon nem
              adóztatható jövedelmet is –{" "}
              <b>75 százaléka Magyarországon adózik.</b> Az adóévben megszerzett
              összes jövedelembe beleszámít az önálló és nem önálló
              tevékenységből származó jövedelem – ideértve különösen a
              vállalkozói jövedelmet és a vállalkozói osztalékalapot vagy az
              átalányadó alapját – valamint a nyugdíj és más hasonló, a korábbi
              foglalkoztatásból megszerzett jövedelem, függetlenül attól, hogy
              mely országban adókötelesek.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b>
                Ha Ön külföldi adóügyi illetőségű, a „Kiegészítő nyilatkozat a
                külföldi adóügyi illetőségű magánszemélyek
                adóalap-kedvezményének érvényesítéséhez” nyilatkozatot is ki
                kell töltenie és a munkáltató, kifizető részére átadnia a
                kedvezményének igénybevételéhez. Adóazonosító jelet ebben az
                esetben is meg kell adnia.
              </b>
            </Typography>
          </Box>
          <Box height="16px" />
          <Box height="16px" />
          <Box className={classes.bgBox}>
            <Typography variant="h5" className={classes.titles}>
              <b>Az adóelőleg-nyilatkozat kitöltése</b>
            </Typography>
            <Box height="32px" />
            <Typography variant="body1">
              <b style={{ textDecoration: "underline" }}>I. blokk:</b> Ön ebben
              a blokkban nyilatkozik munkáltatójának arról, hogy érvényesíteni
              kívánja a négy vagy több gyermeket nevelő anyák kedvezményét.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b>Az 1. pontban</b> kell feltüntetni a kedvezmény igénybevételére
              jogosító gyermekek nevét és adóazonosító jelét. A{" "}
              <b>
                munkáltató, kifizető csak akkor tudja a kedvezményt figyelembe
                venni, ha legalább 4 gyermek adatait feltünteti.
              </b>{" "}
              Ha a gyermeknek nincs adóazonosító jele, akkor adja meg a gyermek
              születési helyét, idejét és az anyja nevét, még abban az esetben
              is, ha az megegyezik az Ön nevével.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b>A 2. pontban</b> nyilatkozik arról, hogy Ön jogosult a négy
              vagy több gyermeket nevelő anyák kedvezményére és igénybe kívánja
              azt venni.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b>A 3. pontban</b> kell jelölni, ha folytatólagos nyilatkozatot
              ad, vagyis ha szeretné, hogy munkáltatója, kifizetője a
              nyilatkozatát folyamatosan figyelembe vegye mindaddig, ameddig Ön
              nem kéri annak mellőzését. Az a négy vagy több gyermeket nevelő
              anya is kérheti a nyilatkozat adóéven túli figyelembevételét,
              akinek a jogosultsága nem végleges.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b>A 4. pontot</b> akkor töltse ki, ha a továbbiakban{" "}
              <b>nem kívánja</b> a négy vagy több gyermeket nevelő anyák
              kedvezményét <b>igénybe venni.</b> Ekkor a „Módosított
              nyilatkozat” kódkockát jelölje X-szel, és tüntesse fel azt a
              hónapot, amelyben a kedvezményt már nem kívánja igénybe venni. Ha
              ezt a pontot kitölti, akkor az 1. és 2. pontot nem kell
              kitöltenie.
            </Typography>
            <Box height="25px" />
            <Typography variant="body1">
              <b style={{ textDecoration: "underline" }}>II. blokk:</b> ezt a
              blokkot az Ön munkáltatójának, kifizetőjének kell kitöltenie.
            </Typography>
          </Box>
          <Box height="25px" />
        </Box>
      </Box>
      <OcPrivateLayoutFooter />
    </Box>
  );
};

export default FourChildrenTaxCreditInformation;
