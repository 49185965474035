import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    filterContainer: {
        backgroundColor: theme.palette.gray30.main,
        borderRadius: "8px",
        padding: "16px",
        maxWidth: "390px",
        fontSize: "10px",
    },
    openFilterContainer: {
        backgroundColor: theme.palette.gray30.main,
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        padding: "16px",
        maxWidth: "390px",
    },
    titleColor: {
        color: theme.palette.primary.main,
    },
}));

export default useStyles;
