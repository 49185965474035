import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./oc-time-date-counter.style";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function OcTimeDateCounter({
  time,
  timeloading,
  cssClasses,
  counterLabel,
}) {
  const classes = useStyles();

  return (
    <Box
      className={
        cssClasses === "leave"
          ? classes.counterContainer
          : classes.myTimeCounterContainer
      }
    >
      {timeloading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Typography
          className={
            cssClasses === "leave" ? classes.counter : classes.workTimeCounter
          }
        >
          {time}
        </Typography>
      )}

      <Typography className={classes.counterLabel}>{counterLabel}</Typography>
    </Box>
  );
}

OcTimeDateCounter.propTypes = {
  /** Count of work days between two dates*/
  workDays: PropTypes.number,
};
