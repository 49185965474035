import React from "react";
import { useTheme } from "@material-ui/styles";
import Box from "@material-ui/core/Box";

export default function OcDatePickerOkButton({ btText }) {
  const theme = useTheme();
  return (
    <Box
      bgcolor={theme.palette.primary.main}
      py="8px"
      px="22px"
      borderRadius="4px"
      style={{ color: "white" }}
    >
      {btText}
    </Box>
  );
}
