import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_MY_TIME_REQUEST } from "my-time/graphql/queries";
import { MY_TIME_REQUESTS_SUBSCRIPTION } from "my-time/graphql/subscriptions";

const useMyTimeRequest = ({ _id }) => {
  const { data, loading, error, refetch } = useQuery(GET_MY_TIME_REQUEST, {
    fetchPolicy: "cache-and-network",
    variables: {
      _id,
    },
  });

  useSubscription(MY_TIME_REQUESTS_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  return {
    data: data?.getMyTimeRequest || {},
    loading: loading && !data,
    error,
  };
};

export default useMyTimeRequest;
