import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const MyCafeteriaSignatureBlock = ({ statement }) => {
    const { t } = useTranslation();

    return (
        <Box
            id="lower-component"
            sx={{ display: "none", displayPrint: "block" }}
        >
            <Box
                id="signs"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                padding="0px 80px"
                marginBottom={statement === "vbk" ? "108px" : "0px"}
            >
                <Box display="flex" flexDirection="row">
                    <Typography
                        varian="body2"
                        style={{
                            fontWeight: "400",
                        }}
                    >
                        {t("DATEOFSIGN")}
                    </Typography>
                    <Box width="6px"></Box>
                    <Box
                        marginTop="16px"
                        height="1px"
                        width="147.67px"
                        style={{
                            backgroundColor: "#4d4d4d",
                        }}
                    />
                </Box>
                <Box display="flex" flexDirection="row" paddingTop="16px">
                    <Box
                        width="156px"
                        borderTop="1px solid #4d4d4d"
                        textAlign="center"
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontWeight: "400",
                            }}
                        >
                            {t("EMPLOYEESING")}
                        </Typography>
                    </Box>
                    <Box width="24px" />
                    <Box
                        width="156px"
                        borderTop="1px solid #4d4d4d"
                        textAlign="center"
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontWeight: "400",
                            }}
                        >
                            {t("FORTHEEMPLOYER")}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {statement === "szep" && (
                <Box paddingTop="50px" paddingBottom="40px">
                    <Typography
                        variant="body1"
                        style={{
                            paddingLeft: "67px",
                            paddingRight: "67px",
                        }}
                    >
                        {t("STATEINDUP")}
                    </Typography>
                </Box>
            )}{" "}
        </Box>
    );
};

export default MyCafeteriaSignatureBlock;
