import { atom } from "recoil";
import persistAtom from "./persist-atom";

export const notificationState = atom({
  key: "notificationState",
  default: {
    type: "",
    message: "",
  },
  effects_UNSTABLE: [persistAtom],
});
