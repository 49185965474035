import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItem";
import chevronDoubleRigth from "@iconify/icons-mdi/chevron-double-right";
import Chevron from "oc/components/oc-private-layout-sidebar-chevron";
import Fade from "@material-ui/core/Fade";
import useStyles from "./oc-private-layout-sidebar-submenu.style";

export default function OcPrivateLayoutSidebarSubmenu({
  id,
  label,
  translation,
  onMenuChange,
  narrowMenu,
  narrowMenuOpened,
}) {
  const { t } = useTranslation();
  let menuLabel = t(translation) || label;
  const classes = useStyles();
  return (
    <ListItem
      id={id}
      button
      className={classes.linkItem}
      onClick={() => onMenuChange(id)}
    >
      {!narrowMenuOpened ? (
        <Box minWidth="20px" maxWidth="20px">
          <Fade in={narrowMenu}>
            <Chevron icon={chevronDoubleRigth} color="#A6ADAF" />
          </Fade>
        </Box>
      ) : (
        <Box minWidth="20px" maxWidth="20px" />
      )}

      <ListItemText>{menuLabel}</ListItemText>
      <Chevron icon={chevronDoubleRigth} />
    </ListItem>
  );
}
