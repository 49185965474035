import React, { Fragment, useEffect, useState } from "react";
import { InlineIcon } from "@iconify/react";
import Button from "@material-ui/core/Button";
import emailIcon from "@iconify/icons-mdi/email";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import useClientState from "hooks/use-oc-client-state";
import { useTranslation } from "react-i18next";
import Loading from "oc/components/oc-loading";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/react-hooks";
import Validator from "fastest-validator";
import PublicLayoutLink from "my-hr/components/my-hr-public-layout-link";

const validator = new Validator();

const FORGOT_PASSWORD = gql`
  query forgotPassword($email: String!, $locale: String!) {
    forgotPassword(email: $email, locale: $locale) {
      status
      statusMessage
    }
  }
`;

export default function MyHrForgotPasswordForm({ onEmailSent }) {
  const { t, i18n } = useTranslation();
  const schema = {
    email: {
      type: "email",
      messages: {
        email: t("ERROR_MESSAGE_WRONG_EMAIL_FORMAT"),
        emailEmpty: t("ERROR_MESSAGE_EMAIL_IS_REQUIRED"),
      },
    },
  };
  const check = validator.compile(schema);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useClientState("loginEmailError", false);
  const [emailErrorText, setEmailErrorText] = useClientState(
    "loginEmailErrorText",
    ""
  );

  const [forgotPassword, { loading }] = useLazyQuery(FORGOT_PASSWORD, {
    fetchPolicy: "network-only",
    skip: emailError,
    onCompleted: (data) => {
      if (data?.forgotPassword) {
        onEmailSent(true);
      }
    },
    onError: (error) => {
      if (error?.graphQLErrors) {
        let errors = error?.graphQLErrors;
        errors.map((item) => {
          if (item.message === "Entity not found!") {
            setEmailErrorText(t("ERROR_MESSAGE_NO_USER_FOUND"));
            setEmailError(true);
          }
          return item;
        });
      }
    },
  });

  function handleForgotPassword(e) {
    e.preventDefault();
    let isValidated = check({ email });
    if (isValidated !== true) {
      isValidated.forEach((item) => {
        if (item.field === "email") {
          setEmailErrorText(item.message);
          setEmailError(true);
        }
      });
      return;
    }

    forgotPassword({ variables: { email, locale: i18n.language } });
  }

  useEffect(() => {
    function handleClear() {
      setEmail("");
      setEmailError(false);
      setEmailErrorText("");
    }
    return function cleanup() {
      handleClear();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Loading show={loading} />
      <form noValidate autoComplete="off" onSubmit={handleForgotPassword}>
        <Box paddingTop="32px" paddingBottom="18px">
          <TextField
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label={t("EMAIL")}
            variant="outlined"
            fullWidth
            error={emailError}
            onFocus={() => {
              setEmailErrorText("");
              setEmailError(false);
            }}
            helperText={emailError && emailErrorText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InlineIcon icon={emailIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box padding="16px 0px 0px 0px">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
          >
            {t("BUTTON_SEND")}
          </Button>
        </Box>
        <Box textAlign="center" paddingTop="18px">
          <PublicLayoutLink label={t("LINK_BACK_TO_LOGIN")} path="/" />
        </Box>
      </form>
    </Fragment>
  );
}
