import React from "react";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import useEmployees from "hooks/use-myhr-employees";
import Table from "oc/components/oc-data-table";
import { employees as tableDef } from "my-hr/configs/my-hr-table-defs";

export default function EmployeeList({
  tableId,
  tableData,
  setTableData,
  onSelectRow,
}) {
  const { t } = useTranslation();

  const { data, loading } = useEmployees({
    sortField: tableData?.sortField,
    sortDirection: tableData?.sortDirection,
    searchField: tableData?.searchField,
    searchText: tableData?.searchText,
    page: tableData?.page,
    pageSize: tableData?.pageSize,
  });

  return (
    <OcCard label={t("SELECT_EMPLOYEE")}>
      <Table
        data={data}
        loading={loading && data?.rows?.length === 0 ? true : false}
        tableDef={tableDef}
        tableData={tableData}
        setTableData={setTableData}
        onSelectRow={onSelectRow}
        tableId={tableId}
        pagination={true}
      />
    </OcCard>
  );
}
