import gql from "graphql-tag";

export const GET_TAX_DECLARATION_FAMILY = gql`
  query taxDeclarationFamily($_id: Int!) {
    taxDeclarationFamily(_id: $_id) {
      dateOfDeclaration
      isModifiedDeclaration
      state
      applyAlone
      applyTogether
      applyOnHungary
      noRequestRelief
      taxReliefAmount
      taxReliefNumberOfChild
      spouseName
      spouseTaxNumber
      spouseEmployerName
      spouseEmployerTaxNumber
      dependents {
        dependentSerialNumber
        taxNumber
        name
        dependentType
        entitlementTitle
        changeDate
      }
    }
  }
`;
