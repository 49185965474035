import React from "react";
import { Box } from "@material-ui/core";
import { InlineIcon } from "@iconify/react";
import useMyTimeIcon from "my-time/hooks/use-my-time-icon";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MyTimeCalendarMonthEvent = ({ event }) => {
  const { t } = useTranslation();
  const icon = useMyTimeIcon(event);
  let iconColor = "#212121";
  if (
    (event.myTimeType === "WORKING_TIME" &&
      event.state !== "approved" &&
      event.state !== "requested") ||
    event.myTimeType === "WORKING_TIME_ITEM" ||
    event.myTimeType === "STAND_BY" ||
    event.myTimeType === "LEAVE" ||
    event.myTimeType === "ABSENCE" ||
    event.myTimeType === "HOMEOFFICE" ||
    event.myTimeType === "ON_CALL" ||
    event.myTimeType === "OTHER"
  ) {
    iconColor = "#fff";
  }

  let myTimeTypeName = event.myTimeType;

  if (event.myTimeType === "OTHER") {
    myTimeTypeName = event.timeDataTypeName;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        style={{
          fontSize: "12px",
          padding: 0,
          margin: 0,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          paddingRight: "2px",
        }}
      >
        {t(myTimeTypeName)} {moment(event.startTime).format("HH:mm")} -{" "}
        {moment(event.endTime).format("HH:mm")}
      </Box>
      <InlineIcon
        icon={icon}
        width="20px"
        height="20px"
        style={{
          color: iconColor,
          minWidth: "20px",
        }}
      />
    </Box>
  );
};

export default MyTimeCalendarMonthEvent;
