import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/styles";
import { InlineIcon } from "@iconify/react";
import Typography from "@material-ui/core/Typography";
import accountIcon from "@iconify/icons-mdi/account";
import RoleChip from "my-hr/components/my-hr-role-chip";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const MyHrUserProfilesRolesItemHeader = ({
  role,
  product,
  showCollapseButton,
  onExpand,
  showCollapse,
}) => {
  const theme = useTheme();
  return (
    <Box
      height="48px"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding="0px 24px"
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <InlineIcon
          icon={accountIcon}
          height="32px"
          style={{
            color: theme.palette.gray80.main,
            marginRight: "24px",
          }}
        />
        <Typography variant="h5">{product}</Typography>
        <Box marginLeft="16px">
          <RoleChip role={role} />
        </Box>
      </Box>
      {showCollapseButton && (
        <IconButton
          id={product + role}
          onClick={onExpand}
          aria-expanded={showCollapse}
          aria-label="show more"
          style={
            showCollapse
              ? {
                  padding: 0,
                  transform: "rotate(180deg)",
                }
              : { padding: 0 }
          }
        >
          <ExpandMoreIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default MyHrUserProfilesRolesItemHeader;
