import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
//import { InlineIcon } from "@iconify/react";
//import pencilIcon from "@iconify/icons-mdi/pencil";
//import { useTheme } from "@material-ui/styles";
import useChangeRequests from "hooks/use-change-requests";
import useCurrentUserState from "hooks/use-current-user-state";
//import {crSelectedTypeAtom,showAddressEditAtom,showEmployeeNameAtom,} from "states";
//import { useSetRecoilState } from "recoil";
import useChangeRequestsExaminersName from "hooks/use-change-requests-examiners-name";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import { useTranslation } from "react-i18next";
import { CustomTooltip } from "my-hr/components/my-hr-applications-data-panel/components/dependents-collapse/dependents-collapse.style";

export function OcCardLabel({
  label,
  translation,
  editable,
  def,
  onEdit,
  isEditButtonDisabled,
}) {
  let { employeeCode } = useCurrentUserState();
  const { data } = useChangeRequests(employeeCode);

  //const theme = useTheme();

  //const setSelectedType = useSetRecoilState(crSelectedTypeAtom);
  //const setShowAddressEdit = useSetRecoilState(showAddressEditAtom);
  //const setShowEmployeeNameEdit = useSetRecoilState(showEmployeeNameAtom);

  //const handleShowDataEditDialog = (value) => {
  //  setSelectedType(def.address);
  // setShowAddressEdit(value);
  //};

  // const handleShowNameDataEditDialog = (value) => {
  //  setShowEmployeeNameEdit(value);
  //};

  const isExist = data?.find(
    (item) =>
      item?.typeGroup === "name" &&
      item?.approvedAt === null &&
      item?.rejectedAt === null
  );
  const isExistPermanentAddress = data?.find(
    (item) =>
      item?.typeGroup === "permanentAddress" &&
      item?.approvedAt === null &&
      item?.rejectedAt === null
  );
  const isExistTemporaryAddress = data?.find(
    (item) =>
      item?.typeGroup === "temporaryAddress" &&
      item?.approvedAt === null &&
      item?.rejectedAt === null
  );
  const isExistServiceAddress = data?.find(
    (item) =>
      item?.typeGroup === "serviceAddress" &&
      item?.approvedAt === null &&
      item?.rejectedAt === null
  );

  //const handleOnClick = () => {
  //if (isEditButtonDisabled) {
  //  return;
  // }
  // onEdit(def?.address);
  //};

  const { t, i18n } = useTranslation();

  let { contractCode } = useCurrentUserState();

  const { data: examinersData } = useChangeRequestsExaminersName({
    locale: i18n.language,
    contract: contractCode,
  });

  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const isExaminer = rolesData?.find(
    (item) => item.product === "MyHR" && item.role === "examiner"
  )
    ? true
    : false;

  const hasExaminer = examinersData?.length > 0;

  let isDisabled = !hasExaminer;
  if (isExaminer && !hasExaminer) {
    isDisabled = false;
  }

  if (isDisabled) {
    editable = false;
  }

  if (isExaminer) {
    isEditButtonDisabled = false;
  }

  return (
    <>
      <Box
        style={{
          fontSize: "20px",
          letterSpacing: "0.15px",
          padding: def?.address ? "unset" : "0px 16px",
          color: "#5E6366",
          width: def?.address ? "100%" : "unset",
        }}
      >
        <Box
          style={{
            fontSize: "20px",
            letterSpacing: "0.15px",
            padding: def?.address ? "unset" : "10px 26px",
            color: "#5E6366",
            borderBottom: def?.address ? "" : "2px solid #C6CACC",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            className="oc-card-h6-title"
            variant="h6"
            style={{ flexGrow: "1" }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {label}
              {editable &&
                (isExist === undefined ||
                isExistPermanentAddress === undefined ||
                isExistServiceAddress === undefined ||
                isExistTemporaryAddress === undefined ? (
                  <Box

                  //onClick={handleOnClick}
                  ></Box>
                ) : (
                  <Box
                  //onClick={() => def?.address ? handleShowDataEditDialog(false) : handleShowNameDataEditDialog(false)}
                  ></Box>
                ))}
              {isDisabled && (
                <CustomTooltip
                  title={t("NOT_SET_EXAMINER_INFO")}
                  placement="top"
                >
                  <Box></Box>
                </CustomTooltip>
              )}
            </Box>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default OcCardLabel;
