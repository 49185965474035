import React from "react";
import Box from "@material-ui/core/Box";

export default function MyHrPayrollYearSelector({
    year,
    onSelectYear,
    contract,
}) {
    let currentYear = new Date().getFullYear();
    let requestedYear = new Date(year).getFullYear();

    let showNextYear = requestedYear < currentYear;
    let previousYear = requestedYear - 1;
    let nextYear = requestedYear + 1;

    let firstAvailableYear = new Date(contract?.startOfContract).getFullYear();
    let showPreviousYear = previousYear >= firstAvailableYear;

    return (
        <Box
            id={year}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            paddingRight="48px"
            marginTop="9px"
        >
            {showPreviousYear && (
                <Box
                    id="payroll-earlier-year"
                    style={{
                        cursor: "pointer",
                        fontSize: "14px",
                        letterSpacing: "0.75px",
                        color: "#919699",
                    }}
                    onClick={() => onSelectYear(previousYear.toString())}
                >
                    {previousYear}
                </Box>
            )}
            <Box
                style={{
                    marginLeft: "54px",
                    fontSize: "14px",
                    letterSpacing: "0.75px",
                }}
            >
                {year}
            </Box>
            {showNextYear && (
                <Box
                    style={{
                        marginLeft: "54px",
                        fontSize: "14px",
                        letterSpacing: "0.75px",
                        color: "#919699",
                        cursor: "pointer",
                    }}
                    onClick={() => onSelectYear(nextYear.toString())}
                >
                    {nextYear}
                </Box>
            )}
        </Box>
    );
}
