import React, { useRef, useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import LeaveChartLegend from "my-leave/components/my-leave-chart-legend";
import LeaveChartSummary from "my-leave/components/my-leave-chart-summary";
import LeaveChartSummaryLoading from "my-leave/components/my-leave-chart-summary-loading";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./my-leave-chart.style";

export default function LeaveChart({
  chartSize,
  dataSet,
  summaryLabel,
  summaryType,
  summaryValue = 0,
  loading,
}) {
  const theme = useTheme();
  const data = {
    labels: dataSet.map((item) => item.label),
    datasets: [
      {
        data: dataSet.map((item) => item.value),
        backgroundColor: dataSet.map((item) => item.color),
        hoverBackgroundColor: dataSet.map((item) => item.color),
      },
    ],
  };

  const dataLoading = {
    labels: ["1"],
    datasets: [
      {
        data: ["1"],
        backgroundColor: [theme.palette.gray30.main],
        hoverBackgroundColor: [theme.palette.gray30.main],
        hoverBorderWidth: [theme.palette.gray30.main],
      },
    ],
  };

  let width = chartSize;
  let centerTextLeft = chartSize / 2 - 43;
  let centerTextTop = chartSize / 2 - 24;

  let legendWidth = 260;

  const componentRef = useRef();
  const [containerWidth, setContainerWidth] = useState(0);

  useLayoutEffect(() => {
    if (componentRef.current) {
      setContainerWidth(
        componentRef.current.offsetWidth > 420
          ? componentRef.current.offsetWidth
          : 420
      );
    }
  }, []);

  const classes = useStyles({
    containerWidth,
    legendWidth,
    width,
    chartSize,
    centerTextLeft,
    centerTextTop,
  });

  return (
    <div
      id="my-leave-full-chart"
      ref={componentRef}
      className={classes.chartContainerRoot}
    >
      <div className={classes.chartContainer}>
        <Doughnut
          data={loading ? dataLoading : data}
          legend={null}
          width={width}
          height={chartSize}
          options={{
            cutoutPercentage: 72,
            maintainAspectRatio: true,
            tooltips: { enabled: true },
            animation: {
              duration: loading ? 0 : 1000,
            },
          }}
        />
        {loading ? (
          <LeaveChartSummaryLoading
            centerTextLeft={centerTextLeft}
            centerTextTop={centerTextTop}
          />
        ) : (
          <LeaveChartSummary
            summaryValue={summaryValue}
            summaryLabel={summaryLabel}
            summaryType={summaryType}
            centerTextLeft={centerTextLeft}
            centerTextTop={centerTextTop}
          />
        )}
      </div>

      <div className={classes.legendMainContainer}>
        {dataSet.map((item, index) => (
          <LeaveChartLegend
            key={index + item}
            index={index}
            item={item}
            listCount={dataSet.length}
            loading={loading}
          ></LeaveChartLegend>
        ))}
      </div>
    </div>
  );
}

LeaveChart.propTypes = {
  /** Size of the chart element. This is required, because other element sizes are counted from this value */
  chartSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Summary labal. It is shown in the center of the chart upper side. */
  summaryLabel: PropTypes.string,
  /** Summary type. It is shown in in the center of the chart upper side. */
  summaryType: PropTypes.string,
  /** Loading state of the component */
  loading: PropTypes.bool,
  /** Data in object array format */
  dataSet: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })
  ),
};

LeaveChart.defaultProps = {
  chartSize: 216,
  summaryLabel: "summary",
  summaryType: "type",
  loading: false,
  dataSet: [
    {
      label: "type 1",
      value: 18,
      color: "#CC0033",
    },
    {
      label: "type 2",
      value: 11,
      color: "#F3AA18",
    },
    {
      label: "type 3",
      value: 6,
      color: "#2FA84F",
    },
  ],
};
