import React from "react";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import clockIcon from "@iconify/icons-mdi/clock";
import { useHistory } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMyTimeRequestCount from "my-time/hooks/use-my-time-request-count";

const StyledBadge = withStyles((theme) => ({
  badge: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "2px",
    fontFamily: "Roboto",
    fontSize: "10px",
    minWidth: "16px",
    width: (props) => (props?.badgeContent > 99 ? "20px" : "16px"),
    height: "16px",
    paddingTop: "2px",
    fontWeight: 500,
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    padding: "6px",
  },
  icon: {
    color: theme.palette.gray80.main,
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
}));

export default function MyTimeAppbarBadge() {
  const history = useHistory();
  const classes = useStyles();

  const { data } = useMyTimeRequestCount();
  //if (!data) return null;

  function handleClick() {
    history.push("/my-time/approve-requests");
  }

  let count = data;

  if (count === 0) return null;

  return (
    <Box onClick={handleClick} className={classes.root}>
      <StyledBadge
        id="my-leave-header-badge-count"
        badgeContent={count}
        max={99}
      >
        <InlineIcon
          id="my-leave-header-badge"
          icon={clockIcon}
          className={classes.icon}
        />
      </StyledBadge>
    </Box>
  );
}
