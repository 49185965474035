import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const calculatedBenefitSate = atom({
  key: "calculatedBenefitsSate",
  default: {
    benefit: 0,
    cost: 0,
  },
  effects_UNSTABLE: [persistAtom],
});
