import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";

export default function MyHrRoleChip({ role }) {
  const { t } = useTranslation();
  let borderColor = "#367FA9";
  switch (role) {
    case "user":
      borderColor = "#367FA9";
      break;
    case "admin":
      borderColor = "#CC0033";
      break;
    default:
      borderColor = "#367FA9";
      break;
  }

  return (
    <Box
      style={{
        border: `1px solid ${borderColor}`,
        borderRadius: "12px",
        padding: "1px 12px",
        color: "#5E6366",
        fontSize: "14px",
        letterSpacing: "0.25px",
      }}
    >
      {t(`ROLE_${role.toUpperCase()}`)}
    </Box>
  );
}
