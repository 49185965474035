import React from "react";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@iconify/icons-mdi/close";
import InputBase from "@material-ui/core/InputBase";

export default function HeaderSearchForm({
  id,
  onSearchChange,
  searchText,
  searchField,
}) {
  if (searchField !== id) return null;

  function handleChange(e) {
    onSearchChange({ searchField: id, searchText: e.target.value });
  }

  function onKeyDown(e) {
    if (e.key === "Escape") {
      onSearchChange({ searchField: "", searchText: "" });
    }
  }

  return (
    <Box
      id="search-form"
      position="absolute"
      height="48px"
      top="0px"
      left="0px"
      display="flex"
      flexDirection="row"
      justifyContent="strech"
      alignItems="center"
      flexGrow={1}
      width="100%"
    >
      <InputBase
        value={searchText}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        autoFocus={true}
        endAdornment={
          <InputAdornment position="end">
            <InlineIcon
              icon={CloseIcon}
              onClick={() =>
                onSearchChange({ searchField: "", searchText: "" })
              }
              style={{ cursor: "pointer" }}
            />
          </InputAdornment>
        }
        style={{
          backgroundColor: "white",
          height: "32px",
          paddingLeft: "16px",
          paddingRight: "16px",
          borderRadius: "4px",
          //marginRight: "16px",
          width: "100%",
        }}
      />
    </Box>
  );
}
