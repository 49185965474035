import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  accountIcon: {
    color: theme.palette.owGray.main,
    overflow: "hidden",
  },
}));

export default useStyles;
