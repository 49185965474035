import React from "react";
import ApplicationWrapper from "my-hr/components/my-hr-applications-data-panel/components/application-wrapper/application-wrapper";
import MyPersonalTaxCreditApplication from "my-hr/components/my-hr-applications-data-panel/components/my-personal-tax-credit-application";
import FirstMarriageTaxCreditApplication from "my-hr/components/my-hr-applications-data-panel/components/first-marriage-tax-credit-application";
import FourChildrenTaxCreditApplication from "my-hr/components/my-hr-applications-data-panel/components/four-children-tax-credit-application";
import FamilyTaxCreditApplication from "my-hr/components/my-hr-applications-data-panel/components/family-tax-credit-application";
import getFullName from "oc/utils/get-fullname";
import UnderTwentyFiveTaxCreditApplication from "my-hr/components/my-hr-applications-data-panel/components/under-twenty-five-tax-credit-application";
import UnderThirtyMothersTaxCreditApplication from "my-hr/components/my-hr-applications-data-panel/components/under-thirty-mothers-tax-credit-application";
import { REMOVE_TAX_DECLARATIONS } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import useOcSnackbar from "hooks/use-oc-snackbar";
import useTaxApplications from "hooks/use-tax-applications";

export default function MyHrTaxCreditApplications({
  label,
  employee,
  taxApplicationSaveEnabled,
  requestState,
  selected,
  setSelected,
  resetSelectedState,
}) {
  const { t } = useTranslation();
  const fullName = getFullName(employee) || "";
  const taxNumber = employee?.taxNumber || "";

  const { setOpenMessage } = useOcSnackbar();
  const [removeDeclaration] = useMutation(REMOVE_TAX_DECLARATIONS, {
    onCompleted: (data) => {
      const res = data?.removeTaxDeclarationRequest;
      if (res?.result) {
        if (res?.messages?.length > 0) {
          const message = res?.messages[0];
          if (message === "A korábban beadott nyilatkozata törlésre került!") {
            setOpenMessage({
              type: "success",
              message: t("TAX_DECLARATION_REMOVED_SUCCESSFULLY"),
            });
            setOpenMessage({
              type: "warning",
              message: t("PREVIOUS_TAX_DECLARATION_DELETED"),
            });
          }
        } else {
          setOpenMessage({
            type: "success",
            message: t("TAX_DECLARATION_REMOVED_SUCCESSFULLY"),
          });
        }
        resetSelectedState();
      } else {
        setOpenMessage({
          type: "error",
          message: res?.messages || t("TAX_DECLARATION_REMOVED_FAILED"),
        });
      }
    },
    onError: (error) => {
      setOpenMessage({
        type: "error",
        message: t("TAX_DECLARATION_REMOVED_FAILED"),
      });
    },
  });

  const { resetTaxApplications } = useTaxApplications();

  const handleRemoveRequest = () => {
    const params = {
      year: selected?.year,
      taxDeclarationType: selected?.type,
    };
    removeDeclaration({ variables: params });
    resetTaxApplications();
    resetSelectedState();
  };

  switch (selected?.type) {
    case "TaxDeclarationPersonal":
      return (
        <MyPersonalTaxCreditApplication
          fullName={fullName}
          taxNumber={taxNumber}
          taxApplicationSaveEnabled={taxApplicationSaveEnabled}
          handleRemoveRequest={handleRemoveRequest}
          requestState={requestState}
          selected={selected}
          setSelected={setSelected}
        />
      );
    case "TaxDeclarationFirstMarriedPerson":
      return (
        <FirstMarriageTaxCreditApplication
          fullName={fullName}
          taxNumber={taxNumber}
          taxApplicationSaveEnabled={taxApplicationSaveEnabled}
          handleRemoveRequest={handleRemoveRequest}
          requestState={requestState}
          selected={selected}
          setSelected={setSelected}
        />
      );
    case "TaxDeclarationFourChildren":
      return (
        <FourChildrenTaxCreditApplication
          fullName={fullName}
          taxNumber={taxNumber}
          taxApplicationSaveEnabled={taxApplicationSaveEnabled}
          handleRemoveRequest={handleRemoveRequest}
          requestState={requestState}
          selected={selected}
          setSelected={setSelected}
        />
      );
    case "TaxDeclarationFamily":
      return (
        <FamilyTaxCreditApplication
          fullName={fullName}
          taxNumber={taxNumber}
          taxApplicationSaveEnabled={taxApplicationSaveEnabled}
          handleRemoveRequest={handleRemoveRequest}
          requestState={requestState}
          selected={selected}
          setSelected={setSelected}
        />
      );
    case "TaxDeclarationUnder25":
      return (
        <UnderTwentyFiveTaxCreditApplication
          fullName={fullName}
          taxNumber={taxNumber}
          taxApplicationSaveEnabled={taxApplicationSaveEnabled}
          handleRemoveRequest={handleRemoveRequest}
          requestState={requestState}
          selected={selected}
          setSelected={setSelected}
        />
      );
    case "TaxDecarationMotherUnder30":
      return (
        <UnderThirtyMothersTaxCreditApplication
          fullName={fullName}
          taxNumber={taxNumber}
          taxApplicationSaveEnabled={taxApplicationSaveEnabled}
          handleRemoveRequest={handleRemoveRequest}
          requestState={requestState}
          selected={selected}
          setSelected={setSelected}
        />
      );
    default:
      return <ApplicationWrapper label={label} />;
  }
}
