import { atom } from "recoil";
import persistAtom from "states/persist-atom";

export const newCafeteriaStatementItemState = atom({
  key: "newCafeteriaStatementItem",
  default: {
    index: 0,
    contractCode: "",
    currentItemStartDate: "", // YYYYMMDD format
    menuItemCode: "",
    startDate: "", // in MM fromat
    endDate: "", // in MM format
    type: "",
    amount: "",
    bruttoAmount: "",
    benefitType: "",
    szepCardApplicationAmount: "",
  },
  effects_UNSTABLE: [persistAtom],
});
