import React from "react";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Currency from "oc/components/oc-currency";
import {
  useStyles,
  useStylesBootstrap,
} from "./my-hr-payroll-yearly-data-tooltip.style";

export default function PayrollTooltip({ item, ...props }) {
  const { t, i18n } = useTranslation();
  const classes = useStylesBootstrap();

  function Title({ item }) {
    const classes = useStyles();
    const monthName = moment(`2020-${item.month}`)
      .locale(i18n.language)
      .format("MMMM");
    return (
      <Box color="#fff" borderRadius="8px" padding="12px 0px">
        <Box textAlign="center" className={classes.modalTitle}>
          {monthName}
        </Box>
        <Box className={classes.modalContent}>
          <Box pr="16px">{t("NET_WAGE")}</Box>
          <Box>
            <Currency value={item.netWage} />
          </Box>
        </Box>
      </Box>
    );
  }

  return <Tooltip classes={classes} title={<Title item={item} />} {...props} />;
}
