import gql from "graphql-tag";

export const CREATE_MY_TIME_REQUEST = gql`
  mutation createMyTimeRequest(
    $contractCode: String!
    $myTimeType: String!
    $startTime: Date!
    $endTime: Date!
    $hours: String!
    $state: String!
    $timeDataTypeCode: String!
    $timeDataTypeName: String!
    $message: String
  ) {
    createMyTimeRequest(
      contractCode: $contractCode
      myTimeType: $myTimeType
      startTime: $startTime
      endTime: $endTime
      hours: $hours
      state: $state
      timeDataTypeCode: $timeDataTypeCode
      timeDataTypeName: $timeDataTypeName
      message: $message
    ) {
      _id
    }
  }
`;
