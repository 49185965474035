import gql from "graphql-tag";

export const GET_CONTRACTS_BY_EMPLOYEE_BY_PERIOD = gql`
  query contractsByEmployeeByPeriod($employee: String!, $startDate: String!, $endDate: String!) {
    contractsByEmployeeByPeriod(employee: $employee, startDate: $startDate, endDate: $endDate) {
      _id
      code
      employee {
        code
      }
      company {
        _id
        code
        name
        taxNumber
        companyLogo
      }
      orgUnit {
        code
        name
      }
      jobClass {
        code
        name
      }
      jobClassGroup {
        code
        name
      }
      payOffice {
        code
        name
      }
      workingPlace {
        code
        name
      }
      typeOfContract {
        code
        name
      }
      shiftType {
        code
        name
      }
      employmentType {
        code
        name
      }
      contractTerminationType {
        code
        name
      }
      maxOvertimeInHour
      roles
      validFrom
      validTo
      startOfContract
    }
  }
`;
