import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import useStyles from "./oc-data-table-body-cell-state.style";

export default function State({ label }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const getColor = (label) => {
    switch (label) {
      case "requested":
        return "#F3AA18";
      case "approved":
        return "#2FA84F";
      case "recorded":
        return "#2FA84F";
      case "rejected":
        return theme.palette.primary.main;
      case "invalidated":
        return theme.palette.primary.main;
      default:
        return "white";
    }
  };

  const classes = useStyles({ borderColor: getColor(label) });

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Typography className={classes.text} variant="body2">
        {t(`Leave.${label}`)}
      </Typography>
    </Box>
  );
}
