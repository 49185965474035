import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  toolbarMainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    paddingBottom: 32,
    fontSize: 20,
    letterSpacing: "0.15px",
    color: theme.palette.gray80.main,
  },
  centerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    paddingTop: 2,
    minWidth: 164,
    textAlign: "center",
    textTransform: "capitalize",
  },
  leftChevron: {
    paddingTop: 1,
    paddingRight: 8,
    boxSizing: "border-box",
    "&:hover": {
      cursor: "pointer",
    },
  },
  month: {
    textTransform: "capitalize",
    marginLeft: 6,
  },
  rightChevron: {
    paddingTop: 1,
    paddingLeft: 8,
    boxSizing: "border-box",
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    color: theme.palette.gray80.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconDisabled: {
    color: theme.palette.primary.main,
  },
  disabled: {
    color: theme.palette.gray40.main,
  },
}));

export default useStyles;
