import React from "react";
import Box from "@material-ui/core/Box";
import { Chart, Line } from "react-chartjs-2";
import moment from "moment";
import { useTranslation } from "react-i18next";

const LineChartPlugin = {
  afterDatasetsDraw: function (chart) {
    const ctx = chart.ctx;
    chart.data.datasets.forEach(function (dataset, i) {
      const meta = chart.getDatasetMeta(i);

      if (!meta.hidden) {
        meta.data.forEach(function (element, index) {
          ctx.fillStyle = "#fff";
          const fontSize = 10;
          const fontStyle = "normal";
          const letterSpacing = "0.27px";
          const fontFamily =
            "Mulish, Roboto, Helvetica Neue, Arial, sans-serif";
          ctx.font = Chart.helpers.fontString(
            fontSize,
            fontStyle,
            fontFamily,
            letterSpacing
          );

          let dataString = new Intl.NumberFormat("hu").format(
            dataset.data[index]
          );

          // Make sure alignment settings are correct
          if (dataset.data[index] > 0) {
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            const padding = 8;
            const startY = 24;
            const position = element.tooltipPosition();
            ctx.fillText(dataString, position.x, startY);

            ctx.save();

            ctx.beginPath();
            ctx.lineWidth = 1;
            ctx.setLineDash([5, 3]);
            ctx.moveTo(position.x, startY + padding);
            ctx.lineTo(position.x, position.y - padding);
            ctx.strokeStyle = "#fff";
            ctx.stroke();

            ctx.restore();
          }
        });
      }
    });
  },
};

export default function MyHrPayrollLineChart({ data }) {
  const { i18n } = useTranslation();

  let labels = [];
  let chartData = [];
  let maxValue = 0;

  if (data) {
    data.forEach((item) => {
      const monthName = moment(`2020-${item.month}`)
        .locale(i18n.language)
        .format("MMM")
        .toUpperCase()
        .replace(".", "");
      labels.push(monthName);
      chartData.push(item.netWage === 0 ? 0 : item.netWage);
      if (item.netWage > maxValue) {
        maxValue = item.netWage;
      }
    });
  }

  return (
    <Box height="280px" marginTop="16px">
      <Line
        data={{
          chartType: "line",
          labels: labels,
          datasets: [
            {
              label: "Net salary: ",
              data: chartData,
              backgroundColor: "#cc0033",
              borderColor: "#cc0033",
              borderWidth: 3,
              pointBackgroundColor: "#cc0033",
              pointHoverBackgroundColor: "#cc0033",
              pointBorderColor: "#fff",
              pointHoverBorderColor: "#fff",
              pointBorderWidth: 3,
            },
          ],
        }}
        plugins={[LineChartPlugin]}
        options={{
          spanGaps: true,
          tooltips: {
            enabled: false,
          },
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          layout: {
            padding: {
              top: 32,
              left: 32,
              right: 32,
            },
          },
          elements: {
            point: {
              radius: 6,
              borderWidth: 3,
              hoverRadius: 8,
              hoverBorderWidth: 2,
              hitRadius: 60,
            },
            line: {
              tension: 0,
            },
          },
          plugins: {
            filler: {
              propagate: false,
            },
            xLabelsOnTop: {
              active: true,
            },
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false,
                  tickMarkLength: 18,
                },
                ticks: {
                  fontColor: "#fff",
                  fontSize: 14,
                  letterSpacing: 0.75,
                },
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  min: 0,
                  max: maxValue + maxValue * 0.3,
                  stepSize: 10000,
                },
              },
            ],
          },
        }}
      />
    </Box>
  );
}
