import { useTranslation } from "react-i18next";

export default function Currency({ value }) {
  const { i18n } = useTranslation();
  let currency = new Intl.NumberFormat(i18n.language, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
    currency: "HUF",
  }).format(value);
  return currency;
}
