import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 94,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sideContainer: {
    width: 60,
  },
  text: {
    fontSize: "60px",
    color: theme.palette.white.main,
  },
  iconContainer: {
    cursor: "pointer",
    width: 40,
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.white.main,
    height: 24,
    width: 24,
  },
}));

export default useStyles;
