import React, { useState } from "react";
import {
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import ButtonBar from "my-leave/components/my-leave-email-card/components/button-bar/button-bar";
import useOcSnackbar from "hooks/use-oc-snackbar";

const MyTimeTimePeriodSettingsCard = () => {
  const { t } = useTranslation();

  const { setOpenMessage } = useOcSnackbar();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [timePeriod, setTimePeriod] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const { data } = useProperty({
    resource: "myTime",
    key: "timePeriod",
    onCompleted: ({ value }) => {
      const newTimePeriod = parseInt(value);
      setTimePeriod(newTimePeriod);
      if (timePeriod !== newTimePeriod) {
        setDisabled(false);
      }
    },
  });

  const [saveProperty] = useMutation(UPDATE_PROPERTY, {
    onError({ ...err }) {
      console.log(err);
      setDisabled(false);
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
    onCompleted(data) {
      const newTimePeriod = parseInt(data.updateProperty.value);
      setTimePeriod(newTimePeriod);
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
  });

  const handleCancel = () => {
    setDisabled(true);
    setTimePeriod(parseInt(data));
  };

  const handleSave = () => {
    setDisabled(true);
    saveProperty({
      variables: {
        resource: "myTime",
        key: "timePeriod",
        value: timePeriod.toString(),
      },
    });
    setShowConfirmDialog(false);
  };

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  const handleTimePeriodChange = (event) => {
    if (parseInt(event.target.value) !== parseInt(data)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setTimePeriod(parseInt(event.target.value));
  };

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />

      <OcCard label={t("MY_TIME_TIME_PERIOD_SETTINGS")}>
        <Box
          padding="8px 0px 24px 0px"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <RadioGroup value={timePeriod} onChange={handleTimePeriodChange}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
            >
              <Box width="200px" textAlign="right">
                <Typography variant="caption" style={{ paddingRight: "16px" }}>
                  {t("NO_TIME_PERIOD")}
                </Typography>
              </Box>
              <Box width="450px">
                <FormControlLabel
                  value={0}
                  control={<Radio id="no-time-period" />}
                  label={
                    <Typography variant="caption">
                      {t("NO_TIME_PERIOD_INFO")}
                    </Typography>
                  }
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box width="200px" textAlign="right">
                <Typography variant="caption" style={{ paddingRight: "16px" }}>
                  {t("10_MINUTES_TIME_PERIOD")}
                </Typography>
              </Box>
              <Box width="450px">
                <FormControlLabel
                  width="200px"
                  value={10}
                  control={<Radio id="10-minutes-time-period" />}
                  style={{ paddingRight: "16px" }}
                  label={
                    <Typography variant="caption">
                      {t("10_MINUTES_TIME_PERIOD_INFO")}
                    </Typography>
                  }
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box width="200px" textAlign="right">
                <Typography variant="caption" style={{ paddingRight: "16px" }}>
                  {t("30_MINUTES_TIME_PERIOD")}
                </Typography>
              </Box>
              <Box width="450px">
                <FormControlLabel
                  value={30}
                  control={<Radio id="30-minutes-time-period" />}
                  label={
                    <Typography variant="caption">
                      {t("30_MINUTES_TIME_PERIOD_INFO")}
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </RadioGroup>
        </Box>
        <ButtonBar
          handleCancel={handleCancel}
          handleSave={() => setShowConfirmDialog(true)}
          disabled={disabled}
        />
      </OcCard>
    </>
  );
};

export default MyTimeTimePeriodSettingsCard;
