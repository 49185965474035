import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import informationOutlineIcon from "@iconify/icons-mdi/information-outline";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyHrForgotPasswordDialogSSO({
  showDialog,
  smallScreen,
  onShowDialog,
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  function handleCloseDialog() {
    onShowDialog(false);
  }

  return (
    <Dialog
      open={showDialog}
      scroll={"body"}
      fullScreen={smallScreen}
      TransitionComponent={Transition}
      BackdropProps={{
        style: {
          width: "100%",
          backgroundImage: "url(/img/loginBg.jpg)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="inherit"
      >
        <Box width={smallScreen ? "auto" : "400px"} padding="24px">
          <Box textAlign="center" p={0} marginTop="14px">
            <InlineIcon
              icon={informationOutlineIcon}
              width="64px"
              height="64px"
              color={theme.palette.gray80.main}
            />
          </Box>
          <Box textAlign="center" marginTop="30px">
            <Typography variant="h6">
              {t("TITLE_FORGOT_PASSWORD_IS_NOT_AVAILABLE")}
            </Typography>
            <Box
              height="88px"
              marginTop="26px"
              marginBottom="16px"
              style={{ textAlign: "left" }}
            >
              <Typography>
                {t("TEXT_FORGOT_PASSWORD_IS_NOT_AVAILABLE")}
              </Typography>
            </Box>
          </Box>
          <Box textAlign="center" paddingTop="26px" paddingBottom="6px">
            <Box
              onClick={handleCloseDialog}
              style={{
                textDecoration: "none",
                fontSize: 16,
                letterSpacing: 0.44,
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            >
              {t("LINK_BACK_TO_LOGIN")}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
