import React from "react";
import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import useProperty from "hooks/use-oc-property";
import Link from "@material-ui/core/Link";

export default function MyHrHelpdeskContact() {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data: helpdeskEmail } = useProperty({
    resource: "system",
    key: "helpdeskEmail",
  });

  const { data: helpdeskPhone } = useProperty({
    resource: "system",
    key: "helpdeskPhone",
  });

  const { data: helpdeskURL } = useProperty({
    resource: "system",
    key: "helpdeskURL",
  });

  const { data: helpdeskCustomText } = useProperty({
    resource: "system",
    key: "helpdeskCustomText",
  });

  let mailto = `mailto:${helpdeskEmail}`;

  let tel = `tel:${"+36" + helpdeskPhone}`;

  return (
    <Box padding="0px 48px" style={{ lineBreak: "auto" }}>
      <Typography variant="body1" style={{ color: theme.palette.gray80.main }}>
        {helpdeskCustomText?.length === 0 ||
        helpdeskCustomText === null ||
        helpdeskCustomText === ""
          ? t("HELPDESK_CONTACTS_INFO")
          : helpdeskCustomText}
      </Typography>
      <Box height="4px" />
      {helpdeskPhone?.length !== undefined && helpdeskPhone?.length !== 0 && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="body1"
            style={{ color: theme.palette.gray80.main }}
          >
            {t("PHONE")}:
          </Typography>
          <Box width="5px" />
          <Link
            style={{ cursor: "pointer", fontSize: "16px" }}
            to="#"
            onClick={(e) => {
              window.location.href = tel;
              e.preventDefault();
            }}
          >
            {"+36" + helpdeskPhone || ""}
          </Link>
        </Box>
      )}
      <Box height="4px" />
      {helpdeskURL?.length !== undefined && helpdeskURL?.length !== 0 && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box width="5px" />
          <Link
            href={"https://" + helpdeskURL}
            target="_blank"
            rel="noreferrer"
          >
            {helpdeskURL || ""}
          </Link>
        </Box>
      )}
      <Box height="4px" />
      {helpdeskEmail?.length !== undefined && helpdeskEmail?.length !== 0 && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="body1"
            style={{ color: theme.palette.gray80.main }}
          >
            {t("EMAIL")}:
          </Typography>
          <Box width="5px" />
          <Link
            style={{ cursor: "pointer", fontSize: "16px" }}
            to="#"
            onClick={(e) => {
              window.location.href = mailto;
              e.preventDefault();
            }}
          >
            {helpdeskEmail || ""}
          </Link>
        </Box>
      )}{" "}
    </Box>
  );
}
