import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

export default function OcTextInputField({
  label,
  value,
  setValue,
  error,
  setError,
  helperText,
  setHelperText,
  optional,
  defaultValue,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setValue("");
      setError(false);
      setHelperText("");
    };
    // eslint-disable-next-line
  }, []);

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      value={value}
      defaultValue={defaultValue}
      onChange={e => setValue(e.target.value)}
      error={error}
      helperText={optional ? t("OPTIONAL") : helperText}
      onFocus={() => {
        setError(false);
        setHelperText("");
      }}
    />
  );
}
