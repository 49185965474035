import gql from "graphql-tag";

export const REMOVE_TAX_DECLARATIONS = gql`
  mutation removeTaxDeclarationRequest($year: String!, $taxDeclarationType: String!) {
    removeTaxDeclarationRequest(year: $year, taxDeclarationType: $taxDeclarationType) {
      result
      messages
    }
  }
`;
