import gql from "graphql-tag";

export const GET_FILTERED_COMPANIES = gql`
  query filteredCompanies(
    $searchField: String
    $searchText: String
    $sort: String
    $page: Int
    $pageSize: Int
    $companies: [String]
    $product: String
    $role: String
    $showAllowedEntities: Boolean
  ) {
    filteredCompanies(
      searchField: $searchField
      searchText: $searchText
      sort: $sort
      page: $page
      pageSize: $pageSize
      companies: $companies
      product: $product
      role: $role
      showAllowedEntities: $showAllowedEntities
    ) {
      availableList {
        _id
        code
        name
      }
      selectedList {
        _id
        code
        name
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
