import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_WORK_DAYS } from "graphql/queries";
import { WORK_DAYS_SUBSCRIPTION } from "graphql/subcriptions";
import usePolling from "hooks/use-polling";
import moment from "moment";

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export default function useWorkDays({ contract, startDate, endDate }) {
  let skip = !contract || !isValidDate(startDate) || !isValidDate(endDate);
  const leaveStartYear = moment(startDate).format("YYYY");
  const leaveEndYear = moment(endDate).format("YYYY");
  let nextYear = null;
  let parsedStartDate = startDate;
  let parsedEndDate = endDate;
  let startOfNextYear;
  let endCurrentOfYear;
  if (leaveEndYear > leaveStartYear) {
    nextYear = leaveEndYear;
    endCurrentOfYear = moment(startDate).endOf("year").toDate();
    startOfNextYear = nextYear
      ? moment(endDate).startOf("year").toDate()
      : null;
    parsedEndDate = endCurrentOfYear;
    parsedStartDate = startOfNextYear;
  }

  const { data, loading, error, refetch } = useQuery(GET_WORK_DAYS, {
    fetchPolicy: "cache-and-network",
    skip: skip,
    pollInterval: usePolling(1000 * 60),
    variables: { contract, startDate, endDate: parsedEndDate },
  });

  const {
    data: nextYearData,
    loading: nextYearLoading,
    error: nextYearError,
    refetch: nextYearRefetch,
  } = useQuery(GET_WORK_DAYS, {
    fetchPolicy: "cache-and-network",
    skip: skip || !nextYear,
    pollInterval: usePolling(1000 * 60),
    variables: { contract, startDate: parsedStartDate, endDate },
  });

  useSubscription(WORK_DAYS_SUBSCRIPTION, {
    skip: skip,
    variables: { contract, startDate, endDate },
    onData: () => {
      if (nextYear) {
        nextYearRefetch();
      }
      refetch();
    },
  });

  const workDays = data?.workDays?.workDays || 0;
  const effectiveWorkDays = data?.workDays?.effectiveWorkDays || 0;
  const effectiveWorkDaysByDays = data?.workDays?.effectiveWorkDaysByDays || [];
  const nextYearWorkDays = nextYearData?.workDays?.workDays || 0;
  const nextYearEffectiveWorkDays =
    nextYearData?.workDays?.effectiveWorkDays || 0;
  const nextYearEffectiveWorkDaysByDays =
    nextYearData?.workDays?.effectiveWorkDaysByDays || [];

  return {
    workDays: nextYear ? workDays + nextYearWorkDays : workDays,
    effectiveWorkDays: nextYear
      ? effectiveWorkDays + nextYearEffectiveWorkDays
      : effectiveWorkDays,
    effectiveWorkDaysByDays: nextYear
      ? effectiveWorkDaysByDays + nextYearEffectiveWorkDaysByDays
      : effectiveWorkDaysByDays,
    currentYearWorkDays: workDays,
    currentYearEffectiveWorkDays: effectiveWorkDays,
    nextYearWorkDays: nextYearWorkDays,
    nextYearEffectiveWorkDays: nextYearEffectiveWorkDays,
    workDaysloading: loading || nextYearLoading,
    workDaysError: error || nextYearError,
  };
}
