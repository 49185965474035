import React from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/styles";

const MyTimeRequestTypeInterface = ({
  data = [],
  dayType,
  myTimeType,
  timeDataTypeName,
}) => {
  const theme = useTheme();

  const { t } = useTranslation();

  let displayDayType = myTimeType;
  let displayTimeDataTypeName = timeDataTypeName;
  let displayMyTimeType = myTimeType;

  if (data?.length !== 0) {
    if (data?.myTimeType) {
      displayDayType = data?.myTimeType;
    }
    displayTimeDataTypeName = data?.timeDataTypeName;
    displayMyTimeType = t("MY_TIME_TYPE");
  }

  return (
    <Box
      style={{
        borderRadius: "8px",
        backgroundColor: theme.palette.gray30.main,
        padding: "16px",
      }}
    >
      <Typography variant="body1">{t("TYPE")}:</Typography>
      <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
        {t(displayDayType)}
      </Typography>
      <Box height="6px" />
      <Typography variant="body1">{t(displayMyTimeType)}:</Typography>
      <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
        {displayTimeDataTypeName}
      </Typography>
    </Box>
  );
};

export default MyTimeRequestTypeInterface;
