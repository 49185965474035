import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  colorSecondary: {
    backgroundColor: "#E3E5E6",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  linear: {
    height: "16px",
    borderRadius: "4px",
  },
  colorPrimary: {
    backgroundColor: "#E3E5E6",
  },
}));

export default useStyles;
