import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import usePolling from "hooks/use-polling";

const CALENDAR = gql`
  query calendar($startDate: Date!, $endDate: Date!) {
    calendar(startDate: $startDate, endDate: $endDate)
  }
`;

export default function useCalendar({ startDate, endDate }) {
  const { data, loading, error } = useQuery(CALENDAR, {
    skip: !startDate || !endDate,
    pollInterval: usePolling(1000 * 60),
    variables: { startDate, endDate },
  });

  return {
    data: data?.calendar,
    loading: loading,
    error: error,
  };
}
