import React from "react";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import Table from "oc/components/oc-data-table";
import useClientState from "hooks/use-oc-client-state";
import { previousFulfilledLeaves as tableDef } from "my-leave/configs/my-leave-table-defs";
import getSortedList from "oc/utils/get-sorted-list";
import { useHistory } from "react-router-dom";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import { LEAVES_SUBSCRIPTION } from "graphql/subcriptions";
import useCurrentUserState from "hooks/use-current-user-state";
import usePolling from "hooks/use-polling";
import gql from "graphql-tag";

const QUERY = gql`
  query leaveYearlyDaysByContract($contract: String!, $year: String!) {
    leaveYearlyDaysByContract(contract: $contract, year: $year) {
      _id
      year
      availableLeaveDays
      availableStudyLeaveDays
      availableESZJTV19LeaveDays
      availableCovidPlus10LeaveDays
      availableEmergencyLeaveDays
      fulfilledLeaveDays
      fulfilledStudyLeaveDays
      fulfilledAbsenceDays
      fulfilledCovidPlus10Days
      fulfilledESZJTV19Days
      fulfilledEmergencyDays
    }
  }
`;

const PreviousFulfilledLeavesContainer = ({ onSelectYear }) => {
  const history = useHistory();
  let thisYear = new Date().getFullYear().toString();

  let { contractCode } = useCurrentUserState();

  const { data, loading, refetch } = useQuery(QUERY, {
    skip: !contractCode || !thisYear,
    pollInterval: usePolling(1000 * 60),
    fetchPolicy: "cache-and-network",
    variables: { contract: contractCode, year: thisYear },
  });

  useSubscription(LEAVES_SUBSCRIPTION, {
    skip: !contractCode,
    onData: () => {
      refetch();
    },
  });

  let result = data?.leaveYearlyDaysByContract || [];

  let now = new Date();
  let filteredResult = result?.filter(
    (item) => item.year !== now.getFullYear().toString()
  );

  const previousFulfilledLeaves = filteredResult.map((item) => {
    let translatedItem = {
      year: item.year,
      fulfilledLeave: `${item.fulfilledLeaveDays.toLocaleString()}/${item.availableLeaveDays.toLocaleString()}`,
      fulfilledAbsence: item.fulfilledAbsenceDays.toLocaleString(),
    };
    return translatedItem;
  });

  let tableId = "previousFulfilledLeaves";

  initialTableData.sortField = "year";
  initialTableData.sortDirection = "desc";
  initialTableData.pageSize = 5;
  initialTableData.selectedRowKey = "year";

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  previousFulfilledLeaves.sort(
    getSortedList(
      tableData?.sortDirection === "asc"
        ? tableData?.sortField
        : `-${tableData?.sortField}`
    )
  );

  let list = {};
  list.total = previousFulfilledLeaves?.length || 0;
  list.page = tableData?.page;
  list.pageSize = tableData?.pageSize || initialTableData.pageSize;
  list.rows = previousFulfilledLeaves || [];

  function handleSelectRow(o) {
    history.push(`/my-leave/my-yearly-leaves/${o.year}`);
  }

  return (
    <Table
      data={list}
      loading={loading && data?.rows?.length === 0 ? true : false}
      tableDef={tableDef}
      tableData={tableData}
      setTableData={setTableData}
      pagination={true}
      onSelectRow={handleSelectRow}
      localPaging={true}
    />
  );
};

export default PreviousFulfilledLeavesContainer;
