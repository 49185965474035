import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { InlineIcon } from "@iconify/react";
import accountIcon from "@iconify/icons-mdi/account-network";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function OcUsernameInputField({ value, setValue, error, setError, helperText, setHelperText }) {
  const { t } = useTranslation();

  return (
    <TextField
      id="oc-username-input-field"
      label={t("USERNAME")}
      variant="outlined"
      fullWidth
      value={value}
      onChange={(e) => setValue(e.target.value)}
      error={error}
      helperText={error && helperText}
      onFocus={() => {
        setError(false);
        setHelperText("");
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" height="20px">
            <InlineIcon icon={accountIcon} height="20px" />
          </InputAdornment>
        ),
      }}
    />
  );
}
