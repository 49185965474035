import React from "react";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import useStyles from "./oc-data-table-pagination-actions.style";

export default function PaginationActions({
    count,
    page,
    rowsPerPage,
    onPageChange,
}) {
    const classes = useStyles();
    const theme = useTheme();

    function handleFirstPageButtonClick() {
        onPageChange(1);
    }

    const handleBackButtonClick = event => {
        onPageChange(page);
    };

    const handleNextButtonClick = event => {
        onPageChange(page + 2);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(Math.max(0, Math.ceil(count / rowsPerPage)));
    };

    return (
        <div
            id="oc-data-table-pagination-actions-buttons"
            className={classes.root}
        >
            <IconButton
                id="oc-data-table-pagination-first-page-button"
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                id="oc-data-table-pagination-previous-page-button"
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                id="oc-data-table-pagination-next-page-button"
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                id="oc-data-table-pagination-last-page-button"
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}
