import gql from "graphql-tag";

export const GET_EMPLOYEE = gql`
  query employee($code: String!) {
    employee(code: $code) {
      _id
      code
      prefixName
      lastName
      middleName
      firstName
      nameSuffix
      dateOfBirth
      placeOfBirth
      countryOfBirth
      mothersNamePrefix
      mothersLastName
      mothersFirstName
      mothersFirstName2
      mothersNameSuffix
      gender {
        code
        name
      }
      maritalStatus {
        code
        name
      }
      taxNumber
      socialSecurityNumber
      identityCardNumber
      nationality
      preferredLanguage
      permanentAddress {
        country
        zip
        city
        district
        streetName
        streetType {
          code
          name
        }
        streetNumber
        building
        stairway
        floor
        door
        postBox
      }
      temporaryAddress {
        country
        zip
        city
        district
        streetName
        streetType {
          code
          name
        }
        streetNumber
        building
        stairway
        floor
        door
        postBox
      }
      serviceAddress {
        country
        zip
        city
        district
        streetName
        streetType {
          code
          name
        }
        streetNumber
        building
        stairway
        floor
        door
        postBox
      }
      phones {
        type
        number
      }
      emails {
        type
        address
      }
      validFrom
      validTo
    }
  }
`;
