import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
//import useClientState from "hooks/use-oc-client-state";
import { useTranslation } from "react-i18next";
import OcSnackbarAlert from "oc/components/oc-snackbar-alert";
import { useRecoilState } from "recoil";
import { notificationState } from "states";

export default function OcSnackbar() {
  const { t } = useTranslation();

  const [openMessage, setOpenMessage] = useRecoilState(notificationState);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessage({
      type: openMessage?.type,
      message: "",
    });
  };

  return (
    <Snackbar
      open={openMessage?.message ? true : false}
      autoHideDuration={openMessage?.type === "error" ? 16000 : 6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <OcSnackbarAlert onClose={handleClose} severity={openMessage?.type}>
        {t(openMessage?.message)}
      </OcSnackbarAlert>
    </Snackbar>
  );
}
