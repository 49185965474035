import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import DocumentIcon from "@iconify/icons-mdi/file-document";
import DownloadIcon from "@iconify/icons-mdi/download";
import UrlIcon from "@iconify/icons-mdi/chevron-right";
import PDFIcon from "@iconify/icons-mdi/file-pdf-box-outline";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useTheme } from "@material-ui/styles";
import dotsVertical from "@iconify/icons-mdi/dots-vertical";
import OcEditorMenu from "oc/components/oc-editor-menu";
import { useHistory } from "react-router-dom";

const OcDownload = ({
  iconType,
  label,
  href,
  actionIconType,
  target = null,
  createdAt,
  badge,
  isSmall,
}) => {
  let icon = iconType === "pdf" ? PDFIcon : DocumentIcon;
  let actionIcon = actionIconType === "url" ? UrlIcon : DownloadIcon;
  const theme = useTheme();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleModified = (e) => {
    setAnchorEl(null);
    history.push("/my-hr/my-profile");
  };

  const handleDelete = (e) => {
    setAnchorEl(null);
  };

  if (!href) return null;

  let height = "72px";

  if (isSmall) {
    height = "56px";
  }

  return (
    <>
      <OcEditorMenu
        handleClose={handleClose}
        handleModified={handleModified}
        handleDelete={handleDelete}
        anchorEl={anchorEl}
      />
      <Link
        id={label.replace(/ /g, "_")}
        href={href}
        download={actionIconType === "url" ? false : true}
        style={{
          textDecoration: "none",
          padding: "0px",
          margin: "0px",
          outline: "none",
        }}
        target={target}
      >
        <Box
          height="40px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          style={{
            height: height,
            backgroundColor: theme.palette.gray20.main,
            border: `1px solid ${theme.palette.gray40.main}`,
            display: "flex",
            borderRadius: "8px",
            color: theme.palette.darkBase.main,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="40px"
            height="40px"
            marginLeft="7px"
          >
            <InlineIcon icon={icon} width="20px" height="20px" />
          </Box>
          <Box flexGrow={1} display="flex" flexDirection="column">
            <Typography variant="body1">{label}</Typography>
            <Typography
              variant="caption"
              style={{
                color: "#919699",
              }}
            >
              {createdAt}
            </Typography>
          </Box>
          {badge?.length > 0 && (
            <>
              <Box
                style={{
                  border: `1px solid ${theme.palette.greenBase.main}`,
                  borderRadius: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 2,
                  paddingBottom: 2,
                  color: theme.palette.gray80.main,
                  fontSize: "14px",
                }}
              >
                {badge}
              </Box>
              <Box width="30px" />
              <InlineIcon
                icon={dotsVertical}
                width="18px"
                height="18px"
                style={{ cursor: "pointer", color: "#4d4d4d" }}
                onClick={handleClick}
              />
            </>
          )}
          <Box width="10px" />
          <Box
            id={`app-${href.slice(15)}`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="40px"
            height="40px"
            style={{
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            <InlineIcon
              icon={actionIcon}
              width="18px"
              height="18px"
              style={{ color: "#4d4d4d" }}
            />
          </Box>
        </Box>
      </Link>
    </>
  );
};

OcDownload.propTypes = {
  /** Specify the icon type */
  iconType: PropTypes.string,
  /** Label of the component*/
  label: PropTypes.string,
  /** Link URL of document to download */
  href: PropTypes.string,
  /** Specify the action icon type */
  actionIconType: PropTypes.string,
};

OcDownload.defaultProps = {
  label: "unspecified label",
  iconType: "document",
  actionIconType: "download",
};

export default OcDownload;
