import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { notificationState } from "states";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import ButtonBar from "my-leave/components/my-leave-email-card/components/button-bar";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import OcFormSwitch from "oc/components/oc-form-switch/oc-form-switch";

export default function MyHrAdminSettingsTaxCreditApplication() {
  const { t } = useTranslation();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [enabledTaxSave, setEnabledTaxSave] = useState(true);
  const [prevEnabledTaxSave, setPrevEnabledTaxSave] = useState(enabledTaxSave);

  const setOpenMessage = useSetRecoilState(notificationState);

  const [updateProperty, { loading }] = useMutation(UPDATE_PROPERTY, {
    onError({ ...err }) {
      alert("error" + err.toString());
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
    onCompleted(data) {
      if (data?.updateProperty.key === "taxApplicationSaveEnabled") {
        const parsedValue = data?.updateProperty.value === "true";
        setEnabledTaxSave(parsedValue);
        setPrevEnabledTaxSave(parsedValue);
      }
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
  });

  const onCompleted = (property) => {
    if (property?.key === "taxApplicationSaveEnabled") {
      const parsedValue = property?.value === "true";
      setEnabledTaxSave(parsedValue);
      setPrevEnabledTaxSave(parsedValue);
    }
  };

  useProperty({
    resource: "system",
    key: "taxApplicationSaveEnabled",
    onCompleted,
    fetchPolicy: "network-only",
  });

  const handleChangeEnabled = (event) => {
    const newEnabled = event.target.checked;
    setEnabledTaxSave(newEnabled);
  };

  const handleSaveEnabled = () => {
    updateProperty({
      variables: {
        resource: "system",
        key: "taxApplicationSaveEnabled",
        value: enabledTaxSave.toString(),
      },
    });
    setPrevEnabledTaxSave(enabledTaxSave);
    setShowConfirmDialog(false);
  };

  const handleCancel = () => {
    setEnabledTaxSave(prevEnabledTaxSave);
  };

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={() => {
          handleSaveEnabled();
        }}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcCard label={t("TAX_CREDIT_APPLICATIONS")} loading={loading}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box width="200px" textAlign="right" color="#4D4D4D">
            <Typography variant="caption">{t("SAVE")}</Typography>
          </Box>
          <Box width="16px" />
          <OcFormSwitch
            id="enabledTaxSave"
            checked={enabledTaxSave}
            onChange={handleChangeEnabled}
            inputProps={{ "aria-label": "controlled" }}
            disabled={false}
          />
          <Box width="16px" />
          <Box width="300px">
            <Box
              fontSize="12px"
              letterSpacing="0.4px"
              color="#4D4D4D"
              width="300px"
            >
              {t("TAX_CREDIT_APPLICATIONS_SETTING_INFO")}
            </Box>
          </Box>
        </Box>
        <Box height="48px" />
        <ButtonBar
          handleCancel={handleCancel}
          handleSave={() => setShowConfirmDialog(true)}
          disabled={loading || enabledTaxSave === prevEnabledTaxSave}
        />
      </OcCard>
    </>
  );
}
