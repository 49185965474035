import gql from "graphql-tag";

export const USER_BY_EMPLOYEE_SUBSCRIPTION = gql`
  subscription userByEmployee($employee: String!) {
    userByEmployee(employee: $employee) {
      _id
      employee
      email
      phone
      ldapUser
      lastLogin
      lastPasswordChange
      validFrom
      validTo
    }
  }
`;
