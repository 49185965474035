import gql from "graphql-tag";

export const GET_JOB_CLASS_GROUPS = gql`
  query jobClassGroups(
    $searchField: String
    $searchText: String
    $sort: String
    $page: Int
    $pageSize: Int
    $jobClassGroups: [String]
    $product: String
    $role: String
    $showAllowedEntities: Boolean
  ) {
    jobClassGroups(
      searchField: $searchField
      searchText: $searchText
      sort: $sort
      page: $page
      pageSize: $pageSize
      jobClassGroups: $jobClassGroups
      product: $product
      role: $role
      showAllowedEntities: $showAllowedEntities
    ) {
      availableList {
        _id
        code
        name
      }
      selectedList {
        _id
        code
        name
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
