import gql from "graphql-tag";

export const DELETE_LEAVE_REQUEST = gql`
  mutation deleteLeaveRequest(
    $_id: String!
    $locale: String!
    $message: String
  ) {
    deleteLeaveRequest(_id: $_id, locale: $locale, message: $message) {
      _id
    }
  }
`;
