import moment from "moment";

export const getDateRangeArray = (startDate, endDate) => {
  let dateArray = [];
  let currentDate = moment(startDate);
  var stopDate = moment(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
};

export default getDateRangeArray;
