import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import usePolling from "hooks/use-polling";

const GET_CONTRACT_CALENDARS = gql`
  query contractCalendar(
    $contract: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    contractCalendar(
      contract: $contract
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export default function useContractCalendar({ contract, startDate, endDate }) {
  const { data, loading, error } = useQuery(GET_CONTRACT_CALENDARS, {
    skip: !contract || !startDate || !endDate,
    pollInterval: usePolling(1000 * 60),
    variables: { contract, startDate, endDate },
  });

  return {
    contractCalendar: data?.contractCalendar,
    contractCalendarLoading: loading,
    contractCalendarError: error,
  };
}
