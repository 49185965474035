import React from "react";
import { Box, Typography } from "@material-ui/core";
import MyTimeDateHeaderTooltip from "my-time/components/my-time-date-header-tooltip/my-time-date-header-tooltip";
import useCalendar from "hooks/use-my-leave-calendar";
import { startOfYear, endOfYear, getDayOfYear } from "date-fns";

const MyTimeCalendarMonthlyDateHeader = (props) => {
  const { date, label, isOffRange, countTimeData } = props;
  const { workTime, overtime, voluntaryOvertime } = countTimeData(date);

  let startDate = startOfYear(date);
  let endDate = endOfYear(date);

  const { data: Calendar } = useCalendar({ startDate, endDate });
  let day = getDayOfYear(date);
  let dayState = Calendar?.charAt(day - 1);

  return (
    <MyTimeDateHeaderTooltip
      workTime={workTime}
      overtime={overtime}
      voluntaryOvertime={voluntaryOvertime}
      isOffRange={isOffRange}
      date={date}
      placement="bottom-end"
    >
      <Box paddingRight="8px">
        <Typography
          variant="body1"
          style={{ padding: 0, margin: 0, lineHeight: "16px", color: dayState === "F" ? "#cc0033" : {} }}
        >
          {label}
        </Typography>
      </Box>
    </MyTimeDateHeaderTooltip>
  );
}

export default MyTimeCalendarMonthlyDateHeader;
