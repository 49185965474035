import gql from "graphql-tag";

export const UPDATE_USER_EMAIL = gql`
  mutation updateUserEmail(
    $_id: String!
    $ldapUser: String,
    $email: String!
    $phone: String
    $password: String!
  ) {
    updateUserEmail(
      _id: $_id
      ldapUser: $ldapUser
      email: $email
      phone: $phone
      password: $password
    ) {
      _id
      email
      phone
      validFrom
      validTo
    }
  }
`;
