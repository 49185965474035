import factIcon from "@iconify/icons-mdi/clipboard-text-clock-outline";
import requestIcon from "@iconify/icons-mdi/account-clock";
import standByIcon from "@iconify/icons-mdi/car-clock";
import leaveIcon from "@iconify/icons-mdi/bag-personal";
import absenceIcon from "@iconify/icons-mdi/account-remove";
import bedClockIcon from "@iconify/icons-mdi/bed-clock";

const useMyTimeIcon = (event) => {
  let icon = factIcon;

  if (event.state && event.state === "requested") {
    icon = requestIcon;
  }

  if (event.myTimeType === "STAND_BY_CALL") {
    icon = standByIcon;
  }

  if (event.myTimeType === "LEAVE") {
    icon = leaveIcon;
  }

  if (event.myTimeType === "ABSENCE") {
    icon = absenceIcon;
  }

  if (event.state && event.state === "approved") {
    icon = requestIcon;
  }

  if (event.myTimeType === "OVERTIME" && event.timeDataTypeCode === "Tö") {
    icon = requestIcon;
  }

  if (
    event.myTimeType === "WORKING_TIME" &&
    (event.state === "approved" || event.state === "requested")
  ) {
    icon = factIcon;
  }

  if (event.myTimeType === "ON_CALL") {
    icon = bedClockIcon;
  }

  return icon;
};

export default useMyTimeIcon;
