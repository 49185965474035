import { useQuery, useSubscription } from "@apollo/client";
import { GET_CHANGE_REQUESTS } from "graphql/queries";
import { CHANGE_REQUESTS_SUBSCRIPTION } from "graphql/subcriptions";

const useChangeRequests = (employee) => {
  const { data, loading, error, refetch } = useQuery(GET_CHANGE_REQUESTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      employee,
    },
  });

  useSubscription(CHANGE_REQUESTS_SUBSCRIPTION, {
    onData: () => {
      refetch();
    },
  });

  const result = data?.changeRequests || [];

  return {
    data: result,
    loading,
    error,
    refetch,
  };
};

export default useChangeRequests;
