import gql from "graphql-tag";

export const CREATE_TAX_DECLARATION_FAMILY = gql`
  mutation createTaxDeclarationFamily(
    $dateOfDeclaration: Date!,
    $isModifiedDeclaration: Boolean!,
    $applyAlone: Boolean!,
    $applyTogether: Boolean!,
    $applyOnHungary: Boolean!,
    $noRequestRelief: Boolean!, 
    $taxReliefAmount: Float!,
    $taxReliefNumberOfChild: Int,
    $spouseName: String,
    $spouseTaxNumber: String,
    $spouseEmployerName: String,
    $spouseEmployerTaxNumber: String,
    $dependents: [TaxDeclarationsFamilyDependentInput],
    ) {
    createTaxDeclarationFamily(
        dateOfDeclaration: $dateOfDeclaration,
        isModifiedDeclaration: $isModifiedDeclaration,
        applyAlone: $applyAlone,
        applyTogether: $applyTogether,
        applyOnHungary: $applyOnHungary,
        noRequestRelief: $noRequestRelief,
        taxReliefAmount: $taxReliefAmount,
        taxReliefNumberOfChild: $taxReliefNumberOfChild,
        spouseName: $spouseName,
        spouseTaxNumber: $spouseTaxNumber,
        spouseEmployerName: $spouseEmployerName,
        spouseEmployerTaxNumber: $spouseEmployerTaxNumber,
        dependents: $dependents,
    ) {
      result
      messages
      tableId
    }
  }`;
