import React from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export default function MyHrUserDataEditCollapseHeaderBadge({
    badgeName,
    border,
}) {
    const theme = useTheme();

    return (
        <>
            <Box
                style={{
                    color: theme.palette.gray80.main,
                    border: border,
                    borderRadius: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 2,
                    paddingBottom: 2,
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-around",
                }}
            >
                {badgeName}
            </Box>
        </>
    );
}
