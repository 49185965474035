import gql from "graphql-tag";

export const REMOVE_MASS_ROLE = gql`
  mutation removeMassRole(
    $roleFilters: RoleFilterInput!
    $roleFiltering: RoleFilterInput!
    $module: String!
    $role: String!
  ) {
    removeMassRole(
      roleFilters: $roleFilters
      roleFiltering: $roleFiltering
      module: $module
      role: $role
    ) {
      processed
      added
      errors {
        employee
        employeeName
        contract
        error
        module
        role
      }
    }
  }
`;
