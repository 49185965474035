import React from "react";
import { Box } from "@material-ui/core";
import LeaveRequestMessage from "my-leave/components/my-leave-request-message";
import LeaveRequestOwnMessage from "my-leave/components/my-leave-request-own-message";
import MyTimeNewMessage from "my-time/components/my-time-new-message";

const MyTimeRequestCardMessages = ({ data, messages, loading }) => {
  return (
    <Box>
      {messages.map((messageItem, index) => (
        <Box key={`${index}_${messageItem._id}`}>
          {messageItem.createdBy === data?.createdBy ? (
            <LeaveRequestOwnMessage messageItem={messageItem} />
          ) : (
            <LeaveRequestMessage messageItem={messageItem} />
          )}
        </Box>
      ))}
      <MyTimeNewMessage
        loading={loading}
        selectedRequest={data?._id}
        employee={data?.createdBy}
      />
    </Box>
  );
};

export default MyTimeRequestCardMessages;
