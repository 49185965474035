import React from "react";
import OcFormEditorItemList from "../oc-form-editor-item-list";
import useOcFormEditorSelector from "../../hooks/use-oc-form-editor-selector";
import usePostalCodes from "hooks/use-postal-codes";

const OcFormEditorZipSelector = ({
  name,
  label,
  value,
  onChange,
  error,
  isNewAddress,
}) => {
  const { data: postalCodes } = usePostalCodes();
  const isZip = name === "zip" ? true : false;
  const {
    list,
    search,
    selectedItem,
    handleSearch,
    handleClearSearch,
    handleHasMore,
  } = useOcFormEditorSelector({
    value,
    list: postalCodes,
    selectName: !isZip,
    searchCode: name === "zip" ? true : false,
  });

  return (
    <OcFormEditorItemList
      search={search}
      onSearch={handleSearch}
      onChange={onChange}
      onClearSearch={handleClearSearch}
      list={list}
      onScroll={handleHasMore}
      value={selectedItem}
      label={label}
      name={name}
      error={error}
      showCode={isZip}
      showItem={true}
      showLabel={isNewAddress}
    />
  );
};

export default OcFormEditorZipSelector;
