import React from "react";
import useLeaveSummaryChartData from "hooks/use-my-leave-summary-chart-data";
import MyLeaveBar from "my-leave/components/my-leave-bar";

const LeaveEmergencyCard = ({ selectedYear, contract }) => {
  const { data } = useLeaveSummaryChartData({
    year: selectedYear,
    contract,
  });

  if (!data?.allEmergencyLeaveDays || data?.allEmergencyLeaveDays === 0) {
    return null;
  }

  return (
    <MyLeaveBar
      id="emergency-leave-days-bar"
      label={"Leave.leaveEmergency"}
      all={data?.allEmergencyLeaveDays}
      available={data?.availableEmergencyLeaveDays}
      requested={data?.requestedEmergencyLeaveDays}
      fulfilled={data?.fulfilledEmergencyDays}
    />
  );
};
export default LeaveEmergencyCard;
