import useCompanies from "./use-companies";
import getCorrectBase64Image from "oc/utils/get-correct-base64-image";

const useDefaultCompanyLogo = () => {
  const { data, error } = useCompanies();

  let companies = data?.companies?.rows;
  let companyLogo = null;

  if (companies?.length > 0) {
    let rawLogo = companies[0].companyLogo;
    if (rawLogo) {
      companyLogo = getCorrectBase64Image(rawLogo);
    }
  }

  return { companyLogo: companyLogo, error };
};

export default useDefaultCompanyLogo;
