import Badge from "@material-ui/core/Badge";
import { makeStyles, withStyles } from "@material-ui/core/styles";

export const StyledBadge = withStyles((theme) => ({
  badge: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "2px",
    fontFamily: "Roboto",
    fontSize: "10px",
    minWidth: "16px",
    width: "16px",
    height: "16px",
    paddingTop: "2px",
    fontWeight: 500,
  },
}))(Badge);

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    padding: "6px",
  },
  icon: {
    color: theme.palette.primary.main,
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
}));
