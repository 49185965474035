import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import useStyles from "./oc-loading.style";

export default function OcLoading({ show }) {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={show || false}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <img src="/img/owPreloading.gif" alt="Loading" />
    </Backdrop>
  );
}
