import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_CURRENT_CAFETERIA_STATEMENT_ITEMS } from "graphql/queries";
import { CAFETERIA_SUBSCRIPTION } from "graphql/subcriptions";
import _ from "lodash";
import moment from "moment";
import parseJdDate from "oc/utils/parse-jd-date";
import { useTranslation } from "react-i18next";
import usePolling from "hooks/use-polling";

export default function useCurrentCafeteriaStatementItems({ contractCode }) {
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery(
    GET_CURRENT_CAFETERIA_STATEMENT_ITEMS,
    {
      skip: !contractCode,
      fetchPolicy: "cache-and-network",
      pollInterval: usePolling(1000 * 60),
      variables: { contractCode },
    }
  );

  const { error: subscriptionError } = useSubscription(CAFETERIA_SUBSCRIPTION, {
    onData: (data) => {
      refetch();
    },
    onError: (err) => {
      console.log("error", err);
    },
  });

  /** Manipulationg data */
  let parsedData = [];
  try {
    parsedData = data?.getCurrentCafeteriaStatementItems?.map((item, index) => {
      let newItem = _.cloneDeep(item);
      let isPlan = item.plan ? t("PLAN") : t("FACT");
      newItem.index = index;
      newItem.startDate = moment(parseJdDate(newItem.startDate)).format("MM");
      newItem.originalStartDate = item.startDate;
      newItem.endDate = moment(parseJdDate(newItem.endDate)).format("MM");
      newItem.originalEndDate = item.endDate;
      newItem.months = `${newItem.startDate} - ${newItem.endDate}`;
      let numberOfMonths = +newItem.endDate - +newItem.startDate + 1;
      newItem.numberOfMonths = numberOfMonths;
      newItem.valueOfBenefit = item.cost;
      newItem.benefit = item.menuItem.name;
      newItem.planable = item.menuItem.planable;
      newItem.origin = `${item.origin} - ${isPlan}`;
      newItem.cost = item.cost - item.amount * numberOfMonths;
      newItem.amount = item?.amount * numberOfMonths;
      return newItem;
    });
  } catch (error) {
    console.log(error);
  }

  return {
    data: parsedData,
    loading: loading && !data,
    error: error || subscriptionError,
  };
}
