import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: ({ centerTextLeft, centerTextTop }) => ({
    position: "absolute",
    left: centerTextLeft,
    top: centerTextTop,
    width: 100,
    textAlign: "center",
  }),

  summaryValue: {
    fontSize: "34px",
    lineHeigh: "43px",
    color: "#232425",
    letterSpacing: "0.25px",
    marginBottom: 10,
  },
  summaryLabel: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.gray60.main,
    letterSpacing: "0.44px",
    textTransform: "capitalize",
  },
  summaryType: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.gray60.main,
    letterSpacing: "0.44px",
    textTransform: "capitalize",
  },
}));

export default useStyles;
