import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import FilterItem from "oc/components/oc-filter-item";
import { GET_PAY_OFFICES } from "graphql/queries";
import { PAY_OFFICES_SUBSCRIPTION } from "graphql/subcriptions";
import { useQuery, useSubscription } from "@apollo/client";
import usePolling from "hooks/use-polling";
import useFiltersData from "hooks/use-filters-data";
import useQueryParams from "hooks/use-query-params";

export default function PayofficeFilter({
  roleFilters,
  onAddFilter,
  onRemoveFilter,
  product,
  role,
  editable = true,
  contract
}) {
  let filterType = "payOffice";

  const entity = {
    payOffices: roleFilters?.payOffice,
  };

  const stateId = "payOfficeFilter";
  const { search, setSearch, setPageSize, queryParams, showLoading } =
    useQueryParams({
      stateId,
      entity,
      product,
      role,
      showAllowedEntities: editable,
      roleFilters,
      contract
    });

  const {
    data: rawData,
    loading,
    fetchMore,
  } = useQuery(GET_PAY_OFFICES, {
    fetchPolicy: "no-cache",
    pollInterval: usePolling(1000 * 60),
    variables: queryParams,
  });

  useSubscription(PAY_OFFICES_SUBSCRIPTION);
  const data = rawData?.payOffices;

  const {
    availableItems,
    selectedItems,
    handleFetchMore,
    handleSearch,
    handleClearSearch,
  } = useFiltersData({
    data,
    fetchMore,
    setSearch,
    setPageSize,
    entityLength: roleFilters?.payOffice?.length,
    selectAll: true,
    product,
    role,
    filterType,
  });

  return (
    <OcErrorBoundary>
      <FilterItem
        availableItems={availableItems}
        editable={editable}
        filterType={filterType}
        onAddFilter={onAddFilter}
        onClearSearch={handleClearSearch}
        onFetchMore={handleFetchMore}
        onLoading={loading && showLoading}
        onRemoveFilter={onRemoveFilter}
        onSearch={handleSearch}
        roleFilters={roleFilters}
        search={search}
        selectedItems={selectedItems}
        withCode={true}
      />
    </OcErrorBoundary>
  );
}
