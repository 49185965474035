import gql from "graphql-tag";

export const GET_CHANGE_REQUESTS = gql`
    query changeRequests($employee: String!) {
        changeRequests(employee: $employee) {
            _id
            typeGroupId
            typeGroup
            type
            oldValue
            newValue
            messages {
                _id
                message
                createdAt
                createdBy
            }
            validFrom
            createdAt
            createdBy
            approvedAt
            rejectedAt
            contract
        }
    }
`;
