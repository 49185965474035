import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcTextInputField from "oc/components/oc-text-input-field";

export default function MyHrSSOForm({
  ssoAddress,
  ssoPort,
  ssoCN,
  domainSuffix,
  systemUserName,
  systemUserPassword
}) {
  const { t } = useTranslation();

  return (
    <Box paddingTop="36px">
      <Box>
        <Box width="480px" padding="0px 24px">
          <Box display="flex" flexDirection="row">
            <OcTextInputField
              label="URL"
              {...ssoAddress}
            />
            <Box width="16px" />
            <Box width="100px">
              <OcTextInputField
                label="PORT"
                {...ssoPort}
              />
            </Box>
          </Box>
          <Box height="36px" />
          <Box>
            <OcTextInputField
              label="DC"
              {...ssoCN}
            />
          </Box>
          <Box height="36px" />
          <Box>
            <OcTextInputField
              label={t("DOMAIN_SUFFIX")}
              {...domainSuffix}
            />
          </Box>
          <Box height="36px" />
          <Box>
            <OcTextInputField
              label={t("SYSTEM_USER_NAME")}
              {...systemUserName}
            />
          </Box>
          <Box height="36px" />
          <Box>
            <OcTextInputField
              label={t("SYSTEM_USER_PASSWORD")}
              {...systemUserPassword}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
