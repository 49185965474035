import { InlineIcon } from "@iconify/react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import emailIcon from "@iconify/icons-mdi/email";
import gql from "graphql-tag";
import InputAdornment from "@material-ui/core/InputAdornment";
import keyIcon from "@iconify/icons-mdi/key";
import Loading from "oc/components/oc-loading";
import React, { useEffect, Fragment, useState } from "react";
import TextField from "@material-ui/core/TextField";
import useClientState from "hooks/use-oc-client-state";
import Validator from "fastest-validator";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import useOcProperty from "hooks/use-oc-property";

const validator = new Validator();

const LOGIN = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      sessionPeriod
      accessTokenPeriod
      currentUser {
        _id
        email
        phone
        ldapUser
        employee
        lastPasswordChange
        validFrom
        validTo
      }
      isTemporaryPassword
    }
  }
`;

export default function MyHrUnlockForm({ onSubmit, buttonLabel, email }) {
  const { t } = useTranslation();
  const schema = {
    email: {
      type: "email",
      messages: {
        email: t("ERROR_MESSAGE_WRONG_EMAIL_FORMAT"),
        emailEmpty: t("ERROR_MESSAGE_EMAIL_IS_REQUIRED"),
      },
    },
    password: {
      type: "string",
      min: 1,
      messages: {
        string: "Nem megfelelő jelszó!",
        stringMin: t("ERROR_MESSAGE_PASSWORD_IS_REQUIRED"),
      },
    },
  };
  const check = validator.compile(schema);

  const [password, setPassword] = useClientState("loginPassword", "");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useClientState(
    "loginPasswordError",
    false
  );
  const [passwordErrorText, setPasswordErrorText] = useClientState(
    "loginPasswordErrorText",
    ""
  );

  const { data: passwordVisibilityData } = useOcProperty({
    resource: "system",
    key: "passwordVisibility",
  });


  const [login, { loading }] = useLazyQuery(LOGIN, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.login) {
        let loginResult = data?.login;
        onSubmit(loginResult);
      }
    },
    onError: (error) => {
      let errors = error?.graphQLErrors;
      errors.map((item) => {
        if (item.message === "Wrong password!") {
          setPasswordErrorText(t("ERROR_MESSAGE_WRONG_PASSWORD"));
          setPasswordError(true);
        }
        return item;
      });
    },
  });

  function handleLogin(e) {
    e.preventDefault();
    let isValidated = check({ email, password });

    if (isValidated !== true) {
      isValidated.forEach((item) => {
        if (item.field === "password") {
          setPasswordErrorText(item.message);
          setPasswordError(true);
        }
      });
      return;
    }

    login({ variables: { email, password } });
  }

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  useEffect(() => {
    function handleClear() {
      setPassword("");
      setPasswordError(false);
      setPasswordErrorText("");
    }
    return function cleanup() {
      handleClear();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Loading show={loading} />
      <form noValidate autoComplete="off" onSubmit={handleLogin}>
        <Box padding="12px 0px 22px 0px">
          <TextField
            id="email"
            value={email}
            //onChange={(e) => setEmail(e.target.value)}
            label={t("EMAIL")}
            variant="outlined"
            fullWidth
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InlineIcon icon={emailIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box padding="14px 0px 16px 0px">
          <TextField
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={t("PASSWORD")}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            helperText={passwordErrorText}
            error={passwordError}
            onFocus={() => {
              setPasswordErrorText("");
              setPasswordError(false);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InlineIcon icon={keyIcon} />
                  <Box width="5px" />
                  {passwordVisibilityData === "true" &&
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityOff
                          style={{ height: "16px", width: "16px" }}
                        />
                      ) : (
                        <Visibility style={{ height: "16px", width: "16px" }} />
                      )}
                    </IconButton>
                  }
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box paddingTop="18px">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
          >
            {buttonLabel}
          </Button>
        </Box>
      </form>
    </Fragment>
  );
}
