import React from "react";
import { Box } from "@material-ui/core";

function ModifiedStatementInfo() {
  return (
    <Box style={{ padding: "16px" }}>
      <Box style={{ color: "#919699", lineHeight: "24px" }}>
        <Box>
          {
            "Ha a nyilatkozatban közölt adatok változnak, Ön köteles haladéktalanul új nyilatkozatot tenni – ezt a “Módosított nyilatkozat” jelölőnégyzet kipipálásával kell jelölnie."
          }
        </Box>
      </Box>
    </Box>
  );
}

export default ModifiedStatementInfo;
