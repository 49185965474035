import gql from "graphql-tag";

export const GET_POSTAL_CODES = gql`
  query postalCodes {
    postalCodes {
      code
      name
    }
  }
`;
