export const parseDayType = (dayType) => {
  switch (dayType) {
    case ".":
      return "WORKDAY";
    case "P":
      return "REST_DAY";
    case "F":
      return "PAID_HOLLIDAY";
    default:
      return "OTHER_DAY_TYPE";
  }
};

export default parseDayType;
