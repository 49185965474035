import React from "react";
import { contract } from "my-hr/configs/my-hr-info-card-defs";
import OcInfoCard from "oc/components/oc-info-card";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";

export default function MyHrContractsDataPanel({ contracts }) {
    const { t } = useTranslation();
    return contracts?.map((item, index) => {
        let label =
            item?.jobClass?.name.charAt(0).toUpperCase() +
            item?.jobClass?.name.slice(1);

        if (
            item?.employmentType?.code === "5" ||
            item?.employmentType?.code === "6"
        ) {
            label = t("CONTRACT_OF_SERVICES");
        }
        return (
            <Box
                key={`${item.code}-${index}`}
                marginBottom={index < contracts?.length - 1 ? "16px" : "0px"}
            >
                <OcInfoCard def={contract} data={item} label={label} />
            </Box>
        );
    });
}
