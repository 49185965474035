export default function getMothersFullName(employee) {
    if (!employee) return null;
    let mothersPrefixName = employee.mothersNamePrefix
        ? employee.mothersNamePrefix + " "
        : "";
    let mothersLastName = employee.mothersLastName
        ? employee.mothersLastName + " "
        : "";
    let mothersFirstName = employee.mothersFirstName
        ? employee.mothersFirstName + " "
        : "";
    let mothersFirstName2 = employee.mothersFirstName2
        ? employee.mothersFirstName2 + " "
        : "";
    let mothersNameSuffix = employee.mothersNameSuffix
        ? employee.mothersNameSuffix + " "
        : "";

    let mothersfullname =
        mothersPrefixName +
        mothersLastName +
        mothersFirstName +
        mothersFirstName2 +
        mothersNameSuffix;

    return mothersfullname?.trim();
}
