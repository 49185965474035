import React from "react";
import OcCard from "oc/components/oc-card";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { InlineIcon } from "@iconify/react";
import documentIcon from "@iconify/icons-mdi/file-document";
import rightIcon from "@iconify/icons-mdi/chevron-right";
import useSelectedTaxDeclaration from "hooks/use-selected-tax-declaration";
import useTaxApplications from "hooks/use-tax-applications";

const TaxApplicationSelector = ({ label, type }) => {
  const theme = useTheme();
  const { selected, setSelected } = useSelectedTaxDeclaration();

  const { taxApplications } = useTaxApplications();
  const enabled = taxApplications[type];

  const handleClick = () => {
    if (!enabled) return;
    const newState = { ...selected, editable: true, type };
    setSelected(newState);
  };

  return (
    <Box
      height="72px"
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={handleClick}
      style={{
        backgroundColor: theme.palette.gray20.main,
        border: `1px solid ${theme.palette.gray40.main}`,
        display: "flex",
        borderRadius: "8px",
        color: enabled
          ? theme.palette.darkBase.main
          : theme.palette.gray60.main,
        cursor: "pointer",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="40px"
        height="40px"
        marginLeft="7px"
      >
        <InlineIcon icon={documentIcon} width="20px" height="20px" />
      </Box>
      <Box flexGrow={1} display="flex" flexDirection="column">
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Box width="10px" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="40px"
        height="40px"
        style={{
          marginRight: "10px",
        }}
      >
        <InlineIcon
          icon={rightIcon}
          width="18px"
          height="18px"
          style={
            enabled
              ? { color: theme.palette.darkBase.main }
              : { color: theme.palette.gray60.main }
          }
        />
      </Box>
    </Box>
  );
};

const CurrentApplications = ({ label }) => {
  return (
    <OcCard label={label}>
      <Box>
        <TaxApplicationSelector
          label={
            "Adóelőleg-nyilatkozat a családi kedvezmény és járulékkedvezmény érvényesítéséről"
          }
          type="TaxDeclarationFamily"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={
            "Adóelőleg-nyilatkozat az első házasok kedvezményének érvényesítéséről"
          }
          type="TaxDeclarationFirstMarriedPerson"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={
            "Adóelőleg-nyilatkozat a négy vagy több gyermeket nevelő anyák kedvezményének érvényesítéséről"
          }
          type="TaxDeclarationFourChildren"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={"Adóelőleg-nyilatkozat a személyi kedvezmény érvényesítéséről"}
          type="TaxDeclarationPersonal"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={
            "Adóelőleg-nyilatkozat a 30 év alatti anyák kedvezményének érvényesítéséről"
          }
          type="TaxDecarationMotherUnder30"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={
            "Nyilatkozat a 25 év alatti fiatalok kedvezményének részben vagy egészben történő érvényesítésének mellőzéséről"
          }
          type="TaxDeclarationUnder25"
        />
      </Box>
    </OcCard>
  );
};

export default CurrentApplications;
