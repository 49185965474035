import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { GET_FILTERED_COMPANIES } from "graphql/queries";
import { COMPANIES_SUBSCRIPTION } from "graphql/subcriptions";
import { useQuery, useSubscription } from "@apollo/client";
import FilterItem from "oc/components/oc-filter-item";
import usePolling from "hooks/use-polling";
import useFiltersData from "hooks/use-filters-data";
import useQueryParams from "hooks/use-query-params";

export default function CompanyFilter({
  roleFilters,
  onAddFilter,
  onRemoveFilter,
  product,
  role,
  editable = true,
  contract
}) {
  let filterType = "company";

  const entity = {
    companies: roleFilters?.company,
  };

  const stateId = "companyFilter";

  const { search, setSearch, setPageSize, queryParams, showLoading } =
    useQueryParams({
      stateId,
      entity,
      product,
      role,
      roleFilters,
      showAllowedEntities: editable,
      contract
    });

  const {
    data: rawData,
    loading,
    fetchMore,
  } = useQuery(GET_FILTERED_COMPANIES, {
    fetchPolicy: "no-cache",
    pollInterval: usePolling(1000 * 60),
    variables: queryParams,
  });

  useSubscription(COMPANIES_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    variables: queryParams,
  });

  const data = rawData?.filteredCompanies;

  const {
    availableItems,
    selectedItems,
    handleFetchMore,
    handleSearch,
    handleClearSearch,
  } = useFiltersData({
    data,
    fetchMore,
    setSearch,
    setPageSize,
    entityLength: roleFilters?.company?.length || 0,
    selectAll: true,
    product,
    role,
    filterType,
  });

  return (
    <OcErrorBoundary>
      <FilterItem
        availableItems={availableItems}
        editable={editable}
        filterType={filterType}
        onAddFilter={onAddFilter}
        onClearSearch={handleClearSearch}
        onFetchMore={handleFetchMore}
        onLoading={loading && showLoading}
        onRemoveFilter={onRemoveFilter}
        onSearch={handleSearch}
        roleFilters={roleFilters}
        search={search}
        selectedItems={selectedItems}
        withCode={true}
      />
    </OcErrorBoundary>
  );
}
