import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #C6CACC",
    borderRadius: "4px",
  },
  counter: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
